import { AuthContext } from "../context/AuthContext";
import { useState, useContext, useEffect } from "react";

const usePresignedUrl = (s3Key) => {
  const [presignedUrl, setPresignedUrl] = useState(undefined);
  const { authToken, ezorder } = useContext(AuthContext);

  const createPresignedUrl = async (controller) => {
    try {
      let data = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      if (controller) {
        data.signal = controller.signal;
      }

      let keySplit = s3Key.split("/");
      let identifier = keySplit[0];
      let resource = keySplit[1];
      let fileName = keySplit[2];
      const response = await ezorder.post(
        `/files/presignedUrl`,
        { resource, fileName, identifier, actionType: "READ" },
        data
      );
      // console.log("Presigned Image Data", response.data);
      setPresignedUrl(response.data.getUrl);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    if (s3Key) createPresignedUrl(controller);

    return () => {
      controller.abort(); // abort api call on unmount for cleanup
    };
  }, [s3Key]);

  return presignedUrl;
};
export default usePresignedUrl;
