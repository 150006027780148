import {
  Box,
  Stack,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  Tooltip,
  IconButton,
} from "@mui/material";
import AdornedInput from "../../../../AdornedInput";
import { toTwoDecimals } from "../../../calculations/utils";

const PanelCalculations = ({
  calculations: calcs,
  overrides,
  handleChange,
  resetOverride,
}) => {
  return (
    <>
      {calcs && (
        <Stack direction="column" spacing={2}>
          <Typography>Material & Labor</Typography>
          <Stack direction="column" spacing={2}>
            <Stack direction="row" spacing={2}>
              <AdornedInput
                type="number"
                onWheel={(event) => {
                  event.preventDefault();
                }}
                onFocus={(e) => e.target.select()}
                label="Coil Size Needed"
                value={calcs.coilSizeNeeded}
                onChange={handleChange("coil_size_needed")}
                isOveridden={overrides?.coil_size_needed}
                handleReset={resetOverride("coil_size_needed")}
                inputProps={{ readOnly: true }}
                disabled
              />
              <AdornedInput
                type="number"
                onWheel={(event) => {
                  event.preventDefault();
                }}
                onFocus={(e) => e.target.select()}
                label="Master Coil Size Needed"
                value={calcs.masterCoilSizeNeeded}
                onChange={handleChange("master_coil_size_needed")}
                isOveridden={overrides?.master_coil_size_needed}
                handleReset={resetOverride("master_coil_size_needed")}
              />
              <AdornedInput
                type="number"
                onWheel={(event) => {
                  event.preventDefault();
                }}
                onFocus={(e) => e.target.select()}
                label="Coil Drop/Excess"
                value={calcs.coilDrop}
                onChange={handleChange("coil_drop")}
                isOveridden={overrides?.coil_drop}
                handleReset={resetOverride("coil_drop")}
                inputProps={{ readOnly: true }}
                disabled
              />
              <AdornedInput
                type="number"
                onWheel={(event) => {
                  event.preventDefault();
                }}
                onFocus={(e) => e.target.select()}
                label="Coils Cut From Master"
                value={calcs.coilsCutFromMaster}
                onChange={handleChange("coils_cut_from_master")}
                isOveridden={overrides?.coils_cut_from_master}
                handleReset={resetOverride("coils_cut_from_master")}
                inputProps={{ readOnly: true }}
                disabled
              />
            </Stack>
            <Stack direction="row" spacing={2}>
              <AdornedInput
                type="number"
                onWheel={(event) => {
                  event.preventDefault();
                }}
                onFocus={(e) => e.target.select()}
                label="Waste Rate"
                value={calcs.waste}
                onChange={handleChange("waste")}
                isOveridden={overrides?.waste}
                handleReset={resetOverride("waste")}
              />
              <AdornedInput
                type="number"
                onWheel={(event) => {
                  event.preventDefault();
                }}
                onFocus={(e) => e.target.select()}
                label="Coil LF (no waste)"
                value={calcs.linealFeetOfCoilNoWaste}
                onChange={handleChange("lineal_feet_of_coil_no_waste")}
                isOveridden={overrides?.lineal_feet_of_coil_no_waste}
                handleReset={resetOverride("lineal_feet_of_coil_no_waste")}
              />
              <AdornedInput
                type="number"
                onWheel={(event) => {
                  event.preventDefault();
                }}
                onFocus={(e) => e.target.select()}
                label="Coil LF (with waste)"
                value={calcs.linealFeetOfCoilWithWaste}
                onChange={handleChange("lineal_feet_of_coil_with_waste")}
                isOveridden={overrides?.lineal_feet_of_coil_with_waste}
                handleReset={resetOverride("lineal_feet_of_coil_with_waste")}
              />
              <AdornedInput
                type="number"
                onWheel={(event) => {
                  event.preventDefault();
                }}
                onFocus={(e) => e.target.select()}
                label="Coil Sq Ft (with waste)"
                value={calcs.squareFeetOfCoilNeededWithWaste}
                onChange={handleChange("square_feet_of_coil_needed_with_waste")}
                isOveridden={overrides?.square_feet_of_coil_needed_with_waste}
                handleReset={resetOverride(
                  "square_feet_of_coil_needed_with_waste"
                )}
              />
            </Stack>
            <Stack direction="row" spacing={2}>
              <AdornedInput
                type="number"
                onWheel={(event) => {
                  event.preventDefault();
                }}
                onFocus={(e) => e.target.select()}
                label="Finished Panel Sq. Ft."
                value={calcs.finishedPanelSquareFeet}
                onChange={handleChange("finished_panel_square_feet")}
                isOveridden={overrides?.finished_panel_square_feet}
                handleReset={resetOverride("finished_panel_square_feet")}
              />
            </Stack>
          </Stack>

          <Typography>Cost Breakdown</Typography>
          <Stack direction="row" spacing={2}>
            <AdornedInput
              type="number"
              onWheel={(event) => {
                event.preventDefault();
              }}
              onFocus={(e) => e.target.select()}
              label="Total Coil Cost"
              value={calcs.materialCost}
              symbol="$"
              position="start"
              onChange={handleChange("material_cost")}
              isOveridden={overrides?.material_cost}
              handleReset={resetOverride("material_cost")}
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            <AdornedInput
              type="number"
              onWheel={(event) => {
                event.preventDefault();
              }}
              onFocus={(e) => e.target.select()}
              label="Est. Lumber Cost"
              value={calcs.estimatedLumberCost}
              symbol="$"
              position="start"
              onChange={handleChange("estimated_lumber_cost")}
              isOveridden={overrides?.estimated_lumber_cost}
              handleReset={resetOverride("estimated_lumber_cost")}
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            <AdornedInput
              type="number"
              onWheel={(event) => {
                event.preventDefault();
              }}
              onFocus={(e) => e.target.select()}
              label="Est. Man Hours"
              value={calcs.estimatedLaborHours}
              onChange={handleChange("total_estimated_labor_hours")}
              isOveridden={overrides?.total_estimated_labor_hours}
              handleReset={resetOverride("total_estimated_labor_hours")}
            />
            <AdornedInput
              type="number"
              onWheel={(event) => {
                event.preventDefault();
              }}
              onFocus={(e) => e.target.select()}
              label="Est. Labor Cost"
              value={calcs?.estimatedLaborCost}
              onChange={(e) => handleChange("total_estimated_labor_cost")(e)}
              isOveridden={overrides?.total_estimated_labor_cost}
              handleReset={(e) =>
                resetOverride("total_estimated_labor_cost")(e)
              }
              symbol="$"
              position="start"
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            <AdornedInput
              type="number"
              onWheel={(event) => {
                event.preventDefault();
              }}
              onFocus={(e) => e.target.select()}
              label="Total Hard Cost"
              value={calcs.totalHardCost}
              symbol="$"
              position="start"
              onChange={handleChange("total_hard_cost")}
              isOveridden={overrides?.total_hard_cost}
              handleReset={resetOverride("total_hard_cost")}
            />
          </Stack>

          <Stack direction="row" spacing={2}>
            <AdornedInput
              type="number"
              onWheel={(event) => {
                event.preventDefault();
              }}
              onFocus={(e) => e.target.select()}
              label="GP %"
              value={
                calcs?.grossProfitMargin
                  ? toTwoDecimals(calcs?.grossProfitMargin * 100.0)
                  : 0
              }
              onChange={(e) => {
                e.target.value = parseFloat(
                  parseFloat(e.target.value).toFixed(4) / 100.0
                ).toFixed(4);
                handleChange("gross_profit_margin")(e);
              }}
              isOveridden={overrides?.gross_profit_margin}
              handleReset={resetOverride("gross_profit_margin")}
              symbol="%"
              position="start"
            />

            <AdornedInput
              type="number"
              onWheel={(event) => {
                event.preventDefault();
              }}
              onFocus={(e) => e.target.select()}
              label="Gross Profit"
              value={calcs.grossProfitDollars}
              symbol="$"
              position="start"
              onChange={handleChange("gross_profit_dollars")}
              isOveridden={overrides?.gross_profit_dollars}
              handleReset={resetOverride("gross_profit_dollars")}
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            <AdornedInput
              type="number"
              onWheel={(event) => {
                event.preventDefault();
              }}
              onFocus={(e) => e.target.select()}
              label="Total Sell Price"
              value={calcs.totalSellPrice}
              symbol="$"
              position="start"
              onChange={handleChange("total_sell_price")}
              isOveridden={overrides?.total_sell_price}
              handleReset={resetOverride("total_sell_price")}
            />
            <AdornedInput
              type="number"
              onWheel={(event) => {
                event.preventDefault();
              }}
              onFocus={(e) => e.target.select()}
              label="Panel Price/Sq. Ft."
              value={calcs.panelPricePerSquareFoot}
              symbol="$"
              position="start"
              onChange={handleChange("panel_price_per_square_foot")}
              isOveridden={overrides?.panel_price_per_square_foot}
              handleReset={resetOverride("panel_price_per_square_foot")}
            />
          </Stack>
        </Stack>
      )}
    </>
  );
};
export default PanelCalculations;
