import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { orderTotals } from "../components/NewDetail/calculations/orderTotals";
import moment from "moment/moment";
import { toTwoDecimals } from "../components/NewDetail/calculations/utils";

const lineItemInit = {
  serviceDate: moment().format("MM/DD/YYYY"),
  service: "",
  description: "",
  qty: 0,
  rate: 0,
  amount: 0,
  isTaxed: false,
  qbItem: null,
};

const useEditInvoice = (orderId) => {
  const { authToken, ezorder } = useContext(AuthContext);
  const [newLineItem, setNewLineItem] = useState(lineItemInit);
  const [serviceOptions, setServiceOptions] = useState([]);

  const [isSubmitting, setIssubmitting] = useState(false);

  const handleNewLineItemChange = (key) => (e) => {
    const value = key === "isTaxed" ? e.target.checked : e.target.value;

    if (key === "service") {
      setNewLineItem((state) => ({
        ...state,
        [key]: value.Name,
        qbItem: value,
      }));
    }
    setNewLineItem((state) => ({ ...state, [key]: value }));
  };

  const [lineItems, setLineItems] = useState([]);

  const addLineItem = () => {
    setLineItems((state) => [...state, newLineItem]);
    setNewLineItem(lineItemInit);
  };

  const authHeader = {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  };
  const getProductsAndServices = async () => {
    try {
      const response = await ezorder.get(
        `/quickbooks/invoice/products-services`,
        authHeader
      );
      const items = response.data.items;
      // console.log("QB ITEMS: ");
      // console.log(items);
      setServiceOptions(items);
    } catch (error) {
      console.log(error);
    }
  };

  const submitInvoice = async (requestBody) => {
    console.log(requestBody);
    try {
      setIssubmitting(true);
      const response = await ezorder.post(
        "/quickbooks/invoice",
        requestBody,
        authHeader
      );
      console.log(response);
      //navigate(`/orders/${order.id}`);
      window.location.reload(false);
    } catch (error) {
      console.log(error);
    } finally {
      setIssubmitting(false);
    }
  };

  const removeLineItem = (index) => {
    const lines = [...lineItems];
    lines.splice(index, 1);
    setLineItems([...lines]);
  };

  const skidChargeDescription = (skidCharge) => {
    return `PALLET CHARGE: $${toTwoDecimals(skidCharge)}`;
  };

  const flatSheetDescription = (flatSheet) => {
    return `FLAT SHEET: ${flatSheet.quantity} pieces @ ${flatSheet.width}"`;
  };

  const coilDescription = (coil) => {
    return `COIL: ${coil.linealFeet} lineal feet @ ${coil.width}"`;
  };

  const panelDescription = (panel) => {
    const cutsDescription = panel.cuts.reduce(
      (desc, curr) =>
        desc +
        `\n-${curr.name}: ${curr.quantity} @ ${curr.feet}' ${curr.inches}"`,
      ""
    );
    return `PANEL CUTLIST ${panel.panelSystem}, ${panel.panelType}, ${panel.panelWidth}"${cutsDescription}`;
  };
  const trimDescription = (trim) => {
    return trim.trimPieces.reduce(
      (desc, curr) =>
        desc +
        `- ${curr.name}: ${curr.quantity} @ ${curr.hits} hits, ${curr.stretchOut}" stretchout `,
      "Trim & Flashing: "
    );
  };
  const copingDescription = (coping) => {
    return coping.capPieces.reduce(
      (desc, curr) =>
        desc +
        `- ${curr.name}: ${curr.quantity} @ ${curr.hits} hits, ${curr.stretchOut}" stretchout `,
      "Coping Cap: "
    );
  };
  const cleatDescription = (cleat) => {
    return cleat.cleatPieces.reduce(
      (desc, curr) =>
        desc +
        `- ${curr.name}: ${curr.quantity} @ ${curr.hits} hits, ${curr.stretchOut}" stretchout `,
      "Coping Cap Cleat: "
    );
  };
  const spliceDescription = (splice) => {
    return splice.plates.reduce(
      (desc, curr) =>
        desc +
        `- ${curr.quantity} @ ${curr.hits} hits, ${curr.stretchOut}" stretchout, ${curr.length}" length `,
      "Splice Plate: "
    );
  };
  const benchDescription = (bench) => {
    return bench.benchWorkPieces.reduce(
      (desc, curr) =>
        desc +
        `- ${curr.name}: ${curr.quantity} @ ${curr.flatSheets} Flat Sheets, ${curr.notes} `,
      "Benchwork: "
    );
  };
  const accessoryDescription = (accessory) => {
    return accessory.items.reduce(
      (desc, curr) =>
        desc + `- ${curr.name}: ${curr.quantity} @ $${curr.price} `,
      "Misc.: "
    );
  };
  const prefillLineItem = (order) => {
    let description = "";
    let qty = 1;
    const {
      outTheDoorCost,
      totalMaterialCost,
      totalLaborCost,
      totalLaborHours,
    } = orderTotals(order.items);
    let rate = outTheDoorCost; // out-the-door;
    let amount = rate * qty; //out-the-door * qty;

    if (order.skidCharge && order.skidCharge > 0) {
      description += skidChargeDescription(order.skidCharge);
    }

    for (const item of order.items) {
      const type = item.objectType;
      switch (type) {
        case "FlatSheet":
          if (item.quantity > 0) {
            if (description.length > 0)
              description += "\n\n" + flatSheetDescription(item);
            else description += flatSheetDescription(item);
          }
          break;
        case "Coil":
          if (item.linealFeet > 0) {
            if (description.length > 0)
              description += "\n\n" + coilDescription(item);
            else description += coilDescription(item);
          }
          break;
        case "TrimAndFlashing": {
          if (item.trimPieces.length > 0) {
            if (description.length > 0)
              description += "\n\n" + trimDescription(item);
            else description += trimDescription(item);
          }
          break;
        }
        case "CopingCap": {
          if (item.capPieces.length > 0) {
            if (description.length > 0)
              description += "\n\n" + copingDescription(item);
            else description += copingDescription(item);
          }
          break;
        }
        case "CopingCapCleat": {
          if (item.cleatPieces.length > 0) {
            if (description.length > 0)
              description += "\n\n" + cleatDescription(item);
            else description += cleatDescription(item);
          }
          break;
        }
        case "SplicePlate": {
          if (item.plates.length > 0) {
            if (description.length > 0)
              description += "\n\n" + spliceDescription(item);
            else description += spliceDescription(item);
          }
          break;
        }
        case "BenchWork": {
          console.log(item);
          if (item.benchWorkPieces.length > 0) {
            if (description.length > 0)
              description += "\n\n" + benchDescription(item);
            else description += benchDescription(item);
          }
          break;
        }
        case "Panel": {
          if (item.cuts.length > 0) {
            if (description.length > 0)
              description += "\n\n" + panelDescription(item);
            else description += panelDescription(item);
          }
          break;
        }
        case "Accessory": {
          console.log(item);
          if (item.items.length > 0) {
            if (description.length > 0)
              description += "\n\n" + accessoryDescription(item);
            else description += accessoryDescription(item);
          }
          break;
        }
        default:
          break;
      }
    }
    console.log(description, qty, rate, amount);
    setNewLineItem((init) => ({
      ...init,
      description: description,
      rate: toTwoDecimals(rate),
      qty: qty,
      amount: toTwoDecimals(amount),
    }));
  };

  const getOrderById = async (id) => {
    try {
      const response = await ezorder.get(`/admin/orders/${id}`, authHeader);
      prefillLineItem(response.data.order);
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  useEffect(() => {
    // prefillLineItem(order);
    getOrderById(orderId);
    getProductsAndServices();
  }, [orderId]);

  return {
    lineItems,
    addLineItem,
    newLineItem,
    handleNewLineItemChange,
    serviceOptions,
    submitInvoice,
    removeLineItem,
    isSubmitting,
  };
};
export default useEditInvoice;
