import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";

const Accessory = (props) => {
  const {
    accessory,
    showTotal,
    showPricePerPiece = false,
    noCalcs = false,
  } = props;
  if (!accessory) return null;
  let accessoriesTotalSellPrice = 0;
  for (const accessoryItem of accessory.items) {
    if (accessoryItem.calculations && accessoryItem.calculations.totalSellPrice)
      accessoriesTotalSellPrice += parseFloat(
        accessoryItem.calculations.totalSellPrice
      );
  }
  return (
    <Box {...props}>
      {showTotal && !noCalcs ? (
        <Typography variant="h5">
          Accessories / MISC - ${Math.round(accessoriesTotalSellPrice)}
        </Typography>
      ) : (
        <Typography variant="h5">Accessories / MISC</Typography>
      )}
      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="right">Quantity</TableCell>
              <TableCell align="right">Unit Of Measure</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {accessory.items.map((item, index) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {item.name}
                </TableCell>
                <TableCell align="right">{item.quantity}</TableCell>
                <TableCell align="right">{item.unitOfMeasure}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default Accessory;
