const order = {
  skidCharge: 150,
  customerRequestedCompletionDate: null,
  isUnconfirmedCutlist: false,
  type: "MOBILE", // It used to be MOBILE OR GUEST. Guest is a non-user
  poNumber: "",
  notes: "",
  tag: "",
  orderStatus: null,
  // manufacturer: "CMG",
  // gauge: "24",
  // color: "Aged Copper",
  flatSheetSize: 48,
  flatSheetCost: 0,
  flatSheetCostOriginal: 0,
  flatSheetLength: 120,
  isCustomMaterial: false,
  outTheDoorCost: 0,
  isCalculated: false,
  qbCustomerId: null,
  qbSubCustomerId: null,
  totalFlatSheetsNeeded: 0,
  totalLinealFeetNeeded: 0,
  company: {
    name: "",
    createdAt: "",
    qbCustomerId: null,
    owner: {
      fullName: "",
      email: "",
      role: "OWNER",
      createdAt: "",
    },
  },
  taxExemptForm: {
    name: "",
    file: "",
    createdAt: "",
    formType: "",
    expiration_date: null,
  },
  project: {
    name: "",
  },
  items: [
    {
      objectType: "FlatSheet",
      quantity: 0,
      width: 48,
    },
    {
      objectType: "Coil",
      linealFeet: 0,
      width: 48,
    },
    {
      totalCost: 0,
      objectType: "Panel",
      panelSystem: "Standing Seam",
      panelType: "SL-175, Striated With Clip Relief",
      panelTakeUp: null,
      panelWidth: 18,
      coilSquareFeet: null,
      coilSquareFeetWithDrop: null,
      panelMasterCoilWidth: null,
      totalPanelAreaCoverage: null,
      panelProfile: "",
      cuts: [],
    },
    {
      totalCost: 0,
      objectType: "TrimAndFlashing",
      trimPieces: [],
    },
    {
      totalCost: 0,
      objectType: "BenchWork",
      benchWorkPieces: [],
    },
    {
      totalCost: 0,
      objectType: "CopingCap",
      capPieces: [],
    },
    {
      totalCost: 0,
      objectType: "CopingCapCleat",
      gauge: "",
      color: null,
      cleatPieces: [],
    },
    {
      totalCost: 0,
      objectType: "SplicePlate",
      plates: [],
    },
    {
      totalCost: 0,
      objectType: "Accessory",
      items: [],
    },
  ],
};
export default order;
