import View from "./View";
import { useState, useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ezorder from "../../../api/ezorderNoAuth";
import jwt_decode from "jwt-decode";

const ResetPassword = () => {
  const navigate = useNavigate();
  const { userId, token } = useParams();
  const [email, setEmail] = useState("");
  const [resetSuccess, setResetSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const resetPassword = async (userId, token, password, confirmPassword) => {
    try {
      setErrorMessage("");
      setLoading(true);
      let requestBody = { userId, token, password, confirmPassword };
      const response = await ezorder.put(`/auth/reset-password`, requestBody);

      //TODO: display a success message
      console.log(response.data);
      setResetSuccess(true);
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        if (error.response.data?.error) {
          setErrorMessage(error.response.data.error);
        } else {
          setErrorMessage("Internal server error");
        }
      } else {
        setErrorMessage("Internal server error");
      }
    } finally {
      setLoading(false);
    }
  };

  const resetPasswordHandler = async (resetPasswordRequest) => {
    setResetSuccess(false);
    console.log("resetPasswordRequest", resetPasswordRequest);
    await resetPassword(
      userId,
      token,
      resetPasswordRequest.password,
      resetPasswordRequest.confirmPassword
    );
  };

  useEffect(() => {
    if (token) {
      var decoded = jwt_decode(token);
      setEmail(decoded.email);
    }
  }, [token]);

  return (
    <>
      <View
        email={email}
        resetPasswordHandler={resetPasswordHandler}
        resetSuccess={resetSuccess}
        loading={loading}
        errorMessage={errorMessage}
      />
    </>
  );
};
export default ResetPassword;
