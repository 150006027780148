const combineReducers = (...reducers) => {
  // Return a new reducer function

  return (state, action) => {
    // Apply each reducer function to the state
    return reducers.reduce((accState, reducer) => {
      return reducer(accState, action);
    }, state);
  };
};
export default combineReducers;
