import { AuthContext } from "../context/AuthContext";
import { useState, useContext, useEffect } from "react";

const useAccessoriesCatalog = () => {
  const { authToken, ezorder } = useContext(AuthContext);
  const [catalog, setCatalog] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // const getAccessories = async () => {
  //   try {
  //     setLoading(true);
  //     const res = await ezorder.get(`/app/accessories`);
  //     console.log("useAccessories", res.data);
  //     setAccessories(res.data.accessories);
  //   } catch (error) {
  //     console.log("useAccessories Error:", error);
  //     setError(error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  const getAccessoriesCatalog = async () => {
    try {
      setLoading(true);
      const response = await ezorder.get(`/app/accessories`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      const items = response.data.accessories;
      setCatalog([...items]);
      return [...items];
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    getAccessoriesCatalog(controller);
    return () => {
      controller.abort();
    };
  }, []);

  return [catalog, loading, error];
};
export default useAccessoriesCatalog;
