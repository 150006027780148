import { useState, useContext, useEffect } from "react";
import TaxFormListUI from "./View";
import { AuthContext } from "../../../../context/AuthContext";
import usePageName from "../../../../hocs/usePageName";
import AddTaxFormModal from "../AddTaxForm/AddTaxFormModal";

const TaxFormList = () => {
  usePageName({ heading: "Tax Exempt Forms" });
  const { authToken, ezorder } = useContext(AuthContext);
  const headers = {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  };
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [taxForms, setTaxForms] = useState([]);

  const [openTaxFormModal, setOpenTaxFormModal] = useState(false);

  const getTaxExemptForms = async () => {
    try {
      setLoading(true);
      const response = await ezorder.get(`/tax-forms`, headers);
      setTaxForms(response.data.taxForms);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const setAsDefault = async (taxExemptFormId) => {
    try {
      setLoading(true);

      const response = await ezorder.patch(
        `/tax-forms/default`,
        { taxExemptFormId: taxExemptFormId },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      getTaxExemptForms();
    } catch (error) {
      console.log("Failed to get forms");
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleOpen = () => {
    setOpenTaxFormModal(true);
  };
  const handleClose = () => {
    getTaxExemptForms();
    setOpenTaxFormModal(false);
  };

  useEffect(() => {
    getTaxExemptForms();
  }, []);

  return (
    <>
      <TaxFormListUI
        taxForms={taxForms}
        setAsDefault={setAsDefault}
        loading={loading}
        error={error}
        handleOpen={handleOpen}
      />
      <AddTaxFormModal
        open={openTaxFormModal}
        handleOpen={handleOpen}
        handleClose={handleClose}
      />
    </>
  );
};
export default TaxFormList;
