import { Button } from "@mui/material";
import { useCallback, useState } from "react";
import Cropper from "react-easy-crop";
import getCroppedImg from "./Crop";
import { useEffect } from "react";

const ImageCropper = ({ image, onSave, onClear, cropShape }) => {
  const CONTAINER_HEIGHT = 500;
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const save = async () => {
    try {
      const croppedImage = await getCroppedImg(
        image,
        croppedAreaPixels,
        rotation
      );
      onSave(croppedImage.file, croppedImage.url);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div>
      <div
        style={{
          position: "relative",
          width: "100%",
          aspectRatio: 1 / 1,
        }}
      >
        <Cropper
          image={image}
          cropShape={cropShape ? cropShape : "rect"} // rect | round
          crop={crop}
          rotation={rotation}
          zoom={zoom}
          zoomSpeed={1}
          minZoom={1}
          maxZoom={100}
          zoomWithScroll={true}
          showGrid={true}
          aspect={4 / 4}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
          // onRotationChange={setRotation} // disable rotation
          // onMediaLoaded={(mediaSize) => {
          //   console.log("Media Size", mediaSize);
          //   // Adapt zoom based on media size to fit max height
          //   // setZoom(CONTAINER_HEIGHT / mediaSize.naturalHeight);
          // }}
          objectFit="contain"
          // objectFit="vertical-cover"
        />
      </div>

      <Button onClick={save}>Crop</Button>
      <Button onClick={onClear}>Clear</Button>
    </div>
  );
};

export default ImageCropper;
