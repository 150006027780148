import {
  Typography,
  Box,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
  MenuItem,
  Avatar,
  Select,
  InputLabel,
} from "@mui/material";
import { useState, useEffect, useContext } from "react";
import { MobileOrderContext } from "../../../../../context/MobileOrderContext";
import { AuthContext } from "../../../../../context/AuthContext";
import TextFieldDebounced from "../../../../../components/TextFieldDebounced";
import usePaintManufacturers from "../../../../../hooks/usePaintManufacturers";
import useAvailableColorsByManufacturer from "../../../../../hooks/useAvailableColorsByManufacturer";

const ColorDetails = ({
  canUpdate,
  vendorProjectName,
  subProject,
  poNumber,
  notes,
  color,
  manufacturer,
  gauge,
  isCustomMaterial,
  customColor,
}) => {
  const { orderDispatch } = useContext(MobileOrderContext);
  const { decoded } = useContext(AuthContext);
  const [selectedColor, setSelectedColor] = useState(null);
  const { paintManufacturers } = usePaintManufacturers();

  const { colors, getColors } = useAvailableColorsByManufacturer();

  const handleManufacturerChange = (e) => {
    handleDetailChange("manufacturer", paintManufacturers[e.target.value].name);
    setSelectedColor(null);
    handleDetailChange("color", null);
    handleDetailChange("gauge", null);
    // getColors(paintManufacturers[e.target.value].id);
  };

  const handleColorChange = (e) => {
    // e.target.value is the INDEX of the color
    setSelectedColor(colors[e.target.value]);
    handleDetailChange("color", colors[e.target.value].name);
    handleDetailChange(
      "gauge",
      colors[e.target.value].gauges[0].gauge.toFixed(4)
    );
  };

  const handleDetailChange = (key, value) => {
    orderDispatch({ type: key, payload: value });
  };

  // Change Gauge Options based on Color Selection
  useEffect(() => {
    if (colors.length > 0 && color) {
      // manufacture is required to select a color
      for (const c of colors) {
        if (c.name + c.paintManufacturer.name === color + manufacturer) {
          setSelectedColor(c);
        }
      }
    }
  }, [colors, color]);

  // Load Color List When Manufacturers are loaded
  useEffect(() => {
    if (paintManufacturers) {
      let initialPaintManufacturerIndex = paintManufacturers
        .map((manufacturer) => manufacturer.name)
        .indexOf(manufacturer);
      let initialPaintManufacturer =
        paintManufacturers[initialPaintManufacturerIndex];
      console.log("INITIAL PAINT MANUFACTURER", initialPaintManufacturer);
      if (initialPaintManufacturer) getColors(initialPaintManufacturer.id);
    }
  }, [paintManufacturers, manufacturer]);

  // if (colors.length == 0 || !color || !manufacturer) return null;

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
          {decoded.isVendorOrSupplier && (
            <TextFieldDebounced
              size="small"
              disabled={!canUpdate}
              label="Project Name"
              value={vendorProjectName}
              onChange={(e) =>
                handleDetailChange("vendorProjectName", e.target.value)
              }
            />
          )}

          <TextFieldDebounced
            size="small"
            disabled={!canUpdate}
            label="Sub-Project Name ie Bldg #, etc)"
            value={subProject ? subProject : ""}
            onChange={(e) => handleDetailChange("subProject", e.target.value)}
          />

          <TextFieldDebounced
            size="small"
            disabled={!canUpdate}
            label="PO # (Optional)"
            value={poNumber ? poNumber : ""}
            onChange={(e) => handleDetailChange("poNumber", e.target.value)}
          />
          <TextFieldDebounced
            size="small"
            disabled={!canUpdate}
            label="Notes (Optional)"
            multiline
            maxRows={3}
            value={notes ? notes : ""}
            onChange={(e) => handleDetailChange("notes", e.target.value)}
          />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          {isCustomMaterial ? (
            <TextFieldDebounced
              size="small"
              disabled={!canUpdate}
              label="Custom Material"
              value={customColor ? customColor : ""}
              onChange={(e) =>
                handleDetailChange("customColor", e.target.value)
              }
            />
          ) : (
            <>
              <TextField
                sx={{}}
                size="small"
                fullWidth
                select
                disabled={!canUpdate}
                label="Manufacturer"
                value={paintManufacturers
                  .map((m) => m.name)
                  .indexOf(manufacturer)}
                onChange={handleManufacturerChange}
              >
                {paintManufacturers.map((manufacturer, index) => (
                  <MenuItem key={manufacturer.id} value={index}>
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      {manufacturer.name}
                    </Box>
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                sx={{ marginTop: "16px" }}
                size="small"
                fullWidth
                select
                disabled={!canUpdate}
                label="Color"
                value={colors
                  .map((c) => c.name + c.paintManufacturer.name)
                  .indexOf(color + manufacturer)}
                onChange={handleColorChange}
              >
                {colors.map((theColor, index) => (
                  <MenuItem key={theColor.id} value={index}>
                    <Box
                      key={theColor.id}
                      value={index}
                      sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <Avatar
                        src={theColor.image}
                        sx={{
                          width: 24,
                          height: 24,
                          marginRight: "1rem",
                        }}
                      />
                      {theColor.name} - {theColor.paintManufacturer.name}{" "}
                      {theColor.status == "BACKORDER"
                        ? `- [${theColor.status}]`
                        : ""}
                    </Box>
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                sx={{ marginTop: "16px" }}
                size="small"
                fullWidth
                disabled={!color || !canUpdate}
                select
                label="Gauge"
                value={parseFloat(parseFloat(gauge).toFixed(4)).toString()}
                onChange={(e) => handleDetailChange("gauge", e.target.value)}
              >
                {selectedColor?.gauges.map((theGauge, index) => (
                  <MenuItem
                    key={theGauge.id}
                    value={parseFloat(
                      parseFloat(theGauge.gauge).toFixed(4)
                    ).toString()}
                  >
                    {parseFloat(
                      parseFloat(theGauge.gauge).toFixed(4)
                    ).toString()}
                  </MenuItem>
                ))}
              </TextField>
            </>
          )}

          <FormGroup
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <FormControlLabel
              disabled={!canUpdate}
              control={
                <Checkbox
                  checked={isCustomMaterial}
                  value={isCustomMaterial ? isCustomMaterial : false}
                  onChange={(e) =>
                    handleDetailChange("isCustomMaterial", e.target.checked)
                  }
                />
              }
              label="Color/Material Not Listed"
            />
          </FormGroup>
        </Box>
      </Box>
    </>
  );
};
export default ColorDetails;
