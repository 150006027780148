const detailReducer = (state, action) => {
  switch (action.type) {
    case "SET ORDER STATE": {
      console.log("SET ORDER STATE", action);
      return { ...state, ...action.payload };
      // if (action.payload && !action.payload.calculations) {
      //   return { ...state, ...action.payload, calculations: {} };
      // } else {
      //   return { ...state, ...action.payload };
      // }
    }
    case "UPDATE DETAILS: ADMIN ESTIMATE APPROVAL": {
      const { date, fileUrl } = action.payload;
      console.log(date, fileUrl);
      return {
        ...state,
        estimateApprovalDate: date,
        signedEstimatePDF: fileUrl,
        status: "ESTIMATE_APPROVED",
      };
    }

    case "UPDATE DETAILS: COLOR": {
      return {
        ...state,
        color: action.payload.color,
        manufacturer: action.payload.manufacturer,
        gauge: action.payload.gauge.toString(),
      };
    }

    case "UPDATE DETAILS: FLAT SHEET OPTIONS": {
      return {
        ...state,
        flatSheetSize: action.payload.flatSheetSize,
        flatSheetCost: action.payload.flatSheetCost,
      };
    }

    // case "UPDATE DETAILS: MANUFACTURER": {
    //   return { ...state,
    //     manufacturer: action.payload.manufacturer,
    //     gauge: action.payload.gauge.toString()
    //   };
    // }
    // case "UPDATE DETAILS: THICKNESS": {
    //   console.log(action.payload);
    //   return { ...state, gauge: action.payload.toString() };
    // }
    case "UPDATE DETAILS: IS CUSTOM MATERIAL": {
      return { ...state, isCustomMaterial: action.payload };
    }
    case "UPDATE DETAILS: CUSTOM COLOR": {
      return { ...state, customColor: action.payload };
    }
    case "UPDATE DETAILS: CUSTOM MANUFACTURER": {
      return { ...state, customManufacturer: action.payload };
    }
    case "UPDATE DETAILS: CUSTOM GAUGE": {
      return { ...state, customGauge: action.payload };
    }
    case "UPDATE DETAILS: COMPANY": {
      //TODO: When creating a new order, the company object should be set BEFORE getting to the new-order page.
      //      This means creating a "Company Selection" process before creating orders?
    }
    case "UPDATE DETAILS: PROJECT": {
      //TODO: The same as above? Admin should select a company --> then a project?
    }
    case "UPDATE DETAILS: VENDOR PROJECT NAME": {
      return { ...state, vendorProjectName: action.payload };
    }
    case "UPDATE DETAILS: SUB PROJECT NAME": {
      return { ...state, subProject: action.payload };
    }
    case "UPDATE DETAILS: PO#": {
      return { ...state, poNumber: action.payload };
    }
    case "UPDATE DETAILS: NOTES": {
      return { ...state, notes: action.payload };
    }
    case "UPDATE DETAILS: ESTIMATE REQUESTED BY": {
      return {
        ...state,
        estimateRequestedBy: action.payload,
        estimateAssignedApprover: null,
      };
    }
    case "UPDATE DETAILS: ESTIMATE ASSIGNED APPROVER": {
      return { ...state, estimateAssignedApprover: action.payload };
    }

    case "UPDATE DETAILS: TAX EXEMPT FORM": {
      return { ...state, taxExemptForm: action.payload };
    }

    case "UPDATE DETAILS: QB CUSTOMER ID": {
      //paymentTermId
      console.log(action.payload);
      return {
        ...state,
        qbCustomerId: action.payload.customerId,
        paymentTermId: action.payload.salesTermId,
      };
    }
    default:
      return state;
  }
};
export default detailReducer;
