import { useState, useContext, useEffect } from "react";
import usePageName from "../../../../hocs/usePageName";
import { AuthContext } from "../../../../context/AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import useTrim from "../../../../hooks/useTrim";
import useTrimOrderHistory from "../../../../hooks/useTrimOrderHistory";
import { Card, Typography, Box, Grid, Button } from "@mui/material";
import S3PresignedImage from "../../../../components/CustomerComponents/S3/S3PresignedImage";
import moment from "moment";
import { SnackAlertContext } from "../../../../context/SnackAlertContext";
import { LoadingButton } from "@mui/lab";
import EditTrimDialog from "../../../../components/Catalog/EditTrimDialog";
import createPresignedUrl from "../../../../helper/createPresignedUrl";
import uploadImage from "../../../../helper/uploadImage";
import { Delete } from "@mui/icons-material";
import DeleteTrimDialog from "../../../../components/Catalog/DeleteTrimDialog";

const TrimDetail = () => {
  usePageName({ heading: "Trim Details" });
  const navigate = useNavigate();
  const { authToken, ezorder, role, decoded } = useContext(AuthContext);
  const { id } = useParams();
  const [isUpdating, setIsUpdating] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const [trim, getTrim, deleteTrim, loading, error] = useTrim(authToken, id);
  const { orderHistory } = useTrimOrderHistory(
    authToken,
    decoded.companyId,
    id
  );

  const [editTrimDialogOpen, setEditTrimDialogOpen] = useState(false);

  const { openSnackMessage } = useContext(SnackAlertContext);
  const orderSelectHandler = (projectId, orderId, orderStatus) => {
    if (
      orderStatus.name == "SAVED" ||
      orderStatus.name == "QUOTE_REQUESTED" ||
      orderStatus.name == "ESTIMATE_APPROVAL_NEEDED"
    ) {
      navigate(`/quotes/${orderId}`);
    } else {
      navigate(`/orders/${orderId}`);
    }
  };

  const updateIsCustomerStockTrim = async (saveToCatalog) => {
    try {
      setIsUpdating(true);
      const response = await ezorder.patch(
        `/products/trims/${id}/save-to-catalog`,
        { saveToCatalog },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      getTrim();
    } catch (error) {
      console.log(error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage("error", error.response.data.error);
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", error);
      }
    } finally {
      setIsUpdating(false);
    }
  };

  const onUpdate = async (
    name,
    imageBlob,
    saveToCatalog,
    masterHits,
    masterStretchOut,
    savedImage
  ) => {
    let key = savedImage;
    if (!savedImage) {
      // Create Presigned URL
      let actionType = "WRITE";
      let fileName = imageBlob.name;
      let contentType = imageBlob.type;
      let resource = "trims";
      let identifier = decoded.companyId;
      const presignedUrlResponse = await createPresignedUrl(
        ezorder,
        authToken,
        actionType,
        fileName,
        contentType,
        resource,
        identifier
      );
      console.log("presignedUrlResponse", presignedUrlResponse);

      // Upload URL
      await uploadImage(presignedUrlResponse.uploadUrl, imageBlob);

      key = presignedUrlResponse.key;
    }
    await updateTrim(name, key);
  };

  const updateTrim = async (name, image) => {
    let update = {
      name,
      image,
    };

    const response = await ezorder.put(`/products/trims/${trim.id}`, update, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    getTrim();
  };

  if (trim)
    return (
      <>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            // gap: "1rem",
          }}
        >
          <div style={{ padding: "24px" }}>
            <Typography variant="h6">{trim.name}</Typography>
            <S3PresignedImage s3Key={trim.image} />
            {!trim.isMasterCatalog && trim.catalog && !trim.deletedAt && (
              <LoadingButton
                loading={loading || isUpdating}
                onClick={() => {
                  updateIsCustomerStockTrim(false);
                }}
              >
                Remove From Stock Catalog
              </LoadingButton>
            )}
            {!trim.isMasterCatalog && !trim.catalog && !trim.deletedAt && (
              <LoadingButton
                loading={loading || isUpdating}
                onClick={() => {
                  updateIsCustomerStockTrim(true);
                }}
              >
                Add To Stock Catalog
              </LoadingButton>
            )}
            {trim && !trim.isMasterCatalog && !trim.deletedAt && (
              <Box>
                <EditTrimDialog
                  trim={trim}
                  // catalogCompanyName={decoded.companyName}
                  // enableSaveToCatalog={!trim.isMasterCatalog}
                  open={editTrimDialogOpen}
                  setOpen={setEditTrimDialogOpen}
                  onSubmit={(
                    name,
                    imageBlob,
                    saveToCatalog,
                    masterHits,
                    masterStretchOut,
                    savedImage // original saved image
                  ) => {
                    onUpdate(
                      name,
                      imageBlob,
                      saveToCatalog,
                      masterHits,
                      masterStretchOut,
                      savedImage
                    );
                    console.log("UPDATE", {
                      name,
                      imageBlob,
                      saveToCatalog,
                      masterHits,
                      masterStretchOut,
                      savedImage, // original saved image
                    });
                  }}
                  isMasterCatalogItem={trim.isMasterCatalog}
                />
                <LoadingButton
                  loading={loading || isUpdating}
                  onClick={() => {
                    setEditTrimDialogOpen(true);
                  }}
                >
                  Edit Trim Details
                </LoadingButton>
              </Box>
            )}
            {trim && !trim.isMasterCatalog && !trim.deletedAt && (
              <Box>
                <DeleteTrimDialog
                  open={deleteDialogOpen}
                  handleClose={() => {
                    setDeleteDialogOpen(false);
                  }}
                  onConfirm={deleteTrim}
                  loading={loading}
                />

                <LoadingButton
                  loading={loading || isUpdating}
                  color="error"
                  onClick={() => {
                    setDeleteDialogOpen(true);
                  }}
                >
                  <Delete color="error" />
                  Delete
                </LoadingButton>
              </Box>
            )}
          </div>
          <div>
            {orderHistory &&
              orderHistory?.map((order, index) => (
                <div
                  onClick={() => {
                    orderSelectHandler(
                      order.project.id,
                      order.id,
                      order.orderStatus
                    );
                  }}
                >
                  {/* <UsedAtJobCard
                key={index}
                date={order.createdAt}
                project={order.project.name}
                orderId={order.id}
                poNumber={order.poNumber}
              /> */}
                  <Card style={{ marginTop: 8 }}>
                    <div style={{ padding: 10 }}>
                      {moment(order.createdAt).format("MM/DD/YYYY")} - Order #
                      {order.id}
                    </div>
                    <div style={{ padding: 10 }}>
                      Project {order.project.name}
                    </div>
                    <div style={{ padding: 10 }}>PO: {order.poNumber}</div>
                  </Card>
                </div>
              ))}
          </div>
        </Box>
      </>
    );
};
export default TrimDetail;
