import { Box, Button, Stack, Typography, useMediaQuery } from "@mui/material";
import Divider from "@mui/material/Divider";
import {
  orderTotals,
  orderItemTotals,
} from "../../../calculations/orderTotals";
import Totals from "./Sections/Totals";
import PanelSummary from "./Sections/PanelSummary";
import TrimSummary from "./Sections/TrimSummary";
import CopingCapSummary from "./Sections/CopingCapSummary";
import CleatSummary from "./Sections/CleatSummary";
import SplicePlateSummary from "./Sections/SplicePlateSummary";
import BenchWorkSummary from "./Sections/BenchWorkSummary";
import AccessoriesSummary from "./Sections/AccessoriesSummary";
import FlatSheetSummary from "./Sections/FlatSheetSummary";
import CoilSummary from "./Sections/CoilSummary";
import Print from "../../../../Print";
import { useTheme } from "@emotion/react";
import OrderTitle from "../../../../OrderTitle";

const Summary = ({ order }) => {
  const theme = useTheme();

  order?.items.forEach((element) => {
    console.log(getPieces(element));
  });
  const totals = orderTotals(order?.items, order?.skidCharge);
  const itemTotals = order?.items
    .filter((item) => {
      const pieces = getPieces(item);
      return pieces && pieces.length > 0;
    })
    .map((item) => ({
      ...orderItemTotals(item),
      name: camelCaseToSentenceCase(item.objectType),
    }));
  console.log("itemTotalsitemTotalsitemTotalsitemTotals", itemTotals);

  return (
    <>
      <Print>
        <Print.Trigger
          style={{
            display: useMediaQuery(theme.breakpoints.down("sm"))
              ? "none"
              : "flex",
            flexDirection: "row",
            justifyContent: "end",
            marginTop: "5px",
          }}
        >
          <Button variant="contained">Print</Button>
        </Print.Trigger>
        <Stack
          direction={"column"}
          divider={<Divider orientation="horizontal" flexItem />}
          spacing={1}
        >
          <Stack direction={"column"}>
            <Totals totals={totals} skidCharge={order?.skidCharge} />
            <FlatSheetSummary order={order} />
            <CoilSummary order={order} />
            <PanelSummary order={order} />
            <TrimSummary order={order} />
            <CopingCapSummary order={order} />
            <CleatSummary order={order} />
            <SplicePlateSummary order={order} />
            <BenchWorkSummary order={order} />
            <AccessoriesSummary order={order} />
          </Stack>
        </Stack>
        <Print.PrintableData>
          <div>
            <div style={{ marginLeft: "56px" }}>
              <OrderTitle order={order} textColor={"#000"} />
            </div>
            <div
              style={{
                columns: 2,
                marginLeft: "32px",
                marginRight: "32px",
                marginBottom: "32px",
              }}
            >
              <div
                style={{
                  breakInside: "avoid",
                  breakBefore: "always",
                  breakAfter: "always",
                }}
              >
                <Totals
                  totals={totals}
                  skidCharge={order?.skidCharge}
                  isPrinting={true}
                />
              </div>
              <div
                style={{
                  breakInside: "avoid",
                  breakBefore: "always",
                  breakAfter: "always",
                }}
              >
                <FlatSheetSummary order={order} isPrinting={true} />
              </div>
              <div
                style={{
                  breakInside: "avoid",
                  breakBefore: "always",
                  breakAfter: "always",
                }}
              >
                <CoilSummary order={order} isPrinting={true} />
              </div>
              <div
                style={{
                  breakInside: "avoid",
                  breakBefore: "always",
                  breakAfter: "always",
                }}
              >
                <PanelSummary order={order} isPrinting={true} />
              </div>
              <div
                style={{
                  breakInside: "avoid",
                  breakBefore: "always",
                  breakAfter: "always",
                }}
              >
                <TrimSummary order={order} isPrinting={true} />
              </div>
              <div
                style={{
                  breakInside: "avoid",
                  breakBefore: "always",
                  breakAfter: "always",
                }}
              >
                <CopingCapSummary order={order} isPrinting={true} />
              </div>
              <div
                style={{
                  breakInside: "avoid",
                  breakBefore: "always",
                  breakAfter: "always",
                }}
              >
                <CleatSummary order={order} />
              </div>
              <div
                style={{
                  breakInside: "avoid",
                  breakBefore: "always",
                  breakAfter: "always",
                }}
              >
                <SplicePlateSummary order={order} isPrinting={true} />
              </div>
              <div
                style={{
                  breakInside: "avoid",
                  breakBefore: "always",
                  breakAfter: "always",
                }}
              >
                <BenchWorkSummary order={order} isPrinting={true} />
              </div>
              <div
                style={{
                  breakInside: "avoid",
                  breakBefore: "always",
                  breakAfter: "always",
                }}
              >
                <AccessoriesSummary order={order} isPrinting={true} />
              </div>
            </div>
          </div>
        </Print.PrintableData>
      </Print>
    </>
  );
};
export default Summary;

function getPieces(orderItem) {
  switch (orderItem.objectType) {
    case "TrimAndFlashing":
      return orderItem.trimPieces;
    case "CopingCap":
      return orderItem.capPieces;
    case "CopingCapCleat":
      return orderItem.cleatPieces;
    case "SplicePlate":
      return orderItem.plates;
    case "BenchWork":
      return orderItem.benchWorkPieces;
    case "Accessories":
      return orderItem.items;
    case "Panel":
      return orderItem.cuts;
    default:
      break;
  }
}

// function that takes a CamelCase string and returns a string with spaces
// between each word
function camelCaseToSentenceCase(str) {
  return str
    .replace(/([A-Z])/g, " $1")
    .replace(/^./, function (str) {
      return str.toUpperCase();
    })
    .trim();
}

export function getPiecses(trimType) {}
