import {
  Box,
  Stack,
  TextField,
  Typography,
  InputAdornment,
  FormControl,
  InputLabel,
  Button,
  OutlinedInput,
  Divider,
  IconButton,
  Tooltip,
  Modal,
  Card,
  CardActionArea,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  MenuItem,
  CardHeader,
  Checkbox,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import { useTheme } from "@emotion/react";
import { Add, Close, Delete, Save, Title } from "@mui/icons-material";
import FabButton from "../../CustomerComponents/FabButton";
import ColorOptionModal from "./ColorOptionModal";
import { SnackAlertContext } from "../../../context/SnackAlertContext";
import DeleteColorDialog from "./DeleteColorDialog";

const ColorDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { openSnackMessage } = useContext(SnackAlertContext);
  const [colorDetails, setColorDetails] = useState(null);
  const [colorModalVisible, setColorModalVisible] = useState(false);
  const { authToken, ezorder } = useContext(AuthContext);
  const theme = useTheme();
  const [deleteColorDialogOpen, setDeleteColorDialogOpen] = useState(false);

  const getColorById = async (id) => {
    try {
      setLoading(true);
      let data = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      const response = await ezorder.get(`/app/colors/${id}`, data);
      setColorDetails(response.data.color);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const deleteColorById = async (id) => {
    try {
      setLoading(true);
      let data = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      const response = await ezorder.delete(`/admin/app/colors/${id}`, data);
      openSnackMessage("success", "Color Deleted");
      navigate("/settings");
    } catch (error) {
      console.log(error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage("error", error.response.data.error);
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", error);
      }
    } finally {
      setLoading(false);
    }
  };

  const saveColor = async () => {
    try {
      setLoading(true);
      let data = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      const response = await ezorder.post(
        `/admin/app/colors`,
        colorDetails,
        data
      );
      setColorDetails(response.data.color);
      openSnackMessage("success", "Saved");
    } catch (error) {
      console.log(error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage("error", error.response.data.error);
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", error);
      }
    } finally {
      setLoading(false);
    }
  };

  const onDeleteOption = (gaugeIndex, coilWidthIndex) => {
    let gaugesCopy = [...colorDetails.gauges];
    let coilWidthsCopy = gaugesCopy[gaugeIndex].coilWidths;
    coilWidthsCopy.splice(coilWidthIndex, 1);

    // If Coil Widths for Gauge is empty, delete the gauge too
    if (coilWidthsCopy.length == 0) {
      gaugesCopy.splice(gaugeIndex, 1);
    }

    let update = {
      ...colorDetails,
      gauges: gaugesCopy,
    };
    setColorDetails(update);
  };

  const onPricePerFsChange = (gaugeIndex, coilWidthIndex, price) => {
    let gaugesCopy = [...colorDetails.gauges];
    let coilWidthsCopy = gaugesCopy[gaugeIndex].coilWidths;
    coilWidthsCopy[coilWidthIndex].price = price;

    let update = {
      ...colorDetails,
      gauges: gaugesCopy,
    };
    setColorDetails(update);
  };

  const onStatusChange = (status) => {
    let update = {
      ...colorDetails,
      status: status,
    };
    setColorDetails(update);
  };

  const onPricePerSqFtChange = (gaugeIndex, coilWidthIndex, pricePerSqFt) => {
    let gaugesCopy = [...colorDetails.gauges];
    let coilWidthsCopy = gaugesCopy[gaugeIndex].coilWidths;
    coilWidthsCopy[coilWidthIndex].pricePerSqFt = pricePerSqFt;

    let update = {
      ...colorDetails,
      gauges: gaugesCopy,
    };
    setColorDetails(update);
  };

  const onFlatSheetAvailableChange = (
    gaugeIndex,
    coilWidthIndex,
    flatAvailable
  ) => {
    let gaugesCopy = [...colorDetails.gauges];
    let coilWidthsCopy = gaugesCopy[gaugeIndex].coilWidths;
    coilWidthsCopy[coilWidthIndex].flatAvailable = flatAvailable;

    let update = {
      ...colorDetails,
      gauges: gaugesCopy,
    };
    setColorDetails(update);
  };

  const onCoilAvailableChange = (gaugeIndex, coilWidthIndex, coilAvailable) => {
    let gaugesCopy = [...colorDetails.gauges];
    let coilWidthsCopy = gaugesCopy[gaugeIndex].coilWidths;
    coilWidthsCopy[coilWidthIndex].coilAvailable = coilAvailable;

    let update = {
      ...colorDetails,
      gauges: gaugesCopy,
    };
    setColorDetails(update);
  };

  useEffect(() => {
    if (id) {
      getColorById(id);
    }
  }, [id]);

  if (!colorDetails) return null;
  return (
    <>
      <Box display={"flex"} flexDirection={"column"} gap={"5px"}>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Stack sx={{ flex: 1 }} direction="row" spacing={2}>
            <TextField
              disabled
              variant="outlined"
              label="Manufacturer"
              value={colorDetails.paintManufacturer.name}
            />
            <TextField
              variant="outlined"
              label="Color"
              value={colorDetails.name}
              onChange={(e) => {
                setColorDetails((prev) => ({ ...prev, name: e.target.value }));
              }}
            />

            <TextField
              sx={{ width: "150px" }}
              select
              label="Status"
              value={colorDetails.status}
              onChange={(e) => {
                onStatusChange(e.target.value);
              }}
            >
              <MenuItem value={"AVAILABLE"}>AVAILABLE</MenuItem>
              <MenuItem value={"BACKORDER"}>BACKORDER</MenuItem>
              <MenuItem value={"UNAVAILABLE"}>UNAVAILABLE</MenuItem>
            </TextField>
          </Stack>
          <Button
            color="error"
            onClick={() => {
              setDeleteColorDialogOpen(true);
            }}
          >
            Delete
          </Button>
          <DeleteColorDialog
            open={deleteColorDialogOpen}
            handleClose={() => {
              setDeleteColorDialogOpen(false);
            }}
            onConfirm={() => {
              deleteColorById(id);
            }}
            loading={loading}
          />
        </Box>

        <Box sx={{}}>
          {colorDetails.gauges.map((gauge, gaugeIndex) => {
            return (
              <Card
                key={gauge.gauge}
                sx={{ marginTop: "20px", padding: "8px" }}
              >
                <Typography variant="h5">
                  {gauge.gauge} {gauge.unitOfMeasure}
                </Typography>
                <Box sx={{ paddingLeft: "32px", paddingRight: "32px" }}>
                  <TableContainer component={Box}>
                    <Table aria-label="collapsible table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Width</TableCell>
                          <TableCell colSpan={2}>Flat Sheets</TableCell>
                          <TableCell colSpan={2}>Coil</TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {gauge.coilWidths.map((coilWidth, coilWidthIndex) => (
                          <TableRow key={coilWidthIndex}>
                            <TableCell>
                              <TextField
                                // disabled={!checked}
                                size="small"
                                variant="standard"
                                label="Coil Width (in.)"
                                value={coilWidth.width}
                                InputProps={{
                                  readOnly: true,
                                  disableUnderline: true,
                                }}
                              />
                            </TableCell>
                            <TableCell
                              colSpan={2}
                              // sx={{
                              //   display: "flex",
                              //   // flexDirection: "row",
                              //   alignItems: "center",
                              // }}
                            >
                              <Checkbox
                                disabled={
                                  !coilWidth.price || coilWidth.price <= 0
                                }
                                style={{
                                  margin: 0,
                                  paddingTop: "8px",
                                }}
                                checked={
                                  coilWidth.price &&
                                  coilWidth.price > 0 &&
                                  coilWidth.flatAvailable
                                }
                                onChange={(e) => {
                                  onFlatSheetAvailableChange(
                                    gaugeIndex,
                                    coilWidthIndex,
                                    e.target.checked
                                  );
                                }}
                              />
                              <TextField
                                // disabled={!checked}
                                onFocus={(e) => e.target.select()}
                                size="small"
                                type="number"
                                onWheel={(event) => {
                                  event.preventDefault();
                                }}
                                label="Price Per Flat Sheet"
                                value={coilWidth.price}
                                onChange={(e) =>
                                  onPricePerFsChange(
                                    gaugeIndex,
                                    coilWidthIndex,
                                    e.target.value
                                  )
                                }
                              />
                            </TableCell>
                            <TableCell
                              colSpan={2}
                              // sx={{
                              //   display: "flex",
                              //   flexDirection: "row",
                              //   alignItems: "center",
                              // }}
                            >
                              <Checkbox
                                disabled={
                                  !coilWidth.pricePerSqFt ||
                                  coilWidth.pricePerSqFt <= 0
                                }
                                style={{
                                  margin: 0,
                                  paddingTop: "8px",
                                }}
                                checked={
                                  coilWidth.pricePerSqFt &&
                                  coilWidth.pricePerSqFt > 0 &&
                                  coilWidth.coilAvailable
                                }
                                onChange={(e) => {
                                  onCoilAvailableChange(
                                    gaugeIndex,
                                    coilWidthIndex,
                                    e.target.checked
                                  );
                                }}
                              />
                              <TextField
                                // disabled={!checked}
                                onFocus={(e) => e.target.select()}
                                size="small"
                                type="number"
                                onWheel={(event) => {
                                  event.preventDefault();
                                }}
                                label="Price Per Sq Ft of Coil"
                                value={coilWidth.pricePerSqFt}
                                onChange={(e) => {
                                  console.log(e.target.value);

                                  onPricePerSqFtChange(
                                    gaugeIndex,
                                    coilWidthIndex,
                                    e.target.value
                                  );
                                }}
                              />
                            </TableCell>
                            <TableCell>
                              <IconButton
                                color="primary"
                                onClick={() => {
                                  onDeleteOption(gaugeIndex, coilWidthIndex);
                                }}
                              >
                                <Close />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Card>
            );
          })}
        </Box>

        <FabButton disabled={loading} color="primary" onClick={saveColor}>
          <Save sx={{ mr: 1 }} />
          Save Changes
        </FabButton>
        <Button
          variant="outlined"
          onClick={() => {
            setColorModalVisible(true);
          }}
        >
          <Add />
          Add Option
        </Button>
        <ColorOptionModal
          color={colorDetails}
          updateColor={setColorDetails}
          visible={colorModalVisible}
          setVisible={setColorModalVisible}
        />
      </Box>
    </>
  );
};
export default ColorDetails;
