import { ThemeProvider } from "@emotion/react";
import { createTheme, CssBaseline, responsiveFontSizes } from "@mui/material";
import ErrorBoundary from "./pages/error/ErrorBoundary";
import ApplicationRoutes from "./Routes/ApplicationRoutes";
import { SnackAlertProvider } from "./context/SnackAlertContext";

const App = () => {
  let appTheme = createTheme({
    palette: {
      mode: "dark",
      primary: {
        main: "#C6885D",
        dark: "#8a5f41",
        light: "#d19f7d",
        contrastText: "#141414",
      },
      secondary: {
        main: "#231F20", //black
        dark: "#181516",
        light: "#4f4b4c",
      },
      neutral: {
        main: "#fffff2",
      },
    },
  });
  appTheme = responsiveFontSizes(appTheme);

  // PREVENT MOUSE SCROLL ON NUMBER INPUT
  document.addEventListener("wheel", function (event) {
    if (document.activeElement.type === "number") {
      document.activeElement.blur();
    }
  });

  return (
    <div
      style={{ flex: 1, width: "100%", height: "100%", margin: 0, padding: 0 }}
    >
      <ThemeProvider theme={appTheme}>
        <CssBaseline />
        <ErrorBoundary>
          <SnackAlertProvider>
            <ApplicationRoutes />
          </SnackAlertProvider>
        </ErrorBoundary>
      </ThemeProvider>
    </div>
  );
};

export default App;
