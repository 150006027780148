import { useTheme } from "@emotion/react";
import { LoadingButton } from "@mui/lab";
import {
  Paper,
  Typography,
  Box,
  TextField,
  Switch,
  Stack,
  Button,
  InputAdornment,
} from "@mui/material";
import { useEffect, useState } from "react";

const EditGalvanizedFlats = ({
  galvanizedFlatOptions,
  saveGalvanizedFlat,
  isLoading,
}) => {
  console.log("galvanizedFlatOptions", galvanizedFlatOptions);
  const [galvanizedFlats, setGalvanizedFlats] = useState([]);
  const theme = useTheme();

  useEffect(() => {
    setGalvanizedFlats(galvanizedFlatOptions);
  }, [galvanizedFlatOptions]);

  if (!galvanizedFlats) {
    return (
      <Paper>
        <Typography>Loading...</Typography>
      </Paper>
    );
  }

  return (
    <Paper sx={{ mt: "30px" }}>
      <Typography ml="20px" variant="h6">
        Galvanized Cleat Gauges & Pricing
      </Typography>
      <Box sx={{ display: "flex", flexDirection: "column", margin: "20px" }}>
        {galvanizedFlats.map((galvFlat, index) => (
          <Box
            key={index}
            sx={{ display: "flex", flexDirection: "row", mb: "10px" }}
          >
            <TextField
              variant="outlined"
              label="Item Name"
              size="small"
              InputProps={{
                readOnly: true,
                disableUnderline: true,
              }}
              value={`${galvFlat.gauge} ${galvFlat.unitOfMeasure}`}
            />
            <TextField
              variant="outlined"
              label="Price Per 4x10 FS"
              size="small"
              type="number"
              onWheel={(event) => {
                event.preventDefault();
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Typography style={{ color: theme.palette.grey["500"] }}>
                      $
                    </Typography>
                  </InputAdornment>
                ),
              }}
              value={galvFlat.price}
              onChange={(e) => {
                let galvanizedFlatsCopy = [...galvanizedFlats];
                galvanizedFlatsCopy[index] = {
                  ...galvanizedFlatsCopy[index],
                  price: e.target.value,
                };
                setGalvanizedFlats(galvanizedFlatsCopy);
              }}
            />

            <LoadingButton
              loading={isLoading}
              variant="outlined"
              sx={{ marginLeft: "50px" }}
              onClick={() => saveGalvanizedFlat(galvFlat)}
            >
              Save Changes
            </LoadingButton>
          </Box>
        ))}
      </Box>
    </Paper>
  );
};
export default EditGalvanizedFlats;
