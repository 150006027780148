import { useState, useContext, useEffect } from "react";
import usePageName from "../../../hocs/usePageName";
import { AuthContext } from "../../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import useTrimCatalog from "../../../hooks/useTrimCatalog";
import { Box, Typography } from "@mui/material";
import PrintCatalog from "../../../components/Catalog/PrintCatalog";
import useTrimCatalogAdmin from "../../../hooks/useTrimCatalogAdmin";

const PrintMasterTrimCatalog = () => {
  //   const { id } = useParams();
  const navigate = useNavigate();
  const { authToken, role, decoded } = useContext(AuthContext);
  const [
    searchQuery,
    setSearchQuery,
    catalogFilter,
    setCatalogFilter,
    results,
    onRefresh,
    refreshing,
    onLoadMore,
    loadingMore,
    total,
    hasMore,
  ] = useTrimCatalogAdmin(authToken, 2000, null, "master");

  return (
    <Box sx={{ heght: "100%" }}>
      <Typography variant="h4" component="div">
        Print Master Catalog
      </Typography>
      <PrintCatalog items={results} />
    </Box>
  );
};
export default PrintMasterTrimCatalog;
