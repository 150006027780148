import {
  getCoilDetails,
  getPanelDetails,
} from "../components/NewDetail/orderUtils";
import {
  toThreeDecimals,
  toTwoDecimals,
} from "../components/NewDetail/calculations/utils";
import moment from "moment/moment";
import {
  getAccessoryTotals,
  getBenchWorkTotals,
  getCleatTotals,
  getCopingCapTotals,
  getSplicePlateTotals,
  getTrimTotals,
  orderTotals,
} from "../components/NewDetail/calculations/orderTotals";
const SECTION_MARGIN = 12;
const FULL_WIDTH = 515;
const COLUMN_GAP = 20;
const HALF_COLUMN_WITH_GAP = (FULL_WIDTH - COLUMN_GAP) / 2;
const ONE_THIRD_COLUMN_WITH_GAP = (FULL_WIDTH - COLUMN_GAP) / 3;

const HEADER = (isMaterialAndLaborSummary) => {
  return [
    {
      columns: [
        {
          image: "logo",
          width: 120,
        },
        {
          text: isMaterialAndLaborSummary ? "Material & Labor" : "Work Order",
          style: "header",
        },
      ],
      margin: [0, 0, 0, 8], // margin: [left, top, right, bottom]
    },
  ];
};

const LINE_TOP = () => {
  return [
    {
      canvas: [{ type: "line", x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 1 }],
    },
  ];
};

const LINE_BELOW = () => {
  return [
    {
      canvas: [{ type: "line", x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 1 }],
      margin: [0, 4, 0, 0], // margin: [left, top, right, bottom]
    },
  ];
};

const formatDate = (date) => {
  const yyyy = date.getFullYear();
  let mm = date.getMonth() + 1; // Months start at 0!
  let dd = date.getDate();

  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;

  const formattedDate = mm + "/" + dd + "/" + yyyy;
  return formattedDate;
};

const QUADRANT = (cell1, cell2, cell3, cell4) => {
  return {
    layout: "noBorders", // optional
    table: {
      // headers are automatically repeated if the table spans over multiple pages
      // you can declare how many rows should be treated as headers
      widths: [HALF_COLUMN_WITH_GAP - 1, HALF_COLUMN_WITH_GAP - 1],
      dontBreakRows: true,
      body: [
        [cell1, cell2],
        [
          {
            margin: [0, 24, 0, 0],
            text: "", // Spacers
          },
          {
            margin: [0, 24, 0, 0],
            text: "", // Spacers
          },
        ],
        [cell3, cell4],
      ],
    },
  };
};

const EMPTY_TRIM_IMAGE = () => {
  return [
    {
      margin: [0, 8, 0, 0],
      canvas: [
        {
          type: "rect",
          x: 0,
          y: 0,
          w: 250,
          h: 250,
          lineWidth: 1,
          lineColor: "#000000",
        },
      ],
    },
    {
      text: "",
      style: "imageText",
      margin: [0, 8, 0, 0],
    },
    {
      text: "",
      style: "imageText",
      margin: [0, 8, 0, 0],
    },
  ];
};

const JOB_DETAILS = (order) => {
  const {
    id,
    createdAt,
    poNumber,
    gauge,
    color,
    manufacturer,
    outTheDoorCost,
    company,
    project,
    notes,
    taxExemptForm,
    type,
    isMaterialSuppliedByCustomer,
    isCustomMaterial,
    customColor,
    customManufacturer,
    customGauge,
  } = order;

  let jobName;
  let customerName;
  let contactName;
  let contactEmail;
  let contactPhone;

  // GUEST VS MOBILE

  jobName = project.name;
  customerName = company.name;
  console.log("ORDER CONTACT", order.orderContact);

  // SET CONTACT INFO
  if (order.orderContact) {
    contactName = order.orderContact.name;
    contactPhone = order.orderContact.phone;
    contactEmail = order.orderContact.email;
  } else {
    let requestedBy = order.estimateRequestedBy;
    contactName =
      requestedBy && requestedBy.fullName
        ? requestedBy.fullName
        : company.contactName;
    contactPhone =
      requestedBy && requestedBy.phone
        ? requestedBy.phone
        : company.contactPhone;
    contactEmail =
      requestedBy && requestedBy.email
        ? requestedBy.email
        : company.contactEmail;
  }

  // Custom Manufacturer vs Regular
  let colorValue;
  let manufacturerValue;
  let gaugeValue;
  if (isCustomMaterial) {
    colorValue = customColor ? customColor : "";
    manufacturerValue = customManufacturer ? customManufacturer : "";
    colorValue = colorValue + " - " + manufacturerValue;
    gaugeValue = customGauge ? customGauge : "";
  } else {
    colorValue = color + " - " + manufacturer;
    manufacturerValue = manufacturer;

    if (gauge < 1) {
      gaugeValue = `${parseFloat(gauge)} in.`;
    } else {
      gaugeValue = `${parseFloat(gauge)} g`;
    }
  }

  return [
    {
      text: `INDUSTRY JOB #${order.id}`,
      style: "subheader",
    },
    ...LINE_TOP(),
    {
      layout: "noBorders", // optional
      table: {
        // headers are automatically repeated if the table spans over multiple pages
        // you can declare how many rows should be treated as headers
        headerRows: 1,
        widths: ["*", "*", "*", "*"],

        body: [
          [
            { text: "DATE APPROVED", style: "detailName" },
            { text: "DATE NEEDED BY", style: "detailName" },
            { text: "DUE DATE", style: "detailName" },
            { text: "MATERIAL SOURCE:", style: "detailName" },
          ],
          [
            {
              text: `${
                order.estimateApprovalDate
                  ? moment(order.estimateApprovalDate).format("MM/DD/YY")
                  : ""
              }`,
              style: "detailValue",
            },
            {
              text: `${
                order.customerRequestedCompletionDate
                  ? moment(order.customerRequestedCompletionDate).format(
                      "MM/DD/YY"
                    )
                  : "N/A"
              }`,
              style: "detailValue",
            },
            {
              text: `${
                order.shopEstimateCompletionDate
                  ? moment(order.shopEstimateCompletionDate).format("MM/DD/YY")
                  : "TBD"
              }`,
              style: "detailValue",
            },
            {
              text: isMaterialSuppliedByCustomer
                ? `Customer Supplied`
                : `Supplied by Industry`,
              style: "detailValue",
            },
          ],
        ],
      },
      margin: [0, 4, 0, 0], // margin: [left, top, right, bottom]
    },
    {
      layout: "noBorders", // optional
      table: {
        // headers are automatically repeated if the table spans over multiple pages
        // you can declare how many rows should be treated as headers
        headerRows: 1,
        widths: ["*", "*", "*", "*"],
        body: [
          [
            { text: "CUSTOMER NAME:", style: "detailName" },
            { text: "CONTACT NAME:", style: "detailName" },
            { text: "CELL PHONE:", style: "detailName" },
            { text: "EMAIL:", style: "detailName" },
          ],
          [
            { text: `${customerName}`, style: "detailValue" },
            { text: `${contactName}`, style: "detailValue" },
            { text: `${contactPhone}`, style: "detailValue" },
            { text: `${contactEmail}`, style: "detailValue" },
          ],
        ],
      },
      margin: [0, 4, 0, 0], // margin: [left, top, right, bottom]
    },
    {
      layout: "noBorders", // optional
      table: {
        // headers are automatically repeated if the table spans over multiple pages
        // you can declare how many rows should be treated as headers
        headerRows: 1,
        widths: ["*", "*", "*", "*"],

        body: [
          [
            { text: "PO #:", style: "detailName" },
            { text: "JOB NAME:", style: "detailName" },
            { text: "GAUGE:", style: "detailName" },
            { text: "COLOR:", style: "detailName" },
          ],
          [
            { text: `${order.poNumber}`, style: "detailValue" },
            { text: `${jobName}`, style: "detailValue" },
            { text: `${gaugeValue}`, style: "detailValue" },
            { text: `${colorValue}`, style: "detailValue" },
          ],
        ],
      },
      margin: [0, 4, 0, 0], // margin: [left, top, right, bottom]
    },

    ...LINE_BELOW(),
  ];
};

const ACCESSORIES = (accessoriesOrderItem) => {
  if (!accessoriesOrderItem) {
    return [];
  }

  const accessories = [];
  for (let i = 0; i < accessoriesOrderItem.items.length; i++) {
    let item = accessoriesOrderItem.items[i];
    accessories.push([
      { text: `${item.name}`, style: "tableCell" },
      { text: `${item.quantity}`, style: "tableCell" },
      { text: `${item.unitOfMeasure}`, style: "tableCell" },
    ]);
  }

  return [
    {
      text: `ACCESSORIES / MISC`,
      style: "subheader",
      margin: [0, SECTION_MARGIN, 0, 0], // margin: [left, top, right, bottom]
    },
    {
      table: {
        // headers are automatically repeated if the table spans over multiple pages
        // you can declare how many rows should be treated as headers
        headerRows: 1,
        widths: ["*", "*", "*"],

        body: [
          [
            {
              text: "DESCRIPTION",
              style: "tableHeader",
              fillColor: "#D3D3D3",
            },
            { text: "QTY", style: "tableHeader", fillColor: "#D3D3D3" },
            { text: "UOM", style: "tableHeader", fillColor: "#D3D3D3" },
          ],
          ...accessories,
        ],
      },
      margin: [0, 4, 0, 0], // margin: [left, top, right, bottom]
    },
  ];
};

const MATERIAL_SUMMARY = (flatTotals, panelOrderItem, coilOrderItem) => {
  let extraCoil =
    coilOrderItem && coilOrderItem.linealFeet ? coilOrderItem.linealFeet : 0;
  return [
    {
      text: `MATERIAL SUMMARY`,
      style: "subheader",
      margin: [0, SECTION_MARGIN, 0, 0], // margin: [left, top, right, bottom]
    },
    {
      table: {
        // headers are automatically repeated if the table spans over multiple pages
        // you can declare how many rows should be treated as headers
        headerRows: 1,
        widths: ["*", 120, 50],

        body: [
          [
            {
              text: `Flats Needed To Complete Order + Extra FS`,
              style: "tableTotalLeft",
            },
            {
              text: `${flatTotals.flatsNeeded}`,
              style: "tableTotal",
            },
            { text: `FS`, style: "tableTotal" },
          ],
          [
            {
              text: `Standalone Flat Sheets - not including what's needed for requested parts`,
              style: "tableTotalLeft",
            },
            {
              text: `${flatTotals.standaloneFlats}`,
              style: "tableTotal",
            },
            { text: `FS`, style: "tableTotal" },
          ],
          [
            {
              text: `Total Galvanized Flats for Cleat`,
              style: "tableTotalLeft",
            },
            {
              text: `${flatTotals.galvFlatsNeeded}`,
              style: "tableCell",
            },
            { text: `FS`, style: "tableCell" },
          ],
          [
            {
              text: `Total Lineal Footage of Coil Needed to Complete Order`,
              style: "tableTotalLeft",
            },
            {
              text: `${parseFloat(
                panelOrderItem &&
                  panelOrderItem.calculations &&
                  panelOrderItem.calculations.linealFeetOfCoilWithWaste
                  ? panelOrderItem.calculations.linealFeetOfCoilWithWaste
                  : 0
              )}`,
              style: "tableTotal",
            },
            { text: `LF`, style: "tableTotal" },
          ],
          [
            {
              text: `Standalone Coil Lineal Footage - not including what's needed for panels`,
              style: "tableTotalLeft",
            },
            {
              text: `${parseFloat(extraCoil)}`,
              style: "tableTotal",
            },
            { text: `LF`, style: "tableTotal" },
          ],
          [
            {
              text: `Coil Width Needed to Create Panels`,
              style: "tableTotalLeft",
            },
            {
              text: `${
                panelOrderItem &&
                panelOrderItem.calculations &&
                panelOrderItem.calculations.masterCoilSizeNeeded
                  ? panelOrderItem.calculations.masterCoilSizeNeeded
                  : 0
              }`,
              style: "tableCell",
            },
            { text: `in`, style: "tableCell" },
          ],
          [
            { text: `Total Coil Square Footage`, style: "tableTotalLeft" },
            {
              text: `${
                panelOrderItem &&
                panelOrderItem.calculations &&
                panelOrderItem.calculations.finishedPanelSquareFeet
                  ? panelOrderItem.calculations.finishedPanelSquareFeet
                  : 0
              }`,
              style: "tableCell",
            },
            { text: `SF`, style: "tableCell" },
          ],
          [
            {
              text: `Total Coil Square Footage inc Drop`,
              style: "tableTotalLeft",
            },
            {
              text: `${
                panelOrderItem &&
                panelOrderItem.calculations &&
                panelOrderItem.calculations.squareFeetOfCoilNeededWithWaste
                  ? panelOrderItem.calculations.squareFeetOfCoilNeededWithWaste
                  : 0
              }`,
              style: "tableCell",
            },
            { text: `SF`, style: "tableCell" },
          ],
          [
            { text: `Total Panel Area Coverage`, style: "tableTotalLeft" },
            {
              text: `${toThreeDecimals(
                (panelOrderItem &&
                panelOrderItem.calculations &&
                panelOrderItem.calculations.linealFeetOfCoilNoWaste &&
                panelOrderItem.panelWidth
                  ? parseFloat(
                      panelOrderItem.calculations.linealFeetOfCoilNoWaste
                    ) * parseFloat(panelOrderItem.panelWidth)
                  : 0) / 12
              )}`,
              style: "tableCell",
            },
            { text: `SF`, style: "tableCell" },
          ],
        ],
      },
      margin: [0, 4, 0, 0], // margin: [left, top, right, bottom]
    },
  ];
};

const LABOR_SUMMARY = (order, productMap) => {
  let totals = orderTotals(order.items, order.skidCharge);
  let panelTotals = null;
  let trimTotals = null;
  let benchWorkTotals = null;
  let copingCapTotals = null;
  let cleatTotals = null;
  let splicePlateTotals = null;
  let accessoryTotals = null;

  let itemHours = [];

  let flatSheet = productMap.get("FlatSheet");
  let coil = productMap.get("Coil");

  let panel = productMap.get("Panel");
  if (panel) {
    itemHours = [
      ...itemHours,
      [
        {
          text: `Panel Hours`,
          style: "tableTotal",
        },
        {
          text: `${toTwoDecimals(panel?.calculations?.estimatedLaborHours)}`,
          style: "tableTotal",
        },
      ],
    ];
  }

  let trimAndFlashing = productMap.get("TrimAndFlashing");
  if (trimAndFlashing) {
    trimTotals = getTrimTotals(trimAndFlashing);
    itemHours = [
      ...itemHours,
      [
        {
          text: `Trim & Flashing Hours`,
          style: "tableTotal",
        },
        {
          text: `${toTwoDecimals(trimTotals.totalLaborHours)}`,
          style: "tableTotal",
        },
      ],
    ];
  }
  let copingCap = productMap.get("CopingCap");
  if (copingCap) {
    copingCapTotals = getCopingCapTotals(copingCap);

    itemHours = [
      ...itemHours,
      [
        {
          text: `Coping Cap Hours`,
          style: "tableTotal",
        },
        {
          text: `${toTwoDecimals(copingCapTotals.totalLaborHours)}`,
          style: "tableTotal",
        },
      ],
    ];
  }
  let cleat = productMap.get("CopingCapCleat");
  if (cleat) {
    cleatTotals = getCleatTotals(cleat);
    itemHours = [
      ...itemHours,
      [
        {
          text: `Cleat Hours`,
          style: "tableTotal",
        },
        {
          text: `${toTwoDecimals(cleatTotals.totalLaborHours)}`,
          style: "tableTotal",
        },
      ],
    ];
  }
  let benchwork = productMap.get("BenchWork");
  if (benchwork) {
    benchWorkTotals = getBenchWorkTotals(benchwork);
    itemHours = [
      ...itemHours,
      [
        {
          text: `Bench Work Hours`,
          style: "tableTotal",
        },
        {
          text: `${toTwoDecimals(benchWorkTotals.totalLaborHours)}`,
          style: "tableTotal",
        },
      ],
    ];
  }
  let splicePlate = productMap.get("SplicePlate");
  if (splicePlate) {
    splicePlateTotals = getSplicePlateTotals(splicePlate);
    itemHours = [
      ...itemHours,
      [
        {
          text: `Splice Plate Hours`,
          style: "tableTotal",
        },
        {
          text: `${toTwoDecimals(splicePlateTotals.totalLaborHours)}`,
          style: "tableTotal",
        },
      ],
    ];
  }
  let accessory = productMap.get("Accessory");
  if (accessory) {
    accessoryTotals = getAccessoryTotals(accessory);
  }

  return [
    {
      text: `LABOR SUMMARY`,
      style: "subheader",
      margin: [0, SECTION_MARGIN, 0, 0], // margin: [left, top, right, bottom]
    },
    {
      table: {
        // headers are automatically repeated if the table spans over multiple pages
        // you can declare how many rows should be treated as headers
        headerRows: 1,
        widths: [170, "*"],

        body: [
          [
            {
              text: `TOTAL Labor Hours to Complete Job`,
              style: "tableTotal",
            },
            {
              text: `${totals.totalLaborHours}`,
              style: "tableTotal",
            },
          ],
          ...itemHours,
        ],
      },
      margin: [0, 4, 0, 0], // margin: [left, top, right, bottom]
    },
  ];
};

const PIECE_SUMMARY = (productMap) => {
  let pieces = [];

  let panel = productMap.get("Panel");
  if (panel) {
    let cutCount = 0;
    for (let cut of panel.cuts) {
      cutCount += cut.quantity;
    }
    pieces = [
      ...pieces,
      [
        {
          text: `Total Panels Ordered`,
          style: "tableTotal",
        },
        {
          text: `${toTwoDecimals(cutCount)}`,
          style: "tableTotal",
        },
      ],
    ];
  }

  let trimAndFlashing = productMap.get("TrimAndFlashing");
  if (trimAndFlashing) {
    let pieceCount = 0;
    for (let piece of trimAndFlashing.trimPieces) {
      pieceCount += piece.quantity;
    }
    pieces = [
      ...pieces,
      [
        {
          text: `Total Trim & Flashing Pieces`,
          style: "tableTotal",
        },
        {
          text: `${toTwoDecimals(pieceCount)}`,
          style: "tableTotal",
        },
      ],
    ];
  }
  let copingCap = productMap.get("CopingCap");
  if (copingCap) {
    let pieceCount = 0;
    for (let piece of copingCap.capPieces) {
      pieceCount += piece.quantity;
    }

    pieces = [
      ...pieces,
      [
        {
          text: `Total Coping Cap Pieces`,
          style: "tableTotal",
        },
        {
          text: `${toTwoDecimals(pieceCount)}`,
          style: "tableTotal",
        },
      ],
    ];
  }
  let cleat = productMap.get("CopingCapCleat");
  if (cleat) {
    let pieceCount = 0;
    for (let piece of cleat.cleatPieces) {
      pieceCount += piece.quantity;
    }
    pieces = [
      ...pieces,
      [
        {
          text: `Total Coping Cap Cleat Pieces`,
          style: "tableTotal",
        },
        {
          text: `${toTwoDecimals(pieceCount)}`,
          style: "tableTotal",
        },
      ],
    ];
  }
  let benchwork = productMap.get("BenchWork");
  if (benchwork) {
    let pieceCount = 0;
    for (let piece of benchwork.benchWorkPieces) {
      pieceCount += piece.quantity;
    }
    pieces = [
      ...pieces,
      [
        {
          text: `Total Benchwork Pieces`,
          style: "tableTotal",
        },
        {
          text: `${toTwoDecimals(pieceCount)}`,
          style: "tableTotal",
        },
      ],
    ];
  }
  let splicePlate = productMap.get("SplicePlate");
  if (splicePlate) {
    let pieceCount = 0;
    for (let piece of splicePlate.plates) {
      pieceCount += piece.quantity;
    }
    pieces = [
      ...pieces,
      [
        {
          text: `Total Splice Plate Pieces`,
          style: "tableTotal",
        },
        {
          text: `${toTwoDecimals(pieceCount)}`,
          style: "tableTotal",
        },
      ],
    ];
  }
  let accessory = productMap.get("Accessory");
  if (accessory) {
    for (let item of accessory.items) {
      pieces = [
        ...pieces,
        [
          {
            text: `${item.name}`,
            style: "tableTotal",
          },
          {
            text: `${toTwoDecimals(item.quantity)}`,
            style: "tableTotal",
          },
        ],
      ];
    }
  }

  if (pieces.length > 0) {
    return [
      {
        text: `PIECE SUMMARY`,
        style: "subheader",
        margin: [0, SECTION_MARGIN, 0, 0], // margin: [left, top, right, bottom]
      },
      {
        table: {
          // headers are automatically repeated if the table spans over multiple pages
          // you can declare how many rows should be treated as headers
          headerRows: 1,
          widths: [170, "*"],

          body: [...pieces],
        },
        margin: [0, 4, 0, 0], // margin: [left, top, right, bottom]
      },
    ];
  } else {
    return [];
  }
};

const FLAT_SHEET = (flatSheetItem) => {
  if (!flatSheetItem) {
    return [];
  }

  return [
    {
      text: `FLAT SHEETS`,
      style: "subheader",
      margin: [0, SECTION_MARGIN, 0, 0], // margin: [left, top, right, bottom]
    },
    {
      unbreakable: true,
      table: {
        // headers are automatically repeated if the table spans over multiple pages
        // you can declare how many rows should be treated as headers
        headerRows: 1,
        widths: ["*", 80],
        body: [
          [
            {
              text: "WIDTH (IN.)",
              style: "tableHeader",
              fillColor: "#D3D3D3",
            },
            { text: "QTY", style: "tableHeader", fillColor: "#D3D3D3" },
          ],
          [
            { text: `${flatSheetItem.width}`, style: "tableCell" },
            { text: `${flatSheetItem.quantity}`, style: "tableCell" },
          ],
        ],
      },
      margin: [0, 4, 0, 0], // margin: [left, top, right, bottom]
    },
    {
      text: ``,
      style: "subheader",
      margin: [0, 10, 0, 0], // margin: [left, top, right, bottom]
    },
  ];
};

const COIL = (coilItem) => {
  if (!coilItem) {
    return [];
  }

  return [
    {
      text: `COIL`,
      style: "subheader",
      margin: [0, SECTION_MARGIN, 0, 0], // margin: [left, top, right, bottom]
    },
    {
      unbreakable: true,
      table: {
        // headers are automatically repeated if the table spans over multiple pages
        // you can declare how many rows should be treated as headers
        headerRows: 1,
        widths: ["*", 80],
        body: [
          [
            { text: "WIDTH (IN.)", style: "tableHeader", fillColor: "#D3D3D3" },
            {
              text: "LINEAL FEET OF COIL",
              style: "tableHeader",
              fillColor: "#D3D3D3",
            },
          ],
          [
            { text: `${coilItem.width}`, style: "tableCell" },
            { text: `${coilItem.linealFeet}`, style: "tableCell" },
          ],
        ],
      },
      margin: [0, 4, 0, 0], // margin: [left, top, right, bottom]
    },
    {
      text: ``,
      style: "subheader",
      margin: [0, 10, 0, 0], // margin: [left, top, right, bottom]
    },
  ];
};

// Longest to shortest
const sortPanelCutList = (panelCuts) => {
  panelCuts.sort((a, b) => {
    let aLength = parseFloat(a.feet) + parseFloat(a.inches) / 12;
    let bLength = parseFloat(b.feet) + parseFloat(b.inches) / 12;
    return bLength - aLength;
  });
};

const PANEL = (panelOrderItem) => {
  // return empty array if panelOrderItem does not exist
  if (!panelOrderItem) return [];

  let panelCuts = [];
  let cutList = panelOrderItem.cuts;
  sortPanelCutList(cutList);

  for (let i = 0; i < cutList.length; i++) {
    let panelCut = cutList[i];
    panelCuts.push([
      { text: `${panelCut.name}`, style: "tableCell" },
      { text: `${panelCut.quantity}`, style: "tableCell" },
      { text: `${panelCut.feet}`, style: "tableCell" },
      { text: `${panelCut.inches}`, style: "tableCell" },
      // { text: `${panelCut.linealFeet}`, style: "tableCell" }, // this is currently null, manually convert for now
      {
        // lineal feet of cut x quantity
        text: `${toThreeDecimals(
          panelCut.quantity * (panelCut.feet + panelCut.inches / 12)
        )}`,
        style: "tableCell",
      },
    ]);
  }

  return [
    {
      // unbreakable: true,
      stack: [
        {
          text: `PANEL CUTLIST`,
          style: "subheader",
          margin: [0, SECTION_MARGIN, 0, 0], // margin: [left, top, right, bottom]
        },
        ...LINE_TOP(),
        {
          layout: "noBorders", // optional
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            headerRows: 1,
            widths: ["*", "*", "*", 100, "*"],

            body: [
              [
                { text: "SYSTEM:", style: "detailName" },
                { text: "PANEL NAME:", style: "detailName" },
                { text: "PANEL WIDTH:", style: "detailName" },
                { text: "MASTER COIL WIDTH:", style: "detailName" },
              ],
              [
                { text: `${panelOrderItem.panelSystem}`, style: "detailValue" },
                { text: `${panelOrderItem.panelType}`, style: "detailValue" },
                { text: `${panelOrderItem.panelWidth}`, style: "detailValue" },
                {
                  text: `${panelOrderItem.calculations.masterCoilSizeNeeded}"`,
                  style: "detailValue",
                },
              ],
            ],
          },
          margin: [0, 4, 0, 0], // margin: [left, top, right, bottom]
        },
        ...LINE_BELOW(),
        {
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            headerRows: 1,
            widths: [200, "*", "*", "*", "*"],

            body: [
              [
                {
                  text: " PANEL LOCATION NAME",
                  style: "tableHeader",
                  fillColor: "#D3D3D3",
                },
                { text: "QTY", style: "tableHeader", fillColor: "#D3D3D3" },
                { text: "FEET", style: "tableHeader", fillColor: "#D3D3D3" },
                { text: "INCHES", style: "tableHeader", fillColor: "#D3D3D3" },
                {
                  text: "TOTAL LF",
                  style: "tableHeader",
                  fillColor: "#D3D3D3",
                },
              ],
              ...panelCuts,
            ],
          },
          margin: [0, 8, 0, 0], // margin: [left, top, right, bottom]
        },
        {
          layout: "noBorders",
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            headerRows: 1,
            widths: [200, "*", "*", "*", "*"],
            body: [
              [
                { text: ``, width: "*", style: "tableCell" },
                { text: ``, width: "*", style: "tableCell" },
                { text: ``, width: "*", style: "tableCell" },
                { text: ``, width: "*", style: "tableCell" },
                {
                  text: `${panelOrderItem.calculations.linealFeetOfCoilNoWaste}`,
                  style: "tableTotal",
                },
              ],
            ],
          },
          margin: [0, 0, 0, 0], // margin: [left, top, right, bottom]
        },
      ],
    },
  ];
};

const SPLICE_PLATES = (splicePlatesOrderItem) => {
  if (!splicePlatesOrderItem) return [];

  const plates = [];
  let total = 0;
  let totalFlatSheetsNeeded = 0;
  for (let i = 0; i < splicePlatesOrderItem.plates.length; i++) {
    let plate = splicePlatesOrderItem.plates[i];
    totalFlatSheetsNeeded += parseFloat(plate.calculations.flatSheetsNeeded);
    total += parseFloat(plate.quantity);

    plates.push([
      { text: `${plate.quantity}`, style: "tableCell" },
      { text: `${plate.length}"`, style: "tableCell" },
      { text: `${plate.stretchOut}"`, style: "tableCell" },
      { text: `${plate.hits}`, style: "tableCell" },
    ]);
  }

  return [
    {
      unbreakable: true,
      stack: [
        {
          text: `SPLICE PLATES`,
          style: "subheader",
          margin: [0, SECTION_MARGIN, 0, 0], // margin: [left, top, right, bottom]
        },
        {
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            headerRows: 1,
            widths: ["*", "*", "*", "*"],
            body: [
              [
                {
                  text: "QTY (pc)",
                  style: "tableHeader",
                  fillColor: "#D3D3D3",
                },
                {
                  text: "PIECE LENGTH",
                  style: "tableHeader",
                  fillColor: "#D3D3D3",
                },
                {
                  text: "STRETCH OUT (in)",
                  style: "tableHeader",
                  fillColor: "#D3D3D3",
                },
                // {
                //   text: "DROP PER FS (in)",
                //   style: "tableHeader",
                //   fillColor: "#D3D3D3",
                // },
                { text: "HITS", style: "tableHeader", fillColor: "#D3D3D3" },
                // { text: "EXACT FS", style: "tableHeader", fillColor: "#D3D3D3" },
                // { text: "FS TO BUY", style: "tableHeader", fillColor: "#D3D3D3" },
              ],
              ...plates,
            ],
          },
          margin: [0, 4, 0, 0], // margin: [left, top, right, bottom]
        },
        // {
        //   layout: "noBorders",
        //   table: {
        //     // headers are automatically repeated if the table spans over multiple pages
        //     // you can declare how many rows should be treated as headers
        //     headerRows: 1,
        //     widths: ["*", "*", "*", "*"],
        //     body: [
        //       [
        //         { text: `${total}`, style: "tableTotal" },
        //         { text: ``, width: "*", style: "tableCell" },
        //         { text: ``, width: "*", style: "tableCell" },
        //         {
        //           text: `FS To Buy: ${totalFlatSheetsNeeded}`,
        //           width: "*",
        //           style: "tableTotal",
        //         },
        //       ],
        //     ],
        //   },
        //   margin: [0, 0, 0, 0], // margin: [left, top, right, bottom]
        // },
      ],
    },
  ];
};

const TRIM_AND_FLASHING_SUMMARY = (trimOrderItem) => {
  if (!trimOrderItem) return [];

  let trimPieces = [];
  let i;
  for (i = 0; i < trimOrderItem.trimPieces.length; i++) {
    let item = trimOrderItem.trimPieces[i];
    trimPieces.push([
      { text: `${item.name}`, style: "tableCell" },
      { text: `${item.quantity}`, style: "tableCell" },
    ]);
  }

  let tableHeaders = [
    {
      text: "DESCRIPTION",
      style: "tableHeader",
      fillColor: "#D3D3D3",
    },
    { text: "QTY", style: "tableHeader", fillColor: "#D3D3D3" },
  ];
  let widths = ["*", 60];
  return [
    {
      text: `TRIM & FLASHING`,
      style: "subheader",
      margin: [0, 10, 0, 0], // margin: [left, top, right, bottom]
    },
    {
      text: `*Detail drawings on back`,
      style: {
        bold: true,
        fontSize: 6,
      },
      margin: [0, 0, 0, 0], // margin: [left, top, right, bottom]
    },
    {
      unbreakable: true,
      table: {
        // headers are automatically repeated if the table spans over multiple pages
        // you can declare how many rows should be treated as headers
        headerRows: 1,
        widths: widths,
        body: [tableHeaders, ...trimPieces],
      },
      margin: [0, 4, 0, 10], // margin: [left, top, right, bottom]
    },
    {
      text: ``,
      style: "subheader",
      margin: [0, 10, 0, 0], // margin: [left, top, right, bottom]
    },
    ...LINE_BELOW(),
  ];
};

const TRIM_AND_FLASHING = (trimOrderItem, productImages, isItemized) => {
  if (!trimOrderItem) return [];

  let pieceIndex = 0;
  let trimPieces = [];
  while (pieceIndex < trimOrderItem.trimPieces.length) {
    let quadrantIterator;
    let cells = [];
    for (quadrantIterator = 0; quadrantIterator < 4; quadrantIterator++) {
      let currentIndex = pieceIndex + quadrantIterator;
      if (currentIndex < trimOrderItem.trimPieces.length) {
        let currentPiece = trimOrderItem.trimPieces[currentIndex];
        let cell = [
          {
            margin: [0, 8, 0, 0],
            table: {
              widths: ["auto"],
              body: [
                [
                  {
                    // margin: [left, top, right, bottom]
                    margin: [0, 1, 0, 1],
                    image: `TrimAndFlashing${currentIndex}`,
                    width: 240,
                    height: 243,
                    alignment: "center",
                  },
                ],
              ],
            },
          },
          {
            margin: [0, 4, 0, 0],
            text: [
              { text: `Piece Name: `, style: "imageText" },
              { text: `${currentPiece.name}`, style: "imageTextBold" },
            ],
            style: "header",
          },
          {
            text: `Qty: ${currentPiece.quantity}`,
            style: "imageText",
          },
          {
            text: `Stretch Out: ${currentPiece.stretchOut}"`,
            style: "imageText",
          },
        ];
        cells.push(cell);
      } else {
        cells.push(EMPTY_TRIM_IMAGE());
      }
    }
    trimPieces = [
      ...trimPieces,
      QUADRANT(cells[0], cells[1], cells[2], cells[3]),
    ];
    pieceIndex += 4;
  }

  return [
    {
      text: `TRIM & FLASHING`,
      style: "subheader",
      margin: [0, SECTION_MARGIN, 0, 0], // margin: [left, top, right, bottom]
      pageBreak: "before",
    },
    ...trimPieces,
  ];
};

const COPING_CAP_SUMMARY = (copingCapOrderItem) => {
  if (!copingCapOrderItem) return [];

  let capPieces = [];
  let i;
  for (i = 0; i < copingCapOrderItem.capPieces.length; i++) {
    let item = copingCapOrderItem.capPieces[i];
    capPieces.push([
      { text: `${item.name}`, style: "tableCell" },
      { text: `${item.quantity}`, style: "tableCell" },
    ]);
  }

  let tableHeaders = [
    {
      text: "DESCRIPTION",
      style: "tableHeader",
      fillColor: "#D3D3D3",
    },
    { text: "QTY", style: "tableHeader", fillColor: "#D3D3D3" },
  ];
  let widths = ["*", 60];
  return [
    {
      text: `COPING CAP`,
      style: "subheader",
      margin: [0, 10, 0, 0], // margin: [left, top, right, bottom]
    },
    {
      text: `*Detail drawings on back`,
      style: {
        bold: true,
        fontSize: 6,
      },
      margin: [0, 0, 0, 0], // margin: [left, top, right, bottom]
    },
    {
      unbreakable: true,
      table: {
        // headers are automatically repeated if the table spans over multiple pages
        // you can declare how many rows should be treated as headers
        headerRows: 1,
        widths: widths,
        body: [tableHeaders, ...capPieces],
      },
      margin: [0, 4, 0, 10], // margin: [left, top, right, bottom]
    },
    {
      text: ``,
      style: "subheader",
      margin: [0, 10, 0, 0], // margin: [left, top, right, bottom]
    },
    ...LINE_BELOW(),
  ];
};

const COPING_CAP = (
  copingCapOrderItem,
  productImages,
  isItemized,
  markUpPercentage
) => {
  console.log("BUILDING COPING CAP SECTION...");
  if (!copingCapOrderItem) return [];

  let pieceIndex = 0;
  let capPieces = [];
  while (pieceIndex < copingCapOrderItem.capPieces.length) {
    let quadrantIterator;
    let cells = [];
    for (quadrantIterator = 0; quadrantIterator < 4; quadrantIterator++) {
      let currentIndex = pieceIndex + quadrantIterator;
      if (currentIndex < copingCapOrderItem.capPieces.length) {
        let currentPiece = copingCapOrderItem.capPieces[currentIndex];
        let cell = [
          {
            margin: [0, 8, 0, 0],
            table: {
              widths: ["auto"],
              body: [
                [
                  {
                    // margin: [left, top, right, bottom]
                    margin: [0, 1, 0, 1],
                    image: `CopingCap${currentIndex}`,
                    width: 240,
                    height: 243,
                    alignment: "center",
                  },
                ],
              ],
            },
          },
          {
            margin: [0, 4, 0, 0],
            text: [
              { text: `Piece Name: `, style: "imageText" },
              { text: `${currentPiece.name}`, style: "imageTextBold" },
            ],
            style: "header",
          },
          {
            text: `Qty: ${currentPiece.quantity}`,
            style: "imageText",
          },
          {
            text: `Stretch Out: ${currentPiece.stretchOut}"`,
            style: "imageText",
          },
        ];
        cells.push(cell);
      } else {
        cells.push(EMPTY_TRIM_IMAGE());
      }
    }
    capPieces = [
      ...capPieces,
      QUADRANT(cells[0], cells[1], cells[2], cells[3]),
    ];
    pieceIndex += 4;
  }

  return [
    {
      text: `COPING CAP`,
      style: "subheader",
      margin: [0, SECTION_MARGIN, 0, 0], // margin: [left, top, right, bottom]
      pageBreak: "before",
    },
    ...capPieces,
  ];
};

const CLEAT_SUMMARY = (cleatOrderItem) => {
  if (!cleatOrderItem) return [];

  let cleatPieces = [];
  let i;
  for (i = 0; i < cleatOrderItem.cleatPieces.length; i++) {
    let item = cleatOrderItem.cleatPieces[i];
    cleatPieces.push([
      { text: `${item.name}`, style: "tableCell" },
      { text: `${item.quantity}`, style: "tableCell" },
    ]);
  }

  let tableHeaders = [
    {
      text: "DESCRIPTION",
      style: "tableHeader",
      fillColor: "#D3D3D3",
    },
    { text: "QTY", style: "tableHeader", fillColor: "#D3D3D3" },
  ];
  let widths = ["*", 60];

  return [
    {
      text: `CLEAT`,
      style: "subheader",
      margin: [0, 10, 0, 0], // margin: [left, top, right, bottom]
    },
    {
      text: `*Detail drawings on back`,
      style: {
        bold: true,
        fontSize: 6,
      },
      margin: [0, 0, 0, 0], // margin: [left, top, right, bottom]
    },
    {
      unbreakable: true,
      table: {
        // headers are automatically repeated if the table spans over multiple pages
        // you can declare how many rows should be treated as headers
        headerRows: 1,
        widths: widths,
        body: [tableHeaders, ...cleatPieces],
      },
      margin: [0, 4, 0, 10], // margin: [left, top, right, bottom]
    },
    {
      text: ``,
      style: "subheader",
      margin: [0, 10, 0, 0], // margin: [left, top, right, bottom]
    },
    ...LINE_BELOW(),
  ];
};

const CLEAT = (cleatOrderItem, productImages, isItemized) => {
  console.log("BUILDING CLEAT SECTION...");
  if (!cleatOrderItem) return [];

  let pieceIndex = 0;
  let cleatPieces = [];
  while (pieceIndex < cleatOrderItem.cleatPieces.length) {
    let quadrantIterator;
    let cells = [];
    for (quadrantIterator = 0; quadrantIterator < 4; quadrantIterator++) {
      let currentIndex = pieceIndex + quadrantIterator;
      if (currentIndex < cleatOrderItem.cleatPieces.length) {
        let currentPiece = cleatOrderItem.cleatPieces[currentIndex];
        let cell = [
          {
            margin: [0, 8, 0, 0],
            table: {
              widths: ["auto"],
              body: [
                [
                  {
                    // margin: [left, top, right, bottom]
                    margin: [0, 1, 0, 1],
                    image: `CopingCapCleat${currentIndex}`,
                    width: 240,
                    height: 243,
                    alignment: "center",
                  },
                ],
              ],
            },
          },
          {
            margin: [0, 4, 0, 0],
            text: [
              { text: `Piece Name: `, style: "imageText" },
              { text: `${currentPiece.name}`, style: "imageTextBold" },
            ],
            style: "header",
          },
          {
            text: `Qty: ${currentPiece.quantity}`,
            style: "imageText",
          },
          {
            text: `Stretch Out: ${currentPiece.stretchOut}"`,
            style: "imageText",
          },
        ];
        cells.push(cell);
      } else {
        cells.push(EMPTY_TRIM_IMAGE());
      }
    }
    cleatPieces = [
      ...cleatPieces,
      QUADRANT(cells[0], cells[1], cells[2], cells[3]),
    ];
    pieceIndex += 4;
  }

  return [
    {
      text: `CLEAT`,
      style: "subheader",
      margin: [0, SECTION_MARGIN, 0, 0], // margin: [left, top, right, bottom]
      pageBreak: "before",
    },
    ...cleatPieces,
  ];
};

const BENCH_WORK_SUMMARY = (benchWorkOrderItem) => {
  if (!benchWorkOrderItem) return [];

  let benchWorkPieces = [];
  let i;
  for (i = 0; i < benchWorkOrderItem.benchWorkPieces.length; i++) {
    let item = benchWorkOrderItem.benchWorkPieces[i];
    benchWorkPieces.push([
      { text: `${item.name}`, style: "tableCell" },
      { text: `${item.quantity}`, style: "tableCell" },
    ]);
  }

  let tableHeaders = [
    {
      text: "DESCRIPTION",
      style: "tableHeader",
      fillColor: "#D3D3D3",
    },
    { text: "QTY", style: "tableHeader", fillColor: "#D3D3D3" },
  ];
  let widths = ["*", 60];

  return [
    {
      text: `BENCH WORK`,
      style: "subheader",
      margin: [0, 10, 0, 0], // margin: [left, top, right, bottom]
    },
    {
      text: `*Detail drawings on back`,
      style: {
        bold: true,
        fontSize: 6,
      },
      margin: [0, 0, 0, 0], // margin: [left, top, right, bottom]
    },
    {
      unbreakable: true,
      table: {
        // headers are automatically repeated if the table spans over multiple pages
        // you can declare how many rows should be treated as headers
        headerRows: 1,
        widths: widths,
        body: [tableHeaders, ...benchWorkPieces],
      },
      margin: [0, 4, 0, 10], // margin: [left, top, right, bottom]
    },
    {
      text: ``,
      style: "subheader",
      margin: [0, 10, 0, 0], // margin: [left, top, right, bottom]
    },
    ...LINE_BELOW(),
  ];
};

const BENCH_WORK = (benchWorkOrderItem, productImages, isItemized) => {
  console.log("BUILDING BENCH WORK SECTION...");
  if (!benchWorkOrderItem) return [];

  let pieceIndex = 0;
  let benchWorkPieces = [];
  while (pieceIndex < benchWorkOrderItem.benchWorkPieces.length) {
    let quadrantIterator;
    let cells = [];
    for (quadrantIterator = 0; quadrantIterator < 4; quadrantIterator++) {
      let currentIndex = pieceIndex + quadrantIterator;
      if (currentIndex < benchWorkOrderItem.benchWorkPieces.length) {
        let currentPiece = benchWorkOrderItem.benchWorkPieces[currentIndex];
        let cell = [
          {
            margin: [0, 8, 0, 0],
            table: {
              widths: ["auto"],
              body: [
                [
                  {
                    // margin: [left, top, right, bottom]
                    margin: [0, 1, 0, 1],
                    image: `BenchWork${currentIndex}`,
                    width: 240,
                    height: 243,
                    alignment: "center",
                  },
                ],
              ],
            },
          },
          {
            margin: [0, 4, 0, 0],
            text: [
              { text: `Piece Name: `, style: "imageText" },
              {
                text: `${currentPiece.name}`,
                style: "imageTextBold",
              },
            ],
            style: "header",
          },
          {
            text: `Qty: ${currentPiece.quantity}`,
            style: "imageText",
          },
          {
            text: `Stretch Out: ${currentPiece.stretchOut}"`,
            style: "imageText",
          },
        ];
        cells.push(cell);
      } else {
        cells.push(EMPTY_TRIM_IMAGE());
      }
    }
    benchWorkPieces = [
      ...benchWorkPieces,
      QUADRANT(cells[0], cells[1], cells[2], cells[3]),
    ];
    pieceIndex += 4;
  }

  return [
    {
      text: `BENCH WORK`,
      style: "subheader",
      margin: [0, SECTION_MARGIN, 0, 0], // margin: [left, top, right, bottom]
      pageBreak: "before",
    },
    ...benchWorkPieces,
  ];
};

const TWO_COLUMNS = (contents1, contents2) => {
  return [
    {
      columnGap: COLUMN_GAP,
      columns: [
        {
          width: HALF_COLUMN_WITH_GAP,
          stack: [[...contents1]],
        },
        {
          width: HALF_COLUMN_WITH_GAP,
          stack: [[...contents2]],
        },
      ],
    },
  ];
};
const THREE_COLUMNS = (contents1, contents2, contents3) => {
  return [
    {
      columnGap: COLUMN_GAP,
      columns: [
        {
          width: ONE_THIRD_COLUMN_WITH_GAP,
          stack: [[...contents1]],
        },
        {
          width: ONE_THIRD_COLUMN_WITH_GAP,
          stack: [[...contents2]],
        },
        {
          width: ONE_THIRD_COLUMN_WITH_GAP,
          stack: [[...contents3]],
        },
      ],
    },
  ];
};

const NOT_IN_STOCK_MATERIAL = () => {
  return [
    {
      margin: [0, 10, 0, 0], // margin: [left, top, right, bottom]
      text: "NOT IN-STOCK MATERIAL:",
      style: "miniBold",
    },
    {
      margin: [0, 10, 0, 0], // margin: [left, top, right, bottom]
      text: "[  ] PO ISSUED TO: _____________________",
      style: "mini",
    },
    {
      margin: [0, 10, 0, 0], // margin: [left, top, right, bottom]
      text: "DATE: _________________________",
      style: "mini",
    },
    {
      margin: [0, 10, 0, 0], // margin: [left, top, right, bottom]
      text: "PO #: _________________________",
      style: "mini",
    },
    {
      margin: [0, 10, 0, 0], // margin: [left, top, right, bottom]
      text: "[  ] Material Received On: __________________",
      style: "mini",
    },
  ];
};

const IN_STOCK_MATERIAL = () => {
  return [
    {
      margin: [0, 10, 0, 0], // margin: [left, top, right, bottom]
      text: "IN-STOCK MATERIAL:",
      style: "miniBold",
    },
    {
      margin: [0, 10, 0, 0], // margin: [left, top, right, bottom]
      text: "[  ] MATERIAL IN-STOCK:          Y / N",
      style: "mini",
    },
    {
      margin: [0, 10, 0, 0], // margin: [left, top, right, bottom]
      text: "NOTES:   ___________________________",
      style: "mini",
    },
  ];
};

const WORK_ORDER_NOTES = (order) => {
  return [
    {
      // margin: [0, 10, 0, 0], // margin: [left, top, right, bottom]
      unbreakable: true,
      stack: [
        // ...TWO_COLUMNS(NOT_IN_STOCK_MATERIAL(), IN_STOCK_MATERIAL()),
        ...THREE_COLUMNS(NOT_IN_STOCK_MATERIAL(), IN_STOCK_MATERIAL(), [
          [
            {
              margin: [0, 10, 0, 0], // margin: [left, top, right, bottom]
              text: "WORK ORDER NOTES",
              style: "miniBold",
            },
          ],
          [
            {
              text: `${order.notes || ""}`,
              style: "mini",
            },
          ],
        ]),
      ],
    },
  ];
};

const getFlatsNeeded = (order) => {
  let flatsNeeded = 0;
  let standaloneFlats = 0;
  let galvFlatsNeeded = 0;
  for (const orderItem of order.items) {
    switch (orderItem.objectType) {
      case "FlatSheet":
        let flatSheet = orderItem;
        standaloneFlats += flatSheet.quantity;
        break;
      case "Accessory":
        for (const accessoryItem of orderItem.items) {
          if (accessoryItem.name.toLowerCase().includes("flat sheet")) {
            standaloneFlats += accessoryItem.quantity;
          }
        }
        break;
      case "TrimAndFlashing":
        let trimPieces = orderItem.trimPieces;
        for (const trimPiece of trimPieces) {
          let flats = parseInt(trimPiece.calculations.flatSheetsNeeded);
          console.log("TrimAndFlashing Flats", flats);
          flatsNeeded += flats;
        }
        break;
      case "CopingCap":
        let capPieces = orderItem.capPieces;
        for (const capPiece of capPieces) {
          let flats = parseInt(capPiece.calculations.flatSheetsNeeded);
          console.log("CopingCap Flats", flats);
          flatsNeeded += flats;
        }
        break;
      case "CopingCapCleat":
        let cleatPieces = orderItem.cleatPieces;
        for (const cleatPiece of cleatPieces) {
          let flats = parseInt(cleatPiece.calculations.flatSheetsNeeded);
          console.log("CopingCapCleat GALV Flats", flats);

          galvFlatsNeeded += flats;
        }
        break;
      case "BenchWork":
        let benchWorkPieces = orderItem.benchWorkPieces;
        for (const benchWorkPiece of benchWorkPieces) {
          flatsNeeded += parseInt(benchWorkPiece.flatSheets);
        }
        break;
      case "SplicePlate":
        let plates = orderItem.plates;
        for (const plate of plates) {
          let flats = parseInt(plate.calculations.flatSheetsNeeded);
          console.log("SplicePlate Flats", flats);
          flatsNeeded += flats;
        }
        break;

      default:
        break;
    }
  }

  let materials = {
    flatsNeeded,
    standaloneFlats,
    galvFlatsNeeded,
  };
  console.log("MATERIALS NEEDED", materials);
  return materials;
};

const getWorkOrderDocDefinition = async (
  order,
  productImages,
  isMaterialAndLaborSummary = false
) => {
  // Put order items in a map by type
  const productMap = new Map();
  for (let i = 0; i < order.items.length; i++) {
    let orderItem = order.items[i];
    productMap.set(`${orderItem.objectType}`, orderItem);
  }
  const panelOrderItem = getPanelDetails(order); // coil calcs
  const coilOrderItem = getCoilDetails(order);
  const flatTotals = getFlatsNeeded(order);
  console.log("Product Map", productMap);

  let materialAndLaborSection = [];
  if (isMaterialAndLaborSummary) {
    materialAndLaborSection = [...LABOR_SUMMARY(order, productMap)];
  }

  let detailsSection = [];
  if (!isMaterialAndLaborSummary) {
    let panel = productMap.get("Panel");
    console.log("Not Material And Labor Summary");
    if (panel) {
      console.log("Panel Exists. Add Panel to detailsSection...");
      let detailsStack = {
        pageBreak: "before",
        stack: [...PANEL(panel)],
      };
      detailsSection.push(detailsStack);
    }
    let splicePlate = productMap.get("SplicePlate");
    let trim = productMap.get("TrimAndFlashing");
    let cap = productMap.get("CopingCap");
    let cleat = productMap.get("CopingCapCleat");
    let benchWork = productMap.get("BenchWork");
    if (trim || cap || cleat || benchWork || splicePlate) {
      let details2Stack = {
        pageBreak: "before",
        stack: [
          // --------------SUMMARY---------------------------
          ...TRIM_AND_FLASHING_SUMMARY(trim),
          ...COPING_CAP_SUMMARY(cap),
          ...CLEAT_SUMMARY(cleat),
          ...BENCH_WORK_SUMMARY(benchWork),
          ...SPLICE_PLATES(splicePlate),
          // --------------IMAGES----------------------------
          ...TRIM_AND_FLASHING(trim, productImages),
          ...COPING_CAP(cap, productImages),
          ...CLEAT(cleat, productImages),
          ...BENCH_WORK(benchWork, productImages),
        ],
      };
      detailsSection.push(details2Stack);
    }
  }

  var docDefinition = {
    pageSize: "A4",
    // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
    pageMargins: [40, 40, 40, 120],
    content: [
      ...HEADER(isMaterialAndLaborSummary),
      ...JOB_DETAILS(order),
      ...MATERIAL_SUMMARY(flatTotals, panelOrderItem, coilOrderItem),

      ...TWO_COLUMNS(
        [
          ...ACCESSORIES(productMap.get("Accessory")),
          // ...FLAT_SHEET(productMap.get("FlatSheet")),
          // ...COIL(productMap.get("Coil")),
        ],
        [...materialAndLaborSection, ...PIECE_SUMMARY(productMap)]
      ),
      ...detailsSection,
      // ...WORK_ORDER_NOTES(order),
    ],

    footer: function (currentPage, pageCount) {
      return {
        margin: [40, 0, 40, 0], // margin: [left, top, right, bottom]
        stack: [
          ...WORK_ORDER_NOTES(order),
          // ...TWO_COLUMNS(NOT_IN_STOCK_MATERIAL(), IN_STOCK_MATERIAL()),
          {
            margin: [0, -10, 0, 0], // margin: [left, top, right, bottom]
            text: `Created on ${new Date().toDateString()}`,
            alignment: "right",
            style: "footerText",
          },
          // {
          //   text: currentPage.toString() + " of " + pageCount,
          //   alignment: "center",
          //   style: "footerText",
          // },
          // {
          //   text: "EZ-ORDER Ver.1.0",
          //   alignment: "right",
          //   style: "footerText",
          // },
        ],
      };
    },

    styles: {
      header: {
        fontSize: 24,
        bold: true,
        alignment: "right",
      },
      subheader: {
        bold: true,
        fontSize: 10,
      },
      miniBold: {
        bold: true,
        fontSize: 8,
      },
      mini: {
        fontSize: 8,
      },
      imageText: {
        fontSize: 10,
        alignment: "center",
      },
      imageTextBold: {
        bold: true,
        fontSize: 10,
        alignment: "center",
      },
      detailName: {
        bold: true,
        fontSize: 10,
      },
      detailValue: {
        fontSize: 8,
      },
      tableHeader: {
        bold: true,
        fontSize: 10,
        alignment: "center",
      },
      tableCell: {
        fontSize: 8,
        alignment: "center",
      },
      tableTotal: {
        fontSize: 8,
        bold: true,
        alignment: "center",
      },
      tableTotalLeft: {
        fontSize: 8,
        bold: true,
        alignment: "left",
      },
      regularText: {
        fontSize: 10,
      },
      materialHeader: {
        fillColor: "#D3D3D3",
        bold: true,
        fontSize: 10,
      },
      checkbox: {},
      footerText: {
        fontSize: 8,
      },
    },
    defaultStyle: {
      fontSize: 12,
    },
    images: {
      // logo: "https://ezorder-public.s3.us-east-2.amazonaws.com/logo_black_horizontal.png",
      logo: "https://ezorder-public.s3.us-east-2.amazonaws.com/industry-metal-co-long-logo.png",
      ...productImages,
    },
  };

  return docDefinition;
};

export default getWorkOrderDocDefinition;
