import { updateOrderItemPiece, withId, trimCalcs as calc } from "./utils";

const trimReducer = (state, action) => {
  const trimCalcs = calc(state, false);
  switch (action.type) {
    case "UPDATE TRIM: PIECE DETAILS": {
      const calculations = trimCalcs(action.payload);
      const updatedItems = updateOrderItemPiece(
        state.items,
        "TrimAndFlashing",
        { ...action.payload, ...calculations }
      );
      return { ...state, items: [...updatedItems] };
    }
    case "UPDATE TRIM: ADD TRIM": {
      console.log("__UPDATE TRIM: ADD TRIM", action);
      const calculations = trimCalcs(action.payload);
      const trim = { ...action.payload, ...calculations };

      let newArr = [...state.items];
      const index = newArr.findIndex(
        (obj) => obj.objectType === "TrimAndFlashing"
      );
      newArr[index].trimPieces.push(withId(trim)); // TODO: why is this addint at the top
      return { ...state, items: [...newArr] };
    }
    case "UPDATE TRIM: REMOVE TRIM": {
      let newArr = [...state.items];
      const index = newArr.findIndex(
        (obj) => obj.objectType === "TrimAndFlashing"
      );
      newArr[index].trimPieces.splice(action.payload, 1);
      return { ...state, items: [...newArr] };
    }
    case "UPDATE TRIM: OVERRIDE CALCULATION": {
      const { trim, key, value } = action.payload;
      const updatedItems = updateOrderItemPiece(
        state.items,
        "TrimAndFlashing",
        {
          ...trim,
          ...trimCalcs(trim, key, value),
        }
      );
      return { ...state, items: [...updatedItems] };
    }
    case "UPDATE TRIM: RESET OVERRIDE": {
      const { trim, key } = action.payload;
      const updatedItems = updateOrderItemPiece(
        state.items,
        "TrimAndFlashing",
        {
          ...trim,
          ...trimCalcs(trim, key, null),
        }
      );
      return { ...state, items: [...updatedItems] };
    }
    case "UPDATE TRIM: RUN CALCULATIONS": {
      const calculations = trimCalcs(action.payload);
      const updatedItems = updateOrderItemPiece(
        state.items,
        "TrimAndFlashing",
        {
          ...action.payload,
          ...calculations,
        }
      );
      return { ...state, items: [...updatedItems] };
    }
    default:
      return state;
  }
};
export default trimReducer;
