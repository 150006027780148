import {
  Box,
  Button,
  Card,
  Fab,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";

import usePanelSystems from "../../../hooks/usePanelSystems";
import { useContext, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { Add } from "@mui/icons-material";
import { useTheme } from "@emotion/react";
import AddPanelSystemDialog from "./AddPanelSystemDialog";
import { AuthContext } from "../../../context/AuthContext";

const AdminPanelCatalog = () => {
  const { panelSystems, getPanelSystems, refreshing } = usePanelSystems();
  const [currentSystem, setCurrentSystem] = useState(null);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const { authToken } = useContext(AuthContext);
  const addPanelSystem = async (description) => {};

  return (
    <>
      <Typography variant="h4" sx={{ textAlign: "center" }}>
        Panel Systems
      </Typography>
      {/* <Box
        sx={{ display: "flex", flexDirection: "row", paddingBottom: "16px" }}
      >
        <TextField
          sx={{ width: 300 }}
          size="small"
          label="Panel System"
          select
          value={currentSystem ? currentSystem.id : -1}
          onChange={(e) => {
            console.log("SELECTED", e.target);
            // findIndex returns -1 if not found
            let indexById = panelSystems.findIndex(
              (element) => element.id == parseInt(e.target.value)
            );
            if (indexById == -1) {
              setCurrentSystem(null);
            } else {
              let selected = panelSystems[indexById];
              setCurrentSystem(selected);
            }
          }}
        >
          <MenuItem value={-1}>ALL</MenuItem>
          {panelSystems?.map((panelSystem) => (
            <MenuItem key={panelSystem.id} value={panelSystem.id}>
              {panelSystem.description}
            </MenuItem>
          ))}
        </TextField>
        <LoadingButton
          //   loading={refreshing || loading}
          onClick={() => {
            // setOpen(true);
          }}
        >
          <Add /> Add Panel System
        </LoadingButton>
      </Box> */}
      <Box>
        {panelSystems &&
          panelSystems.map((system, index) => {
            return (
              <Card sx={{ padding: "16px", marginTop: "16px" }}>
                <Typography variant="h5">
                  {system.description} <Button>Edit</Button>
                </Typography>
                <Box sx={{}}>
                  <TableContainer component={Box}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Panel Name</TableCell>
                          <TableCell align="right">Takeup (in.)</TableCell>
                          <TableCell align="right">
                            Sizes Options (in.)
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {system.panelTypes.map((panelType, ptIndex) => {
                          return (
                            <TableRow>
                              <TableCell>{panelType.description}</TableCell>
                              <TableCell align="right">
                                {panelType.panelTakeUp}
                              </TableCell>
                              <TableCell align="right">
                                {panelType.panelWidths.toString()}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                        {/* <TableRow>
                          <TableCell colSpan={4} align="center">
                            <Button>
                              <Add />
                              Add New Panel
                            </Button>
                          </TableCell>
                        </TableRow> */}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Card>
            );
          })}
      </Box>
      <AddPanelSystemDialog
        open={open}
        setOpen={setOpen}
        onSubmit={(description) => {
          addPanelSystem(description);
        }}
      />
      <Fab
        sx={{
          position: "fixed",
          bottom: 70,
          right: isSmallScreen ? 16 : 56,
        }}
        color="success"
        variant="extended"
        aria-label="add"
        onClick={() => {
          setOpen(true);
        }}
      >
        <Add sx={{ mr: 1 }} />
        New Panel System
      </Fab>
    </>
  );
};
export default AdminPanelCatalog;
