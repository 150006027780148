import { useContext, useEffect, useState } from "react";
import PurchaseOrderForm from "./PurchaseOrderForm";
import { AuthContext } from "../../../context/AuthContext";
import { useParams } from "react-router-dom";
import useVendorPO from "../../../hooks/useVendorPO";
import { Box, Typography } from "@mui/material";

const PurchaseOrderDetails = () => {
  const { id } = useParams();
  const { authToken, ezorder } = useContext(AuthContext);
  const [po, getPo, isLoading] = useVendorPO(authToken, id);
  const [isSaving, setIsSaving] = useState(false);

  const savePurchaseOrder = async (poUpdate) => {
    setIsSaving(true);

    try {
      const response = await ezorder.post(`/admin/purchase-orders`, poUpdate, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      alert("Saved");
      getPo();
    } catch (error) {
    } finally {
      setIsSaving(false);
    }
  };

  if (!po) return null;
  return (
    <Box>
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          Purchase Order #{id}
        </Typography>
      </Box>
      <PurchaseOrderForm
        existingPurchaseOrder={po}
        onSubmit={(update) => {
          savePurchaseOrder(update);
        }}
        disabled={isSaving || isLoading}
      />
    </Box>
  );
};

export default PurchaseOrderDetails;
