import moment from "moment/moment";
import { useState } from "react";
import CollapsableCard from "../../CollapseableCard";
import {
  Box,
  Button,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { SnackAlertContext } from "../../../context/SnackAlertContext";
import { useEffect } from "react";
import SalesRepModal from "../../../pages/admin/Customers/SalesRepModal";

const SalesRepSection = ({ orderId }) => {
  const { authToken, ezorder } = useContext(AuthContext);
  const { openSnackMessage } = useContext(SnackAlertContext);
  const [salesRep, setSalesRep] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openSalesPersonModal, setOpenSalesPersonModal] = useState(false);

  const handleOpenSalesRepModal = () => {
    setOpenSalesPersonModal(true);
  };
  const handleCloseSalesRepModal = () => {
    setOpenSalesPersonModal(false);
  };

  const updateCompanySalesRep = async (salesRep) => {
    try {
      setLoading(true);
      let data = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      const res = await ezorder.put(
        `/admin/orders/${orderId}/sales-rep`,
        {
          salesRepId: salesRep.id,
        },
        data
      );
      handleCloseSalesRepModal();
      setSalesRep(salesRep);
    } catch (error) {
      console.log(error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage("error", error.response.data.error);
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", error);
      }
    } finally {
      setLoading(false);
    }
  };

  const getOrderSalesRep = async (controller, orderId) => {
    try {
      setLoading(true);
      let data = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      if (controller) {
        data.signal = controller.signal;
      }
      const res = await ezorder.get(`/admin/orders/${orderId}/sales-rep`, data);
      setSalesRep(res.data.salesRep);
    } catch (error) {
      console.log(error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage("error", error.response.data.error);
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", error);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    if (orderId) {
      getOrderSalesRep(controller, orderId);
    }

    return () => {
      controller.abort(); // abort api call on unmount for cleanup
    };
  }, [orderId]);

  if (orderId)
    return (
      <CollapsableCard
        title={`Sales Rep:  ${
          salesRep
            ? `${salesRep.fullName}${
                salesRep.deletedAt ? " [DEACTIVATED]" : ""
              }`
            : "UNASSIGNED"
        }`}
        defaultOpen={false}
      >
        <Typography
          variant="h4"
          component="div"
          sx={{ width: "100%", textAlign: "center" }}
        >
          Sales Rep
        </Typography>
        {salesRep && (
          <TableContainer component={Box}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Full Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Role</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{salesRep.fullName}</TableCell>
                  <TableCell>{salesRep.email}</TableCell>
                  <TableCell>{salesRep.role}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <Box>
          <Button variant="contained" onClick={handleOpenSalesRepModal}>
            Change
          </Button>
          <SalesRepModal
            open={openSalesPersonModal}
            // handleOpen={handleOpenSalesRepModal}
            handleClose={handleCloseSalesRepModal}
            onSelect={updateCompanySalesRep}
          />
        </Box>
      </CollapsableCard>
    );
};

export default SalesRepSection;
