import { Box, Button, CircularProgress } from "@mui/material";
import EditDefaultVariables from "../../../components/SettingsComponents/EditDefaultVariables";
import EditAccessories from "../../../components/SettingsComponents/EditAccessories";
import ColorsList from "../../../components/SettingsComponents/Colors/ColorsList";
import { useState, useContext, useEffect } from "react";
import { AuthContext } from "../../../context/AuthContext";
import useVariables from "../../../hooks/useVariables";
import useGalvanizedFlatOptions from "../../../hooks/useGalvanizedFlatOptions";
import EditGalvanizedFlats from "../../../components/SettingsComponents/EditGalvanizedFlats";
import useAllColors from "../../../hooks/useAllColors";
import { SnackAlertContext } from "../../../context/SnackAlertContext";
import { LoadingButton } from "@mui/lab";

const Settings = () => {
  const { authToken, ezorder } = useContext(AuthContext);
  const { openSnackMessage } = useContext(SnackAlertContext);

  const { galvanizedFlatOptions, getGalvanizedFlatOptions, refreshing } =
    useGalvanizedFlatOptions();
  const { variables, getDefaultVariables } = useVariables();
  const [accessories, setAccessories] = useState();
  const [newItem, setNewItem] = useState({
    name: null,
    unitOfMeasure: null,
    inStock: true,
  });

  const [isUpdatingVariables, setIsUpdatingVariables] = useState(false);
  const [isAddingAccessory, setIsAddingAccessory] = useState(false);
  const [isSavingGalvFlatOption, setIsSavingGalvFlatOption] = useState(false);

  const getAccessoryCatalog = async () => {
    try {
      const response = await ezorder.get(`/app/accessories`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      // console.log("getAccessories", response);
      if (response.status === 200 || response.status == 201) {
        setAccessories(response.data.accessories);
      }
    } catch (error) {
      // Object.keys(error).map((error) => console.log(error));
      console.log(error);
    }
  };

  const updateDefaultVariables = async (updatedVariables) => {
    try {
      setIsUpdatingVariables(true);
      const response = await ezorder.put(`/app/variables`, updatedVariables, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      if (response.status === 200 || response.status == 201) {
        console.log(response);
        console.log(response.data.updatedVariables[0]);
        getDefaultVariables();
      }
      openSnackMessage("success", "Saved");
    } catch (error) {
      console.log(error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage("error", error.response.data.error);
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", error);
      }
    } finally {
      setIsUpdatingVariables(false);
    }
  };
  const addNewAccessory = async (item) => {
    try {
      setIsAddingAccessory(true);
      const response = await ezorder.post(`/app/accessories`, item, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      openSnackMessage("success", "Saved");
      getAccessoryCatalog();
    } catch (error) {
      console.log(error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage("error", error.response.data.error);
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", error);
      }
    } finally {
      setIsAddingAccessory(false);
    }
  };
  const updateAccessory = async (item) => {
    // console.log("HELLO!!!", item);
    setIsAddingAccessory(true);
    try {
      const response = await ezorder.put(`/app/accessories/${item.id}`, item, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      // console.log("RES", response);

      getAccessoryCatalog();
      openSnackMessage("success", "Updated");
    } catch (error) {
      console.log(error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage("error", error.response.data.error);
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", error);
      }
    } finally {
      setIsAddingAccessory(false);
    }
  };

  const saveGalvanizedFlat = async (galvanizedFlat) => {
    try {
      setIsSavingGalvFlatOption(true);
      const res = await ezorder.post(`/app/galvanized-fs`, galvanizedFlat, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      getGalvanizedFlatOptions();
      openSnackMessage("success", "Updated");
    } catch (error) {
      console.log(error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage("error", error.response.data.error);
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", error);
      }
    } finally {
      setIsSavingGalvFlatOption(false);
    }
  };

  useEffect(() => {
    getAccessoryCatalog();
  }, []);

  const saveChangesButton = (handler, text, loading) => {
    return (
      <LoadingButton loading={loading} onClick={handler}>
        {text}
      </LoadingButton>
      // <Box component="span" sx={{ m: 1, position: "relative", ml: "20px" }}>
      //   <Button
      //     disabled={loading}
      //     variant="contained"
      //     sx={{ mb: "10px" }}
      //     onClick={() => handler()}
      //   >
      //     {text}
      //   </Button>
      //   {loading && (
      //     <CircularProgress
      //       size={24}
      //       sx={{
      //         position: "absolute",
      //         top: "50%",
      //         left: "50%",
      //         marginTop: "-12px",
      //         marginLeft: "-12px",
      //       }}
      //     />
      //   )}
      // </Box>
    );
  };

  return (
    <>
      <EditDefaultVariables
        defaultVariables={variables}
        onSave={updateDefaultVariables}
        isLoading={isUpdatingVariables}
      />
      <EditAccessories
        accessories={accessories}
        setAccessories={setAccessories}
        newItem={newItem}
        setNewItem={setNewItem}
        saveChangesButton={() =>
          saveChangesButton(
            () => addNewAccessory(newItem),
            "Save Item",
            isAddingAccessory
          )
        }
        updateAccessory={updateAccessory}
        isLoading={isAddingAccessory}
      />
      <EditGalvanizedFlats
        galvanizedFlatOptions={galvanizedFlatOptions}
        saveGalvanizedFlat={saveGalvanizedFlat}
        isLoading={isSavingGalvFlatOption}
      />
    </>
  );
};
export default Settings;
