import { useTheme } from "@emotion/react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { toThreeDecimals } from "../../../../components/NewDetail/calculations/utils";

const Coil = (props) => {
  const {
    coil,
    isEstimate,
    showTotal = false,
    showPricePerPiece = false,
    noCalcs = false,
  } = props;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  if (!coil) return null;

  return (
    <Box {...props}>
      {showTotal && !noCalcs ? (
        <Typography variant="h6">
          Coil - $
          {Math.round(
            parseFloat(
              coil &&
                coil.coilCalculations &&
                coil.coilCalculations.totalSellPrice
                ? coil.coilCalculations.totalSellPrice
                : 0.0
            )
          )}
        </Typography>
      ) : (
        <Typography variant="h6">Coil</Typography>
      )}
      <TableContainer component={Paper} sx={{ marginTop: "8px" }}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Width (in.)</TableCell>
              <TableCell align="right">Lineal Feet Of Coil</TableCell>
              {isEstimate && !noCalcs && (
                <TableCell align="right">Square Foot Pricing</TableCell>
              )}
              {isEstimate && !noCalcs && (
                <TableCell align="right">Lineal Foot Pricing</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {coil.width}
              </TableCell>
              <TableCell align="right">{coil.linealFeet}</TableCell>
              {isEstimate && !noCalcs && (
                <TableCell align="right">
                  ${coil.coilCalculations.pricePerSquareFeetOfCoil}
                </TableCell>
              )}
              {isEstimate && !noCalcs && (
                <TableCell align="right">
                  ${coil.coilCalculations.pricePerLinealFootOfCoil}
                </TableCell>
              )}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default Coil;
