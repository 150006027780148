import { useEffect, useState } from "react";
import {
  Card,
  Typography,
  Box,
  Grid,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  IconButton,
  useMediaQuery,
  TextField,
  MenuItem,
  Button,
  Fab,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import S3PresignedImage from "../CustomerComponents/S3/S3PresignedImage";
import Print from "../Print";
import PrintIcon from "@mui/icons-material/Print";

const PrintCatalog = ({ items, companyName }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  // Group by 6
  const sixes = [];
  let i = 0;
  while (i < items.length) {
    if (i % 6 == 0) {
      let six = [];
      let j;
      for (j = 0; j < 6; j++) {
        if (i < items.length) {
          six.push(items[i]);
          i++;
        }
      }
      sixes.push(six);
    }
  }
  console.log("SIXES", sixes);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Print>
        <Print.Trigger
          style={{
            position: "fixed",
            bottom: "80px",
            right: "50px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Fab variant="extended" color="success">
            <PrintIcon /> Print
          </Fab>
        </Print.Trigger>
        <div style={{ margin: "32px" }}>
          <Grid
            container
            spacing={2}
            style={{
              width: "100%",
              breakInside: "avoid",
              breakBefore: "always",
              breakAfter: "always",
            }}
          >
            {items.map((item, itemIndex) => {
              return (
                <Grid item xs={12} sm={6} md={4}>
                  <Box>
                    <Typography sx={{ textAlign: "center" }}>
                      {item.name}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <S3PresignedImage
                        useCard={false}
                        size={250}
                        s3Key={item.image}
                        style={{ height: "100%" }}
                        disabledPreview={false}
                      />
                    </Box>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </div>
        <Print.PrintableData>
          <div
            style={{
              margin: "32px",
              backgroundColor: "#fff",
              color: "#000",
              position: "relative",
            }}
          >
            {sixes.map((six, index) => {
              return (
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    breakInside: "avoid",
                    breakBefore: "always",
                    breakAfter: "always",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      style={{ marginTop: "8px", marginBottom: "8px" }}
                      src="/industry-metal-co-square-logo.png"
                      height={65}
                    />
                  </Box>
                  <Typography variant="h5">
                    TRIM & FLASHING CATALOG - INDUSTRY Std Details
                  </Typography>
                  {companyName && (
                    <Typography variant="body1">{companyName}</Typography>
                  )}

                  <Grid container spacing={1}>
                    {six.map((trim, itemIndex) => {
                      return (
                        <Grid
                          item
                          xs={6}
                          style={{
                            breakInside: "avoid",
                            breakBefore: "always",
                            breakAfter: "always",
                          }}
                        >
                          <Box
                            sx={{
                              border: 1,
                              padding: "4px",
                            }}
                          >
                            <Typography
                              sx={{ textDecorationLine: "underline" }}
                            >
                              {trim.name}
                            </Typography>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <S3PresignedImage
                                useCard={false}
                                size={215}
                                s3Key={trim.image}
                                style={{ height: "100%" }}
                                disabledPreview={true}
                              />
                            </Box>
                          </Box>
                        </Grid>
                      );
                    })}
                  </Grid>
                  <Box
                    sx={{
                      position: "fixed",
                      bottom: "30px",
                      left: "320px",
                    }}
                  >
                    <Typography variant="body2" sx={{ textAlign: "center" }}>
                      INDUSTRY METAL CO
                    </Typography>
                    <Typography variant="body2" sx={{ textAlign: "center" }}>
                      1146 W HILTON AVE
                    </Typography>
                    <Typography variant="body2" sx={{ textAlign: "center" }}>
                      PHOENIX, AZ 85007
                    </Typography>
                    <Typography variant="body2" sx={{ textAlign: "center" }}>
                      602‐609‐6529
                    </Typography>
                  </Box>
                </Box>
              );
            })}
          </div>
        </Print.PrintableData>
      </Print>
    </Box>
  );
};
export default PrintCatalog;
