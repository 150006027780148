import { Box, Card, Typography } from "@mui/material";
import moment from "moment/moment";

const TimeCardRow = ({ timeCard, onClick }) => {
  return (
    <Card style={{ margin: 8, padding: 8 }} onClick={onClick}>
      <Typography>{moment(timeCard.clockIn).format("MM/DD/YYYY")}</Typography>
      <Box>
        {timeCard.noWork ? (
          <Typography>No Work</Typography>
        ) : timeCard.clockOut ? (
          <Typography>
            Hours:{" "}
            {moment(timeCard.clockOut)
              .diff(moment(timeCard.clockIn), "hours", true)
              .toFixed(1)}
          </Typography>
        ) : (
          <Typography>Need to Clock Out</Typography>
        )}
      </Box>
    </Card>
  );
};

export default TimeCardRow;
