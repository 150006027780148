import {
  Box,
  TextField,
  Button,
  MenuItem,
  Typography,
  Tooltip,
  IconButton,
} from "@mui/material";
import { useEffect, useState, useMemo } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Check, Close } from "@mui/icons-material";
import CollapsableCard from "../../../../CollapseableCard";
import PanelCalculations from "./PanelCalculations";
import usePanelSystems from "../../../../../hooks/usePanelSystems";
import calculatePanelCut from "../../../calculations/panelCut";
import { toTwoDecimals, toThreeDecimals } from "../../../calculations/utils";
import TextFieldDebounced from "../../../../TextFieldDebounced";

const PanelDetails = ({ details, action, materialDetails }) => {
  const { panelSystems, getPanelSystems, refreshing } = usePanelSystems();
  const initCut = { name: "", quantity: "", feet: "", inches: "" };
  const [newCut, setNewCut] = useState(initCut);
  const [showPanelForm, setShowPanelForm] = useState(false);
  const toggleForm = () => {
    setNewCut(initCut);
    setShowPanelForm(!showPanelForm);
  };
  const handleChangeNewCutChange = (fieldName) => (e) => {
    let val = e.target.value;
    if (fieldName == "quantity" || fieldName == "feet") {
      val = Math.round(val);
    }
    if (fieldName == "inches") {
      val = toThreeDecimals(val);
      if (val < 0) val = 0;
      // if (val >= 12) val = 0;
    }
    setNewCut((curr) => ({ ...curr, [fieldName]: val }));
  };

  const handleAddNewCut = () => {
    if (
      !Object.values(newCut).some(
        (val) => val === "" || val === undefined || val === null
      )
    ) {
      action({ type: "UPDATE PANEL: ADD CUT", payload: newCut });
      toggleForm();
    }
  };

  // New
  const getPanelSystemIndexByName = (panelSystemDescription) => {
    let i;
    for (i = 0; i < panelSystems.length; i++) {
      let system = panelSystems[i];
      if (system.description === panelSystemDescription) return i;
    }
    return 0;
  };

  const getTypeOptions = (panelSystemDescription) => {
    return panelSystems.find(
      (system) => system.description === panelSystemDescription
    ).panelTypes;
  };

  const getWidthOptions = (panelSystemDescription, panelTypeDescription) => {
    let panelTypes = getTypeOptions(panelSystemDescription);
    let i;
    for (i = 0; i < panelTypes.length; i++) {
      let panelType = panelTypes[i];
      if (panelType.description === panelTypeDescription)
        return panelType.panelWidths;
    }
  };

  const handleSystemSelect = (systemIndex) => {
    const system = panelSystems[systemIndex];
    const defaultPanelType = system.panelTypes[0];
    // panelWidths is an array of strings
    let defaultPanelWidth = parseFloat(defaultPanelType.panelWidths[0]);

    // IF "Flush Wall, SL-175, Striated With Clip Relief" then default Panel Width to 18
    if (defaultPanelType.description == "SL-175, Striated With Clip Relief") {
      if (defaultPanelType.panelWidths.includes("18")) defaultPanelWidth = 18;
    }
    action({
      type: "UPDATE PANEL: SETTINGS",
      payload: {
        panelSystem: system.description,
        panelType: defaultPanelType.description,
        panelTakeUp: defaultPanelType.panelTakeUp,
        panelWidth: defaultPanelWidth,
        profile: "",
      },
    });
  };

  const handleTypeSelect = (panelSystem, systemType) => {
    // // Use reducer to update default width selected
    let widthOptions = getWidthOptions(panelSystem, systemType);
    let typeOptions = getTypeOptions(panelSystem);
    let selectePaneltype = typeOptions.find((t) => t.description == systemType);

    let defaultPanelWidth = parseFloat(widthOptions[0]);
    // IF "Flush Wall, SL-175, Striated With Clip Relief" then default Panel Width to 18
    if (systemType == "SL-175, Striated With Clip Relief") {
      if (widthOptions.includes("18")) defaultPanelWidth = 18;
    }

    action({
      type: "UPDATE PANEL: SETTINGS",
      payload: {
        panelType: systemType,
        panelTakeUp: selectePaneltype.panelTakeUp,
        panelWidth: defaultPanelWidth,
        //profile: "",
      },
    });
  };

  const handleInitial = (panelSystem, systemType) => {
    // // Use reducer to update default width selected
    //let widthOptions = getWidthOptions(panelSystem, systemType);

    let typeOptions = getTypeOptions(panelSystem);
    let selectePaneltype = typeOptions.find((t) => t.description == systemType);
    console.log("UPDATE PANEL: SETTINGS", {
      panelType: systemType,
      panelTakeUp: selectePaneltype.panelTakeUp,
      //: parseFloat(widthOptions[0]),
      //profile: "",
    });
    action({
      type: "UPDATE PANEL: SETTINGS",
      payload: {
        panelType: systemType,
        panelTakeUp: selectePaneltype.panelTakeUp,
        //: parseFloat(widthOptions[0]),
        //profile: "",
      },
    });
  };

  const handleCalcOveride = (key) => (e) => {
    action({
      type: "UPDATE PANEL: OVERRIDE CALCULATION",
      payload: { key, value: parseFloat(e.target.value) },
    });
  };

  const resetOverrideValue = (key) => () => {
    action({ type: "UPDATE PANEL: RESET OVERIDE", payload: key });
  };

  useEffect(() => {
    if (!details.calculations) {
      action({ type: "UPDATE PANEL: SET CALCULATIONS" });
    }
  }, [details]);

  useEffect(() => {
    action({ type: "UPDATE PANEL: SET CALCULATIONS" });
  }, [
    materialDetails.coilCostPerSqFt,
    materialDetails.coilCostPerSqFtOriginal,
  ]);

  useEffect(() => {
    if (
      details.calculations &&
      details.panelTakeUp == undefined &&
      details.panelTakeUp == null &&
      panelSystems &&
      panelSystems.length > 0
    ) {
      handleInitial(details.panelSystem, details.panelType);
    }
  }, [details, panelSystems]);

  if (panelSystems.length == 0) return null;

  return (
    <CollapsableCard
      title={`Panel - $${Math.round(
        toTwoDecimals(
          details && details.calculations && details.calculations.totalSellPrice
            ? details.calculations.totalSellPrice
            : 0.0
        )
      )}`}
      defaultOpen={details?.cuts && details?.cuts.length > 0}
    >
      <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
        <Typography>
          Square Foot Pricing: $
          {materialDetails.coilCostPerSqFt !=
          materialDetails.coilCostPerSqFtOriginal
            ? parseFloat(materialDetails.coilCostPerSqFt)
            : parseFloat(materialDetails.coilCostPerSqFtOriginal)}
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
          <TextField
            select
            variant="outlined"
            label="Panel System"
            id="system"
            name="Panel System"
            size="small"
            fullWidth
            value={getPanelSystemIndexByName(details.panelSystem)}
            onChange={(e) => handleSystemSelect(e.target.value)}
          >
            {panelSystems?.map((sys, index) => (
              <MenuItem key={sys.description} value={index}>
                {sys.description}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            select
            variant="outlined"
            label="Panel Type"
            id="type"
            name="Panel Type"
            size="small"
            fullWidth
            value={details.panelType}
            onChange={(e) => {
              handleTypeSelect(details.panelSystem, e.target.value);
            }}
          >
            {getTypeOptions(details.panelSystem).map((type) => (
              <MenuItem key={type.description} value={type.description}>
                {type.description}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            select
            variant="outlined"
            label="Panel Width"
            id="width"
            name="Panel Width"
            size="small"
            fullWidth
            value={details.panelWidth}
            onChange={(e) => {
              action({ type: "UPDATE PANEL: WIDTH", payload: e.target.value });
            }}
          >
            {getWidthOptions(details.panelSystem, details.panelType).map(
              (width) => (
                <MenuItem value={Number(width)} key={width}>
                  {width}
                </MenuItem>
              )
            )}
          </TextField>
        </Box>
        {details?.calculations && (
          <PanelCalculations
            calculations={details?.calculations}
            overrides={details?.overrides}
            handleChange={handleCalcOveride}
            resetOverride={resetOverrideValue}
          />
        )}

        <Box>
          <Box sx={{ display: "flex", flexDirection: "row", gap: ".5rem" }}>
            <Typography variant="overline">Cut List:</Typography>
            {!showPanelForm && (
              <Button size="small" variant="text" onClick={toggleForm}>
                + Add
              </Button>
            )}
          </Box>
          {showPanelForm && (
            <PanelForm
              newCut={newCut}
              handleChange={handleChangeNewCutChange}
              handleSubmit={handleAddNewCut}
              toggleForm={toggleForm}
            />
          )}
          {details.cuts.map((cut, index) => {
            let panelCutCalculations = calculatePanelCut(
              cut,
              details,
              details.calculations,
              details.calculations,
              details.overrides
            );
            return (
              <Box
                key={cut.name + index}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  padding: ".5rem",
                  border: "1px solid #E0E0E0",
                  borderRadius: ".5rem",
                }}
              >
                <TextFieldDebounced
                  variant="standard"
                  size="small"
                  label="Name"
                  value={cut.name}
                  // InputProps={{
                  //   readOnly: true,
                  //   disableUnderline: true,
                  // }}
                  onChange={(e) =>
                    action({
                      type: "UPDATE PANEL: EDIT CUT",
                      payload: {
                        index: index,
                        update: {
                          name: e.target.value,
                        },
                      },
                    })
                  }
                />
                <TextFieldDebounced
                  sx={{ marginLeft: "8px" }}
                  type="number"
                  onWheel={(event) => {
                    event.preventDefault();
                  }}
                  variant="standard"
                  size="small"
                  label="Quantity"
                  value={cut.quantity}
                  // InputProps={{
                  //   readOnly: true,
                  //   disableUnderline: true,
                  // }}
                  onChange={(e) =>
                    action({
                      type: "UPDATE PANEL: EDIT CUT",
                      payload: {
                        index: index,
                        update: {
                          quantity: e.target.value,
                        },
                      },
                    })
                  }
                />
                <TextFieldDebounced
                  sx={{ marginLeft: "8px" }}
                  type="number"
                  onWheel={(event) => {
                    event.preventDefault();
                  }}
                  variant="standard"
                  size="small"
                  label="Feet"
                  value={cut.feet}
                  // InputProps={{
                  //   readOnly: true,
                  //   disableUnderline: true,
                  // }}
                  onChange={(e) =>
                    action({
                      type: "UPDATE PANEL: EDIT CUT",
                      payload: {
                        index: index,
                        update: {
                          feet: e.target.value,
                        },
                      },
                    })
                  }
                />
                <TextFieldDebounced
                  sx={{ marginLeft: "8px" }}
                  type="number"
                  onWheel={(event) => {
                    event.preventDefault();
                  }}
                  variant="standard"
                  size="small"
                  label="Inches"
                  value={cut.inches}
                  // InputProps={{
                  //   readOnly: true,
                  //   disableUnderline: true,
                  // }}
                  onChange={(e) =>
                    action({
                      type: "UPDATE PANEL: EDIT CUT",
                      payload: {
                        index: index,
                        update: {
                          inches: e.target.value,
                        },
                      },
                    })
                  }
                />
                <TextField
                  sx={{ marginLeft: "8px" }}
                  type="number"
                  onWheel={(event) => {
                    event.preventDefault();
                  }}
                  variant="standard"
                  size="small"
                  label="LF Coil (no Waste)"
                  value={panelCutCalculations.linealFeetOfCoilNoWaste}
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true,
                  }}
                />
                <TextField
                  type="number"
                  onWheel={(event) => {
                    event.preventDefault();
                  }}
                  variant="standard"
                  size="small"
                  label="Finished Panel Sq Ft"
                  value={panelCutCalculations.finishedPanelSquareFeet}
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true,
                  }}
                />
                <Tooltip title="Remove">
                  <IconButton
                    size="small"
                    onClick={() =>
                      action({
                        type: "UPDATE PANEL: REMOVE CUT",
                        payload: index,
                      })
                    }
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                </Tooltip>
              </Box>
            );
          })}
        </Box>
      </Box>
    </CollapsableCard>
  );
};
export default PanelDetails;

function PanelForm({ newCut, handleChange, handleSubmit, toggleForm }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: ".5rem",
        marginBottom: ".5rem",
      }}
    >
      <TextField
        onFocus={(e) => e.target.select()}
        size="small"
        label="Name"
        value={newCut.name}
        onChange={handleChange("name")}
      />
      <TextField
        onFocus={(e) => e.target.select()}
        type="number"
        onWheel={(event) => {
          event.preventDefault();
        }}
        size="small"
        label="Quantity"
        value={newCut.quantity}
        onChange={handleChange("quantity")}
      />
      <TextField
        onFocus={(e) => e.target.select()}
        type="number"
        onWheel={(event) => {
          event.preventDefault();
        }}
        size="small"
        label="Feet"
        value={newCut.feet}
        onChange={handleChange("feet")}
      />
      <TextField
        onFocus={(e) => e.target.select()}
        type="number"
        onWheel={(event) => {
          event.preventDefault();
        }}
        size="small"
        label="Inches"
        value={newCut.inches}
        onChange={handleChange("inches")}
      />
      <Button
        color="success"
        size="small"
        variant="outlined"
        onClick={handleSubmit}
      >
        <Check />
      </Button>
      <Button
        color="error"
        size="small"
        variant="outlined"
        onClick={toggleForm}
      >
        <Close />
      </Button>
    </Box>
  );
}
