import { useState, useContext, useEffect } from "react";
import { AuthContext } from "../../context/AuthContext";
import moment from "moment";
import TimeCardApprovals from "../../components/TimeCards/TimeCardApprovals";

import { CircularProgress, Box, Typography } from "@mui/material";
import { SnackAlertContext } from "../../context/SnackAlertContext";

const TimeCards = () => {
  const { authToken, ezorder } = useContext(AuthContext);
  const [weeklyTimeCards, setWeeklyTimeCards] = useState();
  const { openSnackMessage } = useContext(SnackAlertContext);

  const [isLoadingTimeCards, setIsLoadingTimeCards] = useState(false);
  const [isApproving, setIsApproving] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isCreating, setIsCreating] = useState(false);

  const initialWeekOf = moment().startOf("isoweek");

  const [weekOf, setWeekOf] = useState(initialWeekOf);

  const getWeeklyTimeCards = async (firstDayOfWeek) => {
    try {
      setIsLoadingTimeCards(true);
      const response = await ezorder.post(
        `/shop/admin/crew/time-cards/week`,
        {
          firstDayOfWeek: firstDayOfWeek,
          lastDayOfWeek: moment(moment(firstDayOfWeek))
            .add(6, "day")
            .set({ hour: 23, minute: 59, second: 59 }) // Time range on DB query needs to be INCLUSIVE
            .format(),
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      if (response.status === 200 || response.status == 201) {
        setWeeklyTimeCards(response.data.timeCards);
      }
      setIsLoadingTimeCards(false);
    } catch (error) {
      setIsLoadingTimeCards(false);
      throw Error("Unable to load timecards", { cause: error });
    }
  };

  const deleteTimeCardById = async (timeCardId) => {
    try {
      setIsUpdating(true);
      const response = await ezorder.delete(
        `/shop/admin/crew/time-cards/${timeCardId}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      getWeeklyTimeCards(weekOf);
      openSnackMessage("success", "Time Card Deleted");
      return true;
    } catch (error) {
      console.log(error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage("error", error.response.data.error);
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", error);
      }
      return false;
    } finally {
      setIsUpdating(false);
    }
  };

  const updateTimeCard = async (userId, timeCardId, body) => {
    try {
      setIsUpdating(true);
      const response = await ezorder.put(
        `/shop/admin/crew/${userId}/time-cards/${timeCardId}`,
        body,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      getWeeklyTimeCards(weekOf);
      openSnackMessage("success", "Time Card Updated");
      return true;
    } catch (error) {
      console.log(error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage("error", error.response.data.error);
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", error);
      }
      return false;
    } finally {
      setIsUpdating(false);
    }
  };

  const createTimeCard = (userId) => async (body) => {
    try {
      setIsCreating(true);
      // console.log("BODY", body);
      const response = await ezorder.post(
        `/shop/admin/crew/${userId}/time-cards`,
        body,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      getWeeklyTimeCards(weekOf);
      openSnackMessage("success", "Time Card Created");
      return true;
    } catch (error) {
      console.log(error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage("error", error.response.data.error);
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", error);
      }
      return false;
    } finally {
      setIsCreating(false);
    }
  };

  const removeApproval = async (weeklyTimeApprovalId) => {
    setIsApproving(true);
    try {
      const response = await ezorder.delete(
        `/shop/admin/crew/time-card/approval-requests/${weeklyTimeApprovalId}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      getWeeklyTimeCards(weekOf);
      openSnackMessage("success", "Removed Approval");
    } catch (error) {
      console.log(error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage("error", error.response.data.error);
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", error);
      }
    } finally {
      setIsApproving(false);
    }
  };

  const approveWeek = async (userId, dateApproved) => {
    setIsApproving(true);
    try {
      const response = await ezorder.put(
        `/shop/admin/crew/${userId}/time-cards`,
        {
          weekOf: weekOf,
          dateApproved: dateApproved,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      getWeeklyTimeCards(weekOf);
      openSnackMessage("success", "Approved");
    } catch (error) {
      console.log(error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage("error", error.response.data.error);
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", error);
      }
    } finally {
      setIsApproving(false);
    }
  };

  useEffect(() => {
    getWeeklyTimeCards(weekOf);
    // getWeeklyTimeCards("07/24/2022 00:00:00");
  }, [, weekOf]);

  if (!weeklyTimeCards) {
    return null;
  } else if (isLoadingTimeCards) {
    return (
      <Box sx={{ display: "flex" }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <TimeCardApprovals
        weeklyTimeCards={weeklyTimeCards}
        setWeeklyTimeCards={setWeeklyTimeCards}
        updateTimeCard={updateTimeCard}
        createTimeCard={createTimeCard}
        deleteTimeCard={deleteTimeCardById}
        weekOf={weekOf}
        setWeekOf={setWeekOf}
        approveWeek={approveWeek}
        removeApproval={removeApproval}
        isApproving={isApproving}
        isUpdating={isUpdating}
        isCreating={isCreating}
      />
    </>
  );
};
export default TimeCards;
