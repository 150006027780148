import { Box, useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";
import TopNav from "./Nav/TopNav";
import BottomNav from "./Nav/BottomNav";
import { NotificationProvider } from "../../context/NotificationContext";
import { SnackAlertContext } from "../../context/SnackAlertContext";
import { useContext } from "react";
import SnackAlert from "../../components/SnackAlert";

const ShopDashboard = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const {
    openSnackAlert,
    setOpenSnackAlert,
    snackAlertSeverity,
    snackAlertTitle,
    snackAlertMessage,
  } = useContext(SnackAlertContext);

  return (
    <>
      <NotificationProvider>
        {isSmallScreen ? (
          <Box>
            <BottomNav />
          </Box>
        ) : (
          <Box>
            <TopNav />
          </Box>
        )}
      </NotificationProvider>
      <SnackAlert
        open={openSnackAlert}
        setOpen={setOpenSnackAlert}
        severity={snackAlertSeverity}
        title={snackAlertTitle}
        message={snackAlertMessage}
      />
    </>
  );
};
export default ShopDashboard;
