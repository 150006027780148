// export const toDecimal = (num) => parseFloat(parseFloat(num).toFixed(2));
export const toThreeDecimals = (num) => Number(parseFloat(num).toFixed(3)); // Use for all measurements and calc
export const toTwoDecimals = (num) => parseFloat(parseFloat(num).toFixed(2)); // Use for Dollars and Hours
export const toFourDecimals = (num) => parseFloat(parseFloat(num).toFixed(4)); // Use for GROSS PROFIT (STORED AS DECIMAL) then 100xed

export const combineVariablesAndOverrides = (variables, overrides) => {
  const overrideObject = overrides ?? {};
  let useThese = {};
  for (const key in variables) {
    if (overrideObject[key] !== null && overrideObject[key] !== undefined) {
      useThese[key] = overrideObject[key];
    } else {
      useThese[key] = variables[key];
    }
  }
  return useThese;
};

export const applyMarkup = (value, markUpPercentage) => {
  let multiplier = 1 + markUpPercentage / 100.0;
  return toThreeDecimals(value * multiplier);
};

export const valueIfNotOverridden = (overrideObject) => (key, value) =>
  overrideObject[key] === null || overrideObject[key] === undefined
    ? value
    : parseFloat(overrideObject[key]);

export const findCalculationsObjects = (orderItem) => {
  // returns an array of calculations objects
  switch (orderItem.objectType) {
    case "FlatSheet":
      return [orderItem.flatSheetCalculations] ?? [];
    case "Coil":
      return [orderItem.coilCalculations] ?? [];
    case "Panel":
      return [orderItem.calculations] ?? [];
    case "TrimAndFlashing":
      return orderItem?.trimPieces?.map((item) => item.calculations) ?? [];
    case "CopingCap":
      return orderItem?.capPieces?.map((item) => item.calculations) ?? [];
    case "CopingCapCleat":
      return orderItem?.cleatPieces?.map((item) => item.calculations) ?? [];
    case "SplicePlate":
      return orderItem?.plates?.map((item) => item.calculations) ?? [];
    case "BenchWork":
      return orderItem?.benchWorkPieces?.map((item) => item.calculations) ?? [];
    case "Accessory":
      return orderItem?.items?.map((item) => item.calculations) ?? [];
    default:
      break;
  }
};
