import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import {
  getCoilDetails,
  getPanelDetails,
} from "../../../../components/NewDetail/orderUtils";
import { toThreeDecimals } from "../../../../components/NewDetail/calculations/utils";

const getFlatsNeeded = (order) => {
  let flatsNeeded = 0;
  let galvFlatsNeeded = 0;
  let accessoryFlats = 0;
  for (const orderItem of order.items) {
    switch (orderItem.objectType) {
      case "FlatSheet":
        let flatSheet = orderItem;
        flatsNeeded += flatSheet.quantity;
        break;
      case "TrimAndFlashing":
        let trimPieces = orderItem.trimPieces;
        for (const trimPiece of trimPieces) {
          let flats = parseInt(trimPiece.calculations.flatSheetsNeeded);
          console.log("TrimAndFlashing Flats", flats);
          flatsNeeded += flats;
        }
        break;
      case "CopingCap":
        let capPieces = orderItem.capPieces;
        for (const capPiece of capPieces) {
          let flats = parseInt(capPiece.calculations.flatSheetsNeeded);
          console.log("CopingCap Flats", flats);
          flatsNeeded += flats;
        }
        break;
      case "CopingCapCleat":
        let cleatPieces = orderItem.cleatPieces;
        for (const cleatPiece of cleatPieces) {
          let flats = parseInt(cleatPiece.calculations.flatSheetsNeeded);
          console.log("CopingCapCleat GALV Flats", flats);

          galvFlatsNeeded += flats;
        }
        break;
      case "BenchWork":
        let benchWorkPieces = orderItem.benchWorkPieces;
        for (const benchWorkPiece of benchWorkPieces) {
          flatsNeeded += parseInt(benchWorkPiece.flatSheets);
        }
        break;
      case "SplicePlate":
        let plates = orderItem.plates;
        for (const plate of plates) {
          let flats = parseInt(plate.calculations.flatSheetsNeeded);
          console.log("SplicePlate Flats", flats);
          flatsNeeded += flats;
        }
        break;
      case "Accessory":
        for (const accessoryItem of orderItem.items) {
          if (accessoryItem.name.toLowerCase().includes("flat sheet")) {
            accessoryFlats += accessoryItem.quantity;
          }
        }
        break;
      default:
        break;
    }
  }

  let materials = {
    flatsNeeded,
    galvFlatsNeeded,
    accessoryFlats,
  };
  console.log("MATERIALS NEEDED", materials);
  return materials;
};

const MaterialSummary = (props) => {
  const { order, isEstimate } = props;

  const productMap = new Map();
  for (let i = 0; i < order.items.length; i++) {
    let orderItem = order.items[i];
    productMap.set(`${orderItem.objectType}`, orderItem);
  }
  const panelOrderItem = getPanelDetails(order); // coil calcs
  const coilOrderItem = getCoilDetails(order);
  const flatTotals = getFlatsNeeded(order);
  let extraCoil =
    coilOrderItem && coilOrderItem.linealFeet ? coilOrderItem.linealFeet : 0;

  if (!order) return null;
  return (
    <Box {...props}>
      <Typography variant="h5">Material Summary</Typography>
      <TableContainer component={Paper} sx={{ marginTop: "8px" }}>
        <Table sx={{ minWidth: 300 }} size="small" aria-label="a dense table">
          <TableHead>
            {!isEstimate && (
              <TableRow>
                <TableCell>Flats Needed To Complete Order + Extra FS</TableCell>
                <TableCell align="right">
                  {flatTotals.flatsNeeded + flatTotals.accessoryFlats} FS
                </TableCell>
              </TableRow>
            )}
            {!isEstimate && (
              <TableRow>
                <TableCell>
                  Total Lineal Footage of Coil Needed to Complete Order + Extra
                  Coil
                </TableCell>
                <TableCell align="right">
                  {parseFloat(
                    panelOrderItem &&
                      panelOrderItem.calculations &&
                      panelOrderItem.calculations.linealFeetOfCoilWithWaste
                      ? panelOrderItem.calculations.linealFeetOfCoilWithWaste
                      : 0
                  ) + parseFloat(extraCoil)}{" "}
                  LF
                </TableCell>
              </TableRow>
            )}
            {!isEstimate && (
              <TableRow>
                <TableCell>Coil Width Needed to Create Panel</TableCell>
                <TableCell align="right">
                  {panelOrderItem
                    ? panelOrderItem.calculations.masterCoilSizeNeeded
                    : 0}{" "}
                  in.
                </TableCell>
              </TableRow>
            )}
            {!isEstimate && (
              <TableRow>
                <TableCell>Total Coil Square Footage</TableCell>
                <TableCell align="right">
                  {panelOrderItem
                    ? panelOrderItem.calculations.finishedPanelSquareFeet
                    : 0}{" "}
                  SF
                </TableCell>
              </TableRow>
            )}

            {!isEstimate && (
              <TableRow>
                <TableCell>Total Coil Square Footage inc Drop</TableCell>
                <TableCell align="right">
                  {panelOrderItem
                    ? panelOrderItem.calculations
                        .squareFeetOfCoilNeededWithWaste
                    : 0}{" "}
                  SF
                </TableCell>
              </TableRow>
            )}

            <TableRow>
              <TableCell>Total Panel Area Coverage</TableCell>
              <TableCell align="right">
                {panelOrderItem
                  ? toThreeDecimals(
                      (panelOrderItem.calculations.linealFeetOfCoilNoWaste *
                        panelOrderItem.panelWidth) /
                        12
                    )
                  : 0}{" "}
                SF
              </TableCell>
            </TableRow>
            {!isEstimate && (
              <TableRow>
                <TableCell>Total Galvanized Flats for Cleat</TableCell>
                <TableCell align="right">
                  {flatTotals.galvFlatsNeeded} FS
                </TableCell>
              </TableRow>
            )}
          </TableHead>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default MaterialSummary;
