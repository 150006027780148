import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

// recieves an array of orders as props
export default function EmployeeTable({ employees, onSelect }) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell align="right">Email</TableCell>
            <TableCell align="right">Role</TableCell>
            <TableCell align="right">Active</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {employees.map((employee, index) => (
            <TableRow
              hover
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              onClick={() => {
                onSelect(employee);
              }}
            >
              <TableCell component="th" scope="row">
                {employee.fullName}
              </TableCell>
              <TableCell align="right">{employee.email}</TableCell>
              <TableCell align="right">
                {employee.role == "EZORDER_ADMIN"
                  ? "Admin"
                  : employee.role == "EZORDER_EMPLOYEE"
                  ? "Shop"
                  : ""}
              </TableCell>
              <TableCell
                align="right"
                sx={{
                  color: employee.deletedAt ? "red" : "inherit",
                }}
              >
                {employee.deletedAt ? "Deactivated" : "Active"}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
