import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import { useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { useState } from "react";
import { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Company from "../../auth/Signup/Company";
import { LoadingButton } from "@mui/lab";
import usePageName from "../../../hocs/usePageName";

const Contact = () => {
  usePageName({ heading: "Contact Us" });
  const { authToken, decoded, ezorder } = useContext(AuthContext);
  const [salesRep, setSalesRep] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const getCompanySalesRep = async () => {
    try {
      setLoading(true);
      const authHeader = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      const res = await ezorder.get(
        `/companies/${decoded.companyId}`,
        authHeader
      );
      let company = res.data.company;
      setSalesRep(company.assignedSalesRep);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCompanySalesRep();
  }, []);

  return (
    <>
      {salesRep ? (
        <Box sx={{ padding: "16px" }}>
          <Typography variant="h4">Your Sales Rep</Typography>
          <Typography>Name: {salesRep.fullName}</Typography>
          <Typography>Email: {salesRep.email}</Typography>
          <Typography>Phone: {salesRep.phone}</Typography>
        </Box>
      ) : (
        <Box>
          <Typography>No assigned Sales Rep</Typography>
        </Box>
      )}
    </>
  );
};

export default Contact;
