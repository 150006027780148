import { Box, Card, Stack, Typography } from "@mui/material";
import { getCopingCapCleatDetails } from "../../../../orderUtils";
import { toTwoDecimals } from "../../../../calculations/utils";
import { getCleatTotals } from "../../../../calculations/orderTotals";

const CleatSummary = ({ order, isPrinting = false }) => {
  const cleatOrderItem = getCopingCapCleatDetails(order);
  console.log("CLEAT ORDER ITEM", cleatOrderItem);

  const totals = getCleatTotals(cleatOrderItem);

  if (
    cleatOrderItem &&
    cleatOrderItem.cleatPieces &&
    cleatOrderItem.cleatPieces.length > 0
  )
    return (
      <Box
        sx={{ padding: 2, margin: 1, color: isPrinting ? "#000" : "inherit" }}
      >
        <Typography variant={"h6"}>CLEAT</Typography>
        <Stack direction={"row"} spacing={1}>
          <Stack direction={"column"} spacing={1}>
            <Typography>Total No. of Pieces:</Typography>
            <Typography>Total Flats:</Typography>
            <Typography>Flat Sheet Cost:</Typography>
            <Typography>Out The Door Cost:</Typography>
            <Typography>Total Material Cost:</Typography>
            <Typography>Total Labor Cost:</Typography>
            <Typography>Total Labor Hours:</Typography>
            <Typography>Total Hard Cost:</Typography>
            <Typography>GP $:</Typography>
            <Typography>GP %:</Typography>
          </Stack>
          <Stack direction={"column"} spacing={1}>
            <Typography>{parseFloat(totals.totalNumberOfPieces)}</Typography>
            <Typography>{parseFloat(totals.totalFlats)}</Typography>
            <Typography>${toTwoDecimals(totals.totalFlatSheetCost)}</Typography>
            <Typography>${toTwoDecimals(totals.outTheDoorCost)}</Typography>
            <Typography>${toTwoDecimals(totals.totalMaterialCost)}</Typography>
            <Typography>${toTwoDecimals(totals.totalLaborCost)}</Typography>
            <Typography>{toTwoDecimals(totals.totalLaborHours)}</Typography>
            <Typography>${toTwoDecimals(totals.totalHardCost ?? 0)}</Typography>
            <Typography>
              ${toTwoDecimals(totals.totalGPDollars ?? 0)}
            </Typography>
            <Typography>
              {toTwoDecimals(totals.totalGPPercent * 100 ?? 0)}%
            </Typography>
          </Stack>
        </Stack>
      </Box>
    );
};

export default CleatSummary;
