import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import {
  Card,
  CircularProgress,
  IconButton,
  InputAdornment,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const LoginForm = ({ loginHandler, loading, errorMsg }) => {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&?.,*])(?=.{8,})/;
  const logInValidationSchema = Yup.object({
    email: Yup.string()
      .email("Please enter valid email")
      .required("Email Address is Required"),
    password: Yup.string().required("Password Required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: logInValidationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: (values) => {
      loginHandler(values);
    },
  });

  return (
    <div>
      <Container maxWidth="xs" sx={{ paddingBottom: "24px" }}>
        <Card
          sx={{
            padding: "24px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box
            component="form"
            onSubmit={formik.handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              color="primary"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              autoComplete="email"
              autoFocus
            />
            {formik.errors.email ? <div>{formik.errors.email}</div> : null}
            <TextField
              color="primary"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              id="password"
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              value={formik.values.password}
              onChange={formik.handleChange}
              autoComplete="current-password"
            />
            {formik.errors.password ? (
              <div>{formik.errors.password}</div>
            ) : null}

            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />

            <Box sx={{ m: 1, position: "relative" }}>
              {errorMsg && (
                <Box>
                  <Typography color={"red"}>{errorMsg}</Typography>
                </Box>
              )}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 1 }}
                disabled={loading}
              >
                Sign In
              </Button>
              {/* <Button
                variant="text"
                size="small"
                sx={{ mt: 1 }}
                disabled={loading}
                onClick={() => {
                  console.log("signup");
                  navigate("/signup");
                }}
              >
                Don't Have an Account? Signup
              </Button> */}
              <Button
                variant="text"
                size="small"
                sx={{ mt: 0 }}
                disabled={loading}
                onClick={() => {
                  console.log("signup");
                  navigate("/forgot-password");
                }}
              >
                Forgot your password?
              </Button>
              {loading && (
                <CircularProgress
                  size={24}
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              )}
            </Box>
          </Box>
        </Card>
      </Container>
    </div>
  );
};

export default LoginForm;
