import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../context/AuthContext";

const useShopEmployees = (authToken) => {
  const { ezorder } = useContext(AuthContext);
  const [shopEmployees, setShopEmployees] = useState([]);

  const getShopEmployees = async () => {
    try {
      const res = await ezorder.get(`/users/shop`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      setShopEmployees(res.data.shopEmployees);
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  useEffect(() => {
    getShopEmployees();
  }, []);

  return { shopEmployees };
};

export default useShopEmployees;
