import {
  Card,
  Typography,
  Box,
  Container,
  CircularProgress,
  Paper,
  TableContainer,
  Table,
  TableBody,
} from "@mui/material";

import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import PullToRefresh from "react-simple-pull-to-refresh";
import { useDebounce } from "use-debounce";

const ListComponentCopy = ({ ListComponent, items, renderItem }) => {
  let toCopy = ListComponent();
  let children = items.map(renderItem);
  let cloned = React.cloneElement(toCopy, toCopy.props, children);
  return cloned;
};

const InfiniteScrollerTable = ({
  TableContainerComponent,
  tableContainerStyle,
  tableStyle,
  TableHead,
  items,
  renderRow,
  onRefresh,
  onEndReached,
  loading,
  ListFooterComponent,
  tableRef,
}) => {
  const [ref, inView, entry] = useInView({
    /* Optional options */
    threshold: 0.5, //Number between 0 and 1 indicating the percentage that should be visible before triggering. Can also be an array of numbers, to create multiple trigger points.
  });

  const [inViewDebounce] = useDebounce(inView, 1000);

  useEffect(() => {
    if (items.length > 0 && inView) {
      console.log("Footer Element In View. Load More...");
      onEndReached();
    }
  }, [inViewDebounce]);

  return (
    <PullToRefresh
      onRefresh={() =>
        // Not sure why but they wrote this component to take a Promise for onRefresh
        Promise.resolve().then(() => {
          onRefresh();
        })
      }
      refreshingContent={
        <CircularProgress color="primary" style={{ margin: "8px" }} />
      }
    >
      <TableContainer
        sx={tableContainerStyle}
        component={TableContainerComponent ? TableContainerComponent : Paper}
      >
        <Table ref={tableRef} sx={tableStyle}>
          {TableHead()}
          <TableBody>{items.map(renderRow)}</TableBody>
        </Table>
        <div
          ref={ref}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {loading ? (
            <CircularProgress color="primary" style={{ margin: "8px" }} />
          ) : (
            ListFooterComponent()
          )}
        </div>
      </TableContainer>
    </PullToRefresh>
  );
};
export default InfiniteScrollerTable;
