import SquareFootPricingForm from "../../../components/SquareFootPricing/SquareFootPricingForm";

const SquareFootPricing = () => {
  return (
    <>
      <h1>Panel Square Foot Pricing</h1>
      <SquareFootPricingForm />
    </>
  );
};

export default SquareFootPricing;
