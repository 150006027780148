import { useContext, useState, useEffect } from "react";
import AdminRoutes from "./AdminRoutes";
import CustomerRoutes from "./CustomerRoutes";
import AuthRoutes from "./AuthRoutes";
import { AuthContext } from "../context/AuthContext";
import ShopRoutes from "./ShopRoutes";

const ApplicationRoutes = () => {
  const { role, authToken } = useContext(AuthContext);
  if (role === undefined || authToken === undefined) {
    return <div>Loading...</div>;
  }

  switch (role) {
    case "OWNER":
    case "USER":
      return <CustomerRoutes />;
    case "EZORDER_ADMIN":
      return <AdminRoutes />;
    case "EZORDER_EMPLOYEE":
      return <ShopRoutes />;
    default:
      return <AuthRoutes />;
  }
};

export default ApplicationRoutes;
