import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  ListItem,
  ListItemText,
  TextField,
  useMediaQuery,
} from "@mui/material";
import useCustomerProjectsByAdmin from "../../../../hooks/useCustomerProjectsByAdmin";
import SearchBar from "../../../SearchBar";
import InfiniteScroller from "../../../InfiniteScroller/InfiniteScroller";
import { useContext, useState } from "react";
import { AuthContext } from "../../../../context/AuthContext";
import { useTheme } from "@emotion/react";
import ListFooter from "../../../InfiniteScroller/ListFooter";
import { LoadingButton } from "@mui/lab";
import { Check } from "@mui/icons-material";

const ChangeOrderProjectDialog = ({
  isVendorOrSupplier,
  companyId,
  open,
  setOpen,
  isSubmitting,
  handleSubmitProject,
  handleProjectSelect,
}) => {
  const { authToken } = useContext(AuthContext);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [projectName, setProjectName] = useState("");
  const [
    project_searchQuery,
    project_setSearchQuery,
    project_results,
    project_onRefresh,
    project_refreshing,
    project_onLoadMore,
    project_loadingMore,
    project_total,
    project_hasMore,
  ] = useCustomerProjectsByAdmin(authToken, 20, companyId);
  return (
    <Dialog
      keepMounted
      scroll={"paper"}
      maxWidth="sm"
      fullWidth={true}
      fullScreen={isSmallScreen}
      PaperProps={{
        style: {
          background: theme.palette.secondary.main,
        },
      }}
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <DialogTitle>
        Change {isVendorOrSupplier ? "Customer" : "Project"}
      </DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <DialogContentText>
          Select an Existing {isVendorOrSupplier ? "Customer" : "Project"}
        </DialogContentText>
        <SearchBar
          label={isVendorOrSupplier ? "Search Customers" : "Search Projects"}
          searchQuery={project_searchQuery}
          setSearchQuery={project_setSearchQuery}
        />
        <Box
          sx={{
            flex: 1,
            minHeight: project_results.length > 0 ? "200px" : "0px",
            overflowY: "scroll",
          }}
        >
          <InfiniteScroller
            items={project_results}
            renderItem={(item, index) => {
              return (
                <>
                  <ListItem
                    key={item.id}
                    project={item}
                    onClick={() => {
                      handleProjectSelect(item);
                    }}
                  >
                    <ListItemText primary={item.name} />
                  </ListItem>
                  <Divider />
                </>
              );
            }}
            onRefresh={project_onRefresh}
            onEndReached={project_onLoadMore}
            loading={project_refreshing || project_loadingMore}
            ListFooterComponent={() => {
              return (
                <ListFooter
                  hasMore={project_hasMore}
                  onClick={project_onLoadMore}
                />
              );
            }}
          />
        </Box>
        <DialogContentText sx={{ marginTop: "32px" }}>
          Or Create a New {isVendorOrSupplier ? "Customer" : "Project"}
        </DialogContentText>
        <Box
          sx={{
            marginTop: "8px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <TextField
            size="small"
            sx={{ flex: 1 }}
            label={`Enter New ${
              isVendorOrSupplier ? "Customer" : "Project"
            } Name`}
            value={projectName}
            onChange={(e) => {
              setProjectName(e.target.value);
            }}
          />
          <LoadingButton
            loading={isSubmitting}
            sx={{ marginLeft: "8px" }}
            variant="contained"
            color="success"
            onClick={() => {
              handleSubmitProject({
                name: projectName,
              });
            }}
          >
            <Check />
          </LoadingButton>
          {/* <IconButton variant="contained" aria-label="Example">
              <ChevronRight />
            </IconButton> */}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setOpen(false);
          }}
          autoFocus
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChangeOrderProjectDialog;
