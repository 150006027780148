import { useTheme } from "@emotion/react";
import { CameraAlt, Close, Upload } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextareaAutosize,
  useMediaQuery,
} from "@mui/material";
import { useRef } from "react";

const AddShopCommentAndPictureDialog = ({
  shopImageData,
  setShopImageData,
  onSubmit,
  isLoading,
  isStatusUpdating,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const uploadRef = useRef();
  const cameraRef = useRef();

  if (!shopImageData) return null;

  return (
    <Dialog
      fullScreen={isSmallScreen}
      fullWidth={true}
      open={shopImageData != null}
      onClose={() => {
        if (isLoading || isStatusUpdating) {
        } else setShopImageData(null);
      }}
      sx={{}}
    >
      <DialogTitle>{shopImageData.dialogTitle}</DialogTitle>
      <DialogContent>
        {shopImageData.allowAddNotes && (
          <Box>
            <DialogContentText>
              Add Notes For the Customer{" "}
              {shopImageData.requireNotes ? " (Required)" : ""}
            </DialogContentText>
            <TextareaAutosize
              placeholder="Add Notes for the Customer..."
              minRows={3}
              style={{
                width: "100%",
                marginTop: 16,
                background: theme.palette.secondary.main,
                color: theme.palette.secondary.contrastText,
                // borderColor: theme.palette.primary.main,
              }}
              value={
                shopImageData && shopImageData.notes ? shopImageData.notes : ""
              }
              onChange={(e) => {
                setShopImageData((prev) => ({
                  ...prev,
                  notes: e.target.value,
                }));
              }}
            />
          </Box>
        )}
        {shopImageData.allowAddPictures && (
          <Box>
            <DialogContentText sx={{ marginTop: "16px" }}>
              Add images of the finished order for the customer (Required)
            </DialogContentText>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <label>
                <input
                  ref={uploadRef}
                  style={{ display: "none" }}
                  type="file"
                  accept="image/*"
                  // capture="environment"
                  onChange={(event) => {
                    if (shopImageData.imageBlobs.length >= 15) {
                      alert("Maximum 15 images");
                    } else {
                      console.log("FILE: ", event.target.files[0]);
                      console.log(
                        "Image",
                        URL.createObjectURL(event.target.files[0])
                      );
                      const file = event.target.files[0];
                      setShopImageData((prev) => ({
                        ...prev,
                        imageBlobs: [...prev.imageBlobs, file],
                        imageUrls: [
                          ...prev.imageUrls,
                          URL.createObjectURL(event.target.files[0]),
                        ],
                      }));
                    }
                  }}
                />
                <div
                  style={{
                    cursor: "pointer",
                    width: "100%",
                    padding: 12,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      borderStyle: "solid",
                      borderWidth: 1,
                      borderColor: "#d2d2d2",
                      color: "#d2d2d2",
                      width: 100,
                      height: 100,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Upload sx={{ fontSize: 40 }} />
                  </div>
                </div>
              </label>
              <label>
                <input
                  ref={cameraRef}
                  style={{ display: "none" }}
                  type="file"
                  accept="image/*"
                  capture="environment"
                  onChange={(event) => {
                    if (shopImageData.imageBlobs.length >= 15) {
                      alert("Maximum 15 images");
                    } else {
                      console.log("FILE: ", event.target.files[0]);
                      console.log(
                        "Image",
                        URL.createObjectURL(event.target.files[0])
                      );
                      const file = event.target.files[0];
                      setShopImageData((prev) => ({
                        ...prev,
                        imageBlobs: [...prev.imageBlobs, file],
                        imageUrls: [
                          ...prev.imageUrls,
                          URL.createObjectURL(event.target.files[0]),
                        ],
                      }));
                    }
                  }}
                />
                <div
                  style={{
                    cursor: "pointer",
                    width: "100%",
                    padding: 12,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      borderStyle: "solid",
                      borderWidth: 1,
                      borderColor: "#d2d2d2",
                      color: "#d2d2d2",
                      width: 100,
                      height: 100,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CameraAlt sx={{ fontSize: 40 }} />
                  </div>
                </div>
              </label>
            </div>
          </Box>
        )}

        <Grid
          container
          padding={0}
          spacing={0}
          alignItems="center"
          justifyContent="center"
        >
          {
            //Saved Images
            shopImageData &&
              shopImageData.images &&
              shopImageData.images.map((entity_image, index) => (
                <Grid
                  item
                  key={index}
                  style={{
                    position: "relative",
                    margin: 0,
                    padding: 0,
                  }}
                >
                  <img
                    onClick={() => {
                      window.open(
                        entity_image.image_url,
                        "_blank",
                        "noopener,noreferrer"
                      );
                    }}
                    src={entity_image.image_url}
                    style={{
                      cursor: "pointer",
                      minHeight: "100px",
                      maxHeight: "100px",
                      minWidth: "100px",
                      maxWidth: "100px",
                      marginRight: "3px",
                      border: "1px solid black",
                    }}
                  />
                  <Close
                    color="error"
                    style={{
                      position: "absolute",
                      top: 8,
                      right: 8,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      let imagesCopy = shopImageData.images;
                      imagesCopy.splice(index, 1);
                      setShopImageData((prev) => ({
                        ...prev,
                        images: imagesCopy,
                      }));
                    }}
                  />
                </Grid>
              ))
          }
          {
            //Unsaved Images
            shopImageData &&
              shopImageData.imageUrls &&
              shopImageData.imageUrls.length > 0 &&
              shopImageData.imageUrls.map((url, index) => (
                <Grid
                  item
                  key={index}
                  style={{
                    position: "relative",
                    margin: 0,
                    padding: 0,
                  }}
                >
                  <img
                    src={url}
                    style={{
                      minHeight: "100px",
                      maxHeight: "100px",
                      minWidth: "100px",
                      maxWidth: "100px",
                      marginRight: "3px",
                      border: "1px solid black",
                    }}
                  />
                  <Close
                    color="error"
                    style={{
                      position: "absolute",
                      top: 8,
                      right: 8,
                    }}
                    onClick={() => {
                      // Remove unsaved image from imageBlobs and imageUrls arrays
                      let imageBlobsCopy = shopImageData.imageBlobs;
                      let imageUrlsCopy = shopImageData.imageUrls;
                      imageBlobsCopy.splice(index, 1);
                      imageUrlsCopy.splice(index, 1);
                      setShopImageData((prev) => ({
                        ...prev,
                        imageBlobs: imageBlobsCopy,
                        imageUrls: imageUrlsCopy,
                      }));
                    }}
                  />
                </Grid>
              ))
          }
        </Grid>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          loading={isLoading || isStatusUpdating}
          onClick={() => {
            setShopImageData(null);
          }}
        >
          Cancel
        </LoadingButton>
        <LoadingButton
          loading={isLoading || isStatusUpdating}
          onClick={() => {
            onSubmit(shopImageData.statusChange);
          }}
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default AddShopCommentAndPictureDialog;
