import {
  valueIfNotOverridden,
  combineVariablesAndOverrides,
  toThreeDecimals,
  toTwoDecimals,
} from "./utils";

const calculatePanelCut = (cut, panel = {}, variables = {}, overrides = {}) => {
  const $variables = combineVariablesAndOverrides(variables, overrides ?? {});
  const { waste: WASTE } = $variables;

  const $ = valueIfNotOverridden(overrides ?? {});

  const COIL_SIZE_NEEDED = $(
    "coil_size_needed",
    Number(panel.panelWidth ?? 0) + Number(panel.panelTakeUp ?? 0)
  ); //inches

  //this is auto-calc'd BUT if it's 40" USER CAN OVERIDE TO 48 IN CASE 40" IS NOT IN-STOCK, BUT IF IT'S 48 YOU CANNOT OVERRIDE
  let masterCoilSizeNeededTemp = 40;
  if (COIL_SIZE_NEEDED <= 16 || COIL_SIZE_NEEDED > 20)
    masterCoilSizeNeededTemp = 48;

  const getLF = (qty, feet, inches) =>
    parseFloat(qty) * (parseFloat(feet) + parseFloat(inches) / 12);

  const LINEAL_FEET_OF_COIL_NEEDED_NOT_INCLUDING_WASTE = $(
    "lineal_feet_of_coil_no_waste",
    getLF(cut.quantity, cut.feet, cut.inches)
  );

  const LINEAL_FEET_OF_COIL_NEEDED_INCLUDING_WASTE = $(
    "lineal_feet_of_coil_with_waste",
    LINEAL_FEET_OF_COIL_NEEDED_NOT_INCLUDING_WASTE +
      LINEAL_FEET_OF_COIL_NEEDED_NOT_INCLUDING_WASTE * WASTE
  );

  const SQFT_OF_COIL_NEEDED_INCLUDING_WASTE = $(
    "square_feet_of_coil_needed_with_waste",
    LINEAL_FEET_OF_COIL_NEEDED_INCLUDING_WASTE * (COIL_SIZE_NEEDED / 12)
  );

  const FINISHED_PANEL_SQFT = $(
    "finished_panel_square_feet",
    // LINEAL_FEET_OF_COIL_NEEDED_INCLUDING_WASTE * (panel.panelWidth / 12)
    // ** Finished Panel Square FT should not include waste
    LINEAL_FEET_OF_COIL_NEEDED_NOT_INCLUDING_WASTE * (panel.panelWidth / 12)
  );

  return {
    linealFeetOfCoilNoWaste: toThreeDecimals(
      LINEAL_FEET_OF_COIL_NEEDED_NOT_INCLUDING_WASTE
    ),
    linealFeetOfCoilWithWaste: toThreeDecimals(
      LINEAL_FEET_OF_COIL_NEEDED_INCLUDING_WASTE
    ),
    squareFeetOfCoilNeededWithWaste: toThreeDecimals(
      SQFT_OF_COIL_NEEDED_INCLUDING_WASTE
    ),
    finishedPanelSquareFeet: toThreeDecimals(FINISHED_PANEL_SQFT),
  };
};

export default calculatePanelCut;
