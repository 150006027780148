import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "../context/AuthContext";
import PdfViewer from "./PdfViewer";

const S3PresignedPdf = (props) => {
  const { s3Key, downloadName } = props;
  const [presignedUrl, setPresignedUrl] = useState(null);
  const { authToken, ezorder } = useContext(AuthContext);

  const createPresignedUrl = async (controller) => {
    try {
      let data = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      if (controller) {
        data.signal = controller.signal;
      }

      let keySplit = s3Key.split("/");
      let identifier = keySplit[0];
      let resource = keySplit[1];
      let fileName = keySplit[2];
      const response = await ezorder.post(
        "/files/presignedUrl",
        {
          identifier,
          resource,
          fileName,
          actionType: "READ",
          contentType: "application/pdf",
        },
        data
      );
      setPresignedUrl(response.data.getUrl);
    } catch (error) {
      // console.log(error);
      throw Error("Unable to load presigned URL", { cause: error });
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    if (s3Key) createPresignedUrl(controller);

    return () => {
      controller.abort(); // abort api call on unmount for cleanup
    };
  }, [s3Key]);

  return <PdfViewer fileUri={presignedUrl} downloadName={downloadName} />;
};

export default S3PresignedPdf;
