import { useState, useEffect, useContext } from "react";
import { AuthContext } from "../context/AuthContext";

const useTrimOrderHistory = (authToken, companyId, trimId) => {
  const { ezorder } = useContext(AuthContext);
  const [orderHistory, setOrderHistory] = useState([]);

  const getTrimOrderHistoryByIdAndCompanyId = async (controller) => {
    try {
      let data = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      if (controller) {
        data.signal = controller.signal;
      }
      const response = await ezorder.get(
        `/companies/${companyId}/trims/${trimId}/order-history`,
        data
      );
      console.log(response.data);
      setOrderHistory(response.data.orderHistory);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    if (companyId && trimId) getTrimOrderHistoryByIdAndCompanyId(controller);

    return () => {
      controller.abort(); // abort api call on unmount for cleanup
    };
  }, [authToken, companyId, trimId]);

  return { orderHistory };
};

export default useTrimOrderHistory;
