import { Box, Card, Stack, Typography } from "@mui/material";
import { getPanelDetails } from "../../../../orderUtils";
import { toThreeDecimals, toTwoDecimals } from "../../../../calculations/utils";

const PanelSummary = ({ order, isPrinting = false }) => {
  const panelOrderItem = getPanelDetails(order);
  console.log("PANEL ORDER ITEM", panelOrderItem);

  if (panelOrderItem && panelOrderItem.cuts && panelOrderItem.cuts.length > 0)
    return (
      <Box
        sx={{ padding: 2, margin: 1, color: isPrinting ? "#000" : "inherit" }}
      >
        <Typography variant={"h6"}>PANELS</Typography>
        <Stack direction={"row"} spacing={1}>
          <Stack direction={"column"} spacing={1}>
            <Typography>Panel Type</Typography>
            <Typography>Width</Typography>
            <Typography>Color</Typography>
            <Typography>Total Square Footage</Typography>
            <Typography>Coil Cost</Typography>
            <Typography>Est. Labor Hours</Typography>
            <Typography>Est. Labor Cost</Typography>
            <Typography>Est. Lumber Cost</Typography>
            <Typography>GP %</Typography>
            <Typography>GP $</Typography>
            <Typography>Total Sell Price</Typography>
            <Typography>Total Sell Price Per SF</Typography>
          </Stack>
          <Stack direction={"column"} spacing={1}>
            <Typography>{panelOrderItem?.panelType}</Typography>
            <Typography>{panelOrderItem?.panelWidth}</Typography>
            <Typography>{order?.color}</Typography>
            <Typography>
              {toThreeDecimals(
                panelOrderItem?.calculations?.finishedPanelSquareFeet
              )}
            </Typography>
            <Typography>
              ${toTwoDecimals(panelOrderItem?.calculations?.materialCost)}
            </Typography>
            <Typography>
              {toTwoDecimals(panelOrderItem?.calculations?.estimatedLaborHours)}
            </Typography>
            <Typography>
              ${toTwoDecimals(panelOrderItem?.calculations?.estimatedLaborCost)}
            </Typography>
            <Typography>
              $
              {toTwoDecimals(panelOrderItem?.calculations?.estimatedLumberCost)}
            </Typography>
            <Typography>
              {toTwoDecimals(
                panelOrderItem?.calculations?.grossProfitMargin * 100
              )}
            </Typography>
            <Typography>
              ${toTwoDecimals(panelOrderItem?.calculations?.grossProfitDollars)}
            </Typography>
            <Typography>
              ${toTwoDecimals(panelOrderItem?.calculations?.totalSellPrice)}
            </Typography>
            <Typography>
              $
              {toThreeDecimals(
                panelOrderItem?.calculations?.panelPricePerSquareFoot
              )}
            </Typography>
          </Stack>
        </Stack>
      </Box>
    );
};

export default PanelSummary;
