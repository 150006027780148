import { useContext, useState, useEffect } from "react";
import { AuthContext } from "../context/AuthContext";

const usePanelSystems = () => {
  const [panelSystems, setPanelSystems] = useState([]);
  const [refreshing, setRefreshing] = useState(false);
  const { authToken, ezorder } = useContext(AuthContext);

  const getPanelSystems = async (controller) => {
    try {
      setRefreshing(true);
      let data = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };

      if (controller) {
        data.signal = controller.signal;
      }

      const response = await ezorder.get(`/app/panel-systems`, data);
      setPanelSystems(response.data.panelSystems);
      // console.log(response.data.panelSystems);
      return response.data.panelSystems;
    } catch (error) {
      console.log(error);
    } finally {
      setRefreshing(false);
    }
  };

  useEffect(() => {
    const controller = new AbortController();

    if (panelSystems.length === 0) {
      getPanelSystems();
    }

    return () => {
      controller.abort(); // abort api call on unmount for cleanup
    };
  }, []);

  return { panelSystems, getPanelSystems, refreshing };
};
export default usePanelSystems;
