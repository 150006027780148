import { useEffect, useContext } from "react";
import { HeadingContext } from ".././context/HeadingContext";
const usePageName = ({ heading, caption }) => {
  const { setHeadingText, setCaptionText } = useContext(HeadingContext);

  useEffect(() => {
    if (heading) {
      setHeadingText(heading);
    }
    if (caption) {
      setCaptionText(caption);
    }
  }, []);
};
export default usePageName;
