import {
  Box,
  Button,
  Card,
  Checkbox,
  FormControlLabel,
  Paper,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Company from "../../auth/Signup/Company";
import { LoadingButton } from "@mui/lab";
import { CameraAlt, Close, Upload } from "@mui/icons-material";
import S3PresignedImage from "../../../components/CustomerComponents/S3/S3PresignedImage";

const CompanySettingsForm = ({
  companyDetails,
  setCompanyDetails,
  onUpdateCompanySettings,
  onUpdateCompanyLogo,
  loading,
  errorMessage,
}) => {
  const [imageBlob, setImageBlob] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [imageBlobCropped, setImageBlobCropped] = useState(null);
  const [imageUrlCropped, setImageUrlCropped] = useState(null);

  const resetData = () => {
    setImageBlob(null);
    setImageUrl(null);
    setImageBlobCropped(null);
    setImageUrlCropped(null);
  };

  const formValidationSchema = Yup.object({
    companyName: Yup.string().required("Company Name is required"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      companyName:
        companyDetails && companyDetails.name ? companyDetails.name : "",
      companyContactName:
        companyDetails && companyDetails.contactName
          ? companyDetails.contactName
          : "",
      companyContactEmail:
        companyDetails && companyDetails.contactEmail
          ? companyDetails.contactEmail
          : "",
      companyContactPhone:
        companyDetails && companyDetails.contactPhone
          ? companyDetails.contactPhone
          : "",
      copyOwnerToAllNotifications:
        companyDetails && companyDetails.copyOwnerToAllNotifications
          ? companyDetails.copyOwnerToAllNotifications
          : "",
      // employees: [],
    },
    validationSchema: formValidationSchema,
    validateOnBlur: false,
    validateOnChange: true,
    onSubmit: (values) => {
      console.log("SUBMITTED", values);
      onUpdateCompanySettings(values);
    },
  });

  if (companyDetails)
    return (
      <>
        <Paper sx={{ padding: "8px", margin: "16px" }}>
          <Typography variant="h5">Company Details</Typography>
          <Company formik={formik} errorMessage={errorMessage} />
          <Box sx={{ padding: 2, margin: 0, paddingTop: 0 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formik.values.copyOwnerToAllNotifications}
                  onChange={(e) => {
                    formik.setValues({
                      ...formik.values,
                      copyOwnerToAllNotifications: e.target.checked,
                    });
                  }}
                />
              }
              label="Copy Owner on all quote requests, quote, and quote approval notifications"
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <LoadingButton loading={loading} onClick={formik.submitForm}>
              Update
            </LoadingButton>
          </Box>
        </Paper>
        {companyDetails.isVendorOrSupplier && (
          <Paper sx={{ padding: "8px", margin: "16px" }}>
            <Typography variant="h5">Company Logo</Typography>
            <S3PresignedImage
              s3Key={companyDetails.logo}
              removeAspectRatio={true}
            />
            <Box>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                sx={{ marginTop: "12px" }}
              >
                Update Company Logo
              </Typography>

              <Box sx={{ maxWidth: "400px" }}>
                {imageUrl ? (
                  // <ImageCropper
                  //   image={imageUrl}
                  //   onSave={(fileBlob, url) => {
                  //     setImageUrl(null);
                  //     setImageBlob(null);
                  //     setImageBlobCropped(fileBlob);
                  //     setImageUrlCropped(url);
                  //   }}
                  //   onClear={() => {
                  //     setImageUrl(null);
                  //     setImageBlob(null);
                  //   }}
                  // />
                  <img
                    src={imageUrl}
                    style={{ maxWidth: "300px", maxHeight: "300px" }}
                  />
                ) : (
                  <div>
                    <div>
                      {imageUrlCropped && (
                        <div style={{ position: "relative" }}>
                          <img
                            src={imageUrlCropped}
                            style={{
                              marginTop: 16,
                              // maxWidth: "100%",
                              // maxHeight: 200,
                              width: "100%",
                            }}
                          />
                          <div
                            onClick={() => {
                              setImageUrlCropped(null);
                              setImageBlobCropped(null);
                            }}
                          >
                            <Close
                              style={{ position: "absolute", top: 8, right: 8 }}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    {imageBlob === null &&
                      imageUrl === null &&
                      imageBlobCropped === null &&
                      imageUrlCropped === null && (
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <label>
                            <input
                              style={{ display: "none" }}
                              type="file"
                              accept="image/png, image/jpeg"
                              // capture="environment"
                              onChange={(event) => {
                                console.log(event.target.files[0]);
                                console.log(
                                  "Image",
                                  URL.createObjectURL(event.target.files[0])
                                );
                                setImageBlob(event.target.files[0]);
                                setImageUrl(
                                  URL.createObjectURL(event.target.files[0])
                                );
                                setImageBlobCropped(null);
                                setImageUrlCropped(null);
                              }}
                            />
                            <div
                              style={{
                                cursor: "pointer",
                                width: "100%",
                                padding: 12,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  borderStyle: "solid",
                                  borderWidth: 1,
                                  borderColor: "#d2d2d2",
                                  color: "#d2d2d2",
                                  width: 100,
                                  height: 100,
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <Upload sx={{ fontSize: 40 }} />
                              </div>
                            </div>
                          </label>
                        </div>
                      )}
                  </div>
                )}
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <LoadingButton
                loading={loading}
                onClick={async () => {
                  await onUpdateCompanyLogo(imageBlob);
                  resetData();
                }}
              >
                Update
              </LoadingButton>
            </Box>
          </Paper>
        )}
      </>
    );
  else return null;
};

export default CompanySettingsForm;
