import MainOrderView from "./views/MainOrderView";
import initialOrder from "./InitialOrderObject";
import { useState, useReducer, useEffect, useContext } from "react";
import { getPresignedUrl, uploadImage } from "./imageUpload";
import { AuthContext } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { piecesBasedOnType } from "./orderUtils";
import combineReducers from "../../reducers/combineReducers";
import detailReducer from "../../reducers/detailsReducer";
import trimReducer from "../../reducers/trimReducer";
import panelReducer from "../../reducers/panelReducer";
import capReducer from "../../reducers/capReducer";
import cleatReducer from "../../reducers/cleatReducer";
import accessoriesReducer from "../../reducers/accessoriesReducer";
import benchReducer from "../../reducers/benchReducer";
import spliceReducer from "../../reducers/spliceReducer";
import useVariables from "../../hooks/useVariables";
import { SnackAlertContext } from "../../context/SnackAlertContext";
import flatSheetReducer from "../../reducers/flatSheetReducer";
import coilReducer from "../../reducers/coilReducer";

const deepCopy = (obj) => JSON.parse(JSON.stringify(obj));

const CreateNewOrder = ({ companyId, projectId }) => {
  const { openSnackMessage } = useContext(SnackAlertContext);
  const navigate = useNavigate();
  const { authToken, ezorder } = useContext(AuthContext);
  const authHeader = {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  };
  const rootReducer = combineReducers(
    detailReducer,
    flatSheetReducer,
    coilReducer,
    trimReducer,
    panelReducer,
    capReducer,
    cleatReducer,
    accessoriesReducer,
    benchReducer,
    spliceReducer
  );

  // let { variables } = useVariables();
  // delete variables.id;
  // let initialOrder = { ...initalOrderValue, defaultVariables: variables };
  // console.log("INITIAL ORDER OBJECT", initialOrder);
  // const [orderState, orderDispatch] = useReducer(rootReducer, initialOrder);
  const [orderState, orderDispatch] = useReducer(rootReducer, initialOrder);

  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(false);
  const { variables, getDefaultVariables } = useVariables();

  const getCompanyById = async (companyId) => {
    try {
      const res = await ezorder.get(
        `/admin/companies/${companyId}`,
        authHeader
      );
      let company = res.data.company;
      return company;
    } catch (error) {
      console.log(error);
    }
    return null;
  };

  const getProject = async (companyId, projectId) => {
    try {
      const res = await ezorder.get(
        `/admin/companies/${companyId}/projects/${projectId}`,
        authHeader
      );
      let project = res.data.project;
      return project;
    } catch (error) {
      console.log(error);
    }
    return null;
  };

  const loadInitialOrderWithDefaultVariables = async () => {
    try {
      let defaultVariables = await getDefaultVariables();
      delete defaultVariables.id;

      // Load Company Details If Creating a  New Order for A Company (Not guest)
      const company = await getCompanyById(companyId);
      // Load Project Details If Not Guest
      const project = await getProject(companyId, projectId);
      let orderObject = {
        ...initialOrder,
        defaultVariables: defaultVariables,
      };
      console.log("COMPANY", company);
      console.log("PROJECT", project);

      if (company && project) {
        orderObject = {
          ...orderObject,
          type: "MOBILE",
          company,
          project,
          taxExemptForm: {
            ...company.yearlyTaxForm,
          },
        };
      }

      console.log("loadInitialOrderWithDefaultVariables", orderObject);

      setOrder(orderObject);
    } catch (error) {
      console.log(error);
    }
  };

  const buildOrderState = (order) => {
    console.log("BUILD ORDER STATE", order);
    // adds empty orderItem objects if they don't exist
    if (!order) return;
    let newOrder = deepCopy(order);
    const items = [
      "FlatSheet",
      "Coil",
      "Panel",
      "TrimAndFlashing",
      "BenchWork",
      "CopingCap",
      "CopingCapCleat",
      "SplicePlate",
      "Accessory",
    ];
    const orderedItems = newOrder.items.map((item) => item.objectType);
    // For some reason, the initialOrderValue object is being mutated when uploading trim,benchwork, etc, pieces
    // We are using a copy of the initial order items instead
    const initialOrderValueItemsCopy = [
      {
        objectType: "FlatSheet",
        totalCost: 0,
        quantity: 0,
        width: 48,
      },
      {
        objectType: "Coil",
        totalCost: 0,
        linealFeet: 0,
        width: 48,
      },
      {
        totalCost: 0,
        objectType: "Panel",
        panelSystem: "Standing Seam",
        panelType: "SL-175, Striated With Clip Relief",
        panelTakeUp: null,
        panelWidth: 18,
        coilSquareFeet: null,
        coilSquareFeetWithDrop: null,
        panelMasterCoilWidth: null,
        totalPanelAreaCoverage: null,
        panelProfile: "",
        cuts: [],
      },
      {
        totalCost: 0,
        objectType: "TrimAndFlashing",
        trimPieces: [],
      },
      {
        totalCost: 0,
        objectType: "BenchWork",
        benchWorkPieces: [],
      },
      {
        totalCost: 0,
        objectType: "CopingCap",
        capPieces: [],
      },
      {
        totalCost: 0,
        objectType: "CopingCapCleat",
        gauge: "",
        color: null,
        cleatPieces: [],
      },
      {
        totalCost: 0,
        objectType: "SplicePlate",
        plates: [],
      },
      {
        totalCost: 0,
        objectType: "Accessory",
        items: [],
      },
    ];
    const newItemsArray = items.map((item) => {
      if (!orderedItems.includes(item)) {
        let orderItem = initialOrderValueItemsCopy.find(
          (obj) => obj.objectType === item
        );
        // let orderItem = initialOrderValue.items.find(
        //   (obj) => obj.objectType === item
        // );

        return orderItem;
      } else {
        return newOrder.items.find((obj) => obj.objectType === item);
      }
    });

    newOrder.items = [...newItemsArray];
    if (!newOrder) return;
    // Is orderState.calculations actually being used???
    orderDispatch({
      type: "SET ORDER STATE",
      payload: { ...newOrder },
    });
  };

  const deconstructOrder = (order) => {
    // removes order items without any pieces, before order is submitted;
    const orderCopy = deepCopy(order);
    const updatedItems = orderCopy.items.reduce(
      (accumulator, current) =>
        (current.objectType == "FlatSheet" && current.quantity > 0) ||
        (current.objectType == "Coil" &&
          current.width > 0 &&
          current.linealFeet > 0)
          ? [...accumulator, current]
          : current.objectType != "FlatSheet" &&
            current.objectType != "Coil" &&
            piecesBasedOnType(current)?.pieces.length > 0
          ? [...accumulator, current]
          : [...accumulator],
      [] // initial value of the accumulator
    );
    orderCopy.items = [...updatedItems];
    // console.log(orderCopy);
    return orderCopy;
  };

  const createOrder = async (order) => {
    try {
      setLoading(true);
      const response = await ezorder.post(
        `/admin/orders`,
        { ...order },
        authHeader
      );
      console.log(response.data.order);
      if (response.status == 200 || response.status === 201) {
        // navigate to order details page
        //orderDispatch({ type: "SET ORDER STATE", payload: initialOrder });
        navigate(`/orders/${response.data.order.id}`);
      }
      openSnackMessage("success", "Quote Saved!");
    } catch (error) {
      console.log(error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage("error", error.response.data.error);
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", error);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitOrder = () => {
    createOrder(orderState);
  };
  useEffect(() => {
    //if (!order)
    loadInitialOrderWithDefaultVariables();
  }, []);

  // TODO: This is actually not being used. Another one in Order.js
  // const [overrideValues, setOverrideValues] = useState({});
  // const handleOverrideValues = (objectType) => (key) => (e) => {
  //   const value = toThreeDecimals(e.target.value);
  //   setOverrideValues((curr) => ({
  //     ...curr,
  //     [objectType]: { ...[objectType], [key]: value },
  //   }));
  //   orderDispatch({
  //     type: "OVERRIDE CALCULATION",
  //     payload: { objectType, key, value },
  //   });
  // };
  useEffect(() => {
    if (order) buildOrderState(order);
  }, [order]);

  useEffect(() => {
    console.log("ORDER STATE UPDATED", orderState);
  }, [orderState]);

  return (
    <MainOrderView
      state={orderState}
      action={orderDispatch}
      getPresignedUrl={getPresignedUrl(ezorder, authToken, orderState)}
      uploadImage={uploadImage}
      // handleOverrideValues={handleOverrideValues}
      handleSubmit={handleSubmitOrder}
      error={false}
      isLoading={loading}
      data={orderState}
    />
  );
};
export default CreateNewOrder;
