import usePageName from "../../../hocs/usePageName";
import { useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "../../../context/AuthContext";
import SearchBar from "../../../components/SearchBar";
import InfiniteScroller from "../../../components/InfiniteScroller/InfiniteScroller";
import ListFooter from "../../../components/InfiniteScroller/ListFooter";
import ShopOrderCard from "./ShopOrderCard";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Box, useMediaQuery } from "@mui/material";

import { useTheme } from "@emotion/react";
import { SnackAlertContext } from "../../../context/SnackAlertContext";
import AddShopCommentAndPictureDialog from "./AddShopCommentAndPictureDialog";
import useShopOrders from "../../../hooks/useShopOrders";
import UpdatePickUpStatusDialog from "./UpdatePickUpStatusDialog";

const ShopOrders = ({ resource, currentTab }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const { authToken, ezorder, decoded } = useContext(AuthContext);
  const { openSnackMessage } = useContext(SnackAlertContext);
  const {
    shopOrders,
    getShopOrders,
    isLoadingShopOrders,
    searchQuery,
    setSearchQuery,
  } = useShopOrders(authToken, currentTab);
  const [updatePickUpStatusData, setUpdatePickUpStatusData] = useState(null);
  const [isStatusUpdating, setIsStatusUpdating] = useState(false);

  const [shopImageData, setShopImageData] = useState(null);

  const handleTabChange = (event, newValue) => {
    // setActiveTab(newValue);
    switch (newValue) {
      case "active":
        navigate(`/${resource}/active`, { replace: true });
        break;
      case "in-progress":
        navigate(`/${resource}/in-progress`, {
          replace: true,
        });
        break;
      case "ready":
        navigate(`/${resource}/ready`, {
          replace: true,
        });
        break;
      default:
        navigate(`/${resource}/in-progress`, {
          replace: true,
        });
        break;
    }
  };

  const onShopPriorityChange = async (order, priorityValue) => {
    try {
      setIsStatusUpdating(true);
      const response = await ezorder.patch(
        `/shop/orders/${order.id}/priority`,
        { shopPriority: priorityValue },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      //console.log(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsStatusUpdating(false);
      getShopOrders();
    }
  };

  const onMoveToOnToday = async (order) => {
    try {
      setIsStatusUpdating(true);
      const response = await ezorder.patch(
        `/shop/orders/${order.id}/on-today`,
        { isOnToday: true },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      //console.log(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsStatusUpdating(false);
      getShopOrders();
    }
  };

  const onChangeShopStatus = async (order, statusChange) => {
    try {
      setIsStatusUpdating(true);
      // If 100% complete, ask for Pics and Comments
      if (statusChange == "100% Complete") {
        const orderData = await ezorder.get(`/shop/orders/${order.id}`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });

        // Opens Modal to ask for Pictures
        setShopImageData({
          statusChange: statusChange,
          dialogTitle: "Mark Job as 100% Complete",
          allowAddNotes: true,
          requireNotes: false,
          allowAddPictures: true,
          requirePictures: true,
          order: orderData.data.order,
          imageBlobs: [],
          imageUrls: [],
        });
      }
      // If Parially Complete, ask for comment
      else if (statusChange == "Partially Complete") {
        const orderData = await ezorder.get(`/shop/orders/${order.id}`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });

        // Opens Modal to ask for Pictures
        setShopImageData({
          statusChange: statusChange,
          dialogTitle: "Mark Job as Partially Complete",
          allowAddNotes: true,
          requireNotes: true,
          allowAddPictures: false,
          requirePictures: false,
          order: orderData.data.order,
          imageBlobs: [],
          imageUrls: [],
        });
      } else {
        // Just Update Status
        const orderData = await ezorder.patch(
          `/shop/orders/${order.id}/shop-status`,
          { shopStatus: statusChange },
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        getShopOrders();
      }
    } catch (error) {
      console.log(error);
    } finally {
      // getShopOrders();
      setIsStatusUpdating(false);
    }
  };

  const onChangeShopProgress = async (order, shopProgress) => {
    try {
      setIsStatusUpdating(true);
      const response = await ezorder.put(
        `/shop/orders/${order.id}/shop-progress`,
        { shopProgress },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      getShopOrders();
    } catch (error) {
      console.log(error);
    } finally {
      // getShopOrders();
      setIsStatusUpdating(false);
    }
  };

  const onSetShopJobDetails = async (
    orderId,
    jobLeaderId,
    notes,
    shopPriority
  ) => {
    try {
      setIsStatusUpdating(true);
      const response = await ezorder.put(
        `/shop/orders/${orderId}/details`,
        {
          assignedShopOrderLeadId: jobLeaderId,
          orderFabNotes: notes,
          shopPriority,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      getShopOrders();
    } catch (error) {
      console.log(error);
    } finally {
      // getShopOrders();
      setIsStatusUpdating(false);
    }
  };

  // Uploads an Array Of Images
  // Returns an Array of Entity Image object with id, entity_type, image_url
  const onUpdateStatusWithPicturesAndComments = async (shopStatus) => {
    try {
      setIsStatusUpdating(true);
      let savedImageCount = shopImageData.images
        ? shopImageData.images.length
        : 0;
      let unsavedImageCount = shopImageData.imageBlobs
        ? shopImageData.imageBlobs.length
        : 0;
      let allImageCount = savedImageCount + unsavedImageCount;
      if (shopImageData.requirePictures && allImageCount === 0) {
        throw "Image is required";
      } else if (
        shopImageData.requireNotes &&
        (!shopImageData.notes || shopImageData.notes.length == 0)
      ) {
        throw "Notes are required";
      } else {
        let imgFormData = new FormData();
        imgFormData.append("shopStatus", shopStatus);
        if (shopImageData.notes && shopImageData.notes.length > 0) {
          imgFormData.append("notes", shopImageData.notes); // If you pass an undefined here, it will be converted to string
        }
        for (let imageBlob of shopImageData.imageBlobs) {
          imgFormData.append("images", imageBlob);
        }

        let response = await ezorder.put(
          `/shop/orders/${shopImageData.order.id}/shop-status/complete`,
          imgFormData,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
              "content-type": "multipart/form-data",
            },
          }
        );
        console.log("UPDATED ORDER", response.data);

        openSnackMessage("success", "Done!");
        setShopImageData(null);
        getShopOrders();
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage("error", error.response.data.error);
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", error);
      }
    } finally {
      setIsStatusUpdating(false);
    }
  };

  const onUpdatePickupStatus = async (
    order,
    pickupStatus,
    pickupData,
    notes,
    savedImages,
    newImageBlobs
  ) => {
    try {
      console.log("onUpdatePickupStatus", {
        order,
        pickupStatus,
        pickupData,
        notes,
        savedImages,
        newImageBlobs,
      });
      setIsStatusUpdating(true);
      let imageCount = savedImages.length + newImageBlobs.length;
      if (imageCount > 15) {
        throw "15 images Max";
      } else {
        let imgFormData = new FormData();
        for (let savedImage of savedImages) {
          imgFormData.append("savedImages[]", savedImage);
        }
        for (let imageBlob of newImageBlobs) {
          imgFormData.append("images", imageBlob);
        }

        imgFormData.append("pickupStatus", pickupStatus);
        imgFormData.append("notes", notes);
        imgFormData.append("deliveredBy", pickupData.deliveredBy);
        imgFormData.append("deliveredOn", pickupData.deliveredOn);
        imgFormData.append("pickedUpBy", pickupData.pickedUpBy);
        imgFormData.append("pickedUpOn", pickupData.pickedUpOn);

        let response = await ezorder.put(
          `/shop/orders/${order.id}/pickup-status`,
          imgFormData,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
              "content-type": "multipart/form-data",
            },
          }
        );
        openSnackMessage("success", "Updated!");
        setUpdatePickUpStatusData(null);
        getShopOrders();
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage("error", error.response.data.error);
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", error);
      }
    } finally {
      setIsStatusUpdating(false);
    }
  };

  // TODO: Use tabs for each section of the shop orders
  return (
    <>
      <SearchBar
        label="Search Orders"
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
      />
      <Tabs
        sx={{ marginBottom: "16px" }}
        value={currentTab}
        onChange={handleTabChange}
        centered
      >
        <Tab label="Active" value="active" />
        <Tab label="In-Progress" value="in-progress" />
        <Tab label="Ready For Pickup" value="ready" />
      </Tabs>
      <Box>
        <InfiniteScroller
          items={shopOrders}
          renderItem={(item, index) => {
            return (
              <ShopOrderCard
                key={item.id}
                isAdmin={decoded.role === "EZORDER_ADMIN"}
                order={item}
                clickHandler={() => navigate(`/orders/${item.id}`)}
                onShopPriorityChange={onShopPriorityChange}
                onMoveToOnToday={onMoveToOnToday}
                onChangeShopStatus={onChangeShopStatus}
                isLoading={isLoadingShopOrders || isStatusUpdating}
                onChangeShopProgress={onChangeShopProgress}
                onSetShopJobDetails={onSetShopJobDetails}
                onChangePickUpStatus={() => {
                  console.log("CHANGING PICK UP STATUS");
                  setUpdatePickUpStatusData({
                    order: item,
                  });
                }}
              />
            );
          }}
          onRefresh={getShopOrders}
          onEndReached={() => {}}
          loading={isLoadingShopOrders}
          ListFooterComponent={() => {
            return <ListFooter hasMore={false} onClick={null} />;
          }}
        />
      </Box>
      <AddShopCommentAndPictureDialog
        shopImageData={shopImageData}
        setShopImageData={setShopImageData}
        isLoading={isLoadingShopOrders}
        isStatusUpdating={isStatusUpdating}
        onSubmit={(status) => {
          onUpdateStatusWithPicturesAndComments(status);
        }}
      />
      {updatePickUpStatusData && (
        <UpdatePickUpStatusDialog
          updatePickUpStatusData={updatePickUpStatusData}
          setUpdatePickUpStatusData={setUpdatePickUpStatusData}
          isLoading={isLoadingShopOrders}
          isStatusUpdating={isStatusUpdating}
          onSubmit={(
            order,
            pickupStatus,
            pickupData,
            notesText,
            savedImages,
            newImageBlobs
          ) => {
            onUpdatePickupStatus(
              order,
              pickupStatus,
              pickupData,
              notesText,
              savedImages,
              newImageBlobs
            );
          }}
        />
      )}
    </>
  );
};

export default ShopOrders;
