import {
  Box,
  Button,
  Fab,
  IconButton,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import ColorListItem from "./ColorListItem";
import AddIcon from "@mui/icons-material/Add";
import { useContext, useState } from "react";
import ColorFormModal from "./ColorFormModal";
import { AuthContext } from "../../../context/AuthContext";
import uploadImage from "../../../helper/uploadImage";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { useTheme } from "@emotion/react";
import useAllColorsbyManufacturer from "../../../hooks/useAllColorsByManufacturer";

const ColorsList = ({ manufacturerId, manufacturerName }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [showColorForm, setShowColorForm] = useState(false);
  const { authToken, ezorder } = useContext(AuthContext);
  const { colors, getColors, addColor } =
    useAllColorsbyManufacturer(manufacturerId);
  const toggleShowForm = () => {
    setShowColorForm(!showColorForm);
  };

  const createPublicFileWritePresignedUrl = async (
    fileName, // Ex: "asdfasfsaf.jpg". The backend replaces the name so it doesnt matter and add back the extension (.jpg)
    resource //"trims", "coping-caps", "cleats", "bench-work", "tax-forms", "job-quotes", "estimates", "signatures", "signed-estimates"
  ) => {
    console.log("Create Presigned URL");

    const response = await ezorder.post(
      "/files/public/presignedUrl",
      {
        fileName,
        resource,
      },
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );

    console.log(response.data);

    return response.data;
  };

  const saveColorAndCloseModal = async (name, imageBlob) => {
    // 1.) Create Presigned URL to Upload to Public EZORDER BUCKET
    const resource = "colors/uploads";
    let fileName = imageBlob.name;
    const presignedUrlResponse = await createPublicFileWritePresignedUrl(
      fileName,
      resource
    );
    console.log("PRESIGNED URL RESPONSE", presignedUrlResponse);

    // 2.) Upload the image
    // Upload URL
    await uploadImage(presignedUrlResponse.uploadUrl, imageBlob);
    console.log("IMAGE UPLOADED");

    // Save New Color with Image URL
    let color = {
      name,
      paintManufacturer: { id: manufacturerId },
      image: presignedUrlResponse.publicAccessUrl,
    };
    await addColor(color);
    setShowColorForm(false);
  };

  return (
    <>
      <Paper>
        <Box
          mb="10px"
          sx={{
            padding: "10px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography ml="20px" variant="h6">
            Color Catalog
          </Typography>
        </Box>
        <Box sx={{ padding: "20px" }}>
          {showColorForm && (
            <Box>
              <ColorFormModal
                visible={showColorForm}
                setVisible={setShowColorForm}
                saveColor={saveColorAndCloseModal}
                manufacturerName={manufacturerName}
              />
            </Box>
          )}
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>Color</TableCell>
                  <TableCell align="right">Manufacturer</TableCell>
                  <TableCell align="right">On Customer Display</TableCell>
                  <TableCell align="right" />
                </TableRow>
              </TableHead>
              <TableBody>
                {colors.map((color) => (
                  <ColorListItem color={color} key={color.id} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Fab
          sx={{
            position: "fixed",
            bottom: 70,
            right: isSmallScreen ? 16 : 56,
          }}
          color="success"
          variant="extended"
          aria-label="add"
          onClick={toggleShowForm}
        >
          <AddIcon sx={{ mr: 1 }} />
          New {manufacturerName} Color
        </Fab>
      </Paper>
    </>
  );
};
export default ColorsList;
