import { Typography } from "@mui/material";

const withLoading = (Component) => (props) => {
  const message = props.isLoading
    ? "Loading..."
    : !props.data
    ? "Loading..."
    : props.error
    ? "Network Error"
    : null;

  if (message) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography textAlign={"center"} variant="h4">
          {message}
        </Typography>
      </div>
    );
  } else {
    return <Component {...props} />;
  }
};
export default withLoading;
