import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";

const EditDefaultVariables = ({ defaultVariables, onSave, isLoading }) => {
  const [variables, setVariables] = useState({});

  const handleVariableChange = (e) => {
    const key = e.target.name;
    const value = e.target.value;

    let newVariables = variables;
    newVariables[key] = parseFloat(value);
    setVariables({ ...newVariables });
  };

  useEffect(() => {
    setVariables(defaultVariables);
  }, [defaultVariables]);

  if (!defaultVariables || !variables) {
    return (
      <Paper>
        <Typography>Loading...</Typography>
      </Paper>
    );
  }
  return (
    <Paper sx={{ mt: "30px" }}>
      <Typography ml="20px" variant="h6">
        Default Variables
      </Typography>
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            margin: "20px",
          }}
        >
          <TextField
            onFocus={(e) => e.target.select()}
            InputLabelProps={{ shrink: true }}
            type="number"
            onWheel={(event) => {
              event.preventDefault();
            }}
            label="Hits Per Minute"
            name="hits_per_minute"
            value={variables.hits_per_minute}
            onChange={(e) => handleVariableChange(e)}
            sx={{ mb: "10px" }}
          />
          <TextField
            onFocus={(e) => e.target.select()}
            InputLabelProps={{ shrink: true }}
            type="number"
            onWheel={(event) => {
              event.preventDefault();
            }}
            label="Waste"
            name="waste"
            value={variables.waste}
            onChange={(e) => {
              handleVariableChange(e);
            }}
            // InputProps={{
            //   startAdornment: (
            //     <InputAdornment position="start">%</InputAdornment>
            //   ),
            // }}
          />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", margin: "20px" }}>
          <TextField
            onFocus={(e) => e.target.select()}
            InputLabelProps={{ shrink: true }}
            type="number"
            onWheel={(event) => {
              event.preventDefault();
            }}
            label="Master Coil Size"
            name="master_coil_size"
            value={variables.master_coil_size}
            onChange={(e) => handleVariableChange(e)}
            sx={{ mb: "10px" }}
          />
          <TextField
            onFocus={(e) => e.target.select()}
            InputLabelProps={{ shrink: true }}
            type="number"
            onWheel={(event) => {
              event.preventDefault();
            }}
            label="Panel Run Rate (LF per man hour)"
            name="panel_run_rate"
            value={variables.panel_run_rate}
            onChange={(e) => handleVariableChange(e)}
            sx={{ mb: "10px" }}
          />
          <TextField
            onFocus={(e) => e.target.select()}
            InputLabelProps={{ shrink: true }}
            type="number"
            onWheel={(event) => {
              event.preventDefault();
            }}
            label="Hourly Labor Rate"
            name="hourly_labor_cost"
            value={variables.hourly_labor_cost}
            onChange={(e) => handleVariableChange(e)}
            sx={{ mb: "10px" }}
          />
          <TextField
            onFocus={(e) => e.target.select()}
            InputLabelProps={{ shrink: true }}
            type="number"
            onWheel={(event) => {
              event.preventDefault();
            }}
            label="Lumber & Crating Rate"
            name="lumber_and_crating_rate"
            value={variables.lumber_and_crating_rate}
            onChange={(e) => {
              handleVariableChange(e);
            }}
            // InputProps={{
            //   startAdornment: (
            //     <InputAdornment position="start">%</InputAdornment>
            //   ),
            // }}
            sx={{ mb: "10px" }}
          />
          <TextField
            onFocus={(e) => e.target.select()}
            InputLabelProps={{ shrink: true }}
            type="number"
            onWheel={(event) => {
              event.preventDefault();
            }}
            label="Gross Profit Margin"
            name="gross_profit_margin"
            value={variables.gross_profit_margin}
            onChange={(e) => {
              handleVariableChange(e);
            }}
            // InputProps={{
            //   startAdornment: (
            //     <InputAdornment position="start">%</InputAdornment>
            //   ),
            // }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          padding: "16px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <LoadingButton
          variant="outlined"
          onClick={() => {
            onSave(variables);
          }}
          loading={isLoading}
        >
          Save Changes
        </LoadingButton>
      </Box>
    </Paper>
  );
};
export default EditDefaultVariables;
