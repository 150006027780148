import {
  valueIfNotOverridden,
  combineVariablesAndOverrides,
  toTwoDecimals,
  toThreeDecimals,
  toFourDecimals,
} from "./utils";

const calculateSplicePlate = (
  splicePlate,
  variables,
  overrides = {},
  state = {}
) => {
  const $variables = combineVariablesAndOverrides(variables, overrides ?? {});
  const {
    gross_profit_margin: GROSS_PROFIT_MARGIN, // = 0.38,
    hits_per_minute: HITS_PER_MINUTE, // = 2.0,
    hourly_labor_cost: HOURLY_LABOR_RATE, // = 40,
  } = $variables;

  let FLAT_SHEET_COST =
    state.flatSheetCost != state.flatSheetCostOriginal
      ? state.flatSheetCost
      : state.flatSheetCostOriginal;
  if (!FLAT_SHEET_COST) FLAT_SHEET_COST = 0;
  let FLAT_SHEET_WIDTH = state.flatSheetSize ? state.flatSheetSize : 48;
  let FLAT_SHEET_LENGTH = state.flatSheetLength ? state.flatSheetLength : 120;

  const $ = valueIfNotOverridden(overrides ?? {});

  // Different
  const PIECES_YIELDED_PER_FLAT_SHEET = $(
    "pieces_yielded_per_flat_sheet",
    // How Many Portions Widthwise
    Math.floor(FLAT_SHEET_WIDTH / (splicePlate?.stretchOut ?? 1)) *
      (FLAT_SHEET_LENGTH / splicePlate?.length ?? 1) // how many portions lengthwise
  );

  // Same
  const FLAT_SHEETS_NEEDED = $(
    "flat_sheets_needed",
    Math.ceil((splicePlate?.quantity ?? 1) / PIECES_YIELDED_PER_FLAT_SHEET)
  );

  // Same
  const INCHES_OF_DROP_PER_FLAT = $(
    "inches_of_drop_per_flat_sheet",
    FLAT_SHEET_WIDTH % (splicePlate?.stretchOut ?? 1)
  );

  // Same
  const NUMBER_OF_DROPS = $(
    "number_of_drops",
    INCHES_OF_DROP_PER_FLAT === 0 ? 0 : FLAT_SHEETS_NEEDED
  );

  // Same
  const ESTIMATED_LABOR_HOURS = $(
    "total_estimated_labor_hours",
    parseFloat(
      Math.ceil(
        ((splicePlate?.quantity ?? 1) * (splicePlate?.hits ?? 1)) /
          HITS_PER_MINUTE /
          60
      )
    )
  );

  const ESTIMATED_LABOR_COST = $(
    "total_estimated_labor_cost",
    parseFloat(ESTIMATED_LABOR_HOURS * HOURLY_LABOR_RATE)
  );

  const MATERIAL_COST = $(
    "material_cost",
    parseFloat(FLAT_SHEETS_NEEDED * FLAT_SHEET_COST)
  );

  const MATERIAL_AND_LABOR = $(
    "material_and_labor",
    parseFloat(ESTIMATED_LABOR_COST + MATERIAL_COST)
  );

  const TOTAL_SELL_PRICE = $(
    "total_sell_price",
    parseFloat(MATERIAL_AND_LABOR / (1 - GROSS_PROFIT_MARGIN))
  );

  const GROSS_PROFIT_DOLLARS = $(
    "gross_profit_dollars",
    parseFloat(TOTAL_SELL_PRICE - MATERIAL_AND_LABOR)
  );

  const PRICE_PER_PIECE = $(
    "price_per_piece",
    parseFloat(TOTAL_SELL_PRICE / (splicePlate?.quantity ?? 1))
  );

  return {
    flatSheetSize: toThreeDecimals(FLAT_SHEET_WIDTH),
    flatSheetCost: toTwoDecimals(FLAT_SHEET_COST),
    piecesYieldedPerFlatSheet: toThreeDecimals(PIECES_YIELDED_PER_FLAT_SHEET),
    flatSheetsNeeded: toThreeDecimals(FLAT_SHEETS_NEEDED),
    inchesOfDropPerFlatSheet: toThreeDecimals(INCHES_OF_DROP_PER_FLAT),
    numberOfDrops: toThreeDecimals(NUMBER_OF_DROPS),
    estimatedLaborHours: toTwoDecimals(ESTIMATED_LABOR_HOURS),
    estimatedLaborCost: toTwoDecimals(ESTIMATED_LABOR_COST),
    materialCost: toTwoDecimals(MATERIAL_COST),
    materialAndLabor: toTwoDecimals(MATERIAL_AND_LABOR),
    grossProfitMargin: toFourDecimals(GROSS_PROFIT_MARGIN),
    grossProfitDollars: toTwoDecimals(GROSS_PROFIT_DOLLARS),
    totalSellPrice: toTwoDecimals(TOTAL_SELL_PRICE),
    pricePerPiece: toTwoDecimals(PRICE_PER_PIECE),
  };
};
export default calculateSplicePlate;
