import { withId } from "./utils";
import calculatePanel from "../components/NewDetail/calculations/panels";

const panelReducer = (state, action) => {
  const panelCalcs = (panel) => {
    const variables = panel?.variables
      ? panel.variables
      : state.defaultVariables;
    const calcs = {
      overrides: panel?.overrides,
      calculations: calculatePanel(panel, variables, panel?.overrides, state),
      variables: variables,
    };
    return calcs;
  };

  const overrides = (panel, key, value) => {
    const variables = panel?.variables
      ? panel.variables
      : state.defaultVariables;

    const overrides = {
      ...panel?.overrides,
      [key]: value,
    };

    const calcs = {
      calculations: calculatePanel(panel, variables, overrides, state),
      overrides: overrides,
      variables: variables,
    };

    return calcs;
  };

  const updatePanels = (key) => {
    const value = action.payload;
    let orderItemsCopy = [...state.items];
    const panelItemIndex = orderItemsCopy.findIndex(
      (obj) => obj.objectType === "Panel"
    );
    orderItemsCopy[panelItemIndex][key] = value;
    orderItemsCopy[panelItemIndex] = {
      ...orderItemsCopy[panelItemIndex],
      ...panelCalcs(orderItemsCopy[panelItemIndex]),
    };
    return orderItemsCopy;
  };

  // This is needed because updating panel system updates, type, profile, etc.
  const updateMultiple = (payload) => {
    let orderItemsCopy = [...state.items];
    const panelItemIndex = orderItemsCopy.findIndex(
      (obj) => obj.objectType === "Panel"
    );
    for (const [key, value] of Object.entries(payload)) {
      orderItemsCopy[panelItemIndex][key] = value;
    }

    orderItemsCopy[panelItemIndex] = {
      ...orderItemsCopy[panelItemIndex],
      ...panelCalcs(orderItemsCopy[panelItemIndex]),
    };
    return orderItemsCopy;
  };

  switch (action.type) {
    case "UPDATE PANEL: SETTINGS": {
      const orderItemsUpdate = updateMultiple(action.payload);
      return { ...state, items: [...orderItemsUpdate] };
    }
    case "UPDATE PANEL: SYSTEM": {
      const orderItemsUpdate = updatePanels("panelSystem");
      return { ...state, items: [...orderItemsUpdate] };
    }
    case "UPDATE PANEL: TAKE UP": {
      const orderItemsUpdate = updatePanels("panelTakeUp");
      return { ...state, items: [...orderItemsUpdate] };
    }
    case "UPDATE PANEL: TYPE": {
      const orderItemsUpdate = updatePanels("panelType");
      return { ...state, items: [...orderItemsUpdate] };
    }
    case "UPDATE PANEL: PROFILE": {
      const orderItemsUpdate = updatePanels("panelProfile");
      return { ...state, items: [...orderItemsUpdate] };
    }
    case "UPDATE PANEL: WIDTH": {
      const orderItemsUpdate = updatePanels("panelWidth");
      return { ...state, items: [...orderItemsUpdate] };
    }
    case "UPDATE PANEL: ADD CUT": {
      const cut = withId(action.payload);
      let newArr = [...state.items];
      const index = newArr.findIndex((obj) => obj.objectType === "Panel");
      newArr[index].cuts.push(cut);
      newArr[index] = {
        ...newArr[index],
        ...panelCalcs(newArr[index]),
      };
      console.log(newArr[index]);
      return { ...state, items: [...newArr] };
    }
    case "UPDATE PANEL: REMOVE CUT": {
      let newArr = [...state.items];
      const index = newArr.findIndex((obj) => obj.objectType === "Panel");
      newArr[index].cuts.splice(action.payload, 1);
      newArr[index] = {
        ...newArr[index],
        ...panelCalcs(newArr[index]),
      };
      return { ...state, items: [...newArr] };
    }
    case "UPDATE PANEL: EDIT CUT": {
      let newArr = [...state.items];
      const index = newArr.findIndex((obj) => obj.objectType === "Panel");

      let cutCopy = newArr[index].cuts[action.payload.index];
      cutCopy = { ...cutCopy, ...action.payload.update };

      newArr[index].cuts[action.payload.index] = cutCopy;

      newArr[index] = {
        ...newArr[index],
        ...panelCalcs(newArr[index]),
      };

      return { ...state, items: [...newArr] };
    }
    case "LOG PANEL STATE": {
      // console.log("YO MAMMA");
      // console.log(state);
      console.log(state.items.find((item) => item.objectType === "Panel"));
      return state;
    }
    case "UPDATE PANEL: OVERRIDE CALCULATION": {
      const { key, value } = action.payload;
      console.log("UPDATE PANEL: OVERRIDE CALCULATION", key, value);
      let newArr = [...state.items];
      const index = newArr.findIndex((obj) => obj.objectType === "Panel");
      newArr[index] = {
        ...newArr[index],
        ...overrides(newArr[index], key, value),
      };

      return { ...state, items: [...newArr] };
    }
    case "UPDATE PANEL: SET CALCULATIONS": {
      let newArr = [...state.items];
      const index = newArr.findIndex((obj) => obj.objectType === "Panel");
      newArr[index] = {
        ...newArr[index],
        ...panelCalcs(newArr[index]),
      };
      return { ...state, items: [...newArr] };
    }
    case "UPDATE PANEL: RESET OVERIDE": {
      let newArr = [...state.items];
      const index = newArr.findIndex((obj) => obj.objectType === "Panel");
      newArr[index] = {
        ...newArr[index],
        ...overrides(newArr[index], action.payload, null),
      };

      // console.log(action.payload);
      return { ...state, items: [...newArr] };
    }
    default:
      return state;
  }
};
export default panelReducer;
