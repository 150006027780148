import {
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useState, useContext, useEffect } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import {
  Download,
  ExpandLess,
  ExpandMore,
  MoreVert,
  Upload,
} from "@mui/icons-material";
import S3FileViewer from "../../../components/S3FileViewer";
import moment from "moment/moment";
import QuotesAndOrders from "../Orders";
import estimatePdfDownloadHandler from "../../../helper/estimatePdfDownloader";
import AddEmployeeModal from "../../customer/Employees/AddEmployee/AddEmployeeModal";
import SalesRepModal from "./SalesRepModal";
import { SnackAlertContext } from "../../../context/SnackAlertContext";
import { useTheme } from "@emotion/react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import CustomerTrimCatalog from "./CustomerTrimCatalog";
import DeactivateCustomerDialog from "./DeactivateCustomerDialog";
import RestoreCustomerDialog from "./RestoreCustomerDialog";
import ChangeOwnerDialog from "./ChangeOwnerDialog";

const CHANGE_OWNER = "CHANGE_OWNER";
const DEACTIVATE = "DEACTIVATE";
const RESTORE = "RESTORE";

const CustomerDetails = () => {
  const { openSnackMessage } = useContext(SnackAlertContext);
  const { id } = useParams();

  const { authToken, ezorder } = useContext(AuthContext);
  const [company, setCompany] = useState(null);
  const [expanded, setExpanded] = useState(true);
  const [users, setUsers] = useState([]);
  const [openAddEmployeeModal, setOpenEmployeeModal] = useState(false);
  const [openSalesPersonModal, setOpenSalesPersonModal] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const theme = useTheme();

  const [changeOwner, setChangeOwner] = useState(false);
  const [deactivate, setDeactivate] = useState(false);
  const [restore, setRestore] = useState(false);

  // Tax Form
  const [taxFormModalOpen, setTaxFormModalOpen] = useState(false);
  const [taxFormModalFile, setTaxFormModalFile] = useState(null);
  const [newTaxFormName, setNewTaxFormName] = useState("");
  const [newTaxFormExpirationDate, setNewTaxFormExpirationDate] = useState(
    moment().format("MM/DD/YYYY")
  );

  const [companyMenuAnchor, setCompanyMenuAnchor] = useState(null);
  const openCompanyMenu = Boolean(companyMenuAnchor);
  const handleOpenCompanyMenu = (event) => {
    setCompanyMenuAnchor(event.currentTarget);
  };
  const handleCloseCompanyMenu = () => {
    setCompanyMenuAnchor(null);
  };

  const handleDate = (date) => {
    const expirationDate = moment(date).format("MM/DD/YYYY");
    setNewTaxFormExpirationDate(expirationDate);
  };
  // Tax Form End

  const handleOpenEmployeeModal = () => {
    setOpenEmployeeModal(true);
  };
  const handleCloseEmployeeModal = () => {
    getCompanyEmployees(id);
    setOpenEmployeeModal(false);
  };

  const handleOpenSalesRepModal = () => {
    setOpenSalesPersonModal(true);
  };
  const handleCloseSalesRepModal = () => {
    setOpenSalesPersonModal(false);
  };

  const deactivateCompany = async () => {
    try {
      console.log("deactivateCompany");
      setLoading(true);
      let data = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      const response = await ezorder.delete(`/admin/companies/${id}`, data);

      if (response.status == 200 || response.status == 201) {
        openSnackMessage("success", "Deactivated");
        getCustomerById(id);
        setDeactivate(false);
      } else {
        openSnackMessage("error", response.response.data.error);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const restoreCompany = async () => {
    try {
      console.log("restoreCompany");
      setLoading(true);
      let data = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      const response = await ezorder.put(
        `/admin/companies/${id}/restore`,
        {},
        data
      );
      if (response.status == 200 || response.status == 201) {
        openSnackMessage("success", "Restored");
        getCustomerById(id);
        setRestore(false);
      } else {
        openSnackMessage("error", response.response.data.error);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  // uplaod image to S3 bucket
  const uploadTaxForm = async () => {
    setLoading(true);
    const identifier = id;
    let actionType = "WRITE";
    let fileName = taxFormModalFile.name;
    let contentType = taxFormModalFile.type;
    let resource = "tax-forms";
    const presignedResponse = await createWritePresignedUrl(
      actionType,
      fileName,
      contentType,
      resource,
      identifier
    );
    let s3Key = presignedResponse.key;
    await fetch(presignedResponse.uploadUrl, {
      method: "PUT",
      body: taxFormModalFile,
    })
      .then(async (response) => {
        console.log(response);
        if (response.status === 200 || response.status == 201) {
          let newTaxForm = {
            name: newTaxFormName,
            file: s3Key,
            companyId: id,
            type: "ANNUAL",
            expirationDate: newTaxFormExpirationDate,
            setAsCompanyDefault: true,
          };
          const saveResponse = await ezorder.post(
            `/admin/company/${id}/tax-forms`,
            newTaxForm,
            {
              headers: {
                Authorization: `Bearer ${authToken}`,
              },
            }
          );
          getCustomerById(id);
          setNewTaxFormName("");
          setTaxFormModalFile(null);
          setTaxFormModalOpen(false);
        }
        setLoading(false);
      })
      .catch((error) => {
        // console.log(error);
        setLoading(false);
        throw Error("Failed", { cause: error });
      });
  };
  const createWritePresignedUrl = async (
    actionType,
    fileName,
    contentType,
    resource,
    identifier
  ) => {
    try {
      let presignedRequest = {
        actionType: actionType,
        fileName: fileName,
        contentType: contentType,
        resource: resource,
        identifier: identifier,
      };

      const response = await ezorder.post(
        `/files/presignedUrl`,
        presignedRequest,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      console.log("Presigned URL", response.data);

      return response.data;
    } catch (error) {
      console.log(error);
      throw Error("Unable to load presigned URL for Trim image", {
        cause: error,
      });
    }
  };

  const getCustomerById = async (companyId) => {
    try {
      let data = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      const res = await ezorder.get(`/admin/companies/${companyId}`, data);
      let company = res.data.company;
      setCompany(company);
    } catch (error) {
      console.log(error);
    }
    return null;
  };

  const getCompanyEmployees = async () => {
    try {
      let data = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      const response = await ezorder.get(`/company/${id}/users`, data);
      console.log("EMPLOYEES", response.data.employees);
      setUsers(response.data.employees);
    } catch (error) {
      console.log(error);
    }
  };

  const updateCompanySalesRep = async (salesRep) => {
    try {
      setLoading(true);
      let data = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      const response = await ezorder.put(
        `/admin/companies/${id}/sales-rep`,
        {
          salesRepId: salesRep.id,
        },
        data
      );

      if (response.status == 200 || response.status == 201) {
        openSnackMessage("success", "Updated");
        getCustomerById(id);
        handleCloseSalesRepModal();
      } else {
        openSnackMessage("error", response.response.data.error);
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage("error", error.response.data.error);
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", error);
      }
    } finally {
      setLoading(false);
    }
  };

  const updateCompanyOwner = async (ownerId) => {
    try {
      setLoading(true);
      let data = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      const response = await ezorder.put(
        `/admin/companies/${id}/owner`,
        {
          ownerId: ownerId,
        },
        data
      );

      if (response.status == 200 || response.status == 201) {
        openSnackMessage("success", "Owner Changed");
        getCustomerById(id);
        getCompanyEmployees(id);
        setChangeOwner(false);
      } else {
        openSnackMessage("error", response.response.data.error);
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage("error", error.response.data.error);
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", error);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      getCustomerById(id);
      getCompanyEmployees(id);
    }
  }, [id]);

  if (!company) return null;

  return (
    <>
      <Paper
        style={{
          height: "100%",
          padding: "16px",
          marginBottom: "16px",
        }}
      >
        <Typography
          variant="h4"
          component="div"
          sx={{ width: "100%", textAlign: "center" }}
        >
          Sales Rep
        </Typography>
        {company.assignedSalesRep && (
          <TableContainer component={Box}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Full Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Role</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{company.assignedSalesRep.fullName}</TableCell>
                  <TableCell>{company.assignedSalesRep.email}</TableCell>
                  <TableCell>{company.assignedSalesRep.role}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <Box>
          <Button variant="contained" onClick={handleOpenSalesRepModal}>
            Change
          </Button>
          <SalesRepModal
            isAdmin={true}
            companyIdFromAdmin={id}
            open={openSalesPersonModal}
            handleOpen={handleOpenSalesRepModal}
            handleClose={handleCloseSalesRepModal}
            onSelect={updateCompanySalesRep}
          />
        </Box>
      </Paper>
      <Box sx={{ mt: "20px", mb: "20px" }}>
        <Paper elevation={3} sx={{ padding: "2px 15px 15px 15px" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Typography variant="h6" sx={{ mb: "15px" }}>
              Company: {company.name}
            </Typography>

            <div>
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={openCompanyMenu ? "long-menu" : undefined}
                aria-expanded={openCompanyMenu ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleOpenCompanyMenu}
              >
                <MoreVert />
              </IconButton>
              <Menu
                id="long-menu"
                MenuListProps={{
                  "aria-labelledby": "long-button",
                }}
                anchorEl={companyMenuAnchor}
                open={openCompanyMenu}
                onClose={handleCloseCompanyMenu}
              >
                <MenuItem
                  value={CHANGE_OWNER}
                  onClick={() => {
                    setChangeOwner(true);
                    handleCloseCompanyMenu();
                  }}
                >
                  Change Company Account Owner
                </MenuItem>
                {company.deletedAt ? (
                  <MenuItem
                    value={RESTORE}
                    onClick={() => {
                      setRestore(true);
                      handleCloseCompanyMenu();
                    }}
                  >
                    Restore Company
                  </MenuItem>
                ) : (
                  <MenuItem
                    value={DEACTIVATE}
                    onClick={() => {
                      setDeactivate(true);
                      handleCloseCompanyMenu();
                    }}
                  >
                    Deactivate Company
                  </MenuItem>
                )}
              </Menu>
              <ChangeOwnerDialog
                company={company}
                users={users}
                open={changeOwner}
                handleClose={() => {
                  setChangeOwner(false);
                }}
                onConfirm={(newOwnerId) => {
                  updateCompanyOwner(newOwnerId);
                }}
                loading={loading}
              />
              <DeactivateCustomerDialog
                open={deactivate}
                handleClose={() => {
                  setDeactivate(false);
                }}
                onConfirm={() => {
                  deactivateCompany();
                }}
                loading={loading}
              />
              <RestoreCustomerDialog
                open={restore}
                handleClose={() => {
                  setRestore(false);
                }}
                onConfirm={() => {
                  restoreCompany();
                }}
                loading={loading}
              />
            </div>
          </Box>
          <Button
            variant="contained"
            onClick={() => {
              navigate(`/customers/${id}/company-settings`);
            }}
          >
            Edit Company Settings
          </Button>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <Card
                style={{
                  height: "100%",
                  padding: "16px",
                }}
              >
                <Typography variant="h6" sx={{ mb: "8xp", fontSize: 18 }}>
                  Contact
                </Typography>
                <Typography>Contact Name: {company.contactName}</Typography>
                <Typography>Contact Email: {company.contactPhone}</Typography>
                <Typography>Contact Phone: {company.contactEmail}</Typography>
              </Card>
            </Grid>
            <Grid item md={6}>
              <Card
                style={{
                  height: "100%",
                  padding: "16px",
                }}
              >
                <Typography variant="h6" sx={{ mb: "8xp", fontSize: 18 }}>
                  Owner
                </Typography>
                <Typography>Name: {company.owner.fullName}</Typography>
                <Typography>Email: {company.owner.email}</Typography>
              </Card>
            </Grid>
            <Grid item sm={12}>
              <Card
                style={{
                  height: "100%",
                  padding: "16px",
                }}
              >
                <Typography variant="h6" sx={{ mb: "8xp", fontSize: 18 }}>
                  Tax Exempt Form
                </Typography>
                <Button
                  component="label"
                  size="small"
                  variant="outlined"
                  endIcon={<Upload />}
                  onClick={() => {
                    setTaxFormModalOpen(true);
                  }}
                >
                  New Tax Form
                </Button>
                <Modal
                  open={taxFormModalOpen}
                  onClose={() => {
                    setNewTaxFormName("");
                    setTaxFormModalFile(null);
                    setTaxFormModalOpen(false);
                    setNewTaxFormExpirationDate(moment());
                  }}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: 400,
                      background: theme.palette.secondary.main,
                      boxShadow: 24,
                      p: 4,
                    }}
                  >
                    <Box
                      sx={{
                        display: " flex",
                        flexDirection: "column",
                        gap: "1rem",
                      }}
                    >
                      <Box>
                        <TextField
                          label="Name"
                          fullWidth
                          value={newTaxFormName}
                          onChange={(e) => {
                            setNewTaxFormName(e.target.value);
                          }}
                        />
                        <div style={{ marginTop: "8px" }}>
                          <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                              disabled={loading}
                              label="Expiration Date"
                              value={newTaxFormExpirationDate}
                              onChange={(newValue) => {
                                handleDate(newValue);
                              }}
                              renderInput={(params) => (
                                <TextField fullWidth sx={{}} {...params} />
                              )}
                            />
                          </LocalizationProvider>
                        </div>
                      </Box>
                      <Box
                        sx={{
                          display: " flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <Box
                          sx={{
                            display: " flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          {taxFormModalFile && (
                            <Typography>{taxFormModalFile.name}</Typography>
                          )}
                          <Button
                            component="label"
                            size="small"
                            variant="outlined"
                            endIcon={<Upload />}
                          >
                            <input
                              hidden
                              accept="image/*, application/pdf"
                              type="file"
                              //onChange={handleImage}
                              onChange={(e) => {
                                console.log(e.target.files);
                                setTaxFormModalFile(e.target.files[0]);
                              }}
                            />
                            File
                          </Button>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: " flex",
                          flexDirection: "row",
                          justifyContent: "end",
                        }}
                      >
                        <Button
                          disabled={loading}
                          color="success"
                          onClick={() => {
                            uploadTaxForm();
                          }}
                        >
                          Submit
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Modal>
                <Box>
                  {company.yearlyTaxForm ? (
                    <Box
                      sx={{
                        marginTop: "16px",
                        display: "flex",
                        flexDirection: "row",
                        gap: "1rem",
                      }}
                    >
                      <TextField
                        sx={{ marginRight: 1 }}
                        InputProps={{
                          readOnly: true,
                          disableUnderline: true,
                        }}
                        label="Name"
                        value={company.yearlyTaxForm.name}
                      />
                      <TextField
                        InputProps={{
                          readOnly: true,
                          disableUnderline: true,
                        }}
                        label="Expiration Date"
                        value={moment(
                          company.yearlyTaxForm?.expirationDate
                        ).format("MM/DD/YYYY")}
                      />

                      {/* <S3FileViewer s3Key={company.yearlyTaxForm.file} /> */}
                      <Button
                        onClick={() =>
                          estimatePdfDownloadHandler(
                            company.yearlyTaxForm.file,
                            authToken,
                            null,
                            null,
                            `TAX-EXEMPT-FORM-${company.name}.pdf`
                          )
                        }
                      >
                        <Download color="primary" />
                        TAX EXEMPT FORM PDF
                      </Button>
                    </Box>
                  ) : (
                    <Typography>No Tax Exempt Form</Typography>
                  )}
                </Box>
              </Card>
            </Grid>
          </Grid>
        </Paper>
      </Box>

      <Paper
        style={{
          height: "100%",
          padding: "16px",
          marginBottom: "16px",
        }}
      >
        <Typography
          variant="h4"
          component="div"
          sx={{ width: "100%", textAlign: "center" }}
        >
          Users
        </Typography>
        <Box>
          <Button variant="contained" onClick={handleOpenEmployeeModal}>
            + New User
          </Button>
          <AddEmployeeModal
            isAdmin={true}
            isVendorAccount={company.isVendorOrSupplier}
            companyIdFromAdmin={id}
            open={openAddEmployeeModal}
            handleOpen={handleOpenEmployeeModal}
            handleClose={handleCloseEmployeeModal}
          />
        </Box>
        <TableContainer component={Box}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Full Name</TableCell>
                <TableCell align="right">Email</TableCell>
                <TableCell align="right">Role</TableCell>
                <TableCell align="right">Active</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user, index) => {
                return (
                  <TableRow
                    key={index}
                    onClick={() => {
                      navigate(`/customers/${company.id}/users/${user.id}`);
                    }}
                  >
                    <TableCell>{user.fullName}</TableCell>
                    <TableCell align="right">{user.email}</TableCell>
                    <TableCell align="right">{user.role}</TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        color: user.deletedAt ? "red" : "inherit",
                      }}
                    >
                      {user.deletedAt ? "Deactivated" : "Active"}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Paper
        style={{
          height: "100%",
          overflowY: "scroll",
          padding: "16px",
          maxHeight: "100vh",
        }}
      >
        <QuotesAndOrders companyId={id} />
      </Paper>

      <Card
        style={{
          marginTop: "16px",
          padding: "16px",
        }}
      >
        <Typography
          variant="h4"
          component="div"
          sx={{ width: "100%", textAlign: "center" }}
        >
          Customer Stock Trim Catalog
        </Typography>
        <Box
          style={{
            overflowY: "scroll",
            height: "300px",
            padding: "16px",
            marginBottom: "16px",
          }}
        >
          <CustomerTrimCatalog
            companyId={id}
            catalogCompanyName={company.name}
          />
        </Box>
      </Card>
    </>
  );
};
export default CustomerDetails;
