import { useState, useEffect, useContext } from "react";
import { AuthContext } from "../context/AuthContext";

const useShopOrders = (authToken, filter) => {
  const { ezorder } = useContext(AuthContext);
  const [searchQuery, setSearchQuery] = useState("");
  const [shopOrders, setShopOrders] = useState([]);
  const [isLoadingShopOrders, setIsLoadingShopOrders] = useState(false);

  const getShopOrders = async (controller) => {
    try {
      setIsLoadingShopOrders(true);
      let data = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };

      if (controller) {
        data.signal = controller.signal;
      }
      let withFilter = ``;
      if (filter) {
        withFilter = `&filter=${filter}`;
      }
      const response = await ezorder.get(
        `/shop/orders?search=${searchQuery}${withFilter}`,
        data
      );
      setShopOrders(response.data.shopOrders);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingShopOrders(false);
    }
  };

  useEffect(() => {
    const controller = new AbortController();

    getShopOrders();

    return () => {
      controller.abort(); // abort api call on unmount for cleanup
    };
  }, [filter, searchQuery]);

  return {
    shopOrders,
    getShopOrders,
    isLoadingShopOrders,
    searchQuery,
    setSearchQuery,
  };
};
export default useShopOrders;
