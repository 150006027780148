import { useState, useContext, useEffect } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import useTrimCatalog from "../../../hooks/useTrimCatalog";
import createPresignedUrl from "../../../helper/createPresignedUrl";
import uploadImage from "../../../helper/uploadImage";
import Catalog from "../../../components/Catalog/Catalog";

const MasterTrimCatalog = () => {
  const navigate = useNavigate();
  const { authToken, ezorder, role, decoded } = useContext(AuthContext);
  const [
    searchQuery,
    setSearchQuery,
    catalogFilter,
    setCatalogFilter,
    results,
    onRefresh,
    refreshing,
    onLoadMore,
    loadingMore,
    total,
    hasMore,
  ] = useTrimCatalog(authToken, 10, null, "master");

  const onItemSelect = async (catalogItem) => {
    console.log("SELECT HANDLER", catalogItem);
    navigate(`/master-trim-catalog/${catalogItem.id}`);
  };

  const onSubmitNewItem = async (
    name,
    imageBlob,
    saveToCatalog,
    masterHits,
    masterStretchOut
  ) => {
    console.log(
      "newItemHandler",
      name,
      imageBlob,
      saveToCatalog,
      masterHits,
      masterStretchOut
    );

    // Create Presigned URL
    let actionType = "WRITE";
    let fileName = imageBlob.name;
    let contentType = imageBlob.type;
    let resource = "trims";
    let identifier = "MASTER"; // decoded.companyId;
    const presignedUrlResponse = await createPresignedUrl(
      ezorder,
      authToken,
      actionType,
      fileName,
      contentType,
      resource,
      identifier
    );
    console.log("presignedUrlResponse", presignedUrlResponse);

    // Upload URL
    await uploadImage(presignedUrlResponse.uploadUrl, imageBlob);

    await saveMasterTrim(
      name,
      presignedUrlResponse.key,
      saveToCatalog,
      masterHits,
      masterStretchOut
    );
    onRefresh();
  };

  const saveMasterTrim = async (
    name,
    image,
    saveToCatalog,
    masterHits,
    masterStretchOut
  ) => {
    let newTrim = {
      name,
      image,
      saveToCatalog,
      masterHits,
      masterStretchOut,
      isMasterCatalog: true,
    };

    const response = await ezorder.post(`/admin/master-trim-catalog`, newTrim, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    return response.data.trim;
  };

  useEffect(() => {
    console.log("hasMore", hasMore);
  }, [hasMore]);

  return (
    <>
      <Catalog
        search={searchQuery}
        setSearch={setSearchQuery}
        catalogFilter={catalogFilter}
        setCatalogFilter={setCatalogFilter}
        enableCatalogFilters={false}
        items={results}
        itemType={"Trim & Flashing"}
        onRefresh={onRefresh}
        refreshing={refreshing}
        onLoadMore={onLoadMore}
        isLoadingMore={loadingMore}
        saveNew={onSubmitNewItem}
        onSelect={onItemSelect}
        hasMore={hasMore}
        enableSaveToCatalog={false}
        isMasterCatalogItem={true}
      />
    </>
  );
};
export default MasterTrimCatalog;
