import { updateOrderItemPiece, spliceCalcs as calcs, withId } from "./utils";

const spliceReducer = (state, action) => {
  const spliceCalcs = calcs(state);
  switch (action.type) {
    case "UPDATE SPLICE PLATE: ADD SPLICE PLATE": {
      const calculations = spliceCalcs(action.payload);
      let newArr = [...state.items];
      const index = newArr.findIndex((obj) => obj.objectType === "SplicePlate");
      newArr[index].plates.push(
        withId({
          ...action.payload,
          ...calculations,
        })
      );
      return { ...state, items: [...newArr] };
    }
    case "UPDATE SPLICE PLATE: PLATE DETAILS": {
      const calculations = spliceCalcs(action.payload);
      const updatedItems = updateOrderItemPiece(state.items, "SplicePlate", {
        ...action.payload,
        ...calculations,
      });
      return { ...state, items: [...updatedItems] };
    }
    case "UPDATE SPLICE PLATE: REMOVE SPLICE PLATE": {
      let newArr = [...state.items];
      const index = newArr.findIndex((obj) => obj.objectType === "SplicePlate");
      newArr[index].plates.splice(action.payload, 1);
      return { ...state, items: [...newArr] };
    }
    case "UPDATE SPLICE PLATE: OVERRIDE CALCULATION": {
      const { trim, key, value } = action.payload;
      const updatedItems = updateOrderItemPiece(state.items, "SplicePlate", {
        ...trim,
        ...spliceCalcs(trim, key, value),
      });
      return { ...state, items: [...updatedItems] };
    }
    case "UPDATE SPLICE PLATE: RESET OVERRIDE": {
      const { trim, key } = action.payload;
      const updatedItems = updateOrderItemPiece(state.items, "SplicePlate", {
        ...trim,
        ...spliceCalcs(trim, key, null),
      });
      return { ...state, items: [...updatedItems] };
    }
    case "UPDATE SPLICE PLATE: RUN CALCULATIONS": {
      const calculations = spliceCalcs(action.payload);
      const updatedItems = updateOrderItemPiece(state.items, "SplicePlate", {
        ...action.payload,
        ...calculations,
      });
      return { ...state, items: [...updatedItems] };
    }
    default:
      return state;
  }
};
export default spliceReducer;
