import { useContext, useState, useEffect } from "react";
import { AuthContext } from "../context/AuthContext";

const useVariables = () => {
  const { authToken, ezorder } = useContext(AuthContext);
  const header = {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  };

  const [variables, setVariables] = useState({});

  const convertValuesToNumbers = (variablesObject) => {
    let res = {};
    for (const variable in variablesObject) {
      res[variable] = parseFloat(variablesObject[variable]);
    }
    return res;
  };

  const getDefaultVariables = async () => {
    try {
      const res = await ezorder.get(`/app/variables`, header);
      const variables = res.data.variables;
      // setVariables(convertValuesToNumbers(variables));
      let convertedVariables = convertValuesToNumbers(variables);
      setVariables(convertedVariables);
      return convertedVariables;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDefaultVariables();
  }, []);

  return { variables, getDefaultVariables };
};
export default useVariables;
