import { withId } from "./utils";
import calculateFlatSheet from "../components/NewDetail/calculations/flatSheet";

const flatSheetReducer = (state, action) => {
  const flatSheetCalcs = (flatSheet) => {
    const variables = flatSheet?.variables
      ? flatSheet.variables
      : state.defaultVariables;
    const calcs = {
      overrides: flatSheet?.overrides,
      flatSheetCalculations: calculateFlatSheet(
        flatSheet,
        variables,
        flatSheet?.overrides,
        state
      ),
      variables: variables,
    };
    return calcs;
  };

  const overrides = (flatSheet, key, value) => {
    const variables = flatSheet?.variables
      ? flatSheet.variables
      : state.defaultVariables;

    const overrides = {
      ...flatSheet?.overrides,
      [key]: value,
    };

    const calcs = {
      flatSheetCalculations: calculateFlatSheet(
        flatSheet,
        variables,
        overrides,
        state
      ),
      overrides: overrides,
      variables: variables,
    };

    return calcs;
  };

  const updateFlatSheet = (key) => {
    const value = action.payload;
    let orderItemsCopy = [...state.items];
    const flatSheetItemIndex = orderItemsCopy.findIndex(
      (obj) => obj.objectType === "FlatSheet"
    );
    orderItemsCopy[flatSheetItemIndex][key] = value;
    orderItemsCopy[flatSheetItemIndex] = {
      ...orderItemsCopy[flatSheetItemIndex],
      ...flatSheetCalcs(orderItemsCopy[flatSheetItemIndex]),
    };
    return orderItemsCopy;
  };

  switch (action.type) {
    case "UPDATE FLAT SHEET: QUANTITY": {
      const orderItemsUpdate = updateFlatSheet("quantity");
      return { ...state, items: [...orderItemsUpdate] };
    }
    case "UPDATE FLAT SHEET: WIDTH": {
      const orderItemsUpdate = updateFlatSheet("width");
      return { ...state, items: [...orderItemsUpdate] };
    }
    case "UPDATE FLAT SHEET: OVERRIDE CALCULATION": {
      const { key, value } = action.payload;
      let newArr = [...state.items];
      const index = newArr.findIndex((obj) => obj.objectType === "FlatSheet");
      newArr[index] = {
        ...newArr[index],
        ...overrides(newArr[index], key, value),
      };

      return { ...state, items: [...newArr] };
    }
    case "UPDATE FLAT SHEET: SET CALCULATIONS": {
      let newArr = [...state.items];
      const index = newArr.findIndex((obj) => obj.objectType === "FlatSheet");
      newArr[index] = {
        ...newArr[index],
        ...flatSheetCalcs(newArr[index]),
      };
      return { ...state, items: [...newArr] };
    }
    case "UPDATE FLAT SHEET: RESET OVERIDE": {
      let newArr = [...state.items];
      const index = newArr.findIndex((obj) => obj.objectType === "FlatSheet");
      newArr[index] = {
        ...newArr[index],
        ...overrides(newArr[index], action.payload, null),
      };

      return { ...state, items: [...newArr] };
    }
    default:
      return state;
  }
};
export default flatSheetReducer;
