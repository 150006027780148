export const getPresignedUrl =
  (ezorder, authToken, order) => (resource) => async (file) => {
    try {
      const identifier =
        order.company && order.company.id ? order.company.id : "GUEST";
      let presignedRequest = {
        actionType: "WRITE",
        identifier: identifier,
        fileName: file.name,
        contentType: file.type,
        resource: resource,
      };

      const presignedResponse = await ezorder.post(
        `/files/presignedUrl`,
        presignedRequest,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      return presignedResponse;
    } catch (error) {
      console.log(error);
      throw Error("Unable to load presigned URL for Trim image", {
        cause: error,
      });
    }
  };

// uplaod image to S3 bucket
export const uploadImage = (file) => async (presignedUrl) => {
  console.log("file", file);
  console.log("presignedUrl", presignedUrl);
  const { uploadUrl, key } = presignedUrl.data;
  const s3Key = await fetch(uploadUrl, {
    method: "PUT",
    body: file,
    headers: {
      "Content-Type": file.type, // DO NOT PASS IN A BEARER TOKEN
    },
  });
  console.log("IMAGE UPLOADED. S3 Key:", s3Key);
  return s3Key;
};
