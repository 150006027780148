import { useContext, useState, useEffect } from "react";
import { useDebounce } from "use-debounce";
import { AuthContext } from "../context/AuthContext";

const useVendorPOs = (authToken, limit, orderId) => {
  const { ezorder } = useContext(AuthContext);
  // Pagination
  const [results, setResults] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchValue] = useDebounce(searchQuery, 1000);
  const [refreshing, setRefreshing] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(limit);
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [hasMore, setHasMore] = useState(false);

  const search = async (controller) => {
    try {
      if (pageNumber == 0) setRefreshing(true);
      else {
        setLoadingMore(true);
      }
      let data = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      if (controller) {
        data.signal = controller.signal;
      }

      let endpoint;
      if (orderId) {
        endpoint = `/admin/purchase-orders?search=${searchQuery}&pageNumber=${pageNumber}&pageSize=${pageSize}&orderId=${orderId}`;
      } else {
        endpoint = `/admin/purchase-orders?search=${searchQuery}&pageNumber=${pageNumber}&pageSize=${pageSize}`;
      }
      const res = await ezorder.get(endpoint, data);

      // If first page, set results as current data
      // If beyond first page, add result to current data
      if (pageNumber === 0) {
        setResults(res.data.page.results);
      } else {
        setResults((prev) => [...prev, ...res.data.page.results]);
      }
      setTotal(res.data.page.total);
      setPages(res.data.page.pages);
    } catch (error) {
      console.log(error);
    } finally {
      setRefreshing(false);
      setLoadingMore(false);
    }
  };

  // Find the next Page Number based on the total length of the current data and the page size
  // If results.length = 10
  // And pageSize = 10
  // then results.length/pageSize = 1
  const onLoadMore = () => {
    let nextPage;
    if (results.length > 0) {
      nextPage = Math.ceil(results.length / pageSize);
    } else {
      nextPage = 0;
    }
    setPageNumber(nextPage);
  };

  // On swipe down (to refresh page) we want to reload all the data from the first page
  // without removing the search text
  const onRefresh = () => {
    if (pageNumber == 0) {
      search();
    } else {
      setPageNumber(0);
    }
  };

  // When Search Text is Updated After Debounce (1 sec), reset the page number to 0
  useEffect(() => {
    setPageNumber(0);
  }, [searchValue]);

  useEffect(() => {
    const controller = new AbortController();

    if (pageNumber != undefined && pageSize != undefined) search(controller);

    return () => {
      controller.abort(); // abort api call on unmount for cleanup
    };
  }, [searchValue, pageNumber, pageSize]);

  useEffect(() => {
    setHasMore(results.length != total);
  }, [results, total]);

  return [
    searchQuery,
    setSearchQuery,
    results,
    onRefresh,
    refreshing,
    onLoadMore,
    loadingMore,
    total,
    hasMore,
  ];
};
export default useVendorPOs;
