import { Box, Button, Card, Fab, Typography } from "@mui/material";
import InfiniteScroller from "../../../components/InfiniteScroller/InfiniteScroller";
import moment from "moment/moment";
import { Add, ArrowLeft, ArrowRight } from "@mui/icons-material";
import { useContext, useEffect, useState } from "react";
import ListFooter from "../../../components/InfiniteScroller/ListFooter";
import { AuthContext } from "../../../context/AuthContext";
import usePageName from "../../../hocs/usePageName";
import FabButton from "../../../components/CustomerComponents/FabButton";
import { useNavigate } from "react-router-dom";
import TimeCardRow from "./TimeCardRow";

const TimeCards = () => {
  usePageName({ heading: "Time Cards" });
  const { authToken, ezorder } = useContext(AuthContext);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const initialWeekOf = moment().startOf("isoweek");
  const [weekOf, setWeekOf] = useState(initialWeekOf);
  const [weeklyTimeCards, setWeeklyTimeCards] = useState([]);
  const navigate = useNavigate();

  const [modalVisible, setModalVisible] = useState(false);

  const getWeeklyTimeCards = async (firstDayOfWeek) => {
    try {
      setIsRefreshing(true);
      const response = await ezorder.post(
        `/shop/crew/time-cards/week`,
        {
          firstDayOfWeek: firstDayOfWeek,
          lastDayOfWeek: moment(moment(firstDayOfWeek))
            .add(6, "day")
            .set({ hour: 23, minute: 59, second: 59 })
            .format(),
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      if (response.status === 200 || response.status == 201) {
        setWeeklyTimeCards(response.data.timeCards);
        //console.log("RES", response.data.timeCards);
        console.log(`${response.data.timeCards.length} time cards in total`);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsRefreshing(false);
    }
  };

  const createTimeCard = async (timeCard) => {
    console.log("Create Time Card", timeCard);
    try {
      setIsRefreshing(true);
      const response = await ezorder.post(`/shop/crew/time-cards`, timeCard, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      console.log("response", response.data);
    } catch (error) {
      //console.log(error);
    } finally {
      setIsRefreshing(false);
    }
  };

  useEffect(() => {
    getWeeklyTimeCards(weekOf);
  }, [weekOf]);

  if (!weekOf) return null;

  return (
    <>
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Button
          onClick={() => {
            console.log("Previous Week");
            setWeekOf(moment(weekOf).subtract(1, "weeks").startOf("isoWeek"));
          }}
        >
          <ArrowLeft />
        </Button>
        <Typography>
          {moment(weekOf).format("MM/DD/YYYY")} -{" "}
          {moment(moment(weekOf)).add(6, "day").format("MM/DD/YYYY")}
        </Typography>
        <Button
          onClick={() => {
            console.log("Next Week");
            setWeekOf(moment(weekOf).add(1, "weeks").startOf("isoWeek"));
          }}
        >
          <ArrowRight size={32} />
        </Button>
      </Box>
      <InfiniteScroller
        // refreshControl={
        //   <RefreshControl
        //     refreshing={isRefreshing}
        //     onRefresh={() => {
        //       getWeeklyTimeCards(weekOf);
        //     }}
        //   />
        // }
        items={weeklyTimeCards}
        renderItem={(item, index) => {
          return (
            <TimeCardRow
              key={index}
              timeCard={item}
              onClick={() => {
                navigate(`/time-cards/${item.id}`);
              }}
            />
          );
        }}
        // onRefresh={onRefresh}
        onEndReached={() => {}}
        loading={isRefreshing}
        ListFooterComponent={() => {
          return <ListFooter hasMore={false} onClick={null} />;
        }}
      />

      <FabButton
        color="primary"
        onClick={() => {
          navigate("/time-cards/new");
        }}
      >
        <Add sx={{ mr: 1 }} />
        New Time Card
      </FabButton>
      {/* <Modal
        contentContainerStyle={{ flexGrow: 1 }}
        animationType="slide"
        transparent={false}
        visible={modalVisible}
        onDismiss={() => {
          setModalVisible(false);
        }}
      >
        <TimeCardForm
          onSubmit={(timeCard) => {
            createTimeCard(timeCard);
            setModalVisible(false);
          }}
          disable={false}
        />
      </Modal> */}
    </>
  );
};

export default TimeCards;
