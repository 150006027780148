import React, { useState, createContext, useEffect } from "react";

export const HeadingContext = createContext();

export const HeadingContextProvider = ({ children }) => {
  const [headingText, setHeadingText] = useState(null);
  const [captionText, setCaptionText] = useState(null);

  return (
    <HeadingContext.Provider
      value={{ headingText, setHeadingText, captionText, setCaptionText }}
    >
      {children}
    </HeadingContext.Provider>
  );
};
