import { useContext, useState, useEffect } from "react";
import { AuthContext } from "../context/AuthContext";

const useAvailableColorsByManufacturer = (paintManufacturerId) => {
  const [colors, setColors] = useState([]);
  const [refreshing, setRefreshing] = useState(false);
  const { authToken, ezorder } = useContext(AuthContext);
  const header = {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  };

  const getColors = async (manufacturerId, controller) => {
    try {
      setRefreshing(true);
      let data = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };

      if (controller) {
        data.signal = controller.signal;
      }

      const response = await ezorder.get(
        `/app/paint-manufacturers/${manufacturerId}/colors`,
        data
      );
      setColors(response.data.colors);
      return response.data.colors;
    } catch (error) {
      console.log(error);
    } finally {
      setRefreshing(false);
    }
  };

  useEffect(() => {
    const controller = new AbortController();

    if (paintManufacturerId) {
      getColors(paintManufacturerId);
    }

    return () => {
      controller.abort(); // abort api call on unmount for cleanup
    };
  }, [paintManufacturerId]);

  return {
    colors,
    getColors,
    refreshing,
  };
};
export default useAvailableColorsByManufacturer;
