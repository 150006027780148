import { LoadingButton } from "@mui/lab";
import { Box, Card, TextField, Typography } from "@mui/material";
import { AuthContext } from "../../../../../context/AuthContext";
import { SnackAlertContext } from "../../../../../context/SnackAlertContext";
import { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import moment from "moment/moment";

const CommentSection = ({ comments, addComment, loading }) => {
  const [comment, setComment] = useState("");
  return (
    <Box>
      <Typography
        style={{
          marginTop: 32,
        }}
        variant="h5"
      >
        Comments
      </Typography>
      <TextField
        sx={{ marginTop: "8px" }}
        fullWidth
        multiline
        rows={3}
        label="Comment"
        value={comment}
        onChange={(e) => setComment(e.target.value)}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "16px",
        }}
      >
        <LoadingButton
          disabled={loading || !comment || comment.length == 0}
          sx={{ marginTop: "8px" }}
          variant="contained"
          onClick={async () => {
            await addComment(comment);
            setComment("");
          }}
        >
          Add Comment
        </LoadingButton>
      </Box>
      {comments &&
        comments.map((comment, index) => {
          let createdAt = moment(new Date(comment.createdAt)).local();
          let weekOld = moment(new Date(createdAt)).local();
          weekOld.add(7, "d");
          let isWeekOld = moment() > weekOld;

          return (
            <Card key={index} style={{ padding: 16, marginTop: 8 }}>
              <Box style={{ display: "flex", flexDirection: "row" }}>
                {comment.user ? (
                  <Typography style={{ marginRight: 8, fontWeight: "bold" }}>
                    {comment.user.fullName}
                  </Typography>
                ) : (
                  <Typography style={{ marginRight: 8, fontWeight: "bold" }}>
                    {comment.guestName}
                  </Typography>
                )}
                <Typography>
                  {isWeekOld
                    ? createdAt.format("MM/DD/YYYY")
                    : createdAt.fromNow()}
                </Typography>
              </Box>
              <Typography style={{ marginTop: 8 }}>{comment.text}</Typography>
            </Card>
          );
        })}
    </Box>
  );
};

export default CommentSection;
