import { Box, Card, Stack, Typography } from "@mui/material";
import { toTwoDecimals } from "../../../../calculations/utils";

const Totals = ({ totals, skidCharge, isPrinting = false }) => (
  <Box sx={{ padding: 2, margin: 1, color: isPrinting ? "#000" : "inherit" }}>
    <Typography variant={"h6"}>ORDER TOTALS</Typography>
    <Stack direction={"row"} spacing={1}>
      <Stack direction={"column"} spacing={1}>
        <Typography>Out The Door Price:</Typography>
        <Typography>Skid Charge: </Typography>
        <Typography>Total Material Cost:</Typography>
        <Typography>Total Labor Cost:</Typography>
        <Typography>Total Labor Hours:</Typography>
        <Typography>Total Hard Cost:</Typography>
        <Typography>Order GP $:</Typography>
        <Typography>Order GP %:</Typography>
      </Stack>
      <Stack direction={"column"} spacing={1}>
        <Typography>${toTwoDecimals(totals.outTheDoorCost ?? 0)}</Typography>
        <Typography>${toTwoDecimals(skidCharge ?? 0)}</Typography>
        <Typography>${toTwoDecimals(totals.totalMaterialCost ?? 0)}</Typography>
        <Typography>${toTwoDecimals(totals.totalLaborCost ?? 0)}</Typography>
        <Typography>{toTwoDecimals(totals.totalLaborHours ?? 0)}</Typography>

        <Typography>${toTwoDecimals(totals.totalHardCost ?? 0)}</Typography>
        <Typography>${toTwoDecimals(totals.totalGPDollars ?? 0)}</Typography>
        <Typography>
          {toTwoDecimals(totals.totalGPPercent * 100 ?? 0)}%
        </Typography>
      </Stack>
    </Stack>
  </Box>
);

export default Totals;
