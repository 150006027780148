import { useState, useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, Typography, Box, Grid, Button, TextField } from "@mui/material";
import moment from "moment";
import { LoadingButton } from "@mui/lab";
import { AuthContext } from "../../../context/AuthContext";
import useTrim from "../../../hooks/useTrim";
import useTrimOrderHistory from "../../../hooks/useTrimOrderHistory";
import { SnackAlertContext } from "../../../context/SnackAlertContext";
import S3PresignedImage from "../../../components/CustomerComponents/S3/S3PresignedImage";
import useTrimOrderHistoryAdmin from "../../../hooks/useTrimOrderHistoryAdmin";
import AddCatalogItemModal from "../../../components/Catalog/AddCatalogItemModal";
import EditTrimDialog from "../../../components/Catalog/EditTrimDialog";
import createPresignedUrl from "../../../helper/createPresignedUrl";
import uploadImage from "../../../helper/uploadImage";
import useTrimAdmin from "../../../hooks/useTrimAdmin";
import { Delete } from "@mui/icons-material";
import DeleteTrimDialog from "../../../components/Catalog/DeleteTrimDialog";

const MasterTrimDetail = () => {
  const navigate = useNavigate();
  const { authToken, ezorder, role, decoded } = useContext(AuthContext);
  const { openSnackMessage } = useContext(SnackAlertContext);
  const { id } = useParams();
  const [isUpdating, setIsUpdating] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const [trim, getTrim, deleteTrim, loading, error] = useTrimAdmin(
    authToken,
    id
  );
  const { orderHistory } = useTrimOrderHistoryAdmin(authToken, id);
  const [editTrimDialogOpen, setEditTrimDialogOpen] = useState(false);

  const orderSelectHandler = (projectId, orderId, orderStatus) => {
    navigate(`/orders/${orderId}`);
  };

  const updateIsCustomerStockTrim = async (saveToCatalog) => {
    try {
      setIsUpdating(true);
      const response = await ezorder.patch(
        `/products/trims/${id}/save-to-catalog`,
        { saveToCatalog },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      getTrim();
    } catch (error) {
      console.log(error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage("error", error.response.data.error);
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", error);
      }
    } finally {
      setIsUpdating(false);
    }
  };

  const onUpdate = async (
    name,
    imageBlob,
    saveToCatalog,
    masterHits,
    masterStretchOut,
    savedImage
  ) => {
    let key = savedImage;
    if (!savedImage) {
      // Create Presigned URL
      let actionType = "WRITE";
      let fileName = imageBlob.name;
      let contentType = imageBlob.type;
      let resource = "trims";
      let identifier = "MASTER"; // decoded.companyId;
      const presignedUrlResponse = await createPresignedUrl(
        ezorder,
        authToken,
        actionType,
        fileName,
        contentType,
        resource,
        identifier
      );
      console.log("presignedUrlResponse", presignedUrlResponse);

      // Upload URL
      await uploadImage(presignedUrlResponse.uploadUrl, imageBlob);

      key = presignedUrlResponse.key;
    }
    await updateMasterTrim(
      name,
      key,
      saveToCatalog,
      masterHits,
      masterStretchOut
    );
  };

  const updateMasterTrim = async (
    name,
    image,
    saveToCatalog,
    masterHits,
    masterStretchOut
  ) => {
    let update = {
      name,
      image,
      saveToCatalog,
      masterHits,
      masterStretchOut,
      isMasterCatalog: true,
    };

    const response = await ezorder.put(
      `/admin/master-trim-catalog/${trim.id}`,
      update,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );

    getTrim();
  };

  if (trim)
    return (
      <>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            // gap: "1rem",
          }}
        >
          <div style={{ padding: "24px" }}>
            <Typography variant="h5" sx={{ textAlign: "center" }}>
              Industry Master Trim
            </Typography>
            <S3PresignedImage s3Key={trim.image} />
            <Box sx={{ marginTop: "16px" }}>
              <TextField
                disabled
                sx={{ margin: "8px" }}
                variant="outlined"
                label="Name"
                size="small"
                value={trim.name ? trim.name : ""}
                // onChange={(e) => setPickupStatus(e.target.value)}
              />
            </Box>
            {trim.isMasterCatalog && (
              <Box sx={{ marginTop: "16px" }}>
                <Box>
                  <TextField
                    disabled
                    sx={{ margin: "8px" }}
                    type="number"
                    variant="outlined"
                    label="Master Hits"
                    size="small"
                    value={trim.masterHits ? trim.masterHits : 1}
                    // onChange={(e) => setPickupStatus(e.target.value)}
                  />
                  <TextField
                    disabled
                    sx={{ margin: "8px" }}
                    type="number"
                    variant="outlined"
                    label="Master Stretch Out"
                    size="small"
                    value={trim.masterStretchOut ? trim.masterStretchOut : 1}
                    // onChange={(e) => setPickupStatus(e.target.value)}
                  />
                </Box>
              </Box>
            )}
            {trim && trim.isMasterCatalog && !trim.deletedAt && (
              <Box>
                <EditTrimDialog
                  trim={trim}
                  // catalogCompanyName={catalogCompanyName}
                  // enableSaveToCatalog={enableSaveToCatalog}
                  open={editTrimDialogOpen}
                  setOpen={setEditTrimDialogOpen}
                  onSubmit={(
                    name,
                    imageBlob,
                    saveToCatalog,
                    masterHits,
                    masterStretchOut,
                    savedImage // original saved image
                  ) => {
                    onUpdate(
                      name,
                      imageBlob,
                      saveToCatalog,
                      masterHits,
                      masterStretchOut,
                      savedImage
                    );
                  }}
                  isMasterCatalogItem={trim.isMasterCatalog}
                />
                <LoadingButton
                  loading={loading || isUpdating}
                  onClick={() => {
                    setEditTrimDialogOpen(true);
                  }}
                >
                  Edit Master Trim Details
                </LoadingButton>
              </Box>
            )}

            {trim && trim.isMasterCatalog && !trim.deletedAt && (
              <Box>
                <DeleteTrimDialog
                  open={deleteDialogOpen}
                  handleClose={() => {
                    setDeleteDialogOpen(false);
                  }}
                  onConfirm={deleteTrim}
                  loading={loading}
                />

                <LoadingButton
                  loading={loading || isUpdating}
                  color="error"
                  onClick={() => {
                    setDeleteDialogOpen(true);
                  }}
                >
                  <Delete color="error" />
                  Delete
                </LoadingButton>
              </Box>
            )}

            {/* {!trim.isMasterCatalog && trim.catalog && (
              <LoadingButton
                loading={loading || isUpdating}
                onClick={() => {
                  updateIsCustomerStockTrim(false);
                }}
              >
                Remove From Stock Catalog
              </LoadingButton>
            )} */}
            {/* {!trim.isMasterCatalog && !trim.catalog && (
              <LoadingButton
                loading={loading || isUpdating}
                onClick={() => {
                  updateIsCustomerStockTrim(true);
                }}
              >
                Add To Stock Catalog
              </LoadingButton>
            )} */}
          </div>
          <div>
            {orderHistory &&
              orderHistory?.map((order, index) => (
                <div
                  onClick={() => {
                    orderSelectHandler(
                      order.project.id,
                      order.id,
                      order.orderStatus
                    );
                  }}
                >
                  <Card style={{ marginTop: 8 }}>
                    <div style={{ padding: 10 }}>
                      {moment(order.createdAt).format("MM/DD/YYYY")} - Order #
                      {order.id}
                    </div>
                    <div style={{ padding: 10 }}>
                      Project {order.project.name}
                    </div>
                    <div style={{ padding: 10 }}>PO: {order.poNumber}</div>
                  </Card>
                </div>
              ))}
          </div>
        </Box>
      </>
    );
};
export default MasterTrimDetail;
