import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { IconButton } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import getPurchaseOrderDocDefinition from "../../pdf-helpers/PurchaseOrderHelper";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const PurchaseOrderButton = (props) => {
  const { poId, buttonText } = props;
  const { authToken, ezorder } = useContext(AuthContext);

  const getPo = async (id) => {
    try {
      let data = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };

      const response = await ezorder.get(`/admin/purchase-orders/${id}`, data);
      return response.data.po;
    } catch (error) {
      // console.log(error);
      // Object.keys(error).map((item) => console.log(error[item]));
      throw Error("Unable to load po", { cause: error });
    }
  };

  const getPDF = async () => {
    try {
      // GET PO
      const po = await getPo(poId);

      const docDef = getPurchaseOrderDocDefinition(po);

      pdfMake.createPdf(docDef).open();
    } catch (error) {
      console.log("ERROR", error);
      throw Error("Unable to load PDF", { cause: error });
    }
  };

  return (
    <IconButton
      {...props}
      variant="outlined"
      color="primary"
      onClick={() => {
        getPDF();
      }}
    >
      {buttonText ? buttonText : "PDF"}
      <DownloadIcon />
    </IconButton>
  );
};

export default PurchaseOrderButton;
