import { useEffect, useState } from "react";
import {
  Card,
  Typography,
  Box,
  Grid,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  IconButton,
  useMediaQuery,
  TextField,
  MenuItem,
  Button,
  Fab,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Star, StarBorder } from "@mui/icons-material";
import { useTheme } from "@emotion/react";
import S3PresignedImage from "../CustomerComponents/S3/S3PresignedImage";
import FabButton from "../CustomerComponents/FabButton";
import AddCatalogItemModal from "./AddCatalogItemModal";
import SearchBar from "../SearchBar";
import InfiniteScroller from "../InfiniteScroller/InfiniteScroller";
import ListFooter from "../InfiniteScroller/ListFooter";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { Link } from "react-router-dom";

const Catalog = ({
  projectTag,
  search,
  setSearch,
  catalogFilter,
  setCatalogFilter,
  enableCatalogFilters,
  items,
  itemType,
  onRefresh,
  refreshing,
  onLoadMore,
  isLoadingMore,
  onSelect,
  saveNew,
  hasMore,
  enableSaveToCatalog = false,
  useFab = true,
  fabAbsolutePositionInContainer = false, // Whether to use absolute (to relative positioned container) or fixed positioning (whole screen)
  isMasterCatalogItem = false,
  disableAddNew = false,
  catalogCompanyName,
  enablePrint = false,
  industryCatalogLink = "/catalog/industry",
  companyCatalogLink = "/catalog/stock",
}) => {
  const [newItemModalOpen, setNewItemModalOpen] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { decoded } = useContext(AuthContext);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: isSmallScreen ? "column" : "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {enableCatalogFilters && (
          <TextField
            size="small"
            sx={{ minWidth: "175px" }}
            fullWidth={isSmallScreen}
            margin="normal"
            select
            label="Filter"
            value={catalogFilter}
            onChange={(e) => setCatalogFilter(e.target.value)}
          >
            <MenuItem value="master">
              Industry Stock Catalog
              {enablePrint && (
                <Link
                  style={{ color: theme.palette.primary.dark }}
                  target="_blank"
                  rel="noopener noreferrer"
                  to={industryCatalogLink}
                >
                  <Button
                    style={{ padding: 0, maxWidth: "10px" }}
                    sx={{ padding: 0 }}
                  >
                    Print
                  </Button>
                </Link>
              )}
            </MenuItem>
            <MenuItem value="catalog">
              Customer Stock Catalog
              {enablePrint && (
                <Link
                  style={{ color: theme.palette.primary.dark }}
                  target="_blank"
                  rel="noopener noreferrer"
                  to={companyCatalogLink}
                >
                  <Button
                    style={{ padding: 0, maxWidth: "10px" }}
                    sx={{ padding: 0 }}
                  >
                    Print
                  </Button>
                </Link>
              )}
            </MenuItem>
            <MenuItem value="all">Previously Used Trims</MenuItem>
            {projectTag && (
              <MenuItem value="project">
                Trims From Selected{" "}
                {decoded.isVendorOrSupplier ? "Customer" : "Job"}: {projectTag}
              </MenuItem>
            )}
          </TextField>
        )}
        <Box
          sx={{
            flex: 1,
            marginLeft: isSmallScreen ? 0 : "16px",
            width: "100%",
          }}
        >
          <SearchBar
            label="Search"
            searchQuery={search}
            setSearchQuery={setSearch}
          />
        </Box>
      </div>
      {!useFab && !disableAddNew && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "8px",
          }}
        >
          <Button
            variant="contained"
            sx={{ minWidth: "300px" }}
            color="success"
            onClick={() => {
              setNewItemModalOpen(true);
            }}
          >
            <AddIcon sx={{ mr: 1 }} />
            Custom Piece
          </Button>
        </Box>
      )}
      {useFab && !disableAddNew && (
        <Fab
          sx={{
            position: fabAbsolutePositionInContainer ? "absolute" : "fixed",
            bottom: 70,
            right: isSmallScreen ? 16 : 56,
          }}
          color="success"
          variant="extended"
          aria-label="add"
          onClick={() => {
            setNewItemModalOpen(true);
          }}
        >
          <AddIcon sx={{ mr: 1 }} />
          Custom Piece
        </Fab>
      )}

      <Box sx={{ flex: 1 }}>
        <InfiniteScroller
          items={items}
          renderItem={(item, index) => {
            return (
              <ImageListItem
                key={index}
                onClick={() => {
                  let itemCopy = {
                    ...item,
                  };
                  delete itemCopy.trimPieces;
                  onSelect(itemCopy);
                }}
              >
                <S3PresignedImage
                  s3Key={item.image}
                  style={{ height: "100%" }}
                  disabledPreview={true}
                />
                <ImageListItemBar
                  title={item.name}
                  // actionIcon={
                  //   <IconButton>
                  //     {" "}
                  //     {item.catalog ? (
                  //       <Star color="primary" />
                  //     ) : (
                  //       <StarBorder color="primary" />
                  //     )}
                  //   </IconButton>
                  // }
                />
              </ImageListItem>
            );
          }}
          onRefresh={onRefresh}
          onEndReached={onLoadMore}
          loading={refreshing || isLoadingMore}
          ListComponent={() => {
            return (
              <ImageList
                sx={{
                  width: "100%",
                }}
                cols={isSmallScreen ? 2 : 4}
                gap={8}
              >
                <></>
              </ImageList>
            );
          }}
          ListFooterComponent={() => {
            return <ListFooter hasMore={hasMore} onClick={onLoadMore} />;
          }}
        />
      </Box>

      <AddCatalogItemModal
        catalogCompanyName={catalogCompanyName}
        enableSaveToCatalog={enableSaveToCatalog}
        open={newItemModalOpen}
        setOpen={setNewItemModalOpen}
        onSubmit={(
          name,
          imageBlob,
          saveToCatalog,
          masterHits,
          masterStretchOut
        ) => {
          saveNew(name, imageBlob, saveToCatalog, masterHits, masterStretchOut);
        }}
        isMasterCatalogItem={isMasterCatalogItem}
      />
    </Box>
  );
};
export default Catalog;
