import {
  Box,
  Card,
  Checkbox,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import ShopS3PresignedCardImage from "../../../../components/ShopS3PresignedCardImage";
import ShopS3PresignedImageLink from "../../../../components/ShopS3PresignedImageLink";

const TrimAndFlashing = (props) => {
  const {
    accessToken,
    trim,
    includeImages = true,
    isEstimate,
    onShearToggle,
    showTotal = false,
    showPricePerPiece = false,
    noCalcs = false,
    isMinifiedList = false,
  } = props;
  if (!trim) return null;
  let trimTotalSellPrice = 0;
  for (const piece of trim.trimPieces) {
    if (piece.calculations && piece.calculations.totalSellPrice)
      trimTotalSellPrice += parseFloat(piece.calculations.totalSellPrice);
  }
  return (
    <Box {...props}>
      {showTotal && !noCalcs ? (
        <Typography variant="h5">
          Trim & Flashing - ${Math.round(trimTotalSellPrice)}
        </Typography>
      ) : (
        <Typography variant="h5">Trim & Flashing</Typography>
      )}
      {isMinifiedList && (
        <TableContainer component={Card} sx={{ marginTop: "8px" }}>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ minWidth: "150px" }}>Piece Name</TableCell>
                <TableCell align="right">Qty</TableCell>
                {!isEstimate && !noCalcs && (
                  <TableCell align="right">Stretch Out</TableCell>
                )}
                {showPricePerPiece && !noCalcs && (
                  <TableCell align="right">Price Per Piece</TableCell>
                )}
                {/* {!isEstimate && <TableCell align="right">Sheared</TableCell>} */}
              </TableRow>
            </TableHead>
            <TableBody>
              {trim.trimPieces.map((piece, index) => {
                return (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <ShopS3PresignedImageLink
                        linkText={piece.name}
                        s3Key={piece.image}
                        accessToken={accessToken}
                      />
                    </TableCell>
                    <TableCell align="right">{piece.quantity}</TableCell>
                    {!isEstimate && !noCalcs && (
                      <TableCell align="right">{piece.stretchOut}</TableCell>
                    )}
                    {showPricePerPiece && !noCalcs && (
                      <TableCell align="right">
                        ${piece.calculations.pricePerPiece}
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {!isMinifiedList &&
        trim.trimPieces.map((piece, index) => (
          <Card
            key={index}
            sx={{
              marginTop: "16px",
              padding: "16px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {includeImages && (
              <ShopS3PresignedCardImage
                s3Key={piece.image}
                accessToken={accessToken}
              />
            )}

            <TableContainer component={Box} sx={{ marginTop: "8px" }}>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>Piece Name</TableCell>
                    <TableCell align="center">Qty</TableCell>
                    {!isEstimate && !noCalcs && (
                      <TableCell align="center">Stretch Out</TableCell>
                    )}
                    {showPricePerPiece && !noCalcs && (
                      <TableCell align="center">Price Per Piece</TableCell>
                    )}
                    {/* {!isEstimate && <TableCell align="right">Sheared</TableCell>} */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {piece.name}
                    </TableCell>
                    <TableCell align="center">{piece.quantity}</TableCell>
                    {!isEstimate && !noCalcs && (
                      <TableCell align="center">{piece.stretchOut}</TableCell>
                    )}
                    {showPricePerPiece && !noCalcs && (
                      <TableCell align="center">
                        ${piece.calculations.pricePerPiece}
                      </TableCell>
                    )}
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        ))}
    </Box>
  );
};

export default TrimAndFlashing;
