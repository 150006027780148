import { useContext, useEffect, useState } from "react";
import {
  Box,
  Paper,
  Typography,
  IconButton,
  Button,
  CircularProgress,
  Checkbox,
  TextField,
  MenuItem,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import S3PresignedPdf from "../../../../../S3PresignedPdf";
import { Download } from "@mui/icons-material";
import { AuthContext } from "../../../../../../context/AuthContext";
import estimatePdfDownloadHandler from "../../../../../../helper/estimatePdfDownloader";
import moment from "moment/moment";

const CustomerQuote = ({
  order,
  orderId,
  estimatePdf,
  enableGenerateEstimate,
  generateEstimateForOrder,
  isGeneratingEstimate,
  selectedPaymentTermId,
  isItemized,
  toggleItemized,
  // isCustomerSupplied,
  // toggleCustomerSupplied,
}) => {
  const { authToken } = useContext(AuthContext);
  const [expanded, setExpanded] = useState(true);
  const [isSalesTaxApplicable, setIsSalesTaxApplicable] = useState(true);

  // Set initial Payment Term Selected as the order's set payment term
  // If not available, then use the customer's payment term
  // If also not available, default to null
  const [paymentTermId, setPaymentTermId] = useState(null);

  return (
    <Box sx={{ mt: "20px" }}>
      {expanded ? (
        <Box
          elevation={3}
          sx={{
            padding: "2px 15px 15px 15px",
            border: "1px solid white",
            borderRadius: "5px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Typography variant="h6" sx={{ mb: "15px" }}>
              Customer Quote
            </Typography>

            <IconButton onClick={() => setExpanded(false)}>
              <ExpandLessIcon />
            </IconButton>
          </Box>
          {order.estimateExpirationDate && (
            <Typography
              sx={{
                color:
                  moment(order.estimateExpirationDate) < moment()
                    ? "red"
                    : "inherit",
              }}
            >
              Valid Until:{" "}
              {moment(order.estimateExpirationDate).local().format("MM/DD/YY")}
            </Typography>
          )}
          {estimatePdf && (
            <Button
              onClick={() =>
                estimatePdfDownloadHandler(
                  estimatePdf,
                  authToken,
                  null,
                  null,
                  `CUSTOMER-QUOTE-${order.id}-${order.company.name}-${order.project.name}.pdf`
                )
              }
            >
              <Download color="primary" />
              Quote PDF
            </Button>
          )}
          {enableGenerateEstimate ? (
            <Box>
              <Box
                component="span"
                sx={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    magin: 0,
                    padding: 0,
                  }}
                >
                  <Checkbox
                    style={{
                      magin: 0,
                      padding: 0,
                    }}
                    checked={isItemized}
                    onChange={(e) => {
                      toggleItemized(e.target.checked);
                    }}
                  />
                  <Typography>Add Price Per Piece</Typography>
                </div>
                <Button
                  Loading={isGeneratingEstimate}
                  variant="contained"
                  onClick={() => {
                    generateEstimateForOrder();
                  }}
                  sx={{ marginLeft: "20px" }}
                  disabled={
                    isGeneratingEstimate
                    // paymentTermId === null || paymentTermId === undefined
                    // !selectedPaymentTermId
                  }
                >
                  Generate Quote PDF
                </Button>

                {isGeneratingEstimate && (
                  <CircularProgress
                    size={24}
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-12px",
                      marginLeft: "-12px",
                    }}
                  />
                )}
              </Box>
            </Box>
          ) : null}
        </Box>
      ) : (
        <Box
          elevation={3}
          sx={{
            padding: "2px 15px 15px 15px",
            border: "1px solid white",
            borderRadius: "5px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h6" sx={{ mt: "auto" }}>
              Estimate
            </Typography>

            <IconButton sx={{ mt: "auto" }} onClick={() => setExpanded(true)}>
              <ExpandMoreIcon />
            </IconButton>
          </Box>
        </Box>
      )}
    </Box>
  );
};
export default CustomerQuote;
