import {
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import { LoadingButton } from "@mui/lab";

const SelectExistingInvoiceDialog = ({
  invoice,
  open,
  handleClose,
  onConfirm,
  loading,
}) => {
  const theme = useTheme();
  if (invoice)
    return (
      <Dialog
        keepMounted
        scroll={"paper"}
        maxWidth="sm"
        fullWidth={true}
        PaperProps={{
          style: {
            background: theme.palette.secondary.main,
          },
        }}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>Use existing Invoice?</DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <DialogContentText>
            Would you like to assign this invoice to the following order?
          </DialogContentText>
          <Typography>
            {invoice.DocNumber ? `No. ${invoice.DocNumber} | ` : ``}
            {invoice.CustomerRef.name}
          </Typography>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            onClick={() => {
              handleClose();
            }}
            loading={loading}
          >
            Back
          </LoadingButton>
          <LoadingButton
            variant="contained"
            loading={loading}
            onClick={onConfirm}
          >
            Confirm
          </LoadingButton>
        </DialogActions>
      </Dialog>
    );
};
export default SelectExistingInvoiceDialog;
