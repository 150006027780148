import {
  TextField,
  Typography,
  Box,
  MenuItem,
  Button,
  IconButton,
  DialogActions,
  DialogTitle,
  DialogContent,
  useMediaQuery,
  Card,
  Paper,
  Fab,
  ListItem,
  Divider,
} from "@mui/material";
import AddProductModal from "../AddProductModal";
// import { panelSystem as panelOptions } from "../../../../../../catalog";
import { useState, useContext, useEffect } from "react";
import { MobileOrderContext } from "../../../../../../context/MobileOrderContext";
import { useTheme } from "@emotion/react";
import TextFieldDebounced from "../../../../../../components/TextFieldDebounced";

const FlatSheet = ({
  showSaving,
  isUpdating,
  isProductSelected,
  isAutoSaving,
  canUpdate,
  open,
  handleClose,
  handleNext,
  hasNext,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const { flatSheet, flatSheetDispatch, orderDispatch } =
    useContext(MobileOrderContext);

  const addOrderItem = (item) => {
    orderDispatch({ type: "add order item", payload: item });
  };

  useEffect(() => {
    if (isProductSelected && canUpdate && isAutoSaving) {
      // TODO: auto save on every change
      addOrderItem(flatSheet);
    }
  }, [isProductSelected, flatSheet]);

  return (
    <>
      <AddProductModal
        showSaving={showSaving}
        isUpdating={isUpdating}
        open={open}
        handleClose={() => {
          addOrderItem(flatSheet);
          handleClose();
        }}
      >
        <DialogTitle>Flat Sheet</DialogTitle>
        <DialogContent
          sx={{
            gap: "1rem",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "row", marginTop: "8px" }}>
            <TextFieldDebounced
              disabled={!canUpdate}
              sx={{}}
              onFocus={(e) => e.target.select()}
              type="number"
              onWheel={(event) => {
                event.preventDefault();
              }}
              size="small"
              label="Quantity"
              value={flatSheet.quantity}
              onChange={(e) => {
                flatSheetDispatch({
                  type: "quantity",
                  payload: e.target.value
                    ? parseFloat(e.target.value)
                    : e.target.value,
                });
              }}
            />
            <TextField
              size="small"
              disabled={!canUpdate}
              sx={{ marginLeft: "8px" }}
              fullWidth
              select
              label="Width (in.)"
              value={parseFloat(flatSheet.width)}
              onChange={(e) => {
                flatSheetDispatch({
                  type: "width",
                  payload: parseFloat(e.target.value),
                });
              }}
            >
              <MenuItem value={40} key={40}>
                40"
              </MenuItem>
              <MenuItem value={48} key={48}>
                48"
              </MenuItem>
            </TextField>
          </Box>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => {
              addOrderItem(flatSheet);
              handleClose();
            }}
          >
            {/* {canUpdate && isAutoSaving ? "Save & Close" : "Close"} */}
            Close
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              addOrderItem(flatSheet);
              handleNext();
            }}
          >
            {hasNext
              ? "Next"
              : canUpdate && isAutoSaving
              ? "Save & Exit"
              : "Exit"}
          </Button>
        </DialogActions>
      </AddProductModal>
    </>
  );
};
export default FlatSheet;
