import { useState, useEffect, useContext } from "react";
import {
  Box,
  Fab,
  Paper,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import SignupForm from "../../auth/Signup/SignupForm";

const CreateCustomer = () => {
  const navigate = useNavigate();
  const { authToken, ezorder } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const signup = async (requestBody) => {
    try {
      setLoading(true);
      setErrorMessage("");
      const response = await ezorder.post(`/admin/auth/signup`, requestBody, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      console.log("response data", response.data);
      navigate(`/customers`);
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        if (error.response.data?.error) {
          setErrorMessage(error.response.data.error);
        } else {
          setErrorMessage("Internal server error");
        }
      } else {
        setErrorMessage("Internal server error");
      }
    } finally {
      setLoading(false);
    }
  };

  const signupHandler = async (signupRequest) => {
    console.log("signupRequest", signupRequest);
    await signup(signupRequest);
  };

  return (
    <Box
      sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}
    >
      {/* <Typography variant="h4" component="div">
        Sign Up New Customer
      </Typography> */}
      <SignupForm
        signupHandler={signupHandler}
        loading={loading}
        errorMessage={errorMessage}
        isAdminView={true}
      />
    </Box>
  );
};

export default CreateCustomer;
