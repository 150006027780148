import { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Menu,
  MenuItem,
  IconButton,
  TextField,
  InputAdornment,
} from "@mui/material";
import { Search } from "@mui/icons-material";

const TableSearchAndFilter = ({
  searchTerms,
  setSearchTerms,
  NavButton,
  NavButton2,
}) => {
  // const [anchorEl, setAnchorEl] = useState(null);
  // const open = Boolean(anchorEl);

  // const handleFilterClick = (e) => {
  //   setAnchorEl(e.currentTarget);
  // };

  // const handleClose = (e) => {
  //   // console.log(e.target.getAttribute("value"));
  //   setFilterTerms(e.target.getAttribute("value"));
  //   setAnchorEl(null);
  // };

  return (
    <Box
      component="div"
      sx={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        mb: "10px",
        padding: "5px",
        alignItems: "center",
        justifyContent: "flex-end",
      }}
    >
      {NavButton ? (
        <Box sx={{ marginRight: "16px" }}>
          <NavButton />
        </Box>
      ) : null}

      {NavButton2 ? (
        <Box sx={{ marginRight: "16px" }}>
          <NavButton2 />
        </Box>
      ) : null}

      {/* {filterTerms && (
        <Typography sx={{ alignSelf: "center", mr: "10px" }}>
          Order by: {filterTerms}
        </Typography>
      )} */}
      <Box>
        {/* <IconButton
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleFilterClick}
        >
          <FilterListIcon />
        </IconButton> */}
        {/* <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={(e) => handleClose(e)} value="Date">
            Date
          </MenuItem>
          <MenuItem onClick={handleClose} value="Status">
            Status
          </MenuItem>
          <MenuItem onClick={handleClose} value={null}>
            None
          </MenuItem>
        </Menu> */}
      </Box>
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search color="primary" />
              </InputAdornment>
            ),
          }}
          color="primary"
          margin="normal"
          //fullWidth
          id="search"
          label="Search"
          name="search"
          value={searchTerms}
          onChange={(e) => setSearchTerms(e.target.value)}
        />
      </Box>
    </Box>
  );
};
export default TableSearchAndFilter;
