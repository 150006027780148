import { useState, useEffect, useContext } from "react";
import { AuthContext } from "../context/AuthContext";

const useTrimAdmin = (authToken, trimId) => {
  const { ezorder } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [trim, setTrim] = useState({});
  const [error, setError] = useState(null);

  const getTrim = async (controller) => {
    try {
      setLoading(true);
      let data = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      if (controller) {
        data.signal = controller.signal;
      }
      const response = await ezorder.get(
        `/admin/products/trims/${trimId}`,
        data
      );
      setTrim(response.data.trim);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const deleteTrim = async (controller) => {
    try {
      setLoading(true);
      let data = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      if (controller) {
        data.signal = controller.signal;
      }
      const response = await ezorder.delete(
        `/admin/products/trims/${trimId}`,
        data
      );
      getTrim();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    getTrim(controller);

    return () => {
      controller.abort(); // abort api call on unmount for cleanup
    };
  }, [authToken, trimId]);

  return [trim, getTrim, deleteTrim, loading, error];
};

export default useTrimAdmin;
