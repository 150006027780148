import { Box, Card, Stack, Typography } from "@mui/material";
import { getAccessoryDetails } from "../../../../orderUtils";
import { toTwoDecimals } from "../../../../calculations/utils";
import { getAccessoryTotals } from "../../../../calculations/orderTotals";

const AccessoriesSummary = ({ order, isPrinting = false }) => {
  const accessoryOrderItem = getAccessoryDetails(order);

  const totals = getAccessoryTotals(accessoryOrderItem);

  if (
    accessoryOrderItem &&
    accessoryOrderItem.items &&
    accessoryOrderItem.items.length > 0
  )
    return (
      <Box
        sx={{ padding: 2, margin: 1, color: isPrinting ? "#000" : "inherit" }}
      >
        <Typography variant={"h6"}>ACCESSORIES</Typography>
        <Stack direction={"row"} spacing={1}>
          <Stack direction={"column"} spacing={1}>
            {accessoryOrderItem.items.map((item, index) => (
              <Typography key={index}>{item.name}</Typography>
            ))}
            <Typography>Hard Cost:</Typography>
            <Typography>Markup:</Typography>
            <Typography>Total Sell Price</Typography>
          </Stack>
          <Stack direction={"column"} spacing={1}>
            {accessoryOrderItem.items.map((item, index) => (
              <Typography key={index}>
                {item.quantity} {item.unitOfMeasure}
              </Typography>
            ))}
            <Typography>${toTwoDecimals(totals.totalHardCost)}</Typography>
            <Typography>${toTwoDecimals(totals.totalMarkUp)}</Typography>
            <Typography>${toTwoDecimals(totals.totalSellPrice)}</Typography>
          </Stack>
        </Stack>
      </Box>
    );
};

export default AccessoriesSummary;
