import { Box, Card, Stack, Typography } from "@mui/material";
import { getCopingCapDetails } from "../../../../orderUtils";
import { toTwoDecimals } from "../../../../calculations/utils";
import { getCopingCapTotals } from "../../../../calculations/orderTotals";

const CopingCapSummary = ({ order, isPrinting = false }) => {
  const copingCapOrderItem = getCopingCapDetails(order);
  console.log("COPING CAP ORDER ITEM", copingCapOrderItem);

  let isCleatIncluded = (orderItems) => {
    let cleat = orderItems.find((item) => item.objectType == "Panel");
    if (!cleat) return false;

    return true;
  };

  let isSplicePlateIncluded = (orderItems) => {
    let cleat = orderItems.find((item) => item.objectType == "SplicePlate");
    if (!cleat) return false;

    return true;
  };

  const totals = getCopingCapTotals(copingCapOrderItem);

  if (
    copingCapOrderItem &&
    copingCapOrderItem.capPieces &&
    copingCapOrderItem.capPieces.length > 0
  )
    return (
      <Box
        sx={{ padding: 2, margin: 1, color: isPrinting ? "#000" : "inherit" }}
      >
        <Typography variant={"h6"}>COPING CAP</Typography>
        <Stack direction={"row"} spacing={1}>
          <Stack direction={"column"} spacing={1}>
            <Typography>Total No. of Pieces:</Typography>
            <Typography>Total Flats:</Typography>
            <Typography>Cleat</Typography>
            <Typography>Splice Plate</Typography>
            <Typography>Flat Sheet Cost:</Typography>
            <Typography>Out The Door Cost:</Typography>
            <Typography>Total Material Cost:</Typography>
            <Typography>Total Labor Cost:</Typography>
            <Typography>Total Labor Hours:</Typography>
            <Typography>Total Hard Cost:</Typography>
            <Typography>GP $:</Typography>
            <Typography>GP %:</Typography>
          </Stack>
          <Stack direction={"column"} spacing={1}>
            <Typography>{parseFloat(totals.totalNumberOfPieces)}</Typography>
            <Typography>{parseFloat(totals.totalFlats)}</Typography>
            <Typography>
              {`${isCleatIncluded(order.items)}`.toUpperCase()}
            </Typography>
            <Typography>
              {`${isSplicePlateIncluded(order.items)}`.toUpperCase()}
            </Typography>
            <Typography>${parseFloat(totals.totalFlatSheetCost)}</Typography>
            <Typography>${parseFloat(totals.outTheDoorCost)}</Typography>
            <Typography>${parseFloat(totals.totalMaterialCost)}</Typography>
            <Typography>${parseFloat(totals.totalLaborCost)}</Typography>
            <Typography>{parseFloat(totals.totalLaborHours)}</Typography>
            <Typography>${toTwoDecimals(totals.totalHardCost ?? 0)}</Typography>
            <Typography>
              ${toTwoDecimals(totals.totalGPDollars ?? 0)}
            </Typography>
            <Typography>
              {toTwoDecimals(totals.totalGPPercent * 100 ?? 0)}%
            </Typography>
          </Stack>
        </Stack>
      </Box>
    );
};

export default CopingCapSummary;
