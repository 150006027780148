import React, { useState, useContext, useEffect } from "react";
import {
  Card,
  CircularProgress,
  Grid,
  Box,
  TextField,
  Button,
  Typography,
  Container,
  Stepper,
  Step,
  StepLabel,
  CardActionArea,
  Paper,
  TableCell,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  FormGroup,
  FormControlLabel,
  Checkbox,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import Company from "./Company";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const steps = ["Company Information", "Add User(s)"];

const SignupForm = ({ signupHandler, errorMessage, isAdminView }) => {
  const navigate = useNavigate();
  const [inValidRole, setInvalidRole] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = (formik) => {
    if (activeStep == 0) {
      formik.validateForm().then((errors) => {
        if (!errors.companyName) {
          // Setting error only for this step
          formik.setErrors({ companyName: errors.companyName });
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
      });
    }
    if (activeStep == 1) {
      formik.submitForm();
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&?.,*])(?=.{8,})/;
  const formValidationSchema = Yup.object({
    companyName: Yup.string().required("Company Name is required"),
    ownerFullName: Yup.string().required("Name is required"),
    ownerEmail: Yup.string().email().required("Email is required"),
    // ownerPassword: Yup.string().required("Password is required"),
    ownerPassword: Yup.string()
      .required("Password Required")
      .max(
        20,
        "Password Must Contain between 8-20 Characters, One Uppercase, One Lowercase, One Number and One Special Character"
      )
      .min(
        8,
        "Password Must Contain between 8-20 Characters, One Uppercase, One Lowercase, One Number and One Special Character"
      )
      .matches(
        passwordRegex,
        "Password Must Contain between 8-20 Characters, One Uppercase, One Lowercase, One Number and One Special Character"
      ),
  });

  const formik = useFormik({
    initialValues: {
      companyName: "",
      companyContactName: "",
      companyContactEmail: "",
      companyContactPhone: "",
      isVendorOrSupplier: false,

      ownerFullName: "",
      ownerEmail: "",
      ownerPhone: "",
      ownerPassword: "",
      copyOwnerToAllNotifications: false,
      // employees: [],
    },
    validationSchema: formValidationSchema,
    validateOnBlur: false,
    validateOnChange: true,
    onSubmit: (values) => {
      console.log("SUBMITTED", values);
      signupHandler(values);
    },
  });

  return (
    <div>
      <Container maxWidth="md" sx={{ paddingBottom: "24px" }}>
        <Card
          sx={{
            padding: "24px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {isAdminView ? (
            <Typography component="h1" variant="h5">
              Add New Customer
            </Typography>
          ) : (
            <Typography component="h1" variant="h5">
              Sign Up
            </Typography>
          )}
          {isAdminView ? (
            <Box
              sx={{
                m: 1,
                position: "relative",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {errorMessage && (
                <Box>
                  <Typography color={"red"}>{errorMessage}</Typography>
                </Box>
              )}
            </Box>
          ) : (
            <Box
              sx={{
                m: 1,
                position: "relative",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Button
                variant="text"
                size="small"
                sx={{ mt: 1 }}
                disabled={isLoading}
                onClick={() => {
                  console.log("signup");
                  navigate("/");
                }}
              >
                Already Have an Account? Login
              </Button>
              {isLoading && (
                <CircularProgress
                  size={24}
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              )}
            </Box>
          )}

          <Box sx={{ width: "100%" }}>
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            {activeStep === 0 && (
              <Company
                formik={formik}
                errorMessage={errorMessage}
                isAdminView={isAdminView}
              />
            )}
            {activeStep === 1 && (
              <Users formik={formik} errorMessage={errorMessage} />
            )}

            <>
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Button
                  color="inherit"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  Back
                </Button>
                <Box sx={{ flex: "1 1 auto" }} />

                <Button onClick={() => handleNext(formik)}>
                  {activeStep === steps.length - 1 ? "Finish" : "Next"}
                </Button>
              </Box>
            </>
          </Box>

          {inValidRole && (
            <Box sx={{ color: "#cf0404" }}>
              You Are Not Authorized To Visit This Page.
            </Box>
          )}
        </Card>
      </Container>
    </div>
  );
};

const Users = ({ formik, errorMessage }) => {
  // const [permissions, setPermissions] = useState([]);

  // const getPermissions = async () => {
  //   try {
  //     const response = await ezorder.get(`/auth/permissions`);
  //     setPermissions(response.data.permissions);
  //     console.log(response.data.permissions);
  //   } catch (error) {}
  // };

  // const handleAddEmployee = (formik) => {
  //   formik.setValues({
  //     employees: [
  //       ...formik.values.employees,
  //       { fullName: "", email: "", permissions: [...permissions] },
  //     ],
  //   });
  // };

  // const handleRemoveEmployee = (formik, index) => {
  //   let employeesCopy = [...formik.values.employees];
  //   employeesCopy.splice(index, 1);
  //   formik.setValues({
  //     employees: employeesCopy,
  //   });
  // };

  // useEffect(() => {
  //   getPermissions();
  // }, []);

  // console.log("FORMIK ERRORS", formik.errors);

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  return (
    <Box component="form" sx={{ mt: 1 }}>
      <Grid container>
        <Grid item xs={12} padding={2}>
          <Typography>Owner Account</Typography>
          <TextField
            margin="normal"
            required
            fullWidth
            id="ownerFullName"
            label="Full Name"
            name="ownerFullName"
            value={formik.values.ownerFullName}
            onChange={formik.handleChange}
            autoComplete="name"
          />
          {formik.errors.ownerFullName ? (
            <Typography color={"red"}>{formik.errors.ownerFullName}</Typography>
          ) : null}
          <TextField
            margin="normal"
            fullWidth
            id="ownerPhone"
            label="Phone Number"
            name="ownerPhone"
            autoComplete="phone"
            value={formik.values.ownerPhone}
            onChange={formik.handleChange}
            // onChange={(e) => {
            //   console.log("changed");
            //   console.log(e.target.value);
            // }}
          />
          {formik.errors.ownerPhone ? (
            <Typography color={"red"}>{formik.errors.ownerPhone}</Typography>
          ) : null}
          <TextField
            margin="normal"
            required
            fullWidth
            id="ownerEmail"
            label="Email Address"
            name="ownerEmail"
            value={formik.values.ownerEmail}
            onChange={formik.handleChange}
            autoComplete="email"
          />
          {formik.errors.ownerEmail ? (
            <Typography color={"red"}>{formik.errors.ownerEmail}</Typography>
          ) : null}
          <Typography>
            Password Must Contain between 8-20 Characters, One Uppercase, One
            Lowercase, One Number and One Special Character
          </Typography>
          <TextField
            margin="normal"
            required
            fullWidth
            name="ownerPassword"
            label="Password"
            id="ownerPassword"
            value={formik.values.ownerPassword}
            onChange={formik.handleChange}
            autoComplete="current-password"
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {formik.errors.ownerPassword ? (
            <Typography color={"red"}>{formik.errors.ownerPassword}</Typography>
          ) : null}
          <FormControlLabel
            control={
              <Checkbox
                checked={formik.values.copyOwnerToAllNotifications}
                onChange={(e) => {
                  formik.setValues({
                    ...formik.values,
                    copyOwnerToAllNotifications: e.target.checked,
                  });
                }}
              />
            }
            label="Copy on all quote requests, quote, and quote approval notifications"
          />
        </Grid>
      </Grid>

      {errorMessage && (
        <Box>
          <Typography color={"red"}>{errorMessage}</Typography>
        </Box>
      )}
    </Box>
  );
};

export default SignupForm;
