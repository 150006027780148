import withError from "../../../../hocs/withError";
import withLoading from "../../../../hocs/withLoading";
import makePage from "../../../../hocs/makePage";
import {
  Box,
  Button,
  Typography,
  Modal,
  TextField,
  MenuItem,
  Checkbox,
  Dialog,
  useMediaQuery,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import { useContext } from "react";
import { AuthContext } from "../../../../context/AuthContext";
import { LoadingButton } from "@mui/lab";

const CreateProjectView = ({
  projectName,
  handleProjectName,
  loading,
  selectedTaxForm,
  handleTaxFormSelect,
  taxForms,
  handleSubmitProject,
  open,
  handleOpen,
  handleClose,
  startNewOrder,
  setStartNewOrder,
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { decoded } = useContext(AuthContext);
  return (
    <Dialog
      keepMounted
      scroll={"paper"}
      maxWidth="xs"
      fullWidth={true}
      // fullScreen={fullScreen}
      PaperProps={{
        style: {
          background: theme.palette.secondary.main,
        },
      }}
      open={open}
      onClose={handleClose}
    >
      <DialogTitle sx={{ background: theme.palette.secondary.main }}>
        {decoded.isVendorOrSupplier ? "Create a Customer" : "Create a Project"}
      </DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <DialogContentText sx={{ paddingTop: "16px" }}>
          {decoded.isVendorOrSupplier
            ? "Enter Customer name"
            : "Enter Project name"}
        </DialogContentText>
        <TextField
          label={decoded.isVendorOrSupplier ? "Customer" : "Project"}
          value={projectName}
          onChange={handleProjectName}
        />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            magin: 0,
            padding: 0,
          }}
        >
          <Checkbox
            style={{
              magin: 0,
              padding: 0,
            }}
            checked={startNewOrder}
            onChange={(e) => {
              setStartNewOrder(e.target.checked);
            }}
          />
          <Typography>Start a New Quote Request</Typography>
        </div>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          loading={loading}
          onClick={() =>
            handleSubmitProject({
              name: projectName,
              isStartNewOrder: startNewOrder,
            })
          }
        >
          Submit
        </LoadingButton>
        <LoadingButton loading={loading} onClick={handleClose} autoFocus>
          Cancel
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
export default makePage(CreateProjectView, withError, withLoading);
