import { Edit, KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import {
  Box,
  Stack,
  TextField,
  Typography,
  InputAdornment,
  FormControl,
  InputLabel,
  Button,
  OutlinedInput,
  Divider,
  IconButton,
  Tooltip,
  TableRow,
  TableCell,
  Collapse,
  Table,
  TableHead,
  TableBody,
} from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const ColorListItem = ({ color }) => {
  let navigate = useNavigate();
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell>{color.name}</TableCell>
        <TableCell align="right">{color.paintManufacturer.name}</TableCell>
        <TableCell align="right">{color.status}</TableCell>
        <TableCell align="right">
          <Button
            onClick={() => {
              navigate(`/color-catalog/${color.id}`);
            }}
          >
            Edit
          </Button>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1, padding: 2 }}>
              <Typography gutterBottom>Flat Sheets</Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell align="right">Size</TableCell>
                    <TableCell align="right">Gauge</TableCell>
                    <TableCell align="right">Price</TableCell>
                    <TableCell align="right">Available</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {color.gauges.map((gauge) => {
                    return gauge.coilWidths.map((coilWidth) => {
                      if (coilWidth.price > 0)
                        return (
                          <TableRow key={color.id}>
                            <TableCell component="th" scope="row" align="right">
                              {coilWidth.width}
                            </TableCell>
                            <TableCell align="right">
                              {gauge.gauge} {gauge.unitOfMeasure}
                            </TableCell>
                            <TableCell align="right">
                              {coilWidth.price}
                            </TableCell>
                            <TableCell align="right">
                              {coilWidth.flatAvailable ? "YES" : "NO"}
                            </TableCell>
                          </TableRow>
                        );
                    });
                  })}
                </TableBody>
              </Table>
            </Box>
            <Box sx={{ margin: 1, padding: 1 }}>
              <Typography gutterBottom>Coil</Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell align="right">Size</TableCell>
                    <TableCell align="right">Gauge</TableCell>
                    <TableCell align="right">Price</TableCell>
                    <TableCell align="right">Available</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {color.gauges.map((gauge) => {
                    return gauge.coilWidths.map((coilWidth) => {
                      if (coilWidth.pricePerSqFt > 0)
                        return (
                          <TableRow key={color.id}>
                            <TableCell component="th" scope="row" align="right">
                              {coilWidth.width}
                            </TableCell>
                            <TableCell align="right">
                              {gauge.gauge} {gauge.unitOfMeasure}
                            </TableCell>
                            <TableCell align="right">
                              {coilWidth.pricePerSqFt}
                            </TableCell>
                            <TableCell align="right">
                              {coilWidth.coilAvailable ? "YES" : "NO"}
                            </TableCell>
                          </TableRow>
                        );
                    });
                  })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
export default ColorListItem;

/* 
{
    "name": "Sandstone",
    "manufacturer": "CMG",
    "image": "https://ezorder-public.s3.us-east-2.amazonaws.com/colors/Sandstone.png",
    "gauges": [
        {
          "value": 22, 
          "unitOfMeasure":"g", 
          "coilWidths": 
            [
              { 
                "value": 48, 
                "unitOfMeasure":"in.", 
                "price": 0 
              }
            ]
          },
        {
          "value": 24, 
          "unitOfMeasure":"g",
          "coilWidths": 
            [
              { 
                "value": 48, 
                "unitOfMeasure":"in.",
                "price": 0 
              }
            ]
        }
    ]
}
*/
