import {
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { useState } from "react";
import { useTheme } from "@emotion/react";
import { LoadingButton } from "@mui/lab";

const DeactivateCustomerDialog = ({
  open,
  handleClose,
  onConfirm,
  loading,
}) => {
  const theme = useTheme();
  const [errorMsg, setErrorMessage] = useState(null);
  const [confirmText, setConfirmText] = useState(null);

  return (
    <Dialog
      keepMounted
      scroll={"paper"}
      maxWidth="sm"
      fullWidth={true}
      PaperProps={{
        style: {
          background: theme.palette.secondary.main,
        },
      }}
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>Deactivate Company Account</DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <DialogContentText>
          Please confirm you want to deactivate this company account. Please
          enter "deactivate"
        </DialogContentText>
        <TextField
          sx={{ marginTop: "16px" }}
          variant="outlined"
          label="deactivate"
          name="deactivate"
          value={confirmText}
          onChange={(e) => {
            setConfirmText(e.target.value);
          }}
          size="large"
        />
      </DialogContent>
      <DialogActions>
        <LoadingButton
          onClick={() => {
            setConfirmText("");
            handleClose();
          }}
          loading={loading}
        >
          Cancel
        </LoadingButton>
        <LoadingButton
          variant="contained"
          loading={loading}
          disabled={confirmText != "deactivate"}
          onClick={onConfirm}
        >
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
export default DeactivateCustomerDialog;
