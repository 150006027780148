import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  TextField,
  Typography,
  Checkbox,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  InputAdornment,
  Tooltip,
  IconButton,
  MenuItem,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment/moment";
import useOrdersAdmin from "../../../hooks/useOrdersAdmin";
import { AuthContext } from "../../../context/AuthContext";
import SearchBar from "../../../components/SearchBar";
import ListFooter from "../../../components/InfiniteScroller/ListFooter";
import InfiniteScrollerTable from "../../../components/InfiniteScroller/InfiniteScrollerTable";
import OrderTableRow from "./OrderTableRow";
import CachedIcon from "@mui/icons-material/Cached";
import { useTheme } from "@emotion/react";
import { useParams } from "react-router-dom";
import {
  toTwoDecimals,
  toThreeDecimals,
} from "../../../components/NewDetail/calculations/utils";

const PurchaseOrderForm = ({ existingPurchaseOrder, onSubmit, disabled }) => {
  const theme = useTheme();
  const { orderId } = useParams();

  const [issueDate, setIssueDate] = useState(
    existingPurchaseOrder
      ? moment(existingPurchaseOrder.issueDate).toISOString()
      : null
  );
  const [deliveryType, setDeliveryType] = useState(
    existingPurchaseOrder ? existingPurchaseOrder.deliveryType : "Delivery"
  );
  const [shippingAddress, setShippingAddress] = useState(
    existingPurchaseOrder
      ? existingPurchaseOrder.shippingAddress
      : "1146 W. Hilton Ave., Phoenix, AZ 85007"
  );
  const [vendor, setVendor] = useState(
    existingPurchaseOrder ? existingPurchaseOrder.vendor : ""
  );
  const [contact, setContact] = useState(
    existingPurchaseOrder ? existingPurchaseOrder.contact : ""
  );
  const [materialReceiveLocation, setMaterialReceiveLocation] = useState(
    existingPurchaseOrder ? existingPurchaseOrder.materialReceiveLocation : ""
  );
  const [status, setStatus] = useState(
    existingPurchaseOrder ? existingPurchaseOrder.status : ""
  );
  const [dateReceived, setDateReceived] = useState(
    existingPurchaseOrder
      ? moment(existingPurchaseOrder.dateReceived).toISOString()
      : null
  );
  const [notes, setNotes] = useState(
    existingPurchaseOrder ? existingPurchaseOrder.notes : ""
  );

  // Painted Flat Sheets
  const [color, setColor] = useState(
    existingPurchaseOrder ? existingPurchaseOrder.color : ""
  );
  const [gauge, setGauge] = useState(
    existingPurchaseOrder ? existingPurchaseOrder.gauge : ""
  );
  const [flatSheetQuantity, setFlatSheetQuantity] = useState(
    existingPurchaseOrder ? existingPurchaseOrder.flatSheetQuantity : 0
  );
  const [flatSheetSizeInches, setFlatSheetSizeInches] = useState(
    existingPurchaseOrder ? existingPurchaseOrder.flatSheetSizeInches : 0
  );
  const [pricePerFlatSheet, setPricePerFlatSheet] = useState(
    existingPurchaseOrder ? existingPurchaseOrder.pricePerFlatSheet : 0
  );
  const [flatSheetTotalPrice, setFlatSheetTotalPrice] = useState(
    existingPurchaseOrder ? existingPurchaseOrder.flatSheetTotalPrice : 0
  );

  // Galvanized Flat Sheets
  const [galvanizedFlatSheetGauge, setGalvanizedFlatSheetGauge] = useState(
    existingPurchaseOrder ? existingPurchaseOrder.galvanizedFlatSheetGauge : ""
  );
  const [galvanizedFlatSheetQuantity, setGalvanizedFlatSheetQuantity] =
    useState(
      existingPurchaseOrder
        ? existingPurchaseOrder.galvanizedFlatSheetQuantity
        : 0
    );
  const [galvanizedFlatSheetSizeInches, setGalvanizedFlatSheetSizeInches] =
    useState(
      existingPurchaseOrder
        ? existingPurchaseOrder.galvanizedFlatSheetSizeInches
        : 0
    );
  const [pricePerGalvanizedFlatSheet, setPricePerGalvanizedFlatSheet] =
    useState(
      existingPurchaseOrder
        ? existingPurchaseOrder.pricePerGalvanizedFlatSheet
        : 0
    );
  const [galvanizedFlatSheetTotalPrice, setGalvanizedFlatSheetTotalPrice] =
    useState(
      existingPurchaseOrder
        ? existingPurchaseOrder.galvanizedFlatSheetTotalPrice
        : 0
    );

  // Coil (Panel)
  const [linealFeetOfCoil, setLinealFeetOfCoil] = useState(
    existingPurchaseOrder ? existingPurchaseOrder.linealFeetOfCoil : 0
  );
  const [squareFeetOfCoil, setSquareFeetOfCoil] = useState(
    existingPurchaseOrder ? existingPurchaseOrder.squareFeetOfCoil : 0
  );
  const [masterCoilWidthInches, setMasterCoilWidthInches] = useState(
    existingPurchaseOrder ? existingPurchaseOrder.masterCoilWidthInches : 0
  );
  const [coilWidthInches, setCoilWidthInches] = useState(
    existingPurchaseOrder ? existingPurchaseOrder.coilWidthInches : 0
  );
  const [pricePerSquareFeetOfCoil, setPricePerSquareFeetOfCoil] = useState(
    existingPurchaseOrder ? existingPurchaseOrder.pricePerSquareFeetOfCoil : 0
  );
  const [coilTotalPrice, setCoilTotalPrice] = useState(
    existingPurchaseOrder ? existingPurchaseOrder.coilTotalPrice : 0
  );

  // Coil Drop (Panel)
  const [coilDropWidthInches, setCoilDropWidthInches] = useState(
    existingPurchaseOrder ? existingPurchaseOrder.coilDropWidthInches : 0
  );
  const [linealFeetOfCoilDrop, setLinealFeetOfCoilDrop] = useState(
    existingPurchaseOrder ? existingPurchaseOrder.linealFeetOfCoilDrop : 0
  );
  const [squareFeetOfCoilDrop, setSquareFeetOfCoilDrop] = useState(
    existingPurchaseOrder ? existingPurchaseOrder.squareFeetOfCoilDrop : 0
  );
  const [coilDropTotalPrice, setCoilDropTotalPrice] = useState(
    existingPurchaseOrder ? existingPurchaseOrder.coilDropTotalPrice : 0
  );

  // Relations
  const [order, setOrder] = useState(
    existingPurchaseOrder ? existingPurchaseOrder.order : null
  );

  const [poData, setPoData] = useState({
    issueDate: existingPurchaseOrder
      ? moment(existingPurchaseOrder.issueDate).toISOString()
      : null,
    deliveryType: existingPurchaseOrder
      ? existingPurchaseOrder.deliveryType
      : "Delivery",
    shippingAddress: existingPurchaseOrder
      ? existingPurchaseOrder.shippingAddress
      : "1146 W. Hilton Ave., Phoenix, AZ 85007",
    vendor: existingPurchaseOrder ? existingPurchaseOrder.vendor : "",
    contact: existingPurchaseOrder ? existingPurchaseOrder.contact : "",
    materialReceiveLocation: existingPurchaseOrder
      ? existingPurchaseOrder.materialReceiveLocation
      : "",
    status: existingPurchaseOrder ? existingPurchaseOrder.status : "",
    dateReceived: existingPurchaseOrder
      ? moment(existingPurchaseOrder.dateReceived).toISOString()
      : null,
    notes: existingPurchaseOrder ? existingPurchaseOrder.notes : "",

    // Painted Flat Sheets
    color: existingPurchaseOrder ? existingPurchaseOrder.color : "",
    gauge: existingPurchaseOrder ? existingPurchaseOrder.gauge : "",
    flatSheetQuantity: existingPurchaseOrder
      ? existingPurchaseOrder.flatSheetQuantity
      : 0,
    flatSheetSizeInches: existingPurchaseOrder
      ? existingPurchaseOrder.flatSheetSizeInches
      : 0,
    pricePerFlatSheet: existingPurchaseOrder
      ? existingPurchaseOrder.pricePerFlatSheet
      : 0,
    flatSheetTotalPrice: existingPurchaseOrder
      ? existingPurchaseOrder.flatSheetTotalPrice
      : 0,

    // Galvanized Flat Sheets
    galvanizedFlatSheetGauge: existingPurchaseOrder
      ? existingPurchaseOrder.galvanizedFlatSheetGauge
      : "",
    galvanizedFlatSheetQuantity: existingPurchaseOrder
      ? existingPurchaseOrder.galvanizedFlatSheetQuantity
      : 0,
    galvanizedFlatSheetSizeInches: existingPurchaseOrder
      ? existingPurchaseOrder.galvanizedFlatSheetSizeInches
      : 0,

    pricePerGalvanizedFlatSheet: existingPurchaseOrder
      ? existingPurchaseOrder.pricePerGalvanizedFlatSheet
      : 0,
    galvanizedFlatSheetTotalPrice: existingPurchaseOrder
      ? existingPurchaseOrder.galvanizedFlatSheetTotalPrice
      : 0,

    // Coil (Panel)
    linealFeetOfCoil: existingPurchaseOrder
      ? existingPurchaseOrder.linealFeetOfCoil
      : 0,
    squareFeetOfCoil: existingPurchaseOrder
      ? existingPurchaseOrder.squareFeetOfCoil
      : 0,
    masterCoilWidthInches: existingPurchaseOrder
      ? existingPurchaseOrder.masterCoilWidthInches
      : 0,
    coilWidthInches: existingPurchaseOrder
      ? existingPurchaseOrder.coilWidthInches
      : 0,
    pricePerSquareFeetOfCoil: existingPurchaseOrder
      ? existingPurchaseOrder.pricePerSquareFeetOfCoil
      : 0,
    coilTotalPrice: existingPurchaseOrder
      ? existingPurchaseOrder.coilTotalPrice
      : 0,

    // Coil Drop (Panel)
    coilDropWidthInches: existingPurchaseOrder
      ? existingPurchaseOrder.coilDropWidthInches
      : 0,
    linealFeetOfCoilDrop: existingPurchaseOrder
      ? existingPurchaseOrder.linealFeetOfCoilDrop
      : 0,
    squareFeetOfCoilDrop: existingPurchaseOrder
      ? existingPurchaseOrder.squareFeetOfCoilDrop
      : 0,
    coilDropTotalPrice: existingPurchaseOrder
      ? existingPurchaseOrder.coilDropTotalPrice
      : 0,

    // Relations
    order: existingPurchaseOrder ? existingPurchaseOrder.order : null,
  });

  const { authToken, ezorder } = useContext(AuthContext);
  const [
    searchQuery,
    setSearchQuery,
    orders,
    onRefresh,
    refreshing,
    onLoadMore,
    loadingMore,
    total,
    hasMore,
  ] = useOrdersAdmin(authToken, 10);

  const getMaterialNeeded = (order) => {
    console.log("GET MATERIALS NEEDED", order);
    let gauge = `${order.gauge}${order.gauge < 1 ? "mm" : "g"}`;
    let color = order.color;
    let manufacturer = order.manufacturer;
    let flatSheetSize = parseFloat(order.flatSheetSize);
    let flatSheetCost = parseFloat(order.flatSheetCost);
    let galvFlatSheetCost = parseFloat(order.galvFlatSheetCost);

    if (order.isCustomMaterial) {
      gauge = order.customGauge;
      color = order.customColor;
      manufacturer = order.customManufacturer;
    }

    let coil = {
      dropLF: 0,
      dropSF: 0,
      coilDropTotalPrice: 0,
    };
    let flatsNeeded = {
      size: flatSheetSize ? flatSheetSize : 0,
      quantity: 0,
      pricePerFS: flatSheetCost ? flatSheetCost : 0,
    };
    let galvFlatsNeeded = {
      gauge: 0,
      size: flatSheetSize ? flatSheetSize : 0,
      quantity: 0,
      pricePerFS: galvFlatSheetCost ? galvFlatSheetCost : 0,
    };
    for (const orderItem of order.items) {
      switch (orderItem.objectType) {
        case "Panel":
          console.log("PANEL PANEL PANEL", orderItem);
          // COIL
          let panelCalcs = orderItem.calculations;
          coil = panelCalcs;
          coil.coilPricePerSF = parseFloat(order.coilCostPerSqFt);
          // DROP

          let totalCoilsYielded = Math.floor(
            panelCalcs.masterCoilSizeNeeded / panelCalcs.coilSizeNeeded
          ); // panels number fit on master coil width
          let dropLF =
            parseFloat(panelCalcs.coilDrop) > 0
              ? toTwoDecimals(panelCalcs.linealFeetOfCoilWithWaste) /
                totalCoilsYielded
              : 0;
          console.log("Calculating Coil Drop LF");
          console.log("COIL DROP", panelCalcs.coilDrop);
          console.log(
            `panelCalcs.masterCoilSizeNeeded`,
            panelCalcs.masterCoilSizeNeeded
          );
          console.log(`panelCalcs.coilSizeNeeded`, panelCalcs.coilSizeNeeded);
          console.log(
            "totalCoilsYielded = panelCalcs.masterCoilSizeNeeded / panelCalcs.coilSizeNeeded",
            totalCoilsYielded
          );
          console.log(
            `panelCalcs.linealFeetOfCoilWithWaste`,
            panelCalcs.linealFeetOfCoilWithWaste
          );
          console.log(`totalCoilsYielded`, totalCoilsYielded);
          console.log(
            "dropLF = panelCalcs.linealFeetOfCoilWithWaste) / totalCoilsYielded",
            dropLF
          );
          console.log("totalCoilsYielded", totalCoilsYielded);

          let dropSF = toThreeDecimals(dropLF * (panelCalcs.coilDrop / 12));
          let coilDropTotalPrice = toTwoDecimals(dropSF * coil.coilPricePerSF);
          coil.dropLF = dropLF;
          coil.dropSF = dropSF;
          coil.coilDropTotalPrice = coilDropTotalPrice;
          break;
        case "TrimAndFlashing":
          let trimPieces = orderItem.trimPieces;
          for (const trimPiece of trimPieces) {
            let flats = parseInt(trimPiece.calculations.flatSheetsNeeded);
            console.log("TrimAndFlashing Flats", flats);
            flatsNeeded.quantity += flats;
          }
          break;
        case "CopingCap":
          let capPieces = orderItem.capPieces;
          for (const capPiece of capPieces) {
            let flats = parseInt(capPiece.calculations.flatSheetsNeeded);
            console.log("CopingCap Flats", flats);
            flatsNeeded.quantity += flats;
          }
          break;
        case "CopingCapCleat":
          let cleatPieces = orderItem.cleatPieces;
          galvFlatsNeeded.gauge =
            orderItem.gauge + " " + orderItem.gaugeUnitOfMeasure;
          for (const cleatPiece of cleatPieces) {
            let flats = parseInt(cleatPiece.calculations.flatSheetsNeeded);
            console.log("CopingCapCleat GALV Flats", flats);
            galvFlatsNeeded.quantity += flats;
          }
          break;
        case "BenchWork":
          let benchWorkPieces = orderItem.benchWorkPieces;
          for (const benchWorkPiece of benchWorkPieces) {
            flatsNeeded.quantity += parseInt(benchWorkPiece.flatSheets);
          }
          break;
        case "SplicePlate":
          let plates = orderItem.plates;
          for (const plate of plates) {
            let flats = parseInt(plate.calculations.flatSheetsNeeded);
            console.log("SplicePlate Flats", flats);
            flatsNeeded.quantity += flats;
          }
          break;
        case "Accessory":
          for (const accessoryItem of orderItem.items) {
            if (accessoryItem.name.toLowerCase().includes("flat sheet")) {
              flatsNeeded.quantity += accessoryItem.quantity;
            }
          }
          break;
        default:
          break;
      }
    }
    let materials = {
      color,
      gauge,
      manufacturer,
      coil,
      flatsNeeded,
      galvFlatsNeeded,
    };
    console.log("MATERIALS NEEDED", materials);
    return materials;
  };

  const onOrderSelect = (order) => {
    console.log("SELECTED ORDER:", order);
    let materialsNeeded = getMaterialNeeded(order);

    // Extract Order Numbers
    // Painted Flat Sheets
    setColor(materialsNeeded.color);
    setGauge(materialsNeeded.gauge);
    setFlatSheetQuantity(materialsNeeded.flatsNeeded.quantity);
    setFlatSheetSizeInches(materialsNeeded.flatsNeeded.size);
    setPricePerFlatSheet(materialsNeeded.flatsNeeded.pricePerFS);
    setFlatSheetTotalPrice(
      materialsNeeded.flatsNeeded.quantity *
        materialsNeeded.flatsNeeded.pricePerFS
    );

    // Galvanized Flat Sheets
    setGalvanizedFlatSheetGauge(materialsNeeded.galvFlatsNeeded.gauge); // here
    setGalvanizedFlatSheetQuantity(materialsNeeded.galvFlatsNeeded.quantity);
    setGalvanizedFlatSheetSizeInches(materialsNeeded.galvFlatsNeeded.size);
    setPricePerGalvanizedFlatSheet(materialsNeeded.galvFlatsNeeded.pricePerFS);
    setGalvanizedFlatSheetTotalPrice(
      materialsNeeded.galvFlatsNeeded.quantity *
        materialsNeeded.galvFlatsNeeded.pricePerFS
    );

    // Coil (Panel)
    setLinealFeetOfCoil(materialsNeeded.coil.linealFeetOfCoilWithWaste);
    setSquareFeetOfCoil(materialsNeeded.coil.squareFeetOfCoilNeededWithWaste);
    setMasterCoilWidthInches(materialsNeeded.coil.masterCoilSizeNeeded);
    setCoilWidthInches(materialsNeeded.coil.coilSizeNeeded);
    setPricePerSquareFeetOfCoil(materialsNeeded.coil.coilPricePerSF);
    setCoilTotalPrice(materialsNeeded.coil.materialCost);

    // Coil Drop (Panel)
    setCoilDropWidthInches(materialsNeeded.coil.coilDrop);
    setLinealFeetOfCoilDrop(materialsNeeded.coil.dropLF);
    setSquareFeetOfCoilDrop(materialsNeeded.coil.dropSF);
    setCoilDropTotalPrice(materialsNeeded.coil.coilDropTotalPrice);

    // Set Order
    setOrder(order);
  };

  const loadPreSelectedOrder = async (orderId) => {
    const response = await ezorder
      .get(`/admin/orders/${orderId}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .catch((error) => {
        throw Error("Unable to load order", { cause: error });
      });
    onOrderSelect(response.data.order);
  };

  useEffect(() => {
    if (orderId) {
      loadPreSelectedOrder(orderId);
    }
  }, [orderId]);

  return (
    <Box>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Card style={{ margin: 8, padding: 8 }}>
          <Box style={{ padding: 16 }}>
            <Box>
              <Typography>PO Details</Typography>
              <Box sx={{ padding: "16px" }}>
                <Box>
                  {order ? (
                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                      <TextField
                        fullWidth
                        sx={{ marginTop: "16px" }}
                        InputProps={{
                          readOnly: true,
                          disableUnderline: true,
                          endAdornment: (
                            <InputAdornment position="end">
                              <Tooltip title="reset">
                                <IconButton
                                  disabled={disabled}
                                  onClick={() => {
                                    setOrder(null);
                                  }}
                                >
                                  <CachedIcon
                                    color="tertiary"
                                    sx={{ marginLeft: 0, marginRight: 0 }}
                                  />
                                </IconButton>
                              </Tooltip>
                            </InputAdornment>
                          ),
                        }}
                        label="For Industry Order - Order ID | PO Number | Status"
                        value={`${order.id} | ${order.poNumber} | ${
                          order.orderStatus && order.orderStatus.description
                            ? order.orderStatus.description
                            : ""
                        }`}
                      />
                      <Button
                        onClick={() => {
                          window.open(
                            `${window.location.origin}/orders/${order.id}`,
                            "_blank",
                            "noreferrer"
                          );
                        }}
                      >
                        View Order Details
                      </Button>
                    </Box>
                  ) : (
                    <Box
                      style={{
                        paddingLeft: 48,
                        paddingRight: 48,
                        paddingTop: 16,
                        paddingBottom: 16,
                      }}
                    >
                      <Typography>
                        Select Industry Order for this Vendor PO
                      </Typography>
                      <SearchBar
                        label="Search Orders"
                        searchQuery={searchQuery}
                        setSearchQuery={setSearchQuery}
                      />
                      <InfiniteScrollerTable
                        TableContainerComponent={Paper}
                        tableContainerStyle={{
                          maxHeight: "250px",
                          minHeight: "100px",
                        }}
                        tableStyle={{ minWidth: "600px", minHeight: "70px" }}
                        TableHead={() => (
                          <TableHead>
                            <TableRow>
                              <TableCell>Sales Rep</TableCell>
                              <TableCell>ID</TableCell>
                              <TableCell>PO #</TableCell>
                              <TableCell>Customer Name</TableCell>
                              <TableCell>Project</TableCell>
                              <TableCell>Status</TableCell>
                            </TableRow>
                          </TableHead>
                        )}
                        items={orders}
                        renderRow={(item, index) => {
                          return (
                            <OrderTableRow
                              key={index}
                              order={item}
                              onClick={() => {
                                onOrderSelect(item);
                              }}
                            />
                          );
                        }}
                        onRefresh={onRefresh}
                        onEndReached={onLoadMore}
                        loading={refreshing || loadingMore}
                        ListFooterComponent={() => {
                          return (
                            <ListFooter
                              hasMore={hasMore}
                              onClick={onLoadMore}
                              noMoreText={"End Reached"}
                            />
                          );
                        }}
                      />
                    </Box>
                  )}
                </Box>
                <DatePicker
                  disabled={disabled}
                  label="Issue Date"
                  value={issueDate}
                  onChange={(value, context) => {
                    setIssueDate(value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      disabled={disabled}
                      fullWidth
                      sx={{ marginTop: "16px" }}
                      {...params}
                    />
                  )}
                />

                <TextField
                  disabled={disabled}
                  select
                  fullWidth
                  sx={{ marginTop: "16px" }}
                  label="Delivery Type"
                  value={deliveryType}
                  onChange={(e) => {
                    setDeliveryType(e.target.value);
                  }}
                >
                  <MenuItem value={"Delivery"}>Delivery</MenuItem>
                  <MenuItem value={"Pickup"}>Pickup</MenuItem>
                </TextField>

                <TextField
                  disabled={disabled}
                  sx={{ marginTop: "16px" }}
                  fullWidth
                  label="Ship To"
                  value={shippingAddress}
                  onChange={(e) => {
                    setShippingAddress(e.target.value);
                  }}
                />

                <TextField
                  disabled={disabled}
                  sx={{ marginTop: "16px" }}
                  fullWidth
                  label="Vendor"
                  value={vendor}
                  onChange={(e) => {
                    setVendor(e.target.value);
                  }}
                />
                <TextField
                  disabled={disabled}
                  sx={{ marginTop: "16px" }}
                  fullWidth
                  label="Contact"
                  value={contact}
                  onChange={(e) => {
                    setContact(e.target.value);
                  }}
                />

                {/* <TextField
                  disabled={disabled}
                  select
                  fullWidth
                  sx={{ marginTop: "16px" }}
                  label="Material Received"
                  value={materialReceiveLocation}
                  onChange={(e) => {
                    setMaterialReceiveLocation(e.target.value);
                  }}
                >
                  <MenuItem value={""}>Please Select</MenuItem>
                  <MenuItem value={"SENT_TO_VENDOR"}>Sent to Vendor</MenuItem>
                  <MenuItem value={"IN_SHOP"}>In Shop</MenuItem>
                </TextField>

                <TextField
                  disabled={disabled}
                  select
                  fullWidth
                  sx={{ marginTop: "16px" }}
                  label="PO Status"
                  value={status}
                  onChange={(e) => {
                    setStatus(e.target.value);
                  }}
                >
                  <MenuItem value={""}>Please Select</MenuItem>
                  <MenuItem value={"OPEN"}>Open - Nothing Rec'd</MenuItem>
                  <MenuItem value={"OPEN_PARTIALLY_RECEIVED"}>
                    Open - Partially Rec'd
                  </MenuItem>
                  <MenuItem value={"RECEIVED"}>Rec'd Full</MenuItem>
                </TextField>

                <DatePicker
                  disabled={disabled}
                  label="Rec'd Date"
                  value={dateReceived}
                  onChange={(value, context) => {
                    setDateReceived(value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      disabled={disabled}
                      fullWidth
                      sx={{ marginTop: "16px" }}
                      {...params}
                    />
                  )}
                /> */}

                <TextField
                  disabled={disabled}
                  sx={{ marginTop: "16px" }}
                  fullWidth
                  multiline
                  rows={4}
                  label="Notes (Not visible in PO PDF)"
                  placeholder="Notes"
                  value={notes}
                  onChange={(e) => {
                    setNotes(e.target.value);
                  }}
                />
              </Box>
            </Box>

            <Box>
              <Typography>Painted Flat Sheets</Typography>
              <Box sx={{ padding: "16px" }}>
                <TextField
                  disabled={disabled}
                  sx={{ marginTop: "16px" }}
                  fullWidth
                  label="Color"
                  value={color}
                  onChange={(e) => {
                    setColor(e.target.value);
                  }}
                />

                <TextField
                  disabled={disabled}
                  sx={{ marginTop: "16px" }}
                  fullWidth
                  label="Gauge"
                  value={gauge}
                  onChange={(e) => {
                    setGauge(e.target.value);
                  }}
                />

                <TextField
                  disabled={disabled}
                  sx={{ marginTop: "16px" }}
                  fullWidth
                  label="FS Qty (pc)"
                  type="number"
                  onWheel={(event) => {
                    event.preventDefault();
                  }}
                  onFocus={(e) => e.target.select()}
                  value={flatSheetQuantity}
                  onChange={(e) => {
                    setFlatSheetQuantity(e.target.value);
                  }}
                />

                <TextField
                  disabled={disabled}
                  sx={{ marginTop: "16px" }}
                  fullWidth
                  label="FS Size (in)"
                  type="number"
                  onWheel={(event) => {
                    event.preventDefault();
                  }}
                  onFocus={(e) => e.target.select()}
                  value={flatSheetSizeInches}
                  onChange={(e) => {
                    setFlatSheetSizeInches(e.target.value);
                  }}
                />

                <TextField
                  disabled={disabled}
                  sx={{ marginTop: "16px" }}
                  fullWidth
                  label="Price Per Flat Sheet"
                  type="number"
                  onWheel={(event) => {
                    event.preventDefault();
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Typography
                          style={{ color: theme.palette.grey["500"] }}
                        >
                          $
                        </Typography>
                      </InputAdornment>
                    ),
                  }}
                  onFocus={(e) => e.target.select()}
                  value={pricePerFlatSheet}
                  onChange={(e) => {
                    setPricePerFlatSheet(e.target.value);
                  }}
                />

                <TextField
                  disabled={disabled}
                  sx={{ marginTop: "16px" }}
                  fullWidth
                  label="Total Flat Sheet Price"
                  type="number"
                  onWheel={(event) => {
                    event.preventDefault();
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Typography
                          style={{ color: theme.palette.grey["500"] }}
                        >
                          $
                        </Typography>
                      </InputAdornment>
                    ),
                  }}
                  onFocus={(e) => e.target.select()}
                  value={flatSheetTotalPrice}
                  onChange={(e) => {
                    setFlatSheetTotalPrice(e.target.value);
                  }}
                />
              </Box>
            </Box>
            <Box>
              <Typography>Galvanized Flat Sheets</Typography>
              <Box sx={{ padding: "16px" }}>
                <TextField
                  disabled={disabled}
                  sx={{ marginTop: "16px" }}
                  fullWidth
                  label="Galvanized FS Gauge"
                  value={galvanizedFlatSheetGauge}
                  onChange={(e) => {
                    setGalvanizedFlatSheetGauge(e.target.value);
                  }}
                />
                <TextField
                  disabled={disabled}
                  sx={{ marginTop: "16px" }}
                  fullWidth
                  label="Galvanized FS Qty (pc)"
                  type="number"
                  onWheel={(event) => {
                    event.preventDefault();
                  }}
                  onFocus={(e) => e.target.select()}
                  value={galvanizedFlatSheetQuantity}
                  onChange={(e) => {
                    setGalvanizedFlatSheetQuantity(e.target.value);
                  }}
                />

                <TextField
                  disabled={disabled}
                  sx={{ marginTop: "16px" }}
                  fullWidth
                  label="Galvanized FS Size (in)"
                  type="number"
                  onWheel={(event) => {
                    event.preventDefault();
                  }}
                  onFocus={(e) => e.target.select()}
                  value={galvanizedFlatSheetSizeInches}
                  onChange={(e) => {
                    setGalvanizedFlatSheetSizeInches(e.target.value);
                  }}
                />

                <TextField
                  disabled={disabled}
                  sx={{ marginTop: "16px" }}
                  fullWidth
                  label="Price Per Galvanized Flat Sheet"
                  type="number"
                  onWheel={(event) => {
                    event.preventDefault();
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Typography
                          style={{ color: theme.palette.grey["500"] }}
                        >
                          $
                        </Typography>
                      </InputAdornment>
                    ),
                  }}
                  onFocus={(e) => e.target.select()}
                  value={pricePerGalvanizedFlatSheet}
                  onChange={(e) => {
                    setPricePerGalvanizedFlatSheet(e.target.value);
                  }}
                />

                <TextField
                  disabled={disabled}
                  sx={{ marginTop: "16px" }}
                  fullWidth
                  label="Total Galvanized Flat Sheet Price"
                  type="number"
                  onWheel={(event) => {
                    event.preventDefault();
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Typography
                          style={{ color: theme.palette.grey["500"] }}
                        >
                          $
                        </Typography>
                      </InputAdornment>
                    ),
                  }}
                  onFocus={(e) => e.target.select()}
                  value={galvanizedFlatSheetTotalPrice}
                  onChange={(e) => {
                    setGalvanizedFlatSheetTotalPrice(e.target.value);
                  }}
                />
              </Box>
            </Box>
            <Box>
              <Typography>Coil</Typography>
              <Box sx={{ padding: "16px" }}>
                <TextField
                  disabled={disabled}
                  sx={{ marginTop: "16px" }}
                  fullWidth
                  label="Coil Qty (lf)"
                  type="number"
                  onWheel={(event) => {
                    event.preventDefault();
                  }}
                  onFocus={(e) => e.target.select()}
                  value={linealFeetOfCoil}
                  onChange={(e) => {
                    setLinealFeetOfCoil(e.target.value);
                  }}
                />

                <TextField
                  disabled={disabled}
                  sx={{ marginTop: "16px" }}
                  fullWidth
                  label="Coil Qty (SF)"
                  type="number"
                  onWheel={(event) => {
                    event.preventDefault();
                  }}
                  onFocus={(e) => e.target.select()}
                  value={squareFeetOfCoil}
                  onChange={(e) => {
                    setSquareFeetOfCoil(e.target.value);
                  }}
                />
                <TextField
                  disabled={disabled}
                  sx={{ marginTop: "16px" }}
                  fullWidth
                  label="Master Coil Size (in)"
                  type="number"
                  onWheel={(event) => {
                    event.preventDefault();
                  }}
                  onFocus={(e) => e.target.select()}
                  value={masterCoilWidthInches}
                  onChange={(e) => {
                    setMasterCoilWidthInches(e.target.value);
                  }}
                />
                <TextField
                  disabled={disabled}
                  sx={{ marginTop: "16px" }}
                  fullWidth
                  label="Coil Width (in)"
                  type="number"
                  onWheel={(event) => {
                    event.preventDefault();
                  }}
                  onFocus={(e) => e.target.select()}
                  value={coilWidthInches}
                  onChange={(e) => {
                    setCoilWidthInches(e.target.value);
                  }}
                />

                <TextField
                  disabled={disabled}
                  sx={{ marginTop: "16px" }}
                  fullWidth
                  label="Price Per SF of Coil"
                  type="number"
                  onWheel={(event) => {
                    event.preventDefault();
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Typography
                          style={{ color: theme.palette.grey["500"] }}
                        >
                          $
                        </Typography>
                      </InputAdornment>
                    ),
                  }}
                  onFocus={(e) => e.target.select()}
                  value={pricePerSquareFeetOfCoil}
                  onChange={(e) => {
                    setPricePerSquareFeetOfCoil(e.target.value);
                  }}
                />

                <TextField
                  disabled={disabled}
                  sx={{ marginTop: "16px" }}
                  fullWidth
                  label="Total Coil Price"
                  type="number"
                  onWheel={(event) => {
                    event.preventDefault();
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Typography
                          style={{ color: theme.palette.grey["500"] }}
                        >
                          $
                        </Typography>
                      </InputAdornment>
                    ),
                  }}
                  onFocus={(e) => e.target.select()}
                  value={coilTotalPrice}
                  onChange={(e) => {
                    setCoilTotalPrice(e.target.value);
                  }}
                />
              </Box>
            </Box>
            <Box>
              <Typography>Coil Drop</Typography>
              <Box sx={{ padding: "16px" }}>
                <TextField
                  disabled={disabled}
                  sx={{ marginTop: "16px" }}
                  fullWidth
                  label="Coil Drop Size (in)"
                  type="number"
                  onWheel={(event) => {
                    event.preventDefault();
                  }}
                  onFocus={(e) => e.target.select()}
                  value={coilDropWidthInches}
                  onChange={(e) => {
                    setCoilDropWidthInches(e.target.value);
                  }}
                />
                <TextField
                  disabled={disabled}
                  sx={{ marginTop: "16px" }}
                  fullWidth
                  label="Coil Drop Qty (LF)"
                  type="number"
                  onWheel={(event) => {
                    event.preventDefault();
                  }}
                  onFocus={(e) => e.target.select()}
                  value={linealFeetOfCoilDrop}
                  onChange={(e) => {
                    setLinealFeetOfCoilDrop(e.target.value);
                  }}
                />
                <TextField
                  disabled={disabled}
                  sx={{ marginTop: "16px" }}
                  fullWidth
                  label="Coil Drop Qty (SF)"
                  type="number"
                  onWheel={(event) => {
                    event.preventDefault();
                  }}
                  onFocus={(e) => e.target.select()}
                  value={squareFeetOfCoilDrop}
                  onChange={(e) => {
                    setSquareFeetOfCoilDrop(e.target.value);
                  }}
                />
                <TextField
                  disabled={disabled}
                  sx={{ marginTop: "16px" }}
                  fullWidth
                  label="Total Coil Drop Price"
                  type="number"
                  onWheel={(event) => {
                    event.preventDefault();
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Typography
                          style={{ color: theme.palette.grey["500"] }}
                        >
                          $
                        </Typography>
                      </InputAdornment>
                    ),
                  }}
                  onFocus={(e) => e.target.select()}
                  value={coilDropTotalPrice}
                  onChange={(e) => {
                    setCoilDropTotalPrice(e.target.value);
                  }}
                />
              </Box>
            </Box>

            <Box>
              <Box
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  disabled={disabled}
                  onClick={() => {
                    let update = {
                      issueDate,
                      deliveryType,
                      shippingAddress,
                      vendor,
                      contact,
                      materialReceiveLocation,
                      status,
                      dateReceived,
                      notes,
                      // Painted Flat Sheets
                      color,
                      gauge,
                      flatSheetQuantity,
                      flatSheetSizeInches,
                      pricePerFlatSheet,
                      flatSheetTotalPrice,

                      // Galvanized Flat Sheets
                      galvanizedFlatSheetGauge,
                      galvanizedFlatSheetQuantity,
                      galvanizedFlatSheetSizeInches,
                      pricePerGalvanizedFlatSheet,
                      galvanizedFlatSheetTotalPrice,

                      // Coil (Panel)
                      linealFeetOfCoil,
                      squareFeetOfCoil,
                      masterCoilWidthInches,
                      coilWidthInches,
                      pricePerSquareFeetOfCoil,
                      coilTotalPrice,

                      // Coil Drop (Panel)
                      coilDropWidthInches,
                      linealFeetOfCoilDrop,
                      squareFeetOfCoilDrop,
                      coilDropTotalPrice,

                      // Relationships
                      order,
                    };
                    if (existingPurchaseOrder) {
                      let poUpdate = {
                        ...existingPurchaseOrder,
                        ...update,
                      };
                      onSubmit(poUpdate);
                    } else {
                      onSubmit(update);
                    }
                  }}
                >
                  Submit
                </Button>
              </Box>
            </Box>
          </Box>
        </Card>
      </LocalizationProvider>
    </Box>
  );
};

export default PurchaseOrderForm;
