import ezorder from "../api/ezorderNoAuth";

const estimatePdfDownloadHandler = async (
  estimateS3Key,
  authToken,
  isNoAuth,
  noAuthAccessToken,
  fileName
) => {
  let presignedUrl;
  if (isNoAuth) {
    presignedUrl = await createPresignedUrlWithNoAuthAccessToken(
      estimateS3Key,
      noAuthAccessToken
    );
  } else {
    presignedUrl = await createPresignedUrl(estimateS3Key, authToken);
  }

  // using Java Script method to get PDF file
  fetch(presignedUrl).then((response) => {
    response.blob().then((blob) => {
      console.log("BLOB", blob);
      // Creating new object of PDF file
      const fileURL = window.URL.createObjectURL(blob);
      // Setting various property values
      let alink = document.createElement("a");
      alink.href = fileURL;
      alink.download = fileName ? fileName : "file.pdf";
      alink.click();
    });
  });
};

const createPresignedUrl = async (s3Key, authToken) => {
  try {
    let data = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };

    let keySplit = s3Key.split("/");
    let identifier = keySplit[0];
    let resource = keySplit[1];
    let fileName = keySplit[2];
    const response = await ezorder.post(
      "/files/presignedUrl",
      {
        identifier,
        resource,
        fileName,
        actionType: "READ",
        contentType: "application/pdf",
      },
      data
    );
    return response.data.getUrl;
  } catch (error) {
    // console.log(error);
    throw Error("Unable to load presigned URL", { cause: error });
  }
};

const createPresignedUrlWithNoAuthAccessToken = async (
  s3Key,
  noAuthAccessToken
) => {
  try {
    console.log(
      "Creating Presigned URL for S3 Key",
      s3Key,
      " with NoAuthAccessToken",
      noAuthAccessToken
    );
    let keySplit = s3Key.split("/");
    let identifier = keySplit[0];
    let resource = keySplit[1];
    let fileName = keySplit[2];
    const response = await ezorder.post("/no-auth/files/presignedUrl", {
      identifier,
      resource,
      fileName,
      actionType: "READ",
      contentType: "application/pdf",
      token: noAuthAccessToken,
    });
    console.log("S3", response.data);
    return response.data.getUrl;
  } catch (error) {
    // console.log(error);
    throw Error("Unable to load presigned URL", { cause: error });
  }
};

export default estimatePdfDownloadHandler;
