import ReactToPrint from "react-to-print";
import QRCode from "react-qr-code";
import React, { useRef, useState } from "react";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";

const PrintableQRCode = ({
  url,
  jobId,
  customerName,
  jobName,
  displaySize,
}) => {
  const componentRef = useRef();
  const buttonRef = useRef();
  const [count, setCount] = useState(8);

  let countArray = [];
  let i;
  for (i = 0; i < count; i++) {
    countArray.push("another one");
  }

  const qrCodeClick = () => {
    const button = buttonRef.current;
    button.click();
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      {/* QR Code Displayed to user */}
      {/* <button onClick={getShortUrl}>SHORT</button> */}
      {/* <TextField
        sx={{ maxWidth: 120 }}
        label="Qty to Print"
        size="small"
        type="number"
        value={count}
        onFocus={(e) => e.target.select()}
        onChange={(e) => {
          let val = e.target.value;
          if (val < 1) val = 1;
          setCount(e.target.value);
        }}
      /> */}
      <Box
        style={{
          background: "white",
          width: "120px", // Same as Display Size
          height: "120px", // Same as Display Size
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <QRCode
          bgColor="#FFFFFF"
          fgColor="#000000"
          level="L" //'L' 'M' 'Q' 'H'
          size={displaySize} // display size
          // title={subtext}
          value={url}
          onClick={qrCodeClick}
          style={{ cursor: "pointer" }}
        />
      </Box>
      <div style={{ overflow: "hidden", height: 0, width: 0 }}>
        <ReactToPrint
          // pageStyle="@page { size: 2.5in 4in }"
          trigger={() => {
            return (
              <Button
                ref={buttonRef}
                sx={{ display: "none" }}
                variant="contained"
              >
                Print All
              </Button>
            );
          }}
          content={() => componentRef.current}
        />
        {/*PRINT WHAT IS INSIDE THIS DIV */}
        <div
          ref={componentRef}
          style={{
            backgroundColor: "#fff",
            color: "#000",
            position: "relative",
          }}
        >
          <Box
            sx={{
              border: 1,
              // padding: "16px",
              // height: "220",
              // width: "352px",
              padding: "16px",
              border: 1,
              height: "235px",
              width: "340px",
              breakInside: "avoid",
              breakBefore: "always",
              breakAfter: "always",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                variant="overline"
                sx={{ color: "black", lineHeight: "8px", fontSize: "8px" }}
              >
                Customer
              </Typography>
              <Typography
                variant="h5"
                style={{
                  flex: 1,
                  color: "black",
                  display: "-webkit-box",
                  overflow: "hidden",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 2,
                }}
              >
                {customerName}
              </Typography>
            </Box>
            <Box
              sx={{
                flex: 1,
                marginTop: "4px",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
                <Typography
                  variant="overline"
                  sx={{
                    color: "black",
                    lineHeight: "8px",
                    fontSize: "8px",
                    marginTop: "8px",
                  }}
                >
                  Job ID
                </Typography>
                <Typography sx={{ color: "black" }} variant="h5" noWrap={true}>
                  #{jobId}
                </Typography>

                <Typography
                  variant="overline"
                  sx={{
                    color: "black",
                    lineHeight: "8px",
                    fontSize: "8px",
                    marginTop: "8px",
                  }}
                >
                  Job Name
                </Typography>
                <Typography
                  variant="h5"
                  style={{
                    flex: 1,
                    color: "black",
                    display: "-webkit-box",
                    overflow: "hidden",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 3,
                  }}
                >
                  {jobName}
                </Typography>
              </Box>
              <Box
                sx={{
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <QRCode
                  bgColor="#FFFFFF"
                  fgColor="#000000"
                  level="L" //'L' 'M' 'Q' 'H'
                  size={120} // print size
                  // title={subtext}
                  value={url}
                />
              </Box>
            </Box>
          </Box>
        </div>
      </div>
    </Box>
  );
};

export default PrintableQRCode;
