import {
  valueIfNotOverridden,
  combineVariablesAndOverrides,
  toTwoDecimals,
  toThreeDecimals,
  toFourDecimals,
} from "./utils";

const calculateFlatSheet = (
  flatSheet = {},
  variables = {},
  overrides = {},
  state = {}
) => {
  const $variables = combineVariablesAndOverrides(variables, overrides ?? {});
  const { gross_profit_margin: GROSS_PROFIT_MARGIN } = $variables;

  // Flat sheet cost for the whole order
  let FLAT_SHEET_COST =
    state.flatSheetCost != state.flatSheetCostOriginal
      ? state.flatSheetCost
      : state.flatSheetCostOriginal;

  // If override exists, return override value instead of calculated
  const $ = valueIfNotOverridden(overrides ?? {});

  const COST_PER_FLAT_SHEET = $(
    "cost_per_piece", // override key
    toTwoDecimals(FLAT_SHEET_COST) // non-override value
  );

  const MATERIAL_COST = $(
    "material_cost",
    toTwoDecimals(parseFloat(flatSheet.quantity) * COST_PER_FLAT_SHEET)
  );

  const GROSS_PROFIT_DOLLARS = $(
    "gross_profit_dollars",
    MATERIAL_COST / (1 - GROSS_PROFIT_MARGIN) - MATERIAL_COST
  );

  const TOTAL_SELL_PRICE = $(
    "total_sell_price",
    MATERIAL_COST + GROSS_PROFIT_DOLLARS
  );

  const PRICE_PER_FLAT_SHEET = $(
    "price_per_piece", // override key
    toTwoDecimals(TOTAL_SELL_PRICE / flatSheet.quantity) // non-override value
  );

  let FLAT_SHEET_CALC = {
    costPerFlatSheet: COST_PER_FLAT_SHEET,
    materialCost: MATERIAL_COST,
    grossProfitMargin: toFourDecimals(GROSS_PROFIT_MARGIN),
    grossProfitDollars: toTwoDecimals(GROSS_PROFIT_DOLLARS),
    totalSellPrice: toTwoDecimals(TOTAL_SELL_PRICE),
    pricePerFlatSheet: PRICE_PER_FLAT_SHEET,
  };

  return FLAT_SHEET_CALC;
};

export default calculateFlatSheet;
