import { useState, useEffect, useContext } from "react";
import { AuthContext } from "../context/AuthContext";

const useNotifications = (authToken) => {
  const { ezorder } = useContext(AuthContext);
  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);

  // Pagination
  const [refreshing, setRefreshing] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [hasMore, setHasMore] = useState(false);

  const getNotifications = async (controller) => {
    try {
      if (pageNumber == 0) setRefreshing(true);
      else {
        setLoadingMore(true);
      }

      let data = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      if (controller) {
        data.signal = controller.signal;
      }

      const res = await ezorder.get(
        `/notifications/messages?pageNumber=${pageNumber}&pageSize=${pageSize}`,
        data
      );
      // console.log("Search Page", pageNumber);
      // console.log("Page", res.data.page);

      // If first page, set results as current data
      // If beyond first page, add result to current data
      if (pageNumber === 0) {
        setNotifications(res.data.page.results);
        setUnreadCount(res.data.page.unreadCount);
      } else {
        setNotifications((prev) => [...prev, ...res.data.page.results]);
        setUnreadCount(res.data.page.unreadCount);
      }
      setTotal(res.data.page.total);
      setPages(res.data.page.pages);
    } catch (error) {
      console.log(error);
    } finally {
      setRefreshing(false);
      setLoadingMore(false);
    }
  };

  const updateMessageToRead = async (messageId) => {
    try {
      const response = await ezorder.patch(
        `/notifications/messages/${messageId}`,
        {
          // empty body
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      console.log("Messaged Marked as Read", response.data);
    } catch (error) {
      //setError(error);
    } finally {
      onRefresh();
    }
  };

  // Find the next Page Number based on the total length of the current data and the page size
  // If messages.length = 10
  // And pageSize = 10
  // then messages.length/pageSize = 1
  const onLoadMore = () => {
    let nextPage;
    if (notifications.length > 0) {
      nextPage = Math.ceil(notifications.length / pageSize);
    } else {
      nextPage = 0;
    }
    setPageNumber(nextPage);
  };

  const onRefresh = () => {
    if (pageNumber == 0) {
      getNotifications();
    } else {
      setPageNumber(0);
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    if (pageNumber != undefined && pageSize != undefined)
      getNotifications(controller);

    return () => {
      controller.abort(); // abort api call on unmount for cleanup
    };
  }, [pageNumber, pageSize, unreadCount]);

  useEffect(() => {
    setHasMore(notifications.length != total);
  }, [notifications, total]);

  return [
    unreadCount,
    setUnreadCount,
    notifications,
    getNotifications,
    onLoadMore,
    onRefresh,
    refreshing,
    loadingMore,
    hasMore,
    updateMessageToRead,
  ];
};

export default useNotifications;
