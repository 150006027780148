import {
  Box,
  TextField,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  OutlinedInput,
  Typography,
  Card,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { useFormik } from "formik";
import * as yup from "yup";
import { useState } from "react";
import { Edit } from "@mui/icons-material";

const ChangePasswordDialog = ({ open, handleClose, onSubmit }) => {
  const updatePasswordValidationSchema = yup.object().shape({
    currentPassword: yup
      .string()
      .required("Password Required")
      .max(
        20,
        "Password Must Contain between 8-20 Characters, One Uppercase, One Lowercase, One Number and One Special Character"
      )
      .min(
        8,
        "Password Must Contain between 8-20 Characters, One Uppercase, One Lowercase, One Number and One Special Character"
      ),
    newPassword: yup
      .string()
      .required("New Password Required")
      .max(
        20,
        "Password Must Contain between 8-20 Characters, One Uppercase, One Lowercase, One Number and One Special Character"
      )
      .min(
        8,
        "Password Must Contain between 8-20 Characters, One Uppercase, One Lowercase, One Number and One Special Character"
      ),
  });
  const formikProps = useFormik({
    initialValues: { currentPassword: "", newPassword: "" },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: updatePasswordValidationSchema,
    onSubmit: (values) => {
      onSubmit(values.currentPassword, values.newPassword);
    },
  });

  return (
    <Dialog
      fullScreen={false}
      fullWidth={true}
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>Change Password</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          type="password"
          label="Current Password"
          value={formikProps.values.currentPassword}
          onChange={formikProps.handleChange("currentPassword")}
        />
        {formikProps.errors.currentPassword && (
          <Box>{formikProps.errors.currentPassword}</Box>
        )}
        <TextField
          sx={{ marginTop: "16px" }}
          fullWidth
          type="password"
          label="New Password"
          value={formikProps.values.newPassword}
          onChange={formikProps.handleChange("newPassword")}
        />
        {formikProps.errors.newPassword && (
          <Box>{formikProps.errors.newPassword}</Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          onClick={() => {
            formikProps.handleSubmit();
          }}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ChangePasswordDialog;
