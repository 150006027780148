import React, { useContext, useEffect, useState } from "react";
import usePageName from "../../../hocs/usePageName";
import {
  Box,
  Button,
  Card,
  TextField,
  Typography,
  Checkbox,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { TimePicker, DatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment/moment";
import { LoadingButton } from "@mui/lab";

const TimeCardForm = ({ existingTimeCard, onSubmit, loading }) => {
  const [date, setDate] = useState(
    existingTimeCard
      ? moment(existingTimeCard.clockIn).toISOString()
      : moment().toISOString()
  );
  const [timeIn, setTimeIn] = useState(
    existingTimeCard
      ? moment(existingTimeCard.clockIn).toISOString()
      : moment(new Date().setHours(7, 0, 0, 0)).toISOString()
  );
  const [timeOut, setTimeOut] = useState(
    existingTimeCard
      ? moment(existingTimeCard.clockOut).toISOString()
      : moment(new Date().setHours(15, 0, 0, 0)).toISOString()
  );
  const [isNoWork, setIsNoWork] = useState(
    existingTimeCard ? existingTimeCard.noWork : false
  );
  const [notes, setNotes] = useState("");

  const onDateChange = (selectedDate) => {
    console.log("onDateChange", selectedDate);
    setDate(selectedDate);

    var copiedTimeIn = new Date(selectedDate);
    copiedTimeIn.setHours(7, 0, 0, 0); // 7 AM
    setTimeIn(moment(copiedTimeIn).toISOString());

    var copiedTimeOut = new Date(selectedDate);
    copiedTimeOut.setHours(15, 0, 0, 0); // 3 PM
    setTimeOut(moment(copiedTimeOut).toISOString());
  };

  const onTimeInChange = (selectedDate) => {
    console.log("onTimeInChange", selectedDate);
    setTimeIn(selectedDate);
  };

  const onTimeOutChange = (selectedDate) => {
    console.log("onTimeInChange", selectedDate);
    // if (event.type !== "dismissed") {
    //   setTimeOut(selectedDate);
    // }
    setTimeOut(selectedDate);
  };

  return (
    <Box>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Card style={{ margin: 8, padding: 8 }}>
          <Box style={{ padding: 16 }}>
            <Box style={{ display: "flex", flexDirection: "column" }}>
              <DatePicker
                label="Date"
                value={date}
                onChange={(value, context) => {
                  //handleDate(newValue);
                  onDateChange(value, context);
                }}
                renderInput={(params) => (
                  <TextField sx={{ mb: "10px" }} {...params} />
                )}
              />
              {!isNoWork && (
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <TimePicker
                    label="Clock-In"
                    value={timeIn}
                    onChange={(value) => {
                      console.log("onChange");
                      onTimeInChange(value);
                    }}
                    renderInput={(params) => (
                      <TextField sx={{ mb: "10px" }} {...params} />
                    )}
                  />
                  <TimePicker
                    label="Clock-Out"
                    sx={{ border: "1px solid red" }}
                    value={moment(timeOut)}
                    onChange={(value) => {
                      onTimeOutChange(value);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Box>
              )}
            </Box>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                magin: 0,
                padding: 0,
              }}
            >
              <Checkbox
                style={{
                  margin: 0,
                  padding: 0,
                }}
                checked={isNoWork}
                onChange={(e) => {
                  setIsNoWork(e.target.checked);
                  setTimeIn(new Date(new Date(date).setHours(7, 0, 0, 0)));
                  setTimeOut(null);
                }}
              />
              <Typography>No Work?</Typography>
            </Box>

            {!existingTimeCard && (
              <TextField
                mode="outlined"
                sx={{ marginTop: "8px" }}
                fullWidth
                multiline
                rows={4}
                label="Notes"
                placeholder="Notes"
                value={notes}
                onChange={(e) => {
                  setNotes(e.target.value);
                }}
              />
            )}
            <Box>
              <Box
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                }}
              >
                <LoadingButton
                  loading={loading}
                  onClick={() => {
                    if (existingTimeCard) {
                      let existingTimeCardUpdate = {
                        id: existingTimeCard.id,
                        clockIn: timeIn,
                        clockOut: isNoWork ? null : timeOut,
                        noWork: isNoWork,
                      };
                      console.log("UPDATE TIME CARD", existingTimeCardUpdate);
                      onSubmit(existingTimeCardUpdate);
                    } else {
                      let newTimecard = {
                        clockIn: timeIn,
                        clockOut: isNoWork ? null : timeOut,
                        noWork: isNoWork,
                        notes: notes,
                      };
                      console.log("CREATE NEW TIEM CARD", newTimecard);
                      onSubmit(newTimecard);
                    }
                  }}
                >
                  Submit
                </LoadingButton>
              </Box>
            </Box>
          </Box>
        </Card>
      </LocalizationProvider>
    </Box>
  );
};

export default TimeCardForm;
