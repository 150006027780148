import {
  Box,
  TextField,
  Button,
  MenuItem,
  Avatar,
  Typography,
  Modal,
  Card,
  Checkbox,
  useMediaQuery,
  Select,
  InputLabel,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useTheme } from "@emotion/react";
import useAllColors from "../../hooks/useAllColors";
import AdornedInput from "../AdornedInput";

const ColorSelect = ({
  materialDetails,
  setMaterialDetails,
  isCustomMaterial,
  isCustomerSupplied,
  toggleCustomerSupplied,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { colors } = useAllColors();
  const [selectedColor, setSelectedColor] = useState(null);
  const [coilWidthOptions, setCoilWidthOptions] = useState([]);

  const handleColorChange = (e) => {
    // e.target.value is the INDEX of the color
    setSelectedColor(colors[e.target.value]);

    // TODO: We need to load the coilWidthOptions
    let gaugeOptions = colors[e.target.value].gauges;

    let selectedGauge = gaugeOptions.find(
      (g) => parseFloat(g.gauge).toFixed(4) == 24
      // parseFloat(colors[e.target.value].gauges[0].gauge).toFixed(4)
    );
    if (!selectedGauge) {
      selectedGauge = gaugeOptions[0]; // Default to first option if 24 gauge doesnt exist
    }

    if (selectedGauge) {
      setCoilWidthOptions(selectedGauge.coilWidths);

      // Find selected coil width option by saved flatSheet size and update flatSheetCostOriginal
      let flatSheetSize = materialDetails.flatSheetSize;

      const index = selectedGauge.coilWidths.findIndex(
        (c) => parseFloat(c.width) == parseFloat(flatSheetSize)
      );
      let coilOptionSelected = selectedGauge.coilWidths[index];

      let payload = {
        color: colors[e.target.value].name,
        manufacturer: colors[e.target.value].paintManufacturer.name,
        // gauge: parseFloat(colors[e.target.value].gauges[0].gauge).toFixed(4),
        gauge: parseFloat(selectedGauge.gauge).toFixed(4),
        flatSheetCost: coilOptionSelected ? coilOptionSelected?.price : 0,
        flatSheetCostOriginal: coilOptionSelected
          ? coilOptionSelected?.price
          : 0,
        coilCostPerSqFt: coilOptionSelected
          ? coilOptionSelected.pricePerSqFt
          : 0,
        coilCostPerSqFtOriginal: coilOptionSelected
          ? coilOptionSelected.pricePerSqFt
          : 0,
      };

      if (isCustomerSupplied) {
        payload.flatSheetCost = 0;
        payload.flatSheetCostOriginal = 0;
        payload.coilCostPerSqFt = 0;
        payload.coilCostPerSqFtOriginal = 0;
      }

      setMaterialDetails((prev) => ({ ...prev, ...payload }));
    } else {
      let payload = {
        color: colors[e.target.value].name,
        manufacturer: colors[e.target.value].paintManufacturer.name,
        gauge: parseFloat(colors[e.target.value].gauges[0].gauge).toFixed(4),
        // TODO: Need to set the value
        flatSheetCost: 0,
        flatSheetCostOriginal: 0,
        coilCostPerSqFt: 0,
        coilCostPerSqFtOriginal: 0,
        flatSheetSize: 48,
      };
      setMaterialDetails((prev) => ({ ...prev, ...payload }));
    }
  };

  const handleGaugeChange = (gaugeValue) => {
    let selectedGauge = selectedColor.gauges.find(
      (g) => parseFloat(g.gauge) == parseFloat(gaugeValue)
    );
    if (selectedGauge) {
      setCoilWidthOptions(selectedGauge.coilWidths);
    }

    // Find selected coil width option by saved flatSheet size and update flatSheetCostOriginal
    let flatSheetSize = materialDetails.flatSheetSize; // default 48.00
    // Set selected coil with to 48 inches if available
    const index = selectedGauge.coilWidths.findIndex(
      (c) => parseFloat(c.width) == parseFloat(flatSheetSize)
    );
    let coilOptionSelected = selectedGauge.coilWidths[index];

    let payload = {
      gauge: parseFloat(gaugeValue).toFixed(4),
      flatSheetCost: coilOptionSelected ? coilOptionSelected?.price : 0,
      flatSheetCostOriginal: coilOptionSelected ? coilOptionSelected?.price : 0,
      coilCostPerSqFt: coilOptionSelected
        ? coilOptionSelected?.pricePerSqFt
        : 0,
      coilCostPerSqFtOriginal: coilOptionSelected
        ? coilOptionSelected?.pricePerSqFt
        : 0,
    };

    if (isCustomerSupplied) {
      payload.flatSheetCost = 0;
      payload.flatSheetCostOriginal = 0;
      payload.coilCostPerSqFt = 0;
      payload.coilCostPerSqFtOriginal = 0;
    }

    setMaterialDetails((prev) => ({ ...prev, ...payload }));
  };

  const handleFSOptionSelect = (fsWidthOption) => {
    const index = coilWidthOptions.findIndex((c) => c.width == fsWidthOption);
    let coilOptionSelected = coilWidthOptions[index];

    // UPDATE FLATSHEET SIZE AND COST VARIABLES WHEN A COIL OPTION IS SELECTED
    let payload = {
      flatSheetSize: fsWidthOption,
      flatSheetCost: coilOptionSelected ? coilOptionSelected?.price : 0,
      flatSheetCostOriginal: coilOptionSelected ? coilOptionSelected?.price : 0,
      coilCostPerSqFt: coilOptionSelected
        ? coilOptionSelected?.pricePerSqFt
        : 0,
      coilCostPerSqFtOriginal: coilOptionSelected
        ? coilOptionSelected?.pricePerSqFt
        : 0,
    };

    if (isCustomerSupplied) {
      payload.flatSheetCost = 0;
      payload.flatSheetCostOriginal = 0;
      payload.coilCostPerSqFt = 0;
      payload.coilCostPerSqFtOriginal = 0;
    }

    setMaterialDetails((prev) => ({ ...prev, ...payload }));
  };

  // for manually typing the FS width/size
  const handleFSSizeChange = (fsWidthEntered) => {
    let flatSheetSize = parseFloat(fsWidthEntered);
    const index = coilWidthOptions.findIndex((c) => c.width == flatSheetSize);
    let coilOptionSelected = coilWidthOptions[index];
    let payload = {
      flatSheetSize: flatSheetSize,
      flatSheetCost: coilOptionSelected ? coilOptionSelected?.price : 0,
      flatSheetCostOriginal: coilOptionSelected ? coilOptionSelected?.price : 0,
      coilCostPerSqFt: coilOptionSelected
        ? coilOptionSelected?.pricePerSqFt
        : 0,
      coilCostPerSqFtOriginal: coilOptionSelected
        ? coilOptionSelected?.pricePerSqFt
        : 0,
    };

    if (isCustomerSupplied) {
      payload.flatSheetCost = 0;
      payload.flatSheetCostOriginal = 0;
      payload.coilCostPerSqFt = 0;
      payload.coilCostPerSqFtOriginal = 0;
    }

    setMaterialDetails((prev) => ({ ...prev, ...payload }));
  };

  const setInitialSelectorOptionsAndInitialPrices = (e) => {
    // Seting the initial color will set the gauge options
    setSelectedColor(colors[e.target.value]);
    let gaugeOptions = colors[e.target.value].gauges;
    let selectedGauge = gaugeOptions.find(
      (g) =>
        parseFloat(g.gauge) ==
        // parseFloat(colors[e.target.value].gauges[0].gauge).toFixed(4)
        parseFloat(materialDetails.gauge).toFixed(4) // This should be based on the order gauge
    );
    if (
      selectedGauge // Check if current order gauge value exists as an option
    ) {
      setCoilWidthOptions(selectedGauge.coilWidths);

      // Find selected coil width option by saved flatSheet size and update flatSheetCostOriginal
      let flatSheetSize = materialDetails.flatSheetSize; // default 48.00

      // Set selected coil with to 48 inches if available
      const index = selectedGauge.coilWidths.findIndex(
        (c) => parseFloat(c.width) == parseFloat(flatSheetSize)
      );
      let coilOptionSelected = selectedGauge.coilWidths[index];

      // UPDATE FLATSHEET SIZE AND COST VARIABLES WHEN A COIL OPTION IS SELECTED
      let payload = {
        flatSheetCost: coilOptionSelected ? coilOptionSelected?.price : 0,
        flatSheetCostOriginal: coilOptionSelected
          ? coilOptionSelected?.price
          : 0,
        coilCostPerSqFt: coilOptionSelected
          ? coilOptionSelected.pricePerSqFt
          : 0,
        coilCostPerSqFtOriginal: coilOptionSelected
          ? coilOptionSelected.pricePerSqFt
          : 0,
      };
      if (isCustomerSupplied) {
        payload.flatSheetCost = 0;
        payload.flatSheetCostOriginal = 0;
        payload.coilCostPerSqFt = 0;
        payload.coilCostPerSqFtOriginal = 0;
      }

      setMaterialDetails((prev) => ({ ...prev, ...payload }));
    } else {
      console.log("__gauge value does not exist as an option");
    }
  };

  // Initial Selector Value Setup without Updating price values if values already exist
  const setInitialSelectorOptions = (e) => {
    // Seting the initial color will set the gauge options
    setSelectedColor(colors[e.target.value]);
    let gaugeOptions = colors[e.target.value].gauges;

    let selectedGauge = gaugeOptions.find(
      (g) =>
        parseFloat(g.gauge) ==
        // parseFloat(colors[e.target.value].gauges[0].gauge).toFixed(4)
        parseFloat(materialDetails.gauge).toFixed(4) // this should be based on the order gauge
    );
    if (selectedGauge) {
      setCoilWidthOptions(selectedGauge.coilWidths);

      // Find selected coil width option by saved flatSheet size and update flatSheetCostOriginal
      let flatSheetSize = materialDetails.flatSheetSize; // default 48.00

      const index = selectedGauge.coilWidths.findIndex(
        (c) => parseFloat(c.width) == parseFloat(flatSheetSize)
      );
      let coilOptionSelected = selectedGauge.coilWidths[index];
    }
  };

  const findColorIndex = (name, manufacturer) => {
    return colors.findIndex(
      (c) => c.name === name && c.paintManufacturer.name === manufacturer
    );
  };

  // Update  Gauge Options based on Color Selection
  // ONLY UPDATE WHEN THERE IS NO SET VALUES
  useEffect(() => {
    if (isCustomerSupplied) {
      let payload = {
        flatSheetCost: 0,
        flatSheetCostOriginal: 0,
        coilCostPerSqFt: 0,
        coilCostPerSqFtOriginal: 0,
      };

      setMaterialDetails((prev) => ({ ...prev, ...payload }));
    } else if (colors.length > 0) {
      if (materialDetails) {
        if (materialDetails.color && materialDetails.manufacturer) {
          if (
            materialDetails.flatSheetCost == null &&
            materialDetails.flatSheetCostOriginal == null &&
            materialDetails.coilCostPerSqFt == null &&
            materialDetails.coilCostPerSqFtOriginal == null
          ) {
            // IF PRICES ARE NULL (FRESH ORDER)
            let matchedColorIndex = findColorIndex(
              materialDetails.color,
              materialDetails.manufacturer
            );

            let e = {
              target: {
                value: matchedColorIndex > -1 ? matchedColorIndex : 0,
              },
            };
            setInitialSelectorOptionsAndInitialPrices(e);
          } else {
            // IF PRICES ARE NOT NULL (ORDER HAS BEEN SAVED AT LEAST ONCE BY ADMIN)

            let matchedColorIndex = findColorIndex(
              materialDetails.color,
              materialDetails.manufacturer
            );
            let e = {
              target: {
                value: matchedColorIndex > -1 ? matchedColorIndex : 0,
              },
            };
            setInitialSelectorOptions(e);
          }
        } else {
          // If no value (creating new order), add initial values as well
          let e = {
            target: {
              value: 0, // value is color index
            },
          };
          handleColorChange(e);
        }
      }
    }
  }, [
    colors,
    materialDetails.color,
    materialDetails.manufacturer,
    isCustomerSupplied,
  ]);
  // The change in color and manufacturer on handleColorChange makes this call twice

  if (
    colors.length == 0 ||
    !materialDetails.color ||
    !materialDetails.manufacturer
  ) {
    return null;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: isSmallScreen ? "column" : "row",
        gap: "1rem",
      }}
    >
      <InputLabel shrink>Color</InputLabel>
      <Select
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: "90%",
              maxWidth: "80%",
            },
          },
        }}
        size="small"
        select
        label="Color"
        value={colors
          .map((c) => c.name + c.paintManufacturer.name)
          .indexOf(materialDetails.color + materialDetails.manufacturer)}
        onChange={handleColorChange}
      >
        {colors.map((theColor, index) => (
          <MenuItem key={theColor.id} value={index}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Avatar
                src={theColor.image}
                sx={{
                  width: 24,
                  height: 24,
                  marginRight: "1rem",
                }}
              />{" "}
              {theColor.name} - {theColor.paintManufacturer.name} - [
              {theColor.status}]
            </Box>
          </MenuItem>
        ))}
      </Select>
      <TextField
        sx={{ minWidth: "150px" }}
        size="small"
        disabled={!materialDetails.color}
        select
        label="Gauge"
        value={parseFloat(
          parseFloat(materialDetails.gauge).toFixed(4)
        ).toString()}
        onChange={(e) => {
          handleGaugeChange(e.target.value);
        }}
      >
        {selectedColor?.gauges.map((theGauge, index) => (
          <MenuItem
            key={theGauge.id}
            value={parseFloat(parseFloat(theGauge.gauge).toFixed(4)).toString()}
          >
            {parseFloat(parseFloat(theGauge.gauge).toFixed(4)).toString()}
          </MenuItem>
        ))}
      </TextField>
      <AdornedInput
        size="large"
        onFocus={(e) => e.target.select()}
        label="Coil Price Per Sq Ft"
        value={`${materialDetails?.coilCostPerSqFt}`}
        type="number"
        onWheel={(event) => {
          event.preventDefault();
        }}
        onChange={(e) => {
          let payload = {
            coilCostPerSqFt: parseFloat(e.target.value),
          };
          setMaterialDetails((prev) => ({ ...prev, ...payload }));
        }}
        isOveridden={
          materialDetails?.coilCostPerSqFt !=
          materialDetails.coilCostPerSqFtOriginal
        }
        handleReset={(e) => {
          // Set FS Price to FS Price Original
          let payload = {
            coilCostPerSqFt: materialDetails.coilCostPerSqFtOriginal,
          };

          setMaterialDetails((prev) => ({ ...prev, ...payload }));
        }}
        symbol="$"
        position="start"
      />
    </Box>
  );
};
export default ColorSelect;
