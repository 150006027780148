import { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import EmployeeForm from "./EmployeeForm";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Modal,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import { SnackAlertContext } from "../../../../context/SnackAlertContext";

const AddEmployeeModal = ({
  open,
  handleOpen,
  handleClose,
  isAdmin,
  companyIdFromAdmin,
  isVendorAccount,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { openSnackMessage } = useContext(SnackAlertContext);
  const navigate = useNavigate();
  const { authToken, decoded, ezorder } = useContext(AuthContext);
  const { role, companyId } = decoded;
  const headers = {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  };
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [permissions, setPermissions] = useState([]);

  const getPermissions = async () => {
    try {
      setLoading(true);
      let response = await ezorder.get(`/auth/permissions`);
      let perms = response.data.permissions;
      // If Creating Vendor Account, default check "Request Quote And Approve Own Quotes"
      if (isVendorAccount) {
        for (let perm of perms) {
          if (perm.name == "Request Quote And Approve Own Quotes") {
            perm.checked = true;
          }
        }
      }
      // If Regular Customer Account, default check "Request Quote"
      else {
        for (let perm of perms) {
          if (perm.name == "Request Quote") {
            perm.checked = true;
          }
        }
      }

      setPermissions(response.data.permissions);
      console.log(response.data.permissions);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSignUp = async (value) => {
    try {
      setLoading(true);
      let selectedPermissions = [];
      for (const permission of permissions) {
        if (permission.checked) selectedPermissions.push(permission);
      }
      let user;
      // If Current User is Industry Admin
      if (isAdmin) {
        user = {
          ...value,
          companyId: companyIdFromAdmin,
          permissions: selectedPermissions,
        };
        console.log("CREATING EMPLOYEE", user);
        const response = await ezorder.post(
          `/admin/auth/signup/employee`,
          user,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        if (response.status == 200 || response.status == 201) {
          openSnackMessage("success", "User Added");
          handleClose();
        } else {
          openSnackMessage("error", response.response.data.error);
        }
      }
      // If Current User is a regular customer user
      else {
        user = { ...value, companyId, permissions: selectedPermissions };
        console.log("CREATING EMPLOYEE", user);
        const response = await ezorder.post(`/auth/signup/employee`, user, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });
        if (response.status == 200 || response.status == 201) {
          openSnackMessage("success", "User Added");
          handleClose();
        } else {
          openSnackMessage("error", response.response.data.error);
        }
      }
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage("error", error.response.data.error);
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", error);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPermissions();
  }, []);

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={"paper"}
        fullScreen={isSmallScreen}
        maxWidth={true}
      >
        <DialogTitle>Add New User Account</DialogTitle>
        <DialogContent>
          <EmployeeForm
            protectEmail={false}
            isVendorAccount={isVendorAccount}
            permissions={permissions}
            setPermissions={setPermissions}
            handleSubmit={handleSignUp}
            loading={loading}
            error={error}
            onBack={() => {
              handleClose();
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};
export default AddEmployeeModal;
