import { useState, useContext, useEffect } from "react";
import { AuthContext } from "../context/AuthContext";
import { Box, Typography, Card, CardMedia } from "@mui/material";

// THIS IS ONLY BEING USED IN ADMIN
const S3PresignedImage = (props) => {
  const { s3Key } = props;
  const [presignedUrl, setPresignedUrl] = useState(null);
  const { authToken, ezorder } = useContext(AuthContext);

  const createPresignedUrl = async (controller) => {
    try {
      let data = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      if (controller) {
        data.signal = controller.signal;
      }

      let keySplit = s3Key.split("/");
      let identifier = keySplit[0];
      let resource = keySplit[1];
      let fileName = keySplit[2];
      const response = await ezorder.post(
        `/files/presignedUrl`,
        { resource, fileName, identifier, actionType: "READ" },
        data
      );
      // console.log("Presigned Image Data", response.data);
      setPresignedUrl(response.data.getUrl);
    } catch (error) {
      // console.log(error);
      throw Error("Unable to load presigned URl", { cause: error });
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    if (s3Key) createPresignedUrl(controller);

    return () => {
      controller.abort(); // abort api call on unmount for cleanup
    };
  }, [s3Key]);

  if (!presignedUrl) {
    return (
      <Box sx={{ maxWidth: 245, minWidth: 245 }}>
        <Typography>No Image</Typography>
      </Box>
    );
  }
  return (
    <Card
      sx={{ maxWidth: 245, minWidth: 245 }}
      onClick={() => {
        window.open(presignedUrl, "_blank");
      }}
    >
      <CardMedia
        component="img"
        height="245"
        name="image"
        image={presignedUrl}
        alt="trim image"
      />
    </Card>
  );
};
export default S3PresignedImage;
