import { Box, Button, Grid, Typography } from "@mui/material";
import ShopOrders from "../shop/Orders/ShopOrders";

const ShopView = ({ resource = "shop-view", activeTab = "in-progress" }) => {
  return (
    <>
      <Typography variant="h4" component="div">
        Shop Orders
      </Typography>
      <ShopOrders resource={resource} currentTab={activeTab} />
    </>
  );
};

export default ShopView;
