import { useContext, useEffect, useState } from "react";
import * as yup from "yup";
import {
  Box,
  Button,
  Typography,
  Modal,
  TextField,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormLabel,
  RadioGroup,
  Radio,
  Container,
} from "@mui/material";

import { AuthContext } from "../../../context/AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import { SnackAlertContext } from "../../../context/SnackAlertContext";
import { LoadingButton } from "@mui/lab";
import DeactivateEmployeeModal from "./DeactivateEmployeeDialog";
import RestoreEmployeeModal from "./RestoreEmployeeDialog";
import IndustryEmployeeForm from "./IndustryEmployeeForm";

const EmployeeDetails = () => {
  const navigate = useNavigate();
  const { openSnackMessage } = useContext(SnackAlertContext);
  const { id } = useParams();
  const { authToken, ezorder, decoded, role } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [deactivateDialogOpen, setDeactivateDialogOpen] = useState(false);
  const [restoreDialogOpen, setRestoreDialogOpen] = useState(false);

  // get ID
  const getIndustryEmployee = async () => {
    try {
      setLoading(true);
      let data = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      const response = await ezorder.get(`/users/${id}`, data);
      setUserDetails(response.data.employee);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleEmployeeUpdate = async (values) => {
    try {
      setLoading(true);

      const response = await ezorder.put(`/users/${userDetails.id}`, values, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      if (response.status == 200 || response.status == 201) {
        openSnackMessage("success", "User Updated");
        navigate("/employees");
      } else {
        openSnackMessage("error", response.response.data.error);
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage("error", error.response.data.error);
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", error);
      }
    } finally {
      setLoading(false);
    }
  };

  const deactivateEmployeeAccount = async () => {
    try {
      setLoading(true);

      const response = await ezorder.delete(`/users/${userDetails.id}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      if (response.status == 200 || response.status == 201) {
        openSnackMessage("success", "User Account Deactivated");
        navigate("/employees");
      } else {
        openSnackMessage("error", response.response.data.error);
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage("error", error.response.data.error);
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", error);
      }
    } finally {
      setLoading(false);
    }
  };

  const restoreEmployeeAccount = async () => {
    try {
      setLoading(true);

      const response = await ezorder.put(
        `/users/${userDetails.id}/restore`,
        {},
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      openSnackMessage("success", "User Account Restored");
      navigate("/employees");
    } catch (error) {
      console.log(error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage("error", error.response.data.error);
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", error);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getIndustryEmployee();
  }, [id]);

  if (userDetails) {
    return (
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography component="h1" variant="h5" sx={{ textAlign: "center" }}>
            Employee Details
          </Typography>
          <IndustryEmployeeForm
            protectEmail={true}
            userDetails={userDetails}
            loading={loading}
            handleSubmit={handleEmployeeUpdate}
            onBack={() => {
              navigate("/employees");
            }}
          />
          {userDetails.deletedAt ? (
            <LoadingButton
              color="success"
              onClick={() => {
                setRestoreDialogOpen(true);
              }}
            >
              Restore Account
            </LoadingButton>
          ) : (
            <LoadingButton
              color="error"
              onClick={() => {
                setDeactivateDialogOpen(true);
              }}
            >
              Deactivate Account
            </LoadingButton>
          )}

          <DeactivateEmployeeModal
            open={deactivateDialogOpen}
            handleClose={() => {
              setDeactivateDialogOpen(!deactivateDialogOpen);
            }}
            loading={loading}
            onConfirm={() => {
              deactivateEmployeeAccount();
            }}
          />
          <RestoreEmployeeModal
            open={restoreDialogOpen}
            handleClose={() => {
              setRestoreDialogOpen(!restoreDialogOpen);
            }}
            loading={loading}
            onConfirm={() => {
              restoreEmployeeAccount();
            }}
          />
        </Box>
      </Container>
    );
  }
};

export default EmployeeDetails;
