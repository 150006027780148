import { Button, Typography } from "@mui/material";

const ListFooter = ({ noMoreText, hasMore, onClick }) => {
  return (
    <div>
      {/* {hasMore ? (
        <Button onClick={onClick}>Load More</Button>
      ) : (
        <Typography>End Reached</Typography>
      )} */}
      {hasMore ? (
        <Button onClick={onClick}>Load More</Button>
      ) : (
        <Typography>{noMoreText ? noMoreText : ""}</Typography>
      )}
    </div>
  );
};

export default ListFooter;
