import React, { useState, createContext, useEffect, useContext } from "react";
import useNotifications from "../hooks/useNotifications";

export const NotificationContext = createContext();

export const NotificationProvider = (props) => {
  const getAuthenticationFromLocalStorage = () => {
    // check if auth exists in state
    // if not, check local storage for jwt and decode
    // decode
    // return
    try {
      const token = localStorage.getItem("CSMAZ_APP_TOKEN");
      return token;
    } catch (error) {
      console.log("No token in localstorage");
    }
  };

  const [
    unreadCount,
    setUnreadCount,
    notifications,
    getNotifications,
    onLoadMore,
    onRefresh,
    refreshing,
    loadingMore,
    hasMore,
    updateMessageToRead,
  ] = useNotifications(getAuthenticationFromLocalStorage());

  return (
    <NotificationContext.Provider
      value={{
        unreadCount,
        setUnreadCount,
        notifications,
        getNotifications,
        onLoadMore,
        onRefresh,
        refreshing,
        loadingMore,
        hasMore,
        updateMessageToRead,
      }}
    >
      {props.children}
    </NotificationContext.Provider>
  );
};
