import {
  TextField,
  Typography,
  Box,
  MenuItem,
  Button,
  IconButton,
  useMediaQuery,
  DialogTitle,
  DialogContent,
  DialogActions,
  Card,
  DialogContentText,
  Dialog,
  Fab,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddProductModal from "../AddProductModal";
import { useState, useContext } from "react";
import { MobileOrderContext } from "../../../../../../context/MobileOrderContext";
import Catalog from "../../../../../../components/Catalog/Catalog";
import useTrimCatalog from "../../../../../../hooks/useTrimCatalog";
import { AuthContext } from "../../../../../../context/AuthContext";
import S3PresignedImage from "../../../../../../components/CustomerComponents/S3/S3PresignedImage";
import useUploadImages from "../../../../../../hooks/useUploadImages";
import CircularProgress from "@mui/material/CircularProgress";
import { useTheme } from "@emotion/react";
import { useEffect } from "react";
import { Add, Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import TextFieldDebounced from "../../../../../../components/TextFieldDebounced";

const Trim = ({
  showSaving,
  isUpdating,
  isProductSelected,
  isAutoSaving,
  canUpdate,
  open,
  handleClose,
  projectName,
  handleNext,
  hasNext,
}) => {
  const { authToken, decoded } = useContext(AuthContext);
  const { trim, trimDispatch, orderDispatch } = useContext(MobileOrderContext);
  const [
    searchQuery,
    setSearchQuery,
    catalogFilter,
    setCatalogFilter,
    results,
    onRefresh,
    refreshing,
    onLoadMore,
    loadingMore,
    total,
    hasMore,
  ] = useTrimCatalog(authToken, 10, projectName, "master");
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [saveTrimImage] = useUploadImages("trims");

  const [addProfile, setAddProfile] = useState(false);
  const [isSubmittingNewTrim, setIsSubmittingNewTrim] = useState(false);

  const handleNewTrim = async (name, imageBlob, resource, saveToCatalog) => {
    try {
      setIsSubmittingNewTrim(true);
      const trim = await saveTrimImage(
        name,
        imageBlob,
        resource,
        saveToCatalog
      );

      // trimDispatch({
      //   type: "add piece",
      //   payload: {
      //     quantity: 1,
      //     trim,
      //   },
      // });
      if (trim.isMasterCatalog) {
        trimDispatch({
          type: "add piece",
          payload: {
            quantity: 1,
            name: trim.name,
            image: trim.image,
            hits: trim.masterHits,
            stretchOut: trim.masterStretchOut,
            trim,
          },
        });
      } else {
        trimDispatch({
          type: "add piece",
          payload: {
            quantity: 1,
            name: trim.name,
            image: trim.image,
            trim,
          },
        });
      }
      setAddProfile(false);
    } catch (error) {
      console.log(error);
    } finally {
      setIsSubmittingNewTrim(false);
      onRefresh(); // Refresh Catalog
    }
  };

  const selectTrim = (trim) => {
    if (trim.isMasterCatalog) {
      trimDispatch({
        type: "add piece",
        payload: {
          quantity: 1,
          name: trim.name,
          image: trim.image,
          hits: trim.masterHits,
          stretchOut: trim.masterStretchOut,
          trim,
        },
      });
    } else {
      trimDispatch({
        type: "add piece",
        payload: {
          quantity: 1,
          name: trim.name,
          image: trim.image,
          trim,
        },
      });
    }
    setAddProfile(false);
  };

  const handleRemovePiece = (index) => {
    trimDispatch({ type: "remove piece", payload: index });
  };

  const handleEditPieceQuantity = (index, quantity) => {
    trimDispatch({ type: "edit quantity", payload: { index, quantity } });
  };

  const handleSaveItem = (item) => {
    // if (!item.trimPieces.length > 0) return;
    orderDispatch({ type: "add order item", payload: item });
  };

  useEffect(() => {
    if (isProductSelected && canUpdate && isAutoSaving) {
      // TODO: auto save on every change
      handleSaveItem(trim);
    }
  }, [isProductSelected, trim]);

  return (
    <AddProductModal
      showSaving={showSaving}
      isUpdating={isUpdating}
      open={open}
      handleClose={() => {
        handleSaveItem(trim);
        handleClose();
      }}
    >
      <DialogTitle>Trim & Flashing</DialogTitle>
      <DialogContent sx={{ gap: "1rem" }}>
        <Box
          sx={{ maxHeight: isSmallScreen ? "100%" : "28rem", overflow: "auto" }}
        >
          <Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography variant="h7" sx={{ flex: 1 }}>
                Profiles
              </Typography>
              {(!addProfile || isSubmittingNewTrim) && canUpdate && (
                <LoadingButton
                  sx={{
                    borderRadius: "17px", // Same as extended variant of Fab
                  }}
                  variant="contained"
                  loading={isSubmittingNewTrim}
                  color="success"
                  onClick={() => setAddProfile(!addProfile)}
                >
                  + Add
                </LoadingButton>
              )}
            </Box>

            {trim.trimPieces.map((piece, index) => (
              <Box
                key={index}
                sx={{
                  marginTop: "2px",
                  padding: "8px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <S3PresignedImage s3Key={piece.image} size={100} />
                <Box
                  sx={{
                    flex: 1,
                    marginLeft: "8px",
                    display: "flex",
                    flexDirection: isSmallScreen ? "column" : "row",
                  }}
                >
                  <TextFieldDebounced
                    disabled={!canUpdate}
                    onFocus={(e) => e.target.select()}
                    size="small"
                    label="Name"
                    value={piece.name}
                    InputProps={{
                      readOnly: true,
                      disableUnderline: true,
                    }}
                  />
                  <TextFieldDebounced
                    disabled={!canUpdate}
                    sx={{
                      marginTop: isSmallScreen ? "8px" : 0,
                      marginLeft: isSmallScreen ? 0 : "8px",
                    }}
                    onFocus={(e) => e.target.select()}
                    type="number"
                    onWheel={(event) => {
                      event.preventDefault();
                    }}
                    size="small"
                    label="Quantity"
                    value={piece.quantity}
                    onChange={(e) => {
                      handleEditPieceQuantity(index, e.target.value);
                    }}
                  />
                </Box>
                {canUpdate && (
                  <IconButton
                    size="small"
                    onClick={() => {
                      handleRemovePiece(index);
                    }}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                )}
              </Box>
            ))}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            handleSaveItem(trim);
            handleClose();
          }}
        >
          {/* {canUpdate && isAutoSaving ? "Save & Close" : "Close"} */}
          Close
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            handleSaveItem(trim);
            handleNext();
          }}
        >
          {hasNext
            ? "Next"
            : canUpdate && isAutoSaving
            ? "Save & Exit"
            : "Exit"}
        </Button>
      </DialogActions>
      <Dialog
        keepMounted
        scroll={"paper"}
        maxWidth="md"
        fullWidth={true}
        fullScreen={isSmallScreen}
        PaperProps={{
          style: {
            background: theme.palette.secondary.main,
          },
        }}
        open={addProfile && !isSubmittingNewTrim}
        onClose={() => {
          setAddProfile(false);
        }}
      >
        <DialogTitle>
          Select Profile From Catalog Or Add Custom Piece
        </DialogTitle>
        <DialogContent>
          <Catalog
            projectTag={projectName}
            search={searchQuery}
            setSearch={setSearchQuery}
            catalogFilter={catalogFilter}
            setCatalogFilter={setCatalogFilter}
            enableCatalogFilters={true}
            items={results}
            itemType={"Trim & Flashing"}
            onRefresh={onRefresh}
            refreshing={refreshing}
            onLoadMore={onLoadMore}
            isLoadingMore={loadingMore}
            saveNew={handleNewTrim}
            onSelect={selectTrim}
            hasMore={hasMore}
            enableSaveToCatalog={true}
            catalogCompanyName={decoded.companyName}
            useFab={true}
            fabAbsolutePositionInContainer={true}
          />
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => {
              setAddProfile(false);
            }}
          >
            Back
          </Button>
        </DialogActions>
      </Dialog>
    </AddProductModal>
  );
};
export default Trim;
