import {
  Card,
  Modal,
  Typography,
  Button,
  TextField,
  Dialog,
  useMediaQuery,
  DialogContent,
  DialogActions,
  DialogTitle,
  Box,
  Checkbox,
} from "@mui/material";
import { useCallback, useState } from "react";
import { CameraAlt, Close, Upload } from "@mui/icons-material";
import { useTheme } from "@emotion/react";
import ImageCropper from "../ImageCropper/ImageCropper";

const AddCatalogItemModal = ({
  open,
  setOpen,
  onSubmit,
  isMasterCatalogItem,
  enableSaveToCatalog = false,
  catalogCompanyName,
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [name, setName] = useState("");
  const [masterHits, setMasterHits] = useState("");
  const [masterStretchOut, setMasterStretchOut] = useState("");
  const [imageBlob, setImageBlob] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [imageBlobCropped, setImageBlobCropped] = useState(null);
  const [imageUrlCropped, setImageUrlCropped] = useState(null);
  const [saveToCatalog, setSaveToCatalog] = useState(false);

  const resetData = () => {
    setName("");
    setImageBlob(null);
    setImageUrl(null);
    setImageBlobCropped(null);
    setImageUrlCropped(null);
    setSaveToCatalog(false);
    setMasterHits(1);
    setMasterStretchOut(1);
  };
  const handleClose = () => {
    resetData();
    setOpen(false);
  };

  return (
    <Dialog
      // disableEnforceFocus
      keepMounted
      scroll={"paper"}
      maxWidth="md"
      fullWidth={true}
      fullScreen={fullScreen}
      PaperProps={{
        style: {
          background: theme.palette.secondary.main,
        },
      }}
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>Add New</DialogTitle>
      <DialogContent sx={{ gap: "1rem" }}>
        <TextField
          variant="outlined"
          label="Name"
          name="Name"
          size="large"
          fullWidth
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
        />
        {isMasterCatalogItem && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              marginTop: "16px",
            }}
          >
            <TextField
              variant="outlined"
              label="Hits"
              name="Hits"
              size="large"
              fullWidth
              value={masterHits}
              onChange={(e) => {
                setMasterHits(parseFloat(e.target.value));
              }}
              sx={{ marginRight: "16px" }}
            />
            <TextField
              variant="outlined"
              label="Stretchout"
              name="Stretchout"
              size="large"
              fullWidth
              type="number"
              value={masterStretchOut}
              onFocus={(e) => e.target.select()}
              onWheel={(event) => {
                event.preventDefault();
              }}
              onChange={(e) => {
                setMasterStretchOut(parseFloat(e.target.value));
              }}
            />
          </Box>
        )}

        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{ marginTop: "12px" }}
        >
          Image
        </Typography>

        <Box sx={{ maxWidth: "400px" }}>
          {imageUrl ? (
            <ImageCropper
              image={imageUrl}
              onSave={(fileBlob, url) => {
                setImageUrl(null);
                setImageBlob(null);
                setImageBlobCropped(fileBlob);
                setImageUrlCropped(url);
              }}
              onClear={() => {
                setImageUrl(null);
                setImageBlob(null);
              }}
            />
          ) : (
            <div>
              <div>
                {imageUrlCropped && (
                  <div style={{ position: "relative" }}>
                    <img
                      src={imageUrlCropped}
                      style={{
                        marginTop: 16,
                        // maxWidth: "100%",
                        // maxHeight: 200,
                        width: "100%",
                      }}
                    />
                    <div
                      onClick={() => {
                        setImageUrlCropped(null);
                        setImageBlobCropped(null);
                      }}
                    >
                      <Close
                        style={{ position: "absolute", top: 8, right: 8 }}
                      />
                    </div>
                  </div>
                )}
              </div>
              {imageBlob === null &&
                imageUrl === null &&
                imageBlobCropped === null &&
                imageUrlCropped === null && (
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <label>
                      <input
                        style={{ display: "none" }}
                        type="file"
                        accept="image/*"
                        // capture="environment"
                        onChange={(event) => {
                          console.log(event.target.files[0]);
                          console.log(
                            "Image",
                            URL.createObjectURL(event.target.files[0])
                          );
                          setImageBlob(event.target.files[0]);
                          setImageUrl(
                            URL.createObjectURL(event.target.files[0])
                          );
                          setImageBlobCropped(null);
                          setImageUrlCropped(null);
                        }}
                      />
                      <div
                        style={{
                          cursor: "pointer",
                          width: "100%",
                          padding: 12,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            borderStyle: "solid",
                            borderWidth: 1,
                            borderColor: "#d2d2d2",
                            color: "#d2d2d2",
                            width: 100,
                            height: 100,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Upload sx={{ fontSize: 40 }} />
                        </div>
                      </div>
                    </label>
                    <label>
                      <input
                        style={{ display: "none" }}
                        type="file"
                        accept="image/*"
                        capture="environment"
                        onChange={(event) => {
                          console.log(event.target.files[0]);
                          console.log(
                            "Image",
                            URL.createObjectURL(event.target.files[0])
                          );
                          setImageBlob(event.target.files[0]);
                          setImageUrl(
                            URL.createObjectURL(event.target.files[0])
                          );
                          setImageBlobCropped(null);
                          setImageUrlCropped(null);
                        }}
                      />
                      <div
                        style={{
                          cursor: "pointer",
                          width: "100%",
                          padding: 12,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            borderStyle: "solid",
                            borderWidth: 1,
                            borderColor: "#d2d2d2",
                            color: "#d2d2d2",
                            width: 100,
                            height: 100,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <CameraAlt sx={{ fontSize: 40 }} />
                        </div>
                      </div>
                    </label>
                  </div>
                )}
            </div>
          )}
        </Box>
        {enableSaveToCatalog && (
          <Box>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                magin: 0,
                padding: 0,
              }}
            >
              <Checkbox
                style={{
                  magin: 0,
                  padding: 0,
                }}
                checked={saveToCatalog}
                onChange={(e) => {
                  setSaveToCatalog(e.target.checked);
                }}
              />
              <Typography>
                Save To{catalogCompanyName ? ` ${catalogCompanyName}` : ""}{" "}
                Stock Catalog
              </Typography>
            </div>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Box style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            disabled={imageBlobCropped === null || name.length < 1}
            variant="outlined"
            onClick={async () => {
              await onSubmit(
                name,
                imageBlobCropped,
                saveToCatalog,
                masterHits,
                masterStretchOut
              );
              resetData();
              setOpen(false);
            }}
          >
            Submit
          </Button>
          <Button onClick={handleClose}>Cancel</Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default AddCatalogItemModal;
