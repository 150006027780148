import {
  valueIfNotOverridden,
  combineVariablesAndOverrides,
  toThreeDecimals,
  toTwoDecimals,
  toFourDecimals,
} from "./utils";

const caclculateBenchwork = (
  benchwork,
  variables,
  overrides = {},
  state = {}
) => {
  const $ = valueIfNotOverridden(overrides ?? {});
  const $variables = combineVariablesAndOverrides(variables, overrides ?? {});
  const { hourly_labor_cost: HOURLY_LABOR_COST } = $variables;

  let FLAT_SHEET_COST =
    state.flatSheetCost != state.flatSheetCostOriginal
      ? state.flatSheetCost
      : state.flatSheetCostOriginal;

  const TOTAL_ESTIMATED_LABOR_HOURS = $(
    "total_estimated_labor_hours",
    benchwork?.calculations?.totalEstimatedLaborHours ?? 0
  );

  const TOTAL_ESTIMATED_LABOR_COST = $(
    "total_estimated_labor_cost",
    parseFloat(TOTAL_ESTIMATED_LABOR_HOURS) * parseFloat(HOURLY_LABOR_COST)
  );

  // const MATERIAL_COST = $(
  //   "material_cost",
  //   benchwork?.calculations?.materialCost ?? 0
  // );

  const FLAT_SHEETS_NEEDED = $(
    "flat_sheets_needed",
    benchwork?.flatSheets ?? 0
  );
  const MATERIAL_COST = $(
    "material_cost",
    parseFloat(FLAT_SHEETS_NEEDED * FLAT_SHEET_COST)
  );

  const MATERIAL_AND_LABOR = $(
    "material_and_labor",
    parseFloat(TOTAL_ESTIMATED_LABOR_COST) + parseFloat(MATERIAL_COST)
  );

  const GROSS_PROFIT_MARGIN = $(
    "gross_profit_margin",
    benchwork?.calculations?.grossProfitMargin ?? 0
  );

  const TOTAL_SELL_PRICE = $(
    "total_sell_price",
    (parseFloat(TOTAL_ESTIMATED_LABOR_COST) + parseFloat(MATERIAL_COST)) /
      (1 - parseFloat(GROSS_PROFIT_MARGIN))
  );

  const GROSS_PROFIT_DOLLARS = $(
    "gross_profit_dollars",
    TOTAL_SELL_PRICE - (TOTAL_ESTIMATED_LABOR_COST + MATERIAL_COST)
  );
  const PRICE_PER_PIECE = $(
    "price_per_piece",
    parseFloat(TOTAL_SELL_PRICE / (benchwork?.quantity ?? 1))
  );

  return {
    hourlyLaborCost: toTwoDecimals(HOURLY_LABOR_COST),
    totalEstimatedLaborHours: toTwoDecimals(TOTAL_ESTIMATED_LABOR_HOURS),
    totalEstimatedLaborCost: toTwoDecimals(TOTAL_ESTIMATED_LABOR_COST),
    materialCost: toTwoDecimals(MATERIAL_COST),
    materialAndLabor: toTwoDecimals(MATERIAL_AND_LABOR),
    grossProfitMargin: toFourDecimals(GROSS_PROFIT_MARGIN),
    grossProfitDollars: toTwoDecimals(GROSS_PROFIT_DOLLARS),
    totalSellPrice: toTwoDecimals(TOTAL_SELL_PRICE),
    pricePerPiece: toTwoDecimals(PRICE_PER_PIECE),
  };
};
export default caclculateBenchwork;
