import { useTheme } from "@emotion/react";
import {
  AttachMoney,
  Money,
  Percent,
  PercentOutlined,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Paper,
  Typography,
  Box,
  TextField,
  Switch,
  Stack,
  Button,
  InputAdornment,
} from "@mui/material";
import { useState } from "react";

const EditAccessories = ({
  accessories,
  setAccessories,
  saveChangesButton,
  newItem,
  setNewItem,
  updateAccessory,
  isLoading,
}) => {
  const theme = useTheme();
  const [addNew, setAddNew] = useState(false);

  const changeHandler = (e) => {
    let inputType = e.target.type;
    let value = inputType === "checkbox" ? e.target.checked : e.target.value;
    let id = parseInt(e.target.id);
    let inputName = e.target.name;
    let newAccessories = accessories;
    let item = newAccessories[accessories.findIndex((item) => item.id == id)];
    if (inputName === "inStockInput") {
      item.inStock = value;
    } else if (inputName === "nameInput") {
      item.name = value;
    } else if (inputName === "unitOfMeasureInput") {
      item.unitOfMeasure = value;
    } else if (inputName === "currentStockInput") {
      if (value == "") value = "0";
      item.currentStock = parseFloat(value);
    } else if (inputName === "markupPercentageInput") {
      if (value == "") value = "0";
      item.markupPercentage = parseFloat(value);
    } else if (inputName === "costInput") {
      if (value == "") value = "0";
      item.cost = parseFloat(value);
    }

    newAccessories.splice(
      accessories.findIndex((item) => item.id == id),
      1,
      item
    );
    setAccessories([...newAccessories]);
  };

  const newItemHandler = (e) => {
    // console.log(e);
    let inputType = e.target.type;
    let value = inputType === "checkbox" ? e.target.checked : e.target.value;
    let item = newItem;
    if (inputType === "checkbox") {
      item.inStock = value;
    } else {
      item.name = value;
    }
    setNewItem({ ...item });
  };

  if (!accessories) {
    return (
      <Paper>
        <Typography>Loading...</Typography>
      </Paper>
    );
  }

  const addNewItem = () => {
    return (
      <Box
        // key={index}
        sx={{ display: "flex", flexDirection: "row", mb: "10px" }}
      >
        <TextField
          variant="outlined"
          label="Item Name"
          size="small"
          value={newItem.name}
          onChange={(e) => newItemHandler(e)}
        />
        <TextField
          variant="outlined"
          label="Unit Of Measure"
          size="small"
          value={newItem.unitOfMeasure}
          onChange={(e) =>
            setNewItem((prev) => ({ ...prev, unitOfMeasure: e.target.value }))
          }
        />
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          sx={{ marginLeft: "15px", marginRight: "50px" }}
        >
          <Typography color={!newItem.inStock ? "#cc1302" : null}>
            out-of-stock
          </Typography>
          <Switch
            checked={newItem.inStock}
            onChange={(e) => newItemHandler(e)}
          />
          <Typography color={newItem.inStock ? "#2cd402" : null}>
            in-stock
          </Typography>
        </Stack>
        <span
          onClick={() => {
            if (newItem.name === null) {
              alert("can't submit item without a name");
            }
            setAddNew(!addNew);
            setNewItem({ name: null, inStock: true });
          }}
        >
          {newItem.name?.length === 0 || newItem.name === null
            ? null
            : saveChangesButton()}
        </span>
      </Box>
    );
  };

  return (
    <Paper sx={{ mt: "30px" }}>
      <Typography ml="20px" variant="h6">
        Accessories Catalog
      </Typography>
      <Box sx={{ display: "flex", flexDirection: "column", margin: "20px" }}>
        {accessories.map((item, index) => (
          <Box
            key={index}
            sx={{ display: "flex", flexDirection: "row", mb: "10px" }}
          >
            <TextField
              variant="outlined"
              label="Item Name"
              size="small"
              name="nameInput"
              id={item.id.toString()}
              value={item.name}
              onChange={(e) => changeHandler(e)}
            />
            <TextField
              variant="outlined"
              label="Unit Of Measure"
              size="small"
              name="unitOfMeasureInput"
              id={item.id.toString()}
              value={item.unitOfMeasure}
              onChange={(e) => changeHandler(e)}
            />
            <TextField
              variant="outlined"
              label="Current Stock"
              size="small"
              name="currentStockInput"
              id={item.id.toString()}
              value={item.currentStock}
              onChange={(e) => changeHandler(e)}
            />
            <TextField
              variant="outlined"
              label="Our Cost"
              size="small"
              name="costInput"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Typography style={{ color: theme.palette.grey["500"] }}>
                      $
                    </Typography>
                  </InputAdornment>
                ),
              }}
              id={item.id.toString()}
              value={item.cost}
              onChange={(e) => changeHandler(e)}
            />
            <TextField
              variant="outlined"
              label="Markup"
              size="small"
              name="markupPercentageInput"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Typography style={{ color: theme.palette.grey["500"] }}>
                      %
                    </Typography>
                  </InputAdornment>
                ),
              }}
              id={item.id.toString()}
              value={item.markupPercentage}
              onChange={(e) => changeHandler(e)}
            />
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              sx={{ marginLeft: "15px" }}
            >
              <Typography color={!item.inStock ? "#cc1302" : null}>
                out-of-stock
              </Typography>
              <Switch
                name="inStockInput"
                id={item.id.toString()}
                checked={item.inStock}
                onChange={(e) => changeHandler(e)}
              />
              <Typography color={item.inStock ? "#2cd402" : null}>
                in-stock
              </Typography>
            </Stack>
            <LoadingButton
              loading={isLoading}
              variant="outlined"
              sx={{ marginLeft: "50px" }}
              onClick={() => updateAccessory(item)}
            >
              Save Changes
            </LoadingButton>
          </Box>
        ))}
        {addNew && addNewItem()}
        <Button color="success" onClick={() => setAddNew(!addNew)}>
          + Add
        </Button>
      </Box>
    </Paper>
  );
};
export default EditAccessories;
