import {
  TextField,
  Typography,
  Box,
  MenuItem,
  Button,
  IconButton,
  useMediaQuery,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Card,
  Dialog,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddProductModal from "../AddProductModal";
import { useState, useContext, useEffect } from "react";
import { MobileOrderContext } from "../../../../../../context/MobileOrderContext";
import Catalog from "../../../../../../components/Catalog/Catalog";
import { AuthContext } from "../../../../../../context/AuthContext";
import S3PresignedImage from "../../../../../../components/CustomerComponents/S3/S3PresignedImage";
import useUploadImages from "../../../../../../hooks/useUploadImages";
import CircularProgress from "@mui/material/CircularProgress";
import useCleatCatalog from "../../../../../../hooks/useCleatCatalog";
import { useTheme } from "@emotion/react";
import useGalvanizedFlatOptions from "../../../../../../hooks/useGalvanizedFlatOptions";
import { LoadingButton } from "@mui/lab";
import { Add } from "@mui/icons-material";
import TextFieldDebounced from "../../../../../../components/TextFieldDebounced";

const Cleat = ({
  showSaving,
  isUpdating,
  isProductSelected,
  isAutoSaving,
  canUpdate,
  open,
  handleClose,
  projectName,
  handleNext,
  hasNext,
}) => {
  const { authToken } = useContext(AuthContext);
  const { cleat, cleatDispatch, orderDispatch } =
    useContext(MobileOrderContext);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [
    searchQuery,
    setSearchQuery,
    results,
    onRefresh,
    refreshing,
    onLoadMore,
    loadingMore,
    total,
    hasMore,
  ] = useCleatCatalog(authToken, 10, null);
  const [catalogSelected, setCatalogSelected] = useState("Company");
  const [saveTrimImage] = useUploadImages("cleats");

  const [addProfile, setAddProfile] = useState(false);
  const [isSubmittingNewTrim, setIsSubmittingNewTrim] = useState(false);
  // const gaugeOptions = ["16g", "18g", "20g", "22g", "24g"];
  const {
    galvanizedFlatOptions,
    getGalvanizedFlatOptions,
    saveGalvanizedFlat,
    refreshing: loadingGalvOptions,
  } = useGalvanizedFlatOptions();

  const handleNewTrim = async (name, imageBlob, resource, saveToCatalog) => {
    try {
      setIsSubmittingNewTrim(true);
      const cleat = await saveTrimImage(
        name,
        imageBlob,
        resource,
        saveToCatalog
      );
      cleatDispatch({
        type: "add piece",
        payload: {
          quantity: 1,
          name: cleat.name,
          image: cleat.image,
          cleat,
        },
      });
      setAddProfile(false);
    } catch (error) {
      console.log(error);
    } finally {
      setIsSubmittingNewTrim(false);
      onRefresh(); // Refresh Catalog
    }
  };

  const selectTrim = (cleat) => {
    cleatDispatch({
      type: "add piece",
      payload: {
        quantity: 1,
        name: cleat.name,
        image: cleat.image,
        cleat,
      },
    });
    setAddProfile(false);
  };

  const handleRemovePiece = (index) => {
    cleatDispatch({ type: "remove piece", payload: index });
  };

  const handleEditPieceQuantity = (index, quantity) => {
    cleatDispatch({ type: "edit quantity", payload: { index, quantity } });
  };

  const handleSaveItem = (item) => {
    // if (!item.cleatPieces.length > 0) return;
    orderDispatch({ type: "add order item", payload: item });
  };

  useEffect(() => {
    if (isProductSelected && canUpdate && isAutoSaving) {
      // TODO: auto save on every change
      handleSaveItem(cleat);
    }
  }, [isProductSelected, cleat]);

  useEffect(() => {
    if (projectName) setSearchQuery(projectName);
  }, [projectName]);

  if (!galvanizedFlatOptions || galvanizedFlatOptions.length == 0) return null;

  return (
    <AddProductModal
      showSaving={showSaving}
      isUpdating={isUpdating}
      open={open}
      handleClose={() => {
        handleSaveItem(cleat);
        handleClose();
      }}
    >
      <DialogTitle>Coping Cap Cleat</DialogTitle>
      <DialogContent sx={{ gap: "1rem" }}>
        <Box
          sx={{ maxHeight: isSmallScreen ? "100%" : "28rem", overflow: "auto" }}
        >
          <TextField
            disabled={!canUpdate}
            sx={{ marginTop: "8px", marginBottom: "16px" }}
            size="small"
            fullWidth
            select
            label="Gauge"
            value={cleat.gauge + cleat.gaugeUnitOfMeasure}
            onChange={(e) => {
              let selectedGalvCleatGauge = galvanizedFlatOptions.find(
                (galvFlat) =>
                  galvFlat.gauge + galvFlat.unitOfMeasure == e.target.value
              );
              cleatDispatch({
                type: "gauge",
                payload: {
                  gauge: selectedGalvCleatGauge.gauge,
                  gaugeUnitOfMeasure: selectedGalvCleatGauge.unitOfMeasure,
                },
              });
            }}
          >
            {galvanizedFlatOptions?.map((galvFlat, index) => (
              <MenuItem
                key={index}
                value={galvFlat.gauge + galvFlat.unitOfMeasure}
              >
                {galvFlat.gauge} {galvFlat.unitOfMeasure}
              </MenuItem>
            ))}
          </TextField>
          <Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography variant="h7" sx={{ flex: 1 }}>
                Profiles
              </Typography>
              {(!addProfile || isSubmittingNewTrim) && canUpdate && (
                <LoadingButton
                  sx={{
                    borderRadius: "17px", // Same as extended variant of Fab
                  }}
                  variant="contained"
                  loading={isSubmittingNewTrim}
                  color="success"
                  onClick={() => setAddProfile(!addProfile)}
                >
                  + Add
                </LoadingButton>
              )}
            </Box>

            {cleat.cleatPieces.map((piece, index) => (
              <Box
                key={index}
                sx={{
                  marginTop: "2px",
                  padding: "8px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <S3PresignedImage s3Key={piece.image} size={100} />
                <Box
                  sx={{
                    flex: 1,
                    marginLeft: "8px",
                    display: "flex",
                    flexDirection: isSmallScreen ? "column" : "row",
                  }}
                >
                  <TextFieldDebounced
                    disabled={!canUpdate}
                    onFocus={(e) => e.target.select()}
                    size="small"
                    label="Name"
                    value={piece.name}
                    InputProps={{
                      readOnly: true,
                      disableUnderline: true,
                    }}
                  />
                  <TextFieldDebounced
                    disabled={!canUpdate}
                    sx={{
                      marginTop: isSmallScreen ? "8px" : 0,
                      marginLeft: isSmallScreen ? 0 : "8px",
                    }}
                    onFocus={(e) => e.target.select()}
                    type="number"
                    onWheel={(event) => {
                      event.preventDefault();
                    }}
                    size="small"
                    label="Quantity"
                    value={piece.quantity}
                    onChange={(e) => {
                      handleEditPieceQuantity(index, e.target.value);
                    }}
                  />
                </Box>
                {canUpdate && (
                  <IconButton
                    size="small"
                    onClick={() => {
                      handleRemovePiece(index);
                    }}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                )}
              </Box>
            ))}
            {/* {(!addProfile || isSubmittingNewTrim) && canUpdate && (
              <Box
                sx={{
                  marginTop: "16px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <LoadingButton
                  sx={{ minWidth: "300px" }}
                  loading={isSubmittingNewTrim}
                  variant="outlined"
                  color="success"
                  onClick={() => setAddProfile(!addProfile)}
                >
                  <Add />
                  Add
                </LoadingButton>
              </Box>
            )} */}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            handleSaveItem(cleat);
            handleClose();
          }}
        >
          {/* {canUpdate && isAutoSaving ? "Save & Close" : "Close"} */}
          Close
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            handleSaveItem(cleat);
            handleNext();
          }}
        >
          {hasNext
            ? "Next"
            : canUpdate && isAutoSaving
            ? "Save & Exit"
            : "Exit"}
        </Button>
      </DialogActions>
      <Dialog
        keepMounted
        scroll={"paper"}
        maxWidth="md"
        fullWidth={true}
        fullScreen={isSmallScreen}
        PaperProps={{
          style: {
            background: theme.palette.secondary.main,
          },
        }}
        open={addProfile && !isSubmittingNewTrim}
        onClose={() => {
          setAddProfile(false);
        }}
      >
        <DialogTitle>
          Select Profile From Catalog Or Add Custom Piece
        </DialogTitle>
        <DialogContent>
          {/* <Catalog
            projectTag={projectName}
            search={searchQuery}
            setSearch={setSearchQuery}
            catalogFilter={catalogFilter}
            setCatalogFilter={setCatalogFilter}
            enableCatalogFilters={true}
            items={results}
            itemType={"Trim & Flashing"}
            onRefresh={onRefresh}
            refreshing={refreshing}
            onLoadMore={onLoadMore}
            isLoadingMore={loadingMore}
            saveNew={handleNewTrim}
            onSelect={selectTrim}
            hasMore={hasMore}
            enableSaveToCatalog={true}
            useFab={false}
          /> */}
          <Catalog
            search={searchQuery}
            setSearch={setSearchQuery}
            catalogSelected={catalogSelected}
            setCatalogSelected={setCatalogSelected}
            items={results}
            itemType={"Cleat"}
            onRefresh={onRefresh}
            refreshing={refreshing}
            onLoadMore={onLoadMore}
            isLoadingMore={loadingMore}
            saveNew={handleNewTrim}
            onSelect={selectTrim}
            hasMore={hasMore}
            enableSaveToCatalog={false}
            useFab={true}
            fabAbsolutePositionInContainer={true}
          />
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => {
              setAddProfile(false);
            }}
          >
            Back
          </Button>
        </DialogActions>
      </Dialog>
    </AddProductModal>
  );
};
export default Cleat;
