import { useContext, useState, useEffect } from "react";
import { AuthContext } from "../../context/AuthContext";

const useQuickBooksCustomer = () => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { authToken, ezorder } = useContext(AuthContext);
  const header = {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  };

  const [search, setSearch] = useState("");
  const [category, setCategory] = useState("DisplayName");
  // const [category, setCategory] = useState("CompanyName");
  const body = {
    searchQuery: search,
    searchCategory: category,
  };
  const [customers, setCustomers] = useState([]);

  const getAllQBCustomers = async () => {
    try {
      setIsLoading(true);
      const res = await ezorder.post(`/quickbooks/customers`, body, header);
      // console.log("findQBCustomers", res.data.customers.QueryResponse.Customer);
      setCustomers(res.data.customers.QueryResponse.Customer);
    } catch (error) {
      console.log(error);
      // setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllQBCustomers();
  }, [search, category]);

  return {
    customers,
    search,
    category,
    setSearch,
    setCategory,
    isLoading,
    error,
  };
};
export default useQuickBooksCustomer;
