import { Typography, Box, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useContext, useState } from "react";
import moment from "moment";
import { LoadingButton } from "@mui/lab";
import useFileUpload from "../../../../../../hooks/useFileUpload";
import S3PresignedImage from "../../../../../../components/S3PresignedImage";
import S3PresignedPdf from "../../../../../../components/S3PresignedPdf";
import { AuthContext } from "../../../../../../context/AuthContext";
import { SnackAlertContext } from "../../../../../../context/SnackAlertContext";

const CustomerQuoteApproval = ({
  companyId,
  estimateApprovalDate,
  signedEstimatePDF,
  onManualApprovalFileUpload,
  downloadName,
}) => {
  const { authToken, ezorder } = useContext(AuthContext);
  const { openSnackMessage } = useContext(SnackAlertContext);
  console.log(estimateApprovalDate, signedEstimatePDF);
  const fileType = signedEstimatePDF ? signedEstimatePDF.split(".")[1] : null;
  const { uploadFile } = useFileUpload(ezorder, authToken);
  const [isUploading, setIsUploading] = useState(false);

  const handleFileUpload = async (e) => {
    try {
      setIsUploading(true);
      const file = e.target.files[0];
      const key = await uploadFile(companyId, "signed-estimates", file);
      console.log("File Uploaded to", key);

      openSnackMessage("success", "Uploaded");
      onManualApprovalFileUpload(key);
    } catch (error) {
      console.log(error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage("error", error.response.data.error);
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", error);
      }
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {!estimateApprovalDate || !signedEstimatePDF ? (
          <>
            <LoadingButton
              loading={isUploading}
              component="label"
              variant="outlined"
              sx={{
                padding: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography variant="overline">
                Manually Upload Signed Quote and Mark as Approved
              </Typography>
              <AddIcon />
              <input
                hidden
                accept="image/*,.doc,.docx,.pdf"
                type="file"
                onChange={handleFileUpload}
              />
            </LoadingButton>
          </>
        ) : (
          <>
            {signedEstimatePDF && (
              <>
                {fileType === "pdf" ? (
                  <S3PresignedPdf
                    s3Key={signedEstimatePDF}
                    downloadName={downloadName}
                  />
                ) : (
                  <S3PresignedImage
                    s3Key={signedEstimatePDF}
                    maxWidth={600}
                    minWidth={600}
                    height={600}
                  />
                )}
              </>
            )}
            {estimateApprovalDate && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: ".5rem",
                }}
              >
                <Typography variant="overline">Approved On: </Typography>
                <Typography variant="overline">
                  {moment(estimateApprovalDate).format("MM/DD/yyyy")}
                </Typography>
              </Box>
            )}
          </>
        )}
      </Box>
    </>
  );
};
export default CustomerQuoteApproval;
