import React, { useState, useContext, useEffect } from "react";
import S3PresignedImage from "./S3PresignedImage";
import S3PresignedPdf from "./S3PresignedPdf";

const S3FileViewer = (props) => {
  const { s3Key } = props;

  if (!s3Key) return null;
  if (s3Key.includes(".pdf")) {
    return <S3PresignedPdf {...props} />;
  } else {
    return <S3PresignedImage {...props} />;
  }
};

export default S3FileViewer;
