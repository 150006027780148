import usePageName from "../../../hocs/usePageName";
import { useNavigate, useParams } from "react-router-dom";
import { useContext, useState } from "react";
import { AuthContext } from "../../../context/AuthContext";
import useProjectOrders from "../../../hooks/useProjectOrders";
import SearchBar from "../../../components/SearchBar";
import InfiniteScroller from "../../../components/InfiniteScroller/InfiniteScroller";
import FabButton from "../../../components/CustomerComponents/FabButton";
import { Add } from "@mui/icons-material";
import ListFooter from "../../../components/InfiniteScroller/ListFooter";
import OrderCard from "../../../components/OrderCard";
import { SnackAlertContext } from "../../../context/SnackAlertContext";
import { initialOrder } from "../../../context/MobileOrderContext";
import { CircularProgress } from "@mui/material";

const ProjectOrders = () => {
  usePageName({ heading: "Project Quotes & Orders" });
  const navigate = useNavigate();
  const { id: projectId } = useParams();
  const { authToken, ezorder, decoded } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const [
    searchQuery,
    setSearchQuery,
    results,
    onRefresh,
    refreshing,
    onLoadMore,
    loadingMore,
    total,
    hasMore,
  ] = useProjectOrders(authToken, 10, projectId);
  const { openSnackMessage } = useContext(SnackAlertContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const copyOrder = async (orderId) => {
    try {
      setIsSubmitting(true);
      const response = await ezorder.post(
        `/orders/${orderId}/copy`,
        {},
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      openSnackMessage("success", "Order Copied");
      let copied = response.data.order;
      navigate(`/quotes/${copied.id}`);
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage("error", error.response.data.error);
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", error);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const createNewOrderForProject = async (projectId) => {
    try {
      setLoading(true);
      const requestBody = {
        projectId: parseInt(projectId),
        orderDetails: { ...initialOrder },
      };

      const response = await ezorder.post(`/orders`, requestBody, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      console.log(response);
      if (response.status == 200 || response.status === 201) {
        navigate(`/quotes/${response.data.order.id}`, {
          replace: true,
        });
      }
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage("error", error.response.data.error);
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", error);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <SearchBar
        label="Search Orders"
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
      />
      <InfiniteScroller
        items={results}
        renderItem={(item, index) => {
          return (
            <OrderCard
              key={index}
              order={item}
              clickHandler={() => {
                if (
                  !item.orderStatus ||
                  !item.orderStatus.name ||
                  item.orderStatus.name == "SAVED" ||
                  item.orderStatus.name == "QUOTE_REQUESTED" ||
                  item.orderStatus.name == "ESTIMATE_APPROVAL_NEEDED"
                ) {
                  navigate(`/quotes/${item.id}`);
                } else {
                  navigate(`/orders/${item.id}`);
                }
              }}
              onCopy={() => {
                copyOrder(item.id);
              }}
              isCopying={isSubmitting}
            />
          );
        }}
        onRefresh={onRefresh}
        onEndReached={onLoadMore}
        loading={refreshing || loadingMore}
        ListFooterComponent={() => {
          return <ListFooter hasMore={hasMore} onClick={onLoadMore} />;
        }}
      />
      <FabButton
        color="success"
        onClick={() => {
          if (!loading) createNewOrderForProject(projectId);
        }}
      >
        <>
          {loading ? (
            <CircularProgress color="inherit" size="1.2rem" sx={{ mr: 1 }} />
          ) : (
            <Add sx={{ mr: 1 }} />
          )}
          Create Quote Request
        </>
      </FabButton>
    </>
  );
};

export default ProjectOrders;
