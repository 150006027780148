import React, { useState, createContext, useEffect } from "react";
import jwt_decode from "jwt-decode";
import axios from "axios";

const REACT_APP_EZORDER_API = process.env.REACT_APP_EZORDER_API;

export const AuthContext = createContext();

export const AuthProvider = (props) => {
  const [authToken, setAuthToken] = useState(null);
  const [decoded, setDecoded] = useState(null);
  const [role, setRole] = useState(null);

  useEffect(() => {
    if (authToken === null) {
      getAuthenticationFromLocalStorage();
    }
  }, []);

  const getAuthenticationFromLocalStorage = () => {
    // check if auth exists in state
    // if not, check local storage for jwt and decode
    // decode
    // return
    try {
      const token = localStorage.getItem("CSMAZ_APP_TOKEN");
      //const token = localStorage.getItem("accessToken");
      var decoded = jwt_decode(token);
      setAuthToken(token);
      setDecoded(decoded);
      setRole(decoded.role);
    } catch (error) {
      console.log("No token in localstorage");
    }
  };

  const setAuthentication = (token) => {
    var decoded = jwt_decode(token);
    setAuthToken(token);
    setDecoded(decoded);
    setRole(decoded.role);

    localStorage.setItem("CSMAZ_APP_TOKEN", token);
    //localStorage.setItem("accessToken", token);
  };

  const signOut = () => {
    setAuthToken(null);
    setRole(null);
    setDecoded(null);
    window.localStorage.clear();
  };

  // This is pretty bad placement
  // This should be in its own context
  let ezorder = axios.create({
    baseURL: REACT_APP_EZORDER_API
      ? REACT_APP_EZORDER_API
      : "http://localhost:8080",
  });
  ezorder.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401 || error.response.status === 403) {
        signOut();
      }
      return error;
    }
  );

  return (
    <AuthContext.Provider
      value={{
        setAuthentication,
        authToken,
        role,
        signOut,
        decoded,
        ezorder,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};
