import {
  Box,
  Card,
  Grid,
  IconButton,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const Company = ({ details }) => {
  const [expanded, setExpanded] = useState(true);

  if (!details || details.type === "GUEST") {
    return null;
  } else {
    return (
      <Box sx={{ mt: "20px", mb: "20px" }}>
        {expanded ? (
          <Paper elevation={3} sx={{ padding: "2px 15px 15px 15px" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Typography variant="h6" sx={{ mb: "15px" }}>
                Company: {details.company.name}
              </Typography>

              <IconButton onClick={() => setExpanded(false)}>
                <ExpandLessIcon />
              </IconButton>
            </Box>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <Card
                  style={{
                    height: "100%",
                    padding: "16px",
                  }}
                >
                  <Typography variant="h6" sx={{ mb: "8xp", fontSize: 18 }}>
                    Contact
                  </Typography>
                  <Typography>
                    Contact Name: {details.company.contactName}
                  </Typography>
                  <Typography>
                    Contact Email: {details.company.contactPhone}
                  </Typography>
                  <Typography>
                    Contact Phone: {details.company.contactEmail}
                  </Typography>
                </Card>
              </Grid>
              <Grid item md={6}>
                <Card
                  style={{
                    height: "100%",
                    padding: "16px",
                  }}
                >
                  <Typography variant="h6" sx={{ mb: "8xp", fontSize: 18 }}>
                    Owner
                  </Typography>
                  <Typography>
                    Name: {details.company.owner.fullName}
                  </Typography>
                  <Typography>Email: {details.company.owner.email}</Typography>
                </Card>
              </Grid>
            </Grid>
          </Paper>
        ) : (
          <Paper elevation={3} sx={{ padding: "2px 15px 15px 15px" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="h6" sx={{ mt: "auto" }}>
                Company: {details.company.name}
              </Typography>

              <IconButton sx={{ mt: "auto" }} onClick={() => setExpanded(true)}>
                <ExpandMoreIcon />
              </IconButton>
            </Box>
          </Paper>
        )}
      </Box>
    );
  }
};

export default Company;
