import React, { useContext, useEffect, useState } from "react";
import usePageName from "../../../hocs/usePageName";
import {
  Box,
  Button,
  Card,
  TextField,
  Typography,
  Checkbox,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { TimePicker, DatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { AuthContext } from "../../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import TimeCardForm from "./TimeCardForm";

const CreateNewTimeCard = () => {
  usePageName({ heading: "Create New Time Card" });
  const navigate = useNavigate();
  const { authToken, ezorder } = useContext(AuthContext);
  const [date, setDate] = useState(new Date());
  const [timeIn, setTimeIn] = useState(
    new Date(new Date().setHours(7, 0, 0, 0))
  );
  const [loading, setLoading] = useState(false);

  const [timeOut, setTimeOut] = useState(
    new Date(new Date().setHours(15, 0, 0, 0))
  );
  const [isNoWork, setIsNoWork] = useState(false);
  const [notes, setNotes] = useState("");
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [showTimeInPicker, setShowTimeInPicker] = useState(false);
  const [showTimeOutPicker, setShowTimeOutPicker] = useState(false);

  const createTimeCard = async (timeCard) => {
    console.log("Create Time Card", timeCard);
    try {
      setLoading(true);
      const response = await ezorder.post(`/shop/crew/time-cards`, timeCard, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      console.log("response", response.data);
      navigate("/time-cards");
    } catch (error) {
      console.log("ERROR", error);
      if (error.response) {
        alert(error.response.data.error);
      } else if (error.message) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        alert(error.message);
      } else {
        alert("Something went wrong");
      }
      console.log(error.config);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      <TimeCardForm
        onSubmit={(newTimeCard) => {
          createTimeCard(newTimeCard);
        }}
        loading={loading}
      />
    </Box>
  );
};

export default CreateNewTimeCard;
