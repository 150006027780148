import {
  TextField,
  Typography,
  Box,
  MenuItem,
  Button,
  IconButton,
  DialogActions,
  DialogTitle,
  DialogContent,
  useMediaQuery,
  Card,
  Paper,
  Fab,
  ListItem,
  Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddProductModal from "../AddProductModal";
import { useState, useContext, useEffect } from "react";
import { MobileOrderContext } from "../../../../../../context/MobileOrderContext";
import usePanelSystems from "../../../../../../hooks/usePanelSystems";
import { useTheme } from "@emotion/react";
import { Link } from "react-router-dom";
import TextFieldDebounced from "../../../../../../components/TextFieldDebounced";

const Panel = ({
  showSaving,
  isUpdating,
  isProductSelected,
  isAutoSaving,
  canUpdate,
  open,
  handleClose,
  handleNext,
  hasNext,
}) => {
  const theme = useTheme();
  const { panelSystems, getPanelSystems, refreshing } = usePanelSystems();

  const { panel, panelDispatch, orderDispatch } =
    useContext(MobileOrderContext);

  const [cut, setCut] = useState({
    name: "",
    quantity: 0,
    feet: 0,
    inches: 0,
  });

  const handleDetailsChange = (key) => (e) => {
    console.log("handleDetailsChange", { type: key, payload: e.target.value });
    panelDispatch({ type: key, payload: e.target.value });
  };

  const getPanelSystemIndexByName = (panelSystemDescription) => {
    let i;
    for (i = 0; i < panelSystems.length; i++) {
      let system = panelSystems[i];
      if (system.description === panelSystemDescription) return i;
    }
    return 0;
  };

  const getTypeOptions = (panelSystemDescription) => {
    return panelSystems.find(
      (system) => system.description === panelSystemDescription
    ).panelTypes;
  };

  const getWidthOptions = (panelSystemDescription, panelTypeDescription) => {
    let panelTypes = getTypeOptions(panelSystemDescription);
    let i;
    for (i = 0; i < panelTypes.length; i++) {
      let panelType = panelTypes[i];
      if (panelType.description === panelTypeDescription)
        return panelType.panelWidths;
    }
  };

  const handleSystemSelect = (systemIndex) => {
    const system = panelSystems[systemIndex];
    const defaultPanelType = system.panelTypes[0];
    // const defaultPanelWidth = defaultPanelType.panelWidths[0]; // TODO: this value is a string, not a number
    // panelWidths is an array of strings
    let defaultPanelWidth = parseFloat(defaultPanelType.panelWidths[0]);

    // IF "Flush Wall, SL-175, Striated With Clip Relief" then default Panel Width to 18
    if (defaultPanelType.description == "SL-175, Striated With Clip Relief") {
      if (defaultPanelType.panelWidths.includes("18")) defaultPanelWidth = 18;
    }

    const updatedPanel = {
      panelSystem: system.description,
      panelType: defaultPanelType.description,
      panelWidth: defaultPanelWidth,
      panelProfile: "",
    };
    panelDispatch({ type: "fill details", payload: updatedPanel });
    // setPanel((prev) => ({ ...prev, ...updatedPanel }));
  };

  const handleTypeSelect = (panelSystem, systemType) => {
    let widthOptions = getWidthOptions(panelSystem, systemType);

    let defaultPanelWidth = parseFloat(widthOptions[0]);
    // IF "Flush Wall, SL-175, Striated With Clip Relief" then default Panel Width to 18
    if (systemType == "SL-175, Striated With Clip Relief") {
      if (widthOptions.includes("18")) defaultPanelWidth = 18;
    }

    const updatedPanel = {
      panelSystem: panelSystem,
      panelType: systemType,
      panelWidth: defaultPanelWidth,
      panelProfile: "",
    };
    panelDispatch({ type: "fill details", payload: updatedPanel });
  };

  const addCut = () => {
    let newCut = { name: "", quantity: 1, feet: 0, inches: 0 };
    panelDispatch({ type: "add cut", payload: newCut });
  };

  const editCut = (index) => (key) => (e) => {
    let panelCutsCopy = panel.cuts;
    let cutCopy = panelCutsCopy[index];
    let val = e.target.value;
    // if (key == "quantity" || key == "feet") {
    //   val = Math.round(val);
    // }
    // if (key == "inches") {
    //   val = toThreeDecimals(val);
    //   if (val < 0) val = 0;
    //   // if (val >= 12) val = 0;
    // }
    cutCopy[key] = val;
    panelDispatch({
      type: "edit cut",
      payload: { index: index, cut: cutCopy },
    });
  };

  const removeCut = (cutIndex) => () => {
    panelDispatch({ type: "remove cut", payload: cutIndex });
  };

  const addOrderItem = (item) => {
    // if (!item.cuts.length > 0) return;
    orderDispatch({ type: "add order item", payload: item });
  };

  useEffect(() => {
    if (isProductSelected && canUpdate && isAutoSaving) {
      // TODO: auto save on every change
      addOrderItem(panel);
    }
  }, [isProductSelected, panel]);

  if (panelSystems.length == 0) return null;
  return (
    <>
      <AddProductModal
        showSaving={showSaving}
        isUpdating={isUpdating}
        open={open}
        handleClose={() => {
          addOrderItem(panel);
          handleClose();
        }}
      >
        <DialogTitle>
          Panel{" "}
          <Link
            style={{ color: theme.palette.primary.dark }}
            target="_blank"
            rel="noopener noreferrer"
            to="/panel-info"
          >
            <Button
              style={{ padding: 0, maxWidth: "10px" }}
              sx={{ padding: 0 }}
            >
              Info
            </Button>
          </Link>
        </DialogTitle>
        <DialogContent
          sx={{
            gap: "1rem",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <TextField
            sx={{ marginTop: "8px" }}
            size="small"
            disabled={!canUpdate}
            fullWidth
            select
            label="Panel System"
            value={getPanelSystemIndexByName(panel.panelSystem)}
            onChange={(e) => handleSystemSelect(e.target.value)}
          >
            {panelSystems?.map((sys, index) => (
              <MenuItem key={sys.description} value={index}>
                {sys.description}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            size="small"
            disabled={!canUpdate}
            sx={{}}
            fullWidth
            select
            label="Panel Type"
            value={panel.panelType}
            onChange={(e) => {
              handleTypeSelect(panel.panelSystem, e.target.value);
            }}
          >
            {getTypeOptions(panel.panelSystem).map((type) => (
              <MenuItem key={type.description} value={type.description}>
                {type.description}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            size="small"
            disabled={!canUpdate || !panel.panelSystem}
            sx={{}}
            fullWidth
            select
            label="Panel Width"
            value={panel.panelWidth}
            onChange={handleDetailsChange("panelWidth")}
          >
            {getWidthOptions(panel.panelSystem, panel.panelType)?.map(
              (width) => (
                <MenuItem value={Number(width)} key={width}>
                  {width}
                </MenuItem>
              )
            )}
          </TextField>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "1rem",
            }}
          >
            <Typography variant="h7">Add Cut List</Typography>
            {canUpdate && (
              <Button
                sx={{ borderRadius: "17px" }}
                variant="contained"
                color="success"
                onClick={addCut}
              >
                + Add
              </Button>
            )}
          </Box>

          <Box
            sx={{
              minHeight: "200px",
              flex: 1, // able to scroll inside flexbox
              overflowY: "scroll", // scrollable box
              gap: "1rem",
            }}
          >
            {panel.cuts.map((cut, index) => (
              <CutForm
                canUpdate={canUpdate}
                key={index}
                name={cut.name}
                quantity={cut.quantity}
                feet={cut.feet}
                inches={cut.inches}
                //readOnly={true}
                handleChange={editCut(index)}
                handleRemove={removeCut(index)}
              />
            ))}
          </Box>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => {
              addOrderItem(panel);
              handleClose();
            }}
          >
            {/* {canUpdate && isAutoSaving ? "Save & Close" : "Close"} */}
            Close
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              addOrderItem(panel);
              handleNext();
            }}
          >
            {hasNext
              ? "Next"
              : canUpdate && isAutoSaving
              ? "Save & Exit"
              : "Exit"}
          </Button>
        </DialogActions>
      </AddProductModal>
    </>
  );
};
export default Panel;

function CutForm({
  canUpdate,
  name,
  quantity,
  feet,
  inches,
  readOnly = false,
  handleChange,
  onAdd,
  handleRemove,
}) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  if (isSmallScreen) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "8px",
        }}
      >
        <Box
          sx={{
            flex: 1,
            padding: "2px 16px 16px 16px",
            borderColor: "#444",
            borderWidth: "1px",
            borderStyle: "solid",
            borderRadius: "4px",
          }}
        >
          <Box>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <TextFieldDebounced
                autoFocus={canUpdate}
                disabled={!canUpdate}
                onFocus={(e) => e.target.select()}
                id="name-input"
                variant={readOnly ? "standard" : "standard"}
                size="small"
                label="name"
                sx={{ flex: 1 }}
                InputProps={{
                  readOnly: readOnly,
                }}
                value={name}
                onChange={handleChange("name")}
              />
            </Box>
            <Box
              sx={{ display: "flex", flexDirection: "row", marginTop: "8px" }}
            >
              <TextFieldDebounced
                disabled={!canUpdate}
                onFocus={(e) => e.target.select()}
                type="number"
                onWheel={(event) => {
                  event.preventDefault();
                }}
                id="qty-input"
                variant={readOnly ? "standard" : "standard"}
                size="small"
                label="qty"
                sx={{ flex: 1 }}
                InputProps={{
                  readOnly: readOnly,
                }}
                value={quantity}
                onChange={handleChange("quantity")}
              />
              <TextFieldDebounced
                disabled={!canUpdate}
                onFocus={(e) => e.target.select()}
                type="number"
                onWheel={(event) => {
                  event.preventDefault();
                }}
                variant={readOnly ? "standard" : "standard"}
                size="small"
                label="feet"
                sx={{ marginLeft: "4px", flex: 1 }}
                InputProps={{
                  readOnly: readOnly,
                }}
                value={feet}
                onChange={handleChange("feet")}
              />
              <TextFieldDebounced
                disabled={!canUpdate}
                onFocus={(e) => e.target.select()}
                type="number"
                onWheel={(event) => {
                  event.preventDefault();
                }}
                variant={readOnly ? "standard" : "standard"}
                size="small"
                label="inches"
                sx={{ marginLeft: "4px", flex: 1 }}
                InputProps={{
                  readOnly: readOnly,
                }}
                value={inches}
                onChange={handleChange("inches")}
              />
            </Box>
          </Box>
        </Box>
        {canUpdate && (
          <IconButton size="small" onClick={handleRemove}>
            <CloseIcon fontSize="small" />
          </IconButton>
        )}
      </Box>
    );
  } else {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          marginTop: "16px",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <TextField
            autoFocus={canUpdate}
            disabled={!canUpdate}
            onFocus={(e) => e.target.select()}
            id="name-input"
            variant={readOnly ? "standard" : "outlined"}
            size="small"
            label="name"
            sx={{ flex: 2 }}
            InputProps={{
              readOnly: readOnly,
            }}
            value={name}
            onChange={handleChange("name")}
          />
          <TextField
            disabled={!canUpdate}
            onFocus={(e) => e.target.select()}
            type="number"
            onWheel={(event) => {
              event.preventDefault();
            }}
            id="qty-input"
            variant={readOnly ? "standard" : "outlined"}
            size="small"
            label="qty"
            sx={{ marginLeft: "4px", flex: 1 }}
            InputProps={{
              readOnly: readOnly,
            }}
            value={quantity}
            onChange={handleChange("quantity")}
          />
          <TextField
            disabled={!canUpdate}
            onFocus={(e) => e.target.select()}
            type="number"
            onWheel={(event) => {
              event.preventDefault();
            }}
            variant={readOnly ? "standard" : "outlined"}
            size="small"
            label="feet"
            sx={{ marginLeft: "4px", flex: 1 }}
            InputProps={{
              readOnly: readOnly,
            }}
            value={feet}
            onChange={handleChange("feet")}
          />
          <TextField
            disabled={!canUpdate}
            onFocus={(e) => e.target.select()}
            type="number"
            onWheel={(event) => {
              event.preventDefault();
            }}
            variant={readOnly ? "standard" : "outlined"}
            size="small"
            label="inches"
            sx={{ marginLeft: "4px", flex: 1 }}
            InputProps={{
              readOnly: readOnly,
            }}
            value={inches}
            onChange={handleChange("inches")}
          />
          {canUpdate && (
            <IconButton size="small" onClick={handleRemove}>
              <CloseIcon fontSize="small" />
            </IconButton>
          )}
        </Box>
      </Box>
    );
  }
}
