import { Box } from "@mui/material";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useState } from "react";

// import "react-big-calendar/lib/sass/styles";
// import "react-big-calendar/lib/addons/dragAndDrop/styles"; // if using DnD

const localizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop(Calendar);

const TestCalendar = (props) => {
  const [events, setEvents] = useState([
    {
      start: moment().toDate(),
      end: moment().add(1, "days").toDate(),
      title: "Some title 1",
    },
    {
      start: moment().toDate(),
      end: moment().add(30, "days").toDate(),
      title: "Some title 2",
    },
    // {
    //   start: moment().toDate(),
    //   end: moment().add(1, "days").toDate(),
    //   title: "Some title 3",
    // },
    // {
    //   start: moment().toDate(),
    //   end: moment().add(1, "days").toDate(),
    //   title: "Some title 4",
    // },
    // {
    //   start: moment().toDate(),
    //   end: moment().add(1, "days").toDate(),
    //   title: "Some title 5",
    // },
    // {
    //   start: moment().toDate(),
    //   end: moment().add(1, "days").toDate(),
    //   title: "Some title 6",
    // },
    // {
    //   start: moment().toDate(),
    //   end: moment().add(1, "days").toDate(),
    //   title: "Some title 7",
    // },
    // {
    //   start: moment().toDate(),
    //   end: moment().add(1, "days").toDate(),
    //   title: "Some title 8",
    // },
    // {
    //   start: moment().toDate(),
    //   end: moment().add(1, "days").toDate(),
    //   title: "Some title 9",
    // },
    // {
    //   start: moment().toDate(),
    //   end: moment().add(1, "days").toDate(),
    //   title: "Some title 10",
    // },
    // {
    //   start: moment().toDate(),
    //   end: moment().add(1, "days").toDate(),
    //   title: "Some title 11",
    // },
    // {
    //   start: moment().toDate(),
    //   end: moment().add(1, "days").toDate(),
    //   title: "Some title 12",
    // },
  ]);

  const onEventResize = (data) => {
    const { start, end } = data;

    setEvents((prev) => {
      let newEvents = [...prev];
      newEvents[0].start = start;
      newEvents[0].end = end;
      return newEvents;
    });
  };

  const onEventDrop = (data) => {
    console.log(data);
  };

  return (
    <Box sx={{ height: "95vh" }}>
      <DnDCalendar
        defaultDate={moment().toDate()}
        defaultView="month"
        events={events}
        localizer={localizer}
        onEventDrop={onEventDrop}
        onEventResize={onEventResize}
        resizable
        style={{ height: "100vh" }}
      />
    </Box>
  );
};

export default TestCalendar;
