import {
  Box,
  TextField,
  Button,
  MenuItem,
  Typography,
  Tooltip,
  IconButton,
  FormControlLabel,
  Switch,
  useMediaQuery,
} from "@mui/material";
import { useEffect, useState, useMemo } from "react";
import usePanelSystems from "../../hooks/usePanelSystems";
import {
  toTwoDecimals,
  toThreeDecimals,
} from "../NewDetail/calculations/utils";
import ColorSelect from "./ColorSelect";
import AdornedInput from "../AdornedInput";
import { useTheme } from "@emotion/react";

const SquareFootPricingForm = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { panelSystems, getPanelSystems, refreshing } = usePanelSystems();
  const [details, setDetails] = useState({
    totalCost: 0,
    objectType: "Panel",
    panelSystem: "Standing Seam",
    panelType: "SL-175, Striated With Clip Relief",
    panelTakeUp: 6,
    panelWidth: 18,
    coilSizeNeeded: 24,
    coilSquareFeet: null,
    coilSquareFeetWithDrop: null,
    panelMasterCoilWidth: null,
    totalPanelAreaCoverage: null,
    panelProfile: "",
    cuts: [],
  });
  const [materialDetails, setMaterialDetails] = useState({
    // manufacturer: "CMG",
    // gauge: "24",
    // color: "Aged Copper",
    flatSheetSize: 48,
    flatSheetCost: 0,
    flatSheetCostOriginal: 0,
    flatSheetLength: 120,
    isCustomMaterial: false,

    // coilCostPerSqFt: orderDetails.coilCostPerSqFt,
    // coilCostPerSqFtOriginal: orderDetails.coilCostPerSqFtOriginal,
    // galvFlatSheetCost: orderDetails.galvFlatSheetCost,
  });
  const [grossProfitPercentage, setGrossProfitPercentage] = useState(35.0);
  const [calculations, setCalculations] = useState({
    sfPrice: 0,
    panels: {
      materialCost: 0,
      laborCost: 0,
      totalHardCost: 0,
    },
    panelCoverage: 0,
    trimAndFlashing: {
      qty: 100, // pc
      stretchOut: 8, // inches
      hits: 6,
      materialCost: 0,
      laborCost: 0,
      totalHardCost: 0,
    },
    total: {
      materialCost: 0,
      laborCost: 0,
      totalHardCost: 0,
    },
  });

  // Constants
  const PANEL_RUN_RATE = 150; // lf/mh
  const LABOR_WAGE_RATE = 40; // $ / hr
  const LABOR = 3; // hits / min

  const [isIncludeTrimAndFlashing, setIsIncludeTrimAndFlashing] =
    useState(false);
  // New
  const getPanelSystemIndexByName = (panelSystemDescription) => {
    let i;
    for (i = 0; i < panelSystems.length; i++) {
      let system = panelSystems[i];
      if (system.description === panelSystemDescription) return i;
    }
    return 0;
  };

  const getTypeOptions = (panelSystemDescription) => {
    return panelSystems.find(
      (system) => system.description === panelSystemDescription
    ).panelTypes;
  };

  const getWidthOptions = (panelSystemDescription, panelTypeDescription) => {
    let panelTypes = getTypeOptions(panelSystemDescription);
    let i;
    for (i = 0; i < panelTypes.length; i++) {
      let panelType = panelTypes[i];
      if (panelType.description === panelTypeDescription)
        return panelType.panelWidths;
    }
  };

  const handleSystemSelect = (systemIndex) => {
    const system = panelSystems[systemIndex];
    const defaultPanelType = system.panelTypes[0];
    // panelWidths is an array of strings
    let defaultPanelWidth = parseFloat(defaultPanelType.panelWidths[0]);

    // IF "Flush Wall, SL-175, Striated With Clip Relief" then default Panel Width to 18
    if (defaultPanelType.description == "SL-175, Striated With Clip Relief") {
      if (defaultPanelType.panelWidths.includes("18")) defaultPanelWidth = 18;
    }
    setDetails((prev) => ({
      ...prev,
      panelSystem: system.description,
      panelType: defaultPanelType.description,
      panelTakeUp: defaultPanelType.panelTakeUp,
      panelWidth: defaultPanelWidth,
      profile: "",
    }));
  };

  const handleTypeSelect = (panelSystem, systemType) => {
    // // Use reducer to update default width selected
    let widthOptions = getWidthOptions(panelSystem, systemType);
    let typeOptions = getTypeOptions(panelSystem);
    let selectePaneltype = typeOptions.find((t) => t.description == systemType);

    let defaultPanelWidth = parseFloat(widthOptions[0]);
    // IF "Flush Wall, SL-175, Striated With Clip Relief" then default Panel Width to 18
    if (systemType == "SL-175, Striated With Clip Relief") {
      if (widthOptions.includes("18")) defaultPanelWidth = 18;
    }
    setDetails((prev) => ({
      ...prev,
      panelType: systemType,
      panelTakeUp: selectePaneltype.panelTakeUp,
      panelWidth: defaultPanelWidth,
      coilSizeNeeded: selectePaneltype.panelTakeUp + defaultPanelWidth,
    }));
  };

  const handleInitial = (panelSystem, systemType) => {
    // // Use reducer to update default width selected
    //let widthOptions = getWidthOptions(panelSystem, systemType);

    let typeOptions = getTypeOptions(panelSystem);
    let selectePaneltype = typeOptions.find((t) => t.description == systemType);

    setDetails((prev) => ({
      ...prev,
      panelType: systemType,
      panelTakeUp: selectePaneltype.panelTakeUp,
      coilSizeNeeded: selectePaneltype.panelTakeUp + prev.panelWidth,
    }));
  };

  const calculateSfPrice = () => {
    const PANEL_QTY = 100; // ea
    const PANEL_FEET = 30;
    const PANEL_INCHES = 0;

    const TRIM_QTY = 100; // pc
    const TRIM_STRETCHOUT = 8; // inches
    const TRIM_HITS = 6;

    const PANEL_TOTAL_FEET = toThreeDecimals(
      PANEL_QTY * (PANEL_FEET + PANEL_INCHES / 12)
    );
    const panelCoverage = toThreeDecimals(
      PANEL_TOTAL_FEET * (details.panelWidth / 12)
    );
    const panelMaterialCost = toThreeDecimals(
      PANEL_TOTAL_FEET *
        (details.coilSizeNeeded / 12) *
        materialDetails.coilCostPerSqFt
    );
    const panelLaborCost = toThreeDecimals(
      (PANEL_TOTAL_FEET / PANEL_RUN_RATE) * LABOR_WAGE_RATE
    );
    const panelTotalHardCost = toThreeDecimals(
      panelMaterialCost + panelLaborCost
    );

    let trimMaterialCost = 0;
    let trimLaborCost = 0;
    let trimTotalHardCost = 0;
    if (isIncludeTrimAndFlashing) {
      trimMaterialCost = toThreeDecimals(
        (TRIM_QTY / (48 / TRIM_STRETCHOUT)) *
          (40 * materialDetails.coilCostPerSqFt)
      );

      trimLaborCost = toThreeDecimals(
        ((TRIM_QTY * TRIM_HITS) / LABOR / 60) * LABOR_WAGE_RATE
      );
      trimTotalHardCost = toThreeDecimals(trimMaterialCost + trimLaborCost);
    }

    const TOTAL_MATERIAL_COST = toThreeDecimals(
      panelMaterialCost + trimMaterialCost
    );
    const TOTAL_LABOR_COST = toThreeDecimals(panelLaborCost + trimLaborCost);
    const TOTAL_HARD_COST = toThreeDecimals(
      panelTotalHardCost + trimTotalHardCost
    );

    const SF_PRICE = toTwoDecimals(
      TOTAL_HARD_COST / (1 - grossProfitPercentage / 100.0) / panelCoverage
    );

    let calc = {
      sfPrice: SF_PRICE,
      panels: {
        materialCost: panelMaterialCost,
        laborCost: panelLaborCost,
        totalHardCost: panelTotalHardCost,
      },
      panelCoverage: panelCoverage,
      trimAndFlashing: {
        materialCost: trimMaterialCost,
        laborCost: trimLaborCost,
        totalHardCost: trimTotalHardCost,
      },
      total: {
        materialCost: TOTAL_MATERIAL_COST,
        laborCost: TOTAL_LABOR_COST,
        totalHardCost: TOTAL_HARD_COST,
      },
    };
    console.log("CALCULATIONS", calc);

    setCalculations(calc);
  };

  useEffect(() => {
    if (
      details.calculations &&
      details.panelTakeUp == undefined &&
      details.panelTakeUp == null &&
      panelSystems &&
      panelSystems.length > 0
    ) {
      handleInitial(details.panelSystem, details.panelType);
    }
  }, [details, panelSystems]);

  useEffect(() => {
    if (details && materialDetails) calculateSfPrice();
  }, [
    details,
    materialDetails,
    grossProfitPercentage,
    isIncludeTrimAndFlashing,
  ]);

  if (!panelSystems || panelSystems.length == 0) return null;

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      <h3>Material Pricing</h3>
      <ColorSelect
        materialDetails={materialDetails}
        setMaterialDetails={setMaterialDetails}
      />

      <h3>Panel</h3>
      <Box
        sx={{
          display: "flex",
          flexDirection: isSmallScreen ? "column" : "row",
          gap: "1rem",
        }}
      >
        <TextField
          select
          variant="outlined"
          label="Panel System"
          id="system"
          name="Panel System"
          size="small"
          fullWidth
          value={getPanelSystemIndexByName(details.panelSystem)}
          onChange={(e) => handleSystemSelect(e.target.value)}
        >
          {panelSystems?.map((sys, index) => (
            <MenuItem key={sys.description} value={index}>
              {sys.description}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          select
          variant="outlined"
          label="Panel Type"
          id="type"
          name="Panel Type"
          size="small"
          fullWidth
          value={details.panelType}
          onChange={(e) => {
            handleTypeSelect(details.panelSystem, e.target.value);
          }}
        >
          {getTypeOptions(details.panelSystem).map((type) => (
            <MenuItem key={type.description} value={type.description}>
              {type.description}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          select
          variant="outlined"
          label="Panel Width"
          id="width"
          name="Panel Width"
          size="small"
          fullWidth
          value={details.panelWidth}
          onChange={(e) => {
            setDetails((prev) => ({
              ...prev,
              panelWidth: e.target.value,
              coilSizeNeeded: prev.panelTakeUp + e.target.value,
            }));
          }}
        >
          {getWidthOptions(details.panelSystem, details.panelType).map(
            (width) => (
              <MenuItem value={parseInt(width)} key={width}>
                {width}
              </MenuItem>
            )
          )}
        </TextField>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: isSmallScreen ? "column" : "row",
          gap: "1rem",
        }}
      >
        <AdornedInput
          type="number"
          onWheel={(event) => {
            event.preventDefault();
          }}
          onFocus={(e) => e.target.select()}
          label="Takeup"
          value={details.panelTakeUp}
          // onChange={handleChange("coil_size_needed")}
          // isOveridden={overrides?.coil_size_needed}
          // handleReset={resetOverride("coil_size_needed")}
          inputProps={{ readOnly: true }}
          disabled
        />
        <AdornedInput
          type="number"
          onWheel={(event) => {
            event.preventDefault();
          }}
          onFocus={(e) => e.target.select()}
          label="Coil Size Needed"
          value={details.coilSizeNeeded}
          // onChange={handleChange("coil_size_needed")}
          // isOveridden={overrides?.coil_size_needed}
          // handleReset={resetOverride("coil_size_needed")}
          inputProps={{ readOnly: true }}
          disabled
        />
        <AdornedInput
          type="number"
          onWheel={(event) => {
            event.preventDefault();
          }}
          onFocus={(e) => e.target.select()}
          label="Panel Run Rate lf/mh"
          value={PANEL_RUN_RATE}
          // onChange={handleChange("coil_size_needed")}
          // isOveridden={overrides?.coil_size_needed}
          // handleReset={resetOverride("coil_size_needed")}
          inputProps={{ readOnly: true }}
          disabled
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: isSmallScreen ? "column" : "row",
          gap: "1rem",
        }}
      >
        <AdornedInput
          type="number"
          onWheel={(event) => {
            event.preventDefault();
          }}
          onFocus={(e) => e.target.select()}
          label="Labor Wage Rate ($/hr)"
          value={LABOR_WAGE_RATE}
          // onChange={handleChange("coil_size_needed")}
          // isOveridden={overrides?.coil_size_needed}
          // handleReset={resetOverride("coil_size_needed")}
          inputProps={{ readOnly: true }}
          disabled
        />
        <AdornedInput
          type="number"
          onWheel={(event) => {
            event.preventDefault();
          }}
          onFocus={(e) => e.target.select()}
          label="Labor (hits / min)"
          value={LABOR}
          // onChange={handleChange("coil_size_needed")}
          // isOveridden={overrides?.coil_size_needed}
          // handleReset={resetOverride("coil_size_needed")}
          inputProps={{ readOnly: true }}
          disabled
        />
      </Box>

      <h3>Trim & Flashing</h3>
      <FormControlLabel
        control={
          <Switch
            checked={isIncludeTrimAndFlashing}
            onChange={(e) => {
              setIsIncludeTrimAndFlashing(e.target.checked);
            }}
          />
        }
        label="Include Trim & Flashing"
      />
      <h3>Gross Profit</h3>
      <AdornedInput
        type="number"
        onWheel={(event) => {
          event.preventDefault();
        }}
        onFocus={(e) => e.target.select()}
        label="GP %"
        value={grossProfitPercentage}
        onChange={(e) => {
          setGrossProfitPercentage(toThreeDecimals(e.target.value));
        }}
        // isOveridden={overrides?.gross_profit_margin}
        // handleReset={resetOverride("gross_profit_margin")}
      />
      {/* {details?.calculations && (
        <PanelCalculations
          calculations={details?.calculations}
          variables={details?.variables}
          overrides={details?.overrides}
          handleChange={handleCalcOveride}
          resetOverride={resetOverrideValue}
        />
      )} */}
      <h2>SF PRICE</h2>
      <AdornedInput
        type="number"
        onWheel={(event) => {
          event.preventDefault();
        }}
        onFocus={(e) => e.target.select()}
        label="SF PRICE"
        value={calculations.sfPrice}
        inputProps={{ readOnly: true }}
        symbol="$"
        position="start"
      />
    </Box>
  );
};
export default SquareFootPricingForm;
