import calculateTrim from "../components/NewDetail/calculations/trim";
import calculateSplicePlate from "../components/NewDetail/calculations/splicePlate";
import caclculateBenchwork from "../components/NewDetail/calculations/benchWork";

export const updateOrderItemsObject =
  (state, objectType, newValue) => (key) => {
    let newArr = [...state.items];
    const index = newArr.findIndex((obj) => obj.objectType === objectType);
    console.log("THE INDEX", index, key);
    newArr[index][key] = newValue;
    console.log(newArr[index][key]);
    return newArr;
  };

const deepEqual = (object1, object2) => {
  const isObject = (object) => {
    return object != null && typeof object === "object";
  };
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (
      (areObjects && !deepEqual(val1, val2)) ||
      (!areObjects && val1 !== val2)
    ) {
      return false;
    }
  }
  return true;
};

export const findAndReplace = (array, object) => {
  const matchIndex = array.findIndex((item) => {
    if (item.id) {
      return object.id === item.id;
    } else if (item.temp_id) {
      return object.temp_id === item.temp_id;
    }
  });

  if (matchIndex > -1) {
    array.splice(matchIndex, 1, object);
    return array;
  } else {
    console.log("NO MATCH FOUND", "FOR", object);
    return array;
  }
};

export const getPiecesKey = (objectType) => {
  return objectType === "TrimAndFlashing"
    ? "trimPieces"
    : objectType === "CopingCap"
    ? "capPieces"
    : objectType === "CopingCapCleat"
    ? "cleatPieces"
    : objectType === "Panel"
    ? "cuts"
    : objectType === "BenchWork"
    ? "benchWorkPieces"
    : objectType === "SplicePlate"
    ? "plates"
    : objectType === "Accessory"
    ? "items"
    : null;
};
export const updateOrderItemPiece = (orderItems, objectType, updateObj) => {
  // returns the updated orderItems array
  const piecesKey = getPiecesKey(objectType);
  const itemIndex = orderItems.findIndex(
    (obj) => obj.objectType === objectType
  );
  const currentPieces = orderItems[itemIndex][piecesKey];
  const updatedPieces = findAndReplace(currentPieces, updateObj);
  // console.log(updatedPieces)
  orderItems[itemIndex][piecesKey] = updatedPieces;
  return orderItems;
};

export const toDecimal = (digits) => (num) =>
  parseFloat(parseFloat(num).toFixed(digits));

export const dollar = toDecimal(2);
export const measurement = toDecimal(3);

export const generateId = () => parseInt(Math.random().toString().substring(2));

export const withId = (object) => {
  const id = generateId();
  return { ...object, temp_id: id };
};

export const getOrderItemDetails = (objectType) => (state) => {
  const item = state.items.find((item) => item.objectType === objectType);
  return item;
};

export const getPanelDetails = getOrderItemDetails("Panel");
export const getTrimDetails = getOrderItemDetails("TrimAndFlashing");
export const getBenchWorkDetails = getOrderItemDetails("BenchWork");
export const getCopingCapDetails = getOrderItemDetails("CopingCap");
export const getCopingCapCleatDetails = getOrderItemDetails("CopingCapCleat");
export const getSplicePlateDetails = getOrderItemDetails("SplicePlate");
export const getAccessoryDetails = getOrderItemDetails("Accessory");

export const piecesBasedOnType = (item) => {
  switch (item.objectType) {
    case "TrimAndFlashing":
      return { pieces: item.trimPieces, key: "trimPieces" };
    case "CopingCap":
      return { pieces: item.capPieces, key: "capPieces" };
    case "CopingCapCleat":
      return { pieces: item.cleatPieces, key: "cleatPieces" };
    case "SplicePlate":
      return { pieces: item.plates, key: "plates" };
    case "BenchWork":
      return { pieces: item.benchWorkPieces, key: "benchWorkPieces" };
    case "Panel":
      return { pieces: item.cuts, key: "cuts" };
    case "Accessory":
      return { pieces: item.items, key: "items" };
    default:
      break;
  }
};

export const cleanUpTempIds = (order) => {
  const updatedItems = order.items.map((item) => {
    const { pieces, key } = piecesBasedOnType(item);
    const updatedPieces = pieces.map((piece) => {
      delete piece.id;
      return piece;
    });
    return { ...item, [key]: updatedPieces };
  });
  return { ...order, items: [...updatedItems] };
};

export const addItemTotalCost = (order) => {
  const updatedItems = order.items.map((item) => {
    const itemTotalCost = order.calculations[item.objectType].totalCost;
    return { ...item, totalCost: itemTotalCost ? itemTotalCost : 0 };
  });
  return { ...order, items: [...updatedItems] };
};

export const trimCalcs =
  (state, isCleat) =>
  (trimPiece, key = null, value = null) => {
    // if (trimPiece?.variables) {
    //   console.log(
    //     "PIECE HAS NO VARIABLES OBJECT, USING THE DEFAULT VARIABLES",
    //     state.defaultVariables
    //   );
    // }
    const variables = trimPiece?.variables
      ? trimPiece.variables
      : state.defaultVariables;
    const overrides = { ...(trimPiece?.overrides ?? {}) };
    if (key) {
      overrides[key] = value;
      console.log(overrides);
    }

    const calcs = {
      calculations: calculateTrim(
        trimPiece,
        variables,
        overrides,
        state,
        isCleat
      ),
      variables: variables,
      overrides: overrides,
    };
    return calcs;
  };

export const spliceCalcs =
  (state) =>
  (splicePiece, key = null, value = null) => {
    const variables = splicePiece?.variables
      ? splicePiece.variables
      : state.defaultVariables;
    const overrides = { ...(splicePiece?.overrides ?? {}) };
    if (key) {
      overrides[key] = value;
    }

    const calcs = {
      calculations: calculateSplicePlate(
        splicePiece,
        variables,
        overrides,
        state
      ),
      variables: variables,
      overrides: overrides,
    };
    return calcs;
  };

export const benchWorkCalcs =
  (state) =>
  (benchWorkPiece, key = null, value = null) => {
    const variables = benchWorkPiece?.variables
      ? benchWorkPiece.variables
      : state.defaultVariables;
    const overrides = { ...(benchWorkPiece?.overrides ?? {}) };
    if (key) {
      overrides[key] = value;
    }

    const calcs = {
      calculations: caclculateBenchwork(
        benchWorkPiece,
        variables,
        overrides,
        state
      ),
      variables: variables,
      overrides: overrides,
    };
    return calcs;
  };
