import {
  TextField,
  Typography,
  Box,
  MenuItem,
  Button,
  IconButton,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import AddProductModal from "../AddProductModal";
import { useState, useContext, useEffect } from "react";
import { MobileOrderContext } from "../../../../../../context/MobileOrderContext";
import { Add } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import TextFieldDebounced from "../../../../../../components/TextFieldDebounced";

const SplicePlate = ({
  showSaving,
  isUpdating,
  isProductSelected,
  isAutoSaving,
  canUpdate,
  open,
  handleClose,
  handleNext,
  hasNext,
}) => {
  const { splice, spliceDispatch, orderDispatch } =
    useContext(MobileOrderContext);

  const handleSubmitPiece = (piece) => {
    spliceDispatch({ type: "add piece", payload: piece });
  };

  const handleRemove = (index) => {
    spliceDispatch({ type: "remove piece", payload: index });
  };

  const handleEditPieceQuantity = (index, quantity) => {
    spliceDispatch({ type: "edit quantity", payload: { index, quantity } });
  };

  const handleEditPieceLength = (index, length) => {
    spliceDispatch({ type: "edit length", payload: { index, length } });
  };

  const handleSaveItem = (item) => {
    // if (!item.plates.length > 0) return;
    orderDispatch({ type: "add order item", payload: item });
  };

  useEffect(() => {
    if (isProductSelected && canUpdate && isAutoSaving) {
      // TODO: auto save on every change
      handleSaveItem(splice);
    }
  }, [isProductSelected, splice]);

  return (
    <AddProductModal
      showSaving={showSaving}
      isUpdating={isUpdating}
      open={open}
      handleClose={() => {
        handleSaveItem(splice);
        handleClose();
      }}
    >
      <DialogTitle>Splice Plate</DialogTitle>
      <DialogContent sx={{ gap: "1rem" }}>
        <Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography variant="h7" sx={{ flex: 1 }}>
              Plates
            </Typography>
            {canUpdate && (
              <Button
                sx={{
                  borderRadius: "17px", // Same as extended variant of Fab
                }}
                variant="contained"
                color="success"
                onClick={() => handleSubmitPiece({ quantity: 0, length: 6 })}
              >
                + Add
              </Button>
            )}
          </Box>
          <Box sx={{ marginTop: "1rem" }}>
            {splice.plates.map((plate, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "1rem",
                  marginBottom: "8px",
                }}
              >
                <TextField
                  disabled={!canUpdate}
                  size="small"
                  fullWidth
                  select
                  label="Width"
                  value={plate.length}
                  onChange={(e) => {
                    handleEditPieceLength(index, e.target.value);
                  }}
                >
                  <MenuItem value={6}>6 in.</MenuItem>
                  <MenuItem value={8}>8 in.</MenuItem>
                  <MenuItem value={10}>10 in.</MenuItem>
                  <MenuItem value={12}>12 in.</MenuItem>
                </TextField>
                <TextFieldDebounced
                  disabled={!canUpdate}
                  onFocus={(e) => e.target.select()}
                  size="small"
                  type="number"
                  onWheel={(event) => {
                    event.preventDefault();
                  }}
                  label="Quantity"
                  value={plate.quantity}
                  onChange={(e) => {
                    handleEditPieceQuantity(index, e.target.value);
                  }}
                />
                {canUpdate && (
                  <IconButton onClick={() => handleRemove(index)}>
                    <CloseIcon />
                  </IconButton>
                )}
              </Box>
            ))}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            handleSaveItem(splice);
            handleClose();
          }}
        >
          {/* {canUpdate && isAutoSaving ? "Save & Close" : "Close"} */}
          Close
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            handleSaveItem(splice);
            handleNext();
          }}
        >
          {hasNext
            ? "Next"
            : canUpdate && isAutoSaving
            ? "Save & Exit"
            : "Exit"}
        </Button>
      </DialogActions>
    </AddProductModal>
  );
};
export default SplicePlate;
