import { useState, useContext, useEffect } from "react";
import usePageName from "../../../hocs/usePageName";
import { AuthContext } from "../../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import useTrimCatalog from "../../../hooks/useTrimCatalog";
import { Box } from "@mui/material";
import PrintCustomerCatalog from "../../../components/Catalog/PrintCatalog";

const IndustryMasterCatalog = () => {
  usePageName({ heading: "Industry Trim Catalog" });
  const navigate = useNavigate();
  const { authToken, role, decoded } = useContext(AuthContext);
  const [
    searchQuery,
    setSearchQuery,
    catalogFilter,
    setCatalogFilter,
    results,
    onRefresh,
    refreshing,
    onLoadMore,
    loadingMore,
    total,
    hasMore,
  ] = useTrimCatalog(authToken, 30, null, "master");

  return (
    <Box sx={{ heght: "100%", display: "flex", flex: 1 }}>
      <PrintCustomerCatalog items={results} />
    </Box>
  );
};
export default IndustryMasterCatalog;
