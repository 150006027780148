import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";

const Company = ({ formik, errorMessage, isAdminView }) => {
  return (
    <Box component="form" sx={{ mt: 1 }}>
      <Grid container>
        <Grid item xs={12} sm={12} padding={2}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="companyName"
            label="Company"
            name="companyName"
            value={formik.values.companyName}
            onChange={formik.handleChange}
          />
          {formik.errors.companyName ? (
            <Typography color={"red"}>{formik.errors.companyName}</Typography>
          ) : null}
          <TextField
            margin="normal"
            fullWidth
            id="companyContactName"
            label="Company Contact Name"
            name="companyContactName"
            value={formik.values.companyContactName}
            onChange={formik.handleChange}
          />
          {formik.errors.companyContactName ? (
            <div>{formik.errors.companyContactName}</div>
          ) : null}
          <TextField
            margin="normal"
            fullWidth
            id="companyContactEmail"
            label="Company Contact Email"
            name="companyContactEmail"
            value={formik.values.companyContactEmail}
            onChange={formik.handleChange}
            autoComplete="email"
          />
          {formik.errors.companyContactEmail ? (
            <div>{formik.errors.companyContactEmail}</div>
          ) : null}
          <TextField
            margin="normal"
            fullWidth
            id="companyContactPhone"
            label="Company Contact Phone"
            name="companyContactPhone"
            value={formik.values.companyContactPhone}
            onChange={formik.handleChange}
            autoComplete="phone"
          />
          {formik.errors.companyContactPhone ? (
            <div>{formik.errors.companyContactPhone}</div>
          ) : null}
          {isAdminView && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={formik.values.isVendorOrSupplier}
                  onChange={(e) => {
                    formik.setValues({
                      ...formik.values,
                      isVendorOrSupplier: e.target.checked,
                    });
                  }}
                />
              }
              label="Vendor / Supplier Ordering on Behalf of Others"
            />
          )}
        </Grid>
      </Grid>
      {errorMessage && (
        <Box>
          <Typography color={"red"}>{errorMessage}</Typography>
        </Box>
      )}
    </Box>
  );
};

export default Company;
