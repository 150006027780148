import * as React from "react";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { Alert, AlertTitle } from "@mui/material";

// const Alert = React.forwardRef(function Alert(props, ref) {
//   return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
// });

const SnackAlert = ({ open, setOpen, severity, message, title }) => {
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={10000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      key={"bottom" + "right"}
    >
      <Alert
        sx={{ bgcolor: "background.paper", width: "100%" }}
        elevation={6}
        variant="outlined" // filled, outlined, standard
        onClose={handleClose}
        severity={severity}
      >
        {title && title.length > 0 && <AlertTitle>{title}</AlertTitle>}
        {message}
      </Alert>
    </Snackbar>
  );
};

export default SnackAlert;
