import { useState, useEffect, useContext } from "react";
import {
  Box,
  Button,
  Card,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { AuthContext } from "../context/AuthContext";
import { useTheme } from "@emotion/react";

const ShopS3PresignedCardImage = (props) => {
  // Image is really just he file's Key on s3. I'm not renaming it now because we use "image" as the trim property on the backend
  const {
    s3Key,
    accessToken,
    width = 245,
    removeAspectRatio,
    size,
    disabledPreview = false,
  } = props;
  const [presignedUrl, setPresignedUrl] = useState(null);
  const { authToken, ezorder } = useContext(AuthContext);

  const [previewDialogOpen, setPreviewDialogOpen] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const createReadPresignedUrl = async (controller) => {
    try {
      let keySplit = s3Key.split("/");
      let identifier = keySplit[0];
      let resource = keySplit[1];
      let fileName = keySplit[2];

      let data = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      if (controller) {
        data.signal = controller.signal;
      }

      let requestData = {
        actionType: "READ",
        identifier,
        fileName,
        //contentType,
        resource,
      };
      const response = await ezorder.post(
        "/files/presignedUrl",
        requestData,
        data
      );
      setPresignedUrl(response.data.getUrl);
    } catch (error) {
      console.log(error);
    }
  };

  const createPresignedUrlWithAccessToken = async (controller, accessToken) => {
    try {
      let keySplit = s3Key.split("/");
      let identifier = keySplit[0];
      let resource = keySplit[1];
      let fileName = keySplit[2];

      let requestData = {
        actionType: "READ",
        fileName,
        //contentType,
        resource,
        identifier,
        token: accessToken,
      };
      const response = await ezorder.post(
        "/no-auth/files/presignedUrl",
        requestData
      );
      setPresignedUrl(response.data.getUrl);
    } catch (error) {
      throw Error("Unable to load presigned URL", { cause: error });
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    if (accessToken) {
      createPresignedUrlWithAccessToken(controller, accessToken);
    } else {
      createReadPresignedUrl(controller);
    }

    return () => {
      controller.abort(); // abort api call on unmount for cleanup
    };
  }, [s3Key, accessToken]);

  if (!presignedUrl) {
    return (
      <Box sx={[{ maxWidth: width, minWidth: width }, props.style]}>
        <Typography>No Image</Typography>
      </Box>
    );
  }
  return (
    <Card
      sx={[{ maxWidth: width, minWidth: width }, props.style]}
      // onClick={() => {
      //   window.open(presignedUrl, "_blank");
      // }}
    >
      <CardMedia
        onClick={() => {
          if (!disabledPreview) setPreviewDialogOpen(true);
        }}
        component="img"
        height={width}
        name="image"
        image={presignedUrl}
        alt="image"
      />
      <Dialog
        open={previewDialogOpen}
        fullScreen={isSmallScreen}
        maxWidth="md"
        fullWidth={true}
        onClose={() => {
          setPreviewDialogOpen(false);
        }}
      >
        <DialogTitle>Preview</DialogTitle>
        <DialogContent sx={{ flex: 1 }}>
          <CardMedia
            onClick={() => {
              window.open(presignedUrl, "_blank");
            }}
            style={{}}
            component="img"
            name="image"
            image={presignedUrl}
            alt="image"
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setPreviewDialogOpen(false);
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default ShopS3PresignedCardImage;
