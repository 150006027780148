import { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import {
  Box,
  Button,
  Card,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@emotion/react";

const S3PresignedImage = (props) => {
  // Image is really just he file's Key on s3. I'm not renaming it now because we use "image" as the trim property on the backend
  const {
    s3Key,
    removeAspectRatio,
    size,
    disabledPreview = false,
    useCard = true,
  } = props;
  const [presignedUrl, setPresignedUrl] = useState(null);
  const { authToken, ezorder } = useContext(AuthContext);
  const [previewDialogOpen, setPreviewDialogOpen] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const createReadPresignedUrl = async (controller) => {
    try {
      // console.log(s3Key);
      let keySplit = s3Key.split("/");
      let identifier = keySplit[0];
      let resource = keySplit[1];
      let fileName = keySplit[2];

      let data = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        params: {
          resource,
          fileName,
        },
      };
      if (controller) {
        data.signal = controller.signal;
      }
      // console.log("Create Presigned URL");
      let requestData = {
        actionType: "READ",
        fileName,
        //contentType,
        resource,
        identifier,
      };
      const response = await ezorder.post(
        "/files/presignedUrl",
        requestData,
        data
      );
      // console.log(response.data);
      setPresignedUrl(response.data.getUrl);
    } catch (error) {
      //console.log(error);
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    createReadPresignedUrl(controller);
    return () => {
      controller.abort(); // abort api call on unmount for cleanup
    };
  }, [s3Key]);

  if (!presignedUrl) {
    return (
      <BoxOrCard
        isCard={useCard}
        sx={{
          maxWidth: size ? size : 245,
          minWidth: size ? size : 100,
          aspectRatio: removeAspectRatio ? null : 1 / 1,
        }}
      >
        <Typography>No Image</Typography>
      </BoxOrCard>
    );
  }

  return (
    <BoxOrCard
      isCard={useCard}
      sx={{
        maxWidth: size ? size : 245,
        minWidth: size ? size : 100,
        aspectRatio: removeAspectRatio ? null : 1 / 1,
      }}
      style={props.style}
    >
      <CardMedia
        onClick={() => {
          if (!disabledPreview) setPreviewDialogOpen(true);
        }}
        style={{
          maxWidth: size ? size : 245,
          minWidth: size ? size : 100,
          aspectRatio: removeAspectRatio ? null : 1 / 1,
        }}
        component="img"
        name="image"
        image={presignedUrl}
        alt="image"
      />
      <Dialog
        open={previewDialogOpen}
        fullScreen={isSmallScreen}
        maxWidth="md"
        fullWidth={true}
        onClose={() => {
          setPreviewDialogOpen(false);
        }}
      >
        <DialogTitle>Preview</DialogTitle>
        <DialogContent sx={{ flex: 1 }}>
          <CardMedia
            onClick={() => {
              window.open(presignedUrl, "_blank");
            }}
            style={{}}
            component="img"
            name="image"
            image={presignedUrl}
            alt="image"
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setPreviewDialogOpen(false);
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </BoxOrCard>
  );
};

const BoxOrCard = (props) => {
  if (props.isCard) {
    return <Card {...props}>{props.children}</Card>;
  } else {
    return <Box {...props}>{props.children}</Box>;
  }
};

export default S3PresignedImage;
