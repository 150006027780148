import {
  Box,
  Card,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../../../context/AuthContext";
import { useContext, useEffect, useState } from "react";
import Panel from "./Panel";
import TrimAndFlashing from "./TrimAndFlashing";
import BenchWork from "./BenchWork";
import CopingCap from "./CopingCap";
import Cleat from "./Cleat";
import SplicePlate from "./SplicePlate";
import Accessory from "./Accessory";
import MaterialSummary from "./MaterialSummary";
import ShopS3PresignedCardImage from "../../../../components/ShopS3PresignedCardImage";
import moment from "moment/moment";
import WorkOrderButton from "../../../../components/PdfButtons/WorkOrderButton";
import PackingListButton from "../../../../components/PdfButtons/PackingListButton";
import PurchaseOrders from "../../../admin/PurchaseOrders/PurchaseOrders";
import OrderTitle from "../../../../components/OrderTitle";
import useShopNotes from "../../../../hooks/useShopNotes";
import FabricatorNotes from "../FabricatorNotes";
import FlatSheet from "./FlatSheet";
import Coil from "./Coil";

const Order = () => {
  const { id } = useParams();
  const { authToken, ezorder } = useContext(AuthContext);
  const [order, setOrder] = useState(null);
  const [productMap, setProductMap] = useState(new Map());
  const [refreshing, setRefreshing] = useState(false);
  const { shopNotes, addShopNote, isLoadingNotes } = useShopNotes(
    authToken,
    id,
    false
  );

  const controller = new AbortController();
  const getOrderById = async (id, controller) => {
    try {
      console.log("GET ORDER BY ID");
      setRefreshing(true);
      let data = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };

      if (controller) {
        data.signal = controller.signal;
      }

      console.log("Get Order By ID", id);
      const response = await ezorder.get(`/shop/orders/${id}`, data);
      //console.log(response.data.order);
      let orderDetails = response.data.order;
      setOrder(response.data.order);

      const prodMap = new Map();
      for (let i = 0; i < orderDetails.items.length; i++) {
        let orderItem = orderDetails.items[i];
        prodMap.set(`${orderItem.objectType}`, orderItem);
      }
      setProductMap(prodMap);
      console.log("PRODUCT MAP", prodMap);
    } catch (error) {
      console.log("ERRORRRRR");
      console.log(error);
    } finally {
      setRefreshing(false);
    }
  };

  const updateOrderItemPieceShearedStatus = async (
    itemType,
    pieceId,
    isSheared
  ) => {
    try {
      setRefreshing(true);
      let data = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };

      if (controller) {
        data.signal = controller.signal;
      }

      const response = await ezorder.put(
        `/shop/shear`,
        { itemType, pieceId, isSheared },
        data
      );

      getOrderById(id);
    } catch (error) {
      console.log("ERRORRRRR");
      console.log(error);
    } finally {
      setRefreshing(false);
    }
  };

  useEffect(() => {
    // const controller = new AbortController();
    if (id && authToken) {
      getOrderById(id, controller);
    }

    return () => {
      controller.abort(); // abort api call on unmount for cleanup
    };
  }, [id, authToken]);

  if (!order) return null;
  return (
    <Box sx={{ padding: "16px" }}>
      {/* <Typography variant="h3" sx={{ textAlign: "center" }}>
        Order #{id}
      </Typography> */}
      <OrderTitle order={order} includeStatus={false} />

      <Card sx={{ marginTop: "8px", padding: "16px" }}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
          <Typography variant="h7" sx={{}}>
            Progress Status
          </Typography>
          {order.orderStatus.name == "IN_PROGRESS" && (
            <Typography>
              Estimated Completion Date:{" "}
              {order.shopEstimateCompletionDate
                ? moment(order.shopEstimateCompletionDate).format("MM/DD/YY")
                : "TBD"}
            </Typography>
          )}
          {order.orderStatus.name == "READY_FOR_PICKUP" &&
            order.shopCompletionDate && (
              <Typography>
                Completion Date:{" "}
                {moment(order.shopCompletionDate).format("MM/DD/YY")}
              </Typography>
            )}
          <TextField
            size="small"
            fullWidth
            label="Order Progress Status"
            value={order.shopStatus}
          />
          <Grid
            container
            padding={0}
            spacing={0}
            alignItems="center"
            justifyContent="center"
          >
            {
              //Saved Images
              order.shopImages.map((s3Key, index) => (
                <Grid
                  item
                  key={index}
                  style={{
                    position: "relative",
                    margin: 0,
                    padding: 0,
                  }}
                >
                  <ShopS3PresignedCardImage
                    key={index}
                    width={100}
                    s3Key={s3Key}
                    style={{
                      cursor: "pointer",
                    }}
                  />
                </Grid>
              ))
            }
          </Grid>
          <FabricatorNotes shopNotes={shopNotes} />
        </Box>
      </Card>
      <Card sx={{ marginTop: "8px", padding: "16px" }}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
          <Typography variant="h7" sx={{}}>
            Material Status
          </Typography>
          <TextField
            size="small"
            fullWidth
            label="Material Status"
            value={order.materialStatus}
          />
          <TextField
            size="small"
            fullWidth
            label="Material Notes"
            multiline
            minRows={3}
            value={order.materialNotes}
          />
        </Box>
        <Box sx={{ paddingTop: "16px" }}>
          <Typography variant="h7">Purchase Orders</Typography>
          <PurchaseOrders
            selectedOrderId={order.id}
            isForOrder={true}
            isShopView={true}
          />
        </Box>
      </Card>
      <Typography variant="h5" sx={{ marginTop: "32px" }}>
        Details
      </Typography>
      <Box>
        <WorkOrderButton orderId={id} />
        <PackingListButton orderId={id} />
      </Box>
      <Card sx={{ marginTop: "8px", padding: "16px" }}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "2rem" }}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
            <TextField label="PO #" value={order.poNumber} />
            <TextField
              size="small"
              label="Notes"
              multiline
              maxRows={3}
              value={order.notes}
            />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
            {order.isCustomMaterial ? (
              <TextField
                size="small"
                label="Custom Material"
                value={order.customColor}
              />
            ) : (
              <>
                <TextField
                  size="small"
                  label="Color"
                  value={`${order.color} - ${order.manufacturer}`}
                />
                <TextField size="small" label="Gauge" value={order.gauge} />
              </>
            )}
          </Box>
        </Box>
      </Card>
      <MaterialSummary order={order} style={{ marginTop: "32px" }} />

      {/* <Typography variant="h6" sx={{ marginTop: "32px" }}>
        Skid Charge (Pallet Charge) - $
        {Math.round(parseFloat(order.skidCharge ? order.skidCharge : 0.0))}
      </Typography> */}

      {productMap.get("FlatSheet") && (
        <FlatSheet
          flatSheet={productMap.get("FlatSheet")}
          sx={{ marginTop: "32px" }}
          // isEstimate={true}
          // showTotal={true}
          // showPricePerPiece={order.isItemizedEstimate}
        />
      )}

      {productMap.get("Coil") && (
        <Coil
          coil={productMap.get("Coil")}
          sx={{ marginTop: "32px" }}
          // isEstimate={true}
          // showTotal={true}
          // showPricePerPiece={order.isItemizedEstimate}
        />
      )}

      {productMap.get("Panel") && (
        <Panel panel={productMap.get("Panel")} sx={{ marginTop: "32px" }} />
      )}
      {productMap.get("TrimAndFlashing") && (
        <TrimAndFlashing
          trim={productMap.get("TrimAndFlashing")}
          style={{ marginTop: "32px" }}
          onShearToggle={(pieceId, isSheared) => {
            updateOrderItemPieceShearedStatus(
              "TrimAndFlashing",
              pieceId,
              isSheared
            );
          }}
        />
      )}
      {productMap.get("BenchWork") && (
        <BenchWork
          benchWork={productMap.get("BenchWork")}
          style={{ marginTop: "32px" }}
          onShearToggle={(pieceId, isSheared) => {
            updateOrderItemPieceShearedStatus("BenchWork", pieceId, isSheared);
          }}
        />
      )}
      {productMap.get("CopingCap") && (
        <CopingCap
          copingCap={productMap.get("CopingCap")}
          style={{ marginTop: "32px" }}
          onShearToggle={(pieceId, isSheared) => {
            updateOrderItemPieceShearedStatus("CopingCap", pieceId, isSheared);
          }}
        />
      )}
      {productMap.get("CopingCapCleat") && (
        <Cleat
          cleat={productMap.get("CopingCapCleat")}
          style={{ marginTop: "32px" }}
          onShearToggle={(pieceId, isSheared) => {
            updateOrderItemPieceShearedStatus(
              "CopingCapCleat",
              pieceId,
              isSheared
            );
          }}
        />
      )}
      {productMap.get("SplicePlate") && (
        <SplicePlate
          splicePlate={productMap.get("SplicePlate")}
          style={{ marginTop: "32px" }}
          onShearToggle={(pieceId, isSheared) => {
            updateOrderItemPieceShearedStatus(
              "SplicePlate",
              pieceId,
              isSheared
            );
          }}
        />
      )}
      {productMap.get("Accessory") && (
        <Accessory
          accessory={productMap.get("Accessory")}
          style={{ marginTop: "32px", marginBottom: "32px" }}
        />
      )}
    </Box>
  );
};

export default Order;
