import { useFormik } from "formik";
import * as yup from "yup";
import {
  Box,
  Button,
  Typography,
  Modal,
  TextField,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormLabel,
  RadioGroup,
  Radio,
  FormHelperText,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import { useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { Edit } from "@mui/icons-material";

const EmployeeForm = ({
  isVendorAccount,
  userDetails,
  permissions,
  setPermissions,
  handleSubmit,
  onBack,
  loading,
  protectEmail = false,
}) => {
  const [permissionsMap, setPermissionsMap] = useState([]);
  const [isEditingEmail, setIsEditingEmail] = useState(false);
  const theme = useTheme();
  const loginValidationSchema = yup.object().shape({
    fullName: yup.string().required("Name is required"),
    email: yup.string().email().required("Email is required"),
    //password: yup.string().required("Password is required"),
    // companyName: yup.string().required("Company Name is required"),
  });

  let getPermissionIndexByName = (perms, name) => {
    let i;
    for (i = 0; i < perms.length; i++) {
      if (perms[i].name == name) {
        return i;
      }
    }
  };

  // Parse Permissions to Display
  const parsePermissions = (perms) => {
    let userAccountsPermissions = [];
    let quotePermissions = [];

    // IF Vendor Account, default to Level 3 ( index 2), if Regular Customer, default to no order perms
    let defaultVendorPermissionIndex = getPermissionIndexByName(
      perms,
      "Request Quote And Approve Own Quotes"
    );
    let quotePermissionPickedIndex = isVendorAccount
      ? defaultVendorPermissionIndex
      : -1;

    let i = 0;
    for (i = 0; i < perms.length; i++) {
      if (perms[i].name == "Employees") {
        userAccountsPermissions.push(i);
      }
      if (!isVendorAccount && perms[i].name == "Request Quote") {
        quotePermissions.push(i);
        if (perms[i].checked) quotePermissionPickedIndex = i;
      }
      if (perms[i].name == "Request Quote And Approve Own Quotes") {
        quotePermissions.push(i);
        if (perms[i].checked) quotePermissionPickedIndex = i;
      }
      if (perms[i].name == "Request Quote And Approve All Quotes") {
        quotePermissions.push(i);
        if (perms[i].checked) quotePermissionPickedIndex = i;
      }
    }
    console.log("quotePermissionPickedIndex", quotePermissionPickedIndex);
    let permsMap = {
      userAccountsPermissions,
      quotePermissions,
      quotePermissionPickedIndex,
    };
    console.log("__Permissions Map", permsMap);
    setPermissionsMap(permsMap);
  };

  const formikProps = useFormik({
    enableReinitialize: true,
    initialValues: {
      fullName: userDetails && userDetails.fullName ? userDetails.fullName : "",
      email: userDetails && userDetails.email ? userDetails.email : "",
      phone: userDetails && userDetails.phone ? userDetails.phone : "",
      // password: "",
    },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: loginValidationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  useEffect(() => {
    if (permissions) parsePermissions(permissions);
  }, [permissions]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      <TextField
        disabled={loading}
        label="Full Name"
        value={formikProps.values.fullName}
        onChange={formikProps.handleChange("fullName")}
      />
      <TextField
        disabled={loading || (protectEmail && !isEditingEmail)}
        // disabled={(userDetails != undefined && userDetails != null) || loading}
        label="Email"
        value={formikProps.values.email}
        onChange={formikProps.handleChange("email")}
        InputProps={{
          endAdornment: protectEmail ? (
            <InputAdornment position="start" size="small">
              <IconButton
                onClick={() => {
                  setIsEditingEmail(!isEditingEmail);
                }}
              >
                <Edit size="small" />
              </IconButton>
            </InputAdornment>
          ) : null,
        }}
      />
      <TextField
        disabled={loading}
        label="Phone"
        value={formikProps.values.phone}
        onChange={formikProps.handleChange("phone")}
      />
      {/* <TextField
              label="Password"
              value={formikProps.values.password}
              onChange={formikProps.handleChange("password")}
            /> */}

      {!(userDetails && userDetails.role == "OWNER") && (
        <Box>
          <Typography fontWeight={"600"} sx={{ marginBottom: "8px" }}>
            Select User Permissions:
          </Typography>
          {permissionsMap && (
            <Box>
              <FormGroup sx={{}}>
                <FormLabel>User Account</FormLabel>
                {permissionsMap.userAccountsPermissions &&
                  permissionsMap.userAccountsPermissions.map(
                    (permissionIndex, index) => {
                      let isChecked = false;
                      if (permissions[permissionIndex].checked)
                        isChecked = true;
                      return (
                        <FormControlLabel
                          disabled={loading}
                          key={index}
                          control={
                            <Checkbox
                              disabled={loading}
                              checked={isChecked}
                              onChange={(e) => {
                                let permissionsCopy = [...permissions];
                                permissionsCopy[permissionIndex].checked =
                                  e.target.checked;
                                setPermissions(permissionsCopy);
                              }}
                            />
                          }
                          label={permissions[permissionIndex].description}
                        />
                      );
                    }
                  )}
              </FormGroup>
            </Box>
          )}
          {permissionsMap && (
            <Box>
              <FormGroup sx={{}}>
                <FormLabel>Quotes And Orders</FormLabel>
                <RadioGroup
                  value={parseInt(permissionsMap.quotePermissionPickedIndex)}
                  onChange={(e) => {
                    // Uncheck other quote permissions
                    let permissionIndex = e.target.value;
                    let permissionsCopy = [...permissions];
                    for (let permIndex of permissionsMap.quotePermissions) {
                      permissionsCopy[permIndex].checked = false;
                    }
                    // Check selected permission
                    if (permissionIndex != -1) {
                      // If null then it wont check
                      permissionsCopy[permissionIndex].checked = true;
                    }

                    // Re-parse Permissions Map
                    setPermissions(permissionsCopy);
                  }}
                >
                  {/* {!isVendorAccount && (
                    <FormControlLabel
                      value={-1}
                      control={<Radio disabled={loading} />}
                      label={"Level 1 - Create Quote Request & Save"}
                    />
                  )} */}
                  {/* {!isVendorAccount && (
                    <FormHelperText
                      sx={{
                        paddingLeft: "32px",
                        paddingTop: 0,
                        marginTop: "0",
                      }}
                    >
                      Can Create Quotes and Save Only, a Higher Level User will
                      be Assigned to Submit
                    </FormHelperText>
                  )} */}

                  {permissionsMap.quotePermissions &&
                    permissionsMap.quotePermissions.map(
                      (pemissionIndex, index) => {
                        return (
                          <Box>
                            <FormControlLabel
                              value={pemissionIndex}
                              control={<Radio disabled={loading} />}
                              label={
                                isVendorAccount
                                  ? permissions[pemissionIndex]
                                      .vendorDescription
                                  : permissions[pemissionIndex].description
                              }
                            />
                            <FormHelperText
                              sx={{
                                paddingLeft: "32px",
                                paddingTop: 0,
                                marginTop: "0",
                              }}
                            >
                              {isVendorAccount
                                ? permissions[pemissionIndex]
                                    .vendorLongDescription
                                : permissions[pemissionIndex].longDescription}
                            </FormHelperText>
                          </Box>
                        );
                      }
                    )}
                </RadioGroup>
              </FormGroup>
            </Box>
          )}
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        <LoadingButton
          sx={{ minWidth: 150 }}
          loading={loading}
          variant="outlined"
          onClick={onBack}
        >
          Back
        </LoadingButton>
        <LoadingButton
          sx={{ minWidth: 150, marginLeft: "8px" }}
          loading={loading}
          variant="contained"
          onClick={() => formikProps.handleSubmit()}
        >
          {userDetails ? "Update" : "Create"}
        </LoadingButton>
      </Box>
    </Box>
  );
};
export default EmployeeForm;
