import {
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  Radio,
} from "@mui/material";
import { useState } from "react";
import { useTheme } from "@emotion/react";
import { LoadingButton } from "@mui/lab";

const ChangeOwnerDialog = ({
  open,
  handleClose,
  onConfirm,
  loading,
  company,
  users,
}) => {
  const theme = useTheme();
  const [errorMsg, setErrorMessage] = useState(null);
  const [confirmText, setConfirmText] = useState(null);
  const [selectedOwnerId, setSelectedOwnerId] = useState(null);

  return (
    <Dialog
      keepMounted
      scroll={"paper"}
      maxWidth="sm"
      fullWidth={true}
      PaperProps={{
        style: {
          background: theme.palette.secondary.main,
        },
      }}
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>Change Company Account Owner</DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <TableContainer component={Box}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Full Name</TableCell>
                <TableCell align="right">Email</TableCell>
                <TableCell align="right">Role</TableCell>
                <TableCell align="right">Active</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user, index) => {
                if (!user.deletedAt && user.role == "USER")
                  return (
                    <TableRow
                      key={user.id}
                      onClick={() => {
                        //   navigate(`/customers/${company.id}/users/${user.id}`);
                      }}
                    >
                      <TableCell>
                        <Radio
                          checked={selectedOwnerId == user.id}
                          onChange={(e) => {
                            setSelectedOwnerId(user.id);
                          }}
                          value="a"
                          name="radio-buttons"
                          inputProps={{ "aria-label": "A" }}
                        />
                      </TableCell>
                      <TableCell>{user.fullName}</TableCell>
                      <TableCell align="right">{user.email}</TableCell>
                      <TableCell align="right">{user.role}</TableCell>
                      <TableCell
                        align="right"
                        sx={{
                          color: user.deletedAt ? "red" : "inherit",
                        }}
                      >
                        {user.deletedAt ? "Deactivated" : "Active"}
                      </TableCell>
                    </TableRow>
                  );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          onClick={() => {
            setConfirmText("");
            handleClose();
          }}
          loading={loading}
        >
          Cancel
        </LoadingButton>
        <LoadingButton
          variant="contained"
          loading={loading}
          disabled={!selectedOwnerId}
          onClick={() => {
            onConfirm(selectedOwnerId);
          }}
        >
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
export default ChangeOwnerDialog;
