import { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { IconButton } from "@mui/material";
import getWorkOrderDocDefinition from "../../pdf-helpers/WorkOrderHelper";
import DownloadIcon from "@mui/icons-material/Download";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const WorkOrderButton = ({ orderId, isMaterialAndLaborSummary = false }) => {
  const { authToken, ezorder } = useContext(AuthContext);

  const getOrderById = async () => {
    const response = await ezorder
      .get(`/shop/orders/${orderId}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .catch((error) => {
        throw Error("Unable to load order", { cause: error });
      });

    return response.data.order;
  };

  const createPresignedUrl = async (s3Key) => {
    try {
      let keySplit = s3Key.split("/");
      let identifier = keySplit[0];
      let resource = keySplit[1];
      let fileName = keySplit[2];

      let data = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };

      let requestData = {
        actionType: "READ",
        fileName,
        //contentType,
        resource,
        identifier,
      };
      const response = await ezorder.post(
        "/files/presignedUrl",
        requestData,
        data
      );
      return response.data.getUrl;
    } catch (error) {
      throw Error("Unable to load presigned URL", { cause: error });
    }
  };

  const getBase64FromUrl = async (url) => {
    let blob = await fetch(url).then((r) => r.blob());
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
    });
  };

  const getProductImages = async (order) => {
    // Put order items in a map by type
    const productMap = new Map();
    for (let i = 0; i < order.items.length; i++) {
      let orderItem = order.items[i];
      productMap.set(`${orderItem.objectType}`, orderItem);
    }
    // Parse Order Items for Images
    let productImages = {};

    let panelProduct = productMap.get("Panel");
    if (panelProduct) {
      switch (panelProduct.panelType) {
        case "SL-175":
          switch (panelProduct.panelProfile) {
            case "Beads No Clip Relief":
              switch (panelProduct.panelWidth) {
                case 12:
                  productImages[`Panel`] =
                    "https://ezorder-public.s3.us-east-2.amazonaws.com/panel-types/SL175+12+BEADS+NO+CLIP+RELIEF.JPG";
                  break;
                case 16:
                  productImages[`Panel`] =
                    "https://ezorder-public.s3.us-east-2.amazonaws.com/panel-types/SL175+16+BEADS+NO+CLIP+RELIEF.JPG";
                  break;
                case 18:
                  productImages[`Panel`] =
                    "https://ezorder-public.s3.us-east-2.amazonaws.com/panel-types/SL175+18+BEADS+NO+CLIP+RELIEF.JPG";
                  break;
              }
              break;
            case "Beads With Clip Relief":
              switch (panelProduct.panelWidth) {
                case 12:
                  productImages[`Panel`] =
                    "https://ezorder-public.s3.us-east-2.amazonaws.com/panel-types/SL175+12+BEADS+WITH+CLIP+RELIEF.JPG";
                  break;
                case 16:
                  productImages[`Panel`] =
                    "https://ezorder-public.s3.us-east-2.amazonaws.com/panel-types/SL175+16+BEADS+WITH+CLIP+RELIEF.JPG";
                  break;
                case 18:
                  productImages[`Panel`] =
                    "https://ezorder-public.s3.us-east-2.amazonaws.com/panel-types/SL175+18+BEADS+WITH+CLIP+RELIEF.JPG";
                  break;
              }
              break;
            case "Smooth No Clip Relief":
              switch (panelProduct.panelWidth) {
                case 12:
                  productImages[`Panel`] =
                    "https://ezorder-public.s3.us-east-2.amazonaws.com/panel-types/SL175+12+SMOOTH+NO+CLIP+RELIEF.JPG";
                  break;
                case 16:
                  productImages[`Panel`] =
                    "https://ezorder-public.s3.us-east-2.amazonaws.com/panel-types/SL175+16+SMOOTH+NO+CLIP+RELIEF.JPG";
                  break;
                case 18:
                  productImages[`Panel`] =
                    "https://ezorder-public.s3.us-east-2.amazonaws.com/panel-types/SL175+18+SMOOTH+NO+CLIP+RELIEF.JPG";
                  break;
              }
              break;
            case "Smooth With Clip Relief":
              switch (panelProduct.panelWidth) {
                case 12:
                  productImages[`Panel`] =
                    "https://ezorder-public.s3.us-east-2.amazonaws.com/panel-types/SL175+12+SMOOTH+WITH+CLIP+RELIEF.JPG";
                  break;
                case 16:
                  productImages[`Panel`] =
                    "https://ezorder-public.s3.us-east-2.amazonaws.com/panel-types/SL175+16+SMOOTH+WITH+CLIP+RELIEF.JPG";
                  break;
                case 18:
                  productImages[`Panel`] =
                    "https://ezorder-public.s3.us-east-2.amazonaws.com/panel-types/SL175+18+SMOOTH+WITH+CLIP+RELIEF.JPG";
                  break;
              }
              break;
            case "Striated No Clip Relief":
              switch (panelProduct.panelWidth) {
                case 12:
                  productImages[`Panel`] =
                    "https://ezorder-public.s3.us-east-2.amazonaws.com/panel-types/SL175+12+STRIATED+NO+CLIP+RELIEF.JPG";
                  break;
                case 16:
                  productImages[`Panel`] =
                    "https://ezorder-public.s3.us-east-2.amazonaws.com/panel-types/SL175+16+STRIATED+NO+CLIP+RELIEF.JPG";
                  break;
                case 18:
                  productImages[`Panel`] =
                    "https://ezorder-public.s3.us-east-2.amazonaws.com/panel-types/SL175+18+STRIATED+NO+CLIP+RELIEF.JPG";
                  break;
              }
              break;
            case "Striated With Clip Relief":
              switch (panelProduct.panelWidth) {
                case 12:
                  productImages[`Panel`] =
                    "https://ezorder-public.s3.us-east-2.amazonaws.com/panel-types/SL175+12+STRIATED+WITH+CLIP+RELIEF.JPG";
                  break;
                case 16:
                  productImages[`Panel`] =
                    "https://ezorder-public.s3.us-east-2.amazonaws.com/panel-types/SL175+16+STRIATED+WITH+CLIP+RELIEF.JPG";
                  break;
                case 18:
                  productImages[`Panel`] =
                    "https://ezorder-public.s3.us-east-2.amazonaws.com/panel-types/SL175+18+STRIATED+WITH+CLIP+RELIEF.JPG";
                  break;
              }
              break;
          }

          break;
        case "NS-10":
          switch (panelProduct.panelProfile) {
            case "Smooth":
              switch (panelProduct.panelWidth) {
                case 12:
                  productImages[`Panel`] =
                    "https://ezorder-public.s3.us-east-2.amazonaws.com/panel-types/NS-10+12+SMOOTH.JPG";
                  break;
                case 16:
                  productImages[`Panel`] =
                    "https://ezorder-public.s3.us-east-2.amazonaws.com/panel-types/NS-10+16+SMOOTH.JPG";
                  break;
                case 18:
                  // TODO: this image is wrong. No available image for 18 and 20
                  productImages[`Panel`] =
                    "https://ezorder-public.s3.us-east-2.amazonaws.com/panel-types/NS-10+16+SMOOTH.JPG";
                  break;
                case 20:
                  // TODO: this image is wrong. No available image for 18 and 20
                  productImages[`Panel`] =
                    "https://ezorder-public.s3.us-east-2.amazonaws.com/panel-types/NS-10+16+SMOOTH.JPG";
                  break;
              }
              break;
            case "Striated":
              switch (panelProduct.panelWidth) {
                case 12:
                  productImages[`Panel`] =
                    "https://ezorder-public.s3.us-east-2.amazonaws.com/panel-types/NS-10+12+STRIATED.JPG";
                  break;
                case 16:
                  productImages[`Panel`] =
                    "https://ezorder-public.s3.us-east-2.amazonaws.com/panel-types/NS-10+16+STRIATED.JPG";
                  break;
                case 18:
                  // TODO: this image is wrong. No available image for 18 and 20
                  productImages[`Panel`] =
                    "https://ezorder-public.s3.us-east-2.amazonaws.com/panel-types/NS-10+16+STRIATED.JPG";
                  break;
                case 20:
                  // TODO: this image is wrong. No available image for 18 and 20
                  productImages[`Panel`] =
                    "https://ezorder-public.s3.us-east-2.amazonaws.com/panel-types/NS-10+16+STRIATED.JPG";
                  break;
              }
              break;
          }
          break;

        // case `Flush Wall - 12\" No Reveal`:
        //   switch (panelProduct.panelProfile) {
        //     case "Smooth":
        //       switch (panelProduct.panelWidth) {
        //         case 12:
        //           productImages[`Panel`] =
        //             "https://ezorder-public.s3.us-east-2.amazonaws.com/panel-types/Flush+Wall+12+-+No+Reveal.JPG";
        //           break;
        //       }
        //       break;
        //   }
        //   break;
        // case `Flush Wall - 12\" + 1\" Reveal`:
        //   switch (panelProduct.panelProfile) {
        //     case "Smooth":
        //       switch (panelProduct.panelWidth) {
        //         case 13:
        //           productImages[`Panel`] =
        //             "https://ezorder-public.s3.us-east-2.amazonaws.com/panel-types/Flush+Wall+with+Reveal+12+%2B+1.JPG";
        //           break;
        //       }
        //       break;
        //   }
        //   break;
        // case `Flush Wall - 12\" + 2\" Reveal`:
        //   switch (panelProduct.panelProfile) {
        //     case "Smooth":
        //       switch (panelProduct.panelWidth) {
        //         case 14:
        //           productImages[`Panel`] =
        //             "https://ezorder-public.s3.us-east-2.amazonaws.com/panel-types/Flush+Wall+with+Reveal+12+%2B+2.JPG";
        //           break;
        //       }
        //       break;
        //   }
        //   break;
        // case `Flush Wall - 12\" + 3\" Reveal`:
        //   switch (panelProduct.panelProfile) {
        //     case "Smooth":
        //       switch (panelProduct.panelWidth) {
        //         case 15:
        //           productImages[`Panel`] =
        //             "https://ezorder-public.s3.us-east-2.amazonaws.com/panel-types/Flush+Wall+with+Reveal+12+%2B+3.JPG";
        //           break;
        //       }
        //       break;
        //   }
        //   break;
        case "Standard":
          switch (panelProduct.panelWidth) {
            case 16:
              if (panelProduct.panelSystem == "Wave Panel") {
                // Wave Panel
                productImages[`Panel`] = await getBase64FromUrl(
                  "https://ezorder-public.s3.us-east-2.amazonaws.com/panel-types/WAVE_PANEL_16.jpg"
                );
              }
              break;
            case 36: // 7.2
              if (panelProduct.panelSystem == "AG Panel") {
                // AG Panel
                productImages[`Panel`] = await getBase64FromUrl(
                  "https://ezorder-public.s3.us-east-2.amazonaws.com/panel-types/AG+PANEL+STANDARD.jpg"
                );
              } else {
                // 7.2
                productImages[`Panel`] = await getBase64FromUrl(
                  "https://ezorder-public.s3.us-east-2.amazonaws.com/panel-types/7_2+Panel+Profile.jpg"
                );
              }
              break;
            case 39: // 7/8 Corrugated
              productImages[`Panel`] = await getBase64FromUrl(
                "https://ezorder-public.s3.us-east-2.amazonaws.com/panel-types/7_8+Panel+Profile.jpg"
              );
              break;
          }
          break;
        default:
          console.log("DEFAULT DEFAULT DEFAULT DEFAULT DEFAULT");
          if (panelProduct.panelType.includes("Flush Wall")) {
            console.log("INCLUDES FLUSH WALL");
            if (panelProduct.panelType.includes("No Reveal")) {
              console.log("NO REVEAL");
              switch (panelProduct.panelProfile) {
                case "Smooth":
                  console.log("Smooth");
                  switch (panelProduct.panelWidth) {
                    case 12:
                      console.log("Panel Width: 12");
                      productImages[`Panel`] = await getBase64FromUrl(
                        "https://ezorder-public.s3.us-east-2.amazonaws.com/panel-types/Flush+Wall+12+-+No+Reveal.JPG"
                      );
                      break;
                  }
                  break;
              }
            }
            if (panelProduct.panelType.includes("+ 1")) {
              console.log("+1 REVEAL");
              switch (panelProduct.panelProfile) {
                case "Smooth":
                  console.log("Smooth");
                  switch (panelProduct.panelWidth) {
                    case 13:
                      console.log("Panel Width: 13");
                      productImages[`Panel`] = await getBase64FromUrl(
                        "https://ezorder-public.s3.us-east-2.amazonaws.com/panel-types/Flush+Wall+with+Reveal+12+%2B+1.JPG"
                      );
                      break;
                  }
                  break;
              }
            } else if (panelProduct.panelType.includes("+ 2")) {
              console.log("+2 REVEAL");
              switch (panelProduct.panelProfile) {
                case "Smooth":
                  console.log("Smooth");
                  switch (panelProduct.panelWidth) {
                    case 14:
                      console.log("Panel Width: 14");
                      productImages[`Panel`] = await getBase64FromUrl(
                        "https://ezorder-public.s3.us-east-2.amazonaws.com/panel-types/Flush+Wall+with+Reveal+12+%2B+2.JPG"
                      );
                      break;
                  }
                  break;
              }
            } else if (panelProduct.panelType.includes("+ 3")) {
              console.log("+3 REVEAL");
              switch (panelProduct.panelProfile) {
                case "Smooth":
                  console.log("Smooth");
                  switch (panelProduct.panelWidth) {
                    case 15:
                      console.log("Panel Width: 15");
                      productImages[`Panel`] = await getBase64FromUrl(
                        "https://ezorder-public.s3.us-east-2.amazonaws.com/panel-types/Flush+Wall+with+Reveal+12+%2B+3.JPG"
                      );
                      break;
                  }
                  break;
              }
            }
          } else {
            console.log("NONE OF THE IMAGES APPLIED");
          }
          break;
      }
    }

    // TrimAndFlashing
    let trimAndFlashingProduct = productMap.get("TrimAndFlashing");
    if (trimAndFlashingProduct) {
      let index = 0;
      for (const trimPiece of trimAndFlashingProduct.trimPieces) {
        let presignedImage = await createPresignedUrl(
          trimPiece.image,
          authToken
        );
        console.log("PRESIGNED IMAGE", presignedImage);
        // let blob = await fetch(presignedImage).then((r) => r.blob());
        let base64 = await getBase64FromUrl(presignedImage);
        productImages[`TrimAndFlashing${index}`] = base64;
        index++;
      }
    }

    // CopingCap
    let copingCapProduct = productMap.get("CopingCap");
    if (copingCapProduct) {
      let index = 0;
      for (const capPiece of copingCapProduct.capPieces) {
        let presignedImage = await createPresignedUrl(
          capPiece.image,
          authToken
        );
        let base64 = await getBase64FromUrl(presignedImage);
        productImages[`CopingCap${index}`] = base64;
        index++;
      }
    }

    // CopingCapCleat
    let copingCapCleatProduct = productMap.get("CopingCapCleat");
    if (copingCapCleatProduct) {
      let index = 0;
      for (const cleatPiece of copingCapCleatProduct.cleatPieces) {
        let presignedImage = await createPresignedUrl(
          cleatPiece.image,
          authToken
        );
        let base64 = await getBase64FromUrl(presignedImage);
        productImages[`CopingCapCleat${index}`] = base64;
        index++;
      }
    }

    // BenchWork
    let benchWorkProduct = productMap.get("BenchWork");
    if (benchWorkProduct) {
      let index = 0;
      for (const benchWorkPiece of benchWorkProduct.benchWorkPieces) {
        let presignedImage = await createPresignedUrl(
          benchWorkPiece.image,
          authToken
        );
        let base64 = await getBase64FromUrl(presignedImage);
        productImages[`BenchWork${index}`] = base64;
        index++;
      }
    }
    // console.log("PRODUCT IMAGES", productImages);

    return productImages;
  };

  const getPDF = async () => {
    try {
      // GET ORDER
      // console.log("GET WORK ORDER PDF FOR ORDER", orderId);
      const order = await getOrderById(orderId);
      const productImages = await getProductImages(order);
      // console.log("Product Images", productImages);
      const docDef = await getWorkOrderDocDefinition(
        order,
        productImages,
        isMaterialAndLaborSummary
      );
      console.log("docDef", docDef);

      // pdfMake.createPdf(docDef).open();
      pdfMake
        .createPdf(docDef)
        .download(
          isMaterialAndLaborSummary
            ? `MATERIAL-AND-LABOR-SUMMARY-${order.id}-${order.company.name}-${order.project.name}.pdf`
            : `WORK-ORDER-${order.id}-${order.company.name}-${order.project.name}.pdf`
        );
    } catch (error) {
      console.log(error);
      throw Error("Unable to load PDF", { cause: error });
    }
  };

  return (
    <IconButton
      variant="outlined"
      color="primary"
      onClick={() => {
        getPDF();
      }}
    >
      {isMaterialAndLaborSummary ? "Material & Labor Summary" : "Work Order"}
      <DownloadIcon />
    </IconButton>
  );
};
export default WorkOrderButton;
