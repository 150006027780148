import { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useTheme } from "@emotion/react";
import { Checkbox, Collapse, TextField, useMediaQuery } from "@mui/material";
import { LoadingButton } from "@mui/lab";

const DeclineEstimateModal = ({
  open,
  handleClose,
  onDeclineEstimate,
  loading,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [commentText, setCommentText] = useState("");

  return (
    <>
      <Modal
        disableEnforceFocus
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            // overflowY: "scroll",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 300,
            background: theme.palette.secondary.main,
            p: 4,
          }}
        >
          <Box
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{ textAlign: "start", width: "100%", fontWeight: "bold" }}
            >
              Request
            </Typography>
            <TextField
              fullWidth
              variant="filled"
              multiline
              rows={5}
              label="Comment"
              value={commentText}
              onChange={(e) => setCommentText(e.target.value)}
            />
            <LoadingButton
              loading={loading}
              fullWidth
              sx={{ marginTop: "16px" }}
              variant="contained"
              disabled={loading}
              onClick={async () => {
                await onDeclineEstimate(false, commentText);
                handleClose();
              }}
            >
              Submit
            </LoadingButton>
            {/* <Button
              fullWidth
              sx={{ marginTop: "8px" }}
              variant="contained"
              disabled={loading}
              onClick={async () => {
                await onDeclineEstimate(true, commentText);
                handleClose();
              }}
            >
              Decline and Cancel
            </Button> */}
            <LoadingButton
              loading={loading}
              sx={{ marginTop: "8px" }}
              disabled={loading}
              onClick={handleClose}
            >
              Back
            </LoadingButton>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
export default DeclineEstimateModal;
