import { useState, Children, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

export const CustomTabs = ({
  activeTab,
  setActiveTab,
  children,
  onActiveTabChange,
  // setTabValue
}) => {
  // const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    // setValue(newValue);
    setActiveTab(newValue);
  };
  const childrenArray = Children.toArray(children);
  const tabNames = childrenArray.map((child) => child.props?.tabName);

  useEffect(() => {
    onActiveTabChange(activeTab);
  }, [activeTab]);

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          centered
          value={activeTab}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          {tabNames.map((label, index) => (
            <Tab key={index} label={label} {...a11yProps(index)} />
          ))}
        </Tabs>
      </Box>
      {childrenArray.map((child, index) => (
        <TabPanel key={index} value={activeTab} index={index}>
          {child}
        </TabPanel>
      ))}
    </Box>
  );
};

export function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ marginTop: "1rem" }}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
