import {
  Box,
  Stack,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  Tooltip,
  IconButton,
} from "@mui/material";
import AdornedInput from "../../../../AdornedInput";
import { toTwoDecimals } from "../../../calculations/utils";

const FlatSheetCalculations = ({
  calculations: calcs,
  variables,
  overrides,
  handleChange,
  resetOverride,
}) => {
  return (
    <>
      {calcs && (
        <Stack direction="column" spacing={2}>
          <Typography>Material</Typography>
          <Stack direction="column" spacing={2}>
            <Stack direction="row" spacing={2}>
              <AdornedInput
                type="number"
                onWheel={(event) => {
                  event.preventDefault();
                }}
                onFocus={(e) => e.target.select()}
                label="Cost Per Flat Sheet"
                value={calcs.costPerFlatSheet}
                onChange={handleChange("cost_per_piece")}
                isOveridden={overrides?.cost_per_piece}
                handleReset={resetOverride("cost_per_piece")}
              />
            </Stack>
          </Stack>

          <Typography>Cost Breakdown</Typography>
          <Stack direction="row" spacing={2}>
            <AdornedInput
              type="number"
              onWheel={(event) => {
                event.preventDefault();
              }}
              onFocus={(e) => e.target.select()}
              label="Total Flat Sheet Cost"
              value={calcs.materialCost}
              symbol="$"
              position="start"
              onChange={handleChange("material_cost")}
              isOveridden={overrides?.material_cost}
              handleReset={resetOverride("material_cost")}
            />
          </Stack>

          <Stack direction="row" spacing={2}>
            <AdornedInput
              type="number"
              onWheel={(event) => {
                event.preventDefault();
              }}
              onFocus={(e) => e.target.select()}
              label="GP %"
              value={
                calcs?.grossProfitMargin
                  ? toTwoDecimals(calcs?.grossProfitMargin * 100.0)
                  : 0
              }
              onChange={(e) => {
                e.target.value = parseFloat(
                  parseFloat(e.target.value).toFixed(4) / 100.0
                ).toFixed(4);
                handleChange("gross_profit_margin")(e);
              }}
              isOveridden={overrides?.gross_profit_margin}
              handleReset={resetOverride("gross_profit_margin")}
              symbol="%"
              position="start"
            />

            <AdornedInput
              type="number"
              onWheel={(event) => {
                event.preventDefault();
              }}
              onFocus={(e) => e.target.select()}
              label="Gross Profit"
              value={calcs.grossProfitDollars}
              symbol="$"
              position="start"
              onChange={handleChange("gross_profit_dollars")}
              isOveridden={overrides?.gross_profit_dollars}
              handleReset={resetOverride("gross_profit_dollars")}
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            <AdornedInput
              type="number"
              onWheel={(event) => {
                event.preventDefault();
              }}
              onFocus={(e) => e.target.select()}
              label="Total Sell Price"
              value={calcs.totalSellPrice}
              symbol="$"
              position="start"
              onChange={handleChange("total_sell_price")}
              isOveridden={overrides?.total_sell_price}
              handleReset={resetOverride("total_sell_price")}
            />
            <AdornedInput
              type="number"
              onWheel={(event) => {
                event.preventDefault();
              }}
              onFocus={(e) => e.target.select()}
              label="Price Per Flat Sheet"
              value={calcs.pricePerFlatSheet}
              symbol="$"
              position="start"
              InputProps={{
                readOnly: true,
                disableUnderline: true,
              }}
              // onChange={handleChange("price_per_piece")}
              // isOveridden={overrides?.price_per_piece}
              // handleReset={resetOverride("price_per_piece")}
            />
          </Stack>
        </Stack>
      )}
    </>
  );
};
export default FlatSheetCalculations;
