import CreateProjectView from "./View";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { SnackAlertContext } from "../../../../context/SnackAlertContext";
import { initialOrder } from "../../../../context/MobileOrderContext";

const CreateProject = ({ open, handleOpen, handleClose }) => {
  const navigate = useNavigate();
  const { authToken, ezorder, decoded } = useContext(AuthContext);
  const { openSnackMessage } = useContext(SnackAlertContext);
  const headers = {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  };
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [taxForms, setTaxForms] = useState([]);
  const [selectedTaxForm, setSelectedTaxForm] = useState("");
  const [projectName, setProjectName] = useState("");
  const [startNewOrder, setStartNewOrder] = useState(false);

  const handleTaxFormSelect = (taxFormId) => {
    setSelectedTaxForm(taxForms.find((form) => form.id === taxFormId));
  };

  const handleProjectName = (e) => {
    setProjectName(e.target.value);
  };

  const handleSubmitProject = async ({ name, isStartNewOrder }) => {
    try {
      if (!name) return;
      setLoading(true);
      const result = await ezorder.post(
        "/projects",
        { name },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      if (decoded.isVendorOrSupplier) {
        openSnackMessage("success", "Customer Created");
      } else {
        openSnackMessage("success", "Project Created");
      }
      handleClose();
      let newProject = result.data.project;

      if (isStartNewOrder) createNewOrderForProject(newProject.id);
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage("error", error.response.data.error);
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", error);
      }
    } finally {
      setLoading(false);
      setProjectName("");
      setSelectedTaxForm("");
    }
  };

  const createNewOrderForProject = async (projectId) => {
    try {
      setLoading(true);
      const requestBody = {
        projectId: parseInt(projectId),
        orderDetails: { ...initialOrder },
      };

      const response = await ezorder.post(`/orders`, requestBody, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      console.log(response);
      if (response.status == 200 || response.status === 201) {
        navigate(`/quotes/${response.data.order.id}`, {
          replace: true,
        });
      }
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage("error", error.response.data.error);
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", error);
      }
    } finally {
      setLoading(false);
    }
  };

  const getTaxExemptForms = async () => {
    try {
      setLoading(false);
      const response = await ezorder.get(`/tax-forms`, headers);
      setTaxForms(response.data.taxForms);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getTaxExemptForms();
  }, []);

  return (
    <CreateProjectView
      taxForms={taxForms}
      handleTaxFormSelect={handleTaxFormSelect}
      selectedTaxForm={selectedTaxForm}
      projectName={projectName}
      handleProjectName={handleProjectName}
      handleSubmitProject={handleSubmitProject}
      open={open}
      handleOpen={handleOpen}
      handleClose={handleClose}
      loading={loading}
      error={error}
      startNewOrder={startNewOrder}
      setStartNewOrder={setStartNewOrder}
    />
  );
};
export default CreateProject;
