import {
  Typography,
  Box,
  Button,
  Checkbox,
  Modal,
  TextField,
  FormControlLabel,
  CircularProgress,
} from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker, DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { useState } from "react";
import { useTheme } from "@emotion/react";

const CreateTimeCardModal = ({
  open,
  handleClose,
  createTimeCard,
  isCreating,
}) => {
  const theme = useTheme();
  const [timeCard, setTimeCard] = useState({
    clockIn: moment().format(),
    clockOut: moment().format(),
  });

  const [errorMsg, setErrorMessage] = useState(null);
  const handleDate = (date) => {
    const justTheDate = moment(date).format("YYYY-MM-DD");
    const newDate = justTheDate + "T" + timeCard.clockIn.split("T")[1];

    let newCard = timeCard ? timeCard : {};
    newCard.clockIn = newDate;
    newCard.clockOut = newDate;
    setTimeCard({ ...newCard });
  };

  const handleTimes = (dateTime, inOrOut) => {
    const justTheTime = moment(dateTime).format("HH:mm:ss");
    const newDateTime = timeCard[inOrOut].split("T")[0] + "T" + justTheTime;
    let newCard = timeCard;
    newCard[inOrOut] = newDateTime;
    setTimeCard({ ...newCard });
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        handleClose();
        setTimeCard({
          clockIn: moment().format(),
          clockOut: moment().format(),
        });
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          background: theme.palette.secondary.main,
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Box sx={{ mt: "25px" }}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              label="Date"
              value={timeCard?.clockIn}
              onChange={(newValue) => {
                handleDate(newValue);
              }}
              renderInput={(params) => (
                <TextField sx={{ mb: "10px" }} {...params} />
              )}
            />
            <TimePicker
              label="Clock-In"
              value={timeCard?.clockIn}
              onChange={(newValue) => {
                if (
                  new Date(newValue).getTime() <
                  new Date(timeCard.clockOut).getTime()
                ) {
                  handleTimes(newValue, "clockIn");
                  setErrorMessage(null);
                } else {
                  handleTimes(newValue, "clockIn");
                  setErrorMessage(
                    "Clock Out Time Must be Greater than Clock In Time"
                  );
                  handleTimes(newValue, "clockIn");
                }
              }}
              renderInput={(params) => (
                <TextField sx={{ mb: "10px" }} {...params} />
              )}
            />
            <TimePicker
              label="Clock-Out"
              sx={{ border: "1px solid red" }}
              value={timeCard?.clockOut}
              onChange={(newValue) => {
                // clock-out must be greater than clock-in
                if (
                  new Date(timeCard.clockIn).getTime() <
                  new Date(newValue).getTime()
                ) {
                  handleTimes(newValue, "clockOut");
                  setErrorMessage(null);
                } else {
                  setErrorMessage(
                    "Clock Out Time Must be Greater than Clock In Time"
                  );
                }
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
          <Box>
            {errorMsg && (
              <Typography variant="caption" sx={{ color: "red" }}>
                {errorMsg}
              </Typography>
            )}
          </Box>

          <TextField
            variant="outlined"
            label="Notes"
            multiline
            maxRows={6}
            sx={{ width: "100%", marginTop: "10px", marginBottom: "10px" }}
            value={timeCard.notes}
            onChange={(e) => {
              let newCard = timeCard;
              newCard.notes = e.target.value;
              setTimeCard({ ...newCard });
            }}
          />

          <FormControlLabel
            control={
              <Checkbox
                value={timeCard.noWork}
                onChange={(e) => {
                  let newCard = timeCard;
                  newCard.noWork = e.target.checked;
                  setTimeCard({ ...newCard });
                }}
              />
            }
            label="No Work?"
          />
          <Box sx={{ m: 1, position: "relative" }}>
            <Button
              variant="outlined"
              // sx={buttonSx}
              disabled={isCreating || errorMsg}
              onClick={async () => {
                let isSuccessful = await createTimeCard(timeCard);
                if (isSuccessful) {
                  handleClose();
                }
              }}
            >
              Create
            </Button>
            {isCreating && (
              <CircularProgress
                size={24}
                sx={{
                  // color: green[500],
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
export default CreateTimeCardModal;
