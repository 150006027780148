import React, { useState, createContext, useEffect, useContext } from "react";
import { AuthContext } from "./AuthContext";

export const QuickBooksContext = createContext();

export const QuickBooksProvider = (props) => {
  const { authToken, role, ezorder } = useContext(AuthContext);
  const [isQbAuthenticated, setIsQbAuthenticated] = useState(true);

  useEffect(() => {
    const controller = new AbortController();
    const refreshTokens = async () => {
      try {
        // console.log("Refresh QB Tokens");
        const response = await ezorder.get(`/quickbooks/auth/refresh`, {
          signal: controller.signal,
        });
        // console.log("refreshTokens", response.data);
        // console.log("QB is Authenticated", response.data.qbTokens);
        setIsQbAuthenticated(true);
      } catch (error) {
        console.log(error);
        // console.log("Failed to Refresh Tokens, QB is NOT Authenticated");
        // console.log(error);
        setIsQbAuthenticated(false);
        throw Error("Failed to refresh QuickBooks tokens.", { cause: error });
      }
    };

    if (authToken && role === "EZORDER_ADMIN") refreshTokens();
    return () => {
      controller.abort(); // abort api call on unmount for cleanup
    };
  }, [authToken, role]);

  return (
    <QuickBooksContext.Provider
      value={{
        isQbAuthenticated,
        setIsQbAuthenticated,
      }}
    >
      {props.children}
    </QuickBooksContext.Provider>
  );
};
