import { Button, Stack, TextField, Typography } from "@mui/material";
import { useTheme } from "@emotion/react";
import AdornedInput from "../../../../AdornedInput";
import { toTwoDecimals } from "../../../calculations/utils";

const BenchCalculations = ({
  calculations: calcs,
  overrides,
  handleChange,
  resetOverride,
}) => {
  const theme = useTheme();
  return (
    <Stack direction={"column"} spacing={1}>
      <Stack direction={"row"} spacing={2}>
        <AdornedInput
          sx={{
            fieldset: {
              borderColor: theme.palette.primary.main,
              borderWidth: 3,
            },
          }}
          type="number"
          onWheel={(event) => {
            event.preventDefault();
          }}
          onFocus={(e) => e.target.select()}
          label="Est. Labor Hrs."
          value={calcs?.totalEstimatedLaborHours}
          // onChange={(e) => handleChange("total_estimated_labor_hours")(e)}
          // value={toTwoDecimals(calcs?.estimatedLaborHours).toFixed(0)}
          onChange={(e) => {
            e.target.value = parseFloat(e.target.value).toFixed(0);
            handleChange("total_estimated_labor_hours")(e);
          }}
          isOveridden={overrides?.total_estimated_labor_hours}
          handleReset={(e) => resetOverride("total_estimated_labor_hours")(e)}
        />
        <AdornedInput
          type="number"
          onWheel={(event) => {
            event.preventDefault();
          }}
          onFocus={(e) => e.target.select()}
          label="Est. Labor Cost"
          value={calcs?.totalEstimatedLaborCost}
          onChange={(e) => handleChange("total_estimated_labor_cost")(e)}
          isOveridden={overrides?.total_estimated_labor_cost}
          handleReset={(e) => resetOverride("total_estimated_labor_cost")(e)}
        />
        <AdornedInput
          sx={{
            fieldset: {
              borderColor: theme.palette.primary.main,
              borderWidth: 3,
            },
          }}
          type="number"
          onWheel={(event) => {
            event.preventDefault();
          }}
          onFocus={(e) => e.target.select()}
          label="Material Cost"
          value={calcs?.materialCost}
          onChange={(e) => handleChange("material_cost")(e)}
          isOveridden={overrides?.material_cost}
          handleReset={(e) => resetOverride("material_cost")(e)}
        />
        <AdornedInput
          type="number"
          onWheel={(event) => {
            event.preventDefault();
          }}
          onFocus={(e) => e.target.select()}
          label="Material & Labor"
          symbol="$"
          position="start"
          value={calcs?.materialAndLabor}
          onChange={(e) => handleChange("material_and_labor")(e)}
          isOveridden={overrides?.material_and_labor}
          handleReset={(e) => resetOverride("material_and_labor")(e)}
        />
      </Stack>

      <Stack direction={"row"} spacing={2}>
        <AdornedInput
          sx={{
            fieldset: {
              borderColor: theme.palette.primary.main,
              borderWidth: 3,
            },
          }}
          type="number"
          onWheel={(event) => {
            event.preventDefault();
          }}
          onFocus={(e) => e.target.select()}
          label="GP %"
          value={
            calcs?.grossProfitMargin
              ? toTwoDecimals(calcs?.grossProfitMargin * 100.0)
              : 0
          }
          onChange={(e) => {
            e.target.value = parseFloat(
              parseFloat(e.target.value).toFixed(4) / 100.0
            ).toFixed(4);
            handleChange("gross_profit_margin")(e);
          }}
          isOveridden={overrides?.gross_profit_margin}
          handleReset={(e) => resetOverride("gross_profit_margin")(e)}
          symbol="%"
          position="start"
        />
        <AdornedInput
          type="number"
          onWheel={(event) => {
            event.preventDefault();
          }}
          onFocus={(e) => e.target.select()}
          label="GP $"
          value={calcs?.grossProfitDollars}
          onChange={(e) => handleChange("gross_profit_dollars")(e)}
          isOveridden={overrides?.gross_profit_dollars}
          handleReset={(e) => resetOverride("gross_profit_dollars")(e)}
        />
      </Stack>
      <Stack direction={"row"} spacing={2}>
        <AdornedInput
          type="number"
          onWheel={(event) => {
            event.preventDefault();
          }}
          onFocus={(e) => e.target.select()}
          label="Sell Price"
          value={calcs?.totalSellPrice}
          onChange={(e) => handleChange("total_sell_price")(e)}
          isOveridden={overrides?.total_sell_price}
          handleReset={(e) => resetOverride("total_sell_price")(e)}
        />
        <AdornedInput
          type="number"
          onWheel={(event) => {
            event.preventDefault();
          }}
          onFocus={(e) => e.target.select()}
          label="Price Per Piece"
          value={calcs?.pricePerPiece}
          onChange={(e) => handleChange("price_per_piece")(e)}
          isOveridden={overrides?.price_per_piece}
          handleReset={(e) => resetOverride("price_per_piece")(e)}
          symbol="$"
          position="start"
        />
      </Stack>
    </Stack>
  );
};
export default BenchCalculations;
