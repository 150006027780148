import moment from "moment/moment";
import { useState } from "react";
import CollapsableCard from "../../CollapseableCard";
import { Box, Button, Card, TextField, Typography } from "@mui/material";
import { useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { SnackAlertContext } from "../../../context/SnackAlertContext";
import { useEffect } from "react";

const CommentSection = ({ orderId, addComment }) => {
  const { authToken, ezorder } = useContext(AuthContext);
  const { openSnackMessage } = useContext(SnackAlertContext);
  const [comments, setComments] = useState([]);
  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState(false);

  const getOrderComments = async (controller, orderId) => {
    try {
      setLoading(true);
      let data = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      if (controller) {
        data.signal = controller.signal;
      }
      const res = await ezorder.get(`/admin/orders/${orderId}/comments`, data);
      setComments(res.data.comments);
    } catch (error) {
      console.log(error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage("error", error.response.data.error);
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", error);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    if (orderId) {
      getOrderComments(controller, orderId);
    }

    return () => {
      controller.abort(); // abort api call on unmount for cleanup
    };
  }, [orderId]);

  if (orderId && comments)
    return (
      <CollapsableCard
        title={`Comments (${comments.length})`}
        defaultOpen={false}
      >
        {comments.map((comment, index) => {
          let createdAt = moment(new Date(comment.createdAt)).local();
          let weekOld = moment(new Date(createdAt)).local();
          weekOld.add(7, "d");
          let isWeekOld = moment() > weekOld;

          return (
            <Card key={index} style={{ padding: 16, marginBottom: 8 }}>
              <Box style={{ display: "flex", flexDirection: "row" }}>
                {comment?.user ? (
                  <Typography style={{ marginRight: 8, fontWeight: "bold" }}>
                    {comment.user.fullName}
                  </Typography>
                ) : (
                  <Typography style={{ marginRight: 8, fontWeight: "bold" }}>
                    {comment.guestName}
                  </Typography>
                )}
                <Typography>
                  {isWeekOld
                    ? createdAt.format("MM/DD/YYYY")
                    : createdAt.fromNow()}
                </Typography>
              </Box>
              <Typography style={{ marginTop: 8 }}>{comment.text}</Typography>
            </Card>
          );
        })}
        <div style={{ marginTop: 16 }}>
          <TextField
            variant="outlined"
            label="Comment"
            name="Comment"
            size="small"
            fullWidth
            multiline={true}
            rows={3}
            value={comment}
            onChange={(e) => {
              setComment(e.target.value);
            }}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "12px",
            }}
          >
            <Button
              disabled={loading || !comment || comment.length == 0}
              variant="outlined"
              onClick={() => {
                addComment(comment);
              }}
            >
              Add Comment
            </Button>
          </div>
        </div>
      </CollapsableCard>
    );
};

export default CommentSection;
