const createPresignedUrl = async (
  ezorder,
  authToken,
  actionType, // READ || WRITE
  fileName, // Ex: "asdfasfsaf.jpg". The backend replaces the name so it doesnt matter and add back the extension (.jpg)
  contentType, // Ex: "image/jpeg"
  resource, //"trims", "coping-caps", "cleats", "bench-work", "tax-forms", "job-quotes", "estimates", "signatures", "signed-estimates"
  identifier // Customer's Company ID or Null
) => {
  console.log("Create Presigned URL");

  const response = await ezorder.post(
    "/files/presignedUrl",
    {
      actionType,
      fileName,
      contentType,
      resource,
      identifier,
    },
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    }
  );

  console.log(response.data);

  return response.data;
};

export default createPresignedUrl;
