import {
  Box,
  TextField,
  Button,
  MenuItem,
  Typography,
  Tooltip,
  IconButton,
} from "@mui/material";
import { useEffect, useState, useMemo } from "react";
import CollapsableCard from "../../../../CollapseableCard";
import { toTwoDecimals } from "../../../calculations/utils";
import CoilCalculations from "../Coil/CoilCalculations";

const CoilDetails = ({ details, action, materialDetails }) => {
  const handleCalcOveride = (key) => (e) => {
    action({
      type: "UPDATE COIL: OVERRIDE CALCULATION",
      payload: { key, value: parseFloat(e.target.value) },
    });
  };

  const resetOverrideValue = (key) => () => {
    action({ type: "UPDATE COIL: RESET OVERIDE", payload: key });
  };

  useEffect(() => {
    if (!details.coilCalculations) {
      action({ type: "UPDATE COIL: SET CALCULATIONS" });
    }
  }, [details]);

  useEffect(() => {
    action({ type: "UPDATE COIL: SET CALCULATIONS" });
  }, [
    materialDetails.coilCostPerSqFt,
    materialDetails.coilCostPerSqFtOriginal,
  ]);

  return (
    <CollapsableCard
      title={`Coil - $${Math.round(
        toTwoDecimals(
          details &&
            details.coilCalculations &&
            details.coilCalculations.totalSellPrice
            ? details.coilCalculations.totalSellPrice
            : 0.0
        )
      )}`}
      defaultOpen={details.linealFeet > 0}
    >
      <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "1rem",
            marginTop: "8px",
          }}
        >
          <TextField
            onFocus={(e) => e.target.select()}
            type="number"
            onWheel={(event) => {
              event.preventDefault();
            }}
            size="small"
            label="Lineal Feet"
            value={parseFloat(details.linealFeet)}
            onChange={(e) => {
              action({
                type: "UPDATE COIL: LINEAL FEET",
                payload: e.target.value,
              });
            }}
          />
          <TextField
            onFocus={(e) => e.target.select()}
            type="number"
            onWheel={(event) => {
              event.preventDefault();
            }}
            size="small"
            label="Width (in.)"
            value={parseFloat(details.width)}
            onChange={(e) => {
              action({
                type: "UPDATE COIL: WIDTH",
                payload: e.target.value,
              });
            }}
          />
        </Box>
        {details?.coilCalculations && (
          <CoilCalculations
            calculations={details?.coilCalculations}
            variables={details?.variables}
            overrides={details?.overrides}
            handleChange={handleCalcOveride}
            resetOverride={resetOverrideValue}
          />
        )}
      </Box>
    </CollapsableCard>
  );
};
export default CoilDetails;
