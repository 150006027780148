import { useTheme } from "@emotion/react";
import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Paper,
  Typography,
} from "@mui/material";

const TermsOfAgreement = ({ estimateVersion }) => {
  const theme = useTheme();
  return (
    <Accordion
      sx={{
        // borderColor: "#c0c0c0",
        borderColor: theme.palette.primary.dark,
        borderWidth: 1,
        borderStyle: "solid",
        background: theme.palette.background.default,
      }}
      // defaultExpanded
    >
      <AccordionSummary
        expandIcon={<ExpandMore color="primary" />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography style={{ fontWeight: "bold", TypographyAlign: "center" }}>
          Read Terms of Service Agreement
        </Typography>
      </AccordionSummary>
      {estimateVersion == "v1" && <AccordionDetialsV1 />}
      {estimateVersion == "v2" && <AccordionDetialsV2 />}
    </Accordion>
  );
};

const AccordionDetialsV1 = () => {
  return (
    <AccordionDetails>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          color: "#ccc8c8", //off-white off white offwhite
        }}
      >
        <Typography style={{ fontWeight: "bold", TypographyAlign: "center" }}>
          Terms of Service Agreement
        </Typography>
        <Typography style={{ TypographyAlign: "left", width: "100%" }}>
          {"\u2022"} This quote is hereby accepted by customer and authorized
          for fabrication. This quote is valid for 30 days. All orders must be
          pre-paid in full prior to fabrication via check or credit card.
          Industry Metal Co does not offer credit terms at this time.
        </Typography>
        <Typography style={{ TypographyAlign: "left", width: "100%" }}>
          {"\u2022"} This Quote does not include Sales Tax or Credit Card
          Convenience Fee. Our Credit Card Fee is 2.5%. For Sales Tax please
          indicate if this is a taxable sale or not. If not taxable, we must
          have a current AZ5005 on file.
        </Typography>
        <Typography style={{ TypographyAlign: "left", width: "100%" }}>
          {"\u2022"} If we are delivering your material, it shall be up to you
          to have sufficient manpower and/or equipment on-site at the time of
          our delivery to offload. Our delivery service does not include
          off-loading from our truck. Our driver will help where he can, but his
          assistance is not guaranteed. A typical truckload should take no more
          than 30 minutes to unload, and anything in excess to this allotted
          time frame will be charged at a rate of $40 per every 15 minutes, and
          charged to the credit card used to place the order, or to the credit
          account. If nobody is onsite, and we need to schedule a re-delivery,
          there will be an additional charge.
        </Typography>
        <Typography style={{ TypographyAlign: "left", width: "100%" }}>
          {"\u2022"} Minor scratches, chips, scrapes, dings, etc are inevitable
          during the fabrication and packaging process and are not a cause for
          rejection.
        </Typography>
        <Typography style={{ TypographyAlign: "left", width: "100%" }}>
          {"\u2022"} We do not allow CMG flat sheets to be drop-shipped and
          "toll-formed", all CMG material must be supplied by Industry Sheet
          Metal, unless specifically noted in the quote and or other written
          agreement between Industry Metal Co and Customer. All manufacturers
          (other than CMG) can be drop shipped and you will be charged for
          fabrication only.
        </Typography>
        <Typography style={{ TypographyAlign: "left", width: "100%" }}>
          {"\u2022"} We are not responsible for extra material, or shorted
          material - Flat Sheets and/or Coil, on customer supplied material.
        </Typography>
        <Typography style={{ TypographyAlign: "left", width: "100%" }}>
          {"\u2022"} Flat Sheets stored over 30 days shall become the property
          of Industry Metal Co, unless another agreement is in place. (Only
          written agreements shall be valid).
        </Typography>
        <Typography style={{ TypographyAlign: "left", width: "100%" }}>
          {"\u2022"} We attempt to optimize all cuts to get the most product out
          of one flat sheet, however, we cannot guarantee perfect optimization.
        </Typography>
        <Typography style={{ TypographyAlign: "left", width: "100%" }}>
          {"\u2022"} When Flat Sheets are included in your trim / flashing
          pricing, we figure out how many flat sheets are needed for each piece
          of trim, and then you are charged for those flat sheets. For example,
          if you order (2) 6" stretch out pieces, you are still charged for the
          entire 48" flat sheet. Basically, we do not calculate utilizing the
          "drop" for other pieces in your order, nor do we provide you with the
          extra drop, therefore it is best for you to utilize entire sheets for
          each detail. The "drop" becomes property of Industry Metal Co.
        </Typography>
        <Typography style={{ TypographyAlign: "left", width: "100%" }}>
          {"\u2022"} Packages are billed upon contract acceptance, and the order
          will not be processed until paid for in full. All orders are "custom"
          and once we process material for your order, we typically cannot use
          for another order.
        </Typography>
        <Typography style={{ TypographyAlign: "left", width: "100%" }}>
          {"\u2022"} We are not liable for finished orders left over (30) days.
          We shall not be liable for material shortages / overages for
          quantities provided to you. For example, if you give us an order, and
          we tell you that you need 10 flat sheets but you need 12 (or
          vice-versa), we shall not be held liable for the extra or the
          shortage.
        </Typography>
        <Typography style={{ TypographyAlign: "left", width: "100%" }}>
          {"\u2022"} All hems are .5" and "open" unless noted otherwise.
        </Typography>
        <Typography style={{ TypographyAlign: "left", width: "100%" }}>
          {"\u2022"} All angles are 45deg or 90deg unless noted otherwise.
        </Typography>
        <Typography style={{ TypographyAlign: "left", width: "100%" }}>
          {"\u2022"} Industry Metal Co is not liable for pieces made with the
          color facing the wrong direction if the color direction is not clearly
          marked on the detail.
        </Typography>
        <Typography style={{ TypographyAlign: "left", width: "100%" }}>
          {"\u2022"} Delivery is not included unless specifically noted in
          contract.
        </Typography>
        <Typography style={{ TypographyAlign: "left", width: "100%" }}>
          {"\u2022"} Crating and Packaging shall be an Additional Charge, and is
          itemized in the contract
        </Typography>
        <Typography style={{ TypographyAlign: "left", width: "100%" }}>
          {"\u2022"} Implied Warranty: the warranty of our products is
          restricted to the paint finish only, and Industry Metal Co does not
          express or imply any type of warranty related to waterproofing,
          product performance, etc.
        </Typography>
      </Box>
    </AccordionDetails>
  );
};

const AccordionDetialsV2 = () => {
  return (
    <AccordionDetails>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          color: "#ccc8c8",
        }}
      >
        <Typography style={{ fontWeight: "bold", TypographyAlign: "center" }}>
          Terms of Service Agreement
        </Typography>
        <Typography
          style={{ TypographyAlign: "left", width: "100%", fontWeight: "bold" }}
        >
          {"\u2022"} All quotes are valid for 30 days only.
        </Typography>
        <Typography style={{ TypographyAlign: "left", width: "100%" }}>
          {"\u2022"} <strong>Payment:</strong> All orders must be pre-paid in
          full prior to fabrication via check or credit card unless your company
          is currently setup with payment terms, and account must be be current
        </Typography>
        <Typography style={{ TypographyAlign: "left", width: "100%" }}>
          {"\u2022"} <strong>Credit Card Convenience Fee:</strong> If paying
          with Credit Card, we will charge a convenience fee of 2.5%. Packages
          are billed upon contract acceptance, and the order will not be
          processed until paid for in full
        </Typography>
        <Typography style={{ TypographyAlign: "left", width: "100%" }}>
          {"\u2022"} <strong>Sales Tax:</strong> For Sales Tax please indicate
          if this is a taxable sale or not. If not taxable, we must have a
          current AZ5005 on file. This Quote does not include Sales Tax
        </Typography>
        <Typography style={{ TypographyAlign: "left", width: "100%" }}>
          {"\u2022"} <strong>Delivery:</strong> Delivery is not included unless
          specifically noted in the quote. If we are delivering your material,
          it shall be up to you to have sufficient manpower and/or equipment
          on-site at the time of our delivery to offload. Our delivery service
          does not include off-loading from our truck. Our driver will help
          where he can, but his assistance is not guaranteed. A typical
          truckload should take no more than 30 minutes to unload, and anything
          in excess to this allotted time frame will be charged at a rate of $40
          per every 15 minutes, and charged to the credit card used to place the
          order, or to the credit account. If nobody is onsite, and we need to
          schedule a re-delivery, there will be an additional charge
        </Typography>
        <Typography style={{ TypographyAlign: "left", width: "100%" }}>
          {"\u2022"} <strong>Minor Scratches:</strong> Minor scratches, chips,
          scrapes, dings, etc are inevitable during the fabrication and
          packaging process and are not a cause for rejection.
        </Typography>
        <Typography style={{ TypographyAlign: "left", width: "100%" }}>
          {"\u2022"} <strong>Toll Forming:</strong> If material is provided by
          customer (“Toll-Forming), Industry is not responsible for any overages
          or shortages. If tollforming, it is the sole responsibility of the
          customer to figure / calculate the material needed and to place the
          order, etc with the raw material supplier. It is also the
          responsibility of the customer to arrange (and pay for) any associated
          freight and delivery fees. Once the material hits the Industry Metal
          Co facility, we will count and document all material. If we believe it
          to be short, we will notify you immediately, and will not proceed with
          the order unless the estimated shortage is acknowledged. If there is a
          shortage of material, regardless of whether we notify you or not, it
          shall be the responsibility of the customer to source, pay for, and
          supply the shorted material. Industry Metal Co has no liability for
          material shortage, and/or damaged material, on toll-formed orders. All
          extra flat sheets will be returned to the customer, as we do not store
          customer material. All scrap pieces will be discarded. Sheet
          Optimization: We do our best to optimize the material used on your
          order, and will always figure what we believe is the most optimal
          method to utilize all material drop. However, we do not accept
          customer directions / instructions on “how to use the various drops” -
          this applies to both toll-forming and single-bill packages. We cannot
          guarantee perfect optimization / drop usage.
        </Typography>
        <Typography style={{ TypographyAlign: "left", width: "100%" }}>
          {"\u2022"} <strong>Material Storage:</strong> Industry Metal Co is not
          a storage facility, and unless another agreement is in place, we do
          not store customer material for future fabrication. All toll-forming
          material to be dropped on a “per-package” / as-needed basis Finished
          packages left over (15) days may be moved outside, and Industry Metal
          Co shall not be liable for any damage and/or weathering that may occur
          Finished packages left over (30) will be discarded, unless a written
          agreement is in place to hold for longer than 30 days Flat Sheets
          stored over 30 days shall become the property of Industry Metal Co,
          unless another agreement is in place. (Only written agreements shall
          be valid).
        </Typography>
        <Typography style={{ TypographyAlign: "left", width: "100%" }}>
          {"\u2022"} <strong>Fabrication:</strong> All hems are .5" and "open"
          unless noted otherwise. All angles are 45deg or 90deg unless noted
          otherwise. Industry Metal Co is not liable for pieces made with the
          color facing the wrong direction if the color direction is not clearly
          marked on the detail.
        </Typography>
        <Typography style={{ TypographyAlign: "left", width: "100%" }}>
          {"\u2022"} <strong>Details:</strong> All fabrication suggestions are
          suggestions only, and Industry Metal Co shall not be liable for
          details that do work and/or fit. It shall be up to the customer to
          verify that the detail is correct, regardless of whether we provided
          the detail. None of our published / custom details have testing
          validation - ie: wind, water, and rain.
        </Typography>
        <Typography style={{ TypographyAlign: "left", width: "100%" }}>
          {"\u2022"} <strong>Crating and Packaging</strong> shall be an
          Additional Charge, and is itemized in the contract
        </Typography>
        <Typography style={{ TypographyAlign: "left", width: "100%" }}>
          {"\u2022"} <strong>Implied Warranty:</strong> The warranty of our
          products is limited / restricted to the paint
        </Typography>
      </Box>
    </AccordionDetails>
  );
};

export default TermsOfAgreement;
