const accessoriesReducer = (state = {}, action) => {
  switch (action.type) {
    case "UPDATE ACCESSORIES: ITEM": {
      const itemsArr = [...state.items];
      const orderItemIndex = itemsArr.findIndex(
        (item) => item.objectType === "Accessory"
      );

      const accessoriesArr = [...itemsArr[orderItemIndex].items];

      const itemIndex = accessoriesArr.findIndex(
        (item) => item.name === action.payload.name
      );

      // If findIndex returns -1 (not found), add to array
      // Else replace found array element with updated
      if (itemIndex < 0) {
        accessoriesArr.push(action.payload);
      } else {
        accessoriesArr.splice(itemIndex, 1, action.payload);
      }
      itemsArr[orderItemIndex].items = [...accessoriesArr];
      console.log("itemsArr", itemsArr);
      return { ...state, items: [...itemsArr] };
    }
    case "DELETE ACCESSORIES: ITEM": {
      const itemsArr = [...state.items];
      // Get Accessory Order Item
      const orderItemIndex = itemsArr.findIndex(
        (item) => item.objectType === "Accessory"
      );

      // Copy Accessory Item Array
      const accessoriesArr = [...itemsArr[orderItemIndex].items];

      const itemIndex = accessoriesArr.findIndex(
        (item) => item.name === action.payload.name
      );

      accessoriesArr.splice(itemIndex, 1);

      console.log("AFTER DELETE", accessoriesArr);
      itemsArr[orderItemIndex].items = [...accessoriesArr];

      return { ...state, items: [...itemsArr] };
    }
    default:
      return state;
  }
};
export default accessoriesReducer;
