import initialOrderValue from "./InitialOrderObject";
import MainOrderView from "./views/MainOrderView";
import { useState, useReducer, useEffect, useContext, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getPresignedUrl, uploadImage } from "./imageUpload";
import { AuthContext } from "../../context/AuthContext";
import { piecesBasedOnType } from "./orderUtils";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import getEstimateDocDefinition from "../../pdf-helpers/EstimateHelper";
import combineReducers from "../../reducers/combineReducers";
import detailReducer from "../../reducers/detailsReducer";

import trimReducer from "../../reducers/trimReducer";
import panelReducer from "../../reducers/panelReducer";
import capReducer from "../../reducers/capReducer";
import cleatReducer from "../../reducers/cleatReducer";
import accessoriesReducer from "../../reducers/accessoriesReducer";
import benchReducer from "../../reducers/benchReducer";
import spliceReducer from "../../reducers/spliceReducer";
import useVariables from "../../hooks/useVariables";
import { Backdrop, Box, CircularProgress, Typography } from "@mui/material";
import OrderTitle from "../OrderTitle";
import { SnackAlertContext } from "../../context/SnackAlertContext";
import { orderTotals } from "./calculations/orderTotals";
import { toThreeDecimals } from "./calculations/utils";
import PrintableQRCode from "../QrCode/PrintableQRCode";
import moment from "moment/moment";
import flatSheetReducer from "../../reducers/flatSheetReducer";
import coilReducer from "../../reducers/coilReducer";

pdfMake.fonts = {
  Roboto: {
    normal:
      "https://ezorder-public.s3.us-east-2.amazonaws.com/fonts/Roboto-Regular.ttf",
    bold: "https://ezorder-public.s3.us-east-2.amazonaws.com/fonts/Roboto-Medium.ttf",
    italics:
      "https://ezorder-public.s3.us-east-2.amazonaws.com/fonts/Roboto-BoldItalic.ttf",
    bolditalics:
      "https://ezorder-public.s3.us-east-2.amazonaws.com/fonts/Roboto-MediumItalic.ttf",
  },
  AlexBrush: {
    normal: `https://ezorder-public.s3.us-east-2.amazonaws.com/fonts/AlexBrush-Regular.ttf`,
  },
};

const Order = () => {
  const navigate = useNavigate();
  const { authToken, ezorder } = useContext(AuthContext);
  const { openSnackMessage } = useContext(SnackAlertContext);
  const { orderId } = useParams();
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [order, setOrder] = useState(null);

  const [isGeneratingEstimate, setIsGeneratingEstimate] = useState(false);
  const [isGeneratingInvoice, setIsGeneratingInvoice] = useState(false);

  const authHeader = {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  };

  const withSetLoading =
    (fn) =>
    async (...args) => {
      setLoading(true);
      await fn(...args);
      setLoading(false);
    };

  const { variables, getDefaultVariables } = useVariables();

  const getOrderById = async (id) => {
    try {
      // setLoading(true);
      // REMOVE ID from Default Variables because we're just copying the values
      // This object is added to pieces if their variable property is null
      let defaultVariables = await getDefaultVariables();
      delete defaultVariables.id;

      const response = await ezorder.get(`/admin/orders/${id}`, authHeader);
      // console.log("API Response: ", response.data.order);
      if (response.status === 200 || response.status == 201) {
        setOrder({
          ...response.data.order,
          defaultVariables: defaultVariables,
        });
      }
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const getOrderWithLoading = withSetLoading(getOrderById);

  const updateOrder = async (order) => {
    try {
      console.log("updateOrder Input", order);
      setLoading(true);
      const res = await ezorder.put(
        `/admin/orders/${order.id}`,
        order,
        authHeader
      );
      console.log(res);
      setOrder(res.data.order);
      openSnackMessage("success", "Updated!");
    } catch (error) {
      console.log(error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage("error", error.response.data.error);
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", error);
      }
    } finally {
      setLoading(false);
    }
  };

  const updateWithLoading = withSetLoading(updateOrder);

  const addComment = async (commentText) => {
    try {
      setLoading(true);

      let requestBody = {
        comment: commentText,
      };

      const response = await ezorder.post(
        `/admin/orders/${order.id}/comment`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      setOrder(response.data.order);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const updatePickupStatus = async (
    pickUpStatus,
    deliveredBy,
    deliveredOn,
    pickedUpBy,
    pickedUpOn
  ) => {
    try {
      setLoading(true);

      let requestBody = {
        pickUpStatus,
        deliveredBy,
        deliveredOn,
        pickedUpBy,
        pickedUpOn,
      };

      const response = await ezorder.put(
        `/admin/orders/${order.id}/pickup-status`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      setOrder(response.data.order);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const addPickupNotes = async (pickupNotes) => {
    try {
      setLoading(true);

      let requestBody = {
        pickupNotes,
      };

      const response = await ezorder.post(
        `/admin/orders/${order.id}/pickup-notes`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      setOrder(response.data.order);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const updateMaterialStatus = async (materialStatus, materialNotes) => {
    try {
      setLoading(true);
      console.log("updateMaterialStatus", materialStatus, materialNotes);

      let requestBody = {
        materialStatus,
        materialNotes,
      };

      const response = await ezorder.put(
        `/admin/orders/${order.id}/material`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      setOrder(response.data.order);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const onUpdateEstimatedCompletionDate = async (
    shopEstimateCompletionDate
  ) => {
    try {
      setLoading(true);
      const response = await ezorder.patch(
        `/shop/orders/${order.id}/estimated-completion`,
        { shopEstimateCompletionDate },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      // console.log(response.data);
      getOrderById(order.id);
    } catch (error) {
      console.log(error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage("error", error.response.data.error);
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", error);
      }
    } finally {
      setLoading(false);
    }
  };

  const onChangeShopStatus = async (statusChange) => {
    try {
      setLoading(true);
      const response = await ezorder.patch(
        `/shop/orders/${order.id}/shop-status`,
        { shopStatus: statusChange },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      // console.log(response.data);
      getOrderById(order.id);
    } catch (error) {
      console.log(error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage("error", error.response.data.error);
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", error);
      }
    } finally {
      setLoading(false);
    }
  };

  const onUpdateShopImages = async (savedImages, newImageBlobs) => {
    try {
      setLoading(true);
      let imageCount = savedImages.length + newImageBlobs.length;
      if (imageCount > 15) {
        throw "15 images Max";
      } else {
        let imgFormData = new FormData();
        for (let savedImage of savedImages) {
          imgFormData.append("savedImages[]", savedImage);
        }
        for (let imageBlob of newImageBlobs) {
          imgFormData.append("images", imageBlob);
        }
        let response = await ezorder.put(
          `/shop/orders/${order.id}/shop-images`,
          imgFormData,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
              "content-type": "multipart/form-data",
            },
          }
        );
        openSnackMessage("success", "Updated!");
        getOrderById(order.id);
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage("error", error.response.data.error);
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", error);
      }
    } finally {
      setLoading(false);
    }
  };

  const onUpdatePickupImages = async (savedImages, newImageBlobs) => {
    try {
      setLoading(true);
      let imageCount = savedImages.length + newImageBlobs.length;
      if (imageCount > 15) {
        throw "15 images Max";
      } else {
        let imgFormData = new FormData();
        for (let savedImage of savedImages) {
          imgFormData.append("savedImages[]", savedImage);
        }
        for (let imageBlob of newImageBlobs) {
          imgFormData.append("images", imageBlob);
        }
        let response = await ezorder.put(
          `/shop/orders/${order.id}/pickup-images`,
          imgFormData,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
              "content-type": "multipart/form-data",
            },
          }
        );
        openSnackMessage("success", "Updated!");
        getOrderById(order.id);
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage("error", error.response.data.error);
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", error);
      }
    } finally {
      setLoading(false);
    }
  };

  // const addShopNotes = async (shopNotes) => {
  //   try {
  //     setLoading(true);

  //     let requestBody = {
  //       shopNotes,
  //     };

  //     const response = await ezorder.post(
  //       `/shop/orders/${order.id}/shop-notes`,
  //       requestBody,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${authToken}`,
  //         },
  //       }
  //     );
  //     setOrder(response.data.order);
  //   } catch (error) {
  //     console.log(error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const rootReducer = combineReducers(
    detailReducer,
    flatSheetReducer,
    coilReducer,
    trimReducer,
    panelReducer,
    capReducer,
    cleatReducer,
    accessoriesReducer,
    benchReducer,
    spliceReducer
  );

  const [orderState, orderDispatch] = useReducer(
    rootReducer,
    initialOrderValue
  );

  useEffect(() => {
    console.log("ORDER STATE UPDATED", orderState);
  }, [orderState]);

  const buildOrderState = (order) => {
    console.log("BUILD ORDER STATE");
    // adds empty orderItem objects if they don't exist
    if (!order) return;
    let newOrder = deepCopy(order);
    const items = [
      "FlatSheet",
      "Coil",
      "Panel",
      "TrimAndFlashing",
      "BenchWork",
      "CopingCap",
      "CopingCapCleat",
      "SplicePlate",
      "Accessory",
    ];
    const orderedItems = newOrder.items.map((item) => item.objectType);
    // For some reason, the initialOrderValue object is being mutated when uploading trim,benchwork, etc, pieces
    // We are using a copy of the initial order items instead
    const initialOrderValueItemsCopy = [
      {
        objectType: "FlatSheet",
        totalCost: 0,
        quantity: 0,
        width: 48,
      },
      {
        objectType: "Coil",
        totalCost: 0,
        linealFeet: 0,
        width: 48,
      },
      {
        totalCost: 0,
        objectType: "Panel",
        panelSystem: "Standing Seam",
        panelType: "SL-175, Striated With Clip Relief",
        panelTakeUp: null,
        panelWidth: 18,
        coilSquareFeet: null,
        coilSquareFeetWithDrop: null,
        panelMasterCoilWidth: null,
        totalPanelAreaCoverage: null,
        panelProfile: "",
        cuts: [],
      },
      {
        totalCost: 0,
        objectType: "TrimAndFlashing",
        trimPieces: [],
      },
      {
        totalCost: 0,
        objectType: "BenchWork",
        benchWorkPieces: [],
      },
      {
        totalCost: 0,
        objectType: "CopingCap",
        capPieces: [],
      },
      {
        totalCost: 0,
        objectType: "CopingCapCleat",
        gauge: "",
        color: null,
        cleatPieces: [],
      },
      {
        totalCost: 0,
        objectType: "SplicePlate",
        plates: [],
      },
      {
        totalCost: 0,
        objectType: "Accessory",
        items: [],
      },
    ];
    const newItemsArray = items.map((item) => {
      if (!orderedItems.includes(item)) {
        let orderItem = initialOrderValueItemsCopy.find(
          (obj) => obj.objectType === item
        );
        // let orderItem = initialOrderValue.items.find(
        //   (obj) => obj.objectType === item
        // );

        return orderItem;
      } else {
        return newOrder.items.find((obj) => obj.objectType === item);
      }
    });

    newOrder.items = [...newItemsArray];
    if (!newOrder) return;
    orderDispatch({
      type: "SET ORDER STATE",
      payload: { ...newOrder },
    });
  };
  const deconstructOrder = (order) => {
    // removes order items without any pieces, before order is submitted;
    const orderCopy = deepCopy(order);
    const updatedItems = orderCopy.items.reduce(
      (accumulator, current) =>
        (current.objectType == "FlatSheet" && current.quantity > 0) ||
        (current.objectType == "Coil" &&
          current.width > 0 &&
          current.linealFeet > 0)
          ? [...accumulator, current]
          : current.objectType != "FlatSheet" &&
            current.objectType != "Coil" &&
            piecesBasedOnType(current)?.pieces.length > 0
          ? [...accumulator, current]
          : [...accumulator],
      [] // initial value of the accumulator
    );
    orderCopy.items = [...updatedItems];
    // console.log(orderCopy);
    return orderCopy;
  };

  const handleSumbitOrder = () => {
    updateWithLoading(deconstructOrder(orderState));
  };

  const createWritePresignedUrl = async (
    actionType,
    fileName,
    contentType,
    resource
  ) => {
    console.log("Create Presigned URL");

    const identifier =
      order.company && order.company.id ? order.company.id : "GUEST";

    const response = await ezorder.post(
      "/files/presignedUrl",
      { actionType, fileName, contentType, resource, identifier },
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );

    return response.data;
  };
  let getEstimateBlobPromise = function (pdfGenerator) {
    return new Promise((resolve, reject) => {
      pdfGenerator.getBlob((blob) => {
        console.log("BLOB", blob);
        resolve(blob);
      });
    });
  };

  const generateEstimateForOrder = async () => {
    try {
      setIsGeneratingEstimate(true);
      // Update Order
      let totals = orderTotals(orderState.items, orderState.skidCharge);
      let outTheDoorCost = totals.outTheDoorCost;
      let data = {
        ...deconstructOrder(orderState),
        outTheDoorCost: outTheDoorCost,
        isNewEstimateRequested: false,
        estimateVersion: "v2",
      };
      await updateOrder(data);
      // Then Generate Estimate
      const docDef = await getEstimateDocDefinition(orderId, authToken);

      // let fonts = {
      //   Roboto: {
      //     normal: "fonts/Roboto-Regular.ttf",
      //     bold: "fonts/Roboto-Medium.ttf",
      //     italics: "fonts/Roboto-Italic.ttf",
      //     bolditalics: "fonts/Roboto-MediumItalic.ttf",
      //   },
      //   AlexBrush: {
      //     normal: "fonts/AlexBrush-Regular.ttf",
      //   },
      // };
      // let pdfGenerator = pdfMake.createPdf(docDef, null, fonts); //.open();
      let pdfGenerator = pdfMake.createPdf(docDef); //.open();

      let estimatePdfBlob = await getEstimateBlobPromise(pdfGenerator);
      const presignedUrlResponse = await createWritePresignedUrl(
        "WRITE",
        "estimate.pdf",
        estimatePdfBlob.contentType,
        "estimates"
      );
      const presignedUploadUrl = presignedUrlResponse.uploadUrl;
      const s3Key = presignedUrlResponse.key;

      const fetchResult = await fetch(presignedUploadUrl, {
        method: "PUT",
        body: estimatePdfBlob,
        headers: {
          "Content-Type": estimatePdfBlob.contentType, // DO NOT PASS IN A BEARER TOKEN
        },
      });

      const response = await ezorder.put(
        `/admin/orders/${order.id}/estimate`,
        {
          estimateS3Key: s3Key,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      await getOrderById(orderId);
    } catch (error) {
      console.log(error);
      // alert(error);
    } finally {
      setIsGeneratingEstimate(false);
    }
  };

  const updateOrderStatus = async (status, data) => {
    setLoading(true);
    try {
      await updateWithLoading(deconstructOrder(orderState));

      let formData = new FormData();
      formData.append("orderId", order.id);
      formData.append("status", status);
      formData.append("data", JSON.stringify(data));
      if (status === "ESTIMATE_APPROVAL_NEEDED") {
        let docDef = await getEstimateDocDefinition(orderId, authToken);
        let pdfGenerator = pdfMake.createPdf(docDef); //.open();
        let estimatePdfBlob = await getEstimateBlobPromise(pdfGenerator);
        console.log("estimatePdfBlob", estimatePdfBlob);
        formData.append("files", estimatePdfBlob);
      }
      console.log("FORMDATA", formData);
      for (var pair of formData.entries()) {
        console.log("  " + pair[0] + ", " + pair[1]);
      }
      const response = await ezorder.patch(
        `/admin/orders/${order.id}/status`,
        // {
        //   orderId: order.id,
        //   status,
        //   data,
        // },
        formData,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "content-type": "multipart/form-data",
          },
        }
      );

      if (status == "ESTIMATE_APPROVED") {
        navigate(`/orders/${order.id}`);
      }

      await getOrderById(orderId);
      openSnackMessage("success", "Saved!");
    } catch (error) {
      console.log(error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage("error", error.response.data.error);
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", error);
      }
    } finally {
      setLoading(false);
    }
  };

  const resendEstimate = async (emailBody, sendTo) => {
    try {
      setLoading(true);
      const response = await ezorder.post(
        `/admin/orders/${order.id}/estimate/resend`,
        {
          emailBody,
          sendTo,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      await getOrderById(orderId);
    } catch (error) {
      console.log(error);
      // throw Error("Unable to update order status", { cause: error });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getDefaultVariables();
    getOrderWithLoading(orderId);
  }, []);

  useEffect(() => {
    buildOrderState(order);
  }, [order]);

  return (
    <Box sx={{ marginBottom: "200px" }}>
      <Box sx={{ display: "flex", flexDirection: "row", marginBottom: "16px" }}>
        <Box
          sx={{
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <OrderTitle order={orderState} />
        </Box>
        {order?.id && (
          <PrintableQRCode
            jobId={order.id}
            customerName={order.company ? order.company.name : ""}
            jobName={order.project ? order.project.name : ""}
            displaySize={100}
            url={`${window.location.host}/orders/${order.id}`}
          />
        )}
      </Box>

      <MainOrderView
        state={orderState}
        action={orderDispatch}
        deconstructOrder={deconstructOrder}
        getPresignedUrl={getPresignedUrl(ezorder, authToken, orderState)}
        uploadImage={uploadImage}
        updateOrder={updateOrder}
        // handleOverrideValues={handleOverrideValues}
        handleSubmit={handleSumbitOrder}
        // generateQuickbooksInvoiceAndUpdateStatusToPendingPayment={
        //   generateQuickbooksInvoiceAndUpdateStatusToPendingPayment
        // }
        generateEstimateForOrder={generateEstimateForOrder}
        isGeneratingEstimate={isGeneratingEstimate}
        isGeneratingInvoice={isGeneratingInvoice}
        updateOrderStatus={updateOrderStatus}
        resendEstimate={resendEstimate}
        addComment={addComment}
        updatePickupStatus={updatePickupStatus}
        addPickupNotes={addPickupNotes}
        updateMaterialStatus={updateMaterialStatus}
        // updateOrderProgressStatus={updateOrderProgressStatus}
        onUpdateEstimatedCompletionDate={onUpdateEstimatedCompletionDate}
        onChangeShopStatus={onChangeShopStatus}
        onUpdateShopImages={onUpdateShopImages}
        onUpdatePickupImages={onUpdatePickupImages}
        // addShopNotes={addShopNotes}
        error={error}
        setLoading={setLoading}
        isLoading={isLoading}
        data={order}
      />
    </Box>
  );
};
export default Order;

function deepCopy(object) {
  return JSON.parse(JSON.stringify(object));
}
