import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import {
  KeyboardArrowLeftOutlined,
  KeyboardArrowRightOutlined,
} from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";

const SortableCut = ({
  orderedCut,
  showLeftAndRightButtons = false,
  disableLeft,
  onClickLeft,
  disableRight,
  onClickRight,
}) => {
  const sortable = useSortable({ id: orderedCut.tempId });
  const {
    attributes,
    listeners,
    isDragging,
    setNodeRef,
    transform,
    transition,
  } = sortable;

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Box
      ref={setNodeRef}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        border: 1,
        cursor: "pointer",
        "&:hover": {
          background: "#5A5A5A",
        },
      }}
      style={{
        transform: transform
          ? `translate3d(${transform.x}px, ${transform.y}px, 0)`
          : undefined,
        transition: transition,
      }}
      {...attributes}
      {...listeners}
    >
      {showLeftAndRightButtons && (
        <IconButton
          disabled={disableLeft}
          color="primary"
          onClick={() => {
            onClickLeft();
          }}
        >
          <KeyboardArrowLeftOutlined />
        </IconButton>
      )}

      <Typography
        key={orderedCut.tempId}
        sx={{
          fontSize: "12px",
          padding: "8px",
          width: "120px",
          textAlign: "left",
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "-webkit-box",
          WebkitLineClamp: "1",
          WebkitBoxOrient: "vertical",
        }}
        inline
      >
        {orderedCut.stretchOut}" - {orderedCut.name}
      </Typography>
      {showLeftAndRightButtons && (
        <IconButton
          disabled={disableRight}
          color="primary"
          onClick={() => {
            onClickRight();
          }}
        >
          <KeyboardArrowRightOutlined />
        </IconButton>
      )}
    </Box>
  );
};

export default SortableCut;
