import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { Box, Card, Typography } from "@mui/material";
import ListFooter from "../../../components/InfiniteScroller/ListFooter";
import { Check, Draw } from "@mui/icons-material";
import InfiniteScroller from "../../../components/InfiniteScroller/InfiniteScroller";
import moment from "moment/moment";

import usePageName from "../../../hocs/usePageName";
import { useNavigate } from "react-router-dom";

const TimeApprovals = ({ navigation }) => {
  usePageName({ heading: "Time Approvals" });
  const { authToken, ezorder } = useContext(AuthContext);
  const navigate = useNavigate();
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [timeCardApprovalRequests, setTimeCardApprovalRequests] = useState([]);

  const [modalVisible, setModalVisible] = useState(false);

  const getTimeCardApprovalRequests = async () => {
    try {
      setIsRefreshing(true);
      const response = await ezorder.get(
        `/shop/crew/time-card/approval-requests`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      console.log("Time Card Approval Requests", response.data.requests);
      setTimeCardApprovalRequests(response.data.requests);
    } catch (error) {
      console.log(error);
    } finally {
      setIsRefreshing(false);
    }
  };

  useEffect(() => {
    getTimeCardApprovalRequests();
  }, []);

  return (
    <>
      <InfiniteScroller
        items={timeCardApprovalRequests}
        renderItem={(item, index) => {
          return (
            <Card
              style={{ margin: 8 }}
              key={index}
              onClick={() => {
                navigate(`/time-approvals/${item.id}`);
              }}
            >
              <Box
                style={{
                  padding: 8,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Box style={{ flex: 1 }}>
                  <Typography>
                    {moment(item.firstDay).format("MM/DD/YYYY")} -{" "}
                    {moment(item.lastDay).format("MM/DD/YYYY")}
                  </Typography>
                  <Typography>Total Hours: {item.totalHours}</Typography>
                </Box>
                {item.signedOn ? (
                  <Check color="success" fontSize="large" />
                ) : (
                  <Draw color="primary" fontSize="large" />
                )}
              </Box>
            </Card>
          );
        }}
        // onRefresh={onRefresh}
        onEndReached={() => {}}
        loading={isRefreshing}
        ListFooterComponent={() => {
          return <ListFooter hasMore={false} onClick={null} />;
        }}
      />
    </>
  );
};

export default TimeApprovals;
