import {
  TextField,
  Typography,
  Box,
  MenuItem,
  Button,
  IconButton,
  useMediaQuery,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Card,
  Dialog,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddProductModal from "../AddProductModal";
import { useState, useContext } from "react";
import { MobileOrderContext } from "../../../../../../context/MobileOrderContext";
import Catalog from "../../../../../../components/Catalog/Catalog";
import useCopingCapCatalog from "../../../../../../hooks/useCopingCapCatalog";
import { AuthContext } from "../../../../../../context/AuthContext";
import S3PresignedImage from "../../../../../../components/CustomerComponents/S3/S3PresignedImage";
import useUploadImages from "../../../../../../hooks/useUploadImages";
import CircularProgress from "@mui/material/CircularProgress";
import { useTheme } from "@emotion/react";
import { useEffect } from "react";
import { LoadingButton } from "@mui/lab";
import { Add } from "@mui/icons-material";
import TextFieldDebounced from "../../../../../../components/TextFieldDebounced";

const CopingCap = ({
  showSaving,
  isUpdating,
  isProductSelected,
  isAutoSaving,
  canUpdate,
  open,
  handleClose,
  projectName,
  handleNext,
  hasNext,
}) => {
  const { authToken } = useContext(AuthContext);
  const { cap, capDispatch, orderDispatch } = useContext(MobileOrderContext);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [
    searchQuery,
    setSearchQuery,
    results,
    onRefresh,
    refreshing,
    onLoadMore,
    loadingMore,
    total,
    hasMore,
  ] = useCopingCapCatalog(authToken, 10, null);
  const [catalogSelected, setCatalogSelected] = useState("Company");
  const [saveTrimImage] = useUploadImages("coping-caps");

  const [addProfile, setAddProfile] = useState(false);
  const [isSubmittingNewTrim, setIsSubmittingNewTrim] = useState(false);

  const handleNewTrim = async (name, imageBlob, resource, saveToCatalog) => {
    // const cap = await saveTrimImage(name, imageBlob, resource, saveToCatalog);
    // onRefresh();
    // setPiece((prev) => ({ ...prev, cap: { ...cap } }));
    // setShowPieceForm(true);

    try {
      setIsSubmittingNewTrim(true);
      const cap = await saveTrimImage(name, imageBlob, resource, saveToCatalog);

      capDispatch({
        type: "add piece",
        payload: {
          quantity: 1,
          name: cap.name,
          image: cap.image,
          cap,
        },
      });
      setAddProfile(false);
    } catch (error) {
      console.log(error);
    } finally {
      setIsSubmittingNewTrim(false);
      onRefresh(); // Refresh Catalog
    }
  };

  const selectTrim = (cap) => {
    capDispatch({
      type: "add piece",
      payload: {
        quantity: 1,
        name: cap.name,
        image: cap.image,
        cap,
      },
    });
    setAddProfile(false);
  };

  const handleRemovePiece = (index) => {
    capDispatch({ type: "remove piece", payload: index });
  };

  const handleEditPieceQuantity = (index, quantity) => {
    capDispatch({ type: "edit quantity", payload: { index, quantity } });
  };

  const handleSaveItem = (item) => {
    // if (!item.capPieces.length > 0) return;
    orderDispatch({ type: "add order item", payload: item });
  };

  useEffect(() => {
    if (isProductSelected && canUpdate && isAutoSaving) {
      // TODO: auto save on every change
      handleSaveItem(cap);
    }
  }, [isProductSelected, cap]);

  useEffect(() => {
    if (projectName) setSearchQuery(projectName);
  }, [projectName]);

  return (
    <AddProductModal
      showSaving={showSaving}
      isUpdating={isUpdating}
      open={open}
      handleClose={() => {
        handleSaveItem(cap);
        handleClose();
      }}
    >
      <DialogTitle>Coping Cap</DialogTitle>
      <DialogContent sx={{ gap: "1rem" }}>
        <Box
          sx={{ maxHeight: isSmallScreen ? "100%" : "28rem", overflow: "auto" }}
        >
          <Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography variant="h7" sx={{ flex: 1 }}>
                Profiles
              </Typography>
              {(!addProfile || isSubmittingNewTrim) && canUpdate && (
                <LoadingButton
                  sx={{
                    borderRadius: "17px", // Same as extended variant of Fab
                  }}
                  variant="contained"
                  loading={isSubmittingNewTrim}
                  color="success"
                  onClick={() => setAddProfile(!addProfile)}
                >
                  + Add
                </LoadingButton>
              )}
            </Box>

            {cap.capPieces.map((piece, index) => (
              <Box
                key={index}
                sx={{
                  marginTop: "2px",
                  padding: "8px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <S3PresignedImage s3Key={piece.image} size={100} />
                <Box
                  sx={{
                    flex: 1,
                    marginLeft: "8px",
                    display: "flex",
                    flexDirection: isSmallScreen ? "column" : "row",
                  }}
                >
                  <TextFieldDebounced
                    disabled={!canUpdate}
                    onFocus={(e) => e.target.select()}
                    size="small"
                    label="Name"
                    value={piece.name}
                    InputProps={{
                      readOnly: true,
                      disableUnderline: true,
                    }}
                  />
                  <TextFieldDebounced
                    disabled={!canUpdate}
                    sx={{
                      marginTop: isSmallScreen ? "8px" : 0,
                      marginLeft: isSmallScreen ? 0 : "8px",
                    }}
                    onFocus={(e) => e.target.select()}
                    type="number"
                    onWheel={(event) => {
                      event.preventDefault();
                    }}
                    size="small"
                    label="Quantity"
                    value={piece.quantity}
                    onChange={(e) => {
                      handleEditPieceQuantity(index, e.target.value);
                    }}
                  />
                </Box>
                {canUpdate && (
                  <IconButton
                    size="small"
                    onClick={() => {
                      handleRemovePiece(index);
                    }}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                )}
              </Box>
            ))}
          </Box>

          {/* {(!addProfile || isSubmittingNewTrim) && canUpdate && (
            <Box
              sx={{
                marginTop: "16px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <LoadingButton
                sx={{ minWidth: "300px" }}
                loading={isSubmittingNewTrim}
                variant="outlined"
                color="success"
                onClick={() => setAddProfile(!addProfile)}
              >
                <Add />
                Add
              </LoadingButton>
            </Box>
          )} */}
        </Box>
      </DialogContent>
      <DialogActions>
        <Box sx={{ marginTop: "3rem" }}>
          <Button
            onClick={() => {
              handleSaveItem(cap);
              handleClose();
            }}
          >
            {/* {canUpdate && isAutoSaving ? "Save & Close" : "Close"} */}
            Close
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              handleSaveItem(cap);
              handleNext();
            }}
          >
            {hasNext
              ? "Next"
              : canUpdate && isAutoSaving
              ? "Save & Exit"
              : "Exit"}
          </Button>
        </Box>
      </DialogActions>
      <Dialog
        keepMounted
        scroll={"paper"}
        maxWidth="md"
        fullWidth={true}
        fullScreen={isSmallScreen}
        PaperProps={{
          style: {
            background: theme.palette.secondary.main,
          },
        }}
        open={addProfile && !isSubmittingNewTrim}
        onClose={() => {
          setAddProfile(false);
        }}
      >
        <DialogTitle>
          Select Profile From Catalog Or Add Custom Piece
        </DialogTitle>
        <DialogContent>
          <Catalog
            search={searchQuery}
            setSearch={setSearchQuery}
            catalogSelected={catalogSelected}
            setCatalogSelected={setCatalogSelected}
            items={results}
            itemType={"Cleat"}
            onRefresh={onRefresh}
            refreshing={refreshing}
            onLoadMore={onLoadMore}
            isLoadingMore={loadingMore}
            saveNew={handleNewTrim}
            onSelect={selectTrim}
            hasMore={hasMore}
            enableSaveToCatalog={false}
            useFab={true}
            fabAbsolutePositionInContainer={true}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setAddProfile(false);
            }}
          >
            Back
          </Button>
        </DialogActions>
      </Dialog>
    </AddProductModal>
  );
};
export default CopingCap;
