import {
  DndContext,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  useDroppable,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
} from "@dnd-kit/sortable";
import { Box, Card, Typography } from "@mui/material";
import React, { useState } from "react";

const TestDragAndDrop = () => {
  const [activeId, setActiveId] = useState(null);
  const [taskList, setTaskList] = useState({
    Bill: [
      "Work Order 1 | 3 hrs",
      "Work Order 2 | 2.5 hrs",
      "Work Order 3 | 2 hrs",
    ],
    Bob: ["Work Order 4 | 3 hrs", "Work Order 5 | 5 hrs"],
    Bart: ["Work Order 8 | 8 hrs"],
  });

  const handleDragStart = (e) => {
    const { active } = e;

    setActiveId(active.id);
  };

  // Drag End Event Handler Function
  const dragEndHandler = (e) => {
    //e: DragEndEvent from "@dnd-kit/core"
    // Check if the position is still the same
    if (e.active.id == e.over?.id) return;

    // Check if it's not in the same column (moved to a different column)
    if (
      e.active.data.current?.sortable.containerId !=
      e.over.data.current?.sortable.containerId
    ) {
      return;
    }

    const containerName = e.active.data.current?.sortable.containerId;

    // Change the items position based on drag end target position
    setTaskList((taskList) => {
      const temp = { ...taskList };
      const oldIdx = temp[containerName].indexOf(e.active.id.toString());
      const newIdx = temp[containerName].indexOf(e.over.id.toString());
      temp[containerName] = arrayMove(temp[containerName], oldIdx, newIdx);
      return temp;
    });

    setActiveId(null);
  };

  const dragOverHandler = (e) => {
    // e: DragOverEvent
    // Check if item is drag into unknown area
    if (!e.over) return;

    // Get the initial and target sortable list name
    let initialContainer = e.active.data.current?.sortable?.containerId;
    let targetContainer = e.over.data.current?.sortable?.containerId;

    // If there are none initial sortable list name, then item is not sortable item

    // Order the item list based on target item position
    setTaskList((taskList) => {
      let temp = { ...taskList };

      // If there are no target container then item is moved into a droppable zone
      // droppable = whole area of sortable list (works when sortable list is empty)
      if (!targetContainer) {
        if (taskList[e.over.id].includes(e.active.id.toString())) return temp;

        // Remove item from it's original container
        temp[initialContainer] = temp[initialContainer].filter(
          (task) => task != e.active.id.toString()
        );

        // Add Item to it's target container which the droppable zone belongs to
        temp[e.over.id].push(e.active.id.toString());

        return temp;
      }

      // If the item is drag around in the same container then just reorder the list
      if ((initialContainer = targetContainer)) {
        let oldIdx = temp[initialContainer].indexOf(e.active.id.toString());
        let newIdx = temp[initialContainer].indexOf(e.over.id.toString());
        temp[initialContainer] = arrayMove(
          temp[initialContainer],
          oldIdx,
          newIdx
        );
      } else {
        // If the item is drag itno another different container

        // Remove item from its initial container
        temp[initialContainer] =
          temp[
            initialContainer.filter((task) => task != e.active.id.toString())
          ];

        // Add item to it's target container
        let newIdx = temp[targetContainer].indexOf(e.over.id.toString());
        temp[targetContainer].splice(newIdx, 0, e.active.id.toString());
      }
      return temp;
    });
  };

  return (
    <DndContext
      onDragStart={handleDragStart}
      onDragEnd={dragEndHandler}
      onDragOver={dragOverHandler}
    >
      <Box>
        {Object.keys(taskList).map((key) => {
          return <TaskList key={key} title={key} tasks={taskList[key]} />;
        })}
      </Box>
      <DragOverlay>
        {activeId ? <TaskItem title={activeId} /> : null}
      </DragOverlay>
    </DndContext>
  );
};

const TaskList = (props) => {
  const { isOver, setNodeRef } = useDroppable({
    id: props.title,
  });
  return (
    <SortableContext id={props.title} items={props.tasks}>
      <Card
        ref={setNodeRef}
        sx={{ padding: "16px", marginTop: "8px", opacity: isOver ? 1 : 0.5 }}
      >
        <Typography variant="h5">{props.title}</Typography>

        <ul style={{ minHeight: "100px" }}>
          {props.tasks.map((task) => {
            return <TaskItem key={task} title={task} />;
          })}
        </ul>
      </Card>
    </SortableContext>
  );
};

const TaskItem = (props) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: props.title });

  return (
    <li
      ref={setNodeRef}
      style={{
        transform: transform
          ? `translate3d(${transform.x}px, ${transform.y}px, 0)`
          : undefined,
        transition: transition,
      }}
      {...attributes}
      {...listeners}
    >
      {props.title}
    </li>
  );
};

export default TestDragAndDrop;
