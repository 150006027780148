import { useState, useContext, useEffect } from "react";
import usePageName from "../../../hocs/usePageName";
import { AuthContext } from "../../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import useTrimCatalog from "../../../hooks/useTrimCatalog";
import createPresignedUrl from "../../../helper/createPresignedUrl";
import uploadImage from "../../../helper/uploadImage";
import Catalog from "../../../components/Catalog/Catalog";
import { Box } from "@mui/material";

const TrimCatalog = () => {
  usePageName({ heading: "Trim Catalog" });
  const navigate = useNavigate();
  const { authToken, ezorder, role, decoded } = useContext(AuthContext);
  const [
    searchQuery,
    setSearchQuery,
    catalogFilter,
    setCatalogFilter,
    results,
    onRefresh,
    refreshing,
    onLoadMore,
    loadingMore,
    total,
    hasMore,
  ] = useTrimCatalog(authToken, 30, null, "catalog");

  const onItemSelect = async (catalogItem) => {
    console.log("SELECT HANDLER", catalogItem);
    navigate(`/catalog/${catalogItem.id}`);
  };

  const onSubmitNewItem = async (name, imageBlob, saveToCatalog) => {
    console.log("newItemHandler", name, imageBlob, saveToCatalog);

    // Create Presigned URL
    let actionType = "WRITE";
    let fileName = imageBlob.name;
    let contentType = imageBlob.type;
    let resource = "trims";
    let identifier = decoded.companyId;
    const presignedUrlResponse = await createPresignedUrl(
      ezorder,
      authToken,
      actionType,
      fileName,
      contentType,
      resource,
      identifier
    );
    console.log("presignedUrlResponse", presignedUrlResponse);

    // Upload URL
    await uploadImage(presignedUrlResponse.uploadUrl, imageBlob);

    await saveNewTrim(name, presignedUrlResponse.key, saveToCatalog);
    onRefresh();
  };

  const saveNewTrim = async (name, image, saveToCatalog) => {
    let newTrim = {
      name,
      image,
      saveToCatalog,
    };

    const response = await ezorder.post(`/products/trims`, newTrim, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    return response.data.trim;
  };

  useEffect(() => {
    console.log("hasMore", hasMore);
  }, [hasMore]);

  return (
    <Box sx={{ heght: "100%", display: "flex", flex: 1 }}>
      <Catalog
        search={searchQuery}
        setSearch={setSearchQuery}
        catalogFilter={catalogFilter}
        setCatalogFilter={setCatalogFilter}
        enableCatalogFilters={true}
        items={results}
        itemType={"Trim & Flashing"}
        onRefresh={onRefresh}
        refreshing={refreshing}
        onLoadMore={onLoadMore}
        isLoadingMore={loadingMore}
        saveNew={onSubmitNewItem}
        onSelect={onItemSelect}
        hasMore={hasMore}
        catalogCompanyName={decoded.companyName}
        enableSaveToCatalog={true}
        useFab={true}
        enablePrint={true}
        industryCatalogLink="/catalog/industry"
        companyCatalogLink="/catalog/stock"
      />
    </Box>
  );
};
export default TrimCatalog;
