import { useContext, useState } from "react";
import PurchaseOrderForm from "./PurchaseOrderForm";
import { AuthContext } from "../../../context/AuthContext";
import { useNavigate } from "react-router-dom";

const CreatePurchaseOrder = () => {
  const navigate = useNavigate();
  const { authToken, ezorder } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);

  const savePurchaseOrder = async (po) => {
    setIsLoading(true);

    try {
      const response = await ezorder.post(`/admin/purchase-orders`, po, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      navigate(`/purchase-orders/${response.data.po.id}`);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <PurchaseOrderForm
        existingPurchaseOrder={null}
        onSubmit={(po) => {
          savePurchaseOrder(po);
        }}
        disabled={isLoading}
      />
    </>
  );
};

export default CreatePurchaseOrder;
