import { useTheme } from "@emotion/react";
import { Box, Typography, Card, useMediaQuery } from "@mui/material";
import NotificationImportantIcon from "@mui/icons-material/NotificationImportant";

const ProjectCard = ({
  project,
  onClick,
  showOrderCount = true,
  showActionsRequired = true,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  let actionsRequired = 0;
  for (const order of project.orders) {
    if (
      order.orderStatus &&
      (order.orderStatus.name == "ESTIMATE_APPROVAL_NEEDED" ||
        order.orderStatus.name == "READY_FOR_PICKUP")
    ) {
      actionsRequired++;
    }
  }

  return (
    <Box
      variant={isSmallScreen ? Box : Card}
      background="secondary.dark"
      sx={{
        marginTop: "2px",
        background: theme.palette.secondary.main,
      }}
      onClick={onClick}
    >
      <Box sx={{ padding: "8px" }}>
        <Box sx={{}}>
          <Box style={{ display: "flex", flexDirection: "row" }}>
            <Typography
              variant="subtitle1"
              color="primary"
              sx={{ textTransform: "uppercase", fontWeight: "bold" }}
            >
              {project.name}
            </Typography>
          </Box>
          {showOrderCount && (
            <Box style={{ display: "flex", flexDirection: "row" }}>
              <Typography variant="subtitle2">Orders:</Typography>
              <Typography
                variant="subtitle2"
                sx={{
                  paddingLeft: "4px",
                  color:
                    project.orders?.length > 0
                      ? theme.palette.primary.light
                      : "inherit",
                }}
              >
                {project.orders.length}
              </Typography>
            </Box>
          )}
          {showActionsRequired && (
            <Box style={{ display: "flex", flexDirection: "row" }}>
              <Typography variant="subtitle2">Action Required: </Typography>
              <Typography
                variant="subtitle2"
                sx={{
                  paddingLeft: "4px",
                  color:
                    actionsRequired > 0
                      ? theme.palette.primary.light
                      : "inherit",
                }}
              >
                {actionsRequired}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ProjectCard;
