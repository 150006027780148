import { useContext, useEffect, useState } from "react";
import CollapsableCard from "../../../../../CollapseableCard";
import ezorder from "../../../../../../api/ezorderNoAuth";
import CustomerQuoteApproval from "./CustomerQuoteApproval";

const Approval = ({ action, order, updateOrderStatus }) => {
  const [estimateApprovedStatus, setEstimateApprovedStatus] = useState(null);

  const getOrderStatusByName = async () => {
    try {
      const response = await ezorder.get(`/order-statuses/ESTIMATE_APPROVED`);

      setEstimateApprovedStatus(response.data.status);
    } catch (error) {
      console.log("Failed To Load Invoice for Order", order.id);
      alert("Soemthing went wrong. Unable to load status");
    } finally {
    }
  };

  useEffect(() => {
    getOrderStatusByName();
  }, []);

  if (estimateApprovedStatus)
    return (
      <>
        <CollapsableCard title="Quote Approval">
          <CustomerQuoteApproval
            companyId={order.company.id}
            estimateApprovalDate={order.estimateApprovalDate}
            signedEstimatePDF={order.signedEstimatePDF}
            onManualApprovalFileUpload={(s3Key) => {
              updateOrderStatus("ESTIMATE_APPROVED", {
                signedEstimatePDF: s3Key,
              });
            }}
            downloadName={`SIGNED-CUSTOMER-QUOTE-${order.id}-${order.company.name}-${order.project.name}.pdf`}
          />
        </CollapsableCard>
      </>
    );
};
export default Approval;
