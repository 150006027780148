import {
  Box,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import FabButton from "../../../components/CustomerComponents/FabButton";
import { Add } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import useVendorPOs from "../../../hooks/useVendorPOs";
import { AuthContext } from "../../../context/AuthContext";
import { useContext } from "react";
import SearchBar from "../../../components/SearchBar";
import InfiniteScrollerTable from "../../../components/InfiniteScroller/InfiniteScrollerTable";
import ListFooter from "../../../components/InfiniteScroller/ListFooter";
import PurchaseOrderButton from "../../../components/PdfButtons/PurchaseOrderButton";
import moment from "moment/moment";
import BillOfMaterialsButton from "../../../components/PdfButtons/BillOfMaterialsButton";

const PurchaseOrders = ({
  selectedOrderId = null,
  isForOrder = false,
  isShopView = false,
}) => {
  const { authToken } = useContext(AuthContext);
  const [
    searchQuery,
    setSearchQuery,
    purchaseOrders,
    onRefresh,
    refreshing,
    onLoadMore,
    loadingMore,
    total,
    hasMore,
  ] = useVendorPOs(authToken, 20, selectedOrderId);

  const navigate = useNavigate();
  return (
    <Box>
      {!isForOrder && (
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography component="h1" variant="h5">
            Purchase Orders
          </Typography>
        </Box>
      )}

      {!isForOrder && (
        <SearchBar
          label="Search Orders"
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
        />
      )}
      <InfiniteScrollerTable
        TableContainerComponent={Paper}
        tableContainerStyle={{
          minHeight: "100px",
        }}
        tableStyle={{ minWidth: isShopView ? null : "600px" }}
        TableHead={() => (
          <TableHead>
            <TableRow>
              {!isShopView && <TableCell>PO ID</TableCell>}

              {!isShopView && <TableCell>Issue Date</TableCell>}
              {!isShopView && <TableCell>Vendor</TableCell>}
              {!isShopView && <TableCell>Contact</TableCell>}
              {!isShopView && <TableCell>Ship To</TableCell>}
              {!isShopView && <TableCell>For Order ID |PO #</TableCell>}
              <TableCell>Notes</TableCell>
              <TableCell>PDF</TableCell>
            </TableRow>
          </TableHead>
        )}
        items={purchaseOrders}
        renderRow={(item, index) => {
          return (
            <TableRow key={index}>
              {!isShopView && (
                <TableCell
                  onClick={() => {
                    if (!isShopView) navigate(`/purchase-orders/${item.id}`);
                  }}
                >
                  {item.id}
                </TableCell>
              )}

              {!isShopView && (
                <TableCell
                  onClick={() => {
                    if (!isShopView) navigate(`/purchase-orders/${item.id}`);
                  }}
                >
                  {moment(item.issueDate).format("MM/DD/YYYY")}
                </TableCell>
              )}
              {!isShopView && (
                <TableCell
                  onClick={() => {
                    if (!isShopView) navigate(`/purchase-orders/${item.id}`);
                  }}
                >
                  {item.vendor}
                </TableCell>
              )}
              {!isShopView && (
                <TableCell
                  onClick={() => {
                    if (!isShopView) navigate(`/purchase-orders/${item.id}`);
                  }}
                >
                  {item.contact}
                </TableCell>
              )}
              {!isShopView && (
                <TableCell
                  onClick={() => {
                    if (!isShopView) navigate(`/purchase-orders/${item.id}`);
                  }}
                >
                  {item.shippingAddress}
                </TableCell>
              )}
              {!isShopView && (
                <TableCell
                  onClick={() => {
                    if (!isShopView) navigate(`/purchase-orders/${item.id}`);
                  }}
                >
                  {item.order
                    ? `${item.order.id} | ${item.order.poNumber}`
                    : "N/A"}
                </TableCell>
              )}

              <TableCell
                onClick={() => {
                  if (!isShopView) navigate(`/purchase-orders/${item.id}`);
                }}
              >
                {item.notes}
              </TableCell>

              <TableCell>
                <PurchaseOrderButton
                  poId={item.id}
                  size="small"
                  buttonText="PO PDF"
                />
                <BillOfMaterialsButton
                  poId={item.id}
                  size="small"
                  buttonText="Bill Of Materials"
                />
              </TableCell>
            </TableRow>
          );
        }}
        onRefresh={onRefresh}
        onEndReached={onLoadMore}
        loading={refreshing || loadingMore}
        ListFooterComponent={() => {
          return (
            <ListFooter
              hasMore={hasMore}
              onClick={onLoadMore}
              //noMoreText={"End Reached"}
            />
          );
        }}
      />
      {!isForOrder && (
        <FabButton
          color="success"
          onClick={() => {
            navigate("/purchase-orders/new");
          }}
        >
          <Add sx={{ mr: 1 }} />
          Purchase Order
        </FabButton>
      )}
    </Box>
  );
};

export default PurchaseOrders;
