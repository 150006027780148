import { useTheme } from "@emotion/react";
import {
  Typography,
  Box,
  TextField,
  Checkbox,
  InputAdornment,
} from "@mui/material";
import { toTwoDecimals } from "../../../calculations/utils";

const AccessoryCard = ({
  checked,
  name,
  unitOfMeasure,
  cost,
  markUpPercentage,
  markUp,
  quantity,
  materialCost,
  totalSellPrice,
  inStock,
  handleChange,
  handleCheckBox,
}) => {
  const theme = useTheme();
  const toFixed = (digits) => (number) =>
    parseFloat(parseFloat(number ? number : 0).toFixed(digits));

  const to1 = toFixed(1);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          borderBottom: "1px solid grey",
        }}
      >
        <Box>
          <Checkbox checked={checked} onChange={(e) => handleCheckBox(e)} />
          <TextField
            disabled={!checked}
            size="small"
            variant="standard"
            label="Item"
            value={name}
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
            sx={{ width: "150px" }}
          />
        </Box>
        <TextField
          disabled={!checked}
          onFocus={(e) => e.target.select()}
          type="number"
          onWheel={(event) => {
            event.preventDefault();
          }}
          size="small"
          label={`Quantity (${unitOfMeasure})`}
          value={to1(quantity)}
          onChange={(e) => handleChange("quantity", e.target.value)}
          sx={{ marginLeft: "8px", flex: 1 }}
        />
        <TextField
          disabled={!checked}
          onFocus={(e) => e.target.select()}
          type="number"
          onWheel={(event) => {
            event.preventDefault();
          }}
          size="small"
          label="Cost"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Typography style={{ color: theme.palette.grey["500"] }}>
                  $
                </Typography>
              </InputAdornment>
            ),
          }}
          value={toTwoDecimals(cost)}
          onChange={(e) => handleChange("price", e.target.value)}
          sx={{ marginLeft: "8px", flex: 1 }}
        />
        <TextField
          disabled={!checked}
          size="small"
          variant="standard"
          label="Material Cost"
          value={toTwoDecimals(materialCost)}
          InputProps={{
            readOnly: true,
            disableUnderline: true,
            startAdornment: (
              <InputAdornment position="start">
                <Typography style={{ color: theme.palette.grey["500"] }}>
                  $
                </Typography>
              </InputAdornment>
            ),
          }}
          sx={{ marginLeft: "8px", flex: 1 }}
        />
        <TextField
          disabled={!checked}
          onFocus={(e) => e.target.select()}
          type="number"
          onWheel={(event) => {
            event.preventDefault();
          }}
          size="small"
          label="Markup"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Typography style={{ color: theme.palette.grey["500"] }}>
                  %
                </Typography>
              </InputAdornment>
            ),
          }}
          value={toTwoDecimals(markUpPercentage)}
          onChange={(e) => handleChange("markUpPercentage", e.target.value)}
          sx={{ marginLeft: "8px", flex: 1 }}
        />
        <TextField
          disabled={!checked}
          size="small"
          variant="standard"
          label="Markup"
          value={toTwoDecimals(markUp)}
          InputProps={{
            readOnly: true,
            disableUnderline: true,
          }}
          sx={{ marginLeft: "8px", flex: 1 }}
        />

        <TextField
          disabled={!checked}
          size="small"
          variant="standard"
          label="Total Sell Price"
          value={toTwoDecimals(totalSellPrice)}
          InputProps={{
            readOnly: true,
            disableUnderline: true,
          }}
          sx={{ marginLeft: "8px", flex: 1 }}
        />
        <TextField
          disabled={!checked}
          size="small"
          variant="standard"
          label="Status"
          value={inStock ? "In-Stock" : "Out"}
          InputProps={{
            readOnly: true,
            disableUnderline: true,
            startAdornment: (
              <InputAdornment position="start">
                <Typography style={{ color: theme.palette.grey["500"] }}>
                  $
                </Typography>
              </InputAdornment>
            ),
          }}
          sx={{ marginLeft: "8px", flex: 1 }}
        />
      </Box>
    </>
  );
};
export default AccessoryCard;
