import { updateOrderItemPiece, trimCalcs, withId } from "./utils";

const cleatReducer = (state, action) => {
  const cleatCalcs = trimCalcs(state, true);
  switch (action.type) {
    case "UPDATE CLEAT: ADD CLEAT": {
      let newArr = [...state.items];
      const index = newArr.findIndex(
        (obj) => obj.objectType === "CopingCapCleat"
      );
      newArr[index].cleatPieces.push(
        withId({
          ...action.payload,
          ...cleatCalcs(action.payload),
        })
      );
      return { ...state, items: [...newArr] };
    }
    case "UPDATE CLEAT: PIECE DETAILS": {
      const calculations = cleatCalcs(action.payload);
      const updatedItems = updateOrderItemPiece(state.items, "CopingCapCleat", {
        ...action.payload,
        ...calculations,
      });
      return { ...state, items: [...updatedItems] };
    }
    case "UPDATE CLEAT: GAUGE": {
      console.log("UPDATE CLEAT: GAUGE", action.payload);
      let newArr = [...state.items];
      const index = newArr.findIndex(
        (obj) => obj.objectType === "CopingCapCleat"
      );
      newArr[index].gauge = action.payload.gauge;
      newArr[index].gaugeUnitOfMeasure = action.payload.gaugeUnitOfMeasure;
      return {
        ...state,
        galvFlatSheetCost: action.payload.galvFlatSheetCost,
        items: [...newArr],
      };
    }
    case "UPDATE CLEAT: REMOVE CLEAT": {
      let newArr = [...state.items];
      const index = newArr.findIndex(
        (obj) => obj.objectType === "CopingCapCleat"
      );
      newArr[index].cleatPieces.splice(action.payload, 1);
      return { ...state, items: [...newArr] };
    }
    case "UPDATE CLEAT: OVERRIDE CALCULATION": {
      console.log(
        "YOU HAVE ENTERED THE REDUCER!!!!!!: UPDATE CLEAT: OVERRIDE CALCULATION"
      );
      const { trim, key, value } = action.payload;
      console.log("THE TRIM", trim, "THE KEY", key, "THE VALUE", value);
      const calcs = cleatCalcs(trim, key, value);
      console.log("THE CALCS", calcs);

      const x = { ...trim, ...calcs };
      console.log("THE X", x);
      const updatedItems = updateOrderItemPiece(state.items, "CopingCapCleat", {
        ...trim,
        ...x,
      });
      console.log("THE UPDATED ITEMS", updatedItems);
      return { ...state, items: [...updatedItems] };
    }
    case "UPDATE CLEAT: RESET OVERRIDE": {
      const { trim, key } = action.payload;
      const updatedItems = updateOrderItemPiece(state.items, "CopingCapCleat", {
        ...trim,
        ...cleatCalcs(trim, key, null),
      });
      return { ...state, items: [...updatedItems] };
    }
    case "UPDATE CLEAT: RUN CALCULATIONS": {
      const calculations = cleatCalcs(action.payload);
      const updatedItems = updateOrderItemPiece(state.items, "CopingCapCleat", {
        ...action.payload,
        ...calculations,
      });
      return { ...state, items: [...updatedItems] };
    }
    default:
      return state;
  }
};
export default cleatReducer;
