import { useState, useContext } from "react";
import {
  Card,
  CircularProgress,
  Grid,
  Box,
  TextField,
  Button,
  Typography,
  Container,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const ResetPasswordForm = ({
  email,
  resetPasswordHandler,
  resetSuccess,
  errorMessage,
}) => {
  const navigate = useNavigate();
  const [inValidRole, setInvalidRole] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);
  const handleMouseDownConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);

  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&?.,*])(?=.{8,})/;
  const formValidationSchema = Yup.object({
    password: Yup.string()
      .required("Password is required")
      .max(
        20,
        "Password Must Contain between 8-20 Characters, One Uppercase, One Lowercase, One Number and One Special Character"
      )
      .min(
        8,
        "Password Must Contain between 8-20 Characters, One Uppercase, One Lowercase, One Number and One Special Character"
      )
      .matches(
        passwordRegex,
        "Password Must Contain between 8-20 Characters, One Uppercase, One Lowercase, One Number and One Special Character"
      ),
    confirmPassword: Yup.string()
      .required("Required Field")
      .oneOf([Yup.ref("password")], "Passwords must match"),
  });

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: formValidationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: (values) => {
      resetPasswordHandler(values);
    },
  });

  return (
    <div>
      <Container maxWidth="xs" sx={{ paddingBottom: "24px" }}>
        <Card
          sx={{
            padding: "24px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography component="h1" variant="h5">
            Set Password
          </Typography>
          <Box
            component="form"
            onSubmit={formik.handleSubmit}
            noValidate
            sx={{ mt: 1, pt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              value={email}
              disabled
            />
            <Typography>
              Password Must Contain between 8-20 Characters, One Uppercase, One
              Lowercase, One Number and One Special Character
            </Typography>
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              id="password"
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              value={formik.values.password}
              onChange={formik.handleChange}
              autoComplete="current-password"
            />
            {formik.errors.password ? (
              <div>{formik.errors.password}</div>
            ) : null}
            <TextField
              margin="normal"
              required
              fullWidth
              name="confirmPassword"
              label="Confirm Password"
              id="confirmPassword"
              type={showConfirmPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowConfirmPassword}
                      onMouseDown={handleMouseDownConfirmPassword}
                    >
                      {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
            />
            {formik.errors.confirmPassword ? (
              <div>{formik.errors.confirmPassword}</div>
            ) : null}
            <Box
              sx={{
                m: 1,
                position: "relative",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {errorMessage && (
                <Box>
                  <Typography color={"red"}>{errorMessage}</Typography>
                </Box>
              )}

              {resetSuccess && (
                <Box>
                  <Typography color={"green"}>
                    Password has been updated
                  </Typography>
                </Box>
              )}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 1 }}
                disabled={isLoading}
              >
                Reset Password
              </Button>
              <Button
                variant="text"
                size="small"
                sx={{ mt: 1 }}
                disabled={isLoading}
                onClick={() => {
                  console.log("signup");
                  navigate("/");
                }}
              >
                Go back
              </Button>
              {isLoading && (
                <CircularProgress
                  size={24}
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              )}
            </Box>
          </Box>
          {inValidRole && (
            <Box sx={{ color: "#cf0404" }}>
              You Are Not Authorized To Visit This Page.
            </Box>
          )}
        </Card>
      </Container>
    </div>
  );
};

export default ResetPasswordForm;
