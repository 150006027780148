import {
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  Tooltip,
  IconButton,
  TextField,
} from "@mui/material";
import CachedIcon from "@mui/icons-material/Cached";
import { styled } from "@mui/material/styles";

export default function AdornedInput(props) {
  const {
    value,
    label,
    symbol,
    position,
    isOveridden,
    onWheel,
    onChange,
    handleReset,
    small,
  } = props;

  const CustomInputContainer = styled(TextField)`
    display: block;
    & .MuiInputBase-root {
      font-size: 12px;
      padding: 4px;
      height: 30px;
      box-sizing: border-box;
      position: relative;
    }

    & .MuiInputLabel-root {
      font-size: 12px;
      position: absolute;
      top: 0;
      left: 10px;
      transform: translate(0, 50%);
      pointer-events: none;
      transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    }

    & .MuiInputLabel-shrink {
      transform: translate(0, -50%);
    }

    & .MuiInput-underline:before {
      border-bottom: 1px solid rgba(0, 0, 0, 0.42);
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 1px;
    }

    &::placeholder {
      line-height: 30px;
      vertical-align: middle;
    }
  `;
  const overrideAlert = isOveridden
    ? {
        endAdornment: (
          <InputAdornment position="end">
            <Tooltip title="reset">
              <IconButton onClick={handleReset}>
                <CachedIcon
                  color="tertiary"
                  sx={{ marginLeft: 0, marginRight: 0 }}
                />
              </IconButton>
            </Tooltip>
          </InputAdornment>
        ),
      }
    : {};

  const startAdornment =
    symbol && position
      ? {
          startAdornment: (
            <InputAdornment position={position}>{symbol}</InputAdornment>
          ),
        }
      : {};

  return (
    <FormControl sx={{ maxWidth: "25ch" }}>
      <Tooltip title={label} placement="top" arrow>
        <InputLabel htmlFor="outlined-adornment-amount">{label}</InputLabel>
      </Tooltip>
      <OutlinedInput
        {...props}
        id="outlined-adornment-amount"
        size="small"
        {...overrideAlert}
        {...startAdornment}
        label={label}
        value={value}
        onChange={onChange}
        onWheel={onWheel}
      />
    </FormControl>
  );
}
