import {
  Typography,
  Box,
  TextField,
  CssBaseline,
  Container,
  Button,
  Card,
  IconButton,
  Checkbox,
  ListItem,
  ListItemText,
  FormGroup,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
  Fab,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useState, useContext, useEffect } from "react";
import { MobileOrderContext } from "../../../../../context/MobileOrderContext";
import SelectProductModal from "./SelectProductModal";
import Panel from "./OrderItems/Panel";
import Trim from "./OrderItems/Trim";
import CopingCap from "./OrderItems/CopingCap";
import Cleat from "./OrderItems/Cleat";
import BenchWork from "./OrderItems/BenchWork";
import SplicePlate from "./OrderItems/SplicePlate";
import Accessories from "./OrderItems/Accessories";
import { AuthContext } from "../../../../../context/AuthContext";
import moment from "moment/moment";
import { useTheme } from "@emotion/react";
import SubmitForQuoteDialog from "./SubmitForQuoteDialog";
import { LoadingButton } from "@mui/lab";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import ProductCard from "./ProductCard";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { Link } from "react-router-dom";
import FlatSheet from "./OrderItems/FlatSheet";
import Coil from "./OrderItems/Coil";
import ColorDetails from "./ColorDetails";

const MainOrderView = ({
  isRequestingNewEstimate,
  isAutoSaving,
  showSaving,
  isUpdating,
  onSave,
  canUpdateOrder,
  canRequestEstimate,
  requestEstimate,
  projectNameForNewOrder,
}) => {
  const { decoded } = useContext(AuthContext);
  const theme = useTheme();
  const {
    order,
    comments,
    orderDispatch,
    flatSheet,
    coil,
    panel,
    trim,
    accessories,
    bench,
    cleat,
    cap,
    splice,
  } = useContext(MobileOrderContext);

  const [flatSheetChecked, setFlatSheetChecked] = useState(
    order?.items?.find((i) => i.objectType === "FlatSheet") ? true : false
  );
  const [coilChecked, setCoilChecked] = useState(
    order?.items?.find((i) => i.objectType === "Coil") ? true : false
  );
  const [panelChecked, setPanelChecked] = useState(
    order?.items?.find((i) => i.objectType === "Panel") ? true : false
  );
  const [trimAndFlashingChecked, setTrimAndFlashingChecked] = useState(
    order?.items?.find((i) => i.objectType === "TrimAndFlashing") ? true : false
  );
  const [benchWorkChecked, setBenchWorkChecked] = useState(
    order?.items?.find((i) => i.objectType === "BenchWork") ? true : false
  );
  const [copingCapChecked, setCopingCapChecked] = useState(
    order?.items?.find((i) => i.objectType === "CopingCap") ? true : false
  );
  const [copingCapCleatChecked, setCopingCapCleatChecked] = useState(
    order?.items?.find((i) => i.objectType === "CopingCapCleat") ? true : false
  );
  const [splicePlateChecked, setSplicePlateChecked] = useState(
    order?.items?.find((i) => i.objectType === "SplicePlate") ? true : false
  );
  const [accessoryChecked, setAccessoryChecked] = useState(
    order?.items?.find((i) => i.objectType === "Accessory") ? true : false
  );

  const [openProductSelect, setOpenProductSelect] = useState(false);
  const toggleProductSelect = () => {
    setOpenProductSelect(!openProductSelect);
  };

  const [openAddFlatSheet, setOpenAddFlatSheet] = useState(false);
  const [openAddCoil, setOpenAddCoil] = useState(false);
  const [openAddPanel, setOpenAddPanel] = useState(false);
  const [openAddTrim, setOpenAddTrim] = useState(false);
  const [openAddCap, setOpenAddCap] = useState(false);
  const [openAddCleat, setOpenAddCleat] = useState(false);
  const [openAddBench, setOpenAddBench] = useState(false);
  const [openAddSplice, setOpenAddSplice] = useState(false);
  const [openAddAccessory, setOpenAddAccessory] = useState(false);

  const [showConfirmedCutListInfo, setShowConfirmedCutListInfo] =
    useState(false);
  const [showUnConfirmedCutListInfo, setShowUnConfirmedCutListInfo] =
    useState(false);

  const [submitDialogOpen, setSubmitDialogOpen] = useState(false);

  const toggleFlatSheet = () => {
    setOpenAddFlatSheet(!openAddFlatSheet);
  };
  const toggleCoil = () => {
    setOpenAddCoil(!openAddCoil);
  };
  const toggleAddPanel = () => {
    setOpenAddPanel(!openAddPanel);
  };
  const toggleAddTrim = () => {
    setOpenAddTrim(!openAddTrim);
  };
  const toggleAddCap = () => {
    setOpenAddCap(!openAddCap);
  };
  const toggleAddCleat = () => {
    setOpenAddCleat(!openAddCleat);
  };
  const toggleAddBench = () => {
    setOpenAddBench(!openAddBench);
  };
  const toggleAddSplice = () => {
    setOpenAddSplice(!openAddSplice);
  };
  const toggleAddAccessory = () => {
    setOpenAddAccessory(!openAddAccessory);
  };

  // TODO: need to go by order listed on checkboxes
  const hasNext = (currentItemObjectType) => {
    const itemTypes = [
      "FlatSheet",
      "Coil",
      "Panel",
      "TrimAndFlashing",
      "BenchWork",
      "CopingCap",
      "CopingCapCleat",
      "SplicePlate",
      "Accessory",
    ];

    // Find the index of Current Item
    let currentIndex = itemTypes.findIndex((t) => t === currentItemObjectType);
    let nextIndex = currentIndex + 1;

    for (nextIndex; nextIndex < itemTypes.length; nextIndex++) {
      let nextOrderItem = order?.items?.find(
        (i) => i.objectType === itemTypes[nextIndex]
      );
      if (nextOrderItem) {
        return true;
      }
    }

    return false;
  };
  const openNext = (currentItemObjectType) => {
    console.log("___OPEN NEXT", currentItemObjectType);
    const itemTypes = [
      "FlatSheet",
      "Coil",
      "Panel",
      "TrimAndFlashing",
      "BenchWork",
      "CopingCap",
      "CopingCapCleat",
      "SplicePlate",
      "Accessory",
    ];

    //     setBenchWorkChecked(
    //       order?.items?.find((i) => i.objectType === "BenchWork") ? true : false
    //     );

    // Find the index of Current Item
    let currentIndex = itemTypes.findIndex((t) => t === currentItemObjectType);
    let nextIndex = currentIndex + 1;

    for (nextIndex; nextIndex < itemTypes.length; nextIndex++) {
      let nextOrderItem = order?.items?.find(
        (i) => i.objectType === itemTypes[nextIndex]
      );
      if (nextOrderItem) {
        handleClickOrderItem(nextOrderItem.objectType);
        break;
      }
    }
  };

  const handleClickOrderItem = (objectType) => {
    switch (objectType) {
      case "FlatSheet":
        toggleFlatSheet();
        break;
      case "Coil":
        toggleCoil();
        break;
      case "Panel":
        toggleAddPanel();
        break;
      case "TrimAndFlashing":
        toggleAddTrim();
        break;
      case "CopingCap":
        toggleAddCap();
        break;
      case "CopingCapCleat":
        toggleAddCleat();
        break;
      case "BenchWork":
        toggleAddBench();
        break;
      case "SplicePlate":
        toggleAddSplice();
        break;
      case "Accessory":
        toggleAddAccessory();
        break;
      default:
        break;
    }
  };

  // Synchronize Order Item Existence with Checkbox
  // useEffect(() => {
  //   if (order) {
  //     setFlatSheetChecked(
  //       order?.items?.find((i) => i.objectType === "FlatSheet") ? true : false
  //     );
  //     setCoilChecked(
  //       order?.items?.find((i) => i.objectType === "Coil") ? true : false
  //     );
  //     setPanelChecked(
  //       order?.items?.find((i) => i.objectType === "Panel") ? true : false
  //     );
  //     setTrimAndFlashingChecked(
  //       order?.items?.find((i) => i.objectType === "TrimAndFlashing")
  //         ? true
  //         : false
  //     );
  //     setBenchWorkChecked(
  //       order?.items?.find((i) => i.objectType === "BenchWork") ? true : false
  //     );
  //     setCopingCapChecked(
  //       order?.items?.find((i) => i.objectType === "CopingCap") ? true : false
  //     );
  //     setCopingCapCleatChecked(
  //       order?.items?.find((i) => i.objectType === "CopingCapCleat")
  //         ? true
  //         : false
  //     );
  //     setSplicePlateChecked(
  //       order?.items?.find((i) => i.objectType === "SplicePlate") ? true : false
  //     );
  //     setAccessoryChecked(
  //       order?.items?.find((i) => i.objectType === "Accessory") ? true : false
  //     );
  //   }
  // }, [order]);

  return (
    <Box sx={{ padding: "16px" }}>
      <Box>
        <FormLabel>When do you need this by? (Optional)</FormLabel>
        <Box sx={{ display: "flex", flexDirection: "row", marginTop: "8px" }}>
          <Box sx={{ flex: 1, display: "flex", flexDirection: "row" }}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                disabled={!canUpdateOrder}
                // label="Confirm Due Date Requested By the Customer"
                value={order.customerRequestedCompletionDate}
                onChange={(newValue) => {
                  orderDispatch({
                    type: "customerRequestedCompletionDate",
                    payload: new Date(newValue),
                  });
                }}
                renderInput={(params) => (
                  <TextField size="small" sx={{}} {...params} />
                )}
              />
            </LocalizationProvider>
            <Button
              onClick={() => {
                orderDispatch({
                  type: "customerRequestedCompletionDate",
                  payload: null,
                });
              }}
            >
              Clear
            </Button>
          </Box>
        </Box>
      </Box>
      <Box sx={{ marginTop: "16px" }}>
        {
          // IF REQUESTING NEW, THIS WILL ALWAYS BE SET TO FALSE
          !isRequestingNewEstimate && (
            <FormGroup sx={{}}>
              <FormLabel>Is this a final cut-list?</FormLabel>
              <RadioGroup
                value={`${order.isUnconfirmedCutlist}`}
                onChange={(e) => {
                  let val = false;
                  if (e.target.value == "true") {
                    val = true;
                  }

                  orderDispatch({
                    type: "isUnconfirmedCutlist",
                    payload: val,
                  });
                }}
              >
                <Box>
                  <FormControlLabel
                    value={"false"}
                    control={<Radio disabled={!canUpdateOrder} />}
                    label={
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <Typography id="standard-bare" margin="normal">
                          Confirmed Cut-List - For Ordering
                          <Button
                            style={{ padding: 0 }}
                            sx={{ padding: 0 }}
                            onClick={() => {
                              setShowConfirmedCutListInfo(
                                !showConfirmedCutListInfo
                              );
                            }}
                          >
                            Info
                          </Button>
                        </Typography>
                      </Box>
                    }
                  />
                  {showConfirmedCutListInfo && (
                    <FormHelperText
                      sx={{
                        paddingLeft: "32px",
                        paddingTop: 0,
                        marginTop: "0",
                      }}
                    >
                      Upon Submission, we will issue a Comprehensive / Detailed
                      Quote, that once approved, will be turned into a Work
                      Order for fabrication
                    </FormHelperText>
                  )}
                </Box>
                <Box>
                  <FormControlLabel
                    value={"true"}
                    control={<Radio disabled={!canUpdateOrder} />}
                    // label={`Preliminary Cut-List - For Budgeting and Estimating Only`}
                    label={
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <Typography id="standard-bare" margin="normal">
                          Preliminary Cut-List - For Budgeting and Estimating
                          Only
                          <Button
                            onClick={() => {
                              setShowUnConfirmedCutListInfo(
                                !showUnConfirmedCutListInfo
                              );
                            }}
                          >
                            Info
                          </Button>
                        </Typography>
                      </Box>
                    }
                  />
                  {showUnConfirmedCutListInfo && (
                    <FormHelperText
                      sx={{
                        paddingLeft: "32px",
                        paddingTop: 0,
                        marginTop: "0",
                      }}
                    >
                      This is only used to provide a comprehensive / detailed
                      Estimate to be used for your internal pricing / estimating
                      purposes only. We recommend this if you are estimating a
                      job and don't have an exact cut-list, but can get close.
                      It cannot be "Approved", nor can it be turned into a work
                      order. A later date, it can be changed to a "Confirmed"
                      Cut-List if desired.{" "}
                    </FormHelperText>
                  )}
                </Box>
              </RadioGroup>
            </FormGroup>
          )
        }
      </Box>
      <Box>
        <Box sx={{ marginBottom: "24px" }}>
          {comments && comments.length > 0 && (
            <Typography
              style={{
                marginTop: 16,
                fontWeight: "bold",
                fontSize: 16,
                flex: 1,
              }}
            >
              Comments
            </Typography>
          )}
          {comments &&
            comments.map((comment, index) => {
              let createdAt = moment(new Date(comment.createdAt)).local();
              let weekOld = moment(new Date(createdAt)).local();
              weekOld.add(7, "d");
              let isWeekOld = moment() > weekOld;

              return (
                <Card key={index} style={{ padding: 16, marginTop: 8 }}>
                  <Box style={{ display: "flex", flexDirection: "row" }}>
                    {/* <Typography style={{ marginRight: 8, fontWeight: "bold" }}>
                      {comment.user.fullName}
                    </Typography> */}
                    {comment.user ? (
                      <Typography
                        style={{ marginRight: 8, fontWeight: "bold" }}
                      >
                        {comment.user.fullName}
                      </Typography>
                    ) : (
                      <Typography
                        style={{ marginRight: 8, fontWeight: "bold" }}
                      >
                        {comment.guestName}
                      </Typography>
                    )}
                    <Typography>
                      {isWeekOld
                        ? createdAt.format("MM/DD/YYYY")
                        : createdAt.fromNow()}
                    </Typography>
                  </Box>
                  <Typography style={{ marginTop: 8 }}>
                    {comment.text}
                  </Typography>
                </Card>
              );
            })}
        </Box>
        {canUpdateOrder && (
          <Box>
            <Box sx={{ marginBottom: "16px" }}>
              <Typography variant="h6">Select Products</Typography>
              <Box sx={{ padding: "8px" }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    magin: 0,
                    padding: 0,
                  }}
                >
                  <Checkbox
                    disabled={!canUpdateOrder}
                    style={{
                      magin: 0,
                      padding: 0,
                    }}
                    checked={flatSheetChecked}
                    onChange={(e) => {
                      let checked = e.target.checked;
                      setFlatSheetChecked(checked);
                      if (checked) {
                        orderDispatch({
                          type: "add order item",
                          payload: flatSheet,
                        });
                      } else {
                        orderDispatch({
                          type: "remove order item",
                          payload: "FlatSheet",
                        });
                      }
                    }}
                  />
                  <Typography>Flat Sheet</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    magin: 0,
                    padding: 0,
                  }}
                >
                  <Checkbox
                    disabled={!canUpdateOrder}
                    style={{
                      magin: 0,
                      padding: 0,
                    }}
                    checked={coilChecked}
                    onChange={(e) => {
                      let checked = e.target.checked;
                      setCoilChecked(checked);
                      if (checked) {
                        orderDispatch({
                          type: "add order item",
                          payload: coil,
                        });
                      } else {
                        orderDispatch({
                          type: "remove order item",
                          payload: "Coil",
                        });
                      }
                    }}
                  />
                  <Typography>Coil</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    magin: 0,
                    padding: 0,
                  }}
                >
                  <Checkbox
                    disabled={!canUpdateOrder}
                    style={{
                      magin: 0,
                      padding: 0,
                    }}
                    checked={panelChecked}
                    onChange={(e) => {
                      let checked = e.target.checked;
                      setPanelChecked(checked);
                      if (checked) {
                        orderDispatch({
                          type: "add order item",
                          payload: panel,
                        });
                      } else {
                        orderDispatch({
                          type: "remove order item",
                          payload: "Panel",
                        });
                      }
                    }}
                  />
                  <Typography>Panel</Typography>
                  <Link
                    style={{ color: theme.palette.primary.dark }}
                    target="_blank"
                    rel="noopener noreferrer"
                    to="/panel-info"
                  >
                    <Button
                      style={{ padding: 0, maxWidth: "10px" }}
                      sx={{ padding: 0 }}
                      // onClick={() => {
                      //   setShowConfirmedCutListInfo(
                      //     !showConfirmedCutListInfo
                      //   );
                      // }}
                    >
                      Info
                    </Button>
                  </Link>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    magin: 0,
                    padding: 0,
                  }}
                >
                  <Checkbox
                    disabled={!canUpdateOrder}
                    style={{
                      magin: 0,
                      padding: 0,
                    }}
                    checked={trimAndFlashingChecked}
                    onChange={(e) => {
                      let checked = e.target.checked;
                      setTrimAndFlashingChecked(checked);
                      if (checked) {
                        orderDispatch({
                          type: "add order item",
                          payload: trim,
                        });
                      } else {
                        orderDispatch({
                          type: "remove order item",
                          payload: "TrimAndFlashing",
                        });
                      }
                    }}
                  />
                  <Typography>Trim & Flashing</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    magin: 0,
                    padding: 0,
                  }}
                >
                  <Checkbox
                    disabled={!canUpdateOrder}
                    style={{
                      magin: 0,
                      padding: 0,
                    }}
                    checked={benchWorkChecked}
                    onChange={(e) => {
                      let checked = e.target.checked;
                      setBenchWorkChecked(checked);
                      if (checked) {
                        orderDispatch({
                          type: "add order item",
                          payload: bench,
                        });
                      } else {
                        orderDispatch({
                          type: "remove order item",
                          payload: "BenchWork",
                        });
                      }
                    }}
                  />
                  <Typography>BenchWork</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    magin: 0,
                    padding: 0,
                  }}
                >
                  <Checkbox
                    disabled={!canUpdateOrder}
                    style={{
                      magin: 0,
                      padding: 0,
                    }}
                    checked={copingCapChecked}
                    onChange={(e) => {
                      let checked = e.target.checked;
                      setCopingCapChecked(checked);
                      if (checked) {
                        orderDispatch({ type: "add order item", payload: cap });
                      } else {
                        orderDispatch({
                          type: "remove order item",
                          payload: "CopingCap",
                        });
                      }
                    }}
                  />
                  <Typography>Coping Cap</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    magin: 0,
                    padding: 0,
                  }}
                >
                  <Checkbox
                    disabled={!canUpdateOrder}
                    style={{
                      magin: 0,
                      padding: 0,
                    }}
                    checked={copingCapCleatChecked}
                    onChange={(e) => {
                      let checked = e.target.checked;
                      setCopingCapCleatChecked(checked);
                      if (checked) {
                        orderDispatch({
                          type: "add order item",
                          payload: cleat,
                        });
                      } else {
                        orderDispatch({
                          type: "remove order item",
                          payload: "CopingCapCleat",
                        });
                      }
                    }}
                  />
                  <Typography>Coping Cap Cleat</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    magin: 0,
                    padding: 0,
                  }}
                >
                  <Checkbox
                    disabled={!canUpdateOrder}
                    style={{
                      magin: 0,
                      padding: 0,
                    }}
                    checked={splicePlateChecked}
                    onChange={(e) => {
                      let checked = e.target.checked;
                      setSplicePlateChecked(checked);
                      if (checked) {
                        orderDispatch({
                          type: "add order item",
                          payload: splice,
                        });
                      } else {
                        orderDispatch({
                          type: "remove order item",
                          payload: "SplicePlate",
                        });
                      }
                    }}
                  />
                  <Typography>Splice Plate</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    magin: 0,
                    padding: 0,
                  }}
                >
                  <Checkbox
                    disabled={!canUpdateOrder}
                    style={{
                      magin: 0,
                      padding: 0,
                    }}
                    checked={accessoryChecked}
                    onChange={(e) => {
                      let checked = e.target.checked;
                      setAccessoryChecked(checked);
                      if (checked) {
                        orderDispatch({
                          type: "add order item",
                          payload: accessories,
                        });
                      } else {
                        orderDispatch({
                          type: "remove order item",
                          payload: "Accessory",
                        });
                      }
                    }}
                  />
                  <Typography>Extra Accessories</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        )}

        <ColorDetails
          canUpdate={canUpdateOrder}
          vendorProjectName={order.vendorProjectName}
          subProject={order.subProject}
          poNumber={order.poNumber}
          notes={order.notes}
          isCustomMaterial={order.isCustomMaterial}
          customColor={order.customColor}
          color={order.color}
          manufacturer={order.manufacturer}
          gauge={order.gauge}
        />
        <Box
          sx={{
            marginTop: "16px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            // padding: "0 1rem 0 1rem",
          }}
        >
          <Typography variant="h6">Products Selected</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: ".5rem",
            marginTop: "1rem",
          }}
        >
          {/* {order.items.map((item, index) => (
            <ProductCard
              key={index}
              canUpdateOrder={canUpdateOrder}
              orderItem={item}
              // onRemove={handleRemoveOrderItem}
              handleClick={handleClickOrderItem}
            />
          ))} */}
          {order?.items?.find((i) => i.objectType === "FlatSheet") && (
            <ProductCard
              canUpdateOrder={canUpdateOrder}
              orderItem={order?.items?.find(
                (i) => i.objectType === "FlatSheet"
              )}
              // onRemove={handleRemoveOrderItem}
              handleClick={handleClickOrderItem}
            />
          )}
          {order?.items?.find((i) => i.objectType === "Coil") && (
            <ProductCard
              canUpdateOrder={canUpdateOrder}
              orderItem={order?.items?.find((i) => i.objectType === "Coil")}
              // onRemove={handleRemoveOrderItem}
              handleClick={handleClickOrderItem}
            />
          )}
          {order?.items?.find((i) => i.objectType === "Panel") && (
            <ProductCard
              canUpdateOrder={canUpdateOrder}
              orderItem={order?.items?.find((i) => i.objectType === "Panel")}
              // onRemove={handleRemoveOrderItem}
              handleClick={handleClickOrderItem}
            />
          )}
          {order?.items?.find((i) => i.objectType === "TrimAndFlashing") && (
            <ProductCard
              canUpdateOrder={canUpdateOrder}
              orderItem={order?.items?.find(
                (i) => i.objectType === "TrimAndFlashing"
              )}
              // onRemove={handleRemoveOrderItem}
              handleClick={handleClickOrderItem}
            />
          )}
          {order?.items?.find((i) => i.objectType === "BenchWork") && (
            <ProductCard
              canUpdateOrder={canUpdateOrder}
              orderItem={order?.items?.find(
                (i) => i.objectType === "BenchWork"
              )}
              // onRemove={handleRemoveOrderItem}
              handleClick={handleClickOrderItem}
            />
          )}
          {order?.items?.find((i) => i.objectType === "CopingCap") && (
            <ProductCard
              canUpdateOrder={canUpdateOrder}
              orderItem={order?.items?.find(
                (i) => i.objectType === "CopingCap"
              )}
              // onRemove={handleRemoveOrderItem}
              handleClick={handleClickOrderItem}
            />
          )}
          {order?.items?.find((i) => i.objectType === "CopingCapCleat") && (
            <ProductCard
              canUpdateOrder={canUpdateOrder}
              orderItem={order?.items?.find(
                (i) => i.objectType === "CopingCapCleat"
              )}
              // onRemove={handleRemoveOrderItem}
              handleClick={handleClickOrderItem}
            />
          )}
          {order?.items?.find((i) => i.objectType === "SplicePlate") && (
            <ProductCard
              canUpdateOrder={canUpdateOrder}
              orderItem={order?.items?.find(
                (i) => i.objectType === "SplicePlate"
              )}
              // onRemove={handleRemoveOrderItem}
              handleClick={handleClickOrderItem}
            />
          )}
          {order?.items?.find((i) => i.objectType === "Accessory") && (
            <ProductCard
              canUpdateOrder={canUpdateOrder}
              orderItem={order?.items?.find(
                (i) => i.objectType === "Accessory"
              )}
              // onRemove={handleRemoveOrderItem}
              handleClick={handleClickOrderItem}
            />
          )}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1em",
              marginBottom: "2rem",
              marginTop: "1rem",
            }}
          >
            {!order.id && (
              <Button
                variant="contained"
                onClick={() => {
                  console.log(order);
                  onSave();
                }}
              >
                Save
              </Button>
            )}
            {/* {canUpdateOrder && (
              <Button
                variant="contained"
                onClick={() => {
                  console.log(order);
                  onSave();
                }}
              >
                Save Changes
              </Button>
            )} */}

            {canRequestEstimate && (
              <>
                <SubmitForQuoteDialog
                  open={submitDialogOpen}
                  setOpen={setSubmitDialogOpen}
                  order={order}
                  onSubmit={(estimateAssignedApproverId) => {
                    requestEstimate(estimateAssignedApproverId);
                  }}
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    justifyContent: "flex-end",
                  }}
                >
                  <LoadingButton
                    // sx={{ width: "150px" }}
                    loading={isUpdating}
                    color="success"
                    variant="contained"
                    onClick={() => {
                      // IF Vendor User, automatically assign current user as approver and skip approver selection dialog
                      if (decoded.isVendorOrSupplier) {
                        requestEstimate(decoded.id);
                      } else {
                        let canApprove = false;
                        for (const permission of decoded.permissions) {
                          if (
                            permission.name ==
                              "Request Quote And Approve Own Quotes" ||
                            permission.name ==
                              "Request Quote And Approve All Quotes"
                          ) {
                            canApprove = true;
                          }
                        }
                        if (decoded.role == "OWNER") {
                          canApprove = true;
                        }
                        if (
                          // IF Level 2 OR Level 3, ignore dialog and autoassign self as approver
                          canApprove
                        ) {
                          requestEstimate(decoded.id);
                        } else {
                          setSubmitDialogOpen(true);
                        }
                      }
                    }}
                  >
                    Submit for Quote
                  </LoadingButton>
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Box>

      <FlatSheet
        showSaving={showSaving}
        isUpdating={isUpdating}
        isProductSelected={order?.items?.find(
          (i) => i.objectType === "FlatSheet"
        )}
        isAutoSaving={isAutoSaving}
        canUpdate={canUpdateOrder}
        open={openAddFlatSheet}
        handleClose={toggleFlatSheet}
        handleNext={() => {
          toggleFlatSheet();
          openNext("FlatSheet");
        }}
        hasNext={hasNext("FlatSheet")}
      />
      <Coil
        showSaving={showSaving}
        isUpdating={isUpdating}
        isProductSelected={order?.items?.find((i) => i.objectType === "Coil")}
        isAutoSaving={isAutoSaving}
        canUpdate={canUpdateOrder}
        open={openAddCoil}
        handleClose={toggleCoil}
        handleNext={() => {
          toggleCoil();
          openNext("Coil");
        }}
        hasNext={hasNext("Coil")}
      />
      <Panel
        showSaving={showSaving}
        isUpdating={isUpdating}
        isProductSelected={order?.items?.find((i) => i.objectType === "Panel")}
        isAutoSaving={isAutoSaving}
        canUpdate={canUpdateOrder}
        open={openAddPanel}
        handleClose={toggleAddPanel}
        handleNext={() => {
          toggleAddPanel();
          openNext("Panel");
        }}
        hasNext={hasNext("Panel")}
      />
      <Trim
        showSaving={showSaving}
        isUpdating={isUpdating}
        isProductSelected={order?.items?.find(
          (i) => i.objectType === "TrimAndFlashing"
        )}
        isAutoSaving={isAutoSaving}
        canUpdate={canUpdateOrder}
        open={openAddTrim}
        handleClose={toggleAddTrim}
        handleNext={() => {
          toggleAddTrim();
          openNext("TrimAndFlashing");
        }}
        hasNext={hasNext("TrimAndFlashing")}
        projectName={
          order.project
            ? order.project.name
            : projectNameForNewOrder
            ? projectNameForNewOrder
            : ""
        }
      />
      <CopingCap
        showSaving={showSaving}
        isUpdating={isUpdating}
        isProductSelected={order?.items?.find(
          (i) => i.objectType === "CopingCap"
        )}
        isAutoSaving={isAutoSaving}
        canUpdate={canUpdateOrder}
        open={openAddCap}
        handleClose={toggleAddCap}
        handleNext={() => {
          toggleAddCap();
          openNext("CopingCap");
        }}
        hasNext={hasNext("CopingCap")}
        // projectName={order.project ? order.project.name : ""}
      />
      <Cleat
        showSaving={showSaving}
        isUpdating={isUpdating}
        isProductSelected={order?.items?.find(
          (i) => i.objectType === "CopingCapCleat"
        )}
        isAutoSaving={isAutoSaving}
        canUpdate={canUpdateOrder}
        open={openAddCleat}
        handleClose={toggleAddCleat}
        handleNext={() => {
          toggleAddCleat();
          openNext("CopingCapCleat");
        }}
        hasNext={hasNext("CopingCapCleat")}
        // projectName={order.project ? order.project.name : ""}
      />
      <BenchWork
        showSaving={showSaving}
        isUpdating={isUpdating}
        isProductSelected={order?.items?.find(
          (i) => i.objectType === "BenchWork"
        )}
        isAutoSaving={isAutoSaving}
        canUpdate={canUpdateOrder}
        open={openAddBench}
        handleClose={toggleAddBench}
        handleNext={() => {
          toggleAddBench();
          openNext("BenchWork");
        }}
        hasNext={hasNext("BenchWork")}
        // projectName={order.project ? order.project.name : ""}
      />
      <SplicePlate
        showSaving={showSaving}
        isUpdating={isUpdating}
        isProductSelected={order?.items?.find(
          (i) => i.objectType === "SplicePlate"
        )}
        isAutoSaving={isAutoSaving}
        canUpdate={canUpdateOrder}
        open={openAddSplice}
        handleClose={toggleAddSplice}
        handleNext={() => {
          toggleAddSplice();
          openNext("SplicePlate");
        }}
        hasNext={hasNext("SplicePlate")}
      />
      <Accessories
        showSaving={showSaving}
        isUpdating={isUpdating}
        isProductSelected={order?.items?.find(
          (i) => i.objectType === "Accessory"
        )}
        isAutoSaving={isAutoSaving}
        canUpdate={canUpdateOrder}
        open={openAddAccessory}
        handleClose={toggleAddAccessory}
        handleNext={() => {
          toggleAddAccessory();
          openNext("Accessory");
        }}
        hasNext={hasNext("Accessory")}
      />
    </Box>
  );
};
export default MainOrderView;
