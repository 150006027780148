import View from "./Views/Main";
import { MobileOrderContext } from "../../../../context/MobileOrderContext";
import { AuthContext } from "../../../../context/AuthContext";
import usePageName from "../../../../hocs/usePageName";
import { useState, useContext, createContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import makePage from "../../../../hocs/makePage";
import withError from "../../../../hocs/withError";
import withLoading from "../../../../hocs/withLoading";
import { Box, Button, Typography } from "@mui/material";
import { SnackAlertContext } from "../../../../context/SnackAlertContext";
import OrderWithEstimateDetails from "./Views/OrderWithEstimateDetails";
import OrderTitle from "../../../../components/OrderTitle";
import { HeadingContext } from "../../../../context/HeadingContext";
import moment from "moment/moment";

const OrderView = makePage(View, withError, withLoading);

const ResubmitQuoteRequest = () => {
  const navigate = useNavigate();
  const { headingText, setHeadingText, captionText, setCaptionText } =
    useContext(HeadingContext);
  const [isEstimateExpired, setIsEstimateExpired] = useState(false);
  const { openSnackMessage } = useContext(SnackAlertContext);
  const { projectId, orderId } = useParams();
  const { authToken, ezorder, decoded } = useContext(AuthContext);
  const headers = {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  };
  const { order, setTaxForms, orderDispatch, fillOrderDetails } =
    useContext(MobileOrderContext);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const getOrderById = async () => {
    try {
      setLoading(true);
      const res = await ezorder.get(`/orders/${orderId}`, headers);
      console.log(res);
      let orderInfo = res.data.order;
      let isExpired =
        orderInfo.orderStatus &&
        orderInfo.estimateExpirationDate &&
        orderInfo.orderStatus.name == "ESTIMATE_APPROVAL_NEEDED" &&
        moment(orderInfo.estimateExpirationDate) < moment();
      setIsEstimateExpired(isExpired);

      fillOrderDetails(res.data.order);
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const requestNewEstimateWithChanges = async (estimateAssignedApproverId) => {
    try {
      setLoading(true);
      let requestBody = {
        order: {
          ...order,
          isUnconfirmedCutlist: false,
        },
        estimateAssignedApproverId,
      };

      const response = await ezorder.put(
        `/orders/${order.id}/estimate/request-new-with-changes`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      openSnackMessage("success", "Quote Requested");
      navigate(`/quotes`);
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage("error", error.response.data.error);
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", error);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getOrderById();
  }, []);

  useEffect(() => {
    setHeadingText("Re-submit Quote Request");
  }, [order.orderStatus]);

  if (
    // FOR EXPIRED QUOTES
    (order &&
      order.orderStatus &&
      order.orderStatus.name == "ESTIMATE_APPROVAL_NEEDED" &&
      isEstimateExpired) ||
    // OR UNCONFIRMED CUTLIST
    (order && order.isUnconfirmedCutlist)
  ) {
    return (
      <OrderView
        isRequestingNewEstimate={true}
        isAutoSaving={false}
        canUpdateOrder={true}
        canRequestEstimate={
          order.orderStatus &&
          (decoded.permissions.filter(
            (p) =>
              p.name === "Request Quote" ||
              p.name === "Request Quote And Approve Own Quotes" ||
              p.name === "Request Quote And Approve All Quotes"
          ).length > 0 ||
            decoded.role === "OWNER")
        }
        requestEstimate={requestNewEstimateWithChanges}
        error={error}
        loading={loading}
      />
    );
  } else {
    return (
      <Box>
        <Typography>Cannot re-submit this quote request</Typography>
      </Box>
    );
  }
};
export default ResubmitQuoteRequest;
