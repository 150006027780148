import {
  Box,
  TextField,
  Button,
  MenuItem,
  Typography,
  Tooltip,
  IconButton,
} from "@mui/material";
import { useEffect, useState, useMemo } from "react";
import CollapsableCard from "../../../../CollapseableCard";
import { toTwoDecimals } from "../../../calculations/utils";
import FlatSheetCalculations from "./FlatSheetCalculations";

const FlatSheetDetails = ({ details, action, materialDetails }) => {
  const handleCalcOveride = (key) => (e) => {
    action({
      type: "UPDATE FLAT SHEET: OVERRIDE CALCULATION",
      payload: { key, value: parseFloat(e.target.value) },
    });
  };

  const resetOverrideValue = (key) => () => {
    action({ type: "UPDATE FLAT SHEET: RESET OVERIDE", payload: key });
  };

  useEffect(() => {
    if (!details.flatSheetCalculations) {
      action({ type: "UPDATE FLAT SHEET: SET CALCULATIONS" });
    }
  }, [details]);

  useEffect(() => {
    action({ type: "UPDATE FLAT SHEET: SET CALCULATIONS" });
  }, [materialDetails.flatSheetCost, materialDetails.flatSheetCostOriginal]);

  return (
    <CollapsableCard
      title={`Flat Sheet - $${Math.round(
        toTwoDecimals(
          details &&
            details.flatSheetCalculations &&
            details.flatSheetCalculations.totalSellPrice
            ? details.flatSheetCalculations.totalSellPrice
            : 0.0
        )
      )}`}
      defaultOpen={details?.quantity > 0}
    >
      <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "1rem",
            marginTop: "8px",
          }}
        >
          <TextField
            onFocus={(e) => e.target.select()}
            type="number"
            onWheel={(event) => {
              event.preventDefault();
            }}
            size="small"
            label="Quantity"
            value={parseFloat(details.quantity)}
            onChange={(e) => {
              action({
                type: "UPDATE FLAT SHEET: QUANTITY",
                payload: e.target.value,
              });
            }}
          />
          <TextField
            select
            variant="outlined"
            label="Width (in.)"
            id="width"
            name="Width (in.)"
            size="small"
            fullWidth
            value={parseFloat(details.width)}
            onChange={(e) => {
              action({
                type: "UPDATE FLAT SHEET: WIDTH",
                payload: e.target.value,
              });
            }}
          >
            <MenuItem value={40}>40"</MenuItem>
            <MenuItem value={48}>48"</MenuItem>
          </TextField>
        </Box>
        {details?.flatSheetCalculations && (
          <FlatSheetCalculations
            calculations={details?.flatSheetCalculations}
            variables={details?.variables}
            overrides={details?.overrides}
            handleChange={handleCalcOveride}
            resetOverride={resetOverrideValue}
          />
        )}
      </Box>
    </CollapsableCard>
  );
};
export default FlatSheetDetails;
