import View from "./View";
import { useState, useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ezorder from "../../../api/ezorderNoAuth";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const requestPasswordReset = async (email) => {
    try {
      setSuccess(false);
      setErrorMessage(null);
      setLoading(true);
      setErrorMessage("");
      let requestBody = { email };
      const response = await ezorder.post(`/auth/forgot-password`, requestBody);

      //TODO: display a success message
      console.log(response.data);
      setSuccess(true);
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        if (error.response.data?.error) {
          setErrorMessage(error.response.data.error);
        } else {
          setErrorMessage("Internal server error");
        }
      } else {
        setErrorMessage("Internal server error");
      }
    } finally {
      setLoading(false);
    }
  };

  const forgotPasswordHandler = async (forgotPasswordRequest) => {
    console.log("forgotPasswordRequest", forgotPasswordRequest);
    await requestPasswordReset(forgotPasswordRequest.email);
  };

  return (
    <>
      <View
        success={success}
        forgotPasswordHandler={forgotPasswordHandler}
        loading={loading}
        errorMessage={errorMessage}
      />
    </>
  );
};
export default ForgotPassword;
