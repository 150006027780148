import { useState, useContext } from "react";
import {
  Card,
  CircularProgress,
  Grid,
  Box,
  TextField,
  Button,
  Typography,
  Container,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";

const ForgotPasswordForm = ({
  success,
  forgotPasswordHandler,
  errorMessage,
}) => {
  const navigate = useNavigate();
  const [inValidRole, setInvalidRole] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const formValidationSchema = Yup.object({
    email: Yup.string().email().required("Email is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: formValidationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: (values) => {
      forgotPasswordHandler(values);
    },
  });

  return (
    <div>
      <Container maxWidth="xs" sx={{ paddingBottom: "24px" }}>
        <Card
          sx={{
            padding: "24px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography component="h1" variant="h5">
            Forgot your Password?
          </Typography>
          <Box
            component="form"
            onSubmit={formik.handleSubmit}
            noValidate
            sx={{ mt: 1, pt: 1 }}
          >
            <Typography>
              Enter your email address to receive a reset link
            </Typography>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              autoComplete="email"
            />
            {formik.errors.email ? <div>{formik.errors.email}</div> : null}
            <Box
              sx={{
                m: 1,
                position: "relative",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {errorMessage && (
                <Box>
                  <Typography color={"red"}>{errorMessage}</Typography>
                </Box>
              )}
              {success && (
                <Box>
                  <Typography color={"green"}>Reset link sent!</Typography>
                </Box>
              )}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 1 }}
                disabled={isLoading}
              >
                Reset Password
              </Button>
              <Button
                variant="text"
                size="small"
                sx={{ mt: 1 }}
                disabled={isLoading}
                onClick={() => {
                  console.log("signup");
                  navigate("/");
                }}
              >
                Go back
              </Button>
              {isLoading && (
                <CircularProgress
                  size={24}
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              )}
            </Box>
          </Box>
          {inValidRole && (
            <Box sx={{ color: "#cf0404" }}>
              You Are Not Authorized To Visit This Page.
            </Box>
          )}
        </Card>
      </Container>
    </div>
  );
};

export default ForgotPasswordForm;
