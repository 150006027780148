import View from "./View";
import { useState, useContext, useEffect, useRef } from "react";
import ezorder from "../../../api/ezorderNoAuth";
import jwt_decode from "jwt-decode";
import { AuthContext } from "../../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { Box, Button } from "@mui/material";
import { useInstallPwaPrompt } from "../../../hooks/useInstallPwaPrompt";

const Login = () => {
  const navigate = useNavigate();
  const { setAuthentication } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [prompt, promptToInstall] = useInstallPwaPrompt();

  const login = async (requestBody) => {
    setLoading(true);

    try {
      const response = await ezorder.post(`/auth/login`, requestBody);
      setLoading(false);
      if (response.status === 200 || response.status == 201) {
        const accessToken = response.data.accessToken;
        const decodedToken = jwt_decode(accessToken);
        if (decodedToken) {
          setAuthentication(accessToken);
          localStorage.setItem("accessToken", accessToken);
          // navigate(`/annual-tax-form`);
          navigate(`/`);
        } else {
          //setInvalidRole(true);
        }
      }
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        if (error.response.data?.error) {
          setErrorMsg(error.response.data.error);
        } else {
          setErrorMsg("Internal server error");
        }
      } else {
        setErrorMsg("Internal server error");
      }
    } finally {
      setLoading(false);
    }
  };

  const loginHandler = async (loginRequest) => {
    await login(loginRequest);
  };
  return (
    <>
      <View
        loginHandler={loginHandler}
        loading={loading}
        errorMsg={errorMsg}
        errorMessage={errorMessage}
      />
      <Box
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <Button onClick={promptToInstall}>Install Application</Button>
      </Box>
    </>
  );
};
export default Login;
