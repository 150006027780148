import { Routes, Route, BrowserRouter, useLocation } from "react-router-dom";
import { HeadingContextProvider } from "../context/HeadingContext";
import CustomerDashboard from "../components/CustomerComponents/Layout/CustomerDashboard";
import Projects from "../pages/customer/Projects/Projects";
import Orders from "../pages/customer/Orders/Orders";
import EmployeeList from "../pages/customer/Employees/EmployeesList/EmployeeList";
import TaxFormList from "../pages/customer/TaxForms/TaxFormList/TaxFormList";
import Settings from "../pages/customer/Settings/Settings";
import TrimCatalog from "../pages/customer/TrimCatalog/TrimCatalog";
import { MobileOrderContextProvider } from "../context/MobileOrderContext";
import TrimDetail from "../pages/customer/TrimCatalog/TrimDetail/TrimDetail";
import OrderDetails from "../pages/customer/Orders/Order/OrderDetails";
import Error404 from "../pages/error/Error404";
import AddAnnualTaxForm from "../pages/customer/AddAnnualTaxForm/AddAnnualTaxForm";
import ProjectOrders from "../pages/customer/Projects/ProjectOrders";
import ApproveEstimate from "../pages/guest/ApproveEstimate";
import EditEmployee from "../pages/customer/Employees/EmployeesList/EditEmployee";
import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import CompanySettings from "../pages/customer/CompanySettings/CompanySettings";
import Quotes from "../pages/customer/Quotes/Quotes";
import ResubmitQuoteRequest from "../pages/customer/Orders/Order/ResubmitQuoteRequest";
import Contact from "../pages/customer/Contact/Contact";
import PanelInfo from "../pages/customer/Industry/PanelInfo";
import IndustryMasterCatalog from "../pages/customer/TrimCatalog/IndustryMasterCatalog";
import CompanyStockCatalog from "../pages/customer/TrimCatalog/CompanyStockCatalog";

const CustomerRoutes = () => {
  const { decoded } = useContext(AuthContext);
  return (
    <HeadingContextProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<CustomerDashboard />}>
            <Route
              path="/"
              element={<Orders resource="orders" activeTab="active" />}
            />
            <Route
              path="/orders/active"
              element={<Orders resource="orders" activeTab="active" />}
            />
            <Route
              path="/orders/ready"
              element={<Orders resource="orders" activeTab="ready" />}
            />
            <Route
              path="/orders/fulfilled"
              element={<Orders resource="orders" activeTab="fulfilled" />}
            />
            <Route
              path="/orders"
              element={<Orders resource="orders" activeTab="active" />}
            />
            <Route path="/annual-tax-form" element={<AddAnnualTaxForm />} />
            <Route path="/quotes" element={<Quotes />} />

            <Route
              path="/orders/:orderId"
              element={
                <MobileOrderContextProvider>
                  <OrderDetails />
                </MobileOrderContextProvider>
              }
            />
            <Route
              path="/quotes/:orderId"
              element={
                <MobileOrderContextProvider>
                  <OrderDetails />
                </MobileOrderContextProvider>
              }
            />
            <Route
              path="/orders/:orderId/approve-estimate"
              element={<ApproveEstimate />}
            />
            <Route
              path="/quotes/:orderId/resubmit-quote-request"
              element={
                <MobileOrderContextProvider>
                  <ResubmitQuoteRequest />
                </MobileOrderContextProvider>
              }
            />
            {decoded.isVendorOrSupplier ? (
              <Route path="/customers" element={<Projects />} />
            ) : (
              <Route path="/projects" element={<Projects />} />
            )}

            {decoded.isVendorOrSupplier ? (
              <Route path="/customers/:id/orders" element={<ProjectOrders />} />
            ) : (
              <Route path="/projects/:id/orders" element={<ProjectOrders />} />
            )}

            <Route path="/panel-info" element={<PanelInfo />} />

            <Route path="users" element={<EmployeeList />} />
            <Route path="users/:id" element={<EditEmployee />} />
            <Route
              path="/catalog/industry"
              element={<IndustryMasterCatalog />}
            />
            <Route path="/catalog/stock" element={<CompanyStockCatalog />} />
            <Route path="/catalog" element={<TrimCatalog />} />
            <Route path="catalog/:id" element={<TrimDetail />} />
            <Route path="tax-exempt-forms" element={<TaxFormList />} />
            <Route path="settings" element={<Settings />} />
            {decoded.role == "OWNER" && (
              <Route path="company-settings" element={<CompanySettings />} />
            )}
            <Route path="contact-us" element={<Contact />} />
            <Route path="*" element={<Error404 />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </HeadingContextProvider>
  );
};
export default CustomerRoutes;
