// uplaod image to S3 bucket
const uploadImage = async (uploadUrl, file) => {
  console.log("Upload Image", uploadUrl, file);
  await fetch(uploadUrl, {
    method: "PUT",
    body: file,
  }).then((response) => {
    // console.log(response);
    // if (response.status === 200 || response.status == 201) {
    //   setImgSuccess({ fileName: file[0].name });
    //   //setIsLoading(false);
    // }
    return response;
  });
  // .catch((error) => {
  //   throw Error("Failed to upload BenchWork image.", { cause: error });
  //   // console.log(error);
  // });
};

export default uploadImage;
