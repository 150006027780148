import {
  Box,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { toThreeDecimals } from "../../../../components/NewDetail/calculations/utils";

const SplicePlate = (props) => {
  const {
    splicePlate,
    isEstimate,
    onShearToggle,
    showTotal = false,
    showPricePerPiece = false,
    noCalcs = false,
  } = props;
  if (!splicePlate) return null;

  let trimTotalSellPrice = 0;
  for (const piece of splicePlate.plates) {
    if (piece.calculations && piece.calculations.totalSellPrice)
      trimTotalSellPrice += parseFloat(piece.calculations.totalSellPrice);
  }

  return (
    <Box {...props}>
      {showTotal && !noCalcs ? (
        <Typography variant="h5">
          Splice Plates - ${Math.round(trimTotalSellPrice)}
        </Typography>
      ) : (
        <Typography variant="h5">Splice Plates</Typography>
      )}
      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Qty (pc)</TableCell>
              <TableCell align="right">Piece Length</TableCell>
              {!isEstimate && !noCalcs && (
                <TableCell align="right">Stretch Out</TableCell>
              )}
              {!isEstimate && !noCalcs && (
                <TableCell align="right">Drop Per FS (in)</TableCell>
              )}
              {!isEstimate && !noCalcs && (
                <TableCell align="right">Hits</TableCell>
              )}
              {!isEstimate && !noCalcs && (
                <TableCell align="right">Exact FS</TableCell>
              )}
              {!isEstimate && !noCalcs && (
                <TableCell align="right">FS to Buy</TableCell>
              )}
              {showPricePerPiece && !noCalcs && (
                <TableCell align="right">Price Per Piece</TableCell>
              )}
              {/* {!isEstimate && <TableCell align="right">Sheared</TableCell>} */}
            </TableRow>
          </TableHead>
          <TableBody>
            {splicePlate.plates.map((plate, index) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {plate.quantity}
                </TableCell>
                <TableCell align="right">{plate.length}</TableCell>
                {!isEstimate && !noCalcs && (
                  <TableCell align="right">{plate.stretchOut}</TableCell>
                )}

                {!isEstimate && !noCalcs && (
                  <TableCell align="right">
                    {plate.calculations.inchesOfDropPerFlatSheet}
                  </TableCell>
                )}
                {!isEstimate && !noCalcs && (
                  <TableCell align="right">{plate.hits}</TableCell>
                )}
                {!isEstimate && !noCalcs && (
                  <TableCell align="right">
                    {toThreeDecimals(
                      plate.quantity /
                        plate.calculations.piecesYieldedPerFlatSheet
                    )}
                    {/*TODO: this is probably wrong */}
                  </TableCell>
                )}
                {!isEstimate && !noCalcs && (
                  <TableCell align="right">
                    {plate.calculations.flatSheetsNeeded}
                  </TableCell>
                )}
                {showPricePerPiece && !noCalcs && (
                  <TableCell align="right">
                    ${plate.calculations.pricePerPiece}
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default SplicePlate;
