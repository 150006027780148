import { Box, Button, Grid, Typography } from "@mui/material";
import TestDragAndDrop from "./TestDragAndDrop";
import TestCalendar from "./TestCalendar";
import CustomCalendar from "./CustomCalendar";

const ShopSchedule = ({}) => {
  return (
    <>
      <Typography variant="h4" component="div">
        Shop Schedule
      </Typography>

      {/* <ShopScheduleList /> */}
      <TestDragAndDrop />
      <TestCalendar />
      <CustomCalendar />
    </>
  );
};

export default ShopSchedule;
