import React, {
  useState,
  createContext,
  useEffect,
  useReducer,
  useContext,
} from "react";

export const initialOrder = {
  type: "MOBILE",
  customerRequestedCompletionDate: "",
  isUnconfirmedCutlist: false,
  vendorProjectName: null,
  subProject: null,
  gauge: 24,
  color: "Aged Copper",
  manufacturer: "CMG",
  isCustomMaterial: false,
  customColor: "",
  customManufacturer: null,
  customGauge: null,
  project: null,

  customerName: "",
  poNumber: "",
  notes: "",
  tag: "",
  items: [],
};
const initialFlatSheet = {
  objectType: "FlatSheet",
  quantity: 0,
  width: 48,
};
const initialCoil = {
  objectType: "Coil",
  linealFeet: 0,
  width: 0,
};
const initialPanel = {
  objectType: "Panel",
  panelSystem: "Standing Seam",
  panelProfile: "",
  panelType: "SL-175, Striated With Clip Relief",
  panelWidth: 18,
  cuts: [],
};
const initalTrim = {
  objectType: "TrimAndFlashing",
  trimPieces: [],
};
const initialCopingCap = {
  objectType: "CopingCap",
  capPieces: [],
};
const initialCleat = {
  objectType: "CopingCapCleat",
  gauge: "22g",
  color: null,
  cleatPieces: [],
};
const initialBench = {
  objectType: "BenchWork",
  benchWorkPieces: [],
};
const initialSplice = {
  objectType: "SplicePlate",
  plates: [],
};
const initialAccessory = {
  objectType: "Accessory",
  items: [],
};

export const MobileOrderContext = createContext();

export function useMobileOrderContext() {
  return useContext(MobileOrderContext);
}

export const MobileOrderContextProvider = (props) => {
  // const [order, setOrder] = useState(initialOrder);
  const [order, orderDispatch] = useReducer(orderReducer, initialOrder);

  const [flatSheet, flatSheetDispatch] = useReducer(
    flatSheetReducer,
    initialFlatSheet
  );
  const [coil, coilDispatch] = useReducer(coilReducer, initialCoil);
  const [panel, panelDispatch] = useReducer(panelReducer, initialPanel);
  const [trim, trimDispatch] = useReducer(trimReducer, initalTrim);
  const [cap, capDispatch] = useReducer(copingCapReducer, initialCopingCap);
  const [cleat, cleatDispatch] = useReducer(cleatReducer, initialCleat);
  const [bench, benchDispatch] = useReducer(benchReducer, initialBench);
  const [splice, spliceDispatch] = useReducer(spliceReducer, initialSplice);
  const [accessories, accessoryDispatch] = useReducer(
    accessoryReducer,
    initialAccessory
  );
  const [comments, setComments] = useState(null);

  const fillOrderItemDetails = (orderItem) => {
    switch (orderItem.objectType) {
      case "FlatSheet":
        flatSheetDispatch({ type: "fill details", payload: orderItem });
        break;
      case "Coil":
        coilDispatch({ type: "fill details", payload: orderItem });
        break;
      case "Panel":
        panelDispatch({ type: "fill details", payload: orderItem });
        break;
      case "TrimAndFlashing":
        trimDispatch({ type: "fill details", payload: orderItem });
        break;
      case "CopingCap":
        capDispatch({ type: "fill details", payload: orderItem });
        break;
      case "CopingCapCleat":
        cleatDispatch({ type: "fill details", payload: orderItem });
        break;
      case "BenchWork":
        benchDispatch({ type: "fill details", payload: orderItem });
        break;
      case "SplicePlate":
        spliceDispatch({ type: "fill details", payload: orderItem });
        break;
      case "Accessory":
        accessoryDispatch({ type: "fill details", payload: orderItem });
        break;
      default:
        break;
    }
  };

  const fillOrderDetails = (order) => {
    orderDispatch({ type: "fill details", payload: order });
    order.items.forEach((item) => {
      fillOrderItemDetails(item);
    });
    setComments(order.comments);
  };

  const clearState = () => {
    orderDispatch({ type: "reset to initial state" });
    flatSheetDispatch({ type: "reset to initial state" });
    coilDispatch({ type: "reset to initial state" });
    panelDispatch({ type: "reset to initial state" });
    trimDispatch({ type: "reset to initial state" });
    capDispatch({ type: "reset to initial state" });
    cleatDispatch({ type: "reset to initial state" });
    benchDispatch({ type: "reset to initial state" });
    spliceDispatch({ type: "reset to initial state" });
    accessoryDispatch({ type: "reset to initial state" });
    setComments(null);
  };

  return (
    <MobileOrderContext.Provider
      value={{
        order,
        orderDispatch,
        fillOrderDetails,
        clearState,
        flatSheet,
        flatSheetDispatch,
        coil,
        coilDispatch,
        panel,
        panelDispatch,
        trim,
        trimDispatch,
        cap,
        capDispatch,
        cleat,
        cleatDispatch,
        bench,
        benchDispatch,
        splice,
        spliceDispatch,
        accessories,
        accessoryDispatch,
        comments,
      }}
    >
      {props.children}
    </MobileOrderContext.Provider>
  );
};

function orderReducer(order, action) {
  const { type, payload } = action;
  switch (type) {
    case "customerRequestedCompletionDate":
    case "isUnconfirmedCutlist":
    case "vendorProjectName":
    case "subProject":
    case "poNumber":
    case "notes":
    case "color":
    case "manufacturer":
    case "gauge":
    case "isCustomMaterial":
    case "customColor":
    case "project":
      return { ...order, [type]: payload };

    case "add order item":
      const orderItemIndex = order.items.findIndex(
        (item) => item.objectType === payload.objectType
      );
      if (orderItemIndex > -1) {
        // if it exists, update it
        const updatedItems = [...order.items];
        updatedItems.splice(orderItemIndex, 1, payload);
        return { ...order, items: [...updatedItems] };
      } else {
        // else, add it
        return { ...order, items: [...order.items, payload] };
      }

    case "update order item": {
    }
    case "remove order item":
      const objectType = payload;
      return {
        ...order,
        items: [
          ...order.items.filter((item) => item.objectType !== objectType),
        ],
      };
    case "fill details": {
      return { ...order, ...payload };
    }
    case "reset to initial state":
      return { ...initialOrder };
    default:
      throw Error(`"${type}" is not a valid action type`);
      break;
  }
}

function flatSheetReducer(flatSheet, action) {
  const { type, payload } = action;
  switch (type) {
    case "quantity":
    case "width":
      return { ...flatSheet, [type]: payload };
    case "fill details":
      return { ...flatSheet, ...payload };
    case "reset to initial state":
      return { ...initialFlatSheet };
    default:
      throw Error(`"${type}" is not a valid action type`);
      break;
  }
}

function coilReducer(coil, action) {
  const { type, payload } = action;
  switch (type) {
    case "linealFeet":
    case "width":
      return { ...coil, [type]: payload };
    case "fill details":
      return { ...coil, ...payload };
    case "reset to initial state":
      return { ...initialFlatSheet };
    default:
      throw Error(`"${type}" is not a valid action type`);
      break;
  }
}

function panelReducer(panel, action) {
  const { type, payload } = action;
  switch (type) {
    case "edit cut":
      let cutsCopy1 = panel.cuts;
      cutsCopy1[payload.index] = payload.cut;
      return { ...panel, cuts: [...cutsCopy1] };
      break;
    case "panelSystem":
    case "panelProfile":
    case "panelType":
    case "panelWidth":
      return { ...panel, [type]: payload };
    case "add cut":
      return { ...panel, cuts: [...panel.cuts, payload] };
    case "remove cut":
      let cutsCopy = panel.cuts;
      let cutIndex = payload;
      cutsCopy.splice(cutIndex, 1);
      return { ...panel, cuts: [...cutsCopy] };
    case "fill details":
      return { ...panel, ...payload };
    case "reset to initial state":
      return { ...initialPanel };
    default:
      throw Error(`"${type}" is not a valid action type`);
      break;
  }
}

function trimReducer(trim, action) {
  const { type, payload } = action;
  switch (type) {
    case "edit quantity":
      let piecesCopy1 = trim.trimPieces;
      piecesCopy1[payload.index].quantity = payload.quantity;
      return { ...trim, trimPieces: [...piecesCopy1] };
    case "remove piece":
      let piecesCopy = trim.trimPieces;
      piecesCopy.splice(payload, 1);
      return { ...trim, trimPieces: [...piecesCopy] };
    case "add piece":
      return { ...trim, trimPieces: [...trim.trimPieces, payload] };
    case "fill details":
      return { ...trim, ...payload };
    case "reset to initial state":
      return { ...initalTrim };
    default:
      break;
  }
}

function copingCapReducer(cap, action) {
  const { type, payload } = action;
  switch (type) {
    case "edit quantity":
      let piecesCopy1 = cap.capPieces;
      piecesCopy1[payload.index].quantity = payload.quantity;
      return { ...cap, capPieces: [...piecesCopy1] };
    case "remove piece":
      let piecesCopy = cap.capPieces;
      piecesCopy.splice(payload, 1);
      return { ...cap, capPieces: [...piecesCopy] };
    case "add piece":
      return { ...cap, capPieces: [...cap.capPieces, payload] };
    case "fill details":
      return { ...cap, ...payload };
    case "reset to initial state":
      return { ...initialCopingCap };
    default:
      throw Error(`"${type}" is not a valid action type`);
      break;
  }
}

function cleatReducer(cleat, action) {
  const { type, payload } = action;
  switch (type) {
    case "edit quantity":
      let piecesCopy1 = cleat.cleatPieces;
      piecesCopy1[payload.index].quantity = payload.quantity;
      return { ...cleat, cleatPieces: [...piecesCopy1] };
    case "remove piece":
      let piecesCopy = cleat.cleatPieces;
      piecesCopy.splice(payload, 1);
      return { ...cleat, cleatPieces: [...piecesCopy] };
    case "add piece":
      return { ...cleat, cleatPieces: [...cleat.cleatPieces, payload] };
    case "gauge":
      return {
        ...cleat,
        gauge: payload.gauge,
        gaugeUnitOfMeasure: payload.gaugeUnitOfMeasure,
      };
    case "color":
      return { ...cleat, color: payload };
    case "fill details":
      return { ...cleat, ...payload };
    case "reset to initial state":
      return { ...initialCleat };
    default:
      throw Error(`"${type}" is not a valid action type`);
      break;
  }
}

function benchReducer(bench, action) {
  const { type, payload } = action;
  switch (type) {
    case "edit quantity":
      let piecesCopy1 = bench.benchWorkPieces;
      piecesCopy1[payload.index].quantity = payload.quantity;
      return { ...bench, benchWorkPieces: [...piecesCopy1] };
    case "edit notes":
      let piecesCopy2 = bench.benchWorkPieces;
      piecesCopy2[payload.index].notes = payload.notes;
      return { ...bench, benchWorkPieces: [...piecesCopy2] };
    case "remove piece":
      let piecesCopy = bench.benchWorkPieces;
      piecesCopy.splice(payload, 1);
      return { ...bench, benchWorkPieces: [...piecesCopy] };
    case "add piece":
      return { ...bench, benchWorkPieces: [...bench.benchWorkPieces, payload] };
    case "fill details":
      return { ...bench, ...payload };
    case "reset to initial state":
      return { ...initialBench };
    default:
      throw Error(`"${type}" is not a valid action type`);
      break;
  }
}

function spliceReducer(splice, action) {
  const { type, payload } = action;
  switch (type) {
    case "edit quantity":
      let piecesCopy1 = splice.plates;
      piecesCopy1[payload.index].quantity = payload.quantity;
      return { ...splice, plates: [...piecesCopy1] };
    case "edit length":
      let piecesCopy2 = splice.plates;
      piecesCopy2[payload.index].length = payload.length;
      return { ...splice, plates: [...piecesCopy2] };
    case "add piece":
      return {
        ...splice,
        plates: [...splice.plates, payload],
      };
    // case "remove piece":
    //   const index = payload;
    //   const updatedPlates = splice.plates.splice(index, 1);
    //   return { ...splice, plates: [...updatedPlates] };
    case "remove piece":
      let piecesCopy = splice.plates;
      piecesCopy.splice(payload, 1);
      return { ...splice, plates: [...piecesCopy] };
    case "fill details":
      return { ...splice, ...payload };
    case "reset to initial state":
      return { ...initialSplice };
    default:
      throw Error(`"${type}" is not a valid action type`);
      break;
  }
}

function accessoryReducer(accessory, action) {
  const { type, payload } = action;
  switch (type) {
    case "add item": {
      let items = [...accessory.items];
      const index = items.findIndex((item) => item.name === payload.name);
      if (index > -1) {
        items.splice(index, 1, payload);
      } else {
        items.push(payload);
      }

      return {
        ...accessory,
        items: [...items],
      };
    }
    case "remove item":
      const updatedItems = accessory.items.filter(
        (item) => item.name !== payload
      );
      console.log(accessory.items);
      console.log(updatedItems);
      return { ...accessory, items: [...updatedItems] };
    case "fill details":
      return { ...accessory, ...payload };
    case "reset to initial state":
      return { ...initialAccessory };
    default:
      throw Error(`"${type}" is not a valid action type`);
      break;
  }
}
