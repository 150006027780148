import { styled } from "@mui/material/styles";
import {
  TextField,
  OutlinedInput,
  InputAdornment,
  FormControl,
  InputLabel,
} from "@mui/material";

const CustomInputContainer = styled(TextField, [{ shouldForwardProp: true }])`
  display: block;
  & .MuiInputBase-root {
    font-size: 12px;
    padding: 4px;
    height: 30px;
    box-sizing: border-box;
    position: relative;
  }

  & .MuiInputLabel-root {
    font-size: 12px;
    position: absolute;
    top: 0;
    left: 10px;
    transform: translate(0, 50%);
    pointer-events: none;
    transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  }

  & .MuiInputLabel-shrink {
    transform: translate(0, -50%);
  }

  & .MuiInput-underline:before {
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
  }

  &::placeholder {
    line-height: 30px;
    vertical-align: middle;
  }
`;

const SmallInput = ({ onChange, ...props }) => (
  <CustomInputContainer
    variant="outlined"
    onChange={(e) => onChange(e)}
    {...props}
  />
);

export default SmallInput;
