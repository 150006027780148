import makePage from "../../../../hocs/makePage";
import withLoading from "../../../../hocs/withLoading";
import withError from "../../../../hocs/withError";
import { Typography, Card, Box, Button } from "@mui/material";
import moment from "moment/moment";
import FabButton from "../../../../components/CustomerComponents/FabButton";
import AddIcon from "@mui/icons-material/Add";

const TaxFormListUI = ({ taxForms, handleOpen, setAsDefault }) => {
  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
        {taxForms.map((form, index) => (
          <Box
            key={index}
            sx={{
              p: 2,
              marginBottom: 1,
              borderBottomWidth: 1,
              borderBottomStyle: "solid",
              borderBottomColor: "#D3D3D3",
              background: "#fffff",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Box sx={{ flex: 1 }}>
              <Typography fontWeight={"600"}>{form.name}</Typography>
              <Typography>
                uploaded: {moment(form.createdAt).format("MM/DD/YYYY")}
              </Typography>
              <Typography>
                expires: {moment(form.expirationDate).format("MM/DD/YYYY")}
              </Typography>
            </Box>
            {form.company.yearlyTaxForm?.id == form.id ? (
              <Button disabled>Default</Button>
            ) : (
              <Button
                onClick={() => {
                  setAsDefault(form.id);
                }}
              >
                Set as default
              </Button>
            )}
          </Box>
        ))}
      </Box>
      <FabButton color="success" onClick={handleOpen}>
        <AddIcon sx={{ mr: 1 }} />
        Tax Form
      </FabButton>
    </>
  );
};
export default makePage(TaxFormListUI, withError, withLoading);
