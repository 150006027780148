import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Error404 = (props) => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "Center",
      }}
    >
      <Typography
        variant="h1"
        sx={{
          textAlign: "center",
          color: props.lightText ? "#fff" : "#000",
        }}
      >
        404
      </Typography>
      <Typography
        variant="h4"
        sx={{
          textAlign: "center",
          color: props.lightText ? "#fff" : "#000",
        }}
      >
        Page Not Found
      </Typography>
      <Button
        variant="contained"
        sx={{
          mt: 2,
          backgroundColor: props.lightText ? "#fff" : "#000",
          color: props.lightText ? "#000" : "#fff",
        }}
        onClick={() => navigate("/")}
      >
        Home
      </Button>
    </Box>
  );
};
export default Error404;
