import View from "./Views/Main";
import { MobileOrderContext } from "../../../../context/MobileOrderContext";
import { AuthContext } from "../../../../context/AuthContext";
import usePageName from "../../../../hocs/usePageName";
import { useState, useContext, createContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import makePage from "../../../../hocs/makePage";
import withError from "../../../../hocs/withError";
import withLoading from "../../../../hocs/withLoading";
import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import { SnackAlertContext } from "../../../../context/SnackAlertContext";
import OrderWithEstimateDetails from "./Views/OrderWithEstimateDetails";
import OrderTitle from "../../../../components/OrderTitle";
import { HeadingContext } from "../../../../context/HeadingContext";
import moment from "moment/moment";
import { useDebounce } from "use-debounce";
import { useTheme } from "@emotion/react";
import { Check } from "@mui/icons-material";

const OrderView = makePage(View, withError, withLoading);

const OrderDetails = () => {
  const navigate = useNavigate();
  const { headingText, setHeadingText, captionText, setCaptionText } =
    useContext(HeadingContext);
  const { openSnackMessage } = useContext(SnackAlertContext);
  const { projectId, orderId } = useParams();
  const { authToken, ezorder, decoded } = useContext(AuthContext);
  const headers = {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  };
  const { order, setTaxForms, orderDispatch, fillOrderDetails } =
    useContext(MobileOrderContext);

  const [orderStateDebounce] = useDebounce(order, 1500);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);
  const [toggleDisappear, setToggleDisappear] = useState(true);
  const [toggleDisappearDebounce] = useDebounce(toggleDisappear, 2000);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const getOrderById = async () => {
    try {
      setLoading(true);
      const res = await ezorder.get(`/orders/${orderId}`, headers);
      console.log(res);
      fillOrderDetails(res.data.order);
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const updateOrder = async (controller) => {
    try {
      setIsUpdating(true);
      console.log("UPDATING ORDER", order);

      let data = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };

      if (controller) {
        data.signal = controller.signal;
      }

      const res = await ezorder.put(`/orders/${orderId}`, order, data);
      console.log(res);
      // fillOrderDetails(res.data.order);
      // openSnackMessage("success", "Changes Saved");
    } catch (error) {
      // if (error.response) {
      //   // The request was made and the server responded with a status code
      //   // that falls out of the range of 2xx
      //   console.log(error.response.data);
      //   console.log(error.response.status);
      //   console.log(error.response.headers);
      //   openSnackMessage("error", error.response.data.error);
      // } else {
      //   // Something went really bad
      //   console.log(error);
      //   openSnackMessage("error", error);
      // }
    } finally {
      setIsUpdating(false);
    }
  };

  const requestEstimate = async (estimateAssignedApproverId) => {
    try {
      setLoading(true);
      const res = await ezorder.put(
        `/orders/${orderId}/estimate-request`,
        {
          order,
          estimateAssignedApproverId,
        },
        headers
      );

      fillOrderDetails(res.data.order);
      openSnackMessage(
        "success",
        "Quote Requested",
        "We will send your quote via email"
      );
      navigate("/quotes");
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage("error", error.response.data.error);
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", error);
      }
    } finally {
      setLoading(false);
    }
  };

  const declineEstimate = async (cancelOrder, commentText) => {
    try {
      setLoading(true);
      console.log("Declining Estimate");

      let requestBody = {
        cancelOrder, // boolean
        comment: commentText,
      };

      const response = await ezorder.put(
        `/orders/${order.id}/estimate/decline`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      fillOrderDetails(response.data.order);
      openSnackMessage(
        "success",
        "Changes requested",
        "We will send your new quote through email"
      );
      navigate("/quotes");
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage("error", error.response.data.error);
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", error);
      }
    } finally {
      setLoading(false);
    }
  };

  const requestNewEstimateForExpired = async (commentText) => {
    try {
      setLoading(true);
      console.log("Requesting a new quote for expired");

      let requestBody = {
        comment: commentText,
      };

      const response = await ezorder.put(
        `/orders/${order.id}/estimate/request-new`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      fillOrderDetails(response.data.order);
      openSnackMessage(
        "success",
        "New Quote requested",
        "We will send your new quote through email"
      );
      navigate("/quotes");
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage("error", error.response.data.error);
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", error);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    //getProjectTaxForms();
    getOrderById();
  }, []);

  useEffect(() => {
    if (order && order.orderStatus) {
      let heading = "";
      switch (order.orderStatus.name) {
        case null:
        case "SAVED":
        case "QUOTE_REQUESTED":
          heading = "Quote Request";
          break;
        case "ESTIMATE_APPROVAL_NEEDED":
          heading = "Quote Details";
          break;
        default:
          heading = "Order Details";
          break;
      }
      setHeadingText(heading);
    } else {
      setHeadingText("Quote Request");
    }
  }, [order.orderStatus]);

  useEffect(() => {
    const controller = new AbortController();
    if (
      order &&
      orderStateDebounce &&
      orderStateDebounce.id && // This insures that the order is actually loaded
      orderStateDebounce.orderStatus.name == "SAVED"
    ) {
      if (isInitialLoad) {
        setIsInitialLoad(false);
      } else {
        updateOrder(controller);
      }
    }
    return () => {
      controller.abort(); // abort api call on unmount for cleanup
    };
  }, [orderStateDebounce]);

  useEffect(() => {
    if (!isUpdating) {
      setToggleDisappear(!toggleDisappear);
    }
  }, [isUpdating]);

  let showSaving = isUpdating || toggleDisappear != toggleDisappearDebounce;

  if (
    !order.orderStatus ||
    !order.orderStatus.name ||
    order.orderStatus.name == "SAVED" ||
    order.orderStatus.name == "QUOTE_REQUESTED"
  ) {
    return (
      <Box sx={{ padding: "16px" }}>
        {showSaving && (
          <Box
            sx={{
              width: "100%",
              background: theme.palette.background.default,
              position: "fixed",
              top: isSmallScreen ? "70px" : "64px",
              left: "0px",
              padding: "8px",
            }}
          >
            <Typography
              sx={{ width: "100%", textAlign: "right" }}
              color="#c0c0c0"
            >
              {isUpdating ? "Saving..." : "Saved "}
              {!isUpdating && <Check fontSize="18px" />}
            </Typography>
          </Box>
        )}

        <OrderTitle order={order} />
        <OrderView
          isAutoSaving={order.id ? true : false}
          isUpdating={isUpdating}
          showSaving={showSaving}
          canUpdateOrder={
            order.id && order.orderStatus && order.orderStatus.name === "SAVED"
          }
          canRequestEstimate={
            order.orderStatus &&
            order.orderStatus.name === "SAVED" &&
            (decoded.permissions.filter(
              (p) =>
                p.name === "Request Quote" ||
                p.name === "Request Quote And Approve Own Quotes" ||
                p.name === "Request Quote And Approve All Quotes"
            ).length > 0 ||
              decoded.role === "OWNER")
          }
          requestEstimate={requestEstimate}
          onSave={updateOrder}
          error={error}
          loading={loading}
        />
      </Box>
    );
  } else {
    return (
      <OrderWithEstimateDetails
        order={order}
        getOrderById={getOrderById}
        canApproveEstimate={
          order.orderStatus.name === "ESTIMATE_APPROVAL_NEEDED" &&
          (decoded.permissions.filter(
            (p) =>
              (p.name === "Request Quote And Approve Own Quotes" &&
                order.estimateAssignedApprover &&
                decoded.id == order.estimateAssignedApprover.id) ||
              p.name === "Request Quote And Approve All Quotes"
          ).length > 0 ||
            decoded.role === "OWNER")
        }
        onDeclineEstimate={declineEstimate}
        isEstimateExpired={
          order.orderStatus &&
          order.estimateExpirationDate &&
          order.orderStatus.name == "ESTIMATE_APPROVAL_NEEDED" &&
          moment(order.estimateExpirationDate) < moment()
        }
        onRenewEstimate={requestNewEstimateForExpired}
        // addComment={addComment}
        // isAddingComment={isAddingComment}
        isLoading={loading}
      />
    );
  }
};
export default OrderDetails;
