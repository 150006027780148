import {
  TextField,
  Typography,
  Box,
  Button,
  IconButton,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddProductModal from "../AddProductModal";
import { useContext, useEffect } from "react";
import { MobileOrderContext } from "../../../../../../context/MobileOrderContext";
import useAccessoriesCatalog from "../../../../../../hooks/useAccessoriesCatalog";
import { Add } from "@mui/icons-material";
import TextFieldDebounced from "../../../../../../components/TextFieldDebounced";

const Accessories = ({
  showSaving,
  isUpdating,
  isProductSelected,
  isAutoSaving,
  canUpdate,
  open,
  handleClose,
  handleNext,
  hasNext,
}) => {
  const { accessories, accessoryDispatch, orderDispatch } =
    useContext(MobileOrderContext);
  const [catalog, loading, error] = useAccessoriesCatalog();

  const addItem = (item) => {
    let catalogItemCopy = { ...item };
    delete catalogItemCopy.id;
    accessoryDispatch({
      type: "add item",
      payload: { ...catalogItemCopy, quantity: 1 },
    });
  };
  const removeItemByName = (name) => {
    accessoryDispatch({ type: "remove item", payload: name });
  };
  const handleQtyByItemName = (name) => (e) => {
    const item = catalog.find((item) => item.name === name);
    let catalogItemCopy = { ...item };
    delete catalogItemCopy.id;
    accessoryDispatch({
      type: "add item",
      payload: { ...catalogItemCopy, quantity: e.target.value },
    });
  };

  const getQuantityByItemName = (name) => {
    const item = accessories.items.find((item) => item.name === name);
    return item.quantity;
  };

  const handleSave = () => {
    orderDispatch({ type: "add order item", payload: accessories });
  };

  useEffect(() => {
    if (isProductSelected && canUpdate && isAutoSaving) {
      // TODO: auto save on every change
      handleSave();
    }
  }, [isProductSelected, accessories]);

  return (
    <AddProductModal
      showSaving={showSaving}
      isUpdating={isUpdating}
      open={open}
      handleClose={() => {
        handleSave();
        handleClose();
      }}
    >
      <DialogTitle>Accessories/Misc.</DialogTitle>
      <DialogContent sx={{ gap: "1rem" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            maxHeight: "28rem",
            overflow: "auto",
            gap: "1rem",
          }}
        >
          {catalog
            .filter((item) => item.inStock)
            .map((item, index) => (
              <Box key={index}>
                {accessories.items
                  .map((item) => item.name)
                  .includes(item.name) ? (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "1rem",
                      alignItems: "center",
                    }}
                  >
                    <TextFieldDebounced
                      variant="standard"
                      value={item.name}
                      label="item"
                      InputProps={{
                        readOnly: true,
                      }}
                      disabled
                    />

                    <TextFieldDebounced
                      variant="standard"
                      value={item.unitOfMeasure}
                      label="Unit"
                      InputProps={{
                        readOnly: true,
                      }}
                      disabled
                    />
                    <TextFieldDebounced
                      onFocus={(e) => e.target.select()}
                      variant="standard"
                      label="Quantity"
                      type={"number"}
                      value={getQuantityByItemName(item.name)}
                      onChange={handleQtyByItemName(item.name)}
                      disabled={!canUpdate}
                    />
                    {canUpdate && (
                      <IconButton
                        sx={{ display: "flex", flexDirection: "column" }}
                        onClick={() => removeItemByName(item.name)}
                      >
                        <Typography variant="caption">remove</Typography>
                        <CloseIcon />
                      </IconButton>
                    )}
                  </Box>
                ) : canUpdate ? (
                  <Button
                    color="success"
                    fullWidth
                    variant="outlined"
                    onClick={() => addItem(item)}
                  >
                    {item.name}
                  </Button>
                ) : null}
              </Box>
            ))}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            handleSave();
            handleClose();
          }}
        >
          {/* {canUpdate && isAutoSaving ? "Save & Close" : "Close"} */}
          Close
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            handleSave();
            handleNext();
          }}
        >
          {hasNext
            ? "Next"
            : canUpdate && isAutoSaving
            ? "Save & Exit"
            : "Exit"}
        </Button>
      </DialogActions>
    </AddProductModal>
  );
};
export default Accessories;
