import { useTheme } from "@emotion/react";
import {
  Card,
  Typography,
  Box,
  Button,
  IconButton,
  useMediaQuery,
  Menu,
  MenuItem,
  Fade,
  CircularProgress,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import { Delete, Download, MoreVert } from "@mui/icons-material";
import estimatePdfDownloadHandler from "../helper/estimatePdfDownloader";
import moment from "moment/moment";
import OrderStatusIcon from "./OrderStatusIcon";

const OrderCard = ({ order, clickHandler, onDelete, onCopy, isCopying }) => {
  const { authToken } = useContext(AuthContext);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [loading, setLoading] = useState(false);
  const [criticalDates, setCriticalDates] = useState(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // const getOrderCriticalDatesById = async (orderId) => {
  //   try {
  //     setLoading(true);

  //     const response = await ezorder.get(`/orders/${orderId}/critical-dates`, {
  //       headers: {
  //         Authorization: `Bearer ${authToken}`,
  //       },
  //     });
  //     setCriticalDates(response.data.criticalDates);
  //   } catch (error) {
  //     console.log(error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   if (order) {
  //     getOrderCriticalDatesById(order.id);
  //   }
  // }, [order]);

  return (
    <Box
      variant={isSmallScreen ? Box : Card}
      background="secondary.dark"
      sx={{
        marginTop: "2px",
        background: theme.palette.secondary.main,
      }}
    >
      <Box sx={{ padding: "8px" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: "1rem",
          }}
        >
          <Box onClick={clickHandler} sx={{ flex: 1 }}>
            <Typography
              variant="subtitle1"
              // color="primary"
              sx={{
                textTransform: "uppercase",
                fontWeight: "bold",
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: "1",
                WebkitBoxOrient: "vertical",
              }}
            >
              {!order.orderStatus ||
              !order.orderStatus.name ||
              order.orderStatus.name == "SAVED" ||
              order.orderStatus.name == "QUOTE_REQUESTED" ||
              order.orderStatus.name == "ESTIMATE_APPROVAL_NEEDED"
                ? "Quote "
                : "Order "}
              #{order.id} - {order.project.name}
            </Typography>
            <Typography
              // variant="subtitle2"
              variant="body1"
              color="#c0c0c0"
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: "1",
                WebkitBoxOrient: "vertical",
              }}
            >
              <OrderStatusIcon
                orderStatus={
                  order.isUnconfirmedCutlist &&
                  order.orderStatus.name == "QUOTE_REQUESTED"
                    ? "UNCONFIRMED"
                    : order.orderStatus.name
                }
              />{" "}
              {order.isUnconfirmedCutlist
                ? "QUOTED - Budget Only"
                : order.orderStatus.description}{" "}
              {order.orderStatus.name == "READY_FOR_PICKUP" &&
              order.shopCompletionDate
                ? moment(order.shopCompletionDate).format("MM/DD/YY")
                : ""}
            </Typography>
            {order.orderStatus.name == "IN_PROGRESS" && (
              <Typography variant="body1" color="#c0c0c0">
                Estimated Completion Date:{" "}
                {order.shopEstimateCompletionDate
                  ? moment(order.shopEstimateCompletionDate).format("MM/DD/YY")
                  : "TBD"}
              </Typography>
            )}
            {/* <Typography
              variant="body1"
              color="#c0c0c0"
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: "1",
                WebkitBoxOrient: "vertical",
              }}
            >
              PO#: {order.poNumber}
            </Typography> */}

            {order.vendorProjectName && (
              <Typography
                variant="body1"
                color="#c0c0c0"
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: "1",
                  WebkitBoxOrient: "vertical",
                }}
              >
                Project: {order.vendorProjectName}
              </Typography>
            )}
            {order.estimateRequestedBy && (
              <Typography
                variant="body1"
                color="#c0c0c0"
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: "1",
                  WebkitBoxOrient: "vertical",
                }}
              >
                Requested By: {order.estimateRequestedBy.fullName}
              </Typography>
            )}

            {order.orderStatus.name == "ESTIMATE_APPROVAL_NEEDED" &&
              order.estimateExpirationDate && (
                <Typography
                  variant="body1"
                  color="#c0c0c0"
                  sx={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: "1",
                    WebkitBoxOrient: "vertical",
                    color:
                      moment(order.estimateExpirationDate) < moment()
                        ? "red"
                        : "#c0c0c0",
                  }}
                >
                  {moment(order.estimateExpirationDate) < moment()
                    ? "Expired: "
                    : "Valid Until: "}
                  {moment(order.estimateExpirationDate)
                    .local()
                    .format("MM/DD/YY")}
                </Typography>
              )}

            {order.estimateApprovalDate && (
              <Typography
                variant="body1"
                color="#c0c0c0"
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: "1",
                  WebkitBoxOrient: "vertical",
                }}
              >
                Approved By Cust. On:{" "}
                {moment(order.estimateApprovalDate).format("MM/DD/YY")}
              </Typography>
            )}
          </Box>
          <Box>
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={open ? "long-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              onClick={handleClick}
            >
              <MoreVert />
            </IconButton>
            {order.orderStatus.name != "QUOTE_REQUESTED" && (
              <Menu
                id="fade-menu"
                MenuListProps={{
                  "aria-labelledby": "fade-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
              >
                {!order.isEstimateApproved && order.estimatePdf && (
                  <MenuItem
                    onClick={() =>
                      estimatePdfDownloadHandler(
                        order.estimatePdf,
                        authToken,
                        null,
                        null,
                        `CUSTOMER-QUOTE-${order.id}-${order.company.name}-${order.project.name}.pdf`
                      )
                    }
                  >
                    <Download color="primary" />
                    Quote PDF
                  </MenuItem>
                )}
                {order.isEstimateApproved && order.signedEstimatePDF && (
                  <MenuItem
                    onClick={() => {
                      estimatePdfDownloadHandler(
                        order.signedEstimatePDF,
                        authToken,
                        false,
                        null,
                        `SIGNED-CUSTOMER-QUOTE-${order.id}-${order.company.name}-${order.project.name}.pdf`
                      );
                    }}
                  >
                    <Download color="primary" />
                    Signed Quote PDF
                  </MenuItem>
                )}
                {/* <MenuItem onClick={onCopy} disabled={isCopying}>
                {isCopying ? (
                  <CircularProgress
                    color="primary"
                    size="1rem"
                    sx={{ marginRight: "4px" }}
                  />
                ) : (
                  <ContentCopyIcon color="primary" />
                )}
                Request New Quote
              </MenuItem> */}
                {order.orderStatus.name == "SAVED" && (
                  <MenuItem onClick={onDelete}>
                    <Delete color="error" />
                    Delete
                  </MenuItem>
                )}
                {/* <MenuItem onClick={handleClose}>Profile</MenuItem>
              <MenuItem onClick={handleClose}>My account</MenuItem>
              <MenuItem onClick={handleClose}>Logout</MenuItem> */}
              </Menu>
            )}

            {/* {order.estimatePdf && (
              <Button
                onClick={() =>
                  estimatePdfDownloadHandler(
                    order.estimatePdf,
                    authToken,
                    null,
                    null,
                    `CUSTOMER-QUOTE-${order.id}-${order.company.name}-${order.project.name}.pdf`
                  )
                }
              >
                <Download color="primary" />
                Estimate
              </Button>
            )}
            <Button onClick={onCopy}>
              <ContentCopyIcon color="primary" /> Copy
            </Button> */}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default OrderCard;
