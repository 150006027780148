import { useState, useEffect, useContext, useRef } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Fab,
  InputAdornment,
  MenuItem,
  Paper,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import InfiniteScrollerTable from "../../../components/InfiniteScroller/InfiniteScrollerTable";
import ListFooter from "../../../components/InfiniteScroller/ListFooter";
import { AuthContext } from "../../../context/AuthContext";
import TableSearchAndFilter from "../../../components/TableSearchAndFilter";
import useSales from "../../../hooks/useSales";
import moment from "moment/moment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { LoadingButton } from "@mui/lab";
import { useTheme } from "@emotion/react";
import TextFieldDebounced from "../../../components/TextFieldDebounced";
import { Search } from "@mui/icons-material";

const Sales = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const tableRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const { authToken, ezorder } = useContext(AuthContext);
  const [salesGuys, setSalesGuys] = useState([]);
  const {
    searchQuery,
    setSearchQuery,
    salesGuySearch,
    setSalesGuySearch,
    userQuery,
    dateRange,
    setDateRange,
    results,
    onRefresh,
    refreshing,
    onLoadMore,
    loadingMore,
    total,
    hasMore,
  } = useSales(authToken, 50);
  const [timeFrame, setTimeFrame] = useState("week");

  const handleTimeFrameChange = () => {
    let today = moment();
    let start;
    let end;
    switch (timeFrame) {
      case "day":
        start = moment(today).startOf("day");
        end = moment(today).endOf("day");
        setDateRange((prev) => ({
          ...prev,
          startDate: start,
          endDate: end,
        }));
        break;
      case "week":
        start = moment(today).startOf("isoweek").startOf("day");
        end = moment(today).endOf("isoweek").endOf("day");
        setDateRange((prev) => ({
          ...prev,
          startDate: start,
          endDate: end,
        }));
        break;
      case "month":
        start = moment(today).startOf("month").startOf("day");
        end = moment(today).endOf("month").endOf("day");
        setDateRange((prev) => ({
          ...prev,
          startDate: start,
          endDate: end,
        }));
        break;
      case "year":
        start = moment(today).startOf("year").startOf("day");
        end = moment(today).endOf("year").endOf("day");
        setDateRange((prev) => ({
          ...prev,
          startDate: start,
          endDate: end,
        }));
        break;
      case "all-time":
        start = moment(0);
        end = moment(today).endOf("year").endOf("day");
        setDateRange((prev) => ({
          ...prev,
          startDate: start,
          endDate: end,
        }));
        break;
    }
  };

  const handlePrev = () => {
    let start;
    let end;
    switch (timeFrame) {
      case "day":
        start = moment(dateRange.startDate).subtract(1, "days").startOf("day");
        end = moment(dateRange.startDate).subtract(1, "days").endOf("day");
        setDateRange((prev) => ({
          ...prev,
          startDate: start,
          endDate: end,
        }));
        break;
      case "week":
        let dayPrevWeek = moment(dateRange.startDate).subtract(1, "week");
        start = moment(dayPrevWeek).startOf("isoweek").startOf("day");
        end = moment(dayPrevWeek).endOf("isoweek").endOf("day");
        setDateRange((prev) => ({
          ...prev,
          startDate: start,
          endDate: end,
        }));
        break;
      case "month":
        let dayPrevMonth = moment(dateRange.startDate).subtract(1, "month");
        start = moment(dayPrevMonth).startOf("month").startOf("day");
        end = moment(dayPrevMonth).endOf("month").endOf("day");
        setDateRange((prev) => ({
          ...prev,
          startDate: start,
          endDate: end,
        }));
        break;
      case "year":
        let dayPrevYear = moment(dateRange.startDate).subtract(1, "year");
        start = moment(dayPrevYear).startOf("year").startOf("day");
        end = moment(dayPrevYear).endOf("year").endOf("day");
        setDateRange((prev) => ({
          ...prev,
          startDate: start,
          endDate: end,
        }));
        break;
    }
  };

  const handleNext = () => {
    let start;
    let end;
    switch (timeFrame) {
      case "day":
        start = moment(dateRange.startDate).add(1, "days").startOf("day");
        end = moment(dateRange.startDate).add(1, "days").endOf("day");
        setDateRange((prev) => ({
          ...prev,
          startDate: start,
          endDate: end,
        }));
        break;
      case "week":
        console.log("NEXT WEEK");
        let dayNextWeek = moment(dateRange.startDate).add(1, "week");
        start = moment(dayNextWeek).startOf("isoweek").startOf("day");
        end = moment(dayNextWeek).endOf("isoweek").endOf("day");
        setDateRange((prev) => ({
          ...prev,
          startDate: start,
          endDate: end,
        }));
        break;
      case "month":
        let dayNextMonth = moment(dateRange.startDate).add(1, "month");
        start = moment(dayNextMonth).startOf("month").startOf("day");
        end = moment(dayNextMonth).endOf("month").endOf("day");
        setDateRange((prev) => ({
          ...prev,
          startDate: start,
          endDate: end,
        }));
        break;
      case "year":
        let dayNextYear = moment(dateRange.startDate).add(1, "year");
        start = moment(dayNextYear).startOf("year").startOf("day");
        end = moment(dayNextYear).endOf("year").endOf("day");
        setDateRange((prev) => ({
          ...prev,
          startDate: start,
          endDate: end,
        }));
        break;
    }
  };

  const getSalesGuys = async (controller) => {
    try {
      setLoading(true);
      let data = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      if (controller) {
        data.signal = controller.signal;
      }

      const response = await ezorder.get(`/users`, data);
      let employees = response.data.employees;
      let salesGuylist = [];
      for (const employee of employees) {
        salesGuylist.push({
          label: employee.fullName,
          id: employee.id,
        });
      }
      setSalesGuys(salesGuylist);
    } catch (error) {
      // Object.keys(error).map((item) => console.log(error[item]));
      // throw Error("Unable to load employees", { cause: error });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleTimeFrameChange();
  }, [timeFrame]);

  useEffect(() => {
    getSalesGuys();
  }, []);

  return (
    <Box
      sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}
    >
      <Box
        sx={{
          position: "relative",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <DownloadTableExcel
          filename="Sales"
          sheet="sales"
          currentTableRef={tableRef.current}
        >
          <LoadingButton
            loading={loadingMore}
            sx={{ position: "absolute", right: 0, top: 12 }}
          >
            Export To Excel
          </LoadingButton>
        </DownloadTableExcel>
        <Typography variant="h4" component="div">
          Sales
        </Typography>
      </Box>
      <Box
        sx={{
          marginTop: "16px",
          width: "100%",
          display: "flex",
          flexDirection: isSmallScreen ? "column" : "row",
          justifyContent: "center",
          alignItems: "flex-end",
        }}
      >
        <TextField
          fullWidth={isSmallScreen}
          sx={{
            width: isSmallScreen ? "100%" : 300,
            marginRight: isSmallScreen ? 0 : "8px",
            marginTop: isSmallScreen ? "8px" : 0,
          }}
          select
          variant="outlined"
          label="Search By Date"
          value={timeFrame}
          onChange={(e) => setTimeFrame(e.target.value)}
        >
          <MenuItem value={"day"}>Day</MenuItem>
          <MenuItem value={"week"}>Week</MenuItem>
          <MenuItem value={"month"}>Month</MenuItem>
          <MenuItem value={"year"}>Year</MenuItem>
          <MenuItem value={"all-time"}>All-Time</MenuItem>
          <MenuItem value={"custom"}>Custom</MenuItem>
        </TextField>
        <Autocomplete
          fullWidth={isSmallScreen}
          sx={{
            width: isSmallScreen ? "100%" : 300,
            marginRight: isSmallScreen ? 0 : "8px",
            marginTop: isSmallScreen ? "8px" : 0,
          }}
          disablePortal
          inputValue={salesGuySearch}
          onInputChange={(event, newInputValue) => {
            setSalesGuySearch(newInputValue);
          }}
          options={salesGuys}
          renderInput={(params) => (
            <TextField fullWidth {...params} label="Sales Person" />
          )}
        />
        <TextFieldDebounced
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search color="primary" />
              </InputAdornment>
            ),
          }}
          label={"Search (Company, Job Name, Order ID)"}
          fullWidth
          sx={{ marginTop: isSmallScreen ? "8px" : 0 }}
          value={searchQuery}
          onChange={(e) => {
            setSearchQuery(e.target.value);
          }}
        />
        {/* <TableSearchAndFilter
          searchTerms={searchQuery}
          setSearchTerms={setSearchQuery}
        /> */}
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "16px",
          marginBottom: "8px",
        }}
      >
        <Box>
          {timeFrame == "custom" ? (
            <Box>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  label="Start Date"
                  value={dateRange.startDate}
                  onChange={(newValue) => {
                    setDateRange((prev) => ({
                      ...prev,
                      startDate: moment(newValue).startOf("day"),
                    }));
                  }}
                  renderInput={(params) => (
                    <TextField sx={{ ml: "10px" }} size="small" {...params} />
                  )}
                />
                <DatePicker
                  label="End Date"
                  value={dateRange.endDate}
                  onChange={(newValue) => {
                    setDateRange((prev) => ({
                      ...prev,
                      endDate: moment(newValue).endOf("day"),
                    }));
                  }}
                  renderInput={(params) => (
                    <TextField sx={{ ml: "10px" }} size="small" {...params} />
                  )}
                />
              </LocalizationProvider>
            </Box>
          ) : timeFrame != "all-time" ? (
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <Button onClick={handlePrev}>Prev</Button>
              <Box>
                <Typography>
                  {dateRange.startDate.format("MM/DD/YY")} -{" "}
                  {dateRange.endDate.format("MM/DD/YY")}
                </Typography>
              </Box>
              <Button onClick={handleNext}>Next</Button>
            </Box>
          ) : null}
        </Box>
      </Box>

      <InfiniteScrollerTable
        tableRef={tableRef}
        TableContainerComponent={Paper}
        TableStyle={{ minWidth: "600px" }}
        TableHead={() => (
          <TableHead>
            <TableRow>
              <TableCell>Date Signed By Customer</TableCell>
              <TableCell>Sales Rep</TableCell>
              <TableCell>Customer</TableCell>
              <TableCell>Job Name</TableCell>
              <TableCell>Order ID</TableCell>
              <TableCell>Out The Door Cost</TableCell>
            </TableRow>
          </TableHead>
        )}
        items={results}
        renderRow={(item, index) => {
          return (
            <TableRow>
              <TableCell
                hover
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  navigate(`/orders/${item.id}`);
                }}
              >
                {moment(item.estimateApprovalDate).format("MM/DD/YY")}
              </TableCell>
              <TableCell
                hover
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  navigate(`/orders/${item.id}`);
                }}
              >
                {item.salesRep ? item.salesRep.fullName : "UNASSIGNED"}
              </TableCell>
              <TableCell>{item.company ? item.company.name : ""}</TableCell>
              <TableCell>{item.project ? item.project.name : ""}</TableCell>
              <TableCell
                hover
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  navigate(`/orders/${item.id}`);
                }}
              >
                {item.id}
              </TableCell>
              <TableCell
                hover
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  navigate(`/orders/${item.id}`);
                }}
              >
                ${item.outTheDoorCost}
              </TableCell>
            </TableRow>
          );
        }}
        onRefresh={onRefresh}
        onEndReached={() => {}}
        loading={refreshing || loadingMore}
        ListFooterComponent={() => {
          return <ListFooter hasMore={hasMore} onClick={() => {}} />;
        }}
      />
    </Box>
  );
};

export default Sales;
