import { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useTheme } from "@emotion/react";
import { Dialog, DialogContent, useMediaQuery } from "@mui/material";
import { Check } from "@mui/icons-material";

const AddProductModal = ({
  open,
  handleClose,
  children,
  showSaving,
  isUpdating,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <Dialog
        // disableEnforceFocus
        keepMounted
        scroll={"paper"}
        maxWidth="md"
        fullWidth={true}
        fullScreen={fullScreen}
        PaperProps={{
          style: {
            background: theme.palette.secondary.main,
          },
        }}
        open={open}
        onClose={handleClose}
      >
        {showSaving && isSmallScreen && (
          <Box
            sx={{
              height: "100%",
              width: "100%",
              // background: theme.palette.background.default,
              position: "absolute",
              top: "8px",
              left: "0px",
              padding: "8px",
            }}
          >
            <Typography
              sx={{ width: "100%", textAlign: "right" }}
              color="#c0c0c0"
            >
              {isUpdating ? "Saving..." : "Saved "}
              {!isUpdating && <Check fontSize="18px" />}
            </Typography>
          </Box>
        )}
        {children}
        {/* </Box> */}
      </Dialog>
    </>
  );
};
export default AddProductModal;
