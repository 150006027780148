import { useTheme } from "@emotion/react";
import { Add, CameraAlt, Close, Upload } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Modal,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useState } from "react";
import ImageCropper from "../../ImageCropper/ImageCropper";

const ColorFormModal = ({
  saveColor,
  visible,
  setVisible,
  manufacturerName,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [name, setName] = useState("");
  const [imageBlob, setImageBlob] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [imageBlobCropped, setImageBlobCropped] = useState(null);
  const [imageUrlCropped, setImageUrlCropped] = useState(null);

  const resetData = () => {
    setName("");
    setImageBlob(null);
    setImageUrl(null);
    setImageBlobCropped(null);
    setImageUrlCropped(null);
  };

  return (
    <Dialog
      fullWidth={true}
      open={visible}
      onClose={() => {
        setVisible(false);
        resetData();
      }}
    >
      <DialogTitle>Add New {manufacturerName} Color</DialogTitle>
      <DialogContent>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <TextField
            size="small"
            fullWidth
            variant="standard"
            label="Name"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
            sx={{ marginTop: 2 }}
          />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ marginTop: "12px" }}
          >
            Color Thumbnail
          </Typography>
          <div>
            {imageUrl ? (
              <ImageCropper
                cropShape={"round"}
                image={imageUrl}
                onSave={(fileBlob, url) => {
                  setImageUrl(null);
                  setImageBlob(null);
                  setImageBlobCropped(fileBlob);
                  setImageUrlCropped(url);
                }}
              />
            ) : (
              <div>
                <div>
                  {imageUrlCropped && (
                    <div style={{ position: "relative" }}>
                      <img
                        src={imageUrlCropped}
                        style={{
                          marginTop: 16,
                          // maxWidth: "100%",
                          // maxHeight: 200,
                          width: "100%",
                        }}
                      />
                      <div
                        onClick={() => {
                          setImageUrlCropped(null);
                          setImageBlobCropped(null);
                        }}
                      >
                        <Close
                          style={{ position: "absolute", top: 8, right: 8 }}
                        />
                      </div>
                    </div>
                  )}
                </div>
                {imageBlob === null &&
                  imageUrl === null &&
                  imageBlobCropped === null &&
                  imageUrlCropped === null && (
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <label>
                        <input
                          style={{ display: "none" }}
                          type="file"
                          accept="image/*"
                          // capture="environment"
                          onChange={(event) => {
                            console.log(event.target.files[0]);
                            console.log(
                              "Image",
                              URL.createObjectURL(event.target.files[0])
                            );
                            setImageBlob(event.target.files[0]);
                            setImageUrl(
                              URL.createObjectURL(event.target.files[0])
                            );
                            setImageBlobCropped(null);
                            setImageUrlCropped(null);
                          }}
                        />
                        <div
                          style={{
                            cursor: "pointer",
                            width: "100%",
                            padding: 12,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              borderStyle: "solid",
                              borderWidth: 1,
                              borderColor: "#d2d2d2",
                              color: "#d2d2d2",
                              width: 100,
                              height: 100,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Upload sx={{ fontSize: 40 }} />
                          </div>
                        </div>
                      </label>
                      <label>
                        <input
                          style={{ display: "none" }}
                          type="file"
                          accept="image/*"
                          capture="environment"
                          onChange={(event) => {
                            console.log(event.target.files[0]);
                            console.log(
                              "Image",
                              URL.createObjectURL(event.target.files[0])
                            );
                            setImageBlob(event.target.files[0]);
                            setImageUrl(
                              URL.createObjectURL(event.target.files[0])
                            );
                            setImageBlobCropped(null);
                            setImageUrlCropped(null);
                          }}
                        />
                        <div
                          style={{
                            cursor: "pointer",
                            width: "100%",
                            padding: 12,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              borderStyle: "solid",
                              borderWidth: 1,
                              borderColor: "#d2d2d2",
                              color: "#d2d2d2",
                              width: 100,
                              height: 100,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <CameraAlt sx={{ fontSize: 40 }} />
                          </div>
                        </div>
                      </label>
                    </div>
                  )}
              </div>
            )}
          </div>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "center",
            marginTop: 2,
          }}
        >
          <Button
            onClick={() => {
              setVisible(false);
              resetData();
            }}
          >
            Cancel
          </Button>
          <Button
            sx={{ marginLeft: 1 }}
            variant="contained"
            onClick={() => {
              console.log("saveColor", name, imageBlobCropped);
              saveColor(name, imageBlobCropped);
            }}
          >
            Save
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default ColorFormModal;
