import {
  Card,
  Modal,
  Typography,
  Button,
  TextField,
  Dialog,
  useMediaQuery,
  DialogContent,
  DialogActions,
  DialogTitle,
  Box,
  Checkbox,
} from "@mui/material";
import { useCallback, useState } from "react";
import { CameraAlt, Close, Upload } from "@mui/icons-material";
import { useTheme } from "@emotion/react";
import ImageCropper from "../ImageCropper/ImageCropper";
import S3PresignedImage from "../CustomerComponents/S3/S3PresignedImage";
import { useEffect } from "react";

const EditTrimDialog = ({
  trim,
  open,
  setOpen,
  onSubmit,
  isMasterCatalogItem,
  enableSaveToCatalog = false,
  catalogCompanyName,
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [name, setName] = useState(trim.name ? trim.name : "");
  const [masterHits, setMasterHits] = useState(
    trim.masterHits ? trim.masterHits : ""
  );
  const [masterStretchOut, setMasterStretchOut] = useState(
    trim.masterStretchOut ? trim.masterStretchOut : ""
  );
  const [savedImage, setSavedImage] = useState(trim.image ? trim.image : null);
  const [imageBlob, setImageBlob] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [imageBlobCropped, setImageBlobCropped] = useState(null);
  const [imageUrlCropped, setImageUrlCropped] = useState(null);
  const [saveToCatalog, setSaveToCatalog] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (trim.name) setName(trim.name);
  }, [trim.name]);
  useEffect(() => {
    if (trim.image) setSavedImage(trim.image);
  }, [trim.image]);
  useEffect(() => {
    if (trim.masterHits) setMasterHits(trim.masterHits);
  }, [trim.masterHits]);
  useEffect(() => {
    if (masterStretchOut.masterStretchOut)
      setMasterHits(masterStretchOut.masterStretchOut);
  }, [masterStretchOut.masterStretchOut]);

  return (
    <Dialog
      keepMounted
      scroll={"paper"}
      maxWidth="md"
      fullWidth={true}
      fullScreen={fullScreen}
      PaperProps={{
        style: {
          background: theme.palette.secondary.main,
        },
      }}
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>Edit</DialogTitle>
      <DialogContent sx={{ gap: "1rem" }}>
        <TextField
          sx={{ marginTop: "8px" }}
          variant="outlined"
          label="Name"
          name="Name"
          size="large"
          fullWidth
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
        />
        {isMasterCatalogItem && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              marginTop: "16px",
            }}
          >
            <TextField
              variant="outlined"
              label="Hits"
              name="Hits"
              size="large"
              fullWidth
              value={masterHits}
              onChange={(e) => {
                setMasterHits(parseFloat(e.target.value));
              }}
              sx={{ marginRight: "16px" }}
            />
            <TextField
              variant="outlined"
              label="Stretchout"
              name="Stretchout"
              size="large"
              fullWidth
              type="number"
              value={masterStretchOut}
              onFocus={(e) => e.target.select()}
              onWheel={(event) => {
                event.preventDefault();
              }}
              onChange={(e) => {
                setMasterStretchOut(parseFloat(e.target.value));
              }}
            />
          </Box>
        )}

        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{ marginTop: "12px" }}
        >
          Image
        </Typography>

        {savedImage && (
          <div>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              {savedImage && (
                <div style={{ position: "relative" }}>
                  <S3PresignedImage s3Key={savedImage} />
                  <div
                    onClick={() => {
                      setSavedImage(null);
                    }}
                  >
                    <Close
                      color="error"
                      style={{ position: "absolute", top: 8, right: 8 }}
                    />
                  </div>
                </div>
              )}
            </Box>
          </div>
        )}
        {!savedImage && (
          <Box sx={{ maxWidth: "400px" }}>
            {imageUrl ? (
              <ImageCropper
                image={imageUrl}
                onSave={(fileBlob, url) => {
                  setImageUrl(null);
                  setImageBlob(null);
                  setImageBlobCropped(fileBlob);
                  setImageUrlCropped(url);
                }}
                onClear={() => {
                  setImageUrl(null);
                  setImageBlob(null);
                }}
              />
            ) : (
              <div>
                <div>
                  {imageUrlCropped && (
                    <div style={{ position: "relative" }}>
                      <img
                        src={imageUrlCropped}
                        style={{
                          marginTop: 16,
                          // maxWidth: "100%",
                          // maxHeight: 200,
                          width: "100%",
                        }}
                      />
                      <div
                        onClick={() => {
                          setImageUrlCropped(null);
                          setImageBlobCropped(null);
                        }}
                      >
                        <Close
                          style={{ position: "absolute", top: 8, right: 8 }}
                        />
                      </div>
                    </div>
                  )}
                </div>
                {imageBlob === null &&
                  imageUrl === null &&
                  imageBlobCropped === null &&
                  imageUrlCropped === null && (
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <label>
                        <input
                          style={{ display: "none" }}
                          type="file"
                          accept="image/*"
                          // capture="environment"
                          onChange={(event) => {
                            console.log(event.target.files[0]);
                            console.log(
                              "Image",
                              URL.createObjectURL(event.target.files[0])
                            );
                            setImageBlob(event.target.files[0]);
                            setImageUrl(
                              URL.createObjectURL(event.target.files[0])
                            );
                            setImageBlobCropped(null);
                            setImageUrlCropped(null);
                          }}
                        />
                        <div
                          style={{
                            cursor: "pointer",
                            width: "100%",
                            padding: 12,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              borderStyle: "solid",
                              borderWidth: 1,
                              borderColor: "#d2d2d2",
                              color: "#d2d2d2",
                              width: 100,
                              height: 100,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Upload sx={{ fontSize: 40 }} />
                          </div>
                        </div>
                      </label>
                      <label>
                        <input
                          style={{ display: "none" }}
                          type="file"
                          accept="image/*"
                          capture="environment"
                          onChange={(event) => {
                            console.log(event.target.files[0]);
                            console.log(
                              "Image",
                              URL.createObjectURL(event.target.files[0])
                            );
                            setImageBlob(event.target.files[0]);
                            setImageUrl(
                              URL.createObjectURL(event.target.files[0])
                            );
                            setImageBlobCropped(null);
                            setImageUrlCropped(null);
                          }}
                        />
                        <div
                          style={{
                            cursor: "pointer",
                            width: "100%",
                            padding: 12,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              borderStyle: "solid",
                              borderWidth: 1,
                              borderColor: "#d2d2d2",
                              color: "#d2d2d2",
                              width: 100,
                              height: 100,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <CameraAlt sx={{ fontSize: 40 }} />
                          </div>
                        </div>
                      </label>
                    </div>
                  )}
              </div>
            )}
          </Box>
        )}

        {enableSaveToCatalog && (
          <Box>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                magin: 0,
                padding: 0,
              }}
            >
              <Checkbox
                style={{
                  magin: 0,
                  padding: 0,
                }}
                checked={saveToCatalog}
                onChange={(e) => {
                  setSaveToCatalog(e.target.checked);
                }}
              />
              <Typography>
                Save To{catalogCompanyName ? ` ${catalogCompanyName}` : ""}{" "}
                Stock Catalog
              </Typography>
            </div>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Box style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            disabled={
              (imageBlobCropped === null && savedImage === null) ||
              name.length < 1
            }
            variant="outlined"
            onClick={async () => {
              await onSubmit(
                name,
                imageBlobCropped,
                saveToCatalog,
                masterHits,
                masterStretchOut,
                savedImage
              );
              // resetData();
              setOpen(false);
            }}
          >
            Submit
          </Button>
          <Button onClick={handleClose}>Cancel</Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default EditTrimDialog;
