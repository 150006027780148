import {
  Box,
  Button,
  Card,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ListItem,
  ListItemText,
  TextareaAutosize,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Edit } from "@mui/icons-material";
import { AuthContext } from "../../../context/AuthContext";
import { useTheme } from "@emotion/react";
import { LoadingButton } from "@mui/lab";
import useShopEmployees from "../../../hooks/useShopEmployees";

const ShopOrderDetailsDialog = ({
  order,
  open,
  setOpen,
  onSave,
  isLoading,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const { authToken, ezorder, decoded } = useContext(AuthContext);
  const [isEditingSelection, setIsEditingSelection] = useState(false);
  const [selected, setSelected] = useState(
    order?.assignedShopOrderLead ? order.assignedShopOrderLead : null
  );
  const { shopEmployees } = useShopEmployees(authToken);
  const [orderFabNotes, setOrderFabNotes] = useState(
    order?.orderFabNotes ? order.orderFabNotes : ""
  );

  const [shopPriority, setShopPriority] = useState(
    order?.shopPriority ? order.shopPriority : 0
  );

  return (
    <Dialog
      scroll={"paper"}
      maxWidth="sm"
      fullWidth={true}
      fullScreen={isSmallScreen}
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <DialogTitle>Set Details for Job #{order?.id}</DialogTitle>
      <DialogContent sx={{ display: "flex", flexDirection: "column" }}>
        <DialogContentText>Order / Fab Notes (for Shop Only)</DialogContentText>
        <TextareaAutosize
          placeholder="Order / Fab Notes"
          style={{
            marginTop: "8px",
            background: theme.palette.secondary.main,
            color: "#fff",
            width: "100%",
          }}
          fullWidth
          minRows={5}
          value={orderFabNotes}
          onChange={(e) => {
            setOrderFabNotes(e.target.value);
          }}
        />
        <DialogContentText sx={{ marginTop: "8px" }}>
          Assign a leader for this order
        </DialogContentText>
        {selected && !isEditingSelection ? (
          <Card
            style={{
              paddingLeft: "16px",
              paddingRight: "16px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <ListItem>
                <ListItemText
                  primary={selected.fullName}
                  secondary={
                    <>
                      <Typography
                        style={{
                          fontSize: 12,
                        }}
                      >
                        Email: {selected.email}
                      </Typography>
                    </>
                  }
                />
              </ListItem>
              <Edit
                onClick={() => {
                  setIsEditingSelection(true);
                }}
              />
            </Box>
          </Card>
        ) : (
          <Card style={{ flex: 1, overflowY: "auto" }}>
            {shopEmployees.map((employee, index) => (
              <ListItem
                onClick={() => {
                  setSelected(employee);
                  setIsEditingSelection(false);
                }}
                sx={{
                  cursor: "pointer",
                  "&:hover": {
                    background: "#5A5A5A",
                  },
                }}
              >
                <ListItemText
                  primary={employee.fullName}
                  secondary={
                    <>
                      <Typography
                        style={{
                          fontSize: 12,
                        }}
                      >
                        Email: {employee.email}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: 12,
                        }}
                      >
                        Phone: {employee.phone}
                      </Typography>
                    </>
                  }
                />
              </ListItem>
            ))}
          </Card>
        )}
        <DialogContentText sx={{ marginTop: "8px" }}>
          Priority:{" "}
        </DialogContentText>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            magin: 0,
            padding: 0,
          }}
        >
          <Checkbox
            style={{
              magin: 0,
              padding: 0,
            }}
            checked={shopPriority == 1}
            onChange={(e) => {
              if (e.target.checked == true) {
                setShopPriority(1);
              } else {
                setShopPriority(0);
              }
            }}
          />
          <Typography>HOT JOB</Typography>
        </div>
      </DialogContent>

      <DialogActions>
        <LoadingButton
          loading={isLoading}
          onClick={() => {
            setOpen(false);
          }}
        >
          Cancel
        </LoadingButton>
        <LoadingButton
          disabled={!selected || isEditingSelection}
          loading={isLoading}
          variant="contained"
          color="primary"
          onClick={async () => {
            // TODO:
            await onSave(order.id, selected.id, orderFabNotes, shopPriority);
            setOpen(false);
          }}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ShopOrderDetailsDialog;
