import { useTheme } from "@emotion/react";
import { AddAPhoto, Close, FileUpload } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Card,
  Typography,
  Box,
  Button,
  Dialog,
  useMediaQuery,
  TextareaAutosize,
  FormGroup,
  FormLabel,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import WorkOrderButton from "../../../components/PdfButtons/WorkOrderButton";
import PackingListButton from "../../../components/PdfButtons/PackingListButton";
import { useNavigate } from "react-router-dom";
import moment from "moment/moment";
import ShopOrderDetailsDialog from "./ShopOrderDetailsDialog";
import FabricatorNotes from "./FabricatorNotes";
import useShopNotes from "../../../hooks/useShopNotes";
import { AuthContext } from "../../../context/AuthContext";

const ShopOrderCard = ({
  isAdmin,
  order,
  clickHandler,
  onShopPriorityChange,
  onMoveToOnToday,
  onChangeShopStatus,
  onChangePickUpStatus,
  isLoading,
  onChangeShopProgress,
  onSetShopJobDetails,
}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [productMap, setProductMap] = useState(new Map());
  const [sheared, setSheared] = useState(null);
  const [openStartJobDialog, setOpenStartJobDialog] = useState(false);
  const [fabricatorNote, setFabricatorNote] = useState("");

  const { authToken, ezorder } = useContext(AuthContext);
  // const { shopNotes, addShopNote, isLoadingNotes } = useShopNotes(
  //   authToken,
  //   order.id,
  //   true
  // );
  const [shopNotes, setShopNotes] = useState([]);
  const [isLoadingNotes, setIsLoadingNotes] = useState(false);

  const getShopNotes = async (controller) => {
    try {
      setIsLoadingNotes(true);
      let data = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };

      if (controller) {
        data.signal = controller.signal;
      }

      // Internal notes
      let url = `/shop/orders/${order.id}/shop-notes`;

      const response = await ezorder.get(url, data);
      setShopNotes(response.data.shopNotes);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingNotes(false);
    }
  };

  const addShopNote = async (fabricatorNote, isInternal) => {
    try {
      setIsLoadingNotes(true);

      let requestBody = {
        shopNotes: fabricatorNote,
        isInternal,
      };

      const response = await ezorder.post(
        `/shop/orders/${order.id}/shop-notes`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      getShopNotes();
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingNotes(false);
    }
  };

  const parseOrder = (orderDetails) => {
    const prodMap = new Map();
    let countToShear = 0;
    let countSheared = 0;
    for (let i = 0; i < orderDetails.items.length; i++) {
      let orderItem = orderDetails.items[i];
      // prodMap.set(`${orderItem.objectType}`, orderItem);
      let pieces = [];
      switch (orderItem.objectType) {
        case "TrimAndFlashing":
          pieces = orderItem.trimPieces;
          break;
        case "CopingCapCleat":
          pieces = orderItem.cleatPieces;
          break;
        case "BenchWork":
          pieces = orderItem.benchWorkPieces;
          break;
        case "CopingCap":
          pieces = orderItem.capPieces;
          break;
        case "SplicePlate":
          pieces = orderItem.plates;
          break;
      }
      countToShear += pieces.length;
      for (const piece of pieces) {
        if (piece.isSheared) {
          countSheared++;
        }
      }
    }
    setSheared({
      toShear: countToShear,
      sheared: countSheared,
    });
    // setProductMap(prodMap);
    // console.log("PRODUCT MAP", prodMap);
  };

  useEffect(() => {
    if (order) parseOrder(order);
  }, [order]);

  useEffect(() => {
    const controller = new AbortController();
    if (order) getShopNotes();

    return () => {
      controller.abort(); // abort api call on unmount for cleanup
    };
  }, [order]);

  return (
    <Card
      style={{
        padding: 0,
        marginTop: 8,
        background: theme.palette.secondary.main,
        borderStyle: "solid",
        borderWidth: 1,
        borderColor:
          order.shopStatus === "100% Complete"
            ? "green"
            : order.shopPriority > 0
            ? "red"
            : order.shopStatus === "Pending" || order.shopStatus === "Paused"
            ? "#6b6b6b"
            : order.shopStatus === "In-Progress" ||
              order.shopStatus === "Partially Complete"
            ? "#FFA011"
            : "#fff",
      }}
    >
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/* SHOP STATUS */}
        <Box
          style={{
            backgroundColor:
              order.shopStatus === "100% Complete"
                ? "green"
                : order.shopPriority > 0
                ? "red"
                : order.shopStatus === "Pending" ||
                  order.shopStatus === "Paused"
                ? "#6b6b6b"
                : order.shopStatus === "In-Progress" ||
                  order.shopStatus === "Partially Complete"
                ? "#FFA011"
                : "#fff",
            color:
              order.shopStatus === "100% Complete"
                ? "#fff"
                : order.shopPriority > 0
                ? "#fff"
                : order.shopStatus === "Pending" ||
                  order.shopStatus === "Paused"
                ? "#fff"
                : order.shopStatus === "In-Progress" ||
                  order.shopStatus === "Partially Complete"
                ? "black"
                : "#fff",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            style={{
              height: "100%",
              textAlign: "center",
              fontSize: "20px",
              fontWeight: "bold",
            }}
          >
            {order.shopStatus}
          </Typography>
        </Box>

        {/* Details */}
        <Box style={{ flex: 1, padding: 16 }}>
          <Typography
            sx={{ fontWeight: "bold", overflow: "hidden" }}
            onClick={clickHandler}
          >
            Order #{order.id}
          </Typography>
          <Typography
            sx={{
              display: "-webkit-box",
              overflow: "hidden",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 1,
            }}
            onClick={clickHandler}
            color="#c0c0c0"
          >
            Customer: {order.company.name}
          </Typography>
          {order.project && (
            <Typography
              variant="body1"
              color="#c0c0c0"
              onClick={clickHandler}
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: "1",
                WebkitBoxOrient: "vertical",
              }}
            >
              {order.company.isVendorOrSupplier
                ? "Vendor's Customer"
                : "Project"}
              : {order.project.name}
            </Typography>
          )}
          {order.vendorProjectName && (
            <Typography
              variant="body1"
              color="#c0c0c0"
              onClick={clickHandler}
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: "1",
                WebkitBoxOrient: "vertical",
              }}
            >
              Project: {order.vendorProjectName}
            </Typography>
          )}
          <Typography
            variant="body1"
            color="#c0c0c0"
            onClick={clickHandler}
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: "1",
              WebkitBoxOrient: "vertical",
            }}
          >
            PO#: {order.poNumber}
          </Typography>
          <Typography
            sx={{
              display: "-webkit-box",
              overflow: "hidden",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 1,
            }}
            color="#c0c0c0"
            onClick={clickHandler}
          >
            Color: {order.isCustomMaterial ? order.customColor : order.color} -{" "}
            {order.isCustomMaterial
              ? order.customManufacturer
              : order.manufacturer}
          </Typography>
          <Typography
            sx={{
              display: "-webkit-box",
              overflow: "hidden",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 1,
            }}
            color="#c0c0c0"
            onClick={clickHandler}
          >
            Order Approved On:{" "}
            {order.estimateApprovalDate
              ? moment(order.estimateApprovalDate).format("MM/DD/YY")
              : ""}
          </Typography>

          <Typography
            sx={{
              display: "-webkit-box",
              overflow: "hidden",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 1,
            }}
            color="#c0c0c0"
            onClick={clickHandler}
          >
            Due Date:{" "}
            {order.shopEstimateCompletionDate
              ? moment(order.shopEstimateCompletionDate).format("MM/DD/YY")
              : ""}
          </Typography>
          <Box>
            <WorkOrderButton orderId={order.id} />
            <PackingListButton orderId={order.id} />
          </Box>
          {(order.shopStatus == "In-Progress" ||
            order.shopStatus == "Partially Complete") && (
            <Box
              sx={{
                marginTop: "8px",
                paddingTop: "8px",
              }}
            >
              <FormGroup sx={{}}>
                <FormLabel
                  sx={{
                    fontWeight: "bold",
                    overflow: "hidden",
                    color: "#fff",
                  }}
                >
                  Shop Progress:
                </FormLabel>
                <FormControlLabel
                  // disabled={loading}
                  control={
                    <Checkbox
                      disabled={isLoading}
                      checked={order.shopProgressAreFlatsSheared}
                      onChange={(e) => {
                        onChangeShopProgress(order, {
                          shopProgressAreFlatsSheared: e.target.checked,
                        });
                      }}
                    />
                  }
                  label={"Flats Sheared"}
                />
                <FormControlLabel
                  // disabled={loading}
                  control={
                    <Checkbox
                      disabled={isLoading}
                      checked={order.shopProgressIsInFabrication}
                      onChange={(e) => {
                        onChangeShopProgress(order, {
                          shopProgressIsInFabrication: e.target.checked,
                        });
                      }}
                    />
                  }
                  label={"In Fabrication"}
                />
                <FormControlLabel
                  // disabled={loading}
                  control={
                    <Checkbox
                      disabled={isLoading}
                      checked={order.shopProgressArePanelsBeingRan}
                      onChange={(e) => {
                        onChangeShopProgress(order, {
                          shopProgressArePanelsBeingRan: e.target.checked,
                        });
                      }}
                    />
                  }
                  label={"Panels Being Run"}
                />
              </FormGroup>
            </Box>
          )}
          {order.assignedShopOrderLead && (
            <Typography
              sx={{ fontWeight: "bold", overflow: "hidden", marginTop: "8px" }}
              onClick={clickHandler}
            >
              Order Lead: {order.assignedShopOrderLead.fullName}
            </Typography>
          )}
          {order.orderFabNotes && (
            <TextareaAutosize
              disabled
              placeholder="Job Notes"
              style={{
                marginTop: "8px",
                background: theme.palette.secondary.main,
                color: "#fff",
                width: "100%",
              }}
              fullWidth
              minRows={3}
              value={order.orderFabNotes}
            />
          )}
        </Box>

        {/* Fabricator Notes */}
        <Box style={{ flex: 1, paddingLeft: 16, paddingRight: 16 }}>
          <Typography
            sx={{ fontWeight: "bold", overflow: "hidden" }}
            onClick={clickHandler}
          >
            Fabricator Progress Notes (Shop Only):
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <TextareaAutosize
              placeholder="Describe your end of day progress. This is not visible to the customer"
              style={{
                marginTop: "8px",
                background: theme.palette.secondary.main,
                color: "#fff",
                flex: 1,
              }}
              fullWidth
              minRows={3}
              value={fabricatorNote}
              onChange={(e) => {
                setFabricatorNote(e.target.value);
              }}
            />
            <LoadingButton
              loading={isLoading || isLoadingNotes}
              onClick={async () => {
                await addShopNote(fabricatorNote, true);
                setFabricatorNote("");
              }}
            >
              Add
            </LoadingButton>
          </Box>

          <FabricatorNotes shopNotes={shopNotes} />
        </Box>

        {/* Buttons */}
        <Box
          sx={{
            padding: "16px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          <ShopOrderDetailsDialog
            order={order}
            open={openStartJobDialog}
            setOpen={setOpenStartJobDialog}
            onSave={onSetShopJobDetails}
            isLoading={isLoading}
          />
          {order.shopStatus === "Pending" || order.shopStatus === "Paused" ? (
            <Box
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <LoadingButton
                loading={isLoading}
                variant="outlined"
                onClick={() => {
                  setOpenStartJobDialog(true);
                }}
              >
                Set Job Details
              </LoadingButton>
              <LoadingButton
                sx={{ marginLeft: "8px" }}
                loading={isLoading}
                variant="outlined"
                onClick={() => {
                  onChangeShopStatus(order, "In-Progress");
                }}
              >
                Start
              </LoadingButton>
            </Box>
          ) : order.shopStatus === "In-Progress" ? (
            <Box
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <LoadingButton
                loading={isLoading}
                variant="outlined"
                onClick={() => {
                  setOpenStartJobDialog(true);
                }}
              >
                Set Job Details
              </LoadingButton>
              <LoadingButton
                sx={{ marginLeft: "8px" }}
                loading={isLoading}
                variant="outlined"
                onClick={() => {
                  onChangeShopStatus(order, "Paused");
                }}
              >
                Paused
              </LoadingButton>
              <LoadingButton
                sx={{ marginLeft: "8px" }}
                loading={isLoading}
                variant="outlined"
                onClick={() => {
                  onChangeShopStatus(order, "100% Complete");
                }}
              >
                100% Complete
              </LoadingButton>
            </Box>
          ) : order.shopStatus === "Partially Complete" ? (
            <Box
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <LoadingButton
                loading={isLoading}
                variant="outlined"
                onClick={() => {
                  setOpenStartJobDialog(true);
                }}
              >
                Set Job Details
              </LoadingButton>
              <LoadingButton
                sx={{ marginLeft: "8px" }}
                loading={isLoading}
                variant="outlined"
                onClick={() => {
                  onChangeShopStatus(order, "Paused");
                }}
              >
                Paused
              </LoadingButton>
              <LoadingButton
                sx={{ marginLeft: "8px" }}
                loading={isLoading}
                variant="outlined"
                onClick={() => {
                  onChangeShopStatus(order, "100% Complete");
                }}
              >
                100% Complete
              </LoadingButton>
            </Box>
          ) : order.shopStatus === "100% Complete" ? (
            <Box
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <LoadingButton
                loading={isLoading}
                variant="outlined"
                onClick={() => {
                  onChangePickUpStatus(order);
                }}
              >
                Update Pick Up / Delivered Status
              </LoadingButton>
            </Box>
          ) : null}
        </Box>
      </Box>
    </Card>
  );
};
export default ShopOrderCard;
