const SECTION_MARGIN = 12;
const FULL_WIDTH = 515;
const COLUMN_GAP = 20;
const HALF_COLUMN_WITH_GAP = (FULL_WIDTH - COLUMN_GAP) / 2;

const HEADER = () => {
  return [
    {
      columns: [
        {
          image: "logo",
          width: 120,
        },
        {
          text: "Weekly Time Summary",
          style: "header",
        },
      ],
      margin: [0, 0, 0, 8], // margin: [left, top, right, bottom]
    },
  ];
};

const LINE_TOP = () => {
  return [
    {
      canvas: [{ type: "line", x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 1 }],
    },
  ];
};

const LINE_BELOW = () => {
  return [
    {
      canvas: [{ type: "line", x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 1 }],
      margin: [0, 4, 0, 0], // margin: [left, top, right, bottom]
    },
  ];
};

const formatDate = (date) => {
  const yyyy = date.getFullYear();
  let mm = date.getMonth() + 1; // Months start at 0!
  let dd = date.getDate();

  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;

  const formattedDate = mm + "/" + dd + "/" + yyyy;
  return formattedDate;
};

const SUMMARY = (employeeSummaries) => {
  let summaryRows = [];
  for (const summary of employeeSummaries) {
    summaryRows.push([summary.name, summary.totalHours]);
  }

  return [
    {
      margin: [0, 8, 0, 0], // margin: [left, top, right, bottom]
      layout: "lightHorizontalLines", // optional
      table: {
        // headers are automatically repeated if the table spans over multiple pages
        // you can declare how many rows should be treated as headers
        headerRows: 1,
        widths: ["*", "*"],

        body: [["Employee", "Total Hours"], ...summaryRows],
      },
    },
  ];
};

const getWeeklyTimeCardSummaryDocDefinition = async (
  weekOf,
  employeeSummaries
) => {
  let content = [
    ...HEADER(),
    {
      text: weekOf,
      fontSize: 18,
      bold: true,
      alignment: "center",
      margin: [0, 8, 0, 0],
    },
    ...SUMMARY(employeeSummaries),
  ];

  var docDefinition = {
    pageSize: "A4",
    content: content,

    footer: function (currentPage, pageCount) {
      return {
        margin: [40, 10, 40, 0], // margin: [left, top, right, bottom]
        columns: [
          {
            text: `Created on ${new Date().toDateString()}`,
            alignment: "left",
            style: "footerText",
          },
          {
            text: currentPage.toString() + " of " + pageCount,
            alignment: "center",
            style: "footerText",
          },
          // {
          //   text: "EZ-ORDER Ver.1.0",
          //   alignment: "right",
          //   style: "footerText",
          // },
        ],
      };
    },

    styles: {
      header: {
        fontSize: 24,
        bold: true,
        alignment: "right",
      },
      subheader: {
        bold: true,
        fontSize: 10,
      },
      imageText: {
        fontSize: 10,
        alignment: "center",
      },
      imageTextBold: {
        bold: true,
        fontSize: 10,
        alignment: "center",
      },
      detailName: {
        bold: true,
        fontSize: 10,
      },
      detailValue: {
        fontSize: 8,
      },
      tableHeader: {
        bold: true,
        fontSize: 10,
        alignment: "center",
      },
      tableCell: {
        fontSize: 8,
        alignment: "center",
      },
      tableTotal: {
        fontSize: 8,
        bold: true,
        alignment: "center",
      },
      regularText: {
        fontSize: 10,
      },
      materialHeader: {
        fillColor: "#D3D3D3",
        bold: true,
        fontSize: 10,
      },
      checkbox: {},
      footerText: {
        fontSize: 8,
      },
    },
    defaultStyle: {
      fontSize: 12,
    },
    images: {
      // logo: "https://ezorder-public.s3.us-east-2.amazonaws.com/logo_black_horizontal.png",
      logo: "https://ezorder-public.s3.us-east-2.amazonaws.com/industry-metal-co-long-logo.png",
    },
  };

  return docDefinition;
};

export default getWeeklyTimeCardSummaryDocDefinition;
