export function getBlankTrim(trimType) {
  return function () {
    switch (trimType) {
      case "TrimAndFlashing":
        return {
          quantity: "",
          hits: "",
          stretchOut: "",
          trim: { name: "", image: "" },
        };
      case "CopingCap":
        return {
          quantity: "",
          hits: "",
          stretchOut: "",
          cap: {
            name: "",
            image: "",
          },
        };
      case "CopingCapCleat":
        return {
          quantity: "",
          hits: "",
          stretchOut: "",
          cleat: {
            name: "",
            image: "",
          },
        };
      case "SplicePlate":
        return {
          quantity: "",
          stretchOut: "",
          hits: "",
          length: "",
        };
      case "BenchWork":
        return {
          quantity: 0,
          hardCost: 0,
          notes: "",
          flatSheets: 0,
          benchPiece: {
            name: "",
            image: "",
          },
        };
      default:
        break;
    }
  };
}

export function getTrimPieces(trimType) {
  return function (trim) {
    switch (trimType) {
      case "TrimAndFlashing":
        return trim.trimPieces;
      case "CopingCap":
        return trim.capPieces;
      case "CopingCapCleat":
        return trim.cleatPieces;
      case "SplicePlate":
        return trim.plates;
      case "BenchWork":
        return trim.benchWorkPieces;
      case "Accessories":
        return trim.items;
      case "Panel":
        return trim.cuts;
      default:
        break;
    }
  };
}

export function updateTrim(trimType) {
  return function (trimObject, key, value) {
    const pathForNameAndImage =
      trimType === "TrimAndFlashing"
        ? "trim"
        : trimType === "CopingCap"
        ? "cap"
        : trimType === "CopingCapCleat"
        ? "cleat"
        : "benchPiece";
    switch (key) {
      case "name":
      case "image":
        return {
          ...trimObject,
          [pathForNameAndImage]: {
            ...trimObject[pathForNameAndImage],
            [key]: value,
          },
        };
      case "stretchOut":
      case "hits":
      case "quantity":
      case "length":
      case "notes":
      case "flatSheets":
      case "hardCost":
        return { ...trimObject, [key]: value };
      default:
        break;
    }
  };
}

export function getProperty(trimType) {
  return function (object, key) {
    const pathForNameAndImage =
      trimType === "TrimAndFlashing"
        ? "trim"
        : trimType === "CopingCap"
        ? "cap"
        : trimType === "CopingCapCleat"
        ? "cleat"
        : "benchPiece";
    switch (key) {
      case "name":
      case "image":
      // if (trimType !== "SplicePlate") {
      //   return object[pathForNameAndImage][key];
      // }
      case "stretchOut":
      case "hits":
      case "quantity":
      case "length":
      case "notes":
      case "flatSheets":
      case "hardCost":
        return object[key];
      default:
        break;
    }
  };
}

export function trimOptions(trimType) {
  const options = [
    {
      objectType: "TrimAndFlashing",
      options: {
        inlineEditActionType: "UPDATE TRIM: PIECE DETAILS",
        updateActionType: "UPDATE TRIM: ADD TRIM",
        removeActionType: "UPDATE TRIM: REMOVE TRIM",
        overrideActionType: "UPDATE TRIM: OVERRIDE CALCULATION",
        resetActionType: "UPDATE TRIM: RESET OVERRIDE",
        runCalcsActionType: "UPDATE TRIM: RUN CALCULATIONS",
        imageResourceType: "trims",
        title: "Trim & Flashing",
      },
    },
    {
      objectType: "CopingCap",
      options: {
        inlineEditActionType: "UPDATE COPING CAP: PIECE DETAILS",
        updateActionType: "UPDATE COPING CAP: ADD COPING CAP",
        removeActionType: "UPDATE COPING CAP: REMOVE COPING CAP",
        overrideActionType: "UPDATE COPING CAP: OVERRIDE CALCULATION",
        resetActionType: "UPDATE COPING CAP: RESET OVERRIDE",
        runCalcsActionType: "UPDATE COPING CAP: RUN CALCULATIONS",
        imageResourceType: "coping-caps",
        title: "Coping Cap",
      },
    },
    {
      objectType: "CopingCapCleat",
      options: {
        inlineEditActionType: "UPDATE CLEAT: PIECE DETAILS",
        updateActionType: "UPDATE CLEAT: ADD CLEAT",
        updateGauge: "UPDATE CLEAT: GAUGE",
        removeActionType: "UPDATE CLEAT: REMOVE CLEAT",
        overrideActionType: "UPDATE CLEAT: OVERRIDE CALCULATION",
        resetActionType: "UPDATE CLEAT: RESET OVERRIDE",
        runCalcsActionType: "UPDATE CLEAT: RUN CALCULATIONS",
        imageResourceType: "cleats",
        title: "Coping Cap Cleat",
      },
    },
    {
      objectType: "SplicePlate",
      options: {
        inlineEditActionType: "UPDATE SPLICE PLATE: PLATE DETAILS",
        updateActionType: "UPDATE SPLICE PLATE: ADD SPLICE PLATE",
        removeActionType: "UPDATE SPLICE PLATE: REMOVE SPLICE PLATE",
        overrideActionType: "UPDATE SPLICE PLATE: OVERRIDE CALCULATION",
        resetActionType: "UPDATE SPLICE PLATE: RESET OVERRIDE",
        runCalcsActionType: "UPDATE SPLICE PLATE: RUN CALCULATIONS",
        title: "Splice Plate",
      },
    },
    {
      objectType: "BenchWork",
      options: {
        inlineEditActionType: "UPDATE BENCH: PIECE DETAILS",
        updateActionType: "UPDATE BENCH: ADD BENCH",
        removeActionType: "UPDATE BENCH: REMOVE BENCH",
        overrideActionType: "UPDATE BENCH: OVERRIDE CALCULATION",
        resetActionType: "UPDATE BENCH: RESET OVERRIDE",
        runCalcsActionType: "UPDATE BENCH: RUN CALCULATIONS",
        imageResourceType: "bench-work",
        title: "Bench Work",
      },
    },
  ];
  return options.find((opt) => opt?.objectType === trimType)?.options;
}

export function functionsBasedOnTrimType(trimType) {
  return {
    getBlankTrim: getBlankTrim(trimType),
    getTrimPieces: getTrimPieces(trimType),
    updateTrim: updateTrim(trimType),
    getProperty: getProperty(trimType),
    trimOptions: trimOptions(trimType),
  };
}

export const findProperty = (obj) => (propName) => {
  if (typeof obj !== "object" || obj === null) {
    return undefined;
  }

  if (propName in obj) {
    return obj[propName];
  }

  for (let key in obj) {
    if (typeof obj[key] === "object") {
      const result = findProperty(obj[key])(propName);
      if (result !== undefined) {
        return result;
      }
    }
  }

  return undefined;
};
