import { useState, useEffect, useContext } from "react";
import { Box, Card, CardMedia, Typography } from "@mui/material";
import { AuthContext } from "../context/AuthContext";

const ShopS3PresignedImage = (props) => {
  // Image is really just he file's Key on s3. I'm not renaming it now because we use "image" as the trim property on the backend
  const { s3Key } = props;
  const [presignedUrl, setPresignedUrl] = useState(null);
  const { authToken, ezorder } = useContext(AuthContext);

  const createReadPresignedUrl = async (controller) => {
    try {
      //   let keySplit = s3Key.split("/");
      //   let resource = keySplit[1];
      //   let fileName = keySplit[2];
      let keySplit = s3Key.split("/");
      let identifier = keySplit[0];
      let resource = keySplit[1];
      let fileName = keySplit[2];
      // console.log("Create SHOP read presigned URL");
      // console.log("identifier", identifier);
      // console.log("resource", resource);
      // console.log("fileName", fileName);

      let data = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      if (controller) {
        data.signal = controller.signal;
      }
      // console.log("Create Presigned URL");
      // const response = await ezorder.get("/shop/presignedUrl", data);
      let requestData = {
        actionType: "READ",
        identifier,
        fileName,
        //contentType,
        resource,
      };
      const response = await ezorder.post(
        "/files/presignedUrl",
        requestData,
        data
      );
      setPresignedUrl(response.data.getUrl);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    createReadPresignedUrl(controller);
    return () => {
      controller.abort(); // abort api call on unmount for cleanup
    };
  }, [s3Key]);

  if (!presignedUrl) {
    return (
      <Box sx={props.style}>
        <Typography>No Image</Typography>
      </Box>
    );
  }
  return <img src={presignedUrl} style={props.style} />;
};

export default ShopS3PresignedImage;
