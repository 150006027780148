import { Style } from "@mui/icons-material";
import {
  Box,
  Card,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useEffect, useState } from "react";
import CollapsableCard from "../../../../CollapseableCard";
import useQuickBooksCustomer from "../../../useQuickBooksCustomer";
import { useTheme } from "@emotion/react";
import useQuickBooksInvoices from "../../../../../hooks/useQuickBooksInvoices";
import SelectExistingInvoiceDialog from "./SelectExistingInvoiceDialog";

const ExistingInvoices = ({ orderId, updateOrder }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const { invoices, search, setSearch, category, setCategory } =
    useQuickBooksInvoices();
  const [selectedInvoice, setSelectedInvoice] = useState(null);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "1rem",
          alignItems: "center",
          justifyContent: "center",
          paddingTop: "16px",
        }}
      >
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Filter</InputLabel>
          <Select
            sx={{ minWidth: "175px" }}
            size="small"
            select
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          >
            <MenuItem value="DocNumber">DocNumber</MenuItem>
            <MenuItem value="Id">
              TxnId (Edit Invoice on QB and Look for TxnId on the URL)
            </MenuItem>
          </Select>
        </FormControl>
        <TextField
          label="Search..."
          size="small"
          fullWidth
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </Box>
      <TableContainer sx={{ maxHeight: 250 }} component={Paper}>
        <Table sx={{}}>
          <TableHead>
            <TableRow>
              <TableCell>No.</TableCell>
              <TableCell>Customer Ref</TableCell>
              <TableCell>Total</TableCell>
              <TableCell>Remaining Balance</TableCell>
              <TableCell>TxnId</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {invoices &&
              invoices.map((inv, index) => {
                return (
                  <TableRow
                    hover
                    key={index}
                    sx={{}}
                    onClick={() => {
                      setSelectedInvoice(inv);
                    }}
                  >
                    <TableCell>{inv.DocNumber}</TableCell>
                    <TableCell>{inv.CustomerRef.name}</TableCell>
                    <TableCell>${inv.TotalAmt}</TableCell>
                    <TableCell>${inv.Balance}</TableCell>
                    <TableCell>{inv.Id}</TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <SelectExistingInvoiceDialog
        open={selectedInvoice != null}
        handleClose={() => {
          setSelectedInvoice(null);
        }}
        invoice={selectedInvoice}
        onConfirm={() => {
          console.log("Use Invoice", selectedInvoice);
          updateOrder({
            id: orderId,
            invoiceId: selectedInvoice.Id, // This is the TxnId
            invoiceCustomerRefName: selectedInvoice.CustomerRef.name,
          });
        }}
      />
    </Box>
  );
};
export default ExistingInvoices;
