import { TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useDebounce } from "use-debounce";

const TextFieldDebounced = (props) => {
  const { debounceTime = 500, onChange } = props;
  const [value, setValue] = useState(props.value);
  const [valueDebounced] = useDebounce(value, debounceTime);

  useEffect(() => {
    let e = {
      target: {
        value: valueDebounced,
      },
    };
    if (onChange) onChange(e);
  }, [valueDebounced]);
  return (
    <TextField
      {...props}
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
      }}
    />
  );
};

export default TextFieldDebounced;
