import { useNavigate, useParams } from "react-router-dom";
import { useContext, useState } from "react";
import { AuthContext } from "../../../../context/AuthContext";
import SearchBar from "../../../../components/SearchBar";
import InfiniteScroller from "../../../../components/InfiniteScroller/InfiniteScroller";
import ListFooter from "../../../../components/InfiniteScroller/ListFooter";
import OrderCard from "../../../../components/OrderCard";
import useCompanyOrders from "../../../../hooks/useCompanyOrders";
import { useTheme } from "@emotion/react";
import { SnackAlertContext } from "../../../../context/SnackAlertContext";

const ActiveOrders = () => {
  const navigate = useNavigate();
  const { openSnackMessage } = useContext(SnackAlertContext);
  const { authToken, ezorder } = useContext(AuthContext);
  const theme = useTheme();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [
    searchQuery,
    setSearchQuery,
    results,
    onRefresh,
    refreshing,
    onLoadMore,
    loadingMore,
    total,
    hasMore,
  ] = useCompanyOrders(authToken, 50, "active");

  const copyOrder = async (orderId) => {
    try {
      setIsSubmitting(true);
      const response = await ezorder.post(
        `/orders/${orderId}/copy`,
        {},
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      openSnackMessage("success", "Order Copied");
      let copied = response.data.order;
      navigate(`/quotes/${copied.id}`);
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage("error", error.response.data.error);
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", error);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <SearchBar
        label="Search Current / Active Orders"
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
      />
      <InfiniteScroller
        items={results}
        renderItem={(item, index) => {
          return (
            <OrderCard
              key={item.id}
              order={item}
              clickHandler={() => navigate(`/orders/${item.id}`)}
              onCopy={() => {
                copyOrder(item.id);
              }}
              isCopying={isSubmitting}
            />
          );
        }}
        onRefresh={onRefresh}
        onEndReached={onLoadMore}
        loading={refreshing || loadingMore || isSubmitting}
        ListFooterComponent={() => {
          return <ListFooter hasMore={hasMore} onClick={onLoadMore} />;
        }}
      />
    </>
  );
};

export default ActiveOrders;
