import { useTheme } from "@emotion/react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { toThreeDecimals } from "../../../../components/NewDetail/calculations/utils";

const FlatSheet = (props) => {
  const {
    flatSheet,
    isEstimate,
    showTotal = false,
    showPricePerPiece = false,
    noCalcs = false,
  } = props;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  if (!flatSheet) return null;

  return (
    <Box {...props}>
      {showTotal && !noCalcs ? (
        <Typography variant="h6">
          Flat Sheet - $
          {Math.round(
            parseFloat(
              flatSheet &&
                flatSheet.flatSheetCalculations &&
                flatSheet.flatSheetCalculations.totalSellPrice
                ? flatSheet.flatSheetCalculations.totalSellPrice
                : 0.0
            )
          )}
        </Typography>
      ) : (
        <Typography variant="h6">Flat Sheet</Typography>
      )}
      <TableContainer component={Paper} sx={{ marginTop: "8px" }}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Width (in.)</TableCell>
              <TableCell align="right">Qty</TableCell>
              {isEstimate && !noCalcs && (
                <TableCell align="right">Price Per Flat Sheet</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {flatSheet.width}
              </TableCell>
              <TableCell align="right">{flatSheet.quantity}</TableCell>
              {isEstimate && !noCalcs && (
                <TableCell align="right">
                  ${flatSheet.flatSheetCalculations.pricePerFlatSheet}
                </TableCell>
              )}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default FlatSheet;
