import { useTheme } from "@emotion/react";
import { Add } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Modal,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useState } from "react";

const ColorOptionModal = ({ color, visible, setVisible, updateColor }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [newGauge, setNewGauge] = useState(0);
  const [newGaugeUoM, setNewGaugeUoM] = useState("ga.");
  const [coilWidth, setCoilWidth] = useState(0);
  const [price, setPrice] = useState(0);
  const [pricePerSqFt, setPricePerSqFt] = useState(0);

  const resetForm = () => {
    setNewGauge(0);
    setNewGaugeUoM("ga.");
    setCoilWidth(0);
    setPrice(0);
    setPricePerSqFt(0);
  };

  const onAddOption = () => {
    let colorUpdate = { ...color };

    // Check if Gauge Already Exists
    let existingGuage = false;
    for (let gauge of colorUpdate.gauges) {
      if (parseFloat(gauge.gauge) == parseFloat(newGauge)) {
        existingGuage = true;
        gauge.coilWidths.push({
          width: coilWidth,
          unitOfMeasure: "in.",
          price: price, // flat sheet price
          pricePerSqFt: pricePerSqFt, // coil square foot pricing
          flatAvailable: true,
          coilAvailable: true,
        });
      }
    }
    // If New Gauge does not match any, then add new gauge
    if (!existingGuage) {
      colorUpdate.gauges.push({
        gauge: newGauge,
        unitOfMeasure: newGaugeUoM,
        coilWidths: [
          {
            width: coilWidth,
            unitOfMeasure: "in.",
            price: price, // flat sheet price
            pricePerSqFt: pricePerSqFt, // coil square foot pricing
            flatAvailable: true,
            coilAvailable: true,
          },
        ],
      });
    }

    updateColor(colorUpdate);
    setVisible(false);
    resetForm();
  };

  return (
    <Dialog
      fullWidth={true}
      open={visible}
      onClose={() => {
        setVisible(false);
      }}
    >
      <DialogTitle>Add Option</DialogTitle>
      <DialogContent>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          {newGaugeUoM == "ga." ? (
            <TextField
              select
              onFocus={(e) => e.target.select()}
              size="small"
              type="number"
              onWheel={(event) => {
                event.preventDefault();
              }}
              fullWidth
              variant="outlined"
              label="Gauge"
              value={newGauge}
              onChange={(e) => setNewGauge(e.target.value)}
              sx={{ marginTop: 2 }}
            >
              <MenuItem value={28}>28</MenuItem>
              <MenuItem value={26}>26</MenuItem>
              <MenuItem value={24}>24</MenuItem>
              <MenuItem value={22}>22</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={18}>18</MenuItem>
              <MenuItem value={16}>16</MenuItem>
            </TextField>
          ) : (
            <TextField
              // disabled={!checked}
              onFocus={(e) => e.target.select()}
              size="small"
              type="number"
              onWheel={(event) => {
                event.preventDefault();
              }}
              fullWidth
              variant="outlined"
              label={newGaugeUoM == "in." ? "Thickness" : "Weight"}
              value={newGauge}
              onChange={(e) => setNewGauge(e.target.value)}
              sx={{ marginTop: 2 }}
            />
          )}

          <TextField
            select
            onFocus={(e) => e.target.select()}
            size="small"
            fullWidth
            variant="outlined"
            label="Gauge Unit of Measure (ga., in., etc.)"
            value={newGaugeUoM}
            onChange={(e) => {
              // If switching to ga. then set to 24
              if (e.target.value == "ga.") {
                setNewGauge(24);
              }
              setNewGaugeUoM(e.target.value);
            }}
            sx={{ marginTop: 2, marginLeft: 2 }}
          >
            <MenuItem value="ga.">ga.</MenuItem>
            <MenuItem value="in.">in.</MenuItem>
            <MenuItem value="oz.">oz.</MenuItem>
          </TextField>
        </Box>

        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <TextField
            // disabled={!checked}
            size="small"
            type="number"
            onWheel={(event) => {
              event.preventDefault();
            }}
            onFocus={(e) => e.target.select()}
            fullWidth
            variant="outlined"
            label="Coil Width (Inches)"
            value={coilWidth}
            onChange={(e) => setCoilWidth(e.target.value)}
            sx={{ marginTop: 2 }}
          />
          {/* <TextField
            // disabled={!checked}
            size="small"
            type="number"
            onWheel={(event) => {
              event.preventDefault();
            }}
            onFocus={(e) => e.target.select()}
            fullWidth
            variant="standard"
            label="Price Per Flat Sheet"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            sx={{ marginTop: 2, marginLeft: 2 }}
          />
          <TextField
            // disabled={!checked}
            size="small"
            type="number"
            onWheel={(event) => {
              event.preventDefault();
            }}
            onFocus={(e) => e.target.select()}
            fullWidth
            variant="standard"
            label="Price Per Sq Ft of Coil"
            value={pricePerSqFt}
            onChange={(e) => setPricePerSqFt(e.target.value)}
            sx={{ marginTop: 2, marginLeft: 2 }}
          /> */}
        </Box>
      </DialogContent>
      <DialogActions>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "center",
            marginTop: 2,
          }}
        >
          <Button
            onClick={() => {
              setVisible(false);
            }}
          >
            Cancel
          </Button>
          <Button
            sx={{ marginLeft: 1 }}
            variant="contained"
            onClick={onAddOption}
          >
            Add
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default ColorOptionModal;
