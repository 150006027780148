import { useTheme } from "@emotion/react";
import { Box, Card, Grid, Typography, useMediaQuery } from "@mui/material";
import moment from "moment/moment";
import React, { useEffect, useState } from "react";

const CustomCalendar = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [month, setMonth] = useState(null);
  const getMonth = (month = moment().month()) => {
    const year = moment().year();
    const firstDayOfTheMonth = moment(new Date(year, month, 1)).day();

    let currentMonthCount = 0 - firstDayOfTheMonth;

    const daysMatrix = new Array(5).fill([]).map(() => {
      return new Array(7).fill(null).map(() => {
        currentMonthCount++;
        return moment(
          new Date(year, month, currentMonthCount)
        ).toLocaleString();
      });
    });

    return daysMatrix;
  };
  useEffect(() => {
    let m = getMonth();
    console.log(m);
    setMonth(m);
  }, []);

  return (
    <Box>
      <h1>CustomCalendar</h1>
      {/* <Typography>month: {getMonth()}</Typography> */}
      <Card>
        <Grid container columns={7} sx={{ height: "40px" }}>
          <Grid
            item
            xs={1}
            sx={{ border: 1, padding: "8px", fontWeight: "bold" }}
          >
            Sun.
          </Grid>
          <Grid
            item
            xs={1}
            sx={{ border: 1, padding: "8px", fontWeight: "bold" }}
          >
            Mon.
          </Grid>
          <Grid
            item
            xs={1}
            sx={{ border: 1, padding: "8px", fontWeight: "bold" }}
          >
            Tue.
          </Grid>
          <Grid
            item
            xs={1}
            sx={{ border: 1, padding: "8px", fontWeight: "bold" }}
          >
            Wed.
          </Grid>
          <Grid
            item
            xs={1}
            sx={{ border: 1, padding: "8px", fontWeight: "bold" }}
          >
            Thu.
          </Grid>
          <Grid
            item
            xs={1}
            sx={{ border: 1, padding: "8px", fontWeight: "bold" }}
          >
            Fri.
          </Grid>
          <Grid
            item
            xs={1}
            sx={{ border: 1, padding: "8px", fontWeight: "bold" }}
          >
            Sat.
          </Grid>
        </Grid>
        {month &&
          month.map((row, i) => {
            return (
              <Grid key={i} container columns={7} sx={{ minHeight: "150px" }}>
                {row.map((day, index) => {
                  return (
                    <Grid
                      key={index}
                      item
                      xs={1}
                      sx={{ border: 1, padding: "8px" }}
                    >
                      <Box
                        sx={{
                          minWidth: "20px",
                          minHeight: "20px",
                          fontSize: isSmallScreen ? "8px" : "12px",
                        }}
                      >
                        {moment(day).format("MM/DD")}
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
            );
          })}
      </Card>
    </Box>
  );
};

export default CustomCalendar;
