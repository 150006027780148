import { Box, Typography } from "@mui/material";

const withError = (Component) => (props) => {
  const { error } = props;

  return (
    <>
      {error ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "13rem",
          }}
        >
          <Typography variant="overline" textAlign={"center"}>
            {error.message
              ? error.message
              : "Sorry, Something Is Wrong With Our Servers!"}
          </Typography>
        </Box>
      ) : (
        <Component {...props} />
      )}
    </>
  );
};
export default withError;
