import { findCalculationsObjects } from "./utils";

export const orderTotals = (orderItems = [], skidCharge = 0) => {
  const calculations = orderItems.flatMap((item) =>
    findCalculationsObjects(item)
  );

  let outTheDoorCost = calculations.reduce(
    (acc, item) => acc + parseFloat(item?.totalSellPrice ?? 0),
    0
  );
  console.log("ORDERTOTALS CALCULATIONS", calculations);
  console.log("ORDERTOTALS item outTheDoorCost", outTheDoorCost);
  console.log("ORDERTOTALS skidCharge", skidCharge);

  outTheDoorCost = parseFloat(outTheDoorCost) + parseFloat(skidCharge);
  outTheDoorCost = Math.ceil(outTheDoorCost);

  // This is correct
  const totalMaterialCost = calculations.reduce(
    (acc, item) => acc + parseFloat(item?.materialCost ?? 0),
    0
  );

  // This is correct
  const totalLaborCost = calculations.reduce(
    (acc, item) =>
      acc +
      parseFloat(
        item?.estimatedLaborCost ?? item?.totalEstimatedLaborCost ?? 0
      ), // totalEstimatedLaborCost for Benchwork
    0
  );

  const totalHardCost =
    totalMaterialCost + totalLaborCost + parseFloat(skidCharge);

  // This is correct
  const totalLaborHours = calculations.reduce(
    (acc, item) =>
      acc +
      parseFloat(
        item?.estimatedLaborHours ?? item?.totalEstimatedLaborHours ?? 0
      ), // totalEstimatedLaborHours for Benchwork
    0
  );

  const totalGPDollars = outTheDoorCost - totalHardCost;
  const totalGPPercent = 1 - totalHardCost / outTheDoorCost;

  return {
    outTheDoorCost,
    totalMaterialCost,
    totalLaborCost,
    totalHardCost,
    totalLaborHours,
    totalGPDollars,
    totalGPPercent,
  };
};

export const orderItemTotals = (orderItem) => {
  const calculations = findCalculationsObjects(orderItem);

  const outTheDoorCost = calculations.reduce(
    (acc, item) => acc + parseFloat(item?.totalSellPrice ?? 0),
    0
  );

  const totalMaterialCost = calculations.reduce(
    (acc, item) => acc + parseFloat(item?.materialCost ?? 0),
    0
  );

  const totalLaborCost = calculations.reduce(
    (acc, item) => acc + parseFloat(item?.estimatedLaborCost ?? 0),
    0
  );

  const totalLaborHours = calculations.reduce(
    (acc, item) => acc + parseFloat(item?.estimatedLaborHours ?? 0),
    0
  );

  return {
    outTheDoorCost,
    totalMaterialCost,
    totalLaborCost,
    totalLaborHours,
  };
};

export const getMaterialNeeded = (orderItems = []) => {
  const calculations = orderItems.flatMap((item) =>
    findCalculationsObjects(item)
  );

  const flatSheetsNeeded = calculations.reduce(
    (acc, item) => acc + parseFloat(item?.flatSheetsNeeded ?? 0),
    0
  );

  const [panel] = orderItems.filter((item) => item.objectType === "Panel");
  const [panelcalc] = findCalculationsObjects(panel) ?? [{}];

  let finishedPanelSquareFeet =
    parseFloat(panelcalc?.finishedPanelSquareFeet) ?? 0;
  let squareFeetOfCoilNeededWithWaste =
    parseFloat(panelcalc?.squareFeetOfCoilNeededWithWaste) ?? 0;

  let linealFeetOfCoilNoWaste =
    parseFloat(panelcalc?.linealFeetOfCoilNoWaste) ?? 0;
  let linealFeetOfCoilWithWaste =
    parseFloat(panelcalc?.linealFeetOfCoilWithWaste) ?? 0;
  let masterCoilSizeNeeded = parseFloat(panelcalc?.masterCoilSizeNeeded) ?? 0;

  // TODO: Add Material Needed for separate Flat Sheet and Coil orders
  const [flatSheet] = orderItems.filter(
    (item) => item.objectType === "FlatSheet"
  );
  const [flatSheetCalc] = findCalculationsObjects(flatSheet) ?? [{}];
  const [coil] = orderItems.filter((item) => item.objectType === "Coil");
  const [coilCalc] = findCalculationsObjects(coil) ?? [{}];

  return {
    flatSheetsNeeded,
    finishedPanelSquareFeet,
    squareFeetOfCoilNeededWithWaste,
    linealFeetOfCoilNoWaste,
    linealFeetOfCoilWithWaste,
    masterCoilSizeNeeded,
  };
};

//TODO: PO for Steel Vendor
export const getPODataForSteelVendor = (orderItems = []) => {};

//TODO: Order Summary (not really sure how to do this)

//TODO: Etimate

export const getTrimTotals = (trim) => {
  let totalNumberOfPieces = 0;
  let totalFlats = 0;
  let totalFlatSheetCost = 0;
  let outTheDoorCost = 0;
  let totalMaterialCost = 0;
  let totalLaborCost = 0;
  let totalLaborHours = 0;
  for (const piece of trim.trimPieces) {
    const calculations = piece.calculations;
    totalNumberOfPieces += parseFloat(piece.quantity);
    totalFlats += parseFloat(calculations?.flatSheetsNeeded);
    totalFlatSheetCost += parseFloat(calculations?.materialCost);
    outTheDoorCost += parseFloat(calculations?.totalSellPrice);
    totalMaterialCost += parseFloat(calculations?.materialCost);
    totalLaborCost += parseFloat(calculations?.estimatedLaborCost);
    totalLaborHours += parseFloat(calculations?.estimatedLaborHours);
  }

  let totalHardCost = totalMaterialCost + totalLaborCost;
  let totalGPDollars = outTheDoorCost - totalHardCost;
  let totalGPPercent = 1 - totalHardCost / outTheDoorCost;

  return {
    totalNumberOfPieces,
    totalFlats,
    totalFlatSheetCost,
    outTheDoorCost,
    totalMaterialCost,
    totalLaborCost,
    totalLaborHours,
    totalHardCost,
    totalGPDollars,
    totalGPPercent,
  };
};

export const getBenchWorkTotals = (benchWork) => {
  console.log("GET BENCH WORK TOTALS");
  let totalNumberOfPieces = 0;
  let totalFlats = 0;
  let totalFlatSheetCost = 0;
  let outTheDoorCost = 0;
  let totalMaterialCost = 0;
  let totalLaborCost = 0;
  let totalLaborHours = 0;
  for (const piece of benchWork.benchWorkPieces) {
    console.log("BENCH WORK PIECE", piece);
    const calculations = piece.calculations;
    totalNumberOfPieces += parseFloat(piece.quantity);
    totalFlats += parseFloat(piece.flatSheets);
    totalFlatSheetCost += parseFloat(calculations?.materialCost);
    outTheDoorCost += parseFloat(calculations?.totalSellPrice);
    totalMaterialCost += parseFloat(calculations?.materialCost);
    totalLaborCost += parseFloat(calculations?.totalEstimatedLaborCost);
    totalLaborHours += parseFloat(calculations?.totalEstimatedLaborHours);
  }

  let totalHardCost = totalMaterialCost + totalLaborCost;
  let totalGPDollars = outTheDoorCost - totalHardCost;
  let totalGPPercent = 1 - totalHardCost / outTheDoorCost;

  return {
    totalNumberOfPieces,
    totalFlats,
    totalFlatSheetCost,
    outTheDoorCost,
    totalMaterialCost,
    totalLaborCost,
    totalLaborHours,
    totalHardCost,
    totalGPDollars,
    totalGPPercent,
  };
};

export const getCleatTotals = (cleat) => {
  console.log("GET CLEAT TOTALS");
  let totalNumberOfPieces = 0;
  let totalFlats = 0;
  let totalFlatSheetCost = 0;
  let outTheDoorCost = 0;
  let totalMaterialCost = 0;
  let totalLaborCost = 0;
  let totalLaborHours = 0;
  for (const piece of cleat.cleatPieces) {
    console.log("CLEAT PIECE", piece);
    const calculations = piece.calculations;
    totalNumberOfPieces += parseFloat(piece.quantity);
    totalFlats += parseFloat(calculations?.flatSheetsNeeded);
    totalFlatSheetCost += parseFloat(calculations?.materialCost);
    outTheDoorCost += parseFloat(calculations?.totalSellPrice);
    totalMaterialCost += parseFloat(calculations?.materialCost);
    totalLaborCost += parseFloat(calculations?.estimatedLaborCost);
    totalLaborHours += parseFloat(calculations?.estimatedLaborHours);
  }

  let totalHardCost = totalMaterialCost + totalLaborCost;
  let totalGPDollars = outTheDoorCost - totalHardCost;
  let totalGPPercent = 1 - totalHardCost / outTheDoorCost;

  return {
    totalNumberOfPieces,
    totalFlats,
    totalFlatSheetCost,
    outTheDoorCost,
    totalMaterialCost,
    totalLaborCost,
    totalLaborHours,
    totalHardCost,
    totalGPDollars,
    totalGPPercent,
  };
};

export const getCopingCapTotals = (copingCap) => {
  console.log("GET COPING CAP TOTALS");
  let totalNumberOfPieces = 0;
  let totalFlats = 0;
  let totalFlatSheetCost = 0;
  let outTheDoorCost = 0;
  let totalMaterialCost = 0;
  let totalLaborCost = 0;
  let totalLaborHours = 0;
  for (const piece of copingCap.capPieces) {
    console.log("COPING CAP PIECE", piece);
    const calculations = piece.calculations;
    totalNumberOfPieces += parseFloat(piece.quantity);
    totalFlats += parseFloat(calculations?.flatSheetsNeeded);
    totalFlatSheetCost += parseFloat(calculations?.materialCost);
    outTheDoorCost += parseFloat(calculations?.totalSellPrice);
    totalMaterialCost += parseFloat(calculations?.materialCost);
    totalLaborCost += parseFloat(calculations?.estimatedLaborCost);
    totalLaborHours += parseFloat(calculations?.estimatedLaborHours);
  }

  let totalHardCost = totalMaterialCost + totalLaborCost;
  let totalGPDollars = outTheDoorCost - totalHardCost;
  let totalGPPercent = 1 - totalHardCost / outTheDoorCost;

  return {
    totalNumberOfPieces,
    totalFlats,
    totalFlatSheetCost,
    outTheDoorCost,
    totalMaterialCost,
    totalLaborCost,
    totalLaborHours,
    totalHardCost,
    totalGPDollars,
    totalGPPercent,
  };
};

export const getSplicePlateTotals = (splicePlate) => {
  console.log("GET SPLICE PLATE TOTALS");
  let totalNumberOfPieces = 0;
  let totalFlats = 0;
  let totalFlatSheetCost = 0;
  let outTheDoorCost = 0;
  let totalMaterialCost = 0;
  let totalLaborCost = 0;
  let totalLaborHours = 0;
  for (const piece of splicePlate.plates) {
    console.log("SPLICE PLATE PIECE", piece);
    const calculations = piece.calculations;
    totalNumberOfPieces += parseFloat(piece.quantity);
    totalFlats += parseFloat(calculations?.flatSheetsNeeded);
    totalFlatSheetCost += parseFloat(calculations?.materialCost);
    outTheDoorCost += parseFloat(calculations?.totalSellPrice);
    totalMaterialCost += parseFloat(calculations?.materialCost);
    totalLaborCost += parseFloat(calculations?.estimatedLaborCost);
    totalLaborHours += parseFloat(calculations?.estimatedLaborHours);
  }

  let totalHardCost = totalMaterialCost + totalLaborCost;
  let totalGPDollars = outTheDoorCost - totalHardCost;
  let totalGPPercent = 1 - totalHardCost / outTheDoorCost;

  return {
    totalNumberOfPieces,
    totalFlats,
    totalFlatSheetCost,
    outTheDoorCost,
    totalMaterialCost,
    totalLaborCost,
    totalLaborHours,
    totalHardCost,
    totalGPDollars,
    totalGPPercent,
  };
};

export const getAccessoryTotals = (accessory) => {
  console.log("GET ACCESSORIES TOTALS");
  let totalHardCost = 0;
  let totalMarkUp = 0;
  let totalSellPrice = 0;
  for (const piece of accessory.items) {
    const calculations = piece.calculations;
    totalHardCost += parseFloat(calculations?.materialCost);
    totalMarkUp += parseFloat(calculations?.markUp);
    totalSellPrice += parseFloat(calculations?.totalSellPrice);
  }

  return {
    totalHardCost,
    totalMarkUp,
    totalSellPrice,
  };
};
