// https://reactjs.org/docs/error-boundaries.html
// As of React 16, errors that were not caught by any error boundary
// will result in unmounting of the whole React component tree.

import { ErrorOutline } from "@mui/icons-material";
import { Box, Card, Container, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

// Can't convert this to a functional component
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    //logErrorToMyService(error, errorInfo);
    console.log("ErrorBoundary error", error);
    console.log("ErrorBoundary errorInfo", errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "Center",
          }}
        >
          <ErrorOutline
            sx={{
              fontSize: 200,
              color: this.props.lightText ? "#fff" : "#000",
            }}
          />
          <Typography
            variant="h1"
            sx={{
              textAlign: "center",
              color: this.props.lightText ? "#fff" : "#000",
            }}
          >
            Oops!
          </Typography>
          <Typography
            variant="h4"
            sx={{
              textAlign: "center",
              color: this.props.lightText ? "#fff" : "#000",
            }}
          >
            Something went wrong
          </Typography>
        </Box>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
