import { SvgIcon } from "@mui/material";

export const TrimIcon = ({ width = "15", height = "20", color = "white" }) => {
  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox="0 0 15 20"
      // fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M11.2941 1.41177H0V0H11.2941V1.41177Z" fill={color} />
      <path
        d="M9.83372 12.3528L9.8756 1.05873L11.2874 1.06396L11.2455 12.358L9.83372 12.3528Z"
        fill={color}
      />
      <path
        d="M9.09327 17.4866L6.91009 14.1816L7.99871 13.4625L10.1862 16.774C10.723 17.5866 11.3043 17.7863 11.9301 17.3729C12.5559 16.9595 12.6004 16.3465 12.0636 15.5338L9.87608 12.2223L10.9647 11.5032L13.1479 14.8082C13.6072 15.5035 13.7894 16.1478 13.6944 16.7411C13.6038 17.3316 13.2195 17.8507 12.5416 18.2985C11.8941 18.7263 11.278 18.8804 10.6935 18.7609C10.1061 18.6371 9.57268 18.2123 9.09327 17.4866Z"
        fill={color}
      />
    </SvgIcon>
  );
};
