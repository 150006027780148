import React, { useContext, useEffect, useState } from "react";
import usePageName from "../../../hocs/usePageName";
import {
  Box,
  Button,
  Card,
  TextField,
  Typography,
  Checkbox,
} from "@mui/material";
import { AuthContext } from "../../../context/AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import TimeCardForm from "./TimeCardForm";
import moment from "moment/moment";
import { LoadingButton } from "@mui/lab";

const TimeCardDetails = () => {
  usePageName({ heading: "Update Time Card" });
  const { id: timeCardId } = useParams();
  const navigate = useNavigate();
  const { authToken, ezorder } = useContext(AuthContext);

  const [isRefreshing, setIsRefreshing] = useState(false);
  const [timeCard, setTimeCard] = useState(null);
  const [newNote, setNewNote] = useState("");

  const getTimeCard = async () => {
    try {
      setIsRefreshing(true);
      console.log("Get Time Card");
      const response = await ezorder.get(
        `/shop/crew/time-cards/${timeCardId}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      console.log("TIME CARD", response.data);
      setTimeCard(response.data.timeCard);
    } catch (error) {
      console.log(error);
    } finally {
      setIsRefreshing(false);
    }
  };

  const updateTimeCard = async (timeCard) => {
    try {
      setIsRefreshing(true);
      console.log("Get Time Card");
      const response = await ezorder.put(
        `/shop/crew/time-cards/${timeCardId}`,
        timeCard,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      console.log("TIME CARD", response.data);
      getTimeCard();
    } catch (error) {
      console.log(error);
    } finally {
      setIsRefreshing(false);
    }
  };

  const addNewNotes = async () => {
    console.log("Add Notes", newNote);
    console.log(`POST /users/ezorderEmployees/time-cards/${timeCardId}/notes`);
    try {
      setIsRefreshing(true);
      const response = await ezorder.post(
        `/shop/crew/time-cards/${timeCardId}/notes`,
        {
          note: newNote,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      console.log("response", response.data);
      setNewNote("");
      getTimeCard();
    } catch (error) {
      console.log(error);
    } finally {
      setIsRefreshing(false);
    }
  };

  useEffect(() => {
    getTimeCard();
  }, []);

  if (!timeCard) return null;

  return (
    <Box>
      <TimeCardForm
        loading={isRefreshing}
        existingTimeCard={timeCard}
        onSubmit={(update) => {
          updateTimeCard(update);
        }}
      />
      <Typography style={{ fontWeight: "bold", fontSize: 20, marginLeft: 8 }}>
        Notes
      </Typography>

      {timeCard &&
        timeCard.notes.map((note, index) => (
          <Card key={index} style={{ margin: 8, padding: "16px" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={{ fontWeight: "bold" }}>
                {note.user.fullName}
              </Typography>
              <Typography>
                {moment(note.createdAt).format("MM/DD/YYYY hh:mm A")}
              </Typography>
            </Box>

            <Typography sx={{ marginTop: "16px", fontStyle: "italic" }}>
              {note.note}
            </Typography>
          </Card>
        ))}
      <Card style={{ margin: 8, padding: "16px" }}>
        <Typography style={{ fontSize: 20 }}>Add Notes</Typography>
        <TextField
          mode="outlined"
          placeholder="Add notes here..."
          fullWidth
          value={newNote}
          multiline
          rows={4}
          onChange={(e) => {
            setNewNote(e.target.value);
          }}
          //   style={{
          //     marginLeft: 16,
          //     marginRight: 16,
          //     marginTop: 2,
          //     marginBottom: 10,
          //   }}
          //   returnKeyType="next"
        />
        <Box>
          <Box
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <LoadingButton
              loading={isRefreshing}
              onClick={() => {
                addNewNotes();
              }}
            >
              Add
            </LoadingButton>
          </Box>
        </Box>
      </Card>
    </Box>
  );
};

export default TimeCardDetails;
