import { useState, useContext, useEffect } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import createPresignedUrl from "../../../helper/createPresignedUrl";
import uploadImage from "../../../helper/uploadImage";
import Catalog from "../../../components/Catalog/Catalog";
import useCompanyTrimCatalogAdmin from "../../../hooks/useCompanyTrimCatalogAdmin";

const CustomerTrimCatalog = ({ companyId, catalogCompanyName }) => {
  const navigate = useNavigate();
  const { authToken, ezorder, decoded } = useContext(AuthContext);
  const [
    searchQuery,
    setSearchQuery,
    catalogFilter,
    setCatalogFilter,
    results,
    onRefresh,
    refreshing,
    onLoadMore,
    loadingMore,
    total,
    hasMore,
  ] = useCompanyTrimCatalogAdmin(companyId, authToken, 10, null, "catalog"); // Load company's stock catalog

  const onItemSelect = async (catalogItem) => {
    // console.log("SELECT HANDLER", catalogItem);
    // navigate(`/master-trim-catalog/${catalogItem.id}`);
  };

  const onSubmitNewItem = async (name, imageBlob) => {
    // Create Presigned URL
    let actionType = "WRITE";
    let fileName = imageBlob.name;
    let contentType = imageBlob.type;
    let resource = "trims";
    let identifier = companyId; // decoded.companyId;
    const presignedUrlResponse = await createPresignedUrl(
      ezorder,
      authToken,
      actionType,
      fileName,
      contentType,
      resource,
      identifier
    );
    console.log("presignedUrlResponse", presignedUrlResponse);

    // Upload URL
    await uploadImage(presignedUrlResponse.uploadUrl, imageBlob);

    await saveCompanyStockTrimByAdmin(name, presignedUrlResponse.key, true);
    onRefresh();
  };

  const saveCompanyStockTrimByAdmin = async (name, image) => {
    let newTrim = {
      name,
      image,
      saveToCatalog: true,
      companyId,
    };

    const response = await ezorder.post(`/admin/products/trims`, newTrim, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    return response.data.trim;
  };

  useEffect(() => {
    console.log("hasMore", hasMore);
  }, [hasMore]);

  return (
    <>
      <Catalog
        search={searchQuery}
        setSearch={setSearchQuery}
        catalogFilter={catalogFilter}
        setCatalogFilter={setCatalogFilter}
        enableCatalogFilters={true}
        items={results}
        itemType={"Trim & Flashing"}
        onRefresh={onRefresh}
        refreshing={refreshing}
        onLoadMore={onLoadMore}
        isLoadingMore={loadingMore}
        saveNew={onSubmitNewItem}
        onSelect={onItemSelect}
        hasMore={hasMore}
        enableSaveToCatalog={false}
        isMasterCatalogItem={false}
        useFab={false}
        catalogCompanyName={catalogCompanyName}
        enablePrint={true}
        industryCatalogLink="/master-trim-catalog/print"
        companyCatalogLink={`/customer/${companyId}/trims/stock`}
      />
    </>
  );
};
export default CustomerTrimCatalog;
