import React, { useState, useContext, useEffect } from "react";

// react-pdf issue https://github.com/wojtekmaj/react-pdf/issues/991
import { Document, Page, pdfjs } from "react-pdf";
import { Button, Card, Dialog, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PdfViewer = (props) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [scale, setScale] = useState(1);
  const [fullScreen, setFullScreen] = useState(false);
  const { fileUri, downloadName } = props;

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <Card
      style={{
        // width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div
        style={{
          // width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Button
          onClick={() => {
            setPageNumber((prev) => (prev > 1 ? prev - 1 : prev));
          }}
        >
          Previous
        </Button>
        <Typography>
          Page {pageNumber} of {numPages}
        </Typography>

        <Button
          onClick={() => {
            setPageNumber((prev) => (prev < numPages ? prev + 1 : prev));
          }}
        >
          Next
        </Button>
      </div>
      <Button
        onClick={() => {
          // using Java Script method to get PDF file
          fetch(fileUri).then((response) => {
            response.blob().then((blob) => {
              console.log("BLOB", blob);
              // Creating new object of PDF file
              const fileURL = window.URL.createObjectURL(blob);
              // Setting various property values
              let alink = document.createElement("a");
              alink.href = fileURL;
              alink.download = downloadName ? downloadName : "file.pdf";
              alink.click();
            });
          });
        }}
      >
        Download
      </Button>
      <div
        style={{
          // width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Button
          onClick={() => {
            setScale((prev) => (prev > 0.5 ? prev - 0.1 : prev));
          }}
        >
          -
        </Button>
        <Typography>ZOOM</Typography>

        <Button
          onClick={() => {
            setScale((prev) => (prev < 3 ? prev + 0.1 : prev));
          }}
        >
          +
        </Button>
      </div>
      <div
        style={{
          // maxHeight: "600px",
          maxHeight: isSmallScreen ? "300px" : "600px",
          width: "100%",
          background: "#d3d3d3",
          margin: "16px",
          overflowX: "scroll",
          overflowY: "scroll",
        }}
      >
        <Document
          file={fileUri}
          onLoadSuccess={onDocumentLoadSuccess}
          onClick={() => {
            setFullScreen(true);
          }}
        >
          <Page pageNumber={pageNumber} scale={scale} />
        </Document>
      </div>
    </Card>
  );
};

export default PdfViewer;
