import { Box, Card, Stack, Typography } from "@mui/material";
import { getFlatSheetDetails } from "../../../../orderUtils";
import { toThreeDecimals, toTwoDecimals } from "../../../../calculations/utils";

const FlatSheetSummary = ({ order, isPrinting = false }) => {
  const flatSheetItem = getFlatSheetDetails(order);

  if (flatSheetItem && flatSheetItem.quantity > 0)
    return (
      <Box
        sx={{ padding: 2, margin: 1, color: isPrinting ? "#000" : "inherit" }}
      >
        <Typography variant={"h6"}>FLAT SHEET</Typography>
        <Stack direction={"row"} spacing={1}>
          <Stack direction={"column"} spacing={1}>
            <Typography>Width</Typography>
            <Typography>Quantity</Typography>
            <Typography>Total Flat Sheet Cost</Typography>
            <Typography>GP %</Typography>
            <Typography>GP $</Typography>
            <Typography>Total Sell Price</Typography>
            <Typography>Total Sell Price Per Flat Sheet</Typography>
          </Stack>
          <Stack direction={"column"} spacing={1}>
            <Typography>{flatSheetItem?.width}</Typography>
            <Typography>{flatSheetItem?.quantity}</Typography>
            <Typography>
              $
              {toTwoDecimals(
                flatSheetItem?.flatSheetCalculations?.materialCost
              )}
            </Typography>
            <Typography>
              {toTwoDecimals(
                flatSheetItem?.flatSheetCalculations?.grossProfitMargin * 100
              )}
            </Typography>
            <Typography>
              $
              {toTwoDecimals(
                flatSheetItem?.flatSheetCalculations?.grossProfitDollars
              )}
            </Typography>
            <Typography>
              $
              {toTwoDecimals(
                flatSheetItem?.flatSheetCalculations?.totalSellPrice
              )}
            </Typography>
            <Typography>
              $
              {toThreeDecimals(
                flatSheetItem?.flatSheetCalculations?.pricePerFlatSheet
              )}
            </Typography>
          </Stack>
        </Stack>
      </Box>
    );
};

export default FlatSheetSummary;
