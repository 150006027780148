import { useFormik } from "formik";
import * as yup from "yup";
import {
  Box,
  TextField,
  InputAdornment,
  IconButton,
  Typography,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import { useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { Edit } from "@mui/icons-material";

const UpdateUserDetails = ({ userDetails, handleSubmit, loading }) => {
  console.log(userDetails);
  const [isEditingEmail, setIsEditingEmail] = useState(false);
  const theme = useTheme();
  const loginValidationSchema = yup.object().shape({
    fullName: yup.string().required("Name is required"),
    email: yup.string().email().required("Email is required"),
    //password: yup.string().required("Password is required"),
    // companyName: yup.string().required("Company Name is required"),
  });

  const formikProps = useFormik({
    enableReinitialize: true,
    initialValues: {
      fullName: userDetails && userDetails.fullName ? userDetails.fullName : "",
      email: userDetails && userDetails.email ? userDetails.email : "",
      phone: userDetails && userDetails.phone ? userDetails.phone : "",
      // password: "",
    },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: loginValidationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      <TextField
        disabled={loading}
        label="Full Name"
        value={formikProps.values.fullName}
        onChange={formikProps.handleChange("fullName")}
      />
      <TextField
        disabled={loading || !isEditingEmail}
        // disabled={(userDetails != undefined && userDetails != null) || loading}
        label="Email"
        value={formikProps.values.email}
        onChange={formikProps.handleChange("email")}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start" size="small">
              <IconButton
                onClick={() => {
                  setIsEditingEmail(!isEditingEmail);
                }}
              >
                <Edit size="small" />
              </IconButton>
            </InputAdornment>
          ),
        }}
        helperText={
          isEditingEmail || formikProps.values.email != userDetails.email
            ? `You will be logged out after changing email address`
            : ""
        }
      />

      <TextField
        disabled={loading}
        label="Phone"
        value={formikProps.values.phone}
        onChange={formikProps.handleChange("phone")}
      />

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        <LoadingButton
          sx={{ minWidth: 150, marginLeft: "8px" }}
          loading={loading}
          variant="contained"
          onClick={() => formikProps.handleSubmit()}
        >
          Update
        </LoadingButton>
      </Box>
    </Box>
  );
};
export default UpdateUserDetails;
