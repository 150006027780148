import {
  Button,
  Box,
  TextField,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { useState, useMemo, useEffect, useContext } from "react";
import TrimItem from "./TrimItem";
import { AuthContext } from "../../../../../context/AuthContext";
import CollapsableCard from "../../../../CollapseableCard";
import NewTrimModal from "./NewTrimModal";
import { functionsBasedOnTrimType } from "./utils";
import useGalvanizedFlatOptions from "../../../../../hooks/useGalvanizedFlatOptions";
import CompanyTrimCatalogDialog from "./CompanyTrimCatalogDialog";
import { toFourDecimals } from "../../../calculations/utils";

const Trim = ({
  companyId, // null if guest
  details: trim,
  materialDetails,
  action,
  getPresignedUrl,
  uploadImage,
}) => {
  const { galvanizedFlatOptions } = useGalvanizedFlatOptions();
  const { authToken, ezorder } = useContext(AuthContext);
  const [isOpen, setIsOpen] = useState(false);
  const TRIM_TYPE = trim.objectType;
  const { getBlankTrim, getTrimPieces, updateTrim, getProperty, trimOptions } =
    functionsBasedOnTrimType(TRIM_TYPE);
  const [newTrim, setNewTrim] = useState(getBlankTrim());
  const toggleOpen = () => {
    setIsOpen(!isOpen);
    setNewTrim(getBlankTrim());
  };

  const [openMasterCatalog, setOpenMasterCatalog] = useState(false);
  const pieces = getTrimPieces(trim);

  const saveTrimPiece = async (piece) => {
    const resource =
      TRIM_TYPE === "TrimAndFlashing"
        ? "trims"
        : TRIM_TYPE === "CopingCap"
        ? "caps"
        : TRIM_TYPE === "CopingCapCleat"
        ? "cleats"
        : // : TRIM_TYPE === "SplicePlate"
        // ? ""
        TRIM_TYPE === "BenchWork"
        ? "benchPieces"
        : null;
    const key =
      TRIM_TYPE === "TrimAndFlashing"
        ? "trim"
        : TRIM_TYPE === "CopingCap"
        ? "cap"
        : TRIM_TYPE === "CopingCapCleat"
        ? "cleat"
        : // : TRIM_TYPE === "SplicePlate"
        // ? ""
        TRIM_TYPE === "BenchWork"
        ? "benchPiece"
        : null;
    try {
      const res = await ezorder.post(
        `/admin/products/${resource}`,
        { companyId, ...piece },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      return res.data[key];
    } catch (error) {
      console.log(error);
    }
  };

  const setImageUrl = (presignedImageResponse) => {
    const updatedTrim = { ...newTrim };
    switch (TRIM_TYPE) {
      case "TrimAndFlashing":
        setNewTrim({
          ...updatedTrim,
          trim: { ...updatedTrim.trim, image: presignedImageResponse.data.key },
        });
        break;
      case "CopingCap":
        setNewTrim({
          ...updatedTrim,
          cap: { ...updatedTrim.cap, image: presignedImageResponse.data.key },
        });
        break;
      case "CopingCapCleat":
        setNewTrim({
          ...updatedTrim,
          cleat: {
            ...updatedTrim.cleat,
            image: presignedImageResponse.data.key,
          },
        });
        break;
      case "BenchWork":
        setNewTrim({
          ...updatedTrim,
          benchPiece: {
            ...updatedTrim.benchPiece,
            image: presignedImageResponse.data.key,
          },
        });
        break;
      default:
        break;
    }
    return presignedImageResponse;
  };

  const handleImage = async (imageFile) => {
    // const handleImage = (e) => {
    await asyncPipe(
      getPresignedUrl(trimOptions.imageResourceType),
      setImageUrl,
      // uploadImage(e.target.files[0])
      uploadImage(imageFile)
      // )(e.target.files[0]);
    )(imageFile);
  };

  const changeHandler = (key) => (e) => {
    const updated = updateTrim(newTrim, key, e.target.value);
    setNewTrim({ ...updated });
  };

  const updateVariables = (piece) => (variableUpdates) => {
    const update = {
      ...piece,
      variables: {
        ...piece.variables,
        ...variableUpdates,
      },
    };
    action({ type: trimOptions.inlineEditActionType, payload: update });
  };

  const submithandler = async () => {
    if (
      !Object.values(newTrim).some(
        (val) => val === "" || val === undefined || val === null
      )
    ) {
      const piecekey =
        TRIM_TYPE === "TrimAndFlashing"
          ? "trim"
          : TRIM_TYPE === "CopingCap"
          ? "cap"
          : TRIM_TYPE === "CopingCapCleat"
          ? "cleat"
          : // : TRIM_TYPE === "SplicePlate"
          // ? ""
          TRIM_TYPE === "BenchWork"
          ? "benchPiece"
          : null;
      const saved = await saveTrimPiece(newTrim[piecekey]);

      action({
        type: trimOptions.updateActionType,
        payload: {
          ...newTrim,
          name: piecekey && saved.name ? saved.name : null,
          image: piecekey && saved.image ? saved.image : null,
          [piecekey]: { ...newTrim[piecekey], ...saved },
        },
      });
      toggleOpen();
    }
  };

  const masterTrimSelectHandler = async (masterTrim) => {
    let newTrim2 = {
      ...getBlankTrim(),
      quantity: 1,
      name: masterTrim.name,
      image: masterTrim.image,
      hits: masterTrim.isMasterCatalog ? masterTrim.masterHits : 1,
      stretchOut: masterTrim.isMasterCatalog ? masterTrim.masterStretchOut : 1,
      trim: masterTrim,
    };

    action({
      type: trimOptions.updateActionType,
      payload: newTrim2,
    });
    setOpenMasterCatalog(false);
  };

  const handleInlineEdit = (piece) => (key) => (e) => {
    const pieceKey =
      TRIM_TYPE === "TrimAndFlashing"
        ? "trim"
        : TRIM_TYPE === "CopingCap"
        ? "cap"
        : TRIM_TYPE === "CopingCapCleat"
        ? "cleat"
        : TRIM_TYPE === "SplicePlate"
        ? ""
        : TRIM_TYPE === "BenchWork"
        ? "benchPiece"
        : null;
    const update = { ...piece };
    if (key === "name") {
      // Name Inline Edited
      update[pieceKey].name = e.target.value;
    } else if (key !== "notes") {
      // All Other keys edited
      let value = e.target.value; // These are string numbers
      // if (e.target.value == "" || parseFloat(value) == 0) {
      //   value = "1";
      // }
      update[key] = parseFloat(value);
      // update[key] = value ? parseFloat(value) : 0;
    } else {
      // Notes (benchwork) Inline Edited
      update[key] = e.target.value;
    }
    action({ type: trimOptions.inlineEditActionType, payload: update });
  };

  const handleMaterialUpdate = (piece) => {
    // trigger a recalculation
    const update = { ...piece };
    action({ type: trimOptions.inlineEditActionType, payload: update });
  };

  const handleDelete = (index) => () => {
    action({ type: trimOptions.removeActionType, payload: index });
  };

  const handleGaugeSelect = (gauge, gaugeUnitOfMeasure, galvFlatSheetCost) => {
    action({
      type: "UPDATE CLEAT: GAUGE",
      payload: { gauge, gaugeUnitOfMeasure, galvFlatSheetCost },
    });
  };

  const overrideValue = (trim) => (key) => (e) => {
    let value = e.target.value; // These are string numbers
    if (e.target.value == "") {
      value = "0";
    }
    value = toFourDecimals(value);
    action({
      type: trimOptions.overrideActionType,
      payload: { trim, key, value: value },
    });
  };
  const resetOverride = (trim) => (key) => {
    action({
      type: trimOptions.resetActionType,
      payload: { trim, key },
    });
  };

  useEffect(() => {
    for (const piece of pieces) {
      if (!piece.calculations) {
        action({ type: trimOptions.runCalcsActionType, payload: piece });
      }
    }
  }, [trim]);

  const [trimTotalSellPrice, setTrimTotalSellPrice] = useState(0);

  useEffect(() => {
    if (trim) {
      let trimPieces = getTrimPieces(trim);
      let ttl = 0;
      for (const piece of trimPieces) {
        if (piece.calculations && piece.calculations.totalSellPrice) {
          ttl += parseFloat(piece.calculations.totalSellPrice);
        }
      }
      setTrimTotalSellPrice(Math.round(ttl));
    }
  }, [trim, materialDetails]);

  useEffect(() => {
    // Initialize price of galv flat sheet
    if (
      TRIM_TYPE === "CopingCapCleat" &&
      trim &&
      trim.cleatPieces.length > 0 &&
      galvanizedFlatOptions &&
      galvanizedFlatOptions.length > 0 &&
      materialDetails &&
      // materialDetails.galvFlatSheetCost == null // The default value is actually "0.00" and not null
      (materialDetails.galvFlatSheetCost == null ||
        parseFloat(materialDetails.galvFlatSheetCost) == 0)
    ) {
      let selectedGalvCleatGauge = galvanizedFlatOptions.find(
        (galvFlat) =>
          "" + galvFlat.gauge + galvFlat.unitOfMeasure ==
          "" + trim.gauge + trim.gaugeUnitOfMeasure
      );
      if (selectedGalvCleatGauge) {
        handleGaugeSelect(
          selectedGalvCleatGauge.gauge,
          selectedGalvCleatGauge.unitOfMeasure,
          selectedGalvCleatGauge.price
        );
      }
    }
  }, [
    trim,
    materialDetails.galvFlatSheetCost,
    galvanizedFlatOptions,
    trim.gauge + trim.gaugeUnitOfMeasure,
  ]);

  if (!galvanizedFlatOptions || galvanizedFlatOptions.length == 0) return null;

  return (
    <>
      <CollapsableCard
        title={`${trimOptions.title} - $${trimTotalSellPrice}`}
        defaultOpen={pieces.length > 0}
      >
        {TRIM_TYPE === "CopingCapCleat" ? (
          <Box>
            <TextField
              sx={{ width: 300 }}
              size="small"
              label="Galvanized Flat Sheet"
              select
              value={trim.gauge + trim.gaugeUnitOfMeasure}
              onChange={(e) => {
                let selectedGalvCleatGauge = galvanizedFlatOptions.find(
                  (galvFlat) =>
                    galvFlat.gauge + galvFlat.unitOfMeasure == e.target.value
                );
                handleGaugeSelect(
                  selectedGalvCleatGauge.gauge,
                  selectedGalvCleatGauge.unitOfMeasure,
                  selectedGalvCleatGauge.price
                );
              }}
            >
              {galvanizedFlatOptions?.map((galvFlat, index) => (
                <MenuItem
                  key={index}
                  value={galvFlat.gauge + galvFlat.unitOfMeasure}
                >
                  {galvFlat.gauge} {galvFlat.unitOfMeasure} - ${galvFlat.price}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        ) : (
          <Typography>
            Flat Sheet Pricing: $
            {materialDetails.flatSheetCost !=
            materialDetails.flatSheetCostOriginal
              ? materialDetails.flatSheetCost
              : materialDetails.flatSheetCostOriginal}
          </Typography>
        )}

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            overflowY: "auto",
          }}
        >
          {pieces.map((piece, index) => {
            return (
              <TrimItem
                key={index}
                materialDetails={materialDetails}
                handleMaterialUpdate={() => handleMaterialUpdate(piece)}
                name={getProperty(piece, "name")}
                image={getProperty(piece, "image")}
                hits={getProperty(piece, "hits")}
                quantity={getProperty(piece, "quantity")}
                stretchOut={getProperty(piece, "stretchOut")}
                isBenchwork={TRIM_TYPE === "BenchWork"}
                notes={getProperty(piece, "notes")}
                flatSheetsNeeded={getProperty(piece, "flatSheets")}
                width={getProperty(piece, "length")}
                overrides={piece.overrides}
                variables={piece.variables}
                calculations={piece.calculations}
                action={action}
                handleRemove={handleDelete(index)}
                handleInlineEdit={handleInlineEdit(piece)}
                overrideValue={overrideValue(piece)}
                resetOverride={resetOverride(piece)}
                updateVariables={updateVariables(piece)}
                isMasterCatalog={
                  piece.trim ? piece.trim.isMasterCatalog : false
                }
              />
            );
          })}
          <Button onClick={toggleOpen}>+ Add New</Button>
          {TRIM_TYPE === "TrimAndFlashing" && (
            <Button
              onClick={() => {
                setOpenMasterCatalog(!openMasterCatalog);
              }}
            >
              + Add Existing Trims
            </Button>
          )}
        </Box>
      </CollapsableCard>
      <NewTrimModal
        trimType={TRIM_TYPE}
        open={isOpen}
        toggleOpen={toggleOpen}
        trim={newTrim}
        handleChange={changeHandler}
        handleImage={handleImage}
        handleSubmit={submithandler}
      />
      <CompanyTrimCatalogDialog
        companyId={companyId}
        open={openMasterCatalog}
        setOpen={setOpenMasterCatalog}
        onItemSelect={(masterTrim) => {
          masterTrimSelectHandler(masterTrim);
        }}
      />
    </>
  );
};
export default Trim;

function asyncPipe(...fns) {
  return function (file) {
    return fns.reduce(async (arg, fn) => fn(await arg), file);
  };
}
