import { Routes, Route, BrowserRouter, useLocation } from "react-router-dom";
import ForgotPassword from "../pages/auth/ForgotPassword/ForgotPassword";
import Login from "../pages/auth/Login/Login";
import ResetPassword from "../pages/auth/ResetPassword/ResetPassword";
import Signup from "../pages/auth/Signup/Signup";
import Error404 from "../pages/error/Error404";
import ApproveEstimateAsGuest from "../pages/guest/ApproveEstimateAsGuest";
import AuthDashboard from "../pages/auth/AuthDashboard";

const AuthRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<AuthDashboard />}>
          <Route path="/" element={<Login />} />
          {/* <Route path="/signup" element={<Signup />} /> */}
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route
            path="/reset-password/:userId/:token"
            element={<ResetPassword />}
          />
          <Route
            path="/set-password/:userId/:token"
            element={<ResetPassword />}
          />
          <Route
            path="/orders/:orderId/approve-estimate"
            element={<ApproveEstimateAsGuest />}
          />
          {/* This orders route is so that user isnt sent to 404 page when clicking on an order link and is not logged in */}
          <Route path="/orders/*" element={<Login />} />
          <Route path="*" element={<Error404 lightText />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
export default AuthRoutes;
