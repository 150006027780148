// import { getMaterialNeeded } from "../../../../calculations/orderTotals";
import { useTheme } from "@emotion/react";
import {
  Typography,
  Stack,
  TextField,
  MenuItem,
  TextareaAutosize,
  Paper,
  Button,
  Box,
  IconButton,
} from "@mui/material";
import { useEffect, useState } from "react";
import PurchaseOrders from "../../../../pages/admin/PurchaseOrders/PurchaseOrders";
import { Add } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const MaterialNeeded = ({
  order,
  status = "Pending",
  notes = "",
  onUpdate,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [materialStatus, setMaterialStatus] = useState(status);
  const [materialNotes, setMaterialNotes] = useState(notes);
  const [materialNeeded, setMaterialNeeded] = useState(null);

  const getMaterialNeeded = (order) => {
    console.log("GET MATERIALS NEEDED", order);
    let color = `${order.gauge}${order.gauge < 1 ? "mm" : "g"}, ${
      order.color
    } - ${order.manufacturer}`;

    if (order.isCustomMaterial) {
      color = `${order.customGauge}, ${order.customColor} - ${order.customManufacturer}`;
    }

    let coil = {
      color: color,
      totalLF: 0,
    };

    let flatsByWidth = new Map();
    let galvFlatsNeeded = { size: 48, quantity: 0 };
    let benchFlats = 0; // We decide the size after iterating through all items (biggest size wins)
    let benchFlatSize = 0;
    let accessories = [];
    for (const orderItem of order.items) {
      switch (orderItem.objectType) {
        case "Panel":
          console.log("PANEL PANEL PANEL");
          let panelCalcs = orderItem.calculations;
          coil.totalLF = panelCalcs?.linealFeetOfCoilWithWaste ?? 0;
          break;
        case "TrimAndFlashing":
          let trimPieces = orderItem.trimPieces;
          for (const trimPiece of trimPieces) {
            let fsWidth = trimPiece.calculations.flatSheetSize;
            let flats = trimPiece.calculations.flatSheetsNeeded;
            console.log("TrimAndFlashing Flats", flats);
            let fsByWidth = flatsByWidth.get(fsWidth);
            if (!fsByWidth) {
              flatsByWidth.set(fsWidth, flats);
            } else {
              flatsByWidth.set(fsWidth, fsByWidth + flats);
            }
          }
          break;
        case "CopingCap":
          let capPieces = orderItem.capPieces;
          for (const capPiece of capPieces) {
            let fsWidth = capPiece.calculations.flatSheetSize;
            let flats = capPiece.calculations.flatSheetsNeeded;
            console.log("CopingCap Flats", flats);
            let fsByWidth = flatsByWidth.get(fsWidth);
            if (!fsByWidth) {
              flatsByWidth.set(fsWidth, flats);
            } else {
              flatsByWidth.set(fsWidth, fsByWidth + flats);
            }
          }
          break;
        case "CopingCapCleat":
          let cleatPieces = orderItem.cleatPieces;
          for (const cleatPiece of cleatPieces) {
            let flats = cleatPiece.calculations.flatSheetsNeeded;
            console.log("CopingCapCleat GALV Flats", flats);

            galvFlatsNeeded.quantity += flats;
          }
          break;
        case "BenchWork":
          let benchWorkPieces = orderItem.benchWorkPieces;
          for (const benchWorkPiece of benchWorkPieces) {
            benchFlats += benchWorkPiece.flatSheets;
          }
          break;
        case "SplicePlate":
          let plates = orderItem.plates;
          for (const plate of plates) {
            let fsWidth = plate.calculations.flatSheetSize;
            let flats = plate.calculations.flatSheetsNeeded;
            console.log("SplicePlate Flats", flats);
            let fsByWidth = flatsByWidth.get(fsWidth);
            if (!fsByWidth) {
              flatsByWidth.set(fsWidth, flats);
            } else {
              flatsByWidth.set(fsWidth, fsByWidth + flats);
            }
          }
          break;
        case "Accessory":
          accessories = orderItem.items;
          break;
        default:
          break;
      }
    }

    let flatWidthsDesc = Array.from(flatsByWidth.keys()).sort(function (a, b) {
      return b - a;
    });

    // Add the benchwork flats after determining the size
    if (flatWidthsDesc.length == 0) benchFlatSize = 48;
    else {
      benchFlatSize = flatWidthsDesc[0]; // biggest fs width
    }
    let fsByWidth = flatsByWidth.get(benchFlatSize);
    if (!fsByWidth) {
      flatsByWidth.set(benchFlatSize, benchFlats);
    } else {
      flatsByWidth.set(benchFlatSize, fsByWidth + benchFlats);
    }

    let flatWidthsAfterAddingBenchDesc = Array.from(flatsByWidth.keys()).sort(
      function (a, b) {
        return b - a;
      }
    );
    let flatsNeeded = [];
    for (const width of flatWidthsAfterAddingBenchDesc) {
      let fsNeeded = flatsByWidth.get(width);
      flatsNeeded.push({ size: width, quantity: fsNeeded });
    }
    let materials = {
      coil,
      color,
      flatsNeeded,
      galvFlatsNeeded,
      accessories,
    };
    console.log("MATERIALS NEEDED", materials);
    return materials;
  };

  useEffect(() => {
    if (order) {
      let materials = getMaterialNeeded(order);
      setMaterialNeeded(materials);
    }
  }, [order]);

  if (!materialNeeded) return null;
  return (
    <Stack spacing={3} direction="column">
      <Box sx={{ padding: "16px" }}>
        <Typography variant="h6" sx={{ mb: "15px", mt: 0 }}>
          Material Status
        </Typography>
        <TextField
          select
          variant="outlined"
          label="Material Status"
          name="Material Status"
          size="small"
          fullWidth
          value={materialStatus}
          onChange={(e) => setMaterialStatus(e.target.value)}
        >
          <MenuItem key={"Pending"} value={"Pending"}>
            Pending
          </MenuItem>
          <MenuItem
            key={"Material Ordered - Manufacturer"}
            value={"Material Ordered - Manufacturer"}
          >
            Material Ordered - Manufacturer
          </MenuItem>
          <MenuItem
            key={"Material Rec'd - Partial"}
            value={"Material Rec'd - Partial"}
          >
            Material Rec'd - Partial
          </MenuItem>
          <MenuItem
            key={"Material Rec'd - Full"}
            value={"Material Rec'd - Full"}
          >
            Material Rec'd - Full
          </MenuItem>
          <MenuItem
            key={"Material in Inventory"}
            value={"Material in Inventory"}
          >
            Material in Inventory
          </MenuItem>
        </TextField>

        <Typography sx={{ marginTop: "16px" }}>
          Material Status Notes (For Shop)
        </Typography>
        <TextareaAutosize
          style={{
            marginTop: "0px",
            background: theme.palette.secondary.main,
            color: "#fff",
            width: "100%",
          }}
          fullWidth
          minRows={5}
          value={materialNotes}
          onChange={(e) => {
            setMaterialNotes(e.target.value);
          }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "8px",
          }}
        >
          <Button
            variant="contained"
            onClick={() => {
              onUpdate(materialStatus, materialNotes);
            }}
          >
            Update Material Status and Notes
          </Button>
        </div>
      </Box>
      <Paper sx={{ padding: "16px" }}>
        <Typography variant="h6" sx={{ mb: "15px" }}>
          Material List
        </Typography>
        {materialNeeded.coil.totalLF > 0 && (
          <Typography>
            Coil: {materialNeeded.coil.color}, {materialNeeded.coil.totalLF} LF
          </Typography>
        )}

        {materialNeeded.flatsNeeded.map((fsType, index) => (
          <Typography key={index}>
            Flats: {materialNeeded.color}, {fsType.quantity} fs
          </Typography>
        ))}

        {materialNeeded.galvFlatsNeeded.quantity > 0 && (
          <Typography>
            Flats: {order.gauge}
            {order.gauge < 1 ? "in." : "g"}, Galv,{" "}
            {`${materialNeeded.galvFlatsNeeded.size}"`},{" "}
            {materialNeeded.galvFlatsNeeded.quantity} fs
          </Typography>
        )}
        {materialNeeded.accessories.map((accessory, index) => (
          <Typography key={index}>
            {accessory.name} {accessory.quantity} {accessory.unitOfMeasure}
          </Typography>
        ))}
      </Paper>
      <Paper sx={{ padding: "16px" }}>
        <Box sx={{ mb: "15px", mt: 0, display: "flex" }}>
          <Typography variant="h6" sx={{ flex: 1 }}>
            Purchase Orders
          </Typography>
          <Button
            onClick={() => {
              navigate(`/orders/${order.id}/purchase-orders/new`);
            }}
          >
            <Add />
            Create
          </Button>
        </Box>
        <PurchaseOrders selectedOrderId={order.id} isForOrder={true} />
      </Paper>
    </Stack>
  );
};
export default MaterialNeeded;
