import {
  Stack,
  IconButton,
  Box,
  Tooltip,
  Button,
  Input,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect } from "react";
import { useTheme } from "@emotion/react";
import usePresignedUrl from "../../../../../hooks/usePresignedUrl";
import SmallInput from "../../../../../components/SmallInput";
import ExpandableImage from "../../../../../components/ExpandableImage";
import TrimCalculations from "./TrimCalculations";
import BenchCalculations from "./BenchCalculations";

const TrimItem = ({
  materialDetails,
  handleMaterialUpdate,
  image,
  name,
  quantity,
  stretchOut,
  hits,
  width, // splice plate (width/length whatever)
  calculations,
  overrides,
  variables,
  overrideValue,
  resetOverride,
  updateVariables,
  handleRemove,
  handleInlineEdit,
  flatSheetsNeeded,
  notes,
  isBenchwork,
  isMasterCatalog,
}) => {
  const theme = useTheme();
  const presignedUrl = usePresignedUrl(image);

  useEffect(() => {
    handleMaterialUpdate();
  }, [
    materialDetails.flatSheetCost,
    materialDetails.flatSheetCostOriginal,
    materialDetails.flatSheetLength,
    materialDetails.galvFlatSheetCost,
  ]);

  // TODO: We are now copying the master hits and master stretchout to the trim piece right when the trim is selected
  // and added as a piece
  // useEffect(() => {
  //   if (isMasterCatalog && masterHits) {
  //     let e = {
  //       target: {
  //         value: masterHits,
  //       },
  //     };
  //     handleInlineEdit("hits")(e);
  //   }
  // }, [masterHits]);
  // useEffect(() => {
  //   // IF Trim is in Master Catalog, override stretchout
  //   if (isMasterCatalog && masterStretchOut) {
  //     let e = {
  //       target: {
  //         value: masterStretchOut,
  //       },
  //     };
  //     handleInlineEdit("stretchOut")(e);
  //   }
  // }, [masterStretchOut]);

  return (
    <>
      <Stack
        direction={"row"}
        spacing={2}
        sx={{
          border: "1px solid white",
          borderRadius: ".5rem",
          padding: ".5rem",
        }}
      >
        {image && (
          <Stack sx={{ alignSelf: "center" }} direction={"column"} spacing={2}>
            <ExpandableImage src={presignedUrl} imageTitle={name}>
              <Stack direction={"column"} spacing={2} mt={2}>
                <Stack direction={"row"} spacing={1}>
                  {name && (
                    <SmallInput
                      label="Name"
                      value={name}
                      onChange={(e) => handleInlineEdit("name")(e)}
                    />
                  )}
                  {notes && (
                    <SmallInput
                      label="Notes"
                      value={notes}
                      onChange={(e) => handleInlineEdit("notes")(e)}
                    />
                  )}

                  <SmallInput
                    onFocus={(e) => e.target.select()}
                    label="Quantity"
                    value={quantity}
                    onChange={(e) => {
                      e.target.value = parseFloat(e.target.value).toFixed(0);
                      handleInlineEdit("quantity")(e);
                    }}
                  />

                  {stretchOut && (
                    <TextField
                      onFocus={(e) => e.target.select()}
                      label="Stretch Out"
                      type="number"
                      onWheel={(event) => {
                        event.preventDefault();
                      }}
                      value={stretchOut}
                      disabled={isMasterCatalog}
                      onChange={(e) => handleInlineEdit("stretchOut")(e)}
                    />
                  )}
                  {flatSheetsNeeded && (
                    <SmallInput
                      type="number"
                      onWheel={(event) => {
                        event.preventDefault();
                      }}
                      onFocus={(e) => e.target.select()}
                      label="Flat Sheets Needed"
                      value={flatSheetsNeeded}
                      // onChange={(e) => handleInlineEdit("flatSheetsNeeded")(e)}
                      // value={toTwoDecimals(calcs?.estimatedLaborHours).toFixed(
                      //   0
                      // )}
                      onChange={(e) => {
                        e.target.value = parseFloat(e.target.value).toFixed(0);
                        handleInlineEdit("flatSheetsNeeded")(e);
                      }}
                    />
                  )}
                </Stack>
                <Stack direction={"row"} spacing={1}>
                  {hits && (
                    <SmallInput
                      type="number"
                      onWheel={(event) => {
                        event.preventDefault();
                      }}
                      onFocus={(e) => e.target.select()}
                      label="Hits"
                      value={hits}
                      disabled={isMasterCatalog}
                      onChange={(e) => {
                        e.target.value = parseFloat(e.target.value).toFixed(0);
                        handleInlineEdit("hits")(e);
                      }}
                    />
                  )}
                </Stack>
              </Stack>
            </ExpandableImage>
          </Stack>
        )}
        <Stack direction={"column"} spacing={1}>
          {name && (
            <SmallInput
              label="Name"
              value={name}
              onChange={(e) => handleInlineEdit("name")(e)}
            />
          )}
          {notes && (
            <SmallInput
              label="Notes"
              value={notes}
              onChange={(e) => handleInlineEdit("notes")(e)}
            />
          )}

          <SmallInput
            type="number"
            onWheel={(event) => {
              event.preventDefault();
            }}
            onFocus={(e) => e.target.select()}
            label="Quantity"
            value={quantity}
            onChange={(e) => {
              e.target.value = parseFloat(e.target.value).toFixed(0);
              handleInlineEdit("quantity")(e);
            }}
          />

          {/* ******BenchWork******* */}
          {flatSheetsNeeded && (
            <SmallInput
              sx={{
                fieldset: {
                  borderColor: theme.palette.primary.main,
                  borderWidth: 3,
                },
              }}
              type="number"
              onWheel={(event) => {
                event.preventDefault();
              }}
              onFocus={(e) => e.target.select()}
              label="Flat Sheets Needed"
              // value={flatSheetsNeeded}
              // onChange={(e) => handleInlineEdit("flatSheets")(e)}
              value={flatSheetsNeeded}
              // onChange={(e) => handleInlineEdit("flatSheetsNeeded")(e)}
              // value={toTwoDecimals(calcs?.estimatedLaborHours).toFixed(
              //   0
              // )}
              onChange={(e) => {
                e.target.value = parseFloat(e.target.value).toFixed(0);
                handleInlineEdit("flatSheets")(e);
              }}
            />
          )}
          {/* ******BenchWork******* */}
          {stretchOut && (
            <SmallInput
              sx={{
                fieldset: {
                  borderColor: isMasterCatalog
                    ? null
                    : theme.palette.primary.main,
                  borderWidth: 3,
                },
              }}
              type="number"
              onFocus={(e) => e.target.select()}
              label="Stretch Out"
              value={stretchOut}
              disabled={isMasterCatalog}
              onChange={(e) => handleInlineEdit("stretchOut")(e)}
            />
          )}
          {hits && (
            <SmallInput
              sx={{
                fieldset: {
                  borderColor: isMasterCatalog
                    ? null
                    : theme.palette.primary.main,
                  borderWidth: 3,
                },
              }}
              type="number"
              onWheel={(event) => {
                event.preventDefault();
              }}
              onFocus={(e) => e.target.select()}
              label="Hits"
              value={hits}
              disabled={isMasterCatalog}
              onChange={(e) => {
                e.target.value = parseFloat(e.target.value).toFixed(0);
                handleInlineEdit("hits")(e);
              }}
            />
          )}
          {/* Splice Plate Width */}
          {width && !isBenchwork && (
            <FormControl sx={{ m: 1 }} variant="outlined">
              <InputLabel>Width</InputLabel>
              <Select
                value={parseFloat(width)}
                onChange={(e) => handleInlineEdit("length")(e)}
              >
                <MenuItem value={6}>6</MenuItem>
                <MenuItem value={8}>8</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={12}>12</MenuItem>
              </Select>
            </FormControl>
          )}
        </Stack>
        {!isBenchwork ? (
          <Stack sx={{ flex: 1 }}>
            <TrimCalculations
              calculations={calculations}
              overrides={overrides}
              handleChange={overrideValue}
              resetOverride={resetOverride}
            />
          </Stack>
        ) : (
          <Stack sx={{ flex: 1 }}>
            <BenchCalculations
              materialDetails={materialDetails}
              calculations={calculations}
              overrides={overrides}
              variables={variables}
              updateVariables={updateVariables}
              handleChange={overrideValue}
              resetOverride={resetOverride}
            />{" "}
          </Stack>
        )}
        <Box>
          <Tooltip title="Remove">
            <IconButton size="small" onClick={handleRemove}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
        </Box>
      </Stack>
    </>
  );
};
export default TrimItem;
