import withLoading from "../withLoading";
import OrderDetails from "./OrderTabs/PricingAndQuote/OrderDetails";
import CoilDetails from "./OrderItems/Coil/CoilDetails";
import PanelDetails from "./OrderItems/Panel/PanelDetails";
import Trim from "./OrderItems/Trim/Trim";
import FlatSheetDetails from "./OrderItems/FlatSheet/FlatSheetDetails";
import AccessoryDetails from "./OrderItems/Accessories/AccessoryDetails";
// import Estimate from "../../OrderCategories/Estimate";
import CustomerQuote from "./OrderTabs/PricingAndQuote/QuoteAndApproval/CustomerQuote";
import Approval from "./OrderTabs/PricingAndQuote//QuoteAndApproval/Approval";
import Invoice from "./OrderTabs/Invoice/Invoice";
import {
  getFlatSheetDetails,
  getCoilDetails,
  getPanelDetails,
  getTrimDetails,
  getCopingCapDetails,
  getCopingCapCleatDetails,
  getSplicePlateDetails,
  getBenchWorkDetails,
  getAccessoryDetails,
} from "../orderUtils";
import {
  Backdrop,
  Box,
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fab,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { useContext, useEffect, useRef, useState } from "react";
import { CustomTabs } from "../../Tabs";
import CollapsableCard from "../../CollapseableCard";
import MaterialNeeded from "./OrderTabs/MaterialNeeded";
import Summary from "./OrderTabs/Summary/Summary";
import { Cancel, Close, Delete } from "@mui/icons-material";
import { useTheme } from "@emotion/react";
import OrderPrimaryInfo from "./OrderTabs/PricingAndQuote/OrderPrimaryInfo";
import OrderProgressStatus from "./OrderTabs/OrderProgressStatus";
import PickupStatus from "./OrderTabs/PickupStatus";
import { AuthContext } from "../../../context/AuthContext";
import CommentSection from "./CommentSection";
import SalesRepSection from "./SalesRepSection";
import CancelOrderDialog from "./OrderDialogs/CancelOrderDialog";
import SkipInvoiceAndPaymentDialog from "./OrderDialogs/SkipInvoiceAndPaymentDialog";
import SkipCustomerApprovalDialog from "./OrderDialogs/SkipCustomerApprovalDialog";
import QuoteRequestPreview from "./OrderTabs/QuoteRequestPreview/QuoteRequestPreview";
import Company from "./OrderTabs/Company/Company";

// Admin
const View = ({
  state: order,
  action,
  deconstructOrder,
  getPresignedUrl,
  uploadImage,
  // handleOverrideValues,
  handleSubmit,
  // generateQuickbooksInvoiceAndUpdateStatusToPendingPayment,
  generateEstimateForOrder,
  updateOrder,
  updateOrderStatus,
  resendEstimate,
  addComment,
  updatePickupStatus,
  addPickupNotes,
  updateMaterialStatus,
  onUpdateEstimatedCompletionDate,
  onChangeShopStatus,
  onUpdateShopImages,
  onUpdatePickupImages,
  // addShopNotes,
  setLoading,
  isLoading,
  isGeneratingEstimate,
  isGeneratingInvoice,
}) => {
  const theme = useTheme();
  const [comment, setComment] = useState("");
  const { authToken, ezorder } = useContext(AuthContext);
  const [finalizeQuoteEmailReceivers, setFinalizeQuoteEmailReceivers] =
    useState([]);
  const [sendQuoteCopyTo, setSendQuoteCopyTo] = useState([]);
  const [isAddingOtherApproversToCopy, setIsAddingOtherApproversToCopy] =
    useState(false);

  const [cancelOrderDialogOpen, setCancelOrderDialogOpen] = useState(false);
  const [skipInvoiceAndPaymentDialogOpen, setSkipInvoiceAndPaymentDialogOpen] =
    useState(false);
  const [skipCustomerApprovalDialogOpen, setSkipCustomerApprovalDialogOpen] =
    useState(false);

  const invoiceTabRef = useRef(null);
  const quoteTabRef = useRef(null);

  const materialNeededTabRef = useRef(null);
  const orderProgressStatusTabRef = useRef(null);
  const pickupStatusTabRef = useRef(null);

  const materialDetails = {
    color: order.color,
    manufacturer: order.manufacturer,
    gauge: order.gauge,
    flatSheetCost: order.flatSheetCost,
    flatSheetCostOriginal: order.flatSheetCostOriginal,
    coilCostPerSqFt: order.coilCostPerSqFt,
    coilCostPerSqFtOriginal: order.coilCostPerSqFtOriginal,
    flatSheetLength: order.flatSheetLength,
    galvFlatSheetCost: order.galvFlatSheetCost,
  };

  const flatSheetDetails = getFlatSheetDetails(order);
  const coilDetails = getCoilDetails(order);
  const panelDetails = getPanelDetails(order);
  const trimDetails = getTrimDetails(order);
  const copingCapDetails = getCopingCapDetails(order);
  const cleatDetails = getCopingCapCleatDetails(order);
  const plateDetails = getSplicePlateDetails(order);
  const benchDetails = getBenchWorkDetails(order);
  const accessoryDetails = getAccessoryDetails(order);

  const [finalizeAndSendEstimateModal, setFinalizeAndSendEstimateModalOpen] =
    useState(false);

  const [finalizeEstimateEmailText, setFinalizeEstimateEmailText] =
    useState("");

  const resetDefaultEstimateEmailText = () => {
    let defaultEstimateEmailText = `Hi,\n\n[[LINK]]\n\nThank you for letting us quote this project. If everything looks correct, please approve by clicking on the link above, then clicking "Approve".\n\nWe appreciate you choosing Industry Metal Co and look forward to working with you!\n\nThank you,`;
    setFinalizeEstimateEmailText(defaultEstimateEmailText);
  };

  useEffect(() => {
    if (order && order.project) {
      resetDefaultEstimateEmailText();
    }
  }, [order]);

  const getFinalizedQuoteEmailList = async () => {
    try {
      const res = await ezorder.get(
        `/admin/companies/${order.company.id}/users/${order.estimateRequestedBy.id}/estimate-approvers`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      let requester = order.estimateRequestedBy;
      let approvers = res.data.quoteApprovers;
      let emails = [];
      // Add Requester to email list if not an approver
      const requesterIndexInApprovers = approvers.findIndex(
        (item) => item.email == requester.email
      );
      if (requesterIndexInApprovers == -1) {
        emails = [requester, ...approvers];
      } else {
        approvers.splice(requesterIndexInApprovers, 1);
        emails = [requester, ...approvers];
      }
      setFinalizeQuoteEmailReceivers(emails);
    } catch (error) {
      console.log(error);
    }
    return null;
  };

  const [customTabValue, setCustomTabValue] = useState(0);

  const QBcustomerId = order?.company?.qbCustomerId
    ? order?.company?.qbCustomerId
    : order.qbCustomerId;

  useEffect(() => {
    if (order && order.company && order.company.id) {
      getFinalizedQuoteEmailList(order.id);
    }
  }, [order.company]);

  useEffect(() => {
    if (order && order.estimateRequestedBy && order.estimateAssignedApprover) {
      if (
        order.estimateRequestedBy.email != order.estimateAssignedApprover.email
      ) {
        setSendQuoteCopyTo([
          order.estimateRequestedBy,
          order.estimateAssignedApprover,
        ]);
      } else {
        setSendQuoteCopyTo([order.estimateRequestedBy]);
      }
    }
  }, [order.estimateRequestedBy, order.estimateAssignedApprover]);

  useEffect(() => {
    // If the company has a qbCustomerId but the Order does not, set the order to match the compnay
    if (order?.company?.qbCustomerId && !order?.qbCustomerId) {
      action({
        type: "UPDATE DETAILS: QB CUSTOMER ID",
        payload: order?.company?.qbCustomerId,
      });
    }
  }, []);

  console.log("THE STATE OF ORDER", order);

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "2rem" }}>
        <SalesRepSection orderId={order.id} />
        <CommentSection orderId={order.id} addComment={addComment} />
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
            width: "100vw",
            height: "100vh",
          }}
          open={isLoading || isGeneratingEstimate || isGeneratingInvoice}
          // onClick={handleClose}
        >
          <CircularProgress color="primary" />
        </Backdrop>
        <CollapsableCard title="Details">
          {order && (
            <Box sx={{ marginTop: "16px", marginBottom: "16px" }}>
              <OrderPrimaryInfo
                details={order}
                action={action}
                isCustomMaterial={order.isCustomMaterial}
              />
            </Box>
          )}
          <CustomTabs
            activeTab={customTabValue}
            setActiveTab={setCustomTabValue}
            onActiveTabChange={(tabVal) => {
              if (tabVal == 1 && order.id) {
                quoteTabRef.current.scrollIntoView({
                  behavior: "smooth",
                  block: "nearest",
                  inline: "start",
                });
              }
              if (tabVal == 2 && order.id) {
                materialNeededTabRef.current.scrollIntoView({
                  behavior: "smooth",
                  block: "nearest",
                  inline: "start",
                });
              }
              if (tabVal == 3 && order.id) {
                invoiceTabRef.current.scrollIntoView({
                  behavior: "smooth",
                  block: "nearest",
                  inline: "start",
                });
              }
              if (tabVal == 4 && order.id) {
                orderProgressStatusTabRef.current.scrollIntoView({
                  behavior: "smooth",
                  block: "nearest",
                  inline: "start",
                });
              }
            }}
          >
            {order && order.id && (
              <Box tabName="Quote Request">
                <QuoteRequestPreview
                  order={order}
                  deconstructOrder={deconstructOrder}
                />
              </Box>
            )}
            {order && (
              <Box tabName="Pricing & Quote">
                {order && order.id && (
                  <Box ref={quoteTabRef} sx={{ marginBottom: "16px" }}>
                    <CustomerQuote
                      order={order}
                      orderId={order.id}
                      estimatePdf={order.estimatePdf}
                      enableGenerateEstimate={
                        order.orderStatus.name &&
                        (order.orderStatus.name === "QUOTE_REQUESTED" ||
                          order.orderStatus.name === "SAVED")
                      }
                      generateEstimateForOrder={generateEstimateForOrder}
                      isGeneratingEstimate={isGeneratingEstimate}
                      style={{ width: "100%" }}
                      selectedPaymentTermId={order.paymentTermId}
                      isItemized={order.isItemizedEstimate}
                      toggleItemized={(isItemized) => {
                        action({
                          type: "SET ORDER STATE",
                          payload: {
                            isItemizedEstimate: isItemized,
                          },
                        });
                      }}
                    />
                    {order.estimatePdf &&
                      order.orderStatus &&
                      order.orderStatus.name !== "SAVED" &&
                      order.orderStatus.name !== "QUOTE_REQUESTED" && (
                        <Approval
                          action={action}
                          order={order}
                          updateOrderStatus={updateOrderStatus}
                        />
                      )}
                  </Box>
                )}
                <OrderDetails
                  details={order}
                  action={action}
                  isCustomMaterial={order.isCustomMaterial}
                  isCustomerSupplied={order.isMaterialSuppliedByCustomer}
                  toggleCustomerSupplied={(isCustomerSupplied) => {
                    action({
                      type: "SET ORDER STATE",
                      payload: {
                        isMaterialSuppliedByCustomer: isCustomerSupplied,
                      },
                    });
                  }}
                />
              </Box>
            )}
            {order && order.id && (
              <Box tabName="Material Needed" ref={materialNeededTabRef}>
                <MaterialNeeded
                  order={order}
                  status={order.materialStatus}
                  notes={order.materialNotes}
                  onUpdate={(status, notes) => {
                    updateMaterialStatus(status, notes);
                  }}
                />
              </Box>
            )}

            {order && order.id && (
              <Box tabName="Invoice" ref={invoiceTabRef}>
                <>
                  <Invoice
                    updateOrder={updateOrder}
                    order={order}
                    action={action}
                    isGeneratingInvoice={isGeneratingInvoice}
                  />
                </>
              </Box>
            )}
            {order && order.id && (
              <Box tabName="Status" ref={orderProgressStatusTabRef}>
                <OrderProgressStatus
                  isLoading={isLoading}
                  disableChangeStatus={order.orderStatus.name != "IN_PROGRESS"}
                  order={order}
                  status={order.shopStatus}
                  // status={order.progressStatus}
                  // notes={order.shopNotes}
                  onUpdateEstimatedCompletionDate={
                    onUpdateEstimatedCompletionDate
                  }
                  onChangeStatus={(status) => {
                    onChangeShopStatus(status);
                  }}
                  onUpdateImages={(savedImages, newImageBlobs) => {
                    onUpdateShopImages(savedImages, newImageBlobs);
                  }}
                  // onAddNotes={addShopNotes}
                />
                <PickupStatus
                  disableChangeStatus={
                    order.orderStatus.name != "READY_FOR_PICKUP"
                  }
                  order={order}
                  status={order.pickUpStatus}
                  notes={order.pickUpNotes}
                  onChangeStatus={(
                    status,
                    deliveredBy,
                    deliveredOn,
                    pickedUpBy,
                    pickedUpOn
                  ) => {
                    updatePickupStatus(
                      status,
                      deliveredBy,
                      deliveredOn,
                      pickedUpBy,
                      pickedUpOn
                    );
                  }}
                  onUpdateImages={(savedImages, newImageBlobs) => {
                    onUpdatePickupImages(savedImages, newImageBlobs);
                  }}
                  onAddNotes={addPickupNotes}
                />
              </Box>
            )}

            {order && order.id && (
              <Box tabName="Company">
                <Company details={order} />
              </Box>
            )}
          </CustomTabs>
        </CollapsableCard>
      </Box>

      {((order && !order.orderStatus) ||
        (order && order.id && customTabValue == 1)) && (
        <Box>
          <Typography variant="h5" sx={{ marginTop: "16px" }}>
            Order Items
          </Typography>
          <Box sx={{ marginTop: "16px" }}>
            {flatSheetDetails && (
              <FlatSheetDetails
                details={flatSheetDetails}
                action={action}
                materialDetails={materialDetails}
              />
            )}
          </Box>
          <Box sx={{ marginTop: "16px" }}>
            {coilDetails && (
              <CoilDetails
                details={coilDetails}
                action={action}
                materialDetails={materialDetails}
              />
            )}
          </Box>

          <Box sx={{ marginTop: "16px" }}>
            {panelDetails && (
              // customTabValue < 1 &&
              <PanelDetails
                details={panelDetails}
                action={action}
                materialDetails={materialDetails}
              />
            )}
          </Box>
          <Box sx={{ marginTop: "16px" }}>
            {trimDetails && (
              // customTabValue < 1 &&
              <Trim
                companyId={order.company ? order.company.id : null}
                details={trimDetails}
                materialDetails={materialDetails}
                action={action}
                getPresignedUrl={getPresignedUrl}
                uploadImage={uploadImage}
              />
            )}
          </Box>
          <Box sx={{ marginTop: "16px" }}>
            {copingCapDetails && (
              // customTabValue < 1 &&
              <Trim
                companyId={order.company ? order.company.id : null}
                details={copingCapDetails}
                materialDetails={materialDetails}
                action={action}
                getPresignedUrl={getPresignedUrl}
                uploadImage={uploadImage}
              />
            )}
          </Box>
          <Box sx={{ marginTop: "16px" }}>
            {cleatDetails && (
              // customTabValue < 1 &&
              <Trim
                companyId={order.company ? order.company.id : null}
                details={cleatDetails}
                materialDetails={materialDetails}
                action={action}
                getPresignedUrl={getPresignedUrl}
                uploadImage={uploadImage}
              />
            )}
          </Box>
          <Box sx={{ marginTop: "16px" }}>
            {plateDetails && (
              // customTabValue < 1 &&
              <Trim
                companyId={order.company ? order.company.id : null}
                details={plateDetails}
                materialDetails={materialDetails}
                action={action}
              />
            )}
          </Box>
          <Box sx={{ marginTop: "16px" }}>
            {benchDetails && (
              // customTabValue < 1 &&
              <Trim
                companyId={order.company ? order.company.id : null}
                details={benchDetails}
                materialDetails={materialDetails}
                action={action}
                getPresignedUrl={getPresignedUrl}
                uploadImage={uploadImage}
              />
            )}
          </Box>
          <Box sx={{ marginTop: "16px" }}>
            {accessoryDetails && (
              // customTabValue < 1 &&
              <AccessoryDetails details={accessoryDetails} action={action} />
            )}
          </Box>

          {order && order.id && (
            <Card sx={{ marginTop: "16px", padding: "16px" }}>
              <Typography variant="h4">Order Summary</Typography>
              <Summary order={order} />
            </Card>
          )}
        </Box>
      )}

      {order.orderStatus && (
        <Dialog
          fullWidth
          open={finalizeAndSendEstimateModal}
          onClose={() => {
            setFinalizeAndSendEstimateModalOpen(false);
            resetDefaultEstimateEmailText();
          }}
        >
          <DialogTitle>
            {order.orderStatus.name == "ESTIMATE_APPROVAL_NEEDED"
              ? "Re-Send Estimate"
              : "Finalize & Send Estimate"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>Send To</DialogContentText>

            <Box>
              {sendQuoteCopyTo.map((sendTo, index) => {
                return (
                  <Button
                    size="small"
                    variant="outlined"
                    color={
                      (order.estimateRequestedBy &&
                        sendTo.email == order.estimateRequestedBy.email) ||
                      (order.estimateAssignedApprover &&
                        sendTo.email == order.estimateAssignedApprover.email)
                        ? "success"
                        : "neutral"
                    }
                    sx={{
                      borderRadius: "120px",
                      textTransform: "none",
                      marginRight: "8px",
                    }}
                  >
                    {order.estimateRequestedBy &&
                    sendTo.email == order.estimateRequestedBy.email
                      ? "[Requester] "
                      : ""}
                    {order.estimateAssignedApprover &&
                    sendTo.email == order.estimateAssignedApprover.email
                      ? "[Approver] "
                      : ""}
                    {sendTo.fullName}({sendTo.email})
                    {/* {sendTo.email == order.estimateRequestedBy.email
                  ? " [Quote Requester]"
                  : ""} */}
                    <Close
                      sx={{ marginLeft: "8px" }}
                      onClick={() => {
                        console.log("Remove From");
                        let copy = [...sendQuoteCopyTo];
                        copy.splice(index, 1);
                        setSendQuoteCopyTo(copy);
                      }}
                    />
                  </Button>
                );
              })}
              <Box>
                <Button
                  onClick={() => {
                    if (
                      sendQuoteCopyTo.length <
                      finalizeQuoteEmailReceivers.length
                    )
                      setIsAddingOtherApproversToCopy(true);
                  }}
                >
                  + Copy Requester And Other Approvers
                </Button>
              </Box>
              {isAddingOtherApproversToCopy && (
                <Box>
                  <DialogContentText>Add Other Approvers</DialogContentText>
                  <Card style={{}}>
                    <div style={{}}>
                      <div
                        style={{
                          display: "block",
                          maxHeight: 200,
                          maxWidth: "100%",
                          overflow: "scroll",
                          overflowX: "hidden",
                        }}
                      >
                        {finalizeQuoteEmailReceivers.map(
                          (finalizeQuoteEmailReceiver, index) => {
                            const result = sendQuoteCopyTo.findIndex(
                              (item) =>
                                item.email == finalizeQuoteEmailReceiver.email
                            );

                            if (result < 0)
                              return (
                                <ListItem
                                  onClick={() => {
                                    let copy = [...sendQuoteCopyTo];
                                    copy.push(finalizeQuoteEmailReceiver);
                                    setSendQuoteCopyTo(copy);
                                    setIsAddingOtherApproversToCopy(false);
                                  }}
                                  sx={{
                                    cursor: "pointer",
                                    "&:hover": {
                                      background: "#5A5A5A",
                                    },
                                  }}
                                >
                                  <ListItemText
                                    primary={`${
                                      finalizeQuoteEmailReceiver.email ==
                                      order.estimateRequestedBy.email
                                        ? "[Requester] "
                                        : ""
                                    } ${finalizeQuoteEmailReceiver.fullName}`}
                                    secondary={
                                      <>
                                        <Typography
                                          style={{
                                            fontSize: 12,
                                          }}
                                        >
                                          Email:{" "}
                                          {finalizeQuoteEmailReceiver.email}
                                        </Typography>
                                        {/* <Typography
                                style={{
                                  fontSize: 12,
                                }}
                              >
                                Phone: {finalizeQuoteEmailReceiver.phone}
                              </Typography> */}
                                      </>
                                    }
                                  />
                                </ListItem>
                              );
                          }
                        )}
                      </div>
                    </div>
                  </Card>
                </Box>
              )}
            </Box>
            <TextField
              sx={{ marginTop: "16px" }}
              fullWidth
              variant="outlined"
              label="Email Body"
              name="emailBody"
              multiline
              rows={8}
              size="small"
              value={finalizeEstimateEmailText}
              onChange={(e) => {
                setFinalizeEstimateEmailText(e.target.value);
              }}
              helperText="The system will replace [[LINK]] with the actual link when it sends out this email."
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setFinalizeAndSendEstimateModalOpen(false);
                resetDefaultEstimateEmailText();
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                if (order.orderStatus.name == "ESTIMATE_APPROVAL_NEEDED") {
                  resendEstimate(finalizeEstimateEmailText, sendQuoteCopyTo);
                } else {
                  updateOrderStatus("ESTIMATE_APPROVAL_NEEDED", {
                    emailBody: finalizeEstimateEmailText,
                    sendTo: sendQuoteCopyTo,
                  });
                }
              }}
            >
              Send
            </Button>
          </DialogActions>
        </Dialog>
      )}

      <div
        style={{
          position: "fixed",
          bottom: "20px",
          right: "50px",
          display: "flex",
          flexDirection: "column",
          gap: 16,
        }}
      >
        {order &&
          order.id &&
          (order.orderStatus.name === "QUOTE_REQUESTED" ||
            order.orderStatus.name === "SAVED") &&
          !order.estimatePdf && (
            <Fab
              disabled={isLoading}
              variant="extended"
              color="success"
              onClick={() => {
                setCustomTabValue(1);
                quoteTabRef.current.scrollIntoView({
                  behavior: "smooth",
                  block: "nearest",
                  inline: "start",
                });
              }}
            >
              Next: Create Quote PDF
            </Fab>
          )}
        {order &&
          order.id &&
          (order.orderStatus.name === "QUOTE_REQUESTED" ||
            order.orderStatus.name === "SAVED") &&
          order.estimatePdf &&
          order.isNewEstimateRequested && (
            <Fab
              disabled={isLoading}
              variant="extended"
              color="success"
              onClick={() => {
                setCustomTabValue(1);
                quoteTabRef.current.scrollIntoView({
                  behavior: "smooth",
                  block: "nearest",
                  inline: "start",
                });
              }}
            >
              Next: Re-Generate Quote for Renewal Request
            </Fab>
          )}
        {!order.isNewEstimateRequested &&
          order.estimatePdf &&
          order.orderStatus &&
          order.orderStatus.name === "QUOTE_REQUESTED" && (
            //order.type !== "GUEST" &&
            <Fab
              disabled={isLoading}
              variant="extended"
              color="success"
              onClick={() => {
                setFinalizeAndSendEstimateModalOpen(true);
              }}
            >
              Finalize & Send Estimate To Customer
            </Fab>
          )}

        {order.orderStatus &&
          order.orderStatus.name == "ESTIMATE_APPROVAL_NEEDED" && (
            <Fab
              disabled={isLoading}
              variant="extended"
              color="success"
              onClick={() => {
                setFinalizeAndSendEstimateModalOpen(true);
              }}
            >
              Re-Send Estimate To Customer
            </Fab>
          )}

        {order.orderStatus &&
          order.orderStatus.name == "ESTIMATE_APPROVAL_NEEDED" && (
            <Fab
              disabled={isLoading}
              variant="extended"
              color="success"
              onClick={() => {
                updateOrderStatus("QUOTE_REQUESTED", null);
              }}
            >
              Re-Open Quote
            </Fab>
          )}

        {order.orderStatus &&
          order.estimatePdf &&
          order.orderStatus &&
          order.orderStatus.name == "ESTIMATE_APPROVAL_NEEDED" && (
            <Fab
              disabled={isLoading || order.isUnconfirmedCutlist}
              variant="extended"
              color="success"
              onClick={() => {
                setSkipCustomerApprovalDialogOpen(true);
              }}
            >
              Skip Customer Approval
              {order.isUnconfirmedCutlist ? " (FOR ESTIMATING ONLY)" : ""}
            </Fab>
          )}
        <SkipCustomerApprovalDialog
          open={skipCustomerApprovalDialogOpen}
          handleClose={() => {
            setSkipCustomerApprovalDialogOpen(false);
          }}
          onConfirm={() => {
            updateOrderStatus("ESTIMATE_APPROVED", null);
          }}
        />

        {order.orderStatus &&
          (order.orderStatus.name === "ESTIMATE_APPROVED" ||
            order.orderStatus.name === "IN_PROGRESS" || // Allow adding an invoice if this was skipped
            order.orderStatus.name === "READY_FOR_PICKUP" ||
            order.orderStatus.name === "CLOSED") &&
          !order.invoiceId && (
            <Fab
              disabled={isLoading}
              variant="extended"
              color="success"
              onClick={() => {
                setCustomTabValue(3);
                invoiceTabRef.current.scrollIntoView({
                  behavior: "smooth",
                  block: "nearest",
                  inline: "start",
                });
              }}
            >
              Next: Create Invoice
            </Fab>
          )}

        {order.orderStatus &&
          order.orderStatus.name === "ESTIMATE_APPROVED" &&
          order.invoiceId && (
            <Fab
              disabled={isLoading}
              variant="extended"
              color="success"
              onClick={() => {
                updateOrderStatus("PENDING_PAYMENT");
              }}
            >
              Update Order Status To "Pending Payment"
            </Fab>
          )}

        {order.orderStatus &&
          !order.isPaid &&
          (order.orderStatus.name === "IN_PROGRESS" || // Allow adding an invoice if this was skipped
            order.orderStatus.name === "READY_FOR_PICKUP" ||
            order.orderStatus.name === "CLOSED") &&
          order.invoiceId && (
            <Fab
              disabled={isLoading}
              variant="extended"
              color="success"
              onClick={() => {
                // updateOrderStatus("PENDING_PAYMENT");
                updateOrder({ id: order.id, isPaid: true });
              }}
            >
              Mark as Paid
            </Fab>
          )}

        {order.orderStatus &&
          order.orderStatus.name === "PENDING_PAYMENT" &&
          order.invoiceId && (
            <Fab
              disabled={isLoading}
              variant="extended"
              color="success"
              onClick={() => {
                updateOrderStatus("IN_PROGRESS");
              }}
            >
              Manually Update Order Status To "In-Progress"
            </Fab>
          )}

        {order.orderStatus &&
          (order.orderStatus.name === "ESTIMATE_APPROVED" ||
            order.orderStatus.name === "PENDING_PAYMENT") && (
            <Fab
              disabled={isLoading}
              variant="extended"
              color="success"
              onClick={() => {
                setSkipInvoiceAndPaymentDialogOpen(true);
              }}
            >
              Skip Invoice & Payment - In-Progress
            </Fab>
          )}
        <SkipInvoiceAndPaymentDialog
          open={skipInvoiceAndPaymentDialogOpen}
          handleClose={() => {
            setSkipInvoiceAndPaymentDialogOpen(false);
          }}
          onConfirm={() => {
            updateOrderStatus("IN_PROGRESS");
          }}
        />
        {order.orderStatus &&
          order.orderStatus.name === "IN_PROGRESS" &&
          // order.invoiceId &&
          order.materialStatus != "Material Rec'd - Full" &&
          order.materialStatus != "Material in Inventory" && (
            <Fab
              disabled={isLoading}
              variant="extended"
              color="success"
              onClick={() => {
                setCustomTabValue(2);
                materialNeededTabRef.current.scrollIntoView({
                  behavior: "smooth",
                  block: "nearest",
                  inline: "start",
                });
              }}
            >
              Next: Order / Update Materials
            </Fab>
          )}
        {order.orderStatus &&
          order.orderStatus.name === "IN_PROGRESS" &&
          // order.invoiceId &&
          order.shopStatus != "Done" && (
            <Fab
              disabled={isLoading}
              variant="extended"
              color="success"
              onClick={() => {
                setCustomTabValue(4);
                orderProgressStatusTabRef.current.scrollIntoView({
                  behavior: "smooth",
                  block: "nearest",
                  inline: "start",
                });
              }}
            >
              Next: Update Order Progress
            </Fab>
          )}
        {order.orderStatus &&
          order.orderStatus.name === "IN_PROGRESS" &&
          // order.invoiceId &&
          order.shopStatus == "Done" && (
            <Fab
              disabled={isLoading}
              variant="extended"
              color="success"
              onClick={() => {
                updateOrderStatus("READY_FOR_PICKUP");
              }}
            >
              Ready for Pick Up
            </Fab>
          )}
        {order.orderStatus &&
          order.orderStatus.name === "READY_FOR_PICKUP" &&
          order.pickUpStatus != "Picked Up - 100%" &&
          order.pickUpStatus != "Delivered - 100%" && (
            // && order.invoiceId
            <Fab
              disabled={isLoading}
              variant="extended"
              color="success"
              onClick={() => {
                setCustomTabValue(4);
                pickupStatusTabRef.current.scrollIntoView({
                  behavior: "smooth",
                  block: "nearest",
                  inline: "start",
                });
              }}
            >
              Next: Update Pick Up / Deliver Status
            </Fab>
          )}
        {order.orderStatus &&
          order.orderStatus.name == "READY_FOR_PICKUP" &&
          (order.pickUpStatus == "Picked Up - 100%" ||
            order.pickUpStatus == "Delivered - 100%") && (
            // order.orderStatus.name !== "CLOSED" &&
            // order.orderStatus.name !== "CANCELLED"
            <Fab
              onClick={() => {
                updateOrderStatus("CLOSED");
              }}
              variant="extended"
              color="success"
              disabled={isLoading}
            >
              <SaveIcon />
              Close Order
            </Fab>
          )}

        {(!order.orderStatus ||
          (order.orderStatus &&
            order.orderStatus.name == "QUOTE_REQUESTED")) && (
          // order.orderStatus.name !== "CLOSED" &&
          // order.orderStatus.name !== "CANCELLED"
          <Fab
            onClick={handleSubmit}
            variant="extended"
            color="primary"
            disabled={isLoading}
          >
            <SaveIcon />
            Save Order
          </Fab>
        )}
        {(!order.orderStatus ||
          (order.orderStatus &&
            order.orderStatus.name !== "CLOSED" &&
            order.orderStatus.name !== "CANCELLED")) && (
          <Fab
            onClick={() => {
              // updateOrderStatus("CANCELLED");
              setCancelOrderDialogOpen(true);
            }}
            variant="extended"
            color="error"
            disabled={isLoading}
          >
            <Cancel />
            Cancel Order
          </Fab>
        )}
        <CancelOrderDialog
          open={cancelOrderDialogOpen}
          handleClose={() => {
            setCancelOrderDialogOpen(false);
          }}
          onConfirm={() => {
            updateOrderStatus("CANCELLED");
          }}
        />
      </div>
    </>
  );
};
export default withLoading(View);
