import { Box, Card, Typography } from "@mui/material";
import moment from "moment/moment";

const FabricatorNotes = ({ shopNotes }) => {
  return (
    <Box>
      {shopNotes &&
        shopNotes.map((note, index) => {
          let createdAt = moment(new Date(note.createdAt)).local();
          let weekOld = moment(new Date(createdAt)).local();
          weekOld.add(7, "d");
          let isWeekOld = moment() > weekOld;

          return (
            <Card key={index} style={{ padding: 16, marginTop: 8 }}>
              <Box style={{ display: "flex", flexDirection: "row" }}>
                {note.user ? (
                  <Typography style={{ marginRight: 8, fontWeight: "bold" }}>
                    {note.user.fullName}
                  </Typography>
                ) : (
                  <Typography style={{ marginRight: 8, fontWeight: "bold" }}>
                    {note.guestName}
                  </Typography>
                )}
                <Typography>
                  {isWeekOld
                    ? createdAt.format("MM/DD/YYYY")
                    : createdAt.fromNow()}
                </Typography>
              </Box>
              <Typography style={{ marginTop: 8 }}>{note.text}</Typography>
            </Card>
          );
        })}
    </Box>
  );
};

export default FabricatorNotes;
