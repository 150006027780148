import { Box, CircularProgress, Typography, Backdrop } from "@mui/material";

const withLoading = (Component) => (props) => {
  const { loading } = props;

  return (
    <>
      {loading ? (
        // <Box
        //   sx={{
        //     display: "flex",
        //     flexDirection: "column",
        //     justifyContent: "center",
        //     alignItems: "center",
        //     marginTop: "13rem",
        //   }}
        // >
        //   <CircularProgress color="inherit" />
        //   <Typography variant="overline" textAlign={"center"}>
        //     loading
        //   </Typography>
        // </Box>
        <>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <Component {...props} />
        </>
      ) : (
        <Component {...props} />
      )}
    </>
  );
};
export default withLoading;
