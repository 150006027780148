import { useState, useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { CircularProgress, MenuItem } from "@mui/material";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useFormik } from "formik";
import * as Yup from "yup";
import * as yup from "yup";
import { LoadingButton } from "@mui/lab";
import { SnackAlertContext } from "../../../context/SnackAlertContext";

export default function Login() {
  let navigate = useNavigate();
  const { authToken, ezorder } = useContext(AuthContext);
  const [inValidRole, setInvalidRole] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const { openSnackMessage } = useContext(SnackAlertContext);

  const createNewEmployee = async (requestBody) => {
    setIsLoading(true);
    // console.log(requestBody);

    try {
      const response = await ezorder.post(`/users`, requestBody, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      navigate(`/employees`);
    } catch (error) {
      console.log(error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage("error", error.response.data.error);
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&?.,*])(?=.{8,})/;
  const logInValidationSchema = Yup.object({
    fullName: Yup.string().required("Employee Name is Required"),
    email: Yup.string()
      .email("Please enter valid email")
      .required("Email Address is Required"),
    password: Yup.string()
      .required("Password Required")
      .max(
        20,
        "Password Must Contain between 8-20 Characters, One Uppercase, One Lowercase, One Number and One Special Character"
      )
      .matches(
        passwordRegex,
        "Password Must Contain between 8-20 Characters, One Uppercase, One Lowercase, One Number and One Special Character"
      ),
    role: yup.string().required("Role is required"),
  });

  const formik = useFormik({
    initialValues: {
      fullName: "",
      email: "",
      phone: "",
      role: "EZORDER_EMPLOYEE",
      jobTitle: "",
      password: "",
    },
    validationSchema: logInValidationSchema,
    onSubmit: (values) => {
      createNewEmployee(values);
    },
  });

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          Add New Employee
        </Typography>
        <Box
          component="form"
          noValidate
          sx={{ mt: 1 }}
          onSubmit={formik.handleSubmit}
        >
          <TextField
            margin="normal"
            required
            fullWidth
            id="fullName"
            label="Full Name"
            name="fullName"
            value={formik.values.fullName}
            onChange={formik.handleChange}
            autoComplete="name"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            autoComplete="email"
          />
          <TextField
            margin="normal"
            fullWidth
            id="jobTitle"
            label="Job Title"
            name="jobTitle"
            value={formik.values.jobTitle}
            onChange={formik.handleChange}
          />
          <TextField
            margin="normal"
            fullWidth
            label="Phone"
            name="phone"
            value={formik.values.phone}
            onChange={formik.handleChange("phone")}
          />
          <TextField
            margin="normal"
            select
            fullWidth
            required
            label="App Role"
            value={formik.values.role}
            onChange={formik.handleChange("role")}
          >
            <MenuItem value="EZORDER_ADMIN">Admin</MenuItem>
            <MenuItem value="EZORDER_EMPLOYEE">Shop</MenuItem>
          </TextField>

          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            autoComplete="current-password"
          />

          {errorMsg && (
            <Box>
              <Typography color={"red"}>{errorMsg}</Typography>
            </Box>
          )}
          {formik.errors.fullName && (
            <Box>
              <Typography color={"red"}>{formik.errors.fullName}</Typography>
            </Box>
          )}
          {formik.errors.email && (
            <Box>
              <Typography color={"red"}>{formik.errors.email}</Typography>
            </Box>
          )}
          {formik.errors.password && (
            <Box>
              <Typography color={"red"}>{formik.errors.password}</Typography>
            </Box>
          )}
          <Box sx={{ m: 1, position: "relative" }}>
            <LoadingButton
              loading={isLoading}
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={isLoading}
            >
              Save
            </LoadingButton>
            {isLoading && (
              <CircularProgress
                size={24}
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )}
          </Box>
        </Box>
        {inValidRole && (
          <Box sx={{ color: "#cf0404" }}>
            You Are Not Authorized To Visit This Page.
          </Box>
        )}
      </Box>
    </Container>
  );
}
