import {
  Box,
  Button,
  Typography,
  Modal,
  TextField,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Radio,
} from "@mui/material";
import UploadIcon from "@mui/icons-material/Upload";
import { useTheme } from "@emotion/react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

const AddTaxFormView = ({
  handleFormChange,
  handleDate,
  formObject,
  open,
  handleOpen,
  handleClose,
  handleSaveTaxForm,
  handleFileUpload,
  loading,
}) => {
  const theme = useTheme();
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "20rem",
            background: theme.palette.secondary.main,
            boxShadow: 24,
            p: 4,
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
            <Button
              variant="contained"
              component="label"
              onChange={handleFormChange("fileBlob")}
            >
              <UploadIcon sx={{ mr: "1rem" }} />
              Upload Tax Form
              <input hidden accept="image/*,.pdf" type="file" />
            </Button>
            {formObject.fileBlob && (
              <Typography>{formObject.fileBlob.name}</Typography>
            )}
            {/* <FormGroup
              sx={{
                marginLeft: "1rem",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
              }}
            >
              <FormControlLabel
                control={
                  <Radio
                    checked={formObject.type === "PER-PROJECT"}
                    onChange={handleFormChange("type")}
                    name="PER-PROJECT"
                  />
                }
                label={"Per Project"}
              />
              <FormControlLabel
                control={
                  <Radio
                    checked={formObject.type === "ANNUAL"}
                    onChange={handleFormChange("type")}
                    name="ANNUAL"
                  />
                }
                label={"Annual"}
              />
            </FormGroup> */}
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                disabled={loading}
                label="Expiration Date"
                value={formObject.expirationDate}
                onChange={(newValue) => {
                  handleDate(newValue);
                }}
                renderInput={(params) => (
                  <TextField fullWidth sx={{}} {...params} />
                )}
              />
            </LocalizationProvider>

            <TextField label="Form Name" onChange={handleFormChange("name")} />

            <Button
              variant="contained"
              onClick={() => {
                const { fileBlob, name, type } = formObject;
                if (!name || !type || !fileBlob) return;
                handleSaveTaxForm(fileBlob, name, type);
              }}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
export default AddTaxFormView;
