import { useTheme } from "@emotion/react";
import {
  Typography,
  Stack,
  TextareaAutosize,
  Paper,
  TextField,
  MenuItem,
  Checkbox,
  Box,
  Button,
  Grid,
  Card,
} from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import ShopS3PresignedCardImage from "../../../../components/ShopS3PresignedCardImage";
import { CameraAlt, Close, Upload } from "@mui/icons-material";
import { SnackAlertContext } from "../../../../context/SnackAlertContext";
import { AuthContext } from "../../../../context/AuthContext";
import moment from "moment/moment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LoadingButton } from "@mui/lab";
import useShopNotes from "../../../../hooks/useShopNotes";
import FabricatorNotes from "../../../../pages/shop/Orders/FabricatorNotes";

const OrderProgressStatus = ({
  order,
  status = "Pending",
  onUpdateEstimatedCompletionDate,
  onChangeStatus,
  onUpdateImages,
  disableChangeStatus = false,
  isLoading,
}) => {
  const theme = useTheme();
  const { openSnackMessage } = useContext(SnackAlertContext);
  const { authToken, ezorder } = useContext(AuthContext);
  const [progressStatus, setProgressStatus] = useState(status);
  const [progressNotes, setProgressNotes] = useState("");
  const [loading, setLoading] = useState(false);
  const { shopNotes, addShopNote, isLoadingNotes } = useShopNotes(
    authToken,
    order.id,
    false
  );

  const [isCompletionTBD, setIsCompletionTBD] = useState(
    order.shopEstimateCompletionDate ? false : true
  );
  const [shopEstimateCompletionDate, setShopEstimateCompletionDate] = useState(
    order.shopEstimateCompletionDate
      ? moment(order.shopEstimateCompletionDate)
      : moment()
  );

  const uploadRef = useRef();
  const cameraRef = useRef();
  const [shopData, setShopData] = useState({
    savedImages: [...order.shopImages],
    newImageBlobs: [],
    newImageLocalUrls: [],
  });

  useEffect(() => {
    console.log("shopData", shopData);
  }, [shopData]);

  return (
    <Stack spacing={3} direction="column">
      <Paper sx={{ padding: "16px" }}>
        <Typography variant="h6" sx={{ mb: "15px" }}>
          Order Progress / Shop Status
        </Typography>

        <Typography sx={{ fontWeight: "bold", marginTop: "16px" }}>
          Shop Due Date / Estimated Completion Date
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "row", marginTop: "8px" }}>
          <Box sx={{ flex: 1, display: "flex", flexDirection: "row" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                magin: 0,
                padding: 0,
              }}
            >
              <Checkbox
                style={{
                  magin: 0,
                  padding: 0,
                }}
                checked={isCompletionTBD}
                onChange={(e) => {
                  setIsCompletionTBD(e.target.checked);
                }}
              />
              <Typography>TBD</Typography>
            </div>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              {!isCompletionTBD && (
                <DatePicker
                  disabled={isLoading}
                  label="Estimated Completion Date"
                  value={shopEstimateCompletionDate}
                  onChange={(newValue) => {
                    setShopEstimateCompletionDate(new Date(newValue));
                  }}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      fullWidth
                      sx={{ marginTop: "8px", marginLeft: "16px" }}
                      {...params}
                    />
                  )}
                />
              )}
            </LocalizationProvider>
          </Box>
          <LoadingButton
            size="small"
            sx={{ marginLeft: "16px" }}
            variant="contained"
            loading={isLoading}
            onClick={() => {
              if (isCompletionTBD) {
                onUpdateEstimatedCompletionDate(null);
              } else {
                onUpdateEstimatedCompletionDate(
                  new Date(shopEstimateCompletionDate)
                );
              }
            }}
          >
            Update Est. Completion Date
          </LoadingButton>
        </Box>

        <Typography sx={{ marginTop: "16px", fontWeight: "bold" }}>
          Progress Status
        </Typography>
        <TextField
          sx={{ marginTop: "16px" }}
          disabled={disableChangeStatus}
          select
          variant="outlined"
          label="Progress Status"
          name="Progress Status"
          size="small"
          fullWidth
          value={progressStatus}
          onChange={(e) => setProgressStatus(e.target.value)}
        >
          <MenuItem key={"Pending"} value={"Pending"}>
            Pending
          </MenuItem>
          <MenuItem value={"In-Progress"}>In-Progress</MenuItem>
          <MenuItem value={"Partially Complete"}>Partially Complete</MenuItem>
          <MenuItem value={"100% Complete"}>100% Complete</MenuItem>
        </TextField>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "8px",
          }}
        >
          <LoadingButton
            loading={isLoading}
            disabled={disableChangeStatus}
            variant="contained"
            onClick={() => {
              onChangeStatus(progressStatus);
            }}
          >
            Update Status
          </LoadingButton>
        </div>
        <Typography sx={{ marginTop: "16px" }}>
          Add images of the finished order for the customer
        </Typography>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <label>
            <input
              ref={uploadRef}
              style={{ display: "none" }}
              type="file"
              accept="image/*"
              // capture="environment"
              onChange={(event) => {
                if (shopData.newImageBlobs.length >= 15) {
                  alert("Maximum 15 images");
                } else {
                  console.log("FILE: ", event.target.files[0]);
                  console.log(
                    "Image",
                    URL.createObjectURL(event.target.files[0])
                  );
                  const file = event.target.files[0];
                  setShopData((prev) => ({
                    ...prev,
                    newImageBlobs: [...prev.newImageBlobs, file],
                    newImageLocalUrls: [
                      ...prev.newImageLocalUrls,
                      URL.createObjectURL(event.target.files[0]),
                    ],
                  }));
                }
              }}
            />
            <div
              style={{
                cursor: "pointer",
                width: "100%",
                padding: 12,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  borderStyle: "solid",
                  borderWidth: 1,
                  borderColor: "#d2d2d2",
                  color: "#d2d2d2",
                  width: 100,
                  height: 100,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Upload sx={{ fontSize: 40 }} />
              </div>
            </div>
          </label>
          <label>
            <input
              ref={cameraRef}
              style={{ display: "none" }}
              type="file"
              accept="image/*"
              capture="environment"
              onChange={(event) => {
                if (shopData.newImageBlobs.length >= 15) {
                  alert("Maximum 15 images");
                } else {
                  console.log("FILE: ", event.target.files[0]);
                  console.log(
                    "Image",
                    URL.createObjectURL(event.target.files[0])
                  );
                  const file = event.target.files[0];
                  setShopData((prev) => ({
                    ...prev,
                    newImageBlobs: [...prev.newImageBlobs, file],
                    newImageLocalUrls: [
                      ...prev.newImageLocalUrls,
                      URL.createObjectURL(event.target.files[0]),
                    ],
                  }));
                }
              }}
            />
            <div
              style={{
                cursor: "pointer",
                width: "100%",
                padding: 12,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  borderStyle: "solid",
                  borderWidth: 1,
                  borderColor: "#d2d2d2",
                  color: "#d2d2d2",
                  width: 100,
                  height: 100,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CameraAlt sx={{ fontSize: 40 }} />
              </div>
            </div>
          </label>
        </div>
        <Grid
          container
          padding={0}
          spacing={0}
          alignItems="center"
          justifyContent="center"
        >
          {
            //Saved Images
            shopData.savedImages.map((s3Key, index) => (
              <Grid
                item
                key={index}
                style={{
                  position: "relative",
                  margin: 0,
                  padding: 0,
                }}
              >
                <ShopS3PresignedCardImage
                  key={index}
                  width={100}
                  s3Key={s3Key}
                  style={{
                    cursor: "pointer",
                  }}
                />
                <Close
                  color="error"
                  style={{
                    position: "absolute",
                    top: 8,
                    right: 8,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    let imagesCopy = shopData.savedImages;
                    imagesCopy.splice(index, 1);
                    setShopData((prev) => ({
                      ...prev,
                      savedImages: imagesCopy,
                    }));
                  }}
                />
              </Grid>
            ))
          }
          {
            //Unsaved Images
            shopData.newImageLocalUrls.map((url, index) => (
              <Grid
                item
                key={index}
                style={{
                  position: "relative",
                  margin: 0,
                  padding: 0,
                }}
              >
                <img
                  src={url}
                  style={{
                    minHeight: "100px",
                    maxHeight: "100px",
                    minWidth: "100px",
                    maxWidth: "100px",
                  }}
                />
                <Close
                  color="error"
                  style={{
                    position: "absolute",
                    top: 8,
                    right: 8,
                  }}
                  onClick={() => {
                    // Remove unsaved image from imageBlobs and imageUrls arrays
                    let imageBlobsCopy = shopData.newImageBlobs;
                    let imageUrlsCopy = shopData.newImageLocalUrls;
                    imageBlobsCopy.splice(index, 1);
                    imageUrlsCopy.splice(index, 1);
                    setShopData((prev) => ({
                      ...prev,
                      newImageBlobs: imageBlobsCopy,
                      newImageLocalUrls: imageUrlsCopy,
                    }));
                  }}
                />
              </Grid>
            ))
          }
        </Grid>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "8px",
          }}
        >
          <Button
            variant="contained"
            onClick={() => {
              onUpdateImages(shopData.savedImages, shopData.newImageBlobs);
            }}
          >
            Update Images
          </Button>
        </div>
        <Typography sx={{ marginTop: "16px" }}>
          Notes (For Customer and Shop)
        </Typography>
        <TextareaAutosize
          style={{
            marginTop: "0px",
            background: theme.palette.secondary.main,
            color: "#fff",
            width: "100%",
          }}
          fullWidth
          minRows={5}
          value={progressNotes}
          onChange={(e) => {
            setProgressNotes(e.target.value);
          }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "8px",
          }}
        >
          <LoadingButton
            loading={isLoadingNotes}
            variant="contained"
            onClick={async () => {
              // onAddNotes(progressNotes);
              await addShopNote(progressNotes, false);
              setProgressNotes("");
            }}
          >
            Add Notes
          </LoadingButton>
        </div>
        <FabricatorNotes shopNotes={shopNotes} />
      </Paper>
    </Stack>
  );
};
export default OrderProgressStatus;
