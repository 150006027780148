import { TableCell, TableRow } from "@mui/material";
import { useTheme } from "@emotion/react";

const OrderTableRow = ({ onClick, order }) => {
  const theme = useTheme();

  return (
    <TableRow
      hover
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      onClick={onClick}
    >
      <TableCell>{order.id}</TableCell>
      <TableCell>{order.poNumber}</TableCell>
      <TableCell>
        {order.company && order.company.name ? order.company.name : "GUEST"}
      </TableCell>
      <TableCell>
        {order.project && order.project.name ? order.project.name : "N/A"}
      </TableCell>
      <TableCell>{order.orderStatus.description}</TableCell>
    </TableRow>
  );
};

export default OrderTableRow;
