import { useContext, useEffect, useState } from "react";
import * as yup from "yup";
import {
  Box,
  Button,
  Typography,
  Modal,
  TextField,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormLabel,
  RadioGroup,
  Radio,
} from "@mui/material";

import { useNavigate, useParams } from "react-router-dom";
import { SnackAlertContext } from "../../../context/SnackAlertContext";
import { useFormik } from "formik";
import { LoadingButton } from "@mui/lab";

const IndustryEmployeeForm = ({ userDetails, handleSubmit, loading }) => {
  const navigate = useNavigate();
  const { openSnackMessage } = useContext(SnackAlertContext);
  const industryEmployeeValidationSchema = yup.object().shape({
    fullName: yup.string().required("Name is required"),
    email: yup.string().email().required("Email is required"),
    role: yup.string().required("Role is required"),
  });

  const formikProps = useFormik({
    enableReinitialize: true,
    initialValues: {
      fullName: userDetails && userDetails.fullName ? userDetails.fullName : "",
      email: userDetails && userDetails.email ? userDetails.email : "",
      jobTitle: userDetails && userDetails.jobTitle ? userDetails.jobTitle : "",
      phone: userDetails && userDetails.phone ? userDetails.phone : "",
      role:
        userDetails && userDetails.role ? userDetails.role : "EZORDER_EMPLOYEE",
    },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: industryEmployeeValidationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  return (
    <>
      <TextField
        margin="normal"
        required
        fullWidth
        disabled={loading}
        label="Full Name"
        value={formikProps.values.fullName}
        onChange={formikProps.handleChange("fullName")}
      />
      <TextField
        margin="normal"
        required
        fullWidth
        disabled={loading}
        // disabled={(userDetails != undefined && userDetails != null) || loading}
        label="Email"
        value={formikProps.values.email}
        onChange={formikProps.handleChange("email")}
      />
      <TextField
        margin="normal"
        fullWidth
        id="jobTitle"
        label="Job Title"
        name="jobTitle"
        value={formikProps.values.jobTitle}
        onChange={formikProps.handleChange("jobTitle")}
      />
      <TextField
        margin="normal"
        fullWidth
        disabled={loading}
        label="Phone"
        value={formikProps.values.phone}
        onChange={formikProps.handleChange("phone")}
      />

      <TextField
        margin="normal"
        required
        fullWidth
        select
        label="Role"
        value={formikProps.values.role}
        onChange={formikProps.handleChange("role")}
      >
        <MenuItem value="EZORDER_ADMIN">Admin</MenuItem>
        <MenuItem value="EZORDER_EMPLOYEE">Shop</MenuItem>
      </TextField>

      <LoadingButton
        fullWidth
        loading={loading}
        variant="contained"
        onClick={() => formikProps.handleSubmit()}
      >
        {userDetails ? "Update" : "Create"}
      </LoadingButton>
    </>
  );
};

export default IndustryEmployeeForm;
