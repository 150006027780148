import CollapsableCard from "../../../../CollapseableCard";
import LineItem from "./LineItem";
import LineItemInput from "./LineItemInput";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import useEditInvoice from "../../../../../hooks/useEditInvoice";
import {
  Button,
  Box,
  Typography,
  Link,
  ListItem,
  ListItemText,
  FormHelperText,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../../context/AuthContext";
import moment from "moment";
import QbCustomers from "./QbCustomers";
import { toTwoDecimals } from "../../../calculations/utils";
import ExistingInvoices from "./ExistingInvoices";

const Invoice = ({ order, action, updateOrder }) => {
  const {
    lineItems,
    addLineItem,
    newLineItem,
    handleNewLineItemChange,
    serviceOptions,
    submitInvoice,
    removeLineItem,
    isSubmitting,
  } = useEditInvoice(order.id);
  // const { invoices } = useQuickBooksInvoices();
  const { authToken, ezorder } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [invoiceLink, setInvoiceLink] = useState("");
  const [invoice, setInvoice] = useState(null);

  const createInvoiceBody = (qbCustomerId, lineItems) => {
    const items = lineItems.map((item) => ({
      DetailType: "SalesItemLineDetail",
      Amount: parseFloat(parseFloat(item.amount).toFixed(1)),
      Description: item.description,
      SalesItemLineDetail: {
        Qty: parseFloat(parseFloat(item.qty).toFixed(1)),
        ServiceDate: moment(item.serviceDate).format(),
        ItemRef: {
          name: item.service.Name,
          value: item.service.Id,
        },
        TaxCodeRef: {
          value: item.isTaxed ? "TAX" : "NON",
        },
      },
    }));
    return { Line: [...items], CustomerRef: { value: `${qbCustomerId}` } };
  };

  const handleSubmit = () => {
    const invoiceData = createInvoiceBody(order.qbCustomerId, lineItems);
    let body = {
      invoiceData,
      orderId: order.id,
    };
    console.log(body);
    submitInvoice(body);
  };

  const getInvoiceDetails = async (controller) => {
    try {
      setLoading(true);
      let data = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      if (controller) {
        data.signal = controller.signal;
      }

      const response = await ezorder.get(
        `/admin/orders/${order.id}/invoice`,
        data
      );

      setInvoice(response.data.invoice);
      setInvoiceLink(response.data.invoice.InvoiceLink);
    } catch (error) {
      console.log("Failed To Load Invoice for Order", order.id);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // console.log("OrderTableRow useEffect()");
    const controller = new AbortController();
    if (order.invoiceId) {
      getInvoiceDetails(controller);
    }

    return () => {
      controller.abort(); // abort api call on unmount for cleanup
    };
  }, [order.invoiceId]);

  if (order.invoiceId && order.invoiceCustomerRefName) {
    return (
      <Box>
        <CollapsableCard title="Invoice">
          <Box>
            {invoice ? (
              <Box>
                <Typography variant="h5">Details</Typography>
                Subcustomer:{" "}
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  href={invoice.InvoiceLink}
                >
                  {invoice.CustomerRef.name}
                </Link>
                <Typography>Invoice #{invoice.DocNumber}</Typography>
                <Typography>Invoice Date: {invoice.TxnDate}</Typography>
                <Typography>Due Date: {invoice.DueDate}</Typography>
                <Typography>
                  Balance: ${toTwoDecimals(invoice.Balance)}
                </Typography>
                <Typography>Email Status: {invoice.EmailStatus}</Typography>
                <Typography>Bill Email: {invoice.BillEmail.Address}</Typography>
                {invoice.BillAddr && (
                  <Paper sx={{ padding: "8px", width: "400px" }}>
                    <Typography sx={{ fontWeight: "bold" }}>
                      Billing Address
                    </Typography>
                    <Typography>{invoice.BillAddr.Line1}</Typography>
                    <Typography>{invoice.BillAddr.Line2}</Typography>
                    <Typography>
                      {invoice.BillAddr.City},{" "}
                      {invoice.BillAddr.CountrySubDivisionCode}{" "}
                      {invoice.BillAddr.PostalCode}
                    </Typography>
                  </Paper>
                )}
              </Box>
            ) : (
              <Typography>
                Could not find invoice details on quickbooks. Please check that
                this invoice was not voided or deleted on Quickbooks
              </Typography>
            )}
            Link <a href={invoiceLink}>{order.invoiceCustomerRefName}</a>
          </Box>
        </CollapsableCard>{" "}
        <Box sx={{ marginTop: "32px" }}>
          <CollapsableCard
            title="Change Assiged Invoice For this Order"
            defaultOpen={false}
          >
            <FormHelperText>
              Make sure the sub-customer reference name is unique. The same
              invoice cannot be used for multiple orders
            </FormHelperText>
            <ExistingInvoices orderId={order.id} updateOrder={updateOrder} />
          </CollapsableCard>
        </Box>
      </Box>
    );
  }

  if (
    order.orderStatus.name === "ESTIMATE_APPROVED" ||
    // order.orderStatus.name === "PENDING_PAYMENT" ||
    order.orderStatus.name === "IN_PROGRESS" || // Allow adding an invoice if this was skipped
    order.orderStatus.name === "READY_FOR_PICKUP" ||
    order.orderStatus.name === "CLOSED"
  ) {
    return (
      <Box>
        <Box>
          <Typography variant="h5">Create an Invoice for this Order</Typography>
          <Box sx={{ padding: "32px" }}>
            <QbCustomers qbCustomerId={order.qbCustomerId} action={action} />
            {order.qbCustomerId && (
              <CollapsableCard title={"Invoice Items"} defaultOpen={true}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>#</TableCell>
                        <TableCell sx={{ minWidth: "200px" }}>
                          SERVICE DATE
                        </TableCell>
                        <TableCell sx={{ minWidth: "120px" }}>
                          PRODUCT/SERVICE
                        </TableCell>
                        <TableCell sx={{ minWidth: "600px" }}>
                          DESCRIPTION
                        </TableCell>
                        <TableCell sx={{ minWidth: "100px" }}>QTY</TableCell>
                        <TableCell sx={{ minWidth: "150px" }}>RATE</TableCell>
                        <TableCell sx={{ minWidth: "150px" }}>AMOUNT</TableCell>
                        <TableCell>TAX</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {lineItems.map((lineItem, index) => (
                        <LineItem
                          key={index}
                          lineItem={lineItem}
                          number={index + 1}
                          removeLineItem={() => removeLineItem(index)}
                        />
                      ))}
                      <LineItemInput
                        services={serviceOptions}
                        inputData={newLineItem}
                        handleChange={handleNewLineItemChange}
                        submit={addLineItem}
                      />
                    </TableBody>
                  </Table>
                </TableContainer>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    margin: "2rem",
                  }}
                >
                  <Box
                    sx={{
                      marginLeft: "auto",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Button
                      color="success"
                      disabled={
                        lineItems.length < 1 ||
                        !order.qbCustomerId ||
                        isSubmitting
                      }
                      variant="outlined"
                      // sx={{ marginLeft: "auto" }}
                      onClick={handleSubmit}
                    >
                      {isSubmitting
                        ? "Loading..."
                        : "Create Invoice In quickbooks"}
                    </Button>
                    {!order.qbCustomerId && (
                      <Typography
                        textAlign={"center"}
                        variant="caption"
                        sx={{ color: "#c0c1c2" }}
                      >
                        Must Select QB Customer First
                      </Typography>
                    )}
                  </Box>
                </Box>
              </CollapsableCard>
            )}
          </Box>
          <Typography variant="h5">
            OR Assign An Existing Invoice to this Order
          </Typography>
          <ExistingInvoices orderId={order.id} updateOrder={updateOrder} />
        </Box>
      </Box>
    );
  } else {
    return (
      <CollapsableCard title="Invoice">
        <Typography>Requires estimate approval</Typography>
      </CollapsableCard>
    );
  }
};
export default Invoice;
