import { useState, useContext, useEffect } from "react";
import { AuthContext } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
  TextField,
  Dialog,
  useMediaQuery,
  DialogContent,
  DialogTitle,
  ListItemText,
  ListItem,
  DialogActions,
  DialogContentText,
  MenuItem,
} from "@mui/material";
import TableSearchAndFilter from "../../components/TableSearchAndFilter";
import CircularProgress from "@mui/material/CircularProgress";
import useOrdersAdmin from "../../hooks/useOrdersAdmin";
import ListFooter from "../../components/InfiniteScroller/ListFooter";
import OrderTableRow from "../../components/OrderTableRow";
import { useInView } from "react-intersection-observer";
import { useDebounce } from "use-debounce";
import InfiniteScrollerTable from "../../components/InfiniteScroller/InfiniteScrollerTable";
import { useTheme } from "@emotion/react";
import useCustomerProjectsByAdmin from "../../hooks/useCustomerProjectsByAdmin";
import useCompanies from "../../hooks/useCompanies";
import SearchBar from "../../components/SearchBar";
import InfiniteScroller from "../../components/InfiniteScroller/InfiniteScroller";
import useQuotesAdmin from "../../hooks/useQuotesAdmin";
import { AdminQuoteAndOrderCountContext } from "../../context/AdminQuoteAndOrderCountContext";
import { Add } from "@mui/icons-material";

const QuoteRequests = ({ companyId = null }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [newQuoteModalOpen, setNewQuoteModalOpen] = useState(false);
  const { refreshQuoteCount } = useContext(AdminQuoteAndOrderCountContext);

  const { authToken, ezorder } = useContext(AuthContext);
  const [
    searchQuery,
    setSearchQuery,
    orders,
    onRefresh,
    refreshing,
    onLoadMore,
    loadingMore,
    total,
    hasMore,
    statusFilter,
    setStatusFilter,
  ] = useQuotesAdmin(authToken, 50, companyId);

  const [ref, inView, entry] = useInView({
    /* Optional options */
    threshold: 0.5, //Number between 0 and 1 indicating the percentage that should be visible before triggering. Can also be an array of numbers, to create multiple trigger points.
  });

  const [inViewDebounce] = useDebounce(inView, 1000);

  const [selectedCompany, setSelectedCompany] = useState(null);
  const [
    company_searchQuery,
    company_setSearchQuery,
    company_results,
    company_onRefresh,
    company_refreshing,
    company_onLoadMore,
    company_loadingMore,
    company_total,
    company_hasMore,
  ] = useCompanies(authToken, 20);
  const [
    project_searchQuery,
    project_setSearchQuery,
    project_results,
    project_onRefresh,
    project_refreshing,
    project_onLoadMore,
    project_loadingMore,
    project_total,
    project_hasMore,
  ] = useCustomerProjectsByAdmin(authToken, 20, selectedCompany?.id);
  const [projectName, setProjectName] = useState("");
  const handleClose = () => {
    setNewQuoteModalOpen(false);
    if (companyId == null) setSelectedCompany(null);
    setProjectName("");
  };
  const [isSubmitting, setIsSubmitting] = useState("");
  const handleSubmitProject = async ({ name }) => {
    try {
      if (!name) return;
      setIsSubmitting(true);
      const result = await ezorder.post(
        `/admin/companies/${selectedCompany.id}/projects`,
        { name },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      navigate(
        `/company/${selectedCompany.id}/projects/${result.data.project.id}/orders/create`
      );
    } catch (error) {
      console.log(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const getCustomerById = async (companyId) => {
    try {
      let data = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      const res = await ezorder.get(`/admin/companies/${companyId}`, data);
      let company = res.data.company;
      setSelectedCompany(company);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    refreshQuoteCount();
  }, []);

  useEffect(() => {
    if (companyId != null) {
      getCustomerById(companyId);
    }
  }, [companyId]);

  useEffect(() => {
    if (orders.length > 0 && inView) {
      console.log("Footer Element In View. Load More...");
      onLoadMore();
    }
  }, [inViewDebounce]);

  return (
    <Box
      sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}
    >
      <Dialog
        scroll={"paper"}
        fullScreen={isSmallScreen}
        fullWidth={true}
        maxWidth={"md"}
        open={newQuoteModalOpen}
        onClose={handleClose}
      >
        <DialogTitle>Create New Quote</DialogTitle>
        <DialogContent>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
            {!selectedCompany && (
              <Box>
                <DialogContentText>Select Company</DialogContentText>
                <Box
                // sx={{
                //   minHeight: "200px",
                //   maxHeight: "300px",
                //   overflowY: "scroll",
                // }}
                >
                  <SearchBar
                    label="Search Companies"
                    searchQuery={company_searchQuery}
                    setSearchQuery={company_setSearchQuery}
                  />
                  <InfiniteScroller
                    items={company_results}
                    renderItem={(item, index) => {
                      return (
                        <ListItem
                          onClick={() => {
                            setSelectedCompany(item);
                            company_onRefresh();
                          }}
                          key={item.id}
                          sx={{
                            cursor: "pointer",
                            "&:hover": {
                              background: "#5A5A5A",
                            },
                          }}
                        >
                          <ListItemText
                            primary={item.name}
                            secondary={
                              <>
                                <Typography>
                                  Contact Name: {item.contactName}
                                </Typography>
                                <Typography>
                                  Contact Email: {item.contactEmail}
                                </Typography>
                                <Typography>
                                  Contact Phone: {item.contactPhone}
                                </Typography>
                                <Typography>
                                  Owner: {item.owner.fullName},{" "}
                                  {item.owner.email}
                                  {item.owner.phone
                                    ? `, ${item.owner.phone}`
                                    : ""}
                                </Typography>
                              </>
                            }
                          />
                        </ListItem>
                      );
                    }}
                    onRefresh={company_onRefresh}
                    onEndReached={company_onLoadMore}
                    loading={company_refreshing || company_loadingMore}
                    ListFooterComponent={() => {
                      return (
                        <ListFooter
                          hasMore={company_hasMore}
                          onClick={company_onLoadMore}
                        />
                      );
                    }}
                  />
                </Box>
              </Box>
            )}
            {selectedCompany && (
              <Box sx={{ width: "100%" }}>
                <DialogContentText>
                  Create an Order For A New Project
                </DialogContentText>
                <Box sx={{ display: "flex" }}>
                  <TextField
                    sx={{ flex: 1, marginTop: "8px" }}
                    size="small"
                    label="New Project"
                    value={projectName}
                    onChange={(e) => {
                      setProjectName(e.target.value);
                    }}
                  />
                  <Button
                    size="small"
                    sx={{ marginLeft: "16px" }}
                    variant="contained"
                    onClick={() =>
                      handleSubmitProject({
                        name: projectName,
                      })
                    }
                    disabled={isSubmitting}
                  >
                    <Add /> New Project
                  </Button>
                </Box>
              </Box>
            )}
            {selectedCompany && (
              <Box>
                <DialogContentText>
                  Or Select Project from {selectedCompany.name}
                </DialogContentText>
                <Box
                // sx={{
                //   minHeight: "200px",
                //   maxHeight: "300px",
                //   overflowY: "scroll",
                // }}
                >
                  <SearchBar
                    label="Search Projects"
                    searchQuery={project_searchQuery}
                    setSearchQuery={project_setSearchQuery}
                  />
                  <InfiniteScroller
                    items={project_results}
                    renderItem={(item, index) => {
                      return (
                        <ListItem
                          onClick={() => {
                            navigate(
                              `/company/${selectedCompany.id}/projects/${item.id}/orders/create`
                            );
                          }}
                          key={index}
                          sx={{
                            cursor: "pointer",
                            "&:hover": {
                              background: "#5A5A5A",
                            },
                          }}
                        >
                          <ListItemText primary={item.name} />
                        </ListItem>
                      );
                    }}
                    onRefresh={project_onRefresh}
                    onEndReached={project_onLoadMore}
                    loading={project_refreshing || project_loadingMore}
                    ListFooterComponent={() => {
                      return (
                        <ListFooter
                          hasMore={project_hasMore}
                          onClick={project_onLoadMore}
                        />
                      );
                    }}
                  />
                </Box>
              </Box>
            )}
          </Box>
        </DialogContent>
        {/* {selectedCompany && (
          <DialogActions
            sx={{ width: "100%", paddingLeft: "24px", paddingRight: "24px" }}
          >
            <Box sx={{ width: "100%" }}>
              <DialogContentText>
                Or Create an Order For A New Project
              </DialogContentText>
              <Box sx={{ display: "flex" }}>
                <TextField
                  sx={{ flex: 1, marginTop: "8px" }}
                  size="small"
                  label="Project"
                  value={projectName}
                  onChange={(e) => {
                    setProjectName(e.target.value);
                  }}
                />
                <Button
                  size="small"
                  sx={{ marginLeft: "16px" }}
                  variant="contained"
                  onClick={() =>
                    handleSubmitProject({
                      name: projectName,
                    })
                  }
                  disabled={isSubmitting}
                >
                  <Add /> New Project
                </Button>
              </Box>
            </Box>
          </DialogActions>
        )} */}

        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>

      <Typography variant="h4" component="div">
        Pending Quotes
      </Typography>

      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "center",
          marginBottom: "16px",
        }}
      >
        <Button
          size="large"
          style={{ height: "100%", "min-height": "54px", marginTop: "8px" }}
          color="success"
          onClick={() => {
            //navigate("/orders/create");
            setNewQuoteModalOpen(true);
          }}
          variant="outlined"
        >
          + New Quote
        </Button>
        <Box sx={{ marginLeft: "8px", flex: 1 }}>
          <SearchBar
            label="Search Orders (By Project/Vendor Customer, Sub-Project, PO #)"
            setSearchQuery={setSearchQuery}
            searchQuery={searchQuery}
            containerStyle={{ background: "blue" }}
          />
        </Box>
        <TextField
          sx={{ width: "200px", marginLeft: "8px" }}
          margin="normal"
          select
          label="Status"
          value={statusFilter}
          onChange={(e) => {
            setStatusFilter(e.target.value);
          }}
        >
          <MenuItem value={""}>All</MenuItem>
          <MenuItem value={"QUOTE_REQUESTED"}>
            Quote Requested By Customer
          </MenuItem>
          <MenuItem value={"ESTIMATE_APPROVAL_NEEDED"}>
            Quoted - Approval Needed By Customer
          </MenuItem>
        </TextField>
      </Box>
      <InfiniteScrollerTable
        TableContainerComponent={Paper}
        TableStyle={{ minWidth: "600px" }}
        TableHead={() => (
          <TableHead>
            <TableRow>
              <TableCell>Sales Rep</TableCell>
              <TableCell>ID</TableCell>
              <TableCell>PO #</TableCell>
              <TableCell>Customer Name</TableCell>
              <TableCell align="right">Project</TableCell>
              <TableCell align="right">Status</TableCell>
              <TableCell align="right">Date Created</TableCell>
              <TableCell align="right">Quote Sent</TableCell>
              {/* <TableCell align="right">Invoice Balance</TableCell>
              <TableCell align="right">Invoice Due Date</TableCell> */}
              <TableCell align="right">Out The Door Cost</TableCell>
              <TableCell align="right">PDFs</TableCell>
            </TableRow>
          </TableHead>
        )}
        items={orders}
        renderRow={(item, index) => {
          return (
            <OrderTableRow
              key={index}
              isPendingQuote={true}
              order={item}
              onSelect={(order) => {
                navigate(`/quotes/${order.id}`);
              }}
            />
          );
        }}
        onRefresh={onRefresh}
        onEndReached={onLoadMore}
        loading={refreshing || loadingMore}
        ListFooterComponent={() => {
          return <ListFooter hasMore={hasMore} onClick={onLoadMore} />;
        }}
      />
    </Box>
  );
};
export default QuoteRequests;
