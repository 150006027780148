import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import EmployeeForm from "../../customer/Employees/AddEmployee/EmployeeForm";
import { SnackAlertContext } from "../../../context/SnackAlertContext";
import { AuthContext } from "../../../context/AuthContext";
import { Box } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import DeactivateEmployeeModal from "../Employees/DeactivateEmployeeDialog";
import RestoreEmployeeModal from "../Employees/RestoreEmployeeDialog";

const EditCustomerUser = () => {
  const navigate = useNavigate();
  const { openSnackMessage } = useContext(SnackAlertContext);
  const { companyId, userId } = useParams();
  const { authToken, ezorder } = useContext(AuthContext);
  const [permissions, setPermissions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [deactivateDialogOpen, setDeactivateDialogOpen] = useState(false);
  const [restoreDialogOpen, setRestoreDialogOpen] = useState(false);

  const getPermissions = async () => {
    try {
      setLoading(true);
      const response = await ezorder.get(`/auth/permissions`);
      return response.data.permissions;
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  // get ID
  const getEmployee = async () => {
    try {
      setLoading(true);
      let data = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      const response = await ezorder.get(
        `/admin/company/${companyId}/users/${userId}`,
        data
      );
      setUserDetails(response.data.employee);

      let userPerms = response.data.employee.permissions;
      let perms = await getPermissions();
      for (const userPerm of userPerms) {
        for (let perm of perms) {
          if (perm.name == userPerm.name) {
            perm.checked = true;
          }
        }
      }
      console.log("__Employee", response.data.employee);
      console.log("__Perms", perms);
      setPermissions(perms);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleEmployeeUpdate = async (values) => {
    try {
      setLoading(true);
      let selectedPermissions = [];
      console.log("PERMISSIONS", permissions);
      for (const permission of permissions) {
        if (permission.checked) selectedPermissions.push(permission);
      }
      let userUpdate = {
        ...userDetails,
        ...values,
        permissions: selectedPermissions,
      };

      const response = await ezorder.put(
        `/admin/company/${companyId}/users/${userDetails.id}`,
        userUpdate,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      openSnackMessage("success", "User Updated");
      navigate(-1);
    } catch (error) {
      console.log(error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage("error", error.response.data.error);
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", error);
      }
    } finally {
      setLoading(false);
    }
  };

  const deactivateEmployeeAccount = async () => {
    try {
      setLoading(true);

      const response = await ezorder.delete(`/users/${userDetails.id}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      if (response.status == 200 || response.status == 201) {
        openSnackMessage("success", "User Account Deactivated");
        navigate(`/customers/${userDetails.company.id}`);
      } else {
        openSnackMessage("error", response.response.data.error);
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage("error", error.response.data.error);
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", error);
      }
    } finally {
      setLoading(false);
    }
  };

  const restoreEmployeeAccount = async () => {
    try {
      setLoading(true);

      const response = await ezorder.put(
        `/users/${userDetails.id}/restore`,
        {},
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      openSnackMessage("success", "User Account Restored");
      navigate(`/customers/${userDetails.company.id}`);
    } catch (error) {
      console.log(error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage("error", error.response.data.error);
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", error);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getEmployee();
    // getPermissions();
  }, [companyId, userId]);

  if (userDetails && permissions && permissions.length > 0) {
    return (
      <>
        <EmployeeForm
          protectEmail={true}
          isVendorAccount={userDetails.company.isVendorOrSupplier}
          userDetails={userDetails}
          permissions={permissions}
          setPermissions={setPermissions}
          loading={loading}
          handleSubmit={(values) => {
            handleEmployeeUpdate(values);
          }}
          onBack={() => {
            navigate(-1);
          }}
        />
        {userDetails.role != "OWNER" && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {userDetails.deletedAt ? (
              <LoadingButton
                color="success"
                onClick={() => {
                  setRestoreDialogOpen(true);
                }}
              >
                Restore Account
              </LoadingButton>
            ) : (
              <LoadingButton
                color="error"
                onClick={() => {
                  setDeactivateDialogOpen(true);
                }}
              >
                Deactivate Account
              </LoadingButton>
            )}
          </Box>
        )}

        <DeactivateEmployeeModal
          open={deactivateDialogOpen}
          handleClose={() => {
            setDeactivateDialogOpen(!deactivateDialogOpen);
          }}
          loading={loading}
          onConfirm={() => {
            deactivateEmployeeAccount();
          }}
        />
        <RestoreEmployeeModal
          open={restoreDialogOpen}
          handleClose={() => {
            setRestoreDialogOpen(!restoreDialogOpen);
          }}
          loading={loading}
          onConfirm={() => {
            restoreEmployeeAccount();
          }}
        />
      </>
    );
  }
};

export default EditCustomerUser;
