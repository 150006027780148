import { useTheme } from "@emotion/react";
import {
  Typography,
  Stack,
  TextareaAutosize,
  Paper,
  TextField,
  MenuItem,
  Checkbox,
  Box,
  Button,
  Card,
  Grid,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import CollapsableCard from "../../../CollapseableCard";
import { CameraAlt, Close, Upload } from "@mui/icons-material";
import ShopS3PresignedCardImage from "../../../ShopS3PresignedCardImage";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment/moment";

const PickupStatus = ({
  order,
  status = "Pending",
  notes = [],
  onChangeStatus,
  onUpdateImages,
  onAddNotes,
  disableChangeStatus = false,
}) => {
  const theme = useTheme();
  const [pickupStatus, setPickupStatus] = useState(status);
  const [notesText, setNotesText] = useState("");

  const uploadRef = useRef();
  const cameraRef = useRef();
  const [pickupData, setPickupData] = useState({
    savedImages: [...order.pickupImages],
    newImageBlobs: [],
    newImageLocalUrls: [],
  });

  const [deliveredBy, setDeliveredBy] = useState(
    order.deliveredBy ? order.deliveredBy : null
  );
  const [deliveredOn, setDeliveredOn] = useState(
    order.deliveredOn
      ? moment(order.deliveredOn).format("MM/DD/YYYY")
      : moment().format("MM/DD/YYYY")
  );

  const [pickedUpBy, setPickedUpBy] = useState(
    order.pickedUpBy ? order.pickedUpBy : null
  );
  const [pickedUpOn, setPickedUpOn] = useState(
    order.pickedUpOn
      ? moment(order.pickedUpOn).format("MM/DD/YYYY")
      : moment().format("MM/DD/YYYY")
  );

  return (
    <Stack spacing={3} direction="column">
      <Paper sx={{ padding: "16px" }}>
        <Typography variant="h6" sx={{ mb: "15px" }}>
          Pick Up Status
        </Typography>
        <TextField
          disabled={disableChangeStatus}
          select
          variant="outlined"
          label="Pick Up Status"
          size="small"
          fullWidth
          value={pickupStatus}
          onChange={(e) => setPickupStatus(e.target.value)}
        >
          <MenuItem key={"Pending"} value={"Pending"}>
            Pending
          </MenuItem>
          <MenuItem value={"Picked Up - 100%"}>Picked Up - 100%</MenuItem>
          <MenuItem value={"Picked Up - Partial"}>Picked Up - Partial</MenuItem>
          <MenuItem value={"Delivered - 100%"}>Delivered - 100%</MenuItem>
          <MenuItem value={"Delivered - Partial"}>Delivered - Partial</MenuItem>
        </TextField>
        {pickupStatus == "Picked Up - 100%" && (
          <Box sx={{ display: "flex", flexDirection: "row", marginTop: "8px" }}>
            <TextField
              variant="outlined"
              label="Picked Up By"
              name="Picked Up By"
              size="small"
              fullWidth
              value={pickedUpBy}
              onChange={(e) => {
                setPickedUpBy(e.target.value);
              }}
            />
            <Box>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  label="Picked Up On"
                  value={pickedUpOn}
                  onChange={(newValue) => {
                    const date = moment(newValue).format("MM/DD/YYYY");
                    setPickedUpOn(date);
                  }}
                  renderInput={(params) => (
                    <TextField size="small" fullWidth sx={{}} {...params} />
                  )}
                />
              </LocalizationProvider>
            </Box>
          </Box>
        )}
        {pickupStatus == "Delivered - 100%" && (
          <Box sx={{ display: "flex", flexDirection: "row", marginTop: "8px" }}>
            <TextField
              variant="outlined"
              label="Delivered By"
              name="Delivered By"
              size="small"
              fullWidth
              value={deliveredBy}
              onChange={(e) => {
                setDeliveredBy(e.target.value);
              }}
            />
            <Box>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  label="Delivered On"
                  value={deliveredOn}
                  onChange={(newValue) => {
                    const date = moment(newValue).format("MM/DD/YYYY");
                    setDeliveredOn(date);
                  }}
                  renderInput={(params) => (
                    <TextField size="small" fullWidth sx={{}} {...params} />
                  )}
                />
              </LocalizationProvider>
            </Box>
          </Box>
        )}

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "8px",
          }}
        >
          <Button
            disabled={disableChangeStatus}
            variant="contained"
            onClick={() => {
              let pickupData = {
                deliveredBy: null,
                deliveredOn: null,
                pickedUpBy: null,
                pickedUpOn: null,
              };
              if (pickupStatus == "Picked Up - 100%") {
                pickupData.pickedUpBy = pickedUpBy;
                pickupData.pickedUpOn = moment(pickedUpOn);
              } else if (pickupStatus == "Delivered - 100%") {
                pickupData.deliveredBy = deliveredBy;
                pickupData.deliveredOn = moment(deliveredOn);
              }
              onChangeStatus(
                pickupStatus,
                pickupData.deliveredBy,
                pickupData.deliveredOn,
                pickupData.pickedUpBy,
                pickupData.pickedUpOn
              );
            }}
          >
            Update Status
          </Button>
        </div>
        <Typography sx={{ marginTop: "16px" }}>
          Add images of the delivery/pickup for the customer
        </Typography>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <label>
            <input
              ref={uploadRef}
              style={{ display: "none" }}
              type="file"
              accept="image/*"
              // capture="environment"
              onChange={(event) => {
                if (pickupData.newImageBlobs.length >= 15) {
                  alert("Maximum 15 images");
                } else {
                  console.log("FILE: ", event.target.files[0]);
                  console.log(
                    "Image",
                    URL.createObjectURL(event.target.files[0])
                  );
                  const file = event.target.files[0];
                  setPickupData((prev) => ({
                    ...prev,
                    newImageBlobs: [...prev.newImageBlobs, file],
                    newImageLocalUrls: [
                      ...prev.newImageLocalUrls,
                      URL.createObjectURL(event.target.files[0]),
                    ],
                  }));
                }
              }}
            />
            <div
              style={{
                cursor: "pointer",
                width: "100%",
                padding: 12,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  borderStyle: "solid",
                  borderWidth: 1,
                  borderColor: "#d2d2d2",
                  color: "#d2d2d2",
                  width: 100,
                  height: 100,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Upload sx={{ fontSize: 40 }} />
              </div>
            </div>
          </label>
          <label>
            <input
              ref={cameraRef}
              style={{ display: "none" }}
              type="file"
              accept="image/*"
              capture="environment"
              onChange={(event) => {
                if (pickupData.newImageBlobs.length >= 15) {
                  alert("Maximum 15 images");
                } else {
                  console.log("FILE: ", event.target.files[0]);
                  console.log(
                    "Image",
                    URL.createObjectURL(event.target.files[0])
                  );
                  const file = event.target.files[0];
                  setPickupData((prev) => ({
                    ...prev,
                    newImageBlobs: [...prev.newImageBlobs, file],
                    newImageLocalUrls: [
                      ...prev.newImageLocalUrls,
                      URL.createObjectURL(event.target.files[0]),
                    ],
                  }));
                }
              }}
            />
            <div
              style={{
                cursor: "pointer",
                width: "100%",
                padding: 12,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  borderStyle: "solid",
                  borderWidth: 1,
                  borderColor: "#d2d2d2",
                  color: "#d2d2d2",
                  width: 100,
                  height: 100,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CameraAlt sx={{ fontSize: 40 }} />
              </div>
            </div>
          </label>
        </div>

        <Grid
          container
          padding={0}
          spacing={0}
          alignItems="center"
          justifyContent="center"
        >
          {
            //Saved Images
            pickupData.savedImages.map((s3Key, index) => (
              <Grid
                item
                key={index}
                style={{
                  position: "relative",
                  margin: 0,
                  padding: 0,
                }}
              >
                <ShopS3PresignedCardImage
                  key={index}
                  width={100}
                  s3Key={s3Key}
                  style={{
                    cursor: "pointer",
                  }}
                />
                <Close
                  color="error"
                  style={{
                    position: "absolute",
                    top: 8,
                    right: 8,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    let imagesCopy = pickupData.savedImages;
                    imagesCopy.splice(index, 1);
                    setPickupData((prev) => ({
                      ...prev,
                      savedImages: imagesCopy,
                    }));
                  }}
                />
              </Grid>
            ))
          }
          {
            //Unsaved Images
            pickupData.newImageLocalUrls.map((url, index) => (
              <Grid
                item
                key={index}
                style={{
                  position: "relative",
                  margin: 0,
                  padding: 0,
                }}
              >
                <img
                  src={url}
                  style={{
                    minHeight: "100px",
                    maxHeight: "100px",
                    minWidth: "100px",
                    maxWidth: "100px",
                  }}
                />
                <Close
                  color="error"
                  style={{
                    position: "absolute",
                    top: 8,
                    right: 8,
                  }}
                  onClick={() => {
                    // Remove unsaved image from imageBlobs and imageUrls arrays
                    let imageBlobsCopy = pickupData.newImageBlobs;
                    let imageUrlsCopy = pickupData.newImageLocalUrls;
                    imageBlobsCopy.splice(index, 1);
                    imageUrlsCopy.splice(index, 1);
                    setPickupData((prev) => ({
                      ...prev,
                      newImageBlobs: imageBlobsCopy,
                      newImageLocalUrls: imageUrlsCopy,
                    }));
                  }}
                />
              </Grid>
            ))
          }
        </Grid>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "8px",
          }}
        >
          <Button
            variant="contained"
            onClick={() => {
              onUpdateImages(pickupData.savedImages, pickupData.newImageBlobs);
            }}
          >
            Update Images
          </Button>
        </div>
        <Typography sx={{ marginTop: "16px" }}>
          Notes (For Customer and Shop)
        </Typography>
        <TextareaAutosize
          placeholder="Delivered/Picked Up By... On..."
          style={{
            marginTop: "0px",
            background: theme.palette.secondary.main,
            color: "#fff",
            width: "100%",
          }}
          fullWidth
          minRows={5}
          value={notesText}
          onChange={(e) => {
            setNotesText(e.target.value);
          }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "8px",
          }}
        >
          <Button
            variant="contained"
            onClick={() => {
              onAddNotes(notesText);
            }}
          >
            Add Notes
          </Button>
        </div>
        {notes &&
          notes.map((note, index) => {
            let createdAt = moment(new Date(note.createdAt)).local();
            let weekOld = moment(new Date(createdAt)).local();
            weekOld.add(7, "d");
            let isWeekOld = moment() > weekOld;

            return (
              <Card key={index} style={{ padding: 16, marginTop: 8 }}>
                <Box style={{ display: "flex", flexDirection: "row" }}>
                  {note.user ? (
                    <Typography style={{ marginRight: 8, fontWeight: "bold" }}>
                      {note.user.fullName}
                    </Typography>
                  ) : (
                    <Typography style={{ marginRight: 8, fontWeight: "bold" }}>
                      {note.guestName}
                    </Typography>
                  )}
                  <Typography>
                    {isWeekOld
                      ? createdAt.format("MM/DD/YYYY")
                      : createdAt.fromNow()}
                  </Typography>
                </Box>
                <Typography style={{ marginTop: 8 }}>{note.text}</Typography>
              </Card>
            );
          })}
      </Paper>
    </Stack>
  );
};
export default PickupStatus;
