import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Outlet, useLocation } from "react-router-dom";
import { HeadingContext } from "../../../context/HeadingContext";
import { useNavigate } from "react-router-dom";
import MenuDrawer from "./MenuDrawer";
import Container from "@mui/material/Container";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";

import Badge from "@mui/material/Badge";
import { useTheme } from "@emotion/react";
import ErrorBoundary from "../../error/ErrorBoundary";
import { NotificationContext } from "../../../context/NotificationContext";

const navItems = [
  { title: "Orders", path: "/orders" },
  // { title: "Alerts", path: "/alerts" },
  { title: "Time Cards", path: "/time-cards" },
  { title: "Time Approvals", path: "/time-approvals" },
];

function TopNav(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const { unreadCount } = React.useContext(NotificationContext);
  const { window } = props;
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const { headingText, captionText } = React.useContext(HeadingContext);
  const handleDrawerToggle = () => {
    setDrawerOpen((prevState) => !prevState);
  };

  const handleNav = (navItem) => {
    switch (navItem) {
      case "Orders":
        navigate("/orders");
        break;
      // case "Alerts":
      //   navigate("/alerts");
      //   break;
      case "Time Cards":
        navigate("/time-cards");
        break;
      case "Time Approvals":
        navigate("/time-approvals");
        break;
      case "Menu":
        handleDrawerToggle();
        break;
      default:
        break;
    }
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      <MenuDrawer open={drawerOpen} toggleDrawer={handleDrawerToggle} />
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar component="nav">
          <Toolbar>
            <Box sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}>
              <img
                src="/industry-metal-co-long-logo-light-outlined-icon.png"
                height={50}
                onClick={() => navigate("/orders")}
              />
            </Box>

            <Box sx={{ display: { xs: "none", sm: "block" } }}>
              {navItems.map((item) => (
                <Button
                  sx={{
                    color: location.pathname.includes(item.path)
                      ? theme.palette.primary.main
                      : "inherit",
                  }}
                  key={item.title}
                  onClick={() => handleNav(item.title)}
                >
                  {item.title.toLowerCase() === "alerts" ? (
                    <Badge badgeContent={unreadCount} color="primary">
                      {item.title}
                    </Badge>
                  ) : (
                    item.title
                  )}
                </Button>
              ))}
              <Button>
                <MenuOutlinedIcon
                  sx={{ color: "white" }}
                  onClick={handleDrawerToggle}
                />
              </Button>
            </Box>
          </Toolbar>
        </AppBar>

        <Box
          component="main"
          sx={{
            p: 3,
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              marginBottom: "1rem",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {headingText && (
                <Typography color variant="h5">
                  {headingText}
                </Typography>
              )}
              {captionText && (
                <Typography color variant="subtitle2">
                  {captionText}
                </Typography>
              )}
            </Box>
          </Box>
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <ErrorBoundary>
              <Outlet />
            </ErrorBoundary>
          </Container>
        </Box>
      </Box>
    </>
  );
}

export default TopNav;
