import {
  Box,
  Card,
  Checkbox,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../../../../context/AuthContext";
import { useContext, useEffect, useState } from "react";
import FlatSheet from "../../../../../pages/shop/Orders/Order/FlatSheet";
import Coil from "../../../../../pages/shop/Orders/Order/Coil";
import Panel from "../../../../../pages/shop/Orders/Order/Panel";
import TrimAndFlashing from "../../../../../pages/shop/Orders/Order/TrimAndFlashing";
import BenchWork from "../../../../../pages/shop/Orders/Order/BenchWork";
import CopingCap from "../../../../../pages/shop/Orders/Order/CopingCap";
import Cleat from "../../../../../pages/shop/Orders/Order/Cleat";
import SplicePlate from "../../../../../pages/shop/Orders/Order/SplicePlate";
import Accessory from "../../../../../pages/shop/Orders/Order/Accessory";

const QuoteRequestPreview = ({ order, deconstructOrder }) => {
  const [deconstructedOrder, setDeconstructedOrder] = useState(null);
  const [productMap, setProductMap] = useState(new Map());

  const getProductMap = () => {
    let orderWithoutEmptyItems = deconstructOrder(order);
    console.log("DECONSTRUCTED", orderWithoutEmptyItems);
    const prodMap = new Map();
    for (let i = 0; i < orderWithoutEmptyItems.items.length; i++) {
      let orderItem = orderWithoutEmptyItems.items[i];
      prodMap.set(`${orderItem.objectType}`, orderItem);
    }
    setDeconstructedOrder(orderWithoutEmptyItems);
    setProductMap(prodMap);
  };

  useEffect(() => {
    if (order) {
      getProductMap();
    }
  }, [order]);

  if (!deconstructedOrder || !productMap) return null;
  return (
    <Box sx={{ padding: "16px" }}>
      <Card sx={{ marginTop: "8px", padding: "16px" }}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "2rem" }}>
          <Box sx={{}}>
            <Typography variant="h6">Components Included</Typography>
            <Box sx={{ padding: "8px" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  magin: 0,
                  padding: 0,
                }}
              >
                <Checkbox
                  disabled
                  style={{
                    magin: 0,
                    padding: 0,
                  }}
                  checked={productMap.has("FlatSheet")}
                />
                <Typography>Flat Sheet</Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  magin: 0,
                  padding: 0,
                }}
              >
                <Checkbox
                  disabled
                  style={{
                    magin: 0,
                    padding: 0,
                  }}
                  checked={productMap.has("Coil")}
                />
                <Typography>Coil</Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  magin: 0,
                  padding: 0,
                }}
              >
                <Checkbox
                  disabled
                  style={{
                    magin: 0,
                    padding: 0,
                  }}
                  checked={productMap.has("Panel")}
                />
                <Typography>Panel</Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  magin: 0,
                  padding: 0,
                }}
              >
                <Checkbox
                  disabled
                  style={{
                    magin: 0,
                    padding: 0,
                  }}
                  checked={productMap.has("TrimAndFlashing")}
                />
                <Typography>Trim & Flashing</Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  magin: 0,
                  padding: 0,
                }}
              >
                <Checkbox
                  disabled
                  style={{
                    magin: 0,
                    padding: 0,
                  }}
                  checked={productMap.has("BenchWork")}
                />
                <Typography>BenchWork</Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  magin: 0,
                  padding: 0,
                }}
              >
                <Checkbox
                  disabled
                  style={{
                    magin: 0,
                    padding: 0,
                  }}
                  checked={productMap.has("CopingCap")}
                />
                <Typography>Coping Cap</Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  magin: 0,
                  padding: 0,
                }}
              >
                <Checkbox
                  disabled
                  style={{
                    magin: 0,
                    padding: 0,
                  }}
                  checked={productMap.has("CopingCapCleat")}
                />
                <Typography>Coping Cap Cleat</Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  magin: 0,
                  padding: 0,
                }}
              >
                <Checkbox
                  disabled
                  style={{
                    magin: 0,
                    padding: 0,
                  }}
                  checked={productMap.has("SplicePlate")}
                />
                <Typography>Splice Plate</Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  magin: 0,
                  padding: 0,
                }}
              >
                <Checkbox
                  disabled
                  style={{
                    magin: 0,
                    padding: 0,
                  }}
                  checked={productMap.has("Accessory")}
                />
                <Typography>Extra Accessories</Typography>
              </Box>
            </Box>
          </Box>

          {/* <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
            <TextField label="PO #" value={deconstructedOrder.poNumber} />
            <TextField
              size="small"
              label="Notes"
              multiline
              maxRows={3}
              value={deconstructedOrder.notes}
            />
          </Box> */}
          <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
            {deconstructedOrder.isCustomMaterial ? (
              <TextField
                size="small"
                label="Custom Material"
                value={deconstructedOrder.customColor}
              />
            ) : (
              <>
                <TextField
                  size="small"
                  label="Color"
                  value={`${deconstructedOrder.color} - ${deconstructedOrder.manufacturer}`}
                />
                <TextField
                  size="small"
                  label="Gauge"
                  value={deconstructedOrder.gauge}
                />
              </>
            )}
            <TextField
              size="small"
              label="Notes"
              value={deconstructedOrder.notes}
            />
          </Box>
        </Box>
      </Card>

      {productMap.get("FlatSheet") && (
        <FlatSheet
          flatSheet={productMap.get("FlatSheet")}
          noCalcs={true}
          sx={{ marginTop: "32px" }}
        />
      )}

      {productMap.get("Coil") && (
        <Coil
          coil={productMap.get("Coil")}
          noCalcs={true}
          sx={{ marginTop: "32px" }}
        />
      )}

      {productMap.get("Panel") && (
        <Panel
          panel={productMap.get("Panel")}
          noCalcs={true}
          sx={{ marginTop: "32px" }}
        />
      )}
      {productMap.get("TrimAndFlashing") && (
        <TrimAndFlashing
          trim={productMap.get("TrimAndFlashing")}
          noCalcs={true}
          style={{ marginTop: "32px" }}
        />
      )}
      {productMap.get("BenchWork") && (
        <BenchWork
          benchWork={productMap.get("BenchWork")}
          noCalcs={true}
          style={{ marginTop: "32px" }}
        />
      )}
      {productMap.get("CopingCap") && (
        <CopingCap
          copingCap={productMap.get("CopingCap")}
          noCalcs={true}
          style={{ marginTop: "32px" }}
        />
      )}
      {productMap.get("CopingCapCleat") && (
        <Cleat
          cleat={productMap.get("CopingCapCleat")}
          noCalcs={true}
          style={{ marginTop: "32px" }}
        />
      )}
      {productMap.get("SplicePlate") && (
        <SplicePlate
          splicePlate={productMap.get("SplicePlate")}
          noCalcs={true}
          style={{ marginTop: "32px" }}
        />
      )}
      {productMap.get("Accessory") && (
        <Accessory
          accessory={productMap.get("Accessory")}
          noCalcs={true}
          style={{ marginTop: "32px", marginBottom: "32px" }}
        />
      )}
    </Box>
  );
};

export default QuoteRequestPreview;
