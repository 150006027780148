import { useState, useContext, useEffect } from "react";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { useTheme } from "@emotion/react";
import { AuthContext } from "../../../../../context/AuthContext";
import Catalog from "../../../../../components/Catalog/Catalog";
import useTrimCatalogAdmin from "../../../../../hooks/useTrimCatalogAdmin";

const CompanyTrimCatalogDialog = ({
  companyId,
  open,
  setOpen,
  onItemSelect,
}) => {
  const theme = useTheme();
  const { authToken, role, decoded } = useContext(AuthContext);
  const [
    searchQuery,
    setSearchQuery,
    catalogFilter,
    setCatalogFilter,
    results,
    onRefresh,
    refreshing,
    onLoadMore,
    loadingMore,
    total,
    hasMore,
  ] = useTrimCatalogAdmin(authToken, 20, null, "master", companyId);

  return (
    <Dialog
      // disableEnforceFocus
      // keepMounted
      scroll={"paper"}
      // maxWidth="md"
      // fullWidth={true}
      // fullScreen={false}
      // PaperProps={{
      //   style: {
      //     background: theme.palette.secondary.main,
      //   },
      // }}
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <DialogTitle>Add Existing Trim</DialogTitle>
      <DialogContent>
        <Catalog
          search={searchQuery}
          setSearch={setSearchQuery}
          catalogFilter={catalogFilter}
          setCatalogFilter={setCatalogFilter}
          enableCatalogFilters={true}
          items={results}
          itemType={"Trim & Flashing"}
          onRefresh={onRefresh}
          refreshing={refreshing}
          onLoadMore={onLoadMore}
          isLoadingMore={loadingMore}
          // saveNew={onSubmitNewItem}
          onSelect={onItemSelect}
          hasMore={hasMore}
          enableSaveToCatalog={false}
          isMasterCatalogItem={true}
          disableAddNew={true}
          enablePrint={true}
          industryCatalogLink="/master-trim-catalog/print"
          companyCatalogLink={`/customer/${companyId}/trims/stock`}
        />
      </DialogContent>
    </Dialog>
  );
};
export default CompanyTrimCatalogDialog;
