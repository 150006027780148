import {
  valueIfNotOverridden,
  combineVariablesAndOverrides,
  toTwoDecimals,
  toThreeDecimals,
  toFourDecimals,
} from "./utils";

const calculatePanel = (
  panel = {},
  variables = {},
  overrides = {},
  state = {}
) => {
  const $variables = combineVariablesAndOverrides(variables, overrides ?? {});
  const {
    // coil_price: COIL_COST_PER_SQFT, // coil_price now comes from the order object
    waste: WASTE,
    // panel_run_rate: PANEL_FAB_RATE_LF_PER_MINUTE, // Wrong the panel run rate is LF per hour not per minute
    panel_run_rate: PANEL_FAB_RATE_LF_PER_HOUR,
    hourly_labor_cost: HOURLY_LABOR_RATE,
    lumber_and_crating_rate: LUMBER_RATE,
    gross_profit_margin: GROSS_PROFIT_MARGIN,
  } = $variables;

  let COIL_COST_PER_SQFT =
    state.coilCostPerSqFt != state.coilCostPerSqFtOriginal
      ? parseFloat(state.coilCostPerSqFt)
      : parseFloat(state.coilCostPerSqFtOriginal);
  if (!COIL_COST_PER_SQFT) COIL_COST_PER_SQFT = 0;

  const $ = valueIfNotOverridden(overrides ?? {});

  const COIL_SIZE_NEEDED = $(
    "coil_size_needed",
    Number(panel.panelWidth ?? 0) + Number(panel.panelTakeUp ?? 0)
  ); //inches

  //this is auto-calc'd BUT if it's 40" USER CAN OVERIDE TO 48 IN CASE 40" IS NOT IN-STOCK, BUT IF IT'S 48 YOU CANNOT OVERRIDE
  let masterCoilSizeNeededTemp = 40;
  if (COIL_SIZE_NEEDED <= 16 || COIL_SIZE_NEEDED > 20)
    masterCoilSizeNeededTemp = 48;
  const MASTER_COIL_SIZE_NEEDED = $(
    "master_coil_size_needed",
    //COIL_SIZE_NEEDED <= 16 ?? COIL_SIZE_NEEDED > 20 ? 48 : 40
    masterCoilSizeNeededTemp
  );

  const COILS_CUT_FROM_MASTER_COIL = $(
    "coils_cut_from_master",
    Math.floor(MASTER_COIL_SIZE_NEEDED / COIL_SIZE_NEEDED)
  );

  const COIL_DROP = $(
    "coil_drop",
    MASTER_COIL_SIZE_NEEDED - COIL_SIZE_NEEDED * COILS_CUT_FROM_MASTER_COIL
  );

  const getLF = (qty, feet, inches) =>
    parseFloat(qty) * (parseFloat(feet) + parseFloat(inches) / 12);

  const LINEAL_FEET_OF_COIL_NEEDED_NOT_INCLUDING_WASTE = $(
    "lineal_feet_of_coil_no_waste",
    panel.cuts.reduce(
      (acc, cut) => (acc += getLF(cut.quantity, cut.feet, cut.inches)),
      0
    )
  );

  const LINEAL_FEET_OF_COIL_NEEDED_INCLUDING_WASTE = $(
    "lineal_feet_of_coil_with_waste",
    LINEAL_FEET_OF_COIL_NEEDED_NOT_INCLUDING_WASTE +
      LINEAL_FEET_OF_COIL_NEEDED_NOT_INCLUDING_WASTE * WASTE
  );

  const SQFT_OF_COIL_NEEDED_INCLUDING_WASTE = $(
    "square_feet_of_coil_needed_with_waste",
    LINEAL_FEET_OF_COIL_NEEDED_INCLUDING_WASTE * (COIL_SIZE_NEEDED / 12)
  );

  const FINISHED_PANEL_SQFT = $(
    "finished_panel_square_feet",
    // LINEAL_FEET_OF_COIL_NEEDED_INCLUDING_WASTE * (panel.panelWidth / 12)
    // ** Finished Panel Square FT should not include waste
    LINEAL_FEET_OF_COIL_NEEDED_NOT_INCLUDING_WASTE *
      (parseFloat(panel.panelWidth) / 12)
  );

  const MATERIAL_COST = $(
    "material_cost",
    COIL_COST_PER_SQFT * SQFT_OF_COIL_NEEDED_INCLUDING_WASTE
  );

  // Wrong the panel run rate is LF per hour not per minute
  // const TOTAL_ESTIMATED_LABOR_HOURS = $(
  //   "total_estimated_labor_hours",
  //   Math.ceil(
  //     LINEAL_FEET_OF_COIL_NEEDED_INCLUDING_WASTE /
  //       PANEL_FAB_RATE_LF_PER_MINUTE /
  //       60
  //   )
  // );

  const TOTAL_ESTIMATED_LABOR_HOURS = $(
    "total_estimated_labor_hours",
    Math.ceil(
      LINEAL_FEET_OF_COIL_NEEDED_INCLUDING_WASTE / PANEL_FAB_RATE_LF_PER_HOUR
    )
  );

  const ESTIMATED_LABOR_COST = $(
    "total_estimated_labor_cost",
    TOTAL_ESTIMATED_LABOR_HOURS * HOURLY_LABOR_RATE
  );

  const ESTIMATED_LUMBER_COST = $(
    "estimated_lumber_cost",
    (MATERIAL_COST + ESTIMATED_LABOR_COST) * LUMBER_RATE
  );

  const TOTAL_HARD_COST = $(
    "total_hard_cost",
    MATERIAL_COST + ESTIMATED_LABOR_COST + ESTIMATED_LUMBER_COST
  );

  const GROSS_PROFIT_DOLLARS = $(
    "gross_profit_dollars",
    TOTAL_HARD_COST / (1 - GROSS_PROFIT_MARGIN) - TOTAL_HARD_COST
  );

  const TOTAL_SELL_PRICE = $(
    "total_sell_price",
    TOTAL_HARD_COST + GROSS_PROFIT_DOLLARS
  );

  const PANEL_PRICE_PER_SQFT = $(
    "panel_price_per_square_foot",
    TOTAL_SELL_PRICE / FINISHED_PANEL_SQFT
  );

  let PANEL_CALC = {
    waste: toThreeDecimals(WASTE),
    coilSizeNeeded: COIL_SIZE_NEEDED,
    masterCoilSizeNeeded: MASTER_COIL_SIZE_NEEDED,
    coilsCutFromMaster: COILS_CUT_FROM_MASTER_COIL,
    coilDrop: COIL_DROP,
    linealFeetOfCoilNoWaste: toThreeDecimals(
      LINEAL_FEET_OF_COIL_NEEDED_NOT_INCLUDING_WASTE
    ),
    linealFeetOfCoilWithWaste: toThreeDecimals(
      LINEAL_FEET_OF_COIL_NEEDED_INCLUDING_WASTE
    ),
    squareFeetOfCoilNeededWithWaste: toThreeDecimals(
      SQFT_OF_COIL_NEEDED_INCLUDING_WASTE
    ),
    finishedPanelSquareFeet: toThreeDecimals(FINISHED_PANEL_SQFT),
    materialCost: toTwoDecimals(MATERIAL_COST),
    estimatedLaborHours: toTwoDecimals(TOTAL_ESTIMATED_LABOR_HOURS),
    estimatedLaborCost: toTwoDecimals(ESTIMATED_LABOR_COST),
    estimatedLumberCost: toTwoDecimals(ESTIMATED_LUMBER_COST),
    totalHardCost: toTwoDecimals(TOTAL_HARD_COST),
    grossProfitMargin: toFourDecimals(GROSS_PROFIT_MARGIN),
    grossProfitDollars: toTwoDecimals(GROSS_PROFIT_DOLLARS),
    totalSellPrice: toTwoDecimals(TOTAL_SELL_PRICE),
    panelPricePerSquareFoot: toTwoDecimals(PANEL_PRICE_PER_SQFT),
  };

  return PANEL_CALC;
};

export default calculatePanel;
