import {
  valueIfNotOverridden,
  combineVariablesAndOverrides,
  toTwoDecimals,
  toThreeDecimals,
  toFourDecimals,
} from "./utils";

const calculateCoil = (
  coil = {},
  variables = {},
  overrides = {},
  state = {}
) => {
  const $variables = combineVariablesAndOverrides(variables, overrides ?? {});
  const { gross_profit_margin: GROSS_PROFIT_MARGIN } = $variables;

  // Coil Cost Based On Color
  let COIL_COST_PER_SQFT =
    state.coilCostPerSqFt != state.coilCostPerSqFtOriginal
      ? parseFloat(state.coilCostPerSqFt)
      : parseFloat(state.coilCostPerSqFtOriginal);
  if (!COIL_COST_PER_SQFT) COIL_COST_PER_SQFT = 0;

  // If override exists, return override value instead of calculated
  const $ = valueIfNotOverridden(overrides ?? {});

  const COST_PER_SQUARE_FEET_OF_COIL = $(
    "coil_cost", // override key
    toTwoDecimals(COIL_COST_PER_SQFT) // non-override value
  );

  // (width (in.) / 12) * lineal feet
  const SQUARE_FEET_OF_COIL_NEEDED = $(
    "square_feet_of_coil_needed_no_waste",
    toThreeDecimals((parseFloat(coil.width) / 12) * parseFloat(coil.linealFeet))
  );

  // Cost = Square Feet of Coil * Price Per Square Feet of Coil
  const MATERIAL_COST = $(
    "material_cost",
    toTwoDecimals(
      parseFloat(COST_PER_SQUARE_FEET_OF_COIL) *
        parseFloat(SQUARE_FEET_OF_COIL_NEEDED)
    )
  );

  const GROSS_PROFIT_DOLLARS = $(
    "gross_profit_dollars",
    MATERIAL_COST / (1 - GROSS_PROFIT_MARGIN) - MATERIAL_COST
  );

  const TOTAL_SELL_PRICE = $(
    "total_sell_price",
    MATERIAL_COST + GROSS_PROFIT_DOLLARS
  );

  const PRICE_PER_SQUARE_FEET_OF_COIL = $(
    "coil_price", // override key
    toTwoDecimals(TOTAL_SELL_PRICE / SQUARE_FEET_OF_COIL_NEEDED) // non-override value
  );

  const PRICE_PER_LINEAL_FOOT_OF_COIL = $(
    "coil_price", // override key
    toTwoDecimals(TOTAL_SELL_PRICE / parseFloat(coil.linealFeet)) // non-override value
  );

  let COIL_CALC = {
    costPerSquareFeetOfCoil: COST_PER_SQUARE_FEET_OF_COIL,
    squareFeetOfCoil: SQUARE_FEET_OF_COIL_NEEDED,
    materialCost: MATERIAL_COST,
    grossProfitMargin: toFourDecimals(GROSS_PROFIT_MARGIN),
    grossProfitDollars: toTwoDecimals(GROSS_PROFIT_DOLLARS),
    totalSellPrice: toTwoDecimals(TOTAL_SELL_PRICE),
    pricePerSquareFeetOfCoil: PRICE_PER_SQUARE_FEET_OF_COIL,
    pricePerLinealFootOfCoil: PRICE_PER_LINEAL_FOOT_OF_COIL,
  };

  return COIL_CALC;
};

export default calculateCoil;
