import {
  Box,
  Checkbox,
  TextField,
  Typography,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const LineItem = ({ lineItem, number, removeLineItem }) => {
  return (
    <>
      <TableRow
        key={number}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      >
        <TableCell component="th" scope="row">
          {number}
        </TableCell>
        <TableCell>{lineItem.serviceDate}</TableCell>
        <TableCell>{lineItem.service.Name}</TableCell>
        <TableCell>{lineItem.description}</TableCell>
        <TableCell>{lineItem.qty}</TableCell>
        <TableCell>${lineItem.rate}</TableCell>
        <TableCell>${lineItem.amount}</TableCell>
        <TableCell>
          <Checkbox checked={lineItem.isTaxed} />
        </TableCell>
        <TableCell onClick={removeLineItem}>
          <DeleteOutlineIcon />
        </TableCell>
      </TableRow>
    </>
  );
};
export default LineItem;
