import uploadImage from "../helper/uploadImage";
import createPresignedUrl from "../helper/createPresignedUrl";
import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";

const useUploadImages = (resource) => {
  const resourceOptions = [
    "trims",
    "coping-caps",
    "cleats",
    "bench-work",
    "tax-forms",
    "job-quotes",
    "estimates",
    "signatures",
    "signed-estimates",
  ];

  const { authToken, decoded, ezorder } = useContext(AuthContext);
  const saveNewTrim = async (name, image, saveToCatalog) => {
    let newTrim = {
      name,
      image,
      saveToCatalog,
    };
    const endPoint =
      resource === "trims"
        ? "trims"
        : resource === "coping-caps"
        ? "caps"
        : resource === "cleats"
        ? "cleats"
        : resource === "bench-work"
        ? "benchPieces"
        : "";
    const response = await ezorder.post(`/products/${endPoint}`, newTrim, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    const returnObjectKey =
      resource === "trims"
        ? "trim"
        : resource === "coping-caps"
        ? "cap"
        : resource === "cleats"
        ? "cleat"
        : resource === "bench-work"
        ? "benchPiece"
        : "";

    return response.data[returnObjectKey]; //This is the object I add to state
  };

  const saveTrimImageHandler = async (name, imageBlob, saveToCatalog) => {
    console.log("newItemHandler", name, imageBlob, saveToCatalog);
    // Create Presigned URL
    let actionType = "WRITE";
    let fileName = imageBlob.name;
    let contentType = imageBlob.type;
    // let resource = resource;
    let identifier = decoded.companyId;
    const presignedUrlResponse = await createPresignedUrl(
      ezorder,
      authToken,
      actionType,
      fileName,
      contentType,
      resource,
      identifier
    );
    console.log("presignedUrlResponse", presignedUrlResponse);

    // Upload URL
    await uploadImage(presignedUrlResponse.uploadUrl, imageBlob);

    return await saveNewTrim(name, presignedUrlResponse.key, saveToCatalog);
    // await getCatalog();
  };

  return [saveTrimImageHandler];
};

export default useUploadImages;
