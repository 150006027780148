import { useTheme } from "@emotion/react";
import { Add, AddCircle } from "@mui/icons-material";
import { Box, Button, Typography, useMediaQuery } from "@mui/material";

const ProductCard = ({
  canUpdateOrder,
  orderItem,
  onRemove,
  handleClick,
  ...props
}) => {
  const { objectType } = orderItem;

  switch (objectType) {
    case "FlatSheet":
      return (
        <Container {...props} handleClick={() => handleClick(objectType)}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography variant="caption" fontWeight={"600"}>
              Flat Sheet
            </Typography>
            <Typography variant="caption">
              count: {orderItem.quantity ? parseFloat(orderItem.quantity) : 0}
            </Typography>
          </Box>
        </Container>
      );
    case "Coil":
      return (
        <Container {...props} handleClick={() => handleClick(objectType)}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography variant="caption" fontWeight={"600"}>
              Coil
            </Typography>
            <Typography variant="caption">
              lineal feet:{" "}
              {orderItem.linealFeet ? parseFloat(orderItem.linealFeet) : 0}
            </Typography>
          </Box>
        </Container>
      );
    case "Panel":
      return (
        <Container {...props} handleClick={() => handleClick(objectType)}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography variant="caption" fontWeight={"600"}>
              Panel
            </Typography>
            <Typography variant="caption">
              entries: {orderItem.cuts.length}
            </Typography>
          </Box>
        </Container>
      );
    case "TrimAndFlashing":
      return (
        <Container {...props} handleClick={() => handleClick(objectType)}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography variant="caption" fontWeight={"600"}>
              Trim & Flashing
            </Typography>
            <Typography variant="caption">
              Profiles: {orderItem.trimPieces.length}
            </Typography>
          </Box>
        </Container>
      );
    case "BenchWork":
      return (
        <Container {...props} handleClick={() => handleClick(objectType)}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography variant="caption" fontWeight={"600"}>
              Bench Work
            </Typography>
            <Typography variant="caption">
              Profiles: {orderItem.benchWorkPieces.length}
            </Typography>
          </Box>
        </Container>
      );
    case "CopingCapCleat":
      return (
        <Container {...props} handleClick={() => handleClick(objectType)}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography variant="caption" fontWeight={"600"}>
              Coping Cap Cleat
            </Typography>
            <Typography variant="caption">
              Profiles: {orderItem.cleatPieces.length}
            </Typography>
          </Box>
        </Container>
      );
    case "CopingCap":
      return (
        <Container {...props} handleClick={() => handleClick(objectType)}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography variant="caption" fontWeight={"600"}>
              Coping Cap
            </Typography>
            <Typography variant="caption">
              Profiles: {orderItem.capPieces.length}
            </Typography>
          </Box>
        </Container>
      );
    case "SplicePlate":
      return (
        <Container {...props} handleClick={() => handleClick(objectType)}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography variant="caption" fontWeight={"600"}>
              Splice Plate
            </Typography>
            <Typography variant="caption">
              plates: {orderItem.plates.length}
            </Typography>
          </Box>
        </Container>
      );
    case "Accessory":
      return (
        <Container {...props} handleClick={() => handleClick(objectType)}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography variant="caption" fontWeight={"600"}>
              Accessories/Misc.
            </Typography>
            <Typography variant="caption">
              items: {orderItem.items.length}
            </Typography>
          </Box>
        </Container>
      );

    default:
      break;
  }
};

const Container = ({ handleClick, children, ...props }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      {...props}
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        //   padding: "5px 15px 5px 15px",
        padding: "8px",
        border: "1px solid #cbcbcb",
        borderRadius: "4px",
        justifyContent: "space-between",
        cursor: "pointer",
      }}
      onClick={handleClick}
    >
      {children}

      <AddCircle color="success" sx={{ fontSize: 32 }} />
    </Box>
  );
};

export default ProductCard;
