import {
  Box,
  CircularProgress,
  Modal,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import moment from "moment/moment";

const CustomerTableRow = ({ onSelect, customer }) => {
  const theme = useTheme();

  let isExpirationInRed = false;

  let yearlyTaxForm = customer.yearlyTaxForm;
  if (yearlyTaxForm) {
    let expirationDate = moment(new Date(yearlyTaxForm.expirationDate)).local();
    let tomorrow = moment().local();
    tomorrow.add(1, "d");
    if (expirationDate < tomorrow) {
      isExpirationInRed = true;
    }
  }

  return (
    <TableRow
      hover
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      onClick={() => {
        onSelect(customer.id);
      }}
    >
      <TableCell>{customer.id}</TableCell>
      <TableCell>{customer.name}</TableCell>
      <TableCell>{customer.contactName}</TableCell>
      <TableCell>{customer.contactEmail}</TableCell>
      <TableCell>{customer.contactPhone}</TableCell>
      <TableCell sx={{ color: isExpirationInRed ? "red" : "inherit" }}>
        {customer.yearlyTaxForm
          ? `${moment(
              customer.yearlyTaxForm.expirationDate
            ).fromNow()} (${moment(
              customer.yearlyTaxForm.expirationDate
            ).format("MM/DD/YY")})`
          : "N/A"}
      </TableCell>
      <TableCell sx={{ color: customer.deletedAt ? "red" : "inherit" }}>
        {customer.deletedAt ? "Deactivated" : "Active"}
      </TableCell>
    </TableRow>
  );
};

export default CustomerTableRow;
