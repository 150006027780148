import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import getPackingListDocDefinition from "../../pdf-helpers/PackingListHelper";
import { IconButton } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const PackingListButton = ({ orderId }) => {
  const { authToken, ezorder } = useContext(AuthContext);

  const getOrderById = async () => {
    const response = await ezorder
      .get(`/shop/orders/${orderId}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .catch((error) => {
        throw Error("Unable to load order", { cause: error });
      });
    return response.data.order;
  };

  const getPDF = async () => {
    try {
      // GET ORDER
      // console.log("GET WORK ORDER PDF FOR ORDER", orderId);
      const order = await getOrderById(orderId);

      // console.log("Creating Packing List for Order", order);

      const docDef = getPackingListDocDefinition(order);

      // pdfMake.createPdf(docDef).open();
      pdfMake
        .createPdf(docDef)
        .download(
          `PACKING-LIST-${order.id}-${order.company.name}-${order.project.name}.pdf`
        );
    } catch (error) {
      console.log("ERROR", error);
      throw Error("Unable to load PDF", { cause: error });
    }
  };

  return (
    <IconButton
      variant="outlined"
      color="primary"
      onClick={() => {
        getPDF();
      }}
    >
      Packing List
      <DownloadIcon />
    </IconButton>
  );
};

export default PackingListButton;
