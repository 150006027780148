import { useTheme } from "@emotion/react";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { forwardRef, useContext, useState } from "react";
import { AuthContext } from "../context/AuthContext";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ApproveEstimateDialog = ({
  open,
  setOpen,
  // titleContent,
  actionAreaContent,
  containerStyle,
  ...props
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { authToken, decoded } = useContext(AuthContext); // To see whether theres a bottom nav bar or not

  // return (
  //   <div
  //     style={{
  //       position: "fixed",
  //       left: 0,
  //       right: 0,
  //       bottom: authToken && isSmallScreen ? "55px" : 0,
  //       display: "flex",
  //       flexDirection: "column",
  //       zIndex: 20,
  //       ...containerStyle,
  //     }}
  //   >
  //     {/* <Accordion
  //       expanded={isExpanded}
  //       sx={{
  //         borderColor: theme.palette.primary.dark,
  //         borderWidth: 1,
  //         borderStyle: "solid",
  //       }}
  //       {...props}
  //     >
  //       <AccordionSummary
  //         onClick={() => setExpanded(!isExpanded)}
  //         // sx={{ width: "100vw", height: "100vh", margin: 0 }}
  //         // expandIcon={<ExpandMore color="primary" />}
  //         expandIcon={
  //           <ExpandLess color="primary" sx={{ marginRight: "8px" }} />
  //         }
  //         aria-controls="panel1a-content"
  //         id="panel1a-header"
  //       >
  //         {titleContent(isExpanded)}
  //       </AccordionSummary>
  //       <AccordionDetails
  //         sx={{
  //           margin: 0,
  //           maxHeight: "70vh",
  //           overflow: "scroll",
  //           overflowX: "hidden",
  //         }}
  //       >
  //         <Box
  //           style={{
  //             display: "flex",
  //             flexDirection: "column",
  //             alignItems: "center",
  //           }}
  //         >
  //           {props.children}
  //         </Box>
  //       </AccordionDetails>
  //     </Accordion> */}
  //     <Box component={isSmallScreen ? Box : Container}>
  //       <Card
  //         sx={{
  //           padding: "16px",
  //           borderColor: theme.palette.primary.dark,
  //           borderWidth: 1,
  //           borderStyle: "solid",
  //           display: "flex",
  //           flexDirection: "row",
  //           alignItems: "center",
  //         }}
  //         onClick={() => setExpanded(!isExpanded)}
  //       >
  //         <Box sx={{ flex: 1 }}>{titleContent(isExpanded)}</Box>
  //         {/* <ExpandLess color="primary" sx={{ marginRight: "8px" }} /> */}
  //       </Card>
  //     </Box>
  //     <Dialog
  //       TransitionComponent={Transition}
  //       fullScreen={true}
  //       fullWidth={true}
  //       open={isExpanded}
  //       onClose={() => {
  //         setExpanded(false);
  //       }}
  //     >
  //       <DialogTitle sx={{ background: "#121212" }}>
  //         <Box component={isSmallScreen ? Box : Container}>{dialogTitle}</Box>
  //       </DialogTitle>
  //       <DialogContent sx={{ background: "#121212" }}>
  //         <Box component={isSmallScreen ? Box : Container}>
  //           {props.children}
  //         </Box>
  //       </DialogContent>
  //       <DialogActions
  //         sx={{
  //           background: "#121212",
  //         }}
  //       >
  //         <Box
  //           component={isSmallScreen ? Box : Container}
  //           sx={{
  //             display: "flex",
  //             flexDirection: "row",
  //             alignItems: "center",
  //             justifyContent: "flex-end",
  //           }}
  //         >
  //           <Button
  //             onClick={() => {
  //               setExpanded(false);
  //             }}
  //           >
  //             Cancel
  //           </Button>
  //           {actionAreaContent()}
  //         </Box>
  //       </DialogActions>
  //     </Dialog>
  //   </div>
  // );

  return (
    <Dialog
      TransitionComponent={Transition}
      fullScreen={true}
      fullWidth={true}
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <DialogTitle>
        <Box component={isSmallScreen ? Box : Container}>
          Review and Approve Quote
        </Box>
      </DialogTitle>
      <DialogContent sx={{ padding: 0 }}>
        <Box component={isSmallScreen ? Box : Container}>{props.children}</Box>
      </DialogContent>
      <DialogActions>
        <Box
          component={isSmallScreen ? Box : Container}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <Button
            onClick={() => {
              setOpen(false);
            }}
          >
            Cancel
          </Button>
          {actionAreaContent()}
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default ApproveEstimateDialog;
