import { useContext, useState, useEffect } from "react";
import { AuthContext } from "../context/AuthContext";

const useShopNotes = (authToken, orderId, isInternal) => {
  const { ezorder } = useContext(AuthContext);
  const [shopNotes, setShopNotes] = useState([]);
  const [isLoadingNotes, setIsLoadingNotes] = useState(false);

  const getShopNotes = async (controller) => {
    try {
      setIsLoadingNotes(true);
      let data = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };

      if (controller) {
        data.signal = controller.signal;
      }

      let url;
      if (isInternal) {
        url = `/shop/orders/${orderId}/shop-notes`;
      } else {
        url = `/orders/${orderId}/shop-notes`;
      }

      const response = await ezorder.get(url, data);
      setShopNotes(response.data.shopNotes);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingNotes(false);
    }
  };

  const addShopNote = async (fabricatorNote, isInternal) => {
    try {
      setIsLoadingNotes(true);

      let requestBody = {
        shopNotes: fabricatorNote,
        isInternal,
      };

      const response = await ezorder.post(
        `/shop/orders/${orderId}/shop-notes`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      getShopNotes();
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingNotes(false);
    }
  };

  useEffect(() => {
    const controller = new AbortController();

    getShopNotes();

    return () => {
      controller.abort(); // abort api call on unmount for cleanup
    };
  }, []);

  return { shopNotes, addShopNote, getShopNotes, isLoadingNotes };
};
export default useShopNotes;
