import { useContext, useState, useEffect } from "react";
import { AuthContext } from "../context/AuthContext";

const usePaintManufacturers = () => {
  const [paintManufacturers, setPaintManufacturers] = useState([]);
  const [refreshing, setRefreshing] = useState(false);
  const { authToken, ezorder } = useContext(AuthContext);
  const header = {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  };

  const getPaintManufacturers = async (controller) => {
    try {
      setRefreshing(true);
      let data = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };

      if (controller) {
        data.signal = controller.signal;
      }

      const response = await ezorder.get(`/app/paint-manufacturers`, data);
      console.log(
        "response.data.paintManufacturers",
        response.data.paintManufacturers
      );
      setPaintManufacturers(response.data.paintManufacturers);
      return response.data.colors;
    } catch (error) {
      console.log(error);
    } finally {
      setRefreshing(false);
    }
  };

  useEffect(() => {
    const controller = new AbortController();

    if (paintManufacturers.length === 0) {
      getPaintManufacturers();
    }

    return () => {
      controller.abort(); // abort api call on unmount for cleanup
    };
  }, []);

  return {
    paintManufacturers,
    setPaintManufacturers,
    getPaintManufacturers,
    refreshing,
  };
};
export default usePaintManufacturers;
