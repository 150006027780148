import { popoverClasses } from "@mui/material";

const SECTION_MARGIN = 12;
const FULL_WIDTH = 515;
const COLUMN_GAP = 20;

const HEADER = (hidePricing) => {
  return [
    {
      columns: [
        {
          image: "logo",
          width: 120,
        },
        {
          text: hidePricing ? "Bill of Materials" : "Purchase Order",
          style: "header",
        },
      ],
      margin: [0, 0, 0, 8], // margin: [left, top, right, bottom]
    },
  ];
};

const LINE_TOP = () => {
  return [
    {
      canvas: [{ type: "line", x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 1 }],
    },
  ];
};

const LINE_BELOW = () => {
  return [
    {
      canvas: [{ type: "line", x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 1 }],
      margin: [0, 4, 0, 0], // margin: [left, top, right, bottom]
    },
  ];
};

const JOB_DETAILS = (po) => {
  const {
    id,
    createdAt,
    poNumber,
    gauge,
    color,
    manufacturer,
    outTheDoorCost,
    company,
    project,
    notes,
    taxExemptForm,
    type,
    isCustomMaterial,
    customColor,
    customManufacturer,
    customGauge,
  } = po.order;

  let jobName;
  let customerName;
  let contactName;
  let contactEmail;
  let contactPhone;

  // GUEST VS MOBILE
  jobName = project.name;
  customerName = company.name;
  contactName = company.contactName;
  contactPhone = company.contactPhone;
  contactEmail = company.contactEmail;

  // Custom Manufacturer vs Regular
  let colorValue;
  let manufacturerValue;
  let gaugeValue;
  if (isCustomMaterial) {
    colorValue = customColor ? customColor : "";
    manufacturerValue = customManufacturer ? customManufacturer : "";
    gaugeValue = customGauge ? customGauge : "";
  } else {
    colorValue = color;
    manufacturerValue = manufacturer;
    gaugeValue = gauge;
  }

  return [
    {
      text: `JOB #${id}`,
      style: "subheader",
    },
    ...LINE_TOP(),
    {
      layout: "noBorders", // optional
      table: {
        // headers are automatically repeated if the table spans over multiple pages
        // you can declare how many rows should be treated as headers
        headerRows: 1,
        widths: ["*", "*", "*"],

        body: [
          [
            { text: "VENDOR NAME:", style: "detailName" },
            { text: "PO #:", style: "detailName" },
            { text: "CREATED BY:", style: "detailName" },
          ],
          [
            { text: `${po.vendor}`, style: "detailValue" },
            { text: `${po.id}`, style: "detailValue" },
            { text: `${po.createdBy.fullName}`, style: "detailValue" },
          ],
        ],
      },
      margin: [0, 4, 0, 0], // margin: [left, top, right, bottom]
    },
    {
      layout: "noBorders", // optional
      table: {
        // headers are automatically repeated if the table spans over multiple pages
        // you can declare how many rows should be treated as headers
        headerRows: 1,
        widths: ["*", "*", "*"],

        body: [
          [
            { text: "JOB NAME:", style: "detailName" },
            { text: "DELIVERY TYPE:", style: "detailName" },
            { text: "SHIP TO:", style: "detailName" },
          ],
          [
            { text: `${jobName}`, style: "detailValue" },
            { text: `${po.deliveryType}`, style: "detailValue" },
            { text: `${po.shippingAddress}`, style: "detailValue" },
          ],
        ],
      },
      margin: [0, 4, 0, 0], // margin: [left, top, right, bottom]
    },
    // TODO: Material Type and Color Type??? Are these deprecated
    // {
    //     layout: "noBorders", // optional
    //     table: {
    //       // headers are automatically repeated if the table spans over multiple pages
    //       // you can declare how many rows should be treated as headers
    //       headerRows: 1,
    //       widths: ["*", "*", "*"],

    //       body: [
    //         [
    //           { text: "MATERIAL TYPE:", style: "detailName" },
    //           { text: "COLOR TYPE:", style: "detailName" },
    //         ],
    //         [
    //           { text: `${po.}`, style: "detailValue" },
    //           ,
    //           { text: `${po.deliveryType}`, style: "detailValue" },
    //         ],
    //       ],
    //     },
    //     margin: [0, 4, 0, 0], // margin: [left, top, right, bottom]
    //   },
    ...LINE_BELOW(),
  ];
};

const FLATSHEETS_PAINTED = (po, hidePricing) => {
  if (!po.flatSheetQuantity || parseFloat(po.flatSheetQuantity) == 0) return [];
  return [
    {
      text: `FLAT SHEETS - PAINTED`,
      style: "subheader",
      margin: [0, SECTION_MARGIN, 0, 0], // margin: [left, top, right, bottom]
    },
    {
      table: {
        // headers are automatically repeated if the table spans over multiple pages
        // you can declare how many rows should be treated as headers
        headerRows: 1,
        widths: hidePricing
          ? ["*", "*", "*", "*"]
          : ["*", "*", "*", "*", "*", "*"],

        body: hidePricing
          ? [
              [
                {
                  text: "COLOR",
                  style: "tableHeader",
                  fillColor: "#D3D3D3",
                },
                { text: "GAUGE", style: "tableHeader", fillColor: "#D3D3D3" },
                {
                  text: "FS SIZE (in)",
                  style: "tableHeader",
                  fillColor: "#D3D3D3",
                },
                { text: "FS QTY", style: "tableHeader", fillColor: "#D3D3D3" },
              ],
              [
                { text: `${po.color}`, style: "tableCell" },
                { text: `${po.gauge}`, style: "tableCell" },
                { text: `${po.flatSheetSizeInches}`, style: "tableCell" },
                { text: `${po.flatSheetQuantity}`, style: "tableCell" },
              ],
            ]
          : [
              [
                {
                  text: "COLOR",
                  style: "tableHeader",
                  fillColor: "#D3D3D3",
                },
                { text: "GAUGE", style: "tableHeader", fillColor: "#D3D3D3" },
                {
                  text: "FS SIZE (in)",
                  style: "tableHeader",
                  fillColor: "#D3D3D3",
                },
                { text: "FS QTY", style: "tableHeader", fillColor: "#D3D3D3" },
                {
                  text: "PRICE PER FS",
                  style: "tableHeader",
                  fillColor: "#D3D3D3",
                },
                {
                  text: "TOTAL $",
                  style: "tableHeader",
                  fillColor: "#D3D3D3",
                },
              ],
              [
                { text: `${po.color}`, style: "tableCell" },
                { text: `${po.gauge}`, style: "tableCell" },
                { text: `${po.flatSheetSizeInches}`, style: "tableCell" },
                { text: `${po.flatSheetQuantity}`, style: "tableCell" },
                {
                  text: `$${po.pricePerFlatSheet}`,
                  style: "tableCell",
                },
                {
                  text: `$${po.flatSheetTotalPrice}`,
                  style: "tableCell",
                },
              ],
            ],
      },
      margin: [0, 4, 0, 0], // margin: [left, top, right, bottom]
    },
  ];
};

const FLATSHEETS_GALVANIZED = (po, hidePricing) => {
  if (
    !po.galvanizedFlatSheetQuantity ||
    parseFloat(po.galvanizedFlatSheetQuantity) == 0
  )
    return [];
  return [
    {
      text: `FLAT SHEETS - GALVANIZED`,
      style: "subheader",
      margin: [0, SECTION_MARGIN, 0, 0], // margin: [left, top, right, bottom]
    },
    {
      table: {
        // headers are automatically repeated if the table spans over multiple pages
        // you can declare how many rows should be treated as headers
        headerRows: 1,
        widths: hidePricing
          ? ["*", "*", "*", "*"]
          : ["*", "*", "*", "*", "*", "*"],

        body: hidePricing
          ? [
              [
                {
                  text: "COLOR",
                  style: "tableHeader",
                  fillColor: "#D3D3D3",
                },
                { text: "GAUGE", style: "tableHeader", fillColor: "#D3D3D3" },
                {
                  text: "FS SIZE (in)",
                  style: "tableHeader",
                  fillColor: "#D3D3D3",
                },
                { text: "FS QTY", style: "tableHeader", fillColor: "#D3D3D3" },
              ],
              [
                { text: `Galvanized`, style: "tableCell" },
                { text: `${po.galvanizedFlatSheetGauge}`, style: "tableCell" },
                {
                  text: `${po.galvanizedFlatSheetSizeInches}`,
                  style: "tableCell",
                },
                {
                  text: `${po.galvanizedFlatSheetQuantity}`,
                  style: "tableCell",
                },
              ],
            ]
          : [
              [
                {
                  text: "COLOR",
                  style: "tableHeader",
                  fillColor: "#D3D3D3",
                },
                { text: "GAUGE", style: "tableHeader", fillColor: "#D3D3D3" },
                {
                  text: "FS SIZE (in)",
                  style: "tableHeader",
                  fillColor: "#D3D3D3",
                },
                { text: "FS QTY", style: "tableHeader", fillColor: "#D3D3D3" },
                {
                  text: "PRICE PER FS",
                  style: "tableHeader",
                  fillColor: "#D3D3D3",
                },
                {
                  text: "TOTAL $",
                  style: "tableHeader",
                  fillColor: "#D3D3D3",
                },
              ],
              [
                { text: `Galvanized`, style: "tableCell" },
                { text: `${po.galvanizedFlatSheetGauge}`, style: "tableCell" },
                {
                  text: `${po.galvanizedFlatSheetSizeInches}`,
                  style: "tableCell",
                },
                {
                  text: `${po.galvanizedFlatSheetQuantity}`,
                  style: "tableCell",
                },
                {
                  text: `$${po.pricePerGalvanizedFlatSheet}`,
                  style: "tableCell",
                },
                {
                  text: `$${po.galvanizedFlatSheetTotalPrice}`,
                  style: "tableCell",
                },
              ],
            ],
      },
      margin: [0, 4, 0, 0], // margin: [left, top, right, bottom]
    },
  ];
};

const COIL_FOR_PANELS = (po, hidePricing) => {
  if (!po.squareFeetOfCoil || parseFloat(po.squareFeetOfCoil) == 0) return [];
  return [
    {
      text: `COIL FOR PANELS`,
      style: "subheader",
      margin: [0, SECTION_MARGIN, 0, 0], // margin: [left, top, right, bottom]
    },
    {
      text: `COIL SIZE: ${po.coilWidthInches}"`,
      style: "detailValue",
      //margin: [0, SECTION_MARGIN, 0, 0], // margin: [left, top, right, bottom]
    },
    {
      text: `MASTER COIL: ${po.masterCoilWidthInches}"`,
      style: "detailValue",
      //margin: [0, SECTION_MARGIN, 0, 0], // margin: [left, top, right, bottom]
    },
    {
      text: `TOTAL COIL SQUARE FOOTAGE INC DROP: ${po.squareFeetOfCoil}`,
      style: "detailValue",
      //margin: [0, SECTION_MARGIN, 0, 0], // margin: [left, top, right, bottom]
    },
    {
      table: {
        // headers are automatically repeated if the table spans over multiple pages
        // you can declare how many rows should be treated as headers
        headerRows: 1,
        widths: hidePricing
          ? ["*", "*", "*", "*"]
          : ["*", "*", "*", "*", "*", "*"],

        body: hidePricing
          ? [
              [
                {
                  text: "COLOR",
                  style: "tableHeader",
                  fillColor: "#D3D3D3",
                },
                { text: "GAUGE", style: "tableHeader", fillColor: "#D3D3D3" },
                {
                  text: "COIL QTY (LF)",
                  style: "tableHeader",
                  fillColor: "#D3D3D3",
                },
                {
                  text: "COIL QTY (SF)",
                  style: "tableHeader",
                  fillColor: "#D3D3D3",
                },
              ],
              [
                { text: `${po.color}`, style: "tableCell" },
                { text: `${po.gauge}`, style: "tableCell" },
                { text: `${po.linealFeetOfCoil}`, style: "tableCell" },
                { text: `${po.squareFeetOfCoil}`, style: "tableCell" },
              ],
            ]
          : [
              [
                {
                  text: "COLOR",
                  style: "tableHeader",
                  fillColor: "#D3D3D3",
                },
                { text: "GAUGE", style: "tableHeader", fillColor: "#D3D3D3" },
                {
                  text: "COIL QTY (LF)",
                  style: "tableHeader",
                  fillColor: "#D3D3D3",
                },
                {
                  text: "COIL QTY (SF)",
                  style: "tableHeader",
                  fillColor: "#D3D3D3",
                },
                {
                  text: "PRICE PER SF",
                  style: "tableHeader",
                  fillColor: "#D3D3D3",
                },
                {
                  text: "TOTAL $",
                  style: "tableHeader",
                  fillColor: "#D3D3D3",
                },
              ],
              [
                { text: `${po.color}`, style: "tableCell" },
                { text: `${po.gauge}`, style: "tableCell" },
                { text: `${po.linealFeetOfCoil}`, style: "tableCell" },
                { text: `${po.squareFeetOfCoil}`, style: "tableCell" },
                {
                  text: `$${po.pricePerSquareFeetOfCoil}`,
                  style: "tableCell",
                },
                {
                  text: `$${po.coilTotalPrice}`,
                  style: "tableCell",
                },
              ],
            ],
      },
      margin: [0, 4, 0, 0], // margin: [left, top, right, bottom]
    },
  ];
};

const COIL_DROP = (po, hidePricing) => {
  if (!po.squareFeetOfCoil || parseFloat(po.squareFeetOfCoil) == 0) return [];
  return [
    {
      text: `COIL - DROP`,
      style: "subheader",
      margin: [0, SECTION_MARGIN, 0, 0], // margin: [left, top, right, bottom]
    },
    {
      text: `DROP SIZE: ${po.coilDropWidthInches}"`,
      style: "detailValue",
      //margin: [0, SECTION_MARGIN, 0, 0], // margin: [left, top, right, bottom]
    },
    {
      table: {
        // headers are automatically repeated if the table spans over multiple pages
        // you can declare how many rows should be treated as headers
        headerRows: 1,
        widths: hidePricing
          ? ["*", "*", "*", "*"]
          : ["*", "*", "*", "*", "*", "*"],

        body: hidePricing
          ? [
              [
                {
                  text: "COLOR",
                  style: "tableHeader",
                  fillColor: "#D3D3D3",
                },
                { text: "GAUGE", style: "tableHeader", fillColor: "#D3D3D3" },
                {
                  text: "COIL QTY (LF)",
                  style: "tableHeader",
                  fillColor: "#D3D3D3",
                },
                {
                  text: "COIL QTY (SF)",
                  style: "tableHeader",
                  fillColor: "#D3D3D3",
                },
              ],
              [
                { text: `${po.color}`, style: "tableCell" },
                { text: `${po.gauge}`, style: "tableCell" },
                { text: `${po.linealFeetOfCoilDrop}`, style: "tableCell" },
                { text: `${po.squareFeetOfCoilDrop}`, style: "tableCell" },
              ],
            ]
          : [
              [
                {
                  text: "COLOR",
                  style: "tableHeader",
                  fillColor: "#D3D3D3",
                },
                { text: "GAUGE", style: "tableHeader", fillColor: "#D3D3D3" },
                {
                  text: "COIL QTY (LF)",
                  style: "tableHeader",
                  fillColor: "#D3D3D3",
                },
                {
                  text: "COIL QTY (SF)",
                  style: "tableHeader",
                  fillColor: "#D3D3D3",
                },
                {
                  text: "PRICE PER SF",
                  style: "tableHeader",
                  fillColor: "#D3D3D3",
                },
                {
                  text: "TOTAL $",
                  style: "tableHeader",
                  fillColor: "#D3D3D3",
                },
              ],
              [
                { text: `${po.color}`, style: "tableCell" },
                { text: `${po.gauge}`, style: "tableCell" },
                { text: `${po.linealFeetOfCoilDrop}`, style: "tableCell" },
                { text: `${po.squareFeetOfCoilDrop}`, style: "tableCell" },
                {
                  text: `$${po.pricePerSquareFeetOfCoil}`,
                  style: "tableCell",
                },
                {
                  text: `$${po.coilDropTotalPrice}`,
                  style: "tableCell",
                },
              ],
            ],
      },
      margin: [0, 4, 0, 0], // margin: [left, top, right, bottom]
    },
  ];
};

const NOTES_SECTION = (po) => {
  if (!popoverClasses) return [];
  return [
    {
      text: `NOTES:`,
      style: "subheader",
      margin: [0, SECTION_MARGIN, 0, 0], // margin: [left, top, right, bottom]
    },
    {
      text: `${po.notes}"`,
      style: "detailValue",
    },
  ];
};

const getPurchaseOrderDocDefinition = (po, hidePricing = false) => {
  let order = po.order;

  // console.log("Product Map", productMap);

  var docDefinition = {
    pageSize: "A4",
    content: [
      ...HEADER(hidePricing),
      ...JOB_DETAILS(po),
      ...FLATSHEETS_PAINTED(po, hidePricing),
      ...FLATSHEETS_GALVANIZED(po, hidePricing),
      ...COIL_FOR_PANELS(po, hidePricing),
      ...COIL_DROP(po, hidePricing),
      ...NOTES_SECTION(po),
    ],

    footer: function (currentPage, pageCount) {
      return {
        margin: [40, 10, 40, 0], // margin: [left, top, right, bottom]
        columns: [
          {
            text: `Created on ${new Date().toDateString()}`,
            alignment: "left",
            style: "footerText",
          },
          {
            text: currentPage.toString() + " of " + pageCount,
            alignment: "center",
            style: "footerText",
          },
          // {
          //   text: "EZ-ORDER Ver.1.0",
          //   alignment: "right",
          //   style: "footerText",
          // },
        ],
      };
    },

    styles: {
      header: {
        fontSize: 24,
        bold: true,
        alignment: "right",
      },
      subheader: {
        bold: true,
        fontSize: 10,
      },
      detailName: {
        bold: true,
        fontSize: 10,
      },
      detailValue: {
        fontSize: 8,
      },
      tableHeader: {
        bold: true,
        fontSize: 10,
        alignment: "center",
      },
      tableCell: {
        fontSize: 8,
        alignment: "center",
      },
      tableTotal: {
        fontSize: 8,
        bold: true,
        alignment: "center",
      },
      footerText: {
        fontSize: 8,
      },
    },
    defaultStyle: {
      fontSize: 12,
    },
    images: {
      // logo: "https://ezorder-public.s3.us-east-2.amazonaws.com/logo_black_horizontal.png",
      logo: "https://ezorder-public.s3.us-east-2.amazonaws.com/industry-metal-co-long-logo.png",
    },
  };

  return docDefinition;
};

export default getPurchaseOrderDocDefinition;
