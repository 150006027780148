import { updateOrderItemPiece, trimCalcs, withId } from "./utils";

const capReducer = (state, action) => {
  const capCalcs = trimCalcs(state, false);
  switch (action.type) {
    case "UPDATE COPING CAP: ADD COPING CAP": {
      let newArr = [...state.items];
      const index = newArr.findIndex((obj) => obj.objectType === "CopingCap");
      newArr[index].capPieces.push(
        withId({
          ...action.payload,
          ...capCalcs(action.payload),
        })
      );
      return { ...state, items: [...newArr] };
    }
    case "UPDATE COPING CAP: REMOVE COPING CAP": {
      let newArr = [...state.items];
      const index = newArr.findIndex((obj) => obj.objectType === "CopingCap");
      newArr[index].capPieces.splice(action.payload, 1);
      return { ...state, items: [...newArr] };
    }
    case "UPDATE COPING CAP: PIECE DETAILS": {
      const calculations = capCalcs(action.payload);
      const updatedItems = updateOrderItemPiece(state.items, "CopingCap", {
        ...action.payload,
        ...calculations,
      });
      return { ...state, items: [...updatedItems] };
    }
    case "UPDATE COPING CAP: OVERRIDE CALCULATION": {
      const { trim, key, value } = action.payload;
      const updatedItems = updateOrderItemPiece(state.items, "CopingCap", {
        ...trim,
        ...capCalcs(trim, key, value),
      });
      return { ...state, items: [...updatedItems] };
    }
    case "UPDATE COPING CAP: RESET OVERRIDE": {
      const { trim, key } = action.payload;
      const updatedItems = updateOrderItemPiece(state.items, "CopingCap", {
        ...trim,
        ...capCalcs(trim, key, null),
      });
      return { ...state, items: [...updatedItems] };
    }
    case "UPDATE COPING CAP: RUN CALCULATIONS": {
      const calculations = capCalcs(action.payload);
      const updatedItems = updateOrderItemPiece(state.items, "CopingCap", {
        ...action.payload,
        ...calculations,
      });
      return { ...state, items: [...updatedItems] };
    }

    default:
      return state;
  }
};
export default capReducer;
