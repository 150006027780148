import { useState, useEffect, useContext } from "react";
import {
  Box,
  Fab,
  Paper,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import useCompanies from "../../../hooks/useCompanies";
import InfiniteScrollerTable from "../../../components/InfiniteScroller/InfiniteScrollerTable";
import CustomerTableRow from "./CustomerTableRow";
import ListFooter from "../../../components/InfiniteScroller/ListFooter";
import { AuthContext } from "../../../context/AuthContext";
import TableSearchAndFilter from "../../../components/TableSearchAndFilter";

const Customers = () => {
  const navigate = useNavigate();
  const { authToken } = useContext(AuthContext);
  const [
    searchQuery,
    setSearchQuery,
    results,
    onRefresh,
    refreshing,
    onLoadMore,
    loadingMore,
    total,
    hasMore,
  ] = useCompanies(authToken, 25, true);

  return (
    <Box
      sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}
    >
      <Fab
        onClick={() => {
          navigate("/customers/new");
        }}
        color="success"
        variant="extended"
        style={{
          margin: 0,
          top: "auto",
          right: 20,
          bottom: 20,
          left: "auto",
          position: "fixed",
        }}
      >
        <AddIcon /> Add New Customer
      </Fab>
      <Typography variant="h4" component="div">
        Customers
      </Typography>
      <TableSearchAndFilter
        // setSearchTerms={setSearchTerms}
        // searchTerms={searchTerms}
        searchTerms={searchQuery}
        setSearchTerms={setSearchQuery}
      />
      <InfiniteScrollerTable
        TableContainerComponent={Paper}
        TableStyle={{ minWidth: "600px" }}
        TableHead={() => (
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Company Name</TableCell>
              <TableCell>Contact Name</TableCell>
              <TableCell>Contact Email</TableCell>
              <TableCell>Contact Phone</TableCell>
              <TableCell>Tax Exempt Form Expiration</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
        )}
        items={results}
        renderRow={(item, index) => {
          return (
            <CustomerTableRow
              key={index}
              customer={item}
              onSelect={(id) => {
                navigate(`/customers/${item.id}`);
              }}
            />
          );
        }}
        onRefresh={onRefresh}
        onEndReached={onLoadMore}
        loading={refreshing || loadingMore}
        ListFooterComponent={() => {
          return <ListFooter hasMore={hasMore} onClick={onLoadMore} />;
        }}
      />
    </Box>
  );
};

export default Customers;
