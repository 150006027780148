import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { useContext, createContext } from "react";

const PrintableContext = createContext();

const Print = ({ children }) => {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <PrintableContext.Provider value={{ componentRef, handlePrint }}>
      {/* <Button onClick={handlePrint}>Print QR Codes</Button>
      <div style={{ display: "none" }}>{printable}</div> */}
      {children}
    </PrintableContext.Provider>
  );
};
export default Print;

Print.Trigger = function Trigger({ children, ...restProps }) {
  const { handlePrint } = useContext(PrintableContext);
  return (
    <div onClick={handlePrint} {...restProps}>
      {children}
    </div>
  );
};

Print.PrintableData = function PrintableData({ children, ...restProps }) {
  const { componentRef } = useContext(PrintableContext);
  return (
    <div style={{ display: "none" }}>
      <div ref={componentRef} {...restProps}>
        {children}
      </div>
    </div>
  );
};
