import { useContext } from "react";
import { Box, useMediaQuery } from "@mui/material";
import BottomNav from "./BottomNav";
import TopNav from "./TopNav";
import { AuthContext } from "../../../context/AuthContext";
import { useTheme } from "@emotion/react";
import { NotificationProvider } from "../../../context/NotificationContext";
import SnackAlert from "../../SnackAlert";
import { SnackAlertContext } from "../../../context/SnackAlertContext";

const CustomerDashboard = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const {
    openSnackAlert,
    setOpenSnackAlert,
    snackAlertSeverity,
    snackAlertTitle,
    snackAlertMessage,
  } = useContext(SnackAlertContext);

  return (
    <>
      <NotificationProvider>
        {isSmallScreen ? (
          <Box>
            <BottomNav />
          </Box>
        ) : (
          <Box>
            <TopNav />
          </Box>
        )}
      </NotificationProvider>
      <SnackAlert
        open={openSnackAlert}
        setOpen={setOpenSnackAlert}
        severity={snackAlertSeverity}
        title={snackAlertTitle}
        message={snackAlertMessage}
      />
    </>
  );
};
export default CustomerDashboard;
