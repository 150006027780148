import { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { Box, Fab, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import TableSearchAndFilter from "../../../components/TableSearchAndFilter";
import EmployeeTable from "../../../components/EmployeeTable";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";

const Employees = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [employees, setEmployees] = useState([]);
  const [searchTerms, setSearchTerms] = useState("");

  const { authToken, ezorder } = useContext(AuthContext);

  const getEmployees = async (controller) => {
    try {
      setIsLoading(true);
      let data = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      if (controller) {
        data.signal = controller.signal;
      }

      const response = await ezorder.get(
        `/all-users?search=${searchTerms}`,
        data
      );
      // console.log(response.data);
      setEmployees(response.data.employees);
    } catch (error) {
      setIsLoading(false);
      // Object.keys(error).map((item) => console.log(error[item]));
      throw Error("Unable to load employees", { cause: error });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    setTimeout(() => {
      getEmployees(controller);
    }, 1500);

    return () => {
      controller.abort(); // abort api call on unmount for cleanup
    };
  }, [searchTerms]);

  return (
    <Box
      sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}
    >
      <Fab
        onClick={() => {
          navigate(`/employees/new`);
        }}
        color="success"
        style={{
          right: 20,
          bottom: 20,
          position: "fixed",
        }}
      >
        <AddIcon />
      </Fab>
      <Typography variant="h4" component="div">
        Employees
      </Typography>
      <TableSearchAndFilter
        setSearchTerms={setSearchTerms}
        searchTerms={searchTerms}
      />
      <EmployeeTable
        employees={employees}
        onSelect={(employee) => {
          navigate(`/employees/${employee.id}`);
        }}
      />
      {isLoading ? <CircularProgress sx={{ margin: "25px" }} /> : null}
    </Box>
  );
};

export default Employees;
