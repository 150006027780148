import { useContext, useState, useEffect } from "react";
import { AuthContext } from "../context/AuthContext";

const useQuickBooksInvoices = () => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { authToken, ezorder } = useContext(AuthContext);
  const [search, setSearch] = useState("");
  const [category, setCategory] = useState("DocNumber");
  const [invoices, setInvoices] = useState([]);

  const getInvoices = async () => {
    try {
      setIsLoading(true);
      const header = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      const body = {
        searchQuery: search,
        searchCategory: category,
      };
      const res = await ezorder.post(`/quickbooks/invoices`, body, header);
      setInvoices(res.data.invoices.QueryResponse.Invoice);
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getInvoices();
  }, [category, search]);

  return {
    invoices,
    search,
    category,
    setSearch,
    setCategory,
    isLoading,
    error,
  };
};
export default useQuickBooksInvoices;
