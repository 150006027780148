import { Box, Card, Stack, Typography } from "@mui/material";
import { getCoilDetails, getFlatSheetDetails } from "../../../../orderUtils";
import { toThreeDecimals, toTwoDecimals } from "../../../../calculations/utils";

const CoilSummary = ({ order, isPrinting = false }) => {
  const coilItem = getCoilDetails(order);

  if (coilItem && coilItem.linealFeet > 0)
    return (
      <Box
        sx={{ padding: 2, margin: 1, color: isPrinting ? "#000" : "inherit" }}
      >
        <Typography variant={"h6"}>COIL</Typography>
        <Stack direction={"row"} spacing={1}>
          <Stack direction={"column"} spacing={1}>
            <Typography>Width (in.)</Typography>
            <Typography>Lineal Feet</Typography>
            <Typography>Coil Cost Per Square Foot</Typography>
            <Typography>Square Feet of Coil</Typography>
            <Typography>Total Coil Cost</Typography>
            <Typography>GP %</Typography>
            <Typography>GP $</Typography>
            <Typography>Total Sell Price</Typography>
            <Typography>Coil Price Per Square Foot</Typography>
            <Typography>Coil Price Per Lineal Foot</Typography>
          </Stack>
          <Stack direction={"column"} spacing={1}>
            <Typography>{coilItem?.width}</Typography>
            <Typography>{coilItem?.linealFeet}</Typography>
            <Typography>
              $
              {toTwoDecimals(
                coilItem?.coilCalculations?.costPerSquareFeetOfCoil
              )}
            </Typography>
            <Typography>
              {toTwoDecimals(coilItem?.coilCalculations?.squareFeetOfCoil)}
            </Typography>
            <Typography>
              ${toTwoDecimals(coilItem?.coilCalculations?.materialCost)}
            </Typography>
            <Typography>
              {toTwoDecimals(
                coilItem?.coilCalculations?.grossProfitMargin * 100
              )}
            </Typography>
            <Typography>
              ${toTwoDecimals(coilItem?.coilCalculations?.grossProfitDollars)}
            </Typography>
            <Typography>
              ${toTwoDecimals(coilItem?.coilCalculations?.totalSellPrice)}
            </Typography>
            <Typography>
              $
              {toThreeDecimals(
                coilItem?.coilCalculations?.pricePerSquareFeetOfCoil
              )}
            </Typography>
            <Typography>
              $
              {toThreeDecimals(
                coilItem?.coilCalculations?.pricePerLinealFootOfCoil
              )}
            </Typography>
          </Stack>
        </Stack>
      </Box>
    );
};

export default CoilSummary;
