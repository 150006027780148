import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import { AuthContext } from "../../../context/AuthContext";
import { SnackAlertContext } from "../../../context/SnackAlertContext";

const SalesRepModal = ({ open, handleClose, onSelect }) => {
  const theme = useTheme();
  const { openSnackMessage } = useContext(SnackAlertContext);
  const navigate = useNavigate();
  const { authToken, ezorder, decoded } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);
  const [employees, setEmployees] = useState([]);
  const [searchTerms, setSearchTerms] = useState("");

  const getEmployees = async (controller) => {
    try {
      setIsLoading(true);
      let data = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      if (controller) {
        data.signal = controller.signal;
      }

      const response = await ezorder.get(`/users?search=${searchTerms}`, data);
      // console.log(response.data);
      setEmployees(response.data.employees);
    } catch (error) {
      setIsLoading(false);
      // Object.keys(error).map((item) => console.log(error[item]));
      throw Error("Unable to load employees", { cause: error });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    setTimeout(() => {
      getEmployees(controller);
    }, 1500);

    return () => {
      controller.abort(); // abort api call on unmount for cleanup
    };
  }, [searchTerms]);

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ background: theme.palette.secondary.main }}>
          <DialogTitle>Sales Rep</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Assign a sales rep for this customer
            </DialogContentText>
            <TableContainer component={Box}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Full Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Role</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {employees.map((user, index) => {
                    return (
                      <TableRow
                        key={index}
                        onClick={() => {
                          // navigate(`/customers/${company.id}/users/${user.id}`);
                          onSelect(user);
                        }}
                      >
                        <TableCell>{user.fullName}</TableCell>
                        <TableCell>{user.email}</TableCell>
                        <TableCell>{user.role}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
        </Box>
      </Dialog>
    </>
  );
};
export default SalesRepModal;
