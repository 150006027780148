import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import PeopleOutlineOutlinedIcon from "@mui/icons-material/PeopleOutlineOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";

const MenuDrawer = ({ open, toggleDrawer }) => {
  const { signOut } = React.useContext(AuthContext);
  const navigate = useNavigate();
  const list = () => (
    <Box
      sx={{ width: 250, marginTop: "2rem" }}
      role="presentation"
      onClick={toggleDrawer}
    >
      {/* <List>
        <ListItem key={"Tax Exempt Forms"} disablePadding>
          <ListItemButton onClick={() => navigate("/tax-exempt-forms")}>
            <ListItemIcon>
              <InsertDriveFileOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={"Tax Exempt Forms"} />
          </ListItemButton>
        </ListItem>
        <ListItem key={"Employees"} disablePadding>
          <ListItemButton onClick={() => navigate("/employees")}>
            <ListItemIcon>
              <PeopleOutlineOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={"Employees"} />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider /> */}
      <List>
        <ListItem key={"Account Settings"} disablePadding>
          <ListItemButton onClick={() => navigate("/settings")}>
            <ListItemIcon>
              <SettingsOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={"Account Settings"} />
          </ListItemButton>
        </ListItem>
        <ListItem key={"Logout"} disablePadding>
          <ListItemButton
            onClick={() => {
              signOut();
              navigate("/");
            }}
          >
            <ListItemIcon>
              <LogoutOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={"Logout"} />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <div>
      <React.Fragment>
        {/* <Button onClick={toggleDrawer()}>{anchor}</Button> */}
        <Drawer anchor={"right"} open={open} onClose={toggleDrawer}>
          {list()}
        </Drawer>
      </React.Fragment>
    </div>
  );
};
export default MenuDrawer;
