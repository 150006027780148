import { useContext, useState, useEffect } from "react";
import { useDebounce } from "use-debounce";
import { AuthContext } from "../context/AuthContext";

const useVendorPO = (authToken, id) => {
  const { ezorder } = useContext(AuthContext);
  // Pagination
  const [po, setPo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const getPo = async (controller) => {
    try {
      setIsLoading(true);
      let data = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      if (controller) {
        data.signal = controller.signal;
      }
      const response = await ezorder.get(`/admin/purchase-orders/${id}`, data);
      console.log(response.data);
      setPo(response.data.po);
    } catch (error) {
      // console.log(error);
      // Object.keys(error).map((item) => console.log(error[item]));
      throw Error("Unable to load orders", { cause: error });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    getPo();

    return () => {
      controller.abort(); // abort api call on unmount for cleanup
    };
  }, []);

  return [po, getPo, isLoading];
};
export default useVendorPO;
