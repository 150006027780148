import { useContext, useEffect, useState } from "react";
import ColorsList from "../../../components/SettingsComponents/Colors/ColorsList";
import usePaintManufacturers from "../../../hooks/usePaintManufacturers";
import { Box, Button, MenuItem, TextField } from "@mui/material";
import { Add } from "@mui/icons-material";
import AddPaintManufacturerDialog from "../../../components/AddPaintManufacturerDialog";
import { LoadingButton } from "@mui/lab";
import { AuthContext } from "../../../context/AuthContext";
import { SnackAlertContext } from "../../../context/SnackAlertContext";

const AdminColorCatalog = () => {
  const { paintManufacturers, getPaintManufacturers, refreshing } =
    usePaintManufacturers();
  const [currentManufacturer, setCurrentManufacturer] = useState(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { openSnackMessage } = useContext(SnackAlertContext);
  const { authToken, ezorder } = useContext(AuthContext);
  const header = {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  };

  useEffect(() => {
    if (paintManufacturers && paintManufacturers.length > 0) {
      setCurrentManufacturer(paintManufacturers[0]);
    }
  }, [paintManufacturers]);

  const addPaintManufacturer = async (name) => {
    try {
      setLoading(true);
      const res = await ezorder.post(
        `/admin/app/paint-manufacturers`,
        { name },
        header
      );
      getPaintManufacturers();
      openSnackMessage("success", "Paint Manufacturer Added");
    } catch (error) {
      console.log(error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage("error", error.response.data.error);
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", error);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Box
        sx={{ display: "flex", flexDirection: "row", paddingBottom: "16px" }}
      >
        <TextField
          sx={{ width: 300 }}
          size="small"
          label="Select Manufacturer"
          select
          value={currentManufacturer ? currentManufacturer.id : -1}
          onChange={(e) => {
            console.log("SELECTED", e.target);
            let indexById = paintManufacturers.findIndex(
              (element) => element.id == parseInt(e.target.value)
            );
            let selected = paintManufacturers[indexById];
            setCurrentManufacturer(selected);
          }}
        >
          {paintManufacturers?.map((paintManufacturer) => (
            <MenuItem key={paintManufacturer.id} value={paintManufacturer.id}>
              {paintManufacturer.name}
            </MenuItem>
          ))}
        </TextField>
        <LoadingButton
          loading={refreshing || loading}
          onClick={() => {
            setOpen(true);
          }}
        >
          <Add /> Add New Manufacturer
        </LoadingButton>
      </Box>
      <ColorsList
        manufacturerId={currentManufacturer ? currentManufacturer.id : 0}
        manufacturerName={currentManufacturer ? currentManufacturer.name : ""}
      />
      <AddPaintManufacturerDialog
        open={open}
        setOpen={setOpen}
        onSubmit={(name) => {
          addPaintManufacturer(name);
          console.log(name);
        }}
      />
    </>
  );
};
export default AdminColorCatalog;
