import { useContext, useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import axios from "axios";
import * as queryString from "query-string";
import ezorder from "../api/ezorderNoAuth";
import { QuickBooksContext } from "../context/QuickBooksContext";
import { useNavigate } from "react-router-dom";
import { Button, CircularProgress } from "@mui/material";

const QuickBooksAuth = () => {
  let navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [authUri, setAuthUri] = useState("");
  const { isQbAuthenticated, setIsQbAuthenticated } =
    useContext(QuickBooksContext);
  let { code, state, realmId } = useParams();
  // console.log(code, state, realmId);

  let location = useLocation();

  useEffect(() => {
    const controller = new AbortController();
    const queryParams = queryString.parse(location.search, true);
    if (Object.keys(queryParams).length > 0) {
      if (!isQbAuthenticated) {
        getTokens(window.location.href, controller);
      }
    } else {
      redirectToQBAuth();
    }
    return () => {
      controller.abort(); // abort api call on unmount for cleanup
    };
  }, [location, isQbAuthenticated]);

  const redirectToQBAuth = async () => {
    try {
      setIsLoading(true);
      // console.log("Get QB Auth URI");
      const response = await ezorder.get("/quickbooks/auth");
      // console.log(response.data);
      setAuthUri(response.data.authUri);
    } catch (error) {
      // console.log(error);
      throw Error("Unable to redirect to QuickBooks Auth page", {
        cause: error,
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Manual OAuth2 endpoints https://developer.intuit.com/app/developer/qbo/docs/develop/authentication-and-authorization/oauth-openid-discovery-doc
  const getTokens = async (url, controller) => {
    try {
      setIsLoading(true);
      let signal = {};
      if (controller) {
        signal.signal = controller.signal;
      }
      // console.log("GET TOKENS", url);

      const response = await ezorder.post(
        "/quickbooks/auth/tokens",
        {
          redirectUrl: url,
        },
        signal
      );
      // console.log(response.data);
      setIsQbAuthenticated(true);
      setIsLoading(false);
      navigate("/orders", { replace: true });
    } catch (error) {
      // console.log(error);
      throw Error("Unable to get QuickBooks Tokens", { cause: error });
    } finally {
      setIsLoading(false);
    }
  };

  if (isQbAuthenticated === undefined || isLoading === undefined) return null;
  return (
    <div
      className="App"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      {isQbAuthenticated || isLoading ? (
        <CircularProgress sx={{}} />
      ) : authUri ? (
        <Button
          sx={{ margin: "auto" }}
          variant="contained"
          size="large"
          className="App-link"
          href={authUri}
        >
          Quickbooks Connect
        </Button>
      ) : null}
      {/* {isLoading && <CircularProgress sx={{}} />} */}
      {/* {authUri && !isQbAuthenticated && (
        <Button
          sx={{ margin: "auto" }}
          variant="contained"
          size="large"
          className="App-link"
          href={authUri}
        >
          Quickbooks Connect
        </Button>
      )} */}
    </div>
  );
};

export default QuickBooksAuth;
