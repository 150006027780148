import { useContext, useState, useEffect } from "react";
import { AuthContext } from "../context/AuthContext";

const useAllColors = () => {
  const [colors, setColors] = useState([]);
  const [refreshing, setRefreshing] = useState(false);
  const { authToken, ezorder } = useContext(AuthContext);
  const header = {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  };

  const getColors = async (controller) => {
    try {
      setRefreshing(true);
      let data = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };

      if (controller) {
        data.signal = controller.signal;
      }

      const response = await ezorder.get(`/admin/app/colors`, data);
      setColors(response.data.colors);
      return response.data.colors;
    } catch (error) {
      console.log(error);
    } finally {
      setRefreshing(false);
    }
  };

  const addColor = async (color) => {
    try {
      setRefreshing(true);
      const res = await ezorder.post(`/admin/app/colors`, color, header);
      getColors();
    } catch (err) {
      console.log(err);
    } finally {
      setRefreshing(false);
    }
  };

  useEffect(() => {
    const controller = new AbortController();

    if (colors.length === 0) {
      getColors();
    }

    return () => {
      controller.abort(); // abort api call on unmount for cleanup
    };
  }, []);

  return { colors, getColors, addColor, refreshing };
};
export default useAllColors;
