import {
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { useState } from "react";
import { useTheme } from "@emotion/react";
import { LoadingButton } from "@mui/lab";

const DeleteTrimDialog = ({ open, handleClose, onConfirm, loading }) => {
  const theme = useTheme();
  const [errorMsg, setErrorMessage] = useState(null);

  return (
    <Dialog
      keepMounted
      scroll={"paper"}
      maxWidth="sm"
      fullWidth={true}
      PaperProps={{
        style: {
          background: theme.palette.secondary.main,
        },
      }}
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>Delete Trim</DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <DialogContentText>Do you want to delete this Trim?</DialogContentText>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          onClick={() => {
            handleClose();
          }}
          loading={loading}
        >
          Back
        </LoadingButton>
        <LoadingButton
          variant="contained"
          loading={loading}
          onClick={onConfirm}
        >
          Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
export default DeleteTrimDialog;
