import { useTheme } from "@emotion/react";
import { Fab, useMediaQuery } from "@mui/material";
const FabButton = (props) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const fabStyleMobile = { position: "fixed", bottom: 110, right: 16 };
  const fabStyleWeb = { position: "fixed", bottom: 70, right: 56 };

  return (
    <Fab
      color="primary"
      variant="extended"
      aria-label="add"
      sx={isSmallScreen ? fabStyleMobile : fabStyleWeb}
      {...props}
    >
      {props.children}
    </Fab>
  );
};
export default FabButton;
