import { useTheme } from "@emotion/react";
import { Box, Typography, useMediaQuery } from "@mui/material";

const OrderTitle = ({ order, includeStatus = true, textColor }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  if (order)
    return (
      <>
        {order && order.company && order.project && order.orderStatus && (
          <Box sx={{ marginBottom: "16px" }}>
            <Typography variant="h5">
              {!order.orderStatus ||
              !order.orderStatus.name ||
              order.orderStatus.name == "SAVED" ||
              order.orderStatus.name == "QUOTE_REQUESTED" ||
              order.orderStatus.name == "ESTIMATE_APPROVAL_NEEDED"
                ? "Quote "
                : "Order "}
              #{order.id} - {order.company.name}
            </Typography>
            <Typography
              variant="h7"
              sx={{ display: "block" }}
              color={textColor ? textColor : "#c0c0c0"}
            >
              {order.company.isVendorOrSupplier
                ? "Vendor/Supplier Customer"
                : "Project"}
              : {order.project.name}
            </Typography>
            {order.vendorProjectName && (
              <Typography
                variant="h7"
                sx={{ display: "block" }}
                color={textColor ? textColor : "#c0c0c0"}
              >
                Project: {order.vendorProjectName}
              </Typography>
            )}
            {order.subProject && (
              <Typography
                variant="h7"
                sx={{ display: "block" }}
                color={textColor ? textColor : "#c0c0c0"}
              >
                Sub-Project: {order.subProject}
              </Typography>
            )}
            <Typography
              variant="h7"
              sx={{ display: "block" }}
              color={textColor ? textColor : "#c0c0c0"}
            >
              {order.poNumber ? `PO#: ${order.poNumber}` : ""}
            </Typography>
            {includeStatus && (
              <Typography
                variant="h7"
                color={textColor ? textColor : "#c0c0c0"}
                sx={{ fontStyle: "italic", display: "block" }}
              >
                {order.isUnconfirmedCutlist &&
                order.orderStatus.name == "QUOTE_REQUESTED"
                  ? "QUOTED - Budget Only"
                  : order.orderStatus.description}
              </Typography>
            )}
          </Box>
        )}
      </>
    );
};

export default OrderTitle;
