import calculateCoil from "../components/NewDetail/calculations/coil";
import { withId } from "./utils";

const coilReducer = (state, action) => {
  const coilCalcs = (coil) => {
    const variables = coil?.variables ? coil.variables : state.defaultVariables;
    const calcs = {
      overrides: coil?.overrides,
      coilCalculations: calculateCoil(coil, variables, coil?.overrides, state),
      variables: variables,
    };
    return calcs;
  };

  const overrides = (coil, key, value) => {
    const variables = coil?.variables ? coil.variables : state.defaultVariables;

    const overrides = {
      ...coil?.overrides,
      [key]: value,
    };

    const calcs = {
      coilCalculations: calculateCoil(coil, variables, overrides, state),
      overrides: overrides,
      variables: variables,
    };

    return calcs;
  };

  const updateCoil = (key) => {
    const value = action.payload;
    let orderItemsCopy = [...state.items];
    const coilItemIndex = orderItemsCopy.findIndex(
      (obj) => obj.objectType === "Coil"
    );
    orderItemsCopy[coilItemIndex][key] = value;
    orderItemsCopy[coilItemIndex] = {
      ...orderItemsCopy[coilItemIndex],
      ...coilCalcs(orderItemsCopy[coilItemIndex]),
    };
    return orderItemsCopy;
  };

  switch (action.type) {
    case "UPDATE COIL: LINEAL FEET": {
      const orderItemsUpdate = updateCoil("linealFeet");
      return { ...state, items: [...orderItemsUpdate] };
    }
    case "UPDATE COIL: WIDTH": {
      const orderItemsUpdate = updateCoil("width");
      return { ...state, items: [...orderItemsUpdate] };
    }
    case "UPDATE COIL: OVERRIDE CALCULATION": {
      const { key, value } = action.payload;
      let newArr = [...state.items];
      const index = newArr.findIndex((obj) => obj.objectType === "Coil");
      newArr[index] = {
        ...newArr[index],
        ...overrides(newArr[index], key, value),
      };

      return { ...state, items: [...newArr] };
    }
    case "UPDATE COIL: SET CALCULATIONS": {
      let newArr = [...state.items];
      const index = newArr.findIndex((obj) => obj.objectType === "Coil");
      newArr[index] = {
        ...newArr[index],
        ...coilCalcs(newArr[index]),
      };
      return { ...state, items: [...newArr] };
    }
    case "UPDATE COIL: RESET OVERIDE": {
      let newArr = [...state.items];
      const index = newArr.findIndex((obj) => obj.objectType === "Coil");
      newArr[index] = {
        ...newArr[index],
        ...overrides(newArr[index], action.payload, null),
      };

      return { ...state, items: [...newArr] };
    }
    default:
      return state;
  }
};
export default coilReducer;
