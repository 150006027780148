import { Box, Grid, Typography } from "@mui/material";
import PdfViewer from "../../../components/PdfViewer";
import usePageName from "../../../hocs/usePageName";

const PanelInfo = () => {
  usePageName({ heading: "Industry Panel Specs" });
  return (
    <Grid container spacing={2} padding={2}>
      <Grid item xs={12} md={6}>
        <Typography variant="h6">Wave Panel</Typography>
        <PdfViewer
          fileUri={`https://ezorder-public.s3.us-east-2.amazonaws.com/panel-pdfs/IND+Wave+Panel.pdf`}
          downloadName={`IND-Wave-Panel.pdf`}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="h6">SL-175</Typography>
        <PdfViewer
          fileUri={`https://ezorder-public.s3.us-east-2.amazonaws.com/panel-pdfs/IND+SL-175.pdf`}
          downloadName={`IND-SL-175.pdf`}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="h6">Pan & Seam</Typography>
        <PdfViewer
          fileUri={`https://ezorder-public.s3.us-east-2.amazonaws.com/panel-pdfs/IND+Pan+%26+Seam.pdf`}
          downloadName={`Pan-And-Seam.pdf`}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="h6">NS10</Typography>
        <PdfViewer
          fileUri={`https://ezorder-public.s3.us-east-2.amazonaws.com/panel-pdfs/IND+NS10.pdf`}
          downloadName={`NS10.pdf`}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="h6">Flush Wall</Typography>
        <PdfViewer
          fileUri={`https://ezorder-public.s3.us-east-2.amazonaws.com/panel-pdfs/IND+Flush+Wall.pdf`}
          downloadName={`Flush-Wall.pdf`}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="h6">Flat Seam</Typography>
        <PdfViewer
          fileUri={`https://ezorder-public.s3.us-east-2.amazonaws.com/panel-pdfs/IND+Flat+Seam.pdf`}
          downloadName={`Flat-Seam.pdf`}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="h6">7.8 Corrugated</Typography>
        <PdfViewer
          fileUri={`https://ezorder-public.s3.us-east-2.amazonaws.com/panel-pdfs/IND+7.8+Corrugated.pdf`}
          downloadName={`7-8-Corrugated.pdf`}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="h6">7.2 Panel</Typography>
        <PdfViewer
          fileUri={`https://ezorder-public.s3.us-east-2.amazonaws.com/panel-pdfs/IND+7.2+Panel.pdf`}
          downloadName={`7-2-Panel.pdf`}
        />
      </Grid>
    </Grid>
  );
};
export default PanelInfo;
