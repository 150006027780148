import {
  CheckCircle,
  CheckCircleOutline,
  Circle,
  ErrorOutline,
  Pending,
  PendingOutlined,
  RadioButtonUnchecked,
} from "@mui/icons-material";
import React from "react";

const OrderStatusIcon = ({ orderStatus }) => {
  switch (orderStatus) {
    case "UNCONFIRMED":
      return (
        <ErrorOutline
          sx={{
            // yellow/orange
            color: "#ffa733",
            fontSize: "20px",
            marginBottom: "-4px",
          }}
        />
      );
    case "CANCELLED":
      break;
    case "SAVED":
      return (
        <RadioButtonUnchecked
          sx={{
            // blue
            color: "#2196f3",
            fontSize: "20px",
            marginBottom: "-4px",
          }}
        />
      );
      break;
    case "QUOTE_REQUESTED":
      return (
        <CheckCircleOutline
          color="success"
          sx={{
            // Neutral
            // color: "lightgray",
            fontSize: "20px",
            marginBottom: "-4px",
          }}
        />
      );
    case "ESTIMATE_APPROVAL_NEEDED":
      return (
        <ErrorOutline
          sx={{
            // yellow/orange
            color: "#ffa733",
            fontSize: "20px",
            marginBottom: "-4px",
          }}
        />
      );
    case "ESTIMATE_APPROVED":
      return (
        <CheckCircleOutline
          color="success"
          sx={{
            // Neutral
            // color: "lightgray",
            fontSize: "20px",
            marginBottom: "-4px",
          }}
        />
      );
    case "PENDING_PAYMENT":
      return (
        <ErrorOutline
          sx={{
            // yellow/orange
            color: "#ffa733",
            fontSize: "20px",
            marginBottom: "-4px",
          }}
        />
      );
    case "IN_PROGRESS":
      return (
        <PendingOutlined
          sx={{
            // blue
            color: "#2196f3",
            fontSize: "20px",
            marginBottom: "-4px",
          }}
        />
      );
    case "READY_FOR_PICKUP":
      return (
        <ErrorOutline
          sx={{
            // yellow/orange
            color: "#ffa733",
            fontSize: "20px",
            marginBottom: "-4px",
          }}
        />
      );
    case "CLOSED":
      return (
        <CheckCircleOutline
          color="success"
          sx={{
            // color: "lightgray",
            fontSize: "20px",
            marginBottom: "-4px",
          }}
        />
      );
      break;
    default:
      return null;
  }
};

export default OrderStatusIcon;
