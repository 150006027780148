import { useState, useContext, useEffect } from "react";
import usePageName from "../../../hocs/usePageName";
import { AuthContext } from "../../../context/AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import useTrimCatalog from "../../../hooks/useTrimCatalog";
import { Box, Typography } from "@mui/material";
import PrintCatalog from "../../../components/Catalog/PrintCatalog";
import useTrimCatalogAdmin from "../../../hooks/useTrimCatalogAdmin";

const PrintCustomerStockTrimCatalog = () => {
  const { companyId } = useParams();
  const navigate = useNavigate();
  const { authToken, ezorder, role, decoded } = useContext(AuthContext);
  const [
    searchQuery,
    setSearchQuery,
    catalogFilter,
    setCatalogFilter,
    results,
    onRefresh,
    refreshing,
    onLoadMore,
    loadingMore,
    total,
    hasMore,
  ] = useTrimCatalogAdmin(authToken, 2000, null, "stock", companyId);
  const [company, setCompany] = useState(null);

  const getCompanyById = async () => {
    try {
      const res = await ezorder.get(`/admin/companies/${companyId}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      setCompany(res.data.company);
      return company;
    } catch (error) {
      console.log(error);
    }
    return null;
  };

  useEffect(() => {
    getCompanyById();
  }, [companyId]);

  if (company)
    return (
      <Box sx={{ heght: "100%" }}>
        <Typography variant="h4" component="div">
          Print {company.name} Catalog
        </Typography>
        <PrintCatalog items={results} companyName={company.name} />
      </Box>
    );
};
export default PrintCustomerStockTrimCatalog;
