import SettingsView from "./View";
import usePageName from "../../../hocs/usePageName";
import { useState, useContext, useEffect } from "react";
import { AuthContext } from "../../../context/AuthContext";
import UpdateUserDetails from "./UpdateUserDetails";
import { useNavigate } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import ChangePasswordDialog from "./ChangePasswordDialog";
import { SnackAlertContext } from "../../../context/SnackAlertContext";

const Settings = () => {
  usePageName({ heading: "Account Settings" });
  const { openSnackMessage } = useContext(SnackAlertContext);
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const { authToken, decoded, ezorder } = useContext(AuthContext);
  const [changePassword, setChangePassword] = useState(false);

  const updatePassword = async (oldPassword, newPassword) => {
    console.log("UPDATE PASSWORD");
    try {
      const response = await ezorder.put(
        `/auth/updatePassword`,
        {
          oldPassword,
          newPassword,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      if (response.status == 200 || response.status == 201) {
        setChangePassword(false);
        openSnackMessage("success", "Password changed");
        getUserDetails();
      } else {
        openSnackMessage("error", "Please check your password");
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage("error", error.response.data.error);
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", error);
      }
    } finally {
    }
  };

  const updateUserDetails = async (details) => {
    try {
      setLoading(true);
      const response = await ezorder.put(`/user-details`, details, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      if (response.status == 200 || response.status == 201) {
        openSnackMessage("success", "User Details Updated");
        getUserDetails();
      } else {
        openSnackMessage("error", "Failed to Update");
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage("error", error.response.data.error);
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", error);
      }
    } finally {
      setLoading(false);
    }
  };

  const getUserDetails = async () => {
    console.log("UPDATE PASSWORD");
    try {
      setLoading(true);
      const response = await ezorder.get(`/user-details`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      setUserDetails(response.data.user);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getUserDetails();
  }, [authToken]);

  if (userDetails)
    return (
      <Box sx={{ padding: "16px" }}>
        {/* <SettingsView
        fullName={decoded.fullName}
        email={decoded.email}
        updatePassword={updatePassword}
      /> */}

        <Typography variant="h5" sx={{ marginTop: "16px" }}>
          User Details
        </Typography>
        <Box sx={{ marginTop: "16px" }}>
          <UpdateUserDetails
            userDetails={userDetails}
            handleSubmit={(details) => {
              updateUserDetails(details);
            }}
            loading={loading}
          />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="h5" sx={{ marginTop: "16px" }}>
            Password
          </Typography>
          <Button
            sx={{ width: "160px" }}
            onClick={() => setChangePassword(true)}
          >
            Change Password
          </Button>
        </Box>

        <ChangePasswordDialog
          open={changePassword}
          handleClose={() => {
            setChangePassword(false);
          }}
          onSubmit={(currentPassword, newPassword) => {
            updatePassword(currentPassword, newPassword);
          }}
        />
      </Box>
    );
};
export default Settings;
