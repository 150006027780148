import { Box, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { useState } from "react";

export default function ExpandableImage({
  src,
  imageTitle,
  thumbnailHeight = 150,
  thumbnailWidth = 150,
  expandedHeight = "100%",
  expandedWidth = "100%",
  children,
  ...props
}) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Box
        onClick={handleClickOpen}
        sx={{
          width: thumbnailWidth,
          height: thumbnailHeight,
          // border: "1px solid #000",
          borderRadius: "5px",
          overflow: "hidden",
          cursor: "zoom-in",
        }}
      >
        <img
          src={src}
          style={{
            width: thumbnailWidth,
            height: thumbnailHeight,
            objectFit: "contain",
          }}
          alt="no image"
          {...props}
        />
      </Box>
      <Dialog open={open} onClose={handleClose} maxWidth="lg">
        {imageTitle && <DialogTitle>{imageTitle}</DialogTitle>}
        <DialogContent>
          <img
            src={src}
            style={{
              width: expandedWidth,
              height: expandedHeight,
              objectFit: "contain",
            }}
            alt="no image"
            {...props}
          />
          {children}
        </DialogContent>
      </Dialog>
    </>
  );
}
