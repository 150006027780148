import usePageName from "../../../hocs/usePageName";
import { useNavigate, useParams } from "react-router-dom";
import { useContext, useState } from "react";
import { AuthContext } from "../../../context/AuthContext";
import SearchBar from "../../../components/SearchBar";
import InfiniteScroller from "../../../components/InfiniteScroller/InfiniteScroller";
import FabButton from "../../../components/CustomerComponents/FabButton";
import { Add, ArrowRight, Check, ChevronRight } from "@mui/icons-material";
import ListFooter from "../../../components/InfiniteScroller/ListFooter";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  ListItem,
  ListItemText,
  Modal,
  Tab,
  Tabs,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import ProjectCard from "../Projects/ProjectCard";
import useProjects from "../../../hooks/useProjects";
import { SnackAlertContext } from "../../../context/SnackAlertContext";

import ActiveOrders from "./OrdersTabs/ActiveOrders";
import ReadyOrders from "./OrdersTabs/ReadyOrders";
import FulfilledOrders from "./OrdersTabs/FulfilledOrders";
import { LoadingButton } from "@mui/lab";
import { initialOrder } from "../../../context/MobileOrderContext";

const Orders = ({ resource, activeTab }) => {
  usePageName({ heading: "Orders" });
  const navigate = useNavigate();
  const { openSnackMessage } = useContext(SnackAlertContext);
  const { authToken, ezorder, decoded } = useContext(AuthContext);
  const theme = useTheme();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [currentTab, setCurrentTab] = useState("active");

  const [
    project_searchQuery,
    project_setSearchQuery,
    project_results,
    project_onRefresh,
    project_refreshing,
    project_onLoadMore,
    project_loadingMore,
    project_total,
    project_hasMore,
  ] = useProjects(authToken, 20, true);
  const [projectName, setProjectName] = useState("");

  const [createModalOpen, setCreateModalOpen] = useState(false);
  const handleClose = () => {
    setCreateModalOpen(false);
    setProjectName("");
  };

  const handleSubmitProject = async ({ name }) => {
    try {
      if (!name) return;
      setIsSubmitting(true);
      const result = await ezorder.post(
        "/projects",
        { name },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      if (decoded.isVendorOrSupplier) {
        openSnackMessage("success", "Customer Created");
      } else {
        openSnackMessage("success", "Project Created");
      }
      let newProject = result.data.project;
      createNewOrderForProject(newProject.id);
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage("error", error.response.data.error);
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", error);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const createNewOrderForProject = async (projectId) => {
    try {
      setIsSubmitting(true);
      const requestBody = {
        projectId: parseInt(projectId),
        orderDetails: { ...initialOrder },
      };

      const response = await ezorder.post(`/orders`, requestBody, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      console.log(response);
      if (response.status == 200 || response.status === 201) {
        navigate(`/quotes/${response.data.order.id}`, {
          replace: true,
        });
      }
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage("error", error.response.data.error);
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", error);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Box
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        {/* <Tabs
          value={currentTab}
          onChange={(e, newValue) => {
            setCurrentTab(newValue);
          }}
        >
          <Tab value="active" label="Current / Active" wrapped />
          <Tab value="closed" label="Picked Up / Delivered" wrapped />
        </Tabs> */}
        <Tabs
          sx={{ marginBottom: "16px" }}
          value={activeTab}
          onChange={(event, newValue) => {
            navigate(`/${resource}/${newValue}`, { replace: true });
          }}
          centered
        >
          <Tab label="Active" value="active" />
          <Tab label="Ready For Pickup" value="ready" />
          <Tab label="Fulfilled" value="fulfilled" />
        </Tabs>
      </Box>
      <Box hidden={activeTab != "active"}>
        <ActiveOrders />
      </Box>
      <Box hidden={activeTab != "ready"}>
        <ReadyOrders />
      </Box>
      <Box hidden={activeTab != "fulfilled"}>
        <FulfilledOrders />
      </Box>
      <FabButton
        color="success"
        onClick={() => {
          //navigate("/projects");
          setCreateModalOpen(true);
        }}
      >
        <Add sx={{ mr: 1 }} />
        Request a Quote
      </FabButton>
      <Dialog
        keepMounted
        scroll={"paper"}
        maxWidth="sm"
        fullWidth={true}
        fullScreen={fullScreen}
        PaperProps={{
          style: {
            background: theme.palette.secondary.main,
          },
        }}
        open={createModalOpen}
        onClose={handleClose}
      >
        <DialogTitle>Create NEW Quote Request</DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <DialogContentText>
            Create a Quote Request For A New{" "}
            {decoded.isVendorOrSupplier ? "Customer" : "Project"}
          </DialogContentText>
          <Box
            sx={{
              marginTop: "8px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <TextField
              size="small"
              sx={{ flex: 1 }}
              label={`Enter New ${
                decoded.isVendorOrSupplier ? "Customer" : "Project"
              } Name`}
              value={projectName}
              onChange={(e) => {
                setProjectName(e.target.value);
              }}
            />
            <LoadingButton
              loading={isSubmitting}
              sx={{ marginLeft: "8px" }}
              variant="contained"
              color="success"
              onClick={() =>
                handleSubmitProject({
                  name: projectName,
                })
              }
            >
              <Check />
            </LoadingButton>
            {/* <IconButton variant="contained" aria-label="Example">
              <ChevronRight />
            </IconButton> */}
          </Box>
          <DialogContentText sx={{ marginTop: "32px" }}>
            Or Create a Quote Request For an Existing{" "}
            {decoded.isVendorOrSupplier ? "Customer" : "Project"}
          </DialogContentText>
          <SearchBar
            label={
              decoded.isVendorOrSupplier
                ? "Search Customers"
                : "Search Projects"
            }
            searchQuery={project_searchQuery}
            setSearchQuery={project_setSearchQuery}
          />
          <Box
            sx={{
              flex: 1,
              minHeight: project_results.length > 0 ? "200px" : "0px",
              overflowY: "scroll",
            }}
          >
            <InfiniteScroller
              items={project_results}
              renderItem={(item, index) => {
                return (
                  <>
                    <ListItem
                      key={item.id}
                      project={item}
                      onClick={() => {
                        if (!isSubmitting) createNewOrderForProject(item.id);
                      }}
                    >
                      <ListItemText primary={item.name} />
                    </ListItem>
                    <Divider />
                  </>
                );
              }}
              onRefresh={project_onRefresh}
              onEndReached={project_onLoadMore}
              loading={project_refreshing || project_loadingMore}
              ListFooterComponent={() => {
                return (
                  <ListFooter
                    hasMore={project_hasMore}
                    onClick={project_onLoadMore}
                  />
                );
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Orders;
