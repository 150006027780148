import { useContext, useState, useEffect } from "react";
import { AuthContext } from "../context/AuthContext";

const useGalvanizedFlatOptions = () => {
  const [galvanizedFlatOptions, setGalvanizedFlatOptions] = useState([]);
  const [refreshing, setRefreshing] = useState(false);
  const { authToken, ezorder } = useContext(AuthContext);
  const header = {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  };

  const getGalvanizedFlatOptions = async (controller) => {
    try {
      setRefreshing(true);
      let data = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };

      if (controller) {
        data.signal = controller.signal;
      }

      const response = await ezorder.get(`/app/galvanized-fs`, data);
      setGalvanizedFlatOptions(response.data.galvanizedFlats);
      return response.data.galvanizedFlats;
    } catch (error) {
      console.log(error);
    } finally {
      setRefreshing(false);
    }
  };
  useEffect(() => {
    const controller = new AbortController();

    if (galvanizedFlatOptions.length === 0) {
      getGalvanizedFlatOptions();
    }

    return () => {
      controller.abort(); // abort api call on unmount for cleanup
    };
  }, []);

  return {
    galvanizedFlatOptions,
    getGalvanizedFlatOptions,
    refreshing,
  };
};
export default useGalvanizedFlatOptions;
