import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SnackAlertContext } from "../../../context/SnackAlertContext";
import { AuthContext } from "../../../context/AuthContext";
import CompanySettingsForm from "../../customer/CompanySettings/CompanySettingsForm";
import createPresignedUrl from "../../../helper/createPresignedUrl";
import uploadImage from "../../../helper/uploadImage";

const EditCustomerSettings = () => {
  const { companyId } = useParams();
  const navigate = useNavigate();
  const { authToken, ezorder } = useContext(AuthContext);
  const { openSnackMessage } = useContext(SnackAlertContext);
  const [companyDetails, setCompanyDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const getCompanyDetails = async () => {
    try {
      setLoading(true);
      console.log("GET COMPANY DETAILS", companyId);
      let data = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      const res = await ezorder.get(`/admin/companies/${companyId}`, data);
      let company = res.data.company;
      setCompanyDetails(company);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const updateCompanyDetails = async (values) => {
    try {
      setLoading(true);
      const authHeader = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      const update = {
        name: values.companyName,
        contactName: values.companyContactName,
        contactPhone: values.companyContactPhone,
        contactEmail: values.companyContactEmail,
        copyOwnerToAllNotifications: values.copyOwnerToAllNotifications,
      };
      const res = await ezorder.put(
        `/admin/companies/${companyId}`,
        update,
        authHeader
      );
      let company = res.data.company;
      openSnackMessage("success", "Updated");
      setCompanyDetails(company);
    } catch (error) {
      console.log(error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage("error", error.response.data.error);
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", error);
      }
    } finally {
      setLoading(false);
    }
  };

  const updateLogo = async (imageBlob) => {
    try {
      setLoading(true);
      let presignedUrlResponse = await createPresignedUrl(
        ezorder,
        authToken,
        "WRITE", // READ || WRITE
        imageBlob.name, //imageBlobCropped.name, // Ex: "asdfasfsaf.jpg". The backend replaces the name so it doesnt matter and add back the extension (.jpg)
        imageBlob.type, //imageBlobCropped.type, // Ex: "image/jpeg"
        "company-logo", //"trims", "coping-caps", "cleats", "bench-work", "tax-forms", "job-quotes", "estimates", "signatures", "signed-estimates"
        companyId // Customer's Company ID or Null
      );
      await uploadImage(presignedUrlResponse.uploadUrl, imageBlob); //imageBlobCropped);

      let s3Key = presignedUrlResponse.key;
      const res = await ezorder.put(
        `/admin/companies/${companyId}/logo`,
        {
          logo: s3Key,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      openSnackMessage("success", "Updated");
      getCompanyDetails();
    } catch (error) {
      console.log(error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage("error", error.response.data.error);
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", error);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (companyId) {
      console.log("ID PROVIDED", companyId);
      getCompanyDetails(companyId);
    } else {
      console.log("NO ID PROVIDED");
    }
  }, [companyId]);

  if (companyDetails) {
    return (
      <>
        <CompanySettingsForm
          companyDetails={companyDetails}
          setCompanyDetails={setCompanyDetails}
          onUpdateCompanySettings={updateCompanyDetails}
          onUpdateCompanyLogo={updateLogo}
          loading={loading}
        />
      </>
    );
  }
};

export default EditCustomerSettings;
