import { updateOrderItemPiece, benchWorkCalcs as calc, withId } from "./utils";
const benchReducer = (state, action) => {
  const benchCalcs = calc(state);
  switch (action.type) {
    case "UPDATE BENCH: ADD BENCH": {
      let newArr = [...state.items];
      const index = newArr.findIndex((obj) => obj.objectType === "BenchWork");
      console.log("index", index);
      console.log("newArr", newArr[index]);
      newArr[index].benchWorkPieces.push(
        withId({
          ...action.payload,
          ...benchCalcs(action.payload),
        })
      );

      return { ...state, items: [...newArr] };
    }
    case "UPDATE BENCH: PIECE DETAILS": {
      const updatedItems = updateOrderItemPiece(state.items, "BenchWork", {
        ...action.payload,
        ...benchCalcs(action.payload),
      });
      return { ...state, items: [...updatedItems] };
    }
    case "UPDATE BENCH: REMOVE BENCH": {
      let newArr = [...state.items];
      const index = newArr.findIndex((obj) => obj.objectType === "BenchWork");
      newArr[index].benchWorkPieces.splice(action.payload, 1);
      return { ...state, items: [...newArr] };
    }
    case "UPDATE BENCH: OVERRIDE CALCULATION": {
      const { trim, key, value } = action.payload;
      const updatedItems = updateOrderItemPiece(state.items, "BenchWork", {
        ...trim,
        ...benchCalcs(trim, key, value),
      });
      return { ...state, items: [...updatedItems] };
    }
    case "UPDATE BENCH: RESET OVERRIDE": {
      const { trim, key } = action.payload;
      const updatedItems = updateOrderItemPiece(state.items, "BenchWork", {
        ...trim,
        ...benchCalcs(trim, key, null),
      });
      return { ...state, items: [...updatedItems] };
    }
    case "UPDATE BENCH: RUN CALCULATIONS": {
      console.log("UPDATE BENCH: RUN CALCULATIONS");
      const calculations = benchCalcs(action.payload);
      const updatedItems = updateOrderItemPiece(state.items, "BenchWork", {
        ...action.payload,
        ...calculations,
      });
      return { ...state, items: [...updatedItems] };
    }
    default:
      return state;
  }
};
export default benchReducer;
