import { useTheme } from "@emotion/react";
import { Add, CameraAlt, Close, Upload } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  TextareaAutosize,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useFormik } from "formik";
import { useState } from "react";
import * as Yup from "yup";

const SetPrimaryContactDialog = ({
  defaultName,
  defaultPhone,
  defaultEmail,
  open,
  handleClose,
  onSubmit,
  isLoading,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [isMe, setIsMe] = useState(true);

  const validationSchema = Yup.object({
    name: Yup.string().required("Name is Required"),
    email: Yup.string().required("Email Address is Required"),
    phone: Yup.string().required("Phone is Required"),
  });
  const formik = useFormik({
    initialValues: {
      name: defaultName,
      phone: defaultPhone,
      email: defaultEmail,
      cc: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log("CONTACT FORM VALUES", values);
      // Convert cc string to array of strings
      let ccArray = [];
      if (values.cc && values.cc.length > 0) {
        ccArray = values.cc.split(",");
      }
      onSubmit({ ...values, cc: ccArray });
    },
  });

  const ccEmailValidationSchema = Yup.object({
    ccEmailInput: Yup.string().email("Must be a valid email"),
  });
  const ccFormik = useFormik({
    initialValues: {
      ccEmailInput: "",
    },
    validationSchema: ccEmailValidationSchema,
    onSubmit: (values) => {
      console.log("ADD CC EMAIL", values);
      const currentCC = formik.values.cc;
      let newArrayString = currentCC;
      if (currentCC.length > 0) {
        newArrayString += `,${values.ccEmailInput}`;
      } else {
        newArrayString += values.ccEmailInput;
      }
      formik.setFieldValue("cc", newArrayString, true);
      ccFormik.setFieldValue("ccEmailInput", "");
    },
  });

  return (
    <Dialog
      fullScreen={isSmallScreen}
      fullWidth={true}
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>Add Contact</DialogTitle>
      <DialogContent>
        <DialogContentText>
          When this order goes to production, who should we contact for
          questions regarding order specifics?
        </DialogContentText>
        <TextField
          autoComplete="name"
          id="name"
          label="Full Name"
          name="name"
          disabled={isMe && defaultName && defaultName.length > 0}
          sx={{ marginTop: "8px" }}
          fullWidth
          variant="filled"
          value={formik.values.name}
          onChange={formik.handleChange}
        />
        {formik.errors.name && (
          <Box>
            <Typography color={"error"}>{formik.errors.name}</Typography>
          </Box>
        )}
        <TextField
          autoComplete="email"
          id="email"
          label="Email"
          name="email"
          disabled={isMe && defaultEmail && defaultEmail.length > 0}
          sx={{ marginTop: "8px" }}
          fullWidth
          variant="filled"
          value={formik.values.email}
          onChange={formik.handleChange}
        />
        {formik.errors.email && (
          <Box>
            <Typography color={"error"}>{formik.errors.email}</Typography>
          </Box>
        )}
        <TextField
          autoComplete="phone"
          id="phone"
          label="Phone Number"
          name="phone"
          disabled={isMe && defaultPhone && defaultPhone.length > 0}
          sx={{ marginTop: "8px" }}
          fullWidth
          variant="filled"
          value={formik.values.phone}
          onChange={formik.handleChange}
        />

        {formik.errors.phone && (
          <Box>
            <Typography color="error">{formik.errors.phone}</Typography>
          </Box>
        )}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            margin: 0,
            padding: 0,
            marginTop: "8px",
          }}
        >
          <Checkbox
            style={{
              margin: 0,
              padding: 0,
            }}
            checked={isMe}
            onChange={(e) => {
              if (e.target.checked == true) {
                formik.setValues({
                  name: defaultName,
                  phone: defaultPhone,
                  email: defaultEmail,
                });
              }

              setIsMe(e.target.checked);
            }}
          />
          <Typography>Me</Typography>
        </div>
        <DialogContentText sx={{ marginTop: "16px" }}>
          Copy others on order notifications
        </DialogContentText>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          {formik &&
            formik.values &&
            formik.values.cc &&
            formik.values.cc.split(",").map((ccEmail, index) => {
              return (
                <Box
                  key={index + ccEmail}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: "4px",
                    marginRight: "4px",
                    border: 1,
                    borderRadius: "25px",
                    borderColor: "rgba(255, 255, 255, 0.23)",
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    sx={{ paddingLeft: "8px", flex: 1, overflow: "hidden" }}
                  >
                    {ccEmail}
                  </Typography>
                  <IconButton
                    onClick={() => {
                      let arrayCopy = formik.values.cc.split(",");
                      arrayCopy.splice(index, 1);
                      formik.setFieldValue("cc", arrayCopy.toString());
                    }}
                  >
                    <Close />
                  </IconButton>
                </Box>
              );
            })}
        </Box>
        <TextField
          autoComplete="email"
          id="ccEmailInput"
          label="cc:"
          name="ccEmailInput"
          sx={{ marginTop: "8px" }}
          fullWidth
          variant="filled"
          value={ccFormik.values.ccEmailInput}
          onChange={ccFormik.handleChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start" size="small">
                <IconButton
                  onClick={() => {
                    ccFormik.submitForm();
                  }}
                >
                  <Add size="small" color="primary" />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {ccFormik.errors.ccEmailInput && (
          <Box>
            <Typography color={"error"}>
              {ccFormik.errors.ccEmailInput}
            </Typography>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <LoadingButton
          loading={isLoading}
          onClick={() => {
            formik.resetForm();
            ccFormik.resetForm();
            handleClose(false);
          }}
        >
          Cancel
        </LoadingButton>
        <LoadingButton loading={isLoading} onClick={formik.handleSubmit}>
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default SetPrimaryContactDialog;
