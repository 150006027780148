import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Box,
  Checkbox,
  TextField,
  Typography,
  FormGroup,
  FormControlLabel,
  Button,
  MenuItem,
  InputAdornment,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment/moment";
const LineItemInput = ({ inputData, handleChange, submit, services }) => {
  const theme = useTheme();
  return (
    <TableRow
      key={"input"}
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
    >
      <TableCell component="th" scope="row"></TableCell>
      <TableCell>
        {/* {lineItem.serviceDate} */}
        {/* <TextField
          size="small"
          value={inputData.serviceDate}
          onChange={handleChange("serviceDate")}
        /> */}
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            label="Service Date"
            value={moment(inputData.serviceDate).format("MM/DD/YYYY")}
            onChange={(newValue) => {
              let dateString = moment(newValue).format("MM/DD/YYYY");
              let e = {
                target: {
                  value: dateString,
                },
              };
              handleChange("serviceDate")(e);
            }}
            renderInput={(params) => (
              <TextField fullWidth sx={{}} {...params} />
            )}
          />
        </LocalizationProvider>
      </TableCell>
      <TableCell>
        {/* {lineItem.service} */}
        <TextField
          select
          size="small"
          value={inputData.service}
          onChange={handleChange("service")}
          // sx={{ width: "16rem" }}
          fullWidth
        >
          {services.map((service, index) => (
            <MenuItem key={index} value={service}>
              {service.Name}
            </MenuItem>
          ))}
        </TextField>
      </TableCell>
      <TableCell>
        {/* {lineItem.description} */}
        <TextField
          fullWidth
          multiline
          maxRows={10}
          rows={10}
          size="small"
          value={inputData.description}
          onChange={handleChange("description")}
        />
      </TableCell>
      <TableCell>
        {/* {lineItem.qty} */}
        <TextField
          type="number"
          onWheel={(event) => {
            event.preventDefault();
          }}
          size="small"
          value={inputData.qty}
          onChange={handleChange("qty")}
        />
      </TableCell>
      <TableCell>
        {/* {lineItem.rate} */}
        <TextField
          type="number"
          onWheel={(event) => {
            event.preventDefault();
          }}
          size="small"
          value={inputData.rate}
          onChange={handleChange("rate")}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Typography style={{ color: theme.palette.grey["500"] }}>
                  $
                </Typography>
              </InputAdornment>
            ),
          }}
        />
      </TableCell>
      <TableCell>
        {/* {lineItem.amount} */}
        <TextField
          type="number"
          size="small"
          onWheel={(event) => {
            event.preventDefault();
          }}
          value={inputData.amount}
          onChange={handleChange("amount")}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Typography style={{ color: theme.palette.grey["500"] }}>
                  $
                </Typography>
              </InputAdornment>
            ),
          }}
        />
      </TableCell>
      <TableCell>
        <Checkbox
          checked={inputData.isTaxed}
          onChange={handleChange("isTaxed")}
        />
      </TableCell>
      <TableCell>
        <Button onClick={() => submit()}>add</Button>
      </TableCell>
    </TableRow>
  );
};
export default LineItemInput;
