import { useContext, useEffect, useState } from "react";
import usePageName from "../../../hocs/usePageName";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import CreateProject from "./CreateProject/CreateProject";
import useProjects from "../../../hooks/useProjects";
import SearchBar from "../../../components/SearchBar";
import InfiniteScroller from "../../../components/InfiniteScroller/InfiniteScroller";
import ProjectCard from "./ProjectCard";
import { Button, Typography } from "@mui/material";
import FabButton from "../../../components/CustomerComponents/FabButton";
import { Add } from "@mui/icons-material";
import ListFooter from "../../../components/InfiniteScroller/ListFooter";
import { HeadingContext } from "../../../context/HeadingContext";

const Projects = () => {
  // usePageName({
  //   heading: decoded.isVendorOrSupplier ? "Customers" : "Projects",
  // });
  const { setHeadingText, setCaptionText } = useContext(HeadingContext);
  const navigate = useNavigate();
  const { authToken, decoded } = useContext(AuthContext);
  const [projectModalOpen, setProjectModalOpen] = useState(false);
  const [
    searchQuery,
    setSearchQuery,
    results,
    onRefresh,
    refreshing,
    onLoadMore,
    loadingMore,
    total,
    hasMore,
  ] = useProjects(authToken, 10);

  const selectHandler = (project) => {
    const { id: projectId, project_tax_form } = project;
    if (decoded.isVendorOrSupplier) {
      navigate(`/customers/${projectId}/orders`);
    } else {
      navigate(`/projects/${projectId}/orders`);
    }
  };

  const handleNewProjectButton = () => {
    setProjectModalOpen(true);
  };

  const handleClose = () => {
    setProjectModalOpen(false);
    onRefresh();
  };

  useEffect(() => {
    if (decoded && decoded.isVendorOrSupplier) {
      setHeadingText("Customers");
      setCaptionText(null);
    } else {
      setHeadingText("Projects");
      setCaptionText(null);
    }
  }, [decoded]);

  return (
    <>
      <SearchBar
        label={
          decoded.isVendorOrSupplier ? "Search Customers" : "Search Projects"
        }
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
      />
      <InfiniteScroller
        items={results}
        renderItem={(item, index) => {
          return (
            <ProjectCard
              key={index}
              project={item}
              onClick={() => selectHandler(item)}
            />
          );
        }}
        onRefresh={onRefresh}
        onEndReached={onLoadMore}
        loading={refreshing || loadingMore}
        ListFooterComponent={() => {
          return <ListFooter hasMore={hasMore} onClick={onLoadMore} />;
        }}
      />
      <FabButton color="success" onClick={handleNewProjectButton}>
        <Add sx={{ mr: 1 }} />
        {decoded.isVendorOrSupplier ? "Customer" : "Project"}
      </FabButton>
      <CreateProject
        open={projectModalOpen}
        handleOpen={() => setProjectModalOpen(true)}
        handleClose={handleClose}
      />
    </>
  );
};
export default Projects;
