const getOrderItemDetails = (objectType) => (state) => {
  const item = state.items.find((item) => item.objectType === objectType);
  return item;
};

export const getFlatSheetDetails = getOrderItemDetails("FlatSheet");
export const getCoilDetails = getOrderItemDetails("Coil");
export const getPanelDetails = getOrderItemDetails("Panel");
export const getTrimDetails = getOrderItemDetails("TrimAndFlashing");
export const getBenchWorkDetails = getOrderItemDetails("BenchWork");
export const getCopingCapDetails = getOrderItemDetails("CopingCap");
export const getCopingCapCleatDetails = getOrderItemDetails("CopingCapCleat");
export const getSplicePlateDetails = getOrderItemDetails("SplicePlate");
export const getAccessoryDetails = getOrderItemDetails("Accessory");

export const piecesBasedOnType = (item) => {
  switch (item.objectType) {
    case "TrimAndFlashing":
      return { pieces: item.trimPieces, key: "trimPieces" };
    case "CopingCap":
      return { pieces: item.capPieces, key: "capPieces" };
    case "CopingCapCleat":
      return { pieces: item.cleatPieces, key: "cleatPieces" };
    case "SplicePlate":
      return { pieces: item.plates, key: "plates" };
    case "BenchWork":
      return { pieces: item.benchWorkPieces, key: "benchWorkPieces" };
    case "Panel":
      return { pieces: item.cuts, key: "cuts" };
    case "Accessory":
      return { pieces: item.items, key: "items" };
    default:
      return null;
      break;
  }
};

export const cleanUpTempIds = (order) => {
  const updatedItems = order.items.map((item) => {
    const { pieces, key } = piecesBasedOnType(item);
    const updatedPieces = pieces.map((piece) => {
      delete piece.temp_id;
      return piece;
    });
    return { ...item, [key]: updatedPieces };
  });
  return { ...order, items: [...updatedItems] };
};

export const addItemTotalCost = (order) => {
  const updatedItems = order.items.map((item) => {
    const itemTotalCost = order.calculations[item.objectType].totalCost;
    return { ...item, totalCost: itemTotalCost ? itemTotalCost : 0 };
  });
  return { ...order, items: [...updatedItems] };
};
