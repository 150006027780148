import { useState, useEffect } from "react";
import { Paper, Box, Typography, IconButton } from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const CollapsableCard = ({ children, defaultOpen = true, title = "" }) => {
  const [open, setOpen] = useState(defaultOpen);

  useEffect(() => {
    setOpen(defaultOpen);
  }, [defaultOpen]);

  return (
    <>
      <Paper elevation={3} sx={{ padding: "2px 15px 15px 15px" }}>
        {open ? (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                cursor: "pointer",
              }}
              onClick={() => setOpen(false)}
            >
              <Typography variant="h6" sx={{ mt: "auto" }}>
                {title}
              </Typography>

              <IconButton>
                <ExpandLessIcon />
              </IconButton>
            </Box>
            <Box sx={{ mt: "1rem", overflow: "auto", padding: "5px" }}>
              {children}
            </Box>
          </>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              cursor: "pointer",
            }}
            onClick={() => setOpen(true)}
          >
            <Typography variant="h6" sx={{ mt: "auto" }}>
              {title}
            </Typography>
            <IconButton sx={{ mt: "auto" }}>
              <ExpandMoreIcon />
            </IconButton>
          </Box>
        )}
      </Paper>
    </>
  );
};
export default CollapsableCard;
