import { useTheme } from "@emotion/react";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { forwardRef, useContext, useState } from "react";
import { AuthContext } from "../context/AuthContext";

const AddPaintManufacturerDialog = ({ open, setOpen, onSubmit }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [name, setName] = useState("");
  return (
    <Dialog
      fullScreen={false}
      fullWidth={true}
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <DialogTitle>Add a Paint Manufacturer</DialogTitle>
      <DialogContent>
        <TextField
          sx={{ marginTop: "8px" }}
          label="Manufacturer Name"
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setOpen(false);
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            onSubmit(name);
            setOpen(false);
          }}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddPaintManufacturerDialog;
