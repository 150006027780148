import {
  Paper,
  Typography,
  Box,
  Card,
  CardContent,
  Button,
  Checkbox,
  CardActionArea,
  Modal,
  TextField,
  IconButton,
  FormControlLabel,
  CircularProgress,
  Dialog,
  DialogContentText,
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import moment from "moment";
import { useState } from "react";
import LockOpenIcon from "@mui/icons-material/LockOpen";

import CreateTimeCardModal from "./createTimeCardModal";
import { Delete, Print } from "@mui/icons-material";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import getWeeklyTimeCardSummaryDocDefinition from "../../pdf-helpers/WeeklyTimeCardSummaryHelper";
import { useTheme } from "@emotion/react";
import { LoadingButton } from "@mui/lab";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const TimeCardApprovals = ({
  weeklyTimeCards,
  updateTimeCard,
  createTimeCard,
  deleteTimeCard,
  weekOf,
  setWeekOf,
  approveWeek,
  removeApproval,
  isApproving,
  isUpdating,
  isCreating,
}) => {
  const theme = useTheme();

  const [openModalEditTimeCard, setOpenModalEditTimeCard] = useState(false);
  const [openModalCreateTimeCard, setOpenModalCreateTimeCard] = useState(false);

  const [selectedTimeCard, setSelectedTimeCard] = useState();

  const [selectedEmployeeID, setSelectedEmployeeID] = useState();
  const [selectedTimeCardID, setSelectedTimeCardID] = useState();

  const selectedUser = weeklyTimeCards.find(
    (user) => user.id === selectedEmployeeID
  );
  // const selectedTimeCard = selectedUser
  //   ? selectedUser.timeCards.find((card) => card.id === selectedTimeCardID)
  //   : null;

  const getWeeklyTimeCardSummaryPDF = async (weekOf, employeeTimeCards) => {
    try {
      let weekOfString = `${moment(weekOf).format("MM/DD/YYYY")} - ${moment(
        moment(weekOf)
      )
        .add(6, "day")
        .format("MM/DD/YYYY")}`;

      console.log("employeeTimeCards", employeeTimeCards);
      let employeeSummaries = [];
      for (const employee of employeeTimeCards) {
        {
          let name = employee.fullName;
          let totalHours = employee.timeCards
            .reduce((prev, curr) => {
              if (!curr.noWork) {
                if (curr.clockOut) {
                  return (
                    prev +
                    moment(curr.clockOut).diff(
                      moment(curr.clockIn),
                      "hours",
                      true
                    )
                  );
                }
              }
              return prev;
            }, 0)
            .toFixed(1);
          employeeSummaries.push({ name, totalHours });
        }
      }
      const docDef = await getWeeklyTimeCardSummaryDocDefinition(
        weekOfString,
        employeeSummaries
      );

      pdfMake.createPdf(docDef).open();
    } catch (error) {
      console.log(error);
      throw Error("Unable to load order summary PDF", { cause: error });
    }
  };

  const handleCloseEditTimeCard = () => {
    setOpenModalEditTimeCard(false);
  };
  const handleOpenEditTimeCard = (employeeId, timeCardId) => {
    setSelectedEmployeeID(employeeId);
    setSelectedTimeCardID(timeCardId);
    setSelectedTimeCard(
      weeklyTimeCards
        .find((user) => user.id === employeeId)
        .timeCards.find((card) => card.id === timeCardId)
    );
    setOpenModalEditTimeCard(true);
  };

  const handleCloseCreateTimeCard = () => {
    setOpenModalCreateTimeCard(false);
  };
  const handleOpenCreateTimeCard = (employeeId) => {
    setSelectedEmployeeID(employeeId);
    setOpenModalCreateTimeCard(true);
  };

  const handleTimeCardChange = (newTimeValue, inOrOut) => {
    // update the time but not the date.
    // create a state copy of the time card, edit it, update the time card with the new state
    const time = moment(newTimeValue).toISOString();
    let newTimeCard = selectedTimeCard;
    newTimeCard[inOrOut] = time;
    setSelectedTimeCard({ ...newTimeCard });
  };

  const saveUpdatedTimeCard = () => {
    updateTimeCard(selectedEmployeeID, selectedTimeCardID, selectedTimeCard);
  };

  const EditTimeCardModal = () => {
    return (
      <Modal open={openModalEditTimeCard} onClose={handleCloseEditTimeCard}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            background: theme.palette.secondary.main,
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
              <Typography variant="button">{selectedUser?.fullName}</Typography>
              <Typography variant="button">
                {moment(selectedTimeCard?.clockIn).format("MMM DD, YYYY")}
              </Typography>
            </Box>
            <LoadingButton
              color="error"
              loading={isUpdating}
              onClick={() => {
                setConfirmDeleteDialog(true);
              }}
            >
              <Delete /> Delete
            </LoadingButton>
          </Box>
          <Box sx={{ mt: "25px" }}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <TimePicker
                label="Clock-In"
                value={selectedTimeCard?.clockIn}
                onChange={(newValue) => {
                  handleTimeCardChange(newValue, "clockIn");
                }}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    sx={{ marginBottom: "10px" }}
                    {...params}
                  />
                )}
              />
              <TimePicker
                label="Clock-Out"
                value={selectedTimeCard?.clockOut}
                onChange={(newValue) => {
                  handleTimeCardChange(newValue, "clockOut");
                }}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    sx={{ marginBottom: "10px" }}
                    {...params}
                  />
                )}
              />

              <DateTimePicker
                /* made these readOnly... wasn't sure if anyone should be able alter these...? */
                readOnly
                label="Date Approved"
                value={
                  selectedTimeCard && selectedTimeCard.approvedOn
                    ? selectedTimeCard.approvedOn
                    : null
                }
                onChange={() => {}}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    sx={{ marginBottom: "10px" }}
                    {...params}
                  />
                )}
              />
              <DateTimePicker
                /* especially this one, except for the employee? */
                readOnly
                label="Date Signed"
                value={
                  selectedTimeCard && selectedTimeCard.signedOn
                    ? selectedTimeCard.signedOn
                    : null
                }
                onChange={() => {}}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    sx={{ marginBottom: "10px" }}
                    {...params}
                  />
                )}
              />
            </LocalizationProvider>
            <FormControlLabel
              control={
                <Checkbox
                  value={selectedTimeCard?.noWork}
                  onChange={(e) => {
                    let newCard = selectedTimeCard;
                    newCard.noWork = e.target.checked;
                    setSelectedTimeCard({ ...newCard });
                  }}
                />
              }
              label="No Work?"
            />
          </Box>
          <Box
            sx={{
              m: 1,
              position: "relative",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <LoadingButton
              loading={isUpdating}
              onClick={() => {
                handleCloseEditTimeCard();
              }}
            >
              Cancel
            </LoadingButton>

            <LoadingButton
              loading={isUpdating}
              variant="outlined"
              // sx={buttonSx}
              disabled={isUpdating}
              onClick={saveUpdatedTimeCard}
            >
              Update
            </LoadingButton>
            {/* {isUpdating && (
              <CircularProgress
                size={24}
                sx={{
                  // color: green[500],
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )} */}
          </Box>
          <Box>
            {selectedTimeCard &&
              selectedTimeCard.notes.map((note, index) => (
                <Card key={index} style={{ margin: 8, padding: "16px" }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography sx={{ fontWeight: "bold" }}>
                      {note.user.fullName}
                    </Typography>
                    <Typography>
                      {moment(note.createdAt).format("MM/DD/YYYY hh:mm A")}
                    </Typography>
                  </Box>

                  <Typography sx={{ marginTop: "16px", fontStyle: "italic" }}>
                    {note.note}
                  </Typography>
                </Card>
              ))}
          </Box>
        </Box>
      </Modal>
    );
  };

  const EmployeesWeeklyEntries = ({
    employee,
    hasUnfinishedTimeCard,
    isApproved,
  }) => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          marginBottom: "15px",
        }}
      >
        <Card
          sx={{
            flexDirection: "column",
            marginRight: "10px",
            minWidth: "150px",
          }}
        >
          <CardContent>
            <Box
              sx={{
                borderBottom: "1px solid black",
                display: "flex",
              }}
            >
              <Typography variant="button">Employee:</Typography>
            </Box>
            <Box>
              <Typography variant="button">{employee.fullName}</Typography>
            </Box>
          </CardContent>
        </Card>
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "row",
            overflowX: "auto",
          }}
        >
          {employee.timeCards[0] !== null &&
            employee.timeCards.map((card) => {
              return (
                <Card
                  key={card.id}
                  sx={{ marginRight: "10px", minWidth: "150px" }}
                >
                  <CardActionArea
                    onClick={() => handleOpenEditTimeCard(employee.id, card.id)}
                  >
                    <CardContent>
                      <Box sx={{ borderBottom: "1px solid black" }}>
                        <Typography variant="button">
                          {moment(card.clockIn).format("dddd MM/DD/YY")}
                        </Typography>
                      </Box>
                      <Box>
                        {card.noWork ? (
                          <Typography variant="button">No Work</Typography>
                        ) : card.clockOut ? (
                          <Typography variant="button">
                            {moment(card.clockOut)
                              .diff(moment(card.clockIn), "hours", true)
                              .toFixed(1)}{" "}
                            hours
                          </Typography>
                        ) : (
                          <Typography
                            variant="button"
                            style={{ color: "#FFA011" }}
                          >
                            Need To Clock Out
                          </Typography>
                        )}
                      </Box>
                    </CardContent>
                  </CardActionArea>
                </Card>
              );
            })}
        </Box>
        <Card style={{ width: 200 }}>
          {employee.timeCards[0] !== null && (
            <CardContent>
              <Box sx={{ position: "relative" }}>
                <Button
                  variant="outlined"
                  // sx={buttonSx}
                  disabled={isApproving || hasUnfinishedTimeCard || isApproved}
                  onClick={() => approveWeek(employee.id, moment().format())}
                >
                  Approve
                </Button>
                {isApproved && (
                  <IconButton
                    disabled={isApproving}
                    onClick={() => {
                      let firstTimeCardWithApprovalRequest = null; // get the first time card for the week that has an approval request
                      // theoretically we should just use any of the time cards in the week.

                      let i;
                      for (i = 0; i < employee.timeCards.length; i++) {
                        let tc = employee.timeCards[i];
                        if (tc.weeklyTimeApprovalRequest) {
                          firstTimeCardWithApprovalRequest = tc;
                          break;
                        }
                      }
                      console.log(
                        "Unlocking/Removing Approval",
                        firstTimeCardWithApprovalRequest
                      );
                      removeApproval(firstTimeCardWithApprovalRequest.id);
                    }}
                  >
                    <LockOpenIcon sx={{ fontSize: 40, color: "grey" }} />
                  </IconButton>
                )}

                {isApproving && (
                  <CircularProgress
                    size={24}
                    sx={{
                      // color: green[500],
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-12px",
                      marginLeft: "-12px",
                    }}
                  />
                )}
              </Box>
              <Box>
                <Typography variant="button">
                  Total:{" "}
                  {employee.timeCards
                    .reduce((prev, curr) => {
                      if (!curr.noWork) {
                        if (curr.clockOut) {
                          return (
                            prev +
                            moment(curr.clockOut).diff(
                              moment(curr.clockIn),
                              "hours",
                              true
                            )
                          );
                        }
                      }
                      return prev;
                    }, 0)
                    .toFixed(1)}
                </Typography>
              </Box>
            </CardContent>
          )}
        </Card>
        <Button
          color="success"
          variant="text"
          size="small"
          onClick={() => handleOpenCreateTimeCard(employee.id)}
        >
          + Time
        </Button>
      </Box>
    );
  };

  const changeWeeks = () => {
    //07/24/2022 00:00:00
    const newWeek = (nextOrPrev) => {
      switch (nextOrPrev) {
        case "Next":
          setWeekOf(moment(weekOf).add(1, "week").format());
          break;
        case "Prev":
          setWeekOf(moment(weekOf).subtract(1, "week").format());
          break;
        default:
          break;
      }
    };
    return (
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <IconButton
          size="small"
          variant="outlined"
          onClick={() => newWeek("Prev")}
        >
          <ArrowCircleLeftIcon />
        </IconButton>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="caption" sx={{ margin: "auto" }}>
            Week Of:
          </Typography>
          <Typography variant="button" sx={{ mr: "5px", ml: "5px" }}>
            {moment(weekOf).format("MM/DD/YYYY")} -{" "}
            {moment(moment(weekOf)).add(6, "day").format("MM/DD/YYYY")}
          </Typography>
        </Box>

        <IconButton
          size="small"
          variant="outlined"
          onClick={() => newWeek("Next")}
        >
          <ArrowCircleRightIcon />
        </IconButton>
      </Box>
    );
  };
  const [confirmDeleteDialog, setConfirmDeleteDialog] = useState(false);
  return (
    <>
      <Paper sx={{ padding: "20px" }}>
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              startIcon={<Print />}
              style={{ marginBottom: 10 }}
              onClick={() => {
                getWeeklyTimeCardSummaryPDF(weekOf, weeklyTimeCards);
              }}
            >
              Print Weekly Summary
            </Button>
          </div>
          {changeWeeks()}
        </Box>
        {weeklyTimeCards.map((employee, i) => {
          let hasUnfinishedTimeCard = false;
          let isApproved = false;
          for (const tc of employee.timeCards) {
            // If there is an unfinished time card ("yes" work and no clock out)
            // do not allow admin to approve
            if (!tc.noWork && !tc.clockOut) {
              // If any time card is incomplete (no clockout)
              hasUnfinishedTimeCard = true;
            }
            if (
              tc.weeklyTimeApprovalRequest // If already has an approval request for this week
            ) {
              // If any time card is incomplete (no clockout)
              //
              isApproved = true;
            }
          }
          return (
            <Box key={i}>
              <EmployeesWeeklyEntries
                employee={employee}
                hasUnfinishedTimeCard={hasUnfinishedTimeCard}
                isApproved={isApproved}
              />
            </Box>
          );
        })}
        <EditTimeCardModal />
        <CreateTimeCardModal
          open={openModalCreateTimeCard}
          handleClose={handleCloseCreateTimeCard}
          createTimeCard={createTimeCard(selectedEmployeeID)}
          // createTimeCard={async () => {
          //   console.log("Create Time Card Clicked");
          //   let isSuccessful = await createTimeCard(selectedEmployeeID);
          //   // if (isSuccessful) handleCloseCreateTimeCard();
          // }}
          isCreating={isCreating}
        />
        {weeklyTimeCards.length === 0 && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <Typography variant="button">No Time Cards To Display</Typography>
          </Box>
        )}
      </Paper>
      <Dialog
        fullWidth="true"
        open={confirmDeleteDialog}
        onClose={() => {
          setConfirmDeleteDialog(false);
        }}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            loading={isUpdating}
            onClick={() => {
              setConfirmDeleteDialog(false);
            }}
          >
            Cancel
          </LoadingButton>
          <LoadingButton
            variant="outlined"
            color="error"
            loading={isUpdating}
            onClick={() => {
              deleteTimeCard(selectedTimeCard.id);
              setConfirmDeleteDialog(false);
            }}
          >
            Delete
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TimeCardApprovals;
